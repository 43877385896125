import { useMutation } from '@apollo/client';
import { ADD_USER_SIGNATURE } from 'api/requests.v2';
import { RoundedButton } from 'components/shared/button';
import { DefaultTextInput } from 'components/shared/form';
import { ImagePicker } from 'components/shared/imagepicker';
import { ImageSelectionPage } from 'constants/enums';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getSignature, resetSignature, setSignature } from 'store/admin';
import Styles from 'style';
import { extractExtensionFromB64Data, trimFormData } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const AddUserSignature: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const [addUserSignature] = useMutation(ADD_USER_SIGNATURE);
  const [isSuccessfullySubmitted, setSuccessfullySubmitted] = useState('');
  const [isSubmittedError, setSubmittedError] = useState('');

  const signatureInfo = useSelector(getSignature);
  const dispatch = useDispatch();

  const {
    control,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: any) => {
    if (signatureInfo) {
      const trimmedData = trimFormData(data);

      const imageExtension = extractExtensionFromB64Data(
        signatureInfo.localURI,
      );

      const signatureResponse = await addUserSignature({
        variables: {
          email: trimmedData.email,
          base64Signature: signatureInfo.localURI,
          imageExtension,
        },
      });

      if (signatureResponse.data.response.success === true) {
        setSuccessfullySubmitted('🤙 User Signature Added Successfully 🎉');
      } else {
        setSubmittedError(signatureResponse.data.response.errorMessage);
      }

      dispatch(resetSignature());
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setTimeout(() => {
        setSubmittedError('');
        setSuccessfullySubmitted('');
      }, 3000);
    }
  }, [reset, isSubmitSuccessful]);

  return (
    <View style={TailwindResponsive('pb-12')}>
      <View style={TailwindResponsive(`border-b border-Dark1 p-12`)}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              value={value}
              label="* Email"
              handleChange={onChange}
              errorMessage={errors.email?.message}
            />
          )}
          name="email"
          defaultValue=""
        />
        <View style={TailwindResponsive('items-center')}>
          <ImagePicker
            image={signatureInfo?.filePath}
            imageSelectionHandler={(image) =>
              dispatch(setSignature({ value: image }))
            }
            page={ImageSelectionPage.Admin}
          />
        </View>
        <View style={TailwindResponsive('m-5')}>
          <RoundedButton
            label="Add Signature"
            onPress={() => {
              handleSubmit(onSubmit)();
            }}
            backgroundColor={Styles.Colours.Primary}
            color={Styles.Colours.Light1}
          />
          {!!isSuccessfullySubmitted && (
            <Text style={{ color: Styles.Colours.Success }}>
              {isSuccessfullySubmitted}
            </Text>
          )}
          {!!isSubmittedError && (
            <Text style={{ color: Styles.Colours.Error }}>
              {isSubmittedError}
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};
