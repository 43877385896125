import { useReactiveVar } from '@apollo/client';
import { GraphikSemiTextSm, GraphikTextSm } from 'components/shared/styled';
import { AssetType } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { getDiminishedRoyalties } from 'store/forms';
import { RegisterStore } from 'store/registerWork';
import { totalDiminishedRoyalty, totalRoyalty } from 'utilities';
import { formatRoyaltyCopy } from 'utilities/Royalties';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useGetFinalForm, useIsBrand, useIsGallery } from '../Hooks';

export const ReviewRoyalties: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const finalForm = useGetFinalForm();
  const isGallery = useIsGallery();
  const isBrand = useIsBrand();
  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const isCollectible = detailsForm?.assetType === AssetType.Collectible;

  const diminishedRoyalties = useSelector(getDiminishedRoyalties);
  const correctRoyalty = diminishedRoyalties.find(
    (royalty) => royalty?.workId === 'RegisterWork',
  );

  if (correctRoyalty && correctRoyalty.doesDiminish) {
    const diminishedTotal = totalDiminishedRoyalty(correctRoyalty);

    return (
      <View>
        {isGallery ? (
          <>
            <View style={TailwindResponsive(`flex-col flex-wrap mb-2`)}>
              <GraphikSemiTextSm>Total Resale Commission</GraphikSemiTextSm>
              <View style={TailwindResponsive(`flex-row flex-wrap`)}>
                <GraphikSemiTextSm>
                  {totalRoyalty(finalForm, isCollectible)}%{' '}
                </GraphikSemiTextSm>
                <GraphikTextSm>for the first </GraphikTextSm>
                <GraphikSemiTextSm>
                  {correctRoyalty.diminishedDate}{' '}
                  {formatRoyaltyCopy(correctRoyalty.diminishedDate)}{' '}
                </GraphikSemiTextSm>
                <GraphikTextSm>following </GraphikTextSm>
                <GraphikSemiTextSm>date of first sale </GraphikSemiTextSm>
                <GraphikTextSm>and </GraphikTextSm>
                <GraphikSemiTextSm>{diminishedTotal}% </GraphikSemiTextSm>
                <GraphikTextSm>thereafter</GraphikTextSm>
              </View>
            </View>
            <View style={TailwindResponsive(`flex-col flex-wrap mb-2`)}>
              <GraphikTextSm>Artist Resale Commission </GraphikTextSm>
              <View style={TailwindResponsive(`flex-row flex-wrap`)}>
                <GraphikSemiTextSm>
                  {parseFloat(finalForm.artistRoyalty)}%{' '}
                </GraphikSemiTextSm>
                <GraphikTextSm>for the first </GraphikTextSm>
                <GraphikSemiTextSm>
                  {correctRoyalty.diminishedDate}{' '}
                  {formatRoyaltyCopy(correctRoyalty.diminishedDate)}{' '}
                </GraphikSemiTextSm>
                <GraphikTextSm>following </GraphikTextSm>
                <GraphikSemiTextSm>date of first sale </GraphikSemiTextSm>
                <GraphikTextSm>and </GraphikTextSm>
                <GraphikSemiTextSm>
                  {correctRoyalty.diminishedArtistValue}%{' '}
                </GraphikSemiTextSm>
                <GraphikTextSm>thereafter</GraphikTextSm>
              </View>
            </View>
            <View style={TailwindResponsive(`flex-col flex-wrap`)}>
              <GraphikTextSm>Gallery Resale Commission </GraphikTextSm>
              <View style={TailwindResponsive(`flex-row flex-wrap`)}>
                <GraphikSemiTextSm>
                  {parseFloat(finalForm.galleryRoyalty)}%{' '}
                </GraphikSemiTextSm>
                <GraphikTextSm>for the first </GraphikTextSm>
                <GraphikSemiTextSm>
                  {correctRoyalty.diminishedDate}{' '}
                  {formatRoyaltyCopy(correctRoyalty.diminishedDate)}{' '}
                </GraphikSemiTextSm>
                <GraphikTextSm>following </GraphikTextSm>
                <GraphikSemiTextSm>date of first sale </GraphikSemiTextSm>
                <GraphikTextSm>and </GraphikTextSm>
                <GraphikSemiTextSm>
                  {correctRoyalty.diminishedGalleryValue}%{' '}
                </GraphikSemiTextSm>
                <GraphikTextSm>thereafter</GraphikTextSm>
              </View>
            </View>
          </>
        ) : (
          <View style={TailwindResponsive(`flex-col flex-wrap mobWeb:mt-2`)}>
            <GraphikTextSm>Artist Resale Commission</GraphikTextSm>
            <View style={TailwindResponsive(`flex-row flex-wrap`)}>
              <GraphikSemiTextSm>
                {parseFloat(finalForm.artistRoyalty)}%{' '}
              </GraphikSemiTextSm>
              <GraphikTextSm>for the first </GraphikTextSm>
              <GraphikSemiTextSm>
                {correctRoyalty.diminishedDate}{' '}
                {formatRoyaltyCopy(correctRoyalty.diminishedDate)}{' '}
              </GraphikSemiTextSm>
              <GraphikTextSm>following </GraphikTextSm>
              <GraphikSemiTextSm>date of first sale </GraphikSemiTextSm>
              <GraphikTextSm>and </GraphikTextSm>
              <GraphikSemiTextSm>
                {correctRoyalty.diminishedArtistValue}%{' '}
              </GraphikSemiTextSm>
              <GraphikTextSm>thereafter</GraphikTextSm>
            </View>
          </View>
        )}
      </View>
    );
  }

  if (isGallery) {
    return (
      <View>
        <GraphikSemiTextSm>
          Total Resale Commission — {totalRoyalty(finalForm, isCollectible)}%
        </GraphikSemiTextSm>
        <GraphikTextSm>
          Artist Resale Commission — {parseFloat(finalForm.artistRoyalty)}%
        </GraphikTextSm>
        <GraphikTextSm>
          Gallery Resale Commission — {parseFloat(finalForm.galleryRoyalty)}%
        </GraphikTextSm>
      </View>
    );
  }

  return (
    <View>
      {isBrand && isCollectible ? (
        <>
          <View style={TailwindResponsive('mb-2')}>
            <GraphikSemiTextSm>
              Total Resale Commission — {totalRoyalty(finalForm, isCollectible)}
              %
            </GraphikSemiTextSm>
          </View>
          <GraphikTextSm>
            Brand Resale Commission — {parseFloat(finalForm.brandRoyalty)}%
          </GraphikTextSm>
          {finalForm.contributorRoyalties.map((contributorRoyalty) => (
            <GraphikTextSm>
              {`${contributorRoyalty.name} Resale Commission – ${parseFloat(
                contributorRoyalty.royalty,
              )}%`}
            </GraphikTextSm>
          ))}
        </>
      ) : (
        <GraphikSemiTextSm>
          Artist Resale Commission – {parseFloat(finalForm.artistRoyalty)}%
        </GraphikSemiTextSm>
      )}
    </View>
  );
};

export default ReviewRoyalties;
