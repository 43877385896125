import { useQuery } from '@apollo/client';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import { GET_CONSIGNED_WORKS, GET_MY_COLLECTION } from 'api/requests.v2';
import { AppBar } from 'components/shared/footer';
import { ButtonFooter } from 'components/shared/footer/ButtonFooter';
import { ArtistOnboarding } from 'components/shared/onboarding/artist/ArtistOnboarding';
import { BasicPage } from 'components/shared/page/BasicPage';
import {
  CollectionPage,
  CollectionTab,
  CollectionView,
  UserRole,
} from 'constants/enums';
import { UserContext } from 'contexts';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { Platform, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentView,
  setCollectionLoading,
  setCollectionTab,
  setErrorMessage,
  setMyCollection,
  setMyConsignedWorks,
  setPage,
  showMobileSelector,
} from 'store/collection';
import {
  getUserHasCompletedCollectorOnboarding,
  getUserHasCompletedOnboarding,
  getUserRole,
} from 'store/user';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import CollectorOnboarding from '../../shared/onboarding/collector/CollectorOnboarding';
import { CTHeader } from './components/CTHeader';
import { CollectionGSAButton } from './components/CollectionGSAButton';
import { ListViewWrapper } from './components/ListViewWrapper';
import GridViewWrapper from './gridView/GridViewWrapper';
import { useGetDraftWorks } from './hooks';

export const Collection: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const userRole = useSelector(getUserRole);
  const userHasCompletedCollectorOnboarding = useSelector(
    getUserHasCompletedCollectorOnboarding,
  );
  const userHasCompletedOnboarding = useSelector(getUserHasCompletedOnboarding);
  const dispatch = useDispatch();
  const route = useRoute();
  const showMobileSelectorScreen = useSelector(showMobileSelector);
  const { TailwindResponsive } = useTailwindResponsive();
  const view = useSelector(getCurrentView);

  const { getDrafts } = useGetDraftWorks();

  useFocusEffect(
    React.useCallback(() => {
      getDrafts();
    }, [getDrafts]),
  );

  const {
    loading: currentWorksLoading,
    error: currentWorksError,
    refetch: currentWorksRefetch,
  } = useQuery(GET_MY_COLLECTION, {
    variables: {
      userId: authUser?.uid,
    },
    onCompleted: (data) => {
      dispatch(setMyCollection(data.response));
    },
  });

  const { loading: consignedWorksLoading, error: consignedWorksError } =
    useQuery(GET_CONSIGNED_WORKS, {
      variables: { consignor: authUser?.uid },
      onCompleted: (data) => {
        dispatch(setMyConsignedWorks(data.response));
      },
    });

  useEffect(() => {
    dispatch(
      setCollectionLoading(consignedWorksLoading || currentWorksLoading),
    );
  }, [consignedWorksLoading, currentWorksLoading, dispatch]);

  useEffect(() => {
    if (consignedWorksError)
      dispatch(setErrorMessage(consignedWorksError.message));
    else if (currentWorksError)
      dispatch(setErrorMessage(currentWorksError.message));
    else dispatch(setErrorMessage(''));
  }, [consignedWorksError, currentWorksError, dispatch]);

  useFocusEffect(
    useCallback(() => {
      dispatch(setPage(CollectionPage.Collection));
      currentWorksRefetch();
    }, [currentWorksRefetch, dispatch]),
  );

  // For a specific tab display
  useEffect(() => {
    const routeParams: any = route.params;
    if (routeParams && routeParams.tab && routeParams.tab === 'consignment')
      dispatch(setCollectionTab(CollectionTab.Consigned));
  }, [dispatch, route]);

  return (
    <BasicPage>
      <CTHeader />

      {userRole === UserRole.Collector &&
        userHasCompletedCollectorOnboarding === false && (
          <CollectorOnboarding />
        )}

      {userRole === UserRole.Artist && userHasCompletedOnboarding === false && (
        <ArtistOnboarding />
      )}

      {view === CollectionView.Line ? <ListViewWrapper /> : <GridViewWrapper />}

      {Platform.OS === 'ios' && !showMobileSelectorScreen && <AppBar />}
      {Platform.OS === 'ios' && showMobileSelectorScreen && (
        <ButtonFooter>
          <View style={TailwindResponsive('justify-center px-4 w-full')}>
            <CollectionGSAButton />
          </View>
        </ButtonFooter>
      )}
    </BasicPage>
  );
};
