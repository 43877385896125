import { gql } from '@apollo/client';

export const GET_VERIFF_SESSION_URL = gql`
  query getVeriffSessionUrl($userId: ID!) {
    response: getVeriffSessionUrl(userId: $userId)
  }
`;

export const SAVE_VERIFF_SESSION_URL = gql`
  mutation saveVeriffSessionUrl($userId: ID!, $sessionUrl: String!) {
    response: saveVeriffSessionUrl(userId: $userId, sessionUrl: $sessionUrl) {
      errorMessage
      success
      successMessage
    }
  }
`;
