import step2 from 'assets/images/onboarding/step2.png';
import { GraphikMediumTextMd, GraphikTextMd } from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { Image, Platform, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ColOnboardingScreen3: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View style={TailwindResponsive(`items-center`)}>
      <View style={TailwindResponsive(`mb-4`)}>
        {Platform.OS === 'web' ? (
          <img src={step2} style={TailwindResponsive(`h-52`)} />
        ) : (
          <Image
            source={step2}
            style={{ height: 200, width: 200 }}
            resizeMode="contain"
          />
        )}
      </View>

      <GraphikMediumTextMd style={TailwindResponsive(`mb-4`)}>
        2 - Certify
      </GraphikMediumTextMd>

      <GraphikTextMd>
        Request digital certificates of title and authenticity directly from
        artists to create a blockchain-backed provenance record for each work in
        your collection.
      </GraphikTextMd>
    </View>
  );
};

export default ColOnboardingScreen3;
