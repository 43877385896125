import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigation } from '@react-navigation/core';
import {
  GENERATE_SALES_AGREEMENT,
  GET_SELECTED_WORKS,
  GET_STRIPE_ACCOUNT_STATUS,
} from 'api/requests.v2';
import { Breadcrumbs, transferSteps } from 'components/shared/breadcrumb';
import { LeftButton } from 'components/shared/button';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { TextButton } from 'components/shared/button/TextButton';
import { ErrorMessage } from 'components/shared/error';
import { SpinLoader } from 'components/shared/loader';
import { WorkflowPage } from 'components/shared/page';
import {
  FCWebView,
  useWebViewNavigationStateChange,
} from 'components/shared/webView';
import { FCStackNavProp } from 'navigation';
import React, {
  FunctionComponent,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Platform, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { WebView } from 'react-native-webview';
import { useDispatch, useSelector } from 'react-redux';
import { clearRoyalties, setDefaultsInitialized } from 'store/forms';
import {
  getContractAccepted,
  getContractLoading,
  getIsRetro,
  getSelectedWorks,
  resetRider,
  setIsRetro,
} from 'store/transferWork';
import {
  InvoiceChoice,
  buyerRegistrationFormVar,
  userInvoiceChoiceVar,
  webViewOpenVar,
  webViewUrlVar,
  worksVar,
} from 'store/transferWork/apollo';
import { getMainUser, getUser } from 'store/user';
import Styles from 'style';
import { isRetroTransaction, useUnload } from 'utilities';
import LogError from 'utilities/LogError';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import BuyerRegistration from './BuyerRegistration';
import { Confirmation } from './Confirmation';
import {
  ITransactionDetailsForm,
  TransactionDetailsDefaultValues,
  TransactionFinalForm,
  transactionDetailsSchema,
} from './Helpers';
import TransactionDetails from './TransactionDetails';
import TransErrorBanner from './components/ErrorBanner';
import { useSetupBuyerRegistrationForm } from './forms/buyer-registration.form';
import {
  useGenerateSalesAgreement,
  useSetupWorks,
  useValidateSubsection,
} from './hooks';

export const TransferWork: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { TailwindResponsive } = useTailwindResponsive();

  const navigation = useNavigation<FCStackNavProp>();

  const contractAccepted = useSelector(getContractAccepted);
  const contractLoading = useSelector(getContractLoading);
  const currentUser = useSelector(getUser);
  const mainUser = useSelector(getMainUser);
  const selectedWorks = useSelector(getSelectedWorks);
  const transIsRetro = useSelector(getIsRetro);

  const [isKycScreen, setKycScreen] = useState(false);
  const webviewRef: RefObject<WebView> = useRef(null);

  const webviewOpen = useReactiveVar(webViewOpenVar);
  const webviewUrl = useReactiveVar(webViewUrlVar);
  const works = useReactiveVar(worksVar);

  const [currentStep, setCurrentStep] = useState(transferSteps[0]!.step);
  const [finalForm, setFinalForm] = useState<TransactionFinalForm | null>(null);

  const keyboardScrollRef: RefObject<KeyboardAwareScrollView> = useRef(null);

  const pageTitle = transIsRetro ? 'Issue Certificate' : 'New Sales Agreement';

  navigation.setOptions({
    title: transIsRetro ? 'Issue Certificate' : 'Generate Sales Agreement',
  });

  useEffect(() => {
    if (selectedWorks.length === 0) navigation.push('Collection');
  }, [navigation, selectedWorks]);

  useEffect(() => {
    const retroTrans =
      !!works &&
      works.length === 1 &&
      !!works[0] &&
      isRetroTransaction(works[0]);
    dispatch(setIsRetro(retroTrans));
  }, [dispatch, works]);

  const [
    callGetStripeAccountStatus,
    { loading: stripeStatusLoading, data: stripeData, refetch: stripeRefetch },
  ] = useLazyQuery(GET_STRIPE_ACCOUNT_STATUS, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (currentUser) {
      callGetStripeAccountStatus({
        variables: {
          userId:
            currentUser?.isSubUser && mainUser ? mainUser?.id : currentUser?.id,
        },
      });
    }
  }, [callGetStripeAccountStatus, currentUser, mainUser]);

  useSetupBuyerRegistrationForm();
  const buyerForm = useReactiveVar(buyerRegistrationFormVar);

  const {
    control: transactionDetailsControl,
    formState: { errors: transactionDetailsErrors },
    trigger: transactionDetailsTrigger,
    getValues: transactionDetailsGetValues,
    setValue: transactionDetailsSetValue,
    watch: transactionDetailsWatch,
    clearErrors: transactionDetailsClearErrors,
    reset: resetTransactionDetailsForm,
  } = useForm<ITransactionDetailsForm>({
    defaultValues: TransactionDetailsDefaultValues,
    resolver: yupResolver(transactionDetailsSchema),
  });

  const { error: getSelectedWorksError } = useQuery(GET_SELECTED_WORKS, {
    variables: {
      workIds: selectedWorks,
    },
    onCompleted: (worksResponse) => {
      dispatch(clearRoyalties());
      dispatch(setDefaultsInitialized(false));
      setupWorks(worksResponse.response);
    },
  });

  const [
    callGenerateSalesAgreement,
    {
      error: generateSalesAgreementError,
      loading: generateSalesAgreementIsLoading,
    },
  ] = useMutation(GENERATE_SALES_AGREEMENT);

  useEffect(() => {
    if (generateSalesAgreementIsLoading) {
      setCurrentStep(5);
    }
  }, [generateSalesAgreementIsLoading]);

  useEffect(() => {
    if (!stripeStatusLoading && (!stripeData || !stripeData.response)) {
      transactionDetailsSetValue('generateInvoice', false);
    } else if (!stripeStatusLoading && stripeData && stripeData.response) {
      transactionDetailsSetValue('generateInvoice', true);
    }
  }, [stripeStatusLoading, stripeData, transactionDetailsSetValue]);

  useEffect(() => {
    if (currentUser?.connectedToQb) {
      transactionDetailsSetValue('quickbooksInvoice', true);
    }
  }, [currentUser, transactionDetailsSetValue]);

  useEffect(() => {
    if (generateSalesAgreementError) {
      LogError.logError(
        `Error in Transfer Work: ${generateSalesAgreementError.message} | Info: ${generateSalesAgreementError.extraInfo}`,
      );
    }
  }, [generateSalesAgreementError]);

  const dataError = useMemo(
    () => getSelectedWorksError || generateSalesAgreementError,
    [generateSalesAgreementError, getSelectedWorksError],
  );

  const isLoading = useMemo(
    () => stripeStatusLoading || (currentStep > 4 && !dataError),
    [currentStep, dataError, stripeStatusLoading],
  );

  const salesAgreementButtonLabel = useMemo(() => {
    if (currentStep === 2) {
      return 'Sign and Send';
    }
    return 'Next';
  }, [currentStep]);

  const handleGoBack = () => {
    setCurrentStep(currentStep - 1);
    if (Platform.OS === 'web')
      window.scroll({ top: 0, left: 0, behavior: 'auto' });
    keyboardScrollRef?.current?.scrollToPosition(0, 0);
  };

  useUnload((e: any) => {
    const navState = navigation.getState();
    if (navState.routes[navState.index]?.name === 'TransferWork') {
      e.preventDefault();
      // eslint-disable-next-line no-param-reassign
      e.returnValue =
        'Are you sure you want to leave? You will lose your current transaction data.';
    }
  });

  const loadingText = useMemo(() => {
    if (currentStep > 4) {
      return `${
        transIsRetro ? 'Issuing certificate' : 'Sending sales offer'
      }, this may take a few moments.`;
    }
    return null;
  }, [currentStep, transIsRetro]);

  const handleWebViewNavigationStateChange = useWebViewNavigationStateChange({
    setWebviewOpen: webViewOpenVar,
    navigation,
    webviewRef,
    resetTransactionDetailsForm,
    setCurrentStep,
    currentStep,
    stripeRefetch,
  });

  const setupWorks = useSetupWorks(transactionDetailsSetValue);
  const { generateSalesAgreement, getVariables } = useGenerateSalesAgreement(
    finalForm,
    callGenerateSalesAgreement,
    setCurrentStep,
  );
  const validateSubsection = useValidateSubsection(
    currentStep,
    isKycScreen,
    keyboardScrollRef,
    setCurrentStep,
    setFinalForm,
    setKycScreen,
    transactionDetailsGetValues,
    transactionDetailsSetValue,
    transactionDetailsTrigger,
  );

  useEffect(() => {
    // Reset everything each start
    worksVar([]);
    buyerForm?.reset();
    resetTransactionDetailsForm();
    userInvoiceChoiceVar(InvoiceChoice.INCLUDE);
    dispatch(resetRider());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading && !webviewOpen)
    return <SpinLoader placeholderText={loadingText} />;
  if (dataError) return <ErrorMessage error={dataError} />;

  if (webviewOpen)
    return (
      <FCWebView
        handleWebViewNavigationStateChange={handleWebViewNavigationStateChange}
        webviewRef={webviewRef}
        webviewUrl={webviewUrl}
      />
    );

  return (
    <WorkflowPage title={pageTitle}>
      {(currentStep <= 2 || Platform.OS === 'web') && (
        <Breadcrumbs currentStep={currentStep} steps={transferSteps} />
      )}
      <TransErrorBanner />

      <KeyboardAwareScrollView
        ref={keyboardScrollRef}
        bounces={false}
        extraHeight={200}
        keyboardOpeningTime={0}
        enableResetScrollToCoords={false}
        contentContainerStyle={TailwindResponsive(`flex`)}
        showsVerticalScrollIndicator={false}
        style={TailwindResponsive(`mob:-mx-4 flex-1`)}
      >
        {currentStep <= 4 && !dataError && (
          <View style={TailwindResponsive(`mob:px-4`)}>
            <View>
              {currentStep === 0 && (
                <BuyerRegistration
                  isKycScreen={isKycScreen}
                  keyboardScrollRef={keyboardScrollRef}
                />
              )}
              {currentStep === 1 && (
                <TransactionDetails
                  clearErrors={transactionDetailsClearErrors}
                  control={transactionDetailsControl}
                  errors={transactionDetailsErrors}
                  getValues={transactionDetailsGetValues}
                  isStripeSetup={stripeData ? stripeData.response : false}
                  setValues={transactionDetailsSetValue}
                  trigger={transactionDetailsTrigger}
                  watch={transactionDetailsWatch}
                  works={works}
                />
              )}
              {currentStep === 2 && (
                <Confirmation
                  getVars={getVariables}
                  finalForm={finalForm!}
                  works={works}
                />
              )}
            </View>

            <View style={TailwindResponsive(`flex flex-row mt-20 mob:mt-4`)}>
              {currentStep > 0 && (
                <View
                  style={TailwindResponsive(`mr-14 mobWeb:w-1/4 mobWeb:mr-0`)}
                >
                  <TextButton
                    label="Back"
                    onPress={() => handleGoBack()}
                    paddingHorizontal={0}
                  />
                </View>
              )}

              <View
                style={TailwindResponsive(`mobWeb:flex-1 mobWeb:max-w-full`)}
              >
                {currentStep === 2 ? (
                  <FairchainButton
                    buttonStyle={TailwindResponsive(
                      `bg-Primary px-6 rounded-full`,
                    )}
                    disabled={!contractAccepted}
                    label={salesAgreementButtonLabel}
                    loading={contractLoading}
                    loadingStyle={TailwindResponsive(`w-40`)}
                    onPress={() => {
                      generateSalesAgreement();
                    }}
                    spinnerColor={Styles.Colours.Light1}
                    textStyle={TailwindResponsive(`text-Light1`)}
                  />
                ) : (
                  <LeftButton label="Next" onPress={validateSubsection} />
                )}
              </View>
            </View>
          </View>
        )}
      </KeyboardAwareScrollView>
    </WorkflowPage>
  );
};

export default TransferWork;
