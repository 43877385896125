import { useReactiveVar } from '@apollo/client';
import { Link } from '@react-navigation/native';
import PrimaryFooter from 'components/graphics/PrimaryFooter';
import CenteredImage from 'components/shared/image/CenteredImage';
import { ImageSelectionPage } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { Pressable, View } from 'react-native';
import { IImageKey } from 'store/registerWork';
import { RegisterStore } from 'store/registerWork/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useImagePicker } from '../utils';

interface IImageDisplayProps {
  image: string;
  imageKey?: IImageKey;
  page: number;
}

export const ImageDisplay: FunctionComponent<IImageDisplayProps> = ({
  image,
  imageKey,
  page,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const { linkConfig } = useImagePicker(imageKey, page, image);

  const primaryImageKey = useReactiveVar(RegisterStore.Vars.Images.primary);

  if (page === ImageSelectionPage.RegisterWork)
    return (
      <View style={TailwindResponsive('mr-5 w-52 h-52')}>
        <Pressable
          onPress={() => {
            RegisterStore.Vars.Images.modalImage(imageKey);
            RegisterStore.Vars.Images.showModal(true);
          }}
          testID={`ImagePicker_${imageKey}_Image`}
        >
          <View
            style={{
              ...TailwindResponsive(`p-4 bg-Light5 w-52 h-52`),
            }}
          >
            <CenteredImage isLocal uri={image} />
          </View>
        </Pressable>
        {primaryImageKey === imageKey && (
          <PrimaryFooter testID={`ImagePicker_${imageKey}_Primary`} />
        )}
      </View>
    );

  return (
    <View style={TailwindResponsive('mr-5 w-52 h-52')}>
      <Link to={linkConfig} testID={`ImagePicker_${imageKey}_Image`}>
        <View
          style={{
            ...TailwindResponsive(`p-4 bg-Light5 w-52 h-52`),
          }}
        >
          <CenteredImage isLocal uri={image} />
        </View>
      </Link>
      {primaryImageKey === imageKey && (
        <PrimaryFooter testID={`ImagePicker_${imageKey}_Primary`} />
      )}
    </View>
  );
};
