import { TextButton } from 'components/shared/button';
import React, { FunctionComponent } from 'react';
import { Platform, Pressable, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { GraphikTextMd } from '../styled';

interface IConfirmationPopupProps {
  confirm: () => void;
  cancel: () => void;
}

export const ConfirmationPopup: FunctionComponent<IConfirmationPopupProps> = ({
  confirm,
  cancel,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View style={TailwindResponsive('flex flex-1 flex-row')}>
      {Platform.OS === 'web' && (
        <Pressable
          style={TailwindResponsive(
            'bg-Light1 bg-opacity-50 flex-1 mobWeb:hidden',
          )}
          onPress={cancel}
        />
      )}
      <View
        style={TailwindResponsive(
          'bg-Light1 mob:bg-opacity-90 mob:justify-center mob:w-full mobWeb:w-full web:flex-1 web:p-15',
        )}
      >
        <View
          style={TailwindResponsive(
            'flex flex-col pl-5 py-300px w-300px mob:p-0 mobWeb:pl-0 mobWeb:w-full',
          )}
        >
          <View style={TailwindResponsive('flex flex-row justify-center')}>
            <GraphikTextMd>
              Are you sure you want to transfer title?
            </GraphikTextMd>
          </View>
          <View style={TailwindResponsive('flex flex-row web:flex-1 web:py-6')}>
            <View style={{ flex: 1 }}>
              <TextButton
                label="Confirm"
                onPress={() => {
                  confirm();
                }}
              />
            </View>
            <View style={{ flex: 1 }}>
              <TextButton
                label="Cancel"
                onPress={() => {
                  cancel();
                }}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
