import { useLazyQuery } from '@apollo/client';
import { GET_CLICKWRAP_FOR_BUYER } from 'api/requests.v2';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { ErrorMessage } from 'components/shared/error';
import { GraphikSemiTextLg } from 'components/shared/styled';
import { SITE_BASE_URL } from 'constants/constants';
import React, { FunctionComponent } from 'react';
import { Linking, Platform, View } from 'react-native';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';
import Styles from 'style';
import { getDisplayName } from 'types/Users';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ViewContract: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const transaction = useGetTransactionDetailsHook();

  const sellerName = getDisplayName(transaction?.seller);
  const buyerName = getDisplayName(transaction?.buyer);
  const CertificationOrSales = transaction?.isRetro
    ? 'Certification Agreement'
    : 'Sales Agreement';

  const wrapperStyle =
    Platform.OS === 'web'
      ? `flex flex-row justify-between pr-14 mb-2 w-1/2 mobWeb:w-full mobWeb:pr-0 mobWeb:flex-col mob:flex-col mob:w-full`
      : 'self-start items-start';

  const [
    getClickwrapForBuyer,
    {
      error: getClickwrapSellerSignURLError,
      loading: loadingClickwrapSellerSign,
    },
  ] = useLazyQuery(GET_CLICKWRAP_FOR_BUYER, {
    onCompleted: ({ response }) => {
      if (response) {
        Linking.openURL(response);
      }
    },
    fetchPolicy: 'network-only',
  });

  const error = getClickwrapSellerSignURLError;

  if (error) return <ErrorMessage error={error as Error} />;

  return (
    <View style={TailwindResponsive(wrapperStyle)}>
      {Platform.OS === 'web' && (
        <GraphikSemiTextLg lineHeight="0px" color={Styles.Colours.Dark1}>
          {!transaction?.isBuyerView
            ? `${CertificationOrSales} to ${buyerName}`
            : `${CertificationOrSales} from ${sellerName}`}
        </GraphikSemiTextLg>
      )}

      {transaction?.canSeeContract && (
        <FairchainButton
          buttonStyle={TailwindResponsive(
            `flex justify-center h-6 mobWeb:mt-2 mobWeb:mb-1`,
          )}
          label="View Contract"
          loading={loadingClickwrapSellerSign}
          loadingStyle={TailwindResponsive(`w-24`)}
          onPress={() => {
            if (!transaction?.envelopeId) {
              if (Platform.OS === 'web') {
                Linking.openURL(
                  `${SITE_BASE_URL}/load-contract/${transaction.id}`,
                );
              } else {
                getClickwrapForBuyer({
                  variables: { transactionId: transaction?.id },
                });
              }
            } else {
              Linking.openURL(
                `${SITE_BASE_URL}/load-contract/${transaction.id}`,
              );
            }
          }}
          textStyle={{
            ...TailwindResponsive(`text-Primary`),
            fontFamily: Styles.Fonts.FontFamily.SansRegular,
            fontSize:
              Platform.OS === 'web'
                ? `${Styles.Fonts.FontSize.Small}px`
                : Styles.Fonts.FontSize.Small,
            lineHeight:
              Platform.OS === 'web'
                ? `${Styles.Fonts.LineHeights.Small}px`
                : Styles.Fonts.LineHeights.Small,
          }}
        />
      )}
    </View>
  );
};

export default ViewContract;
