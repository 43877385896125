import { useRoute } from '@react-navigation/native';
import { DocPicker } from 'components/shared/docPicker/DocPicker';
import { GraphikTextSm } from 'components/shared/styled';
import { RegisterWorkRouteProp } from 'navigation';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { RegisterStore } from 'store/registerWork/apollo';
import { SupportingDoc } from 'types/SupportingDocs';
import { deleteImageAsync, uploadDataUriAsync } from 'utilities/Firebaseutil';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const SupportingDocs: FunctionComponent = () => {
  const route = useRoute<RegisterWorkRouteProp>();
  const { TailwindResponsive } = useTailwindResponsive();

  const handleDeleteDoc = async (doc: SupportingDoc) => {
    const path = `temp-support-docs/${route.params.draftId}/${doc.fileName}`;
    await deleteImageAsync(path);
  };

  const handleDocPicker = async (doc: SupportingDoc) => {
    // Lets save our document
    const fileName = `temp-support-docs/${route.params.draftId}/${doc.fileName}`;
    await uploadDataUriAsync(doc.uri, fileName);
  };

  return (
    <View>
      <View
        style={TailwindResponsive('web:w-4/5 mb-8')}
        testID="SupportingDocsUploadInfoWrapper"
      >
        <GraphikTextSm>
          You can upload up to four documents associated with this work. You may
          wish to attach invoices, condition reports, or exhibition documents.
          You can choose which of these documents will transfer to future
          buyers.
        </GraphikTextSm>
      </View>

      <View
        style={TailwindResponsive(
          'flex-row flex-wrap content-between mb-12 web:p-4 mob:-mt-2',
        )}
      >
        <DocPicker
          deleteHandler={handleDeleteDoc}
          index={0}
          pickHandler={handleDocPicker}
          supportingDocsVar={RegisterStore.Vars.supportingDocsVar}
        />
        <DocPicker
          deleteHandler={handleDeleteDoc}
          index={1}
          pickHandler={handleDocPicker}
          supportingDocsVar={RegisterStore.Vars.supportingDocsVar}
        />
        <DocPicker
          deleteHandler={handleDeleteDoc}
          index={2}
          pickHandler={handleDocPicker}
          supportingDocsVar={RegisterStore.Vars.supportingDocsVar}
        />
        <DocPicker
          deleteHandler={handleDeleteDoc}
          index={3}
          pickHandler={handleDocPicker}
          supportingDocsVar={RegisterStore.Vars.supportingDocsVar}
        />
      </View>
    </View>
  );
};
