import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import Styles from 'style';
import { ISVGProps } from './Types';

export const CheckOnlyIcon: FunctionComponent<ISVGProps> = ({
  color,
  width,
}) => {
  const iconWidth = width || 48;

  return (
    <Svg
      viewBox="0 0 48 48"
      width={iconWidth}
      fill={color || Styles.Colours.Dark1}
      height={iconWidth}
    >
      <Path d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
    </Svg>
  );
};

export default CheckOnlyIcon;
