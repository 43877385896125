import { useMutation } from '@apollo/client';
import { REMOVE_EXPO_PUSH_TOKEN } from 'api/requests.v2';
import { signOut } from 'firebase/auth';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { userDisconnect } from 'store/user';
import { FCAnalyticEvents, getFBAuth, logAnalyticsEvent } from './Firebaseutil';

export const useLogout = (setLogoutError?: (string) => void) => {
  const dispatch = useDispatch();
  const firebaseAuth = getFBAuth();
  const [removeExpoPushToken] = useMutation(REMOVE_EXPO_PUSH_TOKEN);

  const logout = useCallback(
    async (event) => {
      if (event.key === 'Enter' || event === 0) {
        try {
          const userId = firebaseAuth.currentUser?.uid;
          firebaseAuth.currentUser?.getIdToken(true);
          await removeExpoPushToken({ variables: { userId } });
          await signOut(firebaseAuth);
          dispatch(userDisconnect());
          logAnalyticsEvent(FCAnalyticEvents.UserSignOut, { uid: userId });
        } catch (caughtError) {
          if (setLogoutError) setLogoutError((caughtError as Error).message);
        }
      }
    },
    [dispatch, firebaseAuth, removeExpoPushToken, setLogoutError],
  );
  return logout;
};
