import { gql, makeVar, useQuery } from '@apollo/client';
import { AgentType } from 'constants/enums';

export const agentEmailVar = makeVar<string>('');
export const agentNameVar = makeVar<string>('');
export const agentTypeVar = makeVar<AgentType>(AgentType.gift);
export const isAgentVar = makeVar<boolean>(false);

export const agentBuyerTypeDefs = gql`
  extend type Query {
    agentEmail: String!
    agentName: String!
    agentType: String!
    isAgent: Boolean!
  }
`;

export interface AgentInfo {
  agentType: string;
  isAgent: boolean;
  email: string;
  name: string;
}

const defaultAgentInfo: AgentInfo = {
  agentType: '',
  isAgent: false,
  email: '',
  name: '',
};

const GET_AGENT_INFO = gql`
  query GetAgentInfo {
    agentType @client
    isAgent @client
    email: agentEmail @client
    name: agentName @client
  }
`;

export const useGetAgentInfo = (): AgentInfo => {
  const { data } = useQuery(GET_AGENT_INFO);
  return data || defaultAgentInfo;
};

export const resetAgentInfo = () => {
  agentEmailVar('');
  agentNameVar('');
  agentTypeVar(AgentType.gift);
  isAgentVar(false);
};

export const agentInfoFields = {
  agentEmail: {
    read() {
      return agentEmailVar();
    },
  },
  agentName: {
    read() {
      return agentNameVar();
    },
  },
  agentType: {
    read() {
      return agentTypeVar();
    },
  },
  isAgent: {
    read() {
      return isAgentVar();
    },
  },
};
