import { AddIcon } from 'components/graphics';
import { SpinLoader } from 'components/shared/loader';
import { useUploadTempImage } from 'components/views/registerWork/Hooks';
import { ImageSelectionPage } from 'constants/enums';
import * as ExpoImagePicker from 'expo-image-picker';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Alert, Platform, Pressable, View } from 'react-native';
import * as RNP from 'react-native-url-polyfill';
import { useSelector } from 'react-redux';
import { IImageKey } from 'store/registerWork';
import { getUser } from 'store/user';
import { IImagePickerResponse } from 'types/Images';
import {
  b64StringtoBlob,
  extractExtensionFromB64Data,
  logError,
} from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import * as uuid from 'uuid';
import { GraphikTextTiny } from '../styled';
import { ImageDisplay } from './components/ImageDisplay';

interface IImagePickerProps {
  image: string | undefined;
  imageKey?: IImageKey;
  imageSelectionHandler: (image: IImagePickerResponse) => void;
  page: number;
}

export const ImagePicker: FunctionComponent<IImagePickerProps> = ({
  image,
  imageKey,
  imageSelectionHandler,
  page,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const uploadImage = useUploadTempImage();

  const user = useSelector(getUser);
  const imageId = uuid.v4();
  const [loading, setLoading] = useState(false);
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const [pickerError, setError] = useState('');

  useEffect(() => {
    (async () => {
      const { status } = await ExpoImagePicker.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web' && hasPermission === false) {
        // eslint-disable-next-line no-alert
        alert('Sorry, we need camera permissions to make this work!');
      }
    })();
  }, [hasPermission]);

  const selectImage = () =>
    Alert.alert(
      'Select Image',
      '',
      [
        {
          text: 'Take a Photo',
          onPress: () => takeImage(),
        },
        {
          text: 'Choose From Library',
          onPress: () => pickImage(),
        },
        {
          text: 'Cancel',
          style: 'cancel',
        },
      ],
      {
        cancelable: true,
        onDismiss: () =>
          Alert.alert(
            'This alert was dismissed by tapping outside of the alert dialog.',
          ),
      },
    );

  const pickImage = async () => {
    try {
      if (Platform.OS === 'ios') {
        setLoading(true);
      }

      const result = await ExpoImagePicker.launchImageLibraryAsync({
        base64: true,
        mediaTypes: ExpoImagePicker.MediaTypeOptions.All,
        quality: 1,
      });

      if (!result.canceled && user) {
        setLoading(true); // for web
        const asset = result.assets[0];
        if (!asset) throw new Error('No image selected.');

        const base64 = asset.base64
          ? asset.base64
          : asset.uri.split('base64,')[1];
        const blob = b64StringtoBlob(base64);
        const blobUrl =
          Platform.OS === 'web'
            ? URL.createObjectURL(blob)
            : RNP.URL.createObjectURL(blob);
        const returnImageObject: IImagePickerResponse = {
          id: imageId,
          ext: extractExtensionFromB64Data(asset.uri || ''),
          filePath: blobUrl,
          localURI: asset.uri || '',
        };
        const path =
          page === ImageSelectionPage.Admin ? 'artist-signatures/' : '';
        const remoteUrl = await uploadImage(returnImageObject, path);
        returnImageObject.remoteURI = remoteUrl;
        imageSelectionHandler(returnImageObject);
      }
    } catch (error: any) {
      logError(error, `Error in picking image`);
      setError('Error in picking image');
    } finally {
      setLoading(false);
    }
  };

  const takeImage = async () => {
    if (Platform.OS === 'ios') {
      setTimeout(() => {
        setLoading(true);
      }, 300);
    }

    const result: any = await ExpoImagePicker.launchCameraAsync({
      base64: true,
      mediaTypes: ExpoImagePicker.MediaTypeOptions.All,
      quality: 1,
    });

    setLoading(true);

    try {
      result.base64 = result.base64
        ? result.base64
        : result.uri.split('base64,')[1];
      if (!result.canceled && user) {
        const returnImageObject: IImagePickerResponse = {
          id: imageId,
          filePath: URL.createObjectURL(b64StringtoBlob(result.base64)),
          localURI: result.uri,
        };
        imageSelectionHandler(returnImageObject);
      }
    } catch (error) {
      logError(error, `Error in taking image.`);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <View
        style={TailwindResponsive('mob:h-44 mob:w-1/2 mob:p-2 mobWeb:mb-8')}
      >
        <View
          style={TailwindResponsive(
            'border border-Dark1 web:mr-5 w-52 h-52 mob:w-full mob:h-full',
          )}
        >
          {loading && <SpinLoader />}
        </View>
      </View>
    );
  }

  return (
    <View style={TailwindResponsive('mob:h-44 mob:w-1/2 mob:p-2 mobWeb:mb-8')}>
      {!!image && (
        <ImageDisplay image={image} imageKey={imageKey} page={page} />
      )}

      {!image && (
        <Pressable
          onPress={() => {
            if (Platform.OS === 'web') {
              pickImage();
            } else {
              selectImage();
            }
          }}
          style={TailwindResponsive(
            'items-center justify-center border border-Dark1 web:mr-5 w-52 h-52 mob:w-full mob:h-full',
          )}
          testID={`ImagePicker_${imageKey}_Selector`}
        >
          <View style={TailwindResponsive('flex-row h-8')}>
            <AddIcon width={30} />
          </View>
        </Pressable>
      )}

      {!!pickerError && (
        <GraphikTextTiny style={TailwindResponsive(`text-red-600 mt-2`)}>
          {pickerError}
        </GraphikTextTiny>
      )}
    </View>
  );
};
