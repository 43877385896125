/* Copyright (C) 2021 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */

import React, { FunctionComponent } from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

interface LogoProps {
  color?: string;
  height?: string;
  width?: string;
}

export const FairchainLogo: FunctionComponent<LogoProps> = ({
  color,
  height,
  width,
}) => (
  <Svg
    id="RGB_Fairchain-Black"
    width={width || '142.503'}
    height={height || '18'}
    viewBox="0 0 142.503 18"
    fill={color || 'black'}
  >
    <Path
      id="Path_77"
      data-name="Path 77"
      d="M438.488,1.451A7.252,7.252,0,0,1,445.044,5.1h1.662A8.7,8.7,0,0,0,438.488,0a9.418,9.418,0,0,0-6.328,2.317,8.8,8.8,0,0,0-2.842,5.642h1.711A7.284,7.284,0,0,1,438.488,1.451Z"
      transform="translate(-371.484 0)"
    />
    <Rect
      id="Rectangle_1041"
      data-name="Rectangle 1041"
      width="1.702"
      height="7.608"
      transform="translate(80.338 0.352)"
    />
    <Path
      id="Path_78"
      data-name="Path 78"
      d="M309.6,2.614h-6.535v7.607h1.7V4.04H309.4c2.785,0,4.657,1.66,4.657,4.131a4.156,4.156,0,0,1-.517,2.051h1.806a5.444,5.444,0,0,0,.388-2.051C315.736,4.951,313.157,2.614,309.6,2.614Z"
      transform="translate(-262.241 -2.262)"
    />
    <Path
      id="Path_79"
      data-name="Path 79"
      d="M128.348,10.221h1.581l-3.2-7.607H125.28l-3.2,7.607h1.576l2.346-5.866Z"
      transform="translate(-105.639 -2.262)"
    />
    <Rect
      id="Rectangle_1042"
      data-name="Rectangle 1042"
      width="1.702"
      height="7.608"
      transform="translate(32.93 0.352)"
    />
    <Rect
      id="Rectangle_1043"
      data-name="Rectangle 1043"
      width="1.702"
      height="7.608"
      transform="translate(92.738 0.352)"
    />
    <Rect
      id="Rectangle_1044"
      data-name="Rectangle 1044"
      width="1.551"
      height="7.608"
      transform="translate(140.951 0.352)"
    />
    <Path
      id="Path_80"
      data-name="Path 80"
      d="M956.8,10.221l-6.046-7.607h-1.33v7.607h1.551V5.159L955,10.221Z"
      transform="translate(-821.524 -2.262)"
    />
    <Rect
      id="Rectangle_1045"
      data-name="Rectangle 1045"
      width="1.702"
      height="7.608"
      transform="translate(120.001 0.352)"
    />
    <Path
      id="Path_81"
      data-name="Path 81"
      d="M10.329,2.614H0v7.607H1.7V4.065h8.627Z"
      transform="translate(0 -2.262)"
    />
    <Rect
      id="Rectangle_1046"
      data-name="Rectangle 1046"
      width="1.702"
      height="7.608"
      transform="translate(120.001 10.04)"
    />
    <Path
      id="Path_82"
      data-name="Path 82"
      d="M1010.458,82.141V74.533h-1.551V79.6l-4.023-5.062h-1.8l6.046,7.608Z"
      transform="translate(-867.955 -64.493)"
    />
    <Rect
      id="Rectangle_1047"
      data-name="Rectangle 1047"
      width="1.551"
      height="7.608"
      transform="translate(127.898 10.04)"
    />
    <Path
      id="Path_83"
      data-name="Path 83"
      d="M610.475,82.141V74.533h-14.1v7.608h1.7V75.988h10.7v6.153Z"
      transform="translate(-516.035 -64.493)"
    />
    <Rect
      id="Rectangle_1048"
      data-name="Rectangle 1048"
      width="1.702"
      height="7.608"
      transform="translate(32.93 10.04)"
    />
    <Path
      id="Path_84"
      data-name="Path 84"
      d="M10.1,74.533H0v7.608H1.7V75.984h8.4Z"
      transform="translate(0 -64.493)"
    />
    <Path
      id="Path_85"
      data-name="Path 85"
      d="M445.044,77.4a7.6,7.6,0,0,1-14.015-2.862h-1.711a8.8,8.8,0,0,0,2.842,5.643,9.418,9.418,0,0,0,6.328,2.317,8.7,8.7,0,0,0,8.217-5.1Z"
      transform="translate(-371.485 -64.493)"
    />
    <Path
      id="Path_86"
      data-name="Path 86"
      d="M103.628,75.988l2.483,6.153h1.75l-3.2-7.608H95.073l-3.2,7.608h1.75L96.1,75.988Z"
      transform="translate(-79.501 -64.493)"
    />
    <Path
      id="Path_87"
      data-name="Path 87"
      d="M313.977,82.143h2.134L310.8,75.868l.252-.054a6.509,6.509,0,0,0,2.747-1.278H303.068v7.608h1.7V75.961h4.051Z"
      transform="translate(-262.241 -64.495)"
    />
    <Path
      id="Path_88"
      data-name="Path 88"
      d="M772.7,10.221h1.581l-3.2-7.607h-1.454l-3.2,7.607h1.576l2.346-5.866Z"
      transform="translate(-663.19 -2.262)"
    />
    <Path
      id="Path_89"
      data-name="Path 89"
      d="M747.982,75.988l2.483,6.153h1.75l-3.2-7.608h-9.592l-3.2,7.608h1.75l2.476-6.153Z"
      transform="translate(-637.053 -64.493)"
    />
  </Svg>
);

export default FairchainLogo;
