import { useLazyQuery, useMutation } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import {
  BUYER_ACCEPT_CLICKWRAP,
  GET_BUYER_SIGNLINK,
  UPDATE_AGREED_TO_TERMS,
} from 'api/requests.v2';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { ErrorMessage } from 'components/shared/error';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useGetAgentInfo } from 'store/agentBuyer/apollo';
import { getAgreedToTerms } from 'store/agreements';
import { GeneralApollo } from 'store/general/apollo-nav';
import TDGQL, {
  useGetTransactionDetailsHook,
} from 'store/transactionDetails/apollo';
import {
  getContractAccepted,
  getContractLoading,
  getContractSignDate,
  getContractSignTimezone,
} from 'store/transferWork';
import { getUserId, userUpdateFirstLogin } from 'store/user';
import Styles from 'style';
import { getUserAgentInfo } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import CancelTransaction from './CancelTransaction';

export const AcceptAndCancel: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { TailwindResponsive } = useTailwindResponsive();
  const route = useRoute();

  const transaction = useGetTransactionDetailsHook();

  const agentInfo = useGetAgentInfo();

  const agreedToTerms = useSelector(getAgreedToTerms);
  const contractAccepted = useSelector(getContractAccepted);
  const contractLoading = useSelector(getContractLoading);
  const contractSignDate = useSelector(getContractSignDate);
  const contractSignTimezone = useSelector(getContractSignTimezone);
  const userId = useSelector(getUserId);

  const isAgreements = route.name === 'Agreements';

  const [updateAgreedToTerms] = useMutation(UPDATE_AGREED_TO_TERMS, {
    onCompleted: async (didUpdate) => {
      if (didUpdate && didUpdate.response.success) {
        if (transaction?.isClickwrap) {
          setIsBuyerAcceptingClickwrap(true);
          await buyerAcceptClickwrap({
            variables: {
              input: {
                contractSignDate,
                contractSignTimezone,
                transactionId: transaction?.id,
                userAgentInfo: getUserAgentInfo(),
                agentType: agentInfo.isAgent ? agentInfo.agentType : '',
                agentBuyerEmail: agentInfo.email,
                agentBuyerName: agentInfo.name,
              },
            },
          });
        } else {
          await getBuyerSignURL({
            variables: {
              transactionId: transaction?.id,
              isMobile: false,
              agentType: agentInfo.isAgent ? agentInfo.agentType : '',
              agentBuyerEmail: agentInfo.email,
              agentBuyerName: agentInfo.name,
            },
          });
        }
      }
    },
  });

  const handleUpdateAgreedToTerms = async () => {
    const variables = {
      userId,
      agreedTimeStamp: new Date().toISOString(),
      agreedTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    await updateAgreedToTerms({ variables });
  };

  const buttonDisabled = isAgreements
    ? (agentInfo.isAgent && (!agentInfo.name || !agentInfo.email)) ||
      (transaction?.isClickwrap && !contractAccepted) ||
      !agreedToTerms
    : (agentInfo.isAgent && (!agentInfo.name || !agentInfo.email)) ||
      (transaction?.isClickwrap && !contractAccepted);

  const [isAccepted, setIsAccepted] = useState(false);
  const [isBuyerAcceptingClickwrap, setIsBuyerAcceptingClickwrap] =
    useState(false);

  const [
    getBuyerSignURL,
    {
      data: buyerSignURL,
      loading: getBuyerSignURLLoading,
      error: getBuyerSignURLError,
    },
  ] = useLazyQuery(GET_BUYER_SIGNLINK, {
    onCompleted: () => {
      if (buyerSignURL && buyerSignURL.response) {
        window.location.href = buyerSignURL.response;
      }
    },
    fetchPolicy: 'network-only',
  });

  const [buyerAcceptClickwrap, { loading: buyerAcceptClickwrapLoading }] =
    useMutation(BUYER_ACCEPT_CLICKWRAP, {
      onCompleted: (data) => {
        if (data) {
          TDGQL.clickwrapAcceptedVar(true);
        }
        setIsBuyerAcceptingClickwrap(false);
        setIsAccepted(true);
        if (isAgreements) {
          GeneralApollo.navTransactionId(transaction?.id);
          dispatch(userUpdateFirstLogin(false));
        }
      },
    });

  const handleAcceptAgreement = async () => {
    if (isAgreements) {
      handleUpdateAgreedToTerms();
    }
    if (!isAgreements) {
      if (transaction?.isClickwrap) {
        setIsBuyerAcceptingClickwrap(true);
        buyerAcceptClickwrap({
          variables: {
            input: {
              contractSignDate,
              contractSignTimezone,
              transactionId: transaction?.id,
              userAgentInfo: getUserAgentInfo(),
              agentType: agentInfo.isAgent ? agentInfo.agentType : '',
              agentBuyerEmail: agentInfo.email,
              agentBuyerName: agentInfo.name,
            },
          },
        });
      } else {
        getBuyerSignURL({
          variables: {
            transactionId: transaction?.id,
            isMobile: false,
            agentType: agentInfo.isAgent ? agentInfo.agentType : '',
            agentBuyerEmail: agentInfo.email,
            agentBuyerName: agentInfo.name,
          },
        });
      }
    }
  };

  const acceptButtonLoading = useMemo(
    () =>
      getBuyerSignURLLoading ||
      !!buyerSignURL ||
      buyerAcceptClickwrapLoading ||
      isBuyerAcceptingClickwrap,
    [
      getBuyerSignURLLoading,
      buyerSignURL,
      buyerAcceptClickwrapLoading,
      isBuyerAcceptingClickwrap,
    ],
  );

  if (getBuyerSignURLError) {
    return <ErrorMessage />;
  }

  if (isAccepted) return null;

  return (
    <View
      style={TailwindResponsive(
        'mb-50px w-full flex flex-row items-center justify-between mobWeb:flex-col',
      )}
    >
      <FairchainButton
        buttonStyle={TailwindResponsive(
          `bg-Primary items-start pl-6 rounded-full w-64 mobWeb:items-center`,
        )}
        disabled={buttonDisabled}
        label={transaction?.isRetro ? 'Accept Certificate' : 'Accept Agreement'}
        loading={contractLoading || acceptButtonLoading}
        onPress={handleAcceptAgreement}
        spinnerColor={Styles.Colours.Light1}
        textStyle={TailwindResponsive(`text-Light1`)}
      />

      <CancelTransaction
        label={
          transaction?.isRetro ? 'Decline Certificate' : 'Decline Sales Offer'
        }
      />
    </View>
  );
};
