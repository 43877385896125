import { useNavigation, useRoute } from '@react-navigation/native';
import { CollectionTab } from 'constants/enums';
import { FCStackNavProp, NativeStackParamList } from 'navigation';
import React, { FunctionComponent, ReactNode } from 'react';
import { Pressable, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { setCollectionDisplay } from 'store/collection';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { GraphikSemiTextXTiny } from '../styled';

interface IAppBarIconProps {
  children?: ReactNode;
  onPress?: () => void;
  screen?: keyof NativeStackParamList;
  title: string;
}

export const AppBarIcon: FunctionComponent<IAppBarIconProps> = ({
  children,
  onPress,
  screen,
  title,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const navigation = useNavigation<FCStackNavProp>();
  const route = useRoute();
  const dispatch = useDispatch();
  const currentRoute = route.name;

  return (
    <Pressable
      onPress={() => {
        dispatch(setCollectionDisplay(CollectionTab.Current));
        if (screen && currentRoute !== screen) {
          navigation.push(screen);
        } else if (onPress) onPress();
      }}
    >
      <View
        style={TailwindResponsive(
          'flex flex-col h-14 items-center justify-center w-14',
        )}
      >
        <View style={TailwindResponsive(`h-6 m-4 w-6 items-center`)}>
          {children}
        </View>
        <View style={TailwindResponsive('flex')}>
          <GraphikSemiTextXTiny
            color={
              currentRoute === screen
                ? Styles.Colours.Dark1
                : Styles.Colours.Gray2
            }
          >
            {title}
          </GraphikSemiTextXTiny>
        </View>
      </View>
    </Pressable>
  );
};
