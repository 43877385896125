import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { FCStackNavProp } from 'navigation';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GeneralApollo } from 'store/general/apollo-nav';
import { getUser } from 'store/user';

export const useScreenRedirect = () => {
  const navigation = useNavigation<FCStackNavProp>();
  const navTransId = useReactiveVar(GeneralApollo.navTransactionId);
  const userInfo = useSelector(getUser);

  useEffect(() => {
    if (navTransId && userInfo && !userInfo.firstLogin) {
      GeneralApollo.navTransactionId('');
      navigation.navigate('TransactionDetail', {
        transactionId: navTransId,
      });
    }
  }, [navigation, navTransId, userInfo]);
};
