import { ImageIcon } from 'components/graphics';
import { FairchainImage } from 'components/shared/image';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import { UserContext } from 'contexts';
import React, { FunctionComponent, useContext } from 'react';
import { Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { sortingByDate } from 'store/collection';
import Styles from 'style';
import { Work, getDisplayName } from 'types';
import { getResponsiveSizes } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useCorrectConsignment } from '../../artworkDetails/hooks';

interface IConsignmentLineItemProp {
  id?: number;
  index?: number;
  work: Work;
}

export const ConsignmentLineItem: FunctionComponent<
  IConsignmentLineItemProp
> = ({ id, index, work }) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const authUser = useContext(UserContext);
  const isByDate = useSelector(sortingByDate);

  const { mobWeb } = getResponsiveSizes();
  const boldStyle = {
    fontSize: Styles.Fonts.FontSize.Small,
    fontFamily: Styles.Fonts.FontFamily.SansMedium,
  };
  const columnMediumStyle = {
    flex: 2,
    pointerEvents: 'auto',
  };
  const columnLargeStyle = {
    flex: 3,
    pointerEvents: 'auto',
  };

  const correctConsignment = useCorrectConsignment(work, authUser?.uid);

  const rowDisplayStyle = index! > 2 ? 'hidden' : 'flex';
  const displayBorder = id === 0 ? '' : 'border-t border-Gray1';

  return (
    <View
      style={TailwindResponsive(
        `${rowDisplayStyle} ${displayBorder} flex-row h-24 items-center`,
      )}
    >
      <View style={TailwindResponsive(`w-20 mobWeb:w-7`)} />

      <View style={TailwindResponsive(`w-40 mobWeb:max-w-min mobWeb:mr-5`)}>
        <ExternalLink link={`/details/${work.id}`}>
          {work.images && work.images[0]?.startsWith('https://') ? (
            <FairchainImage uri={work.images[0]!} width={40} />
          ) : (
            <ImageIcon width={40} />
          )}
        </ExternalLink>
      </View>

      <View
        style={TailwindResponsive(
          `flex-1 mobWeb:max-w-min mobWeb:mx-5 mobWeb:hidden`,
        )}
      >
        <ExternalLink link={`/details/${work.id}`}>
          <Text style={boldStyle}>
            {getDisplayName(work.artist) || 'No artist name'}
          </Text>
        </ExternalLink>
      </View>

      {mobWeb ? (
        <View style={TailwindResponsive(`flex-1`)}>
          {isByDate && (
            <View style={[columnMediumStyle]}>
              <ExternalLink link={`/details/${work.id}`}>
                <Text style={boldStyle}>
                  {getDisplayName(work.artist) || 'No artist name'}
                </Text>
              </ExternalLink>
            </View>
          )}
          <View style={columnLargeStyle}>
            <ExternalLink link={`/details/${work.id}`}>
              <Text style={boldStyle}>{work.title}</Text>
            </ExternalLink>
          </View>
          <View style={TailwindResponsive(`w-24`)}>
            <ExternalLink link={`/details/${work.id}`}>
              <Text style={boldStyle}>{work.yearProduced}</Text>
            </ExternalLink>
          </View>
        </View>
      ) : (
        <>
          <View style={TailwindResponsive(`flex-1`)}>
            <ExternalLink link={`/details/${work.id}`}>
              <Text style={boldStyle}>{work.title}</Text>
            </ExternalLink>
          </View>
          <View style={TailwindResponsive(`flex-1`)}>
            <ExternalLink link={`/details/${work.id}`}>
              <Text style={boldStyle}>{work.yearProduced}</Text>
            </ExternalLink>
          </View>
          <View style={TailwindResponsive(`flex-1`)}>
            <ExternalLink link={`/details/${work.id}`}>
              <Text style={boldStyle}>{correctConsignment!.consignee}</Text>
            </ExternalLink>
          </View>
          <View style={TailwindResponsive(`flex-1`)}>
            <ExternalLink link={`/details/${work.id}`}>
              <Text style={boldStyle}>
                {new Date(correctConsignment!.consignedDate).toDateString()}
              </Text>
            </ExternalLink>
          </View>
        </>
      )}
    </View>
  );
};
