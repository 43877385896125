import { DeviceType } from 'expo-device';
import { GeneralRootState } from 'store/types';
import { IFeatureFlags, INotifications } from 'types';

export const getDeviceType = (store: GeneralRootState): DeviceType =>
  store.general.deviceType;

export const getFeatureFlags = (store: GeneralRootState): IFeatureFlags =>
  store.general.featureFlags;

export const getNotifications = (store: GeneralRootState): INotifications =>
  store.general.notifications;

export const getRedirectURL = (store: GeneralRootState): string | null =>
  store.general.redirectUrl;
