import {
  GraphikSemiTextSm,
  GraphikTextSm,
  GraphikTextTiny,
} from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { DetailsLineItemTitleStyle, IDetailsLineItemProps } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const DetailsLineItem: FunctionComponent<IDetailsLineItemProps> = ({
  children,
  text,
  title,
  titleStyle,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  let TitleComponent;
  if (titleStyle === DetailsLineItemTitleStyle.regular)
    TitleComponent = GraphikTextSm;
  else TitleComponent = GraphikSemiTextSm;

  return (
    <View style={TailwindResponsive(`flex flex-row mb-12 mobWeb:flex-col`)}>
      <View style={TailwindResponsive(`flex-1 pr-4`)}>
        <TitleComponent style={TailwindResponsive(`mb-2`)}>
          {title}
        </TitleComponent>
        <GraphikTextTiny>{text}</GraphikTextTiny>
      </View>

      <View style={TailwindResponsive(`flex-1 mobWeb:mt-2`)}>{children}</View>
    </View>
  );
};

export default DetailsLineItem;
