import { useMutation, useReactiveVar } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { REGISTER_WORK } from 'api/requests.v2';
import { RoundedButton } from 'components/shared/button';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import HeaderVars from 'components/shared/header/apollo.header';
import { useSubscriptionActive } from 'components/shared/header/hooks';
import { AssetType, RegistrationType } from 'constants/enums';
import { FCStackNavProp, RegisterWorkRouteProp } from 'navigation';
import React, { FunctionComponent, useEffect } from 'react';
import { Platform, View } from 'react-native';
import { useSelector } from 'react-redux';
import {
  getDiminishedRoyalties,
  getDiminishedRoyaltiesErrors,
} from 'store/forms';
import { RegisterStore } from 'store/registerWork/apollo';
import Styles from 'style';
import { FCAnalyticEvents, logAnalyticsEvent } from 'utilities';
import LogError, { logError } from 'utilities/LogError';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { scrollToTop } from '../Helpers';
import { useGetFinalForm, useGetImages, useResetAll } from '../Hooks';
import {
  ARTWORK_FIRST_PAGE_TRIGGER,
  COLLECTIBLE_FIRST_PAGE_TRIGGER,
  useGetFormDetails,
} from '../forms/artist-registration.form';
import { useAutoSave } from '../hooks.auto-save';
import RWAutoSave from './AutoSave';
import { CancelRWModal } from './CancelModal';

export const RWFooter: FunctionComponent = () => {
  const { handleSave } = useAutoSave();
  const navigation = useNavigation<FCStackNavProp>();
  const route = useRoute<RegisterWorkRouteProp>();
  const { TailwindResponsive } = useTailwindResponsive();

  const subActive = useSubscriptionActive();

  const finalForm = useGetFinalForm();

  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const arForm = useGetFormDetails(detailsForm);
  const assetType = detailsForm?.assetType;
  const currentStep = useReactiveVar(RegisterStore.Vars.currentStep);
  const images = useGetImages();
  const keyboardScrollRef = useReactiveVar(
    RegisterStore.Vars.keyboardScrollRef,
  );
  const primaryImageKey = useReactiveVar(RegisterStore.Vars.Images.primary);
  const registrationType = useReactiveVar(RegisterStore.Vars.registrationType);
  const royalForm = useReactiveVar(RegisterStore.Vars.royaltyForm);
  const supportingDocs = useReactiveVar(RegisterStore.Vars.supportingDocsVar);

  const diminishedRoyalties = useSelector(getDiminishedRoyalties);
  const diminishedRoyaltyErrors = useSelector(getDiminishedRoyaltiesErrors);

  const resetEverything = useResetAll();

  let registerButtonText;

  if (detailsForm?.assetType === AssetType.Collectible) {
    registerButtonText = 'Register Item';
  } else if (detailsForm?.assetType === AssetType.Artwork) {
    registerButtonText = 'Register Artwork';
  } else {
    registerButtonText = 'Register Work';
  }

  const [registerNewWork, { error: registerWorkError }] =
    useMutation(REGISTER_WORK);

  useEffect(() => {
    if (registerWorkError) {
      LogError.logError(
        `Error in Registering Work: ${registerWorkError.message} | Info: ${registerWorkError.extraInfo}`,
      );
    }
  }, [registerWorkError]);

  const handleGoBack = () => {
    if (
      registrationType === RegistrationType.Uncertified &&
      currentStep === 3
    ) {
      RegisterStore.Vars.currentStep(1);
    } else {
      RegisterStore.Vars.currentStep(currentStep - 1);
    }

    if (Platform.OS === 'web')
      window.scroll({ top: 0, left: 0, behavior: 'auto' });
    // scrollRef?.current?.scrollTo({ x: 0, y: 0 });
  };

  const handleGoNext = async () => {
    keyboardScrollRef?.current?.scrollToPosition(0, 0);
    if (currentStep === 3) {
      if (subActive) {
        onSubmit();
      } else {
        HeaderVars.showNeedSubModal(true);
      }
    } else {
      validateSubsection();
    }
  };

  const onSubmit = async () => {
    try {
      RegisterStore.Vars.loading(true);
      const permImages = Object.entries(images).reduce(
        (acc: string[], [key, image]) => {
          if (image) {
            const imagePath = image.id + image.ext;
            if (key === primaryImageKey) acc.unshift(imagePath);
            else acc.push(imagePath);
          }

          return acc;
        },
        [],
      );

      const correctRoyalty = diminishedRoyalties.find(
        (royalty) => royalty?.workId === 'RegisterWork',
      );

      const supportingDocsInput = await Promise.all(
        supportingDocs.map(async (doc) => ({
          fileName: doc.fileName,
          transferable: doc.transferable,
        })),
      );

      const isCollectible = detailsForm?.assetType === AssetType.Collectible;

      const collectibleInput = isCollectible
        ? {
            materials: finalForm?.materials,
            measurements: finalForm?.measurements,
            rfid: '',
            contributors: finalForm?.contributors,
          }
        : {};

      const { data } = await registerNewWork({
        variables: {
          registerWorkInput: {
            additionalInfo: finalForm?.additionalInfo,
            artistId: finalForm?.id,
            artistName: finalForm?.artistName,
            artistRoyalty: finalForm?.artistRoyalty,
            assetType: detailsForm?.assetType,
            brandName: finalForm?.brandName,
            brandRoyalty: finalForm?.brandRoyalty,
            contributorRoyalties: finalForm?.contributorRoyalties,
            collectibleInfo: collectibleInput,
            diminishedArtistValue: correctRoyalty?.doesDiminish
              ? correctRoyalty?.diminishedArtistValue
              : '',
            diminishedGalleryValue: correctRoyalty?.doesDiminish
              ? correctRoyalty?.diminishedGalleryValue
              : '',
            diminishedDate: correctRoyalty?.doesDiminish
              ? correctRoyalty?.diminishedDate
              : '',
            birthYear: finalForm?.birthYear,
            dateRegistered: finalForm?.dateRegistered,
            dateRegisteredTimezone: finalForm?.dateRegisteredTimezone,
            dimensionUnits: finalForm?.dimensionUnits,
            digitalRepoRights: finalForm?.digitalRepoRights,
            draftId: route.params.draftId,
            editionNumber: finalForm?.editionNumber,
            editionType: finalForm?.editionType,
            editionTotal: finalForm?.editionTotal,
            email: finalForm?.email,
            firstName: finalForm?.firstName,
            galleryRoyalty: finalForm?.galleryRoyalty,
            generateCota: registrationType !== RegistrationType.Uncertified,
            images: permImages,
            inventoryNumber: finalForm?.inventoryNumber,
            isRetro: registrationType === RegistrationType.Retro,
            isEditionWork: finalForm?.isEditionWork,
            lastName: finalForm?.lastName,
            medium: finalForm?.medium,
            height: finalForm?.height,
            width: finalForm?.width,
            depth: finalForm?.depth,
            nationality: finalForm?.nationality,
            numAP: finalForm?.numAP,
            ownerId: finalForm?.ownerId,
            privateNotes: finalForm?.privateNotes,
            purchaseAmount: finalForm?.purchaseAmount,
            purchaseDate: finalForm?.purchaseDate,
            location: finalForm?.location,
            registeredById: finalForm?.registeredById,
            seller: finalForm?.seller,
            supportingDocs: supportingDocsInput,
            title: finalForm?.title,
            totalNumberOfAPs: finalForm?.totalNumberOfAPs,
            videoURL: finalForm?.videoUrl,
            yearProduced: finalForm?.yearProduced,
          },
        },
      });

      if (data && data.response && data.response.success) {
        logAnalyticsEvent(FCAnalyticEvents.RegisteredWork, {
          title: finalForm?.title,
        });

        resetEverything();
        navigation.push('Details', {
          workId: data.response.successMessage,
          wasRegistered: true,
        });
      } else {
        RegisterStore.Vars.registrationError(data.response.errorMessage);
      }
    } catch (error) {
      RegisterStore.Vars.registrationError(
        `An error occured in registering the artwork. Error: ${error}`,
      );
      logError(error);
    } finally {
      RegisterStore.Vars.loading(false);
    }
  };

  const validateSubsection = async () => {
    switch (currentStep) {
      case 0: {
        const resultArtist = await arForm?.trigger(
          assetType === AssetType.Collectible
            ? COLLECTIBLE_FIRST_PAGE_TRIGGER
            : ARTWORK_FIRST_PAGE_TRIGGER,
        );
        if (resultArtist) {
          RegisterStore.Vars.currentStep(currentStep + 1);
          handleSave();
          scrollToTop();
        }
        break;
      }
      case 1: {
        if (Object.values(images).filter((image) => !!image).length > 0) {
          const resultImages = await arForm?.trigger('videoUrl');
          if (resultImages) {
            RegisterStore.Vars.Images.errors(false);

            if (registrationType === RegistrationType.Uncertified) {
              RegisterStore.Vars.currentStep(3);
            } else {
              RegisterStore.Vars.currentStep(currentStep + 1);
            }

            handleSave();
            scrollToTop();
          }
        } else {
          RegisterStore.Vars.Images.errors(true);
          logError('Error uploading images');
        }
        break;
      }
      case 2: {
        const artistRoyalty = royalForm?.getValues('artistRoyalty');
        const brandRoyalty = royalForm?.getValues('brandRoyalty');
        const galleryRoyalty = royalForm?.getValues('galleryRoyalty');
        if (!artistRoyalty) {
          royalForm?.setValue('artistRoyalty', '0');
        }
        if (!galleryRoyalty) {
          royalForm?.setValue('galleryRoyalty', '0');
        }
        if (!brandRoyalty) {
          royalForm?.setValue('brandRoyalty', '0');
        }
        const resultRoyalty = await royalForm?.trigger();
        if (resultRoyalty && !diminishedRoyaltyErrors) {
          RegisterStore.Vars.currentStep(currentStep + 1);
          handleSave();
          scrollToTop();
        }
        break;
      }
      default:
        RegisterStore.Vars.currentStep(currentStep + 1);
        handleSave();
        scrollToTop();
    }
  };

  return (
    <View
      style={{
        ...TailwindResponsive(
          'flex flex-row bottom-0 h-24 justify-between w-full',
        ),
        zIndex: -1,
      }}
    >
      <View style={TailwindResponsive('flex-1 flex flex-row h-24 w-full')}>
        <View style={TailwindResponsive('web:mr-14 mob:mr-3')}>
          <FairchainButton
            label={currentStep === 3 ? 'Exit' : 'Cancel'}
            onPress={() => {
              RegisterStore.Vars.showCancelModal(true);
            }}
            testId="RegisterWorkCancelButton"
            textStyle={TailwindResponsive('text-sm')}
          />
        </View>

        {currentStep > 0 && (
          <View style={TailwindResponsive('web:mr-14 mob:mr-3')}>
            <FairchainButton
              label="Back"
              onPress={handleGoBack}
              testId="RegisterWorkBackButton"
              textStyle={TailwindResponsive('text-sm')}
            />
          </View>
        )}

        <View style={TailwindResponsive('web:w-80 mob:flex-1 mobWeb:w-1/2')}>
          <RoundedButton
            label={currentStep === 3 ? registerButtonText : 'Next'}
            onPress={handleGoNext}
            backgroundColor={
              currentStep === 3 ? Styles.Colours.Primary : Styles.Colours.Dark1
            }
            color={Styles.Colours.Light1}
            testId="RegisterWorkForwardButton"
          />
        </View>
      </View>

      {Platform.OS === 'web' && false && <RWAutoSave />}

      <CancelRWModal />
    </View>
  );
};

export default RWFooter;
