import { FairchainStyle } from 'types';
import Fonts from './fonts';

export const Regular: FairchainStyle = {
  fontFamily: Fonts.FontFamily.SansRegular,
  fontSize: Fonts.FontSize.Small,
  lineHeight: 24,
  letterSpacing: -0.35,
};

const Text = {
  Regular,
};

export default Text;
