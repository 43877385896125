import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { buyerRegistrationFormVar } from 'store/transferWork/apollo';
import { BuyerDefaultValues, IBuyerForm } from '../Helpers';
import { useGetBuyerRegistrationSchema } from '../hooks';

export const useSetupBuyerRegistrationForm = () => {
  const form = useForm<IBuyerForm>({
    defaultValues: BuyerDefaultValues,
    resolver: yupResolver(useGetBuyerRegistrationSchema()),
  });
  buyerRegistrationFormVar(form);
};
