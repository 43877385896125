import { useMutation, useReactiveVar } from '@apollo/client';
import { CANCEL_SUBSCRIPTION } from 'api/requests.v2';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import {
  GraphikMediumTextHuge,
  GraphikSemiTextMd,
  GraphikTextMd,
  GraphikTextSm,
} from 'components/shared/styled';
import { UserContext } from 'contexts';
import { format, parseISO } from 'date-fns';
import { FunctionComponent, useContext } from 'react';
import { View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ProfileVars } from '../../apollo';

export const ActiveSub: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const { TailwindResponsive } = useTailwindResponsive();

  const refetch = useReactiveVar(ProfileVars.subScreen.refetch);
  const sub = useReactiveVar(ProfileVars.subScreen.subscription);

  const [cancelSub, { loading }] = useMutation(CANCEL_SUBSCRIPTION, {
    onCompleted() {
      refetch();
    },
  });

  let planName = 'STANDARD';
  if (sub.description.includes('Basic')) planName = 'BASIC';
  else if (sub.description.includes('Premium')) planName = 'PREMIUM';

  return (
    <View style={TailwindResponsive(`mt-10`)}>
      <View
        style={TailwindResponsive(
          'flex flex-row items-center justify-between max-w-lg',
        )}
      >
        <View style={TailwindResponsive('flex flex-row')}>
          <View
            style={TailwindResponsive(
              'bg-Dark2 px-3 py-1 rounded-md self-start',
            )}
          >
            <GraphikSemiTextMd color={Styles.Colours.Light1}>
              {planName}
            </GraphikSemiTextMd>
          </View>
          <View style={TailwindResponsive('px-3 py-1 self-start')}>
            <GraphikSemiTextMd>Plan</GraphikSemiTextMd>
          </View>
        </View>

        <View style={TailwindResponsive('my-2')}>
          <GraphikMediumTextHuge>
            {sub.amount} <GraphikTextSm>/ mo</GraphikTextSm>
          </GraphikMediumTextHuge>
        </View>
      </View>

      <GraphikTextMd style={TailwindResponsive(`mt-2`)}>
        Your next billing date is:{' '}
        {format(parseISO(sub.nextBillingDate), 'PPP')}
      </GraphikTextMd>

      <FairchainButton
        buttonStyle={TailwindResponsive(`mt-20 rounded-full self-start`)}
        label="Cancel Subscription"
        loading={loading}
        onPress={() => {
          cancelSub({
            variables: {
              userId: authUser?.uid,
            },
          });
        }}
        textStyle={TailwindResponsive(`text-Dark1 underline`)}
      />
    </View>
  );
};

export default ActiveSub;
