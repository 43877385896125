import { useMutation, useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { GENERATE_COTA } from 'api/requests.v2';
import { TextButton } from 'components/shared/button';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { ErrorMessage } from 'components/shared/error';
import { ImagesWithThumbnails } from 'components/shared/image';
import { SpinLoader } from 'components/shared/loader';
import { DetailsPage } from 'components/shared/page/DetailsPage';
import {
  GraphikMediumTextSm,
  GraphikSemiTextMd,
  GraphikTextMd,
  GraphikTextSm,
  IBMTextMd,
} from 'components/shared/styled';
import { ENV } from 'constants/constants';
import { AssetType, UserRole } from 'constants/enums';
import { UserContext } from 'contexts';
import { ArtworkDetailsRouteProp, FCStackNavProp } from 'navigation';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { Linking, Pressable, View } from 'react-native';
import { useSelector } from 'react-redux';
import { DetailsData, ResetDetails } from 'store/artworkDetails/apollo';
import { getUserRole } from 'store/user';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ADCertificate, ADView } from './components';
import { useArtworkDetails, useConsignView } from './hooks';

export interface IArtworkDetailsProps {
  route: ArtworkDetailsRouteProp;
}

export const ArtworkDetails: FunctionComponent<IArtworkDetailsProps> = ({
  route,
}) => {
  const authUser = useContext(UserContext);
  const navigation = useNavigation<FCStackNavProp>();
  const { TailwindResponsive } = useTailwindResponsive();

  const isCertificateView = useReactiveVar(DetailsData.isCertificateView);
  const userRole = useSelector(getUserRole);

  const { artworkDetailsData, artworkDetailsError, artworkDetailsLoading } =
    useArtworkDetails(authUser?.uid, route.params.workId);

  const wasJustRegistered = useMemo(() => route.params.wasRegistered, [route]);

  const isConsignView = useConsignView();

  const workIdentifier = artworkDetailsData?.response?.work?.workIdentifier;
  const assetType = artworkDetailsData?.response.work?.assetType || '';

  let assetText;
  if (assetType === AssetType.Collectible) {
    assetText = 'Item';
  } else if (assetType === AssetType.Artwork) {
    assetText = 'Artwork';
  } else {
    // Items in the DB with no assetType are assumed to be Artwork
    assetText = 'Artwork';
  }

  const [generateCota, { loading }] = useMutation(GENERATE_COTA);

  useEffect(() => {
    const newWorkValue = artworkDetailsData
      ? artworkDetailsData.response.work
      : null;

    DetailsData.canConsign(artworkDetailsData?.response?.canConsign ?? false);
    DetailsData.work(newWorkValue);
  }, [artworkDetailsData]);

  useEffect(() => {
    ResetDetails();
  }, []);

  if (artworkDetailsLoading) return <SpinLoader />;
  if (!artworkDetailsData.response.canView) navigation.navigate('Collection');
  if (artworkDetailsError || !artworkDetailsData) {
    return <ErrorMessage error={artworkDetailsError} />;
  }

  return (
    <DetailsPage backScreen="collection" title={`${assetText} Details`}>
      {!!wasJustRegistered && assetType === AssetType.Artwork && (
        <GraphikTextMd style={TailwindResponsive(`mt-4`)}>
          Artwork registered successfully! Fairchain recommends recording the
          Fairchain ID <IBMTextMd>{workIdentifier}</IBMTextMd> on the back of
          the work or on a label applied to the work.
        </GraphikTextMd>
      )}
      {!!wasJustRegistered && assetType === AssetType.Collectible && (
        <GraphikTextMd style={TailwindResponsive(`mt-4`)}>
          Your item has been registered successfully with Fairchain ID{' '}
          <IBMTextMd>{workIdentifier}</IBMTextMd>. To issue a sales agreement
          for this item to a buyer, click{' '}
          <GraphikSemiTextMd>Generate Sales Agreement</GraphikSemiTextMd> below.
        </GraphikTextMd>
      )}
      <View style={TailwindResponsive(`flex flex-row flex-1 mobWeb:flex-col`)}>
        <View style={TailwindResponsive(`web:flex-1 mt-16 mobWeb:mb-8`)}>
          <ImagesWithThumbnails
            isLocal
            images={artworkDetailsData.response.work.images}
            videoURL={artworkDetailsData.response.work.videoURL}
          />
        </View>

        <View
          style={TailwindResponsive(`flex-1 p-16 mobWeb:p-0 mobWeb:flex-none`)}
        >
          {!!isConsignView && (
            <View style={TailwindResponsive(`border-t border-Gray2 mb-14`)}>
              <GraphikMediumTextSm style={TailwindResponsive(`mt-2`)}>
                Consigned to{' '}
                {artworkDetailsData.response.work.userConsignment?.consignee}
                {` - `}
                {new Date(
                  artworkDetailsData.response.work.userConsignment?.consignedDate,
                ).toDateString()}
              </GraphikMediumTextSm>
            </View>
          )}

          {isCertificateView ? (
            <View>
              <Pressable onPress={() => DetailsData.isCertificateView(false)}>
                <View style={TailwindResponsive(`flex items-end mb-3`)}>
                  <GraphikTextSm style={{ color: Styles.Colours.Primary }}>
                    View {assetText} Details
                  </GraphikTextSm>
                </View>
              </Pressable>
              <ADCertificate work={artworkDetailsData.response.work} />
              <TextButton
                style={TailwindResponsive(`px-0 pt-12`)}
                label="Report Issue"
                onPress={() => {
                  Linking.openURL(
                    `mailto:support@fairchain.art?&subject=${artworkDetailsData.response.work.title} Issue`,
                  );
                }}
                color={Styles.Colours.Gray2}
              />
            </View>
          ) : (
            <View>
              <ADView />

              <TextButton
                style={TailwindResponsive(`mt-5 self-end`)}
                label="Report Issue"
                onPress={() => {
                  Linking.openURL(
                    `mailto:support@fairchain.art?&subject=${artworkDetailsData.response.work.title} Issue`,
                  );
                }}
                color={Styles.Colours.Gray2}
              />

              {userRole === UserRole.Admin && ENV === 'DEVELOPMENT' && (
                <FairchainButton
                  buttonStyle={TailwindResponsive(
                    `bg-Primary rounded-full w-40`,
                  )}
                  label="Generate COTA"
                  loading={loading}
                  onPress={() => {
                    generateCota({
                      variables: {
                        artworkId: artworkDetailsData.response.work.id,
                      },
                    });
                  }}
                  textStyle={TailwindResponsive(`text-Light1`)}
                />
              )}
            </View>
          )}
        </View>
      </View>
    </DetailsPage>
  );
};

export default ArtworkDetails;
