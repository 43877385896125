import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { SmallTitle } from '../styled';

interface RadioButtonIProps {
  borderWidth: number;
  bulletSize?: number;
  color?: string;
  height: number;
  label: string;
  selected: boolean;
  width: number;
}

export const RadioButton: FunctionComponent<RadioButtonIProps> = ({
  borderWidth,
  bulletSize,
  color,
  height,
  label,
  selected,
  width,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const radioBulletSize = bulletSize || 12;
  const radioColor = color || '#000';

  return (
    <View style={TailwindResponsive('flex flex-row items-center')}>
      <View style={TailwindResponsive('py-4 pr-4')}>
        <View
          style={[
            {
              height,
              width,
              borderWidth,
              borderColor: radioColor,
              alignItems: 'center',
              justifyContent: 'center',
            },
            TailwindResponsive('flex flex-row items-center rounded-full'),
          ]}
        >
          {selected && (
            <View
              style={[
                {
                  height: radioBulletSize,
                  width: radioBulletSize,
                  backgroundColor: radioColor,
                },
                TailwindResponsive('rounded-full'),
              ]}
            />
          )}
        </View>
      </View>

      {!!label && (
        <SmallTitle style={TailwindResponsive('flex-1 flex-wrap')}>
          {label}
        </SmallTitle>
      )}
    </View>
  );
};

export default RadioButton;
