import { GraphikSemiTextMd, GraphikTextSm } from 'components/shared/styled';
import currency from 'currency.js';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { TransactionWithObjects } from 'types';
import { getCurrencySymbol } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IArtworkValueProps {
  discount: number;
  price: number;
  transaction: TransactionWithObjects;
}

export const ArtworkValue: FunctionComponent<IArtworkValueProps> = ({
  discount,
  price,
  transaction,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const currencySymbol = getCurrencySymbol(transaction.currency);

  const priceValue = currency(price || 0, {
    symbol: currencySymbol,
    precision: 0,
  });

  const discountAmount = currency(priceValue || 0, {
    symbol: currencySymbol,
  })
    .multiply((discount || 0) / 100)
    .format();

  const itemStyle =
    'border-b border-Dark6 py-1 flex flex-row items-center justify-between mobWeb:flex-row mob:flex-row mob:py-2';

  if (!transaction.isMultiWork) return null;

  return (
    <View
      style={TailwindResponsive(
        `my-24 mob:border-b mob:border-black mob:border-opacity-20 w-full`,
      )}
    >
      <View
        style={TailwindResponsive(
          `pb-2 border-b border-Dark6 mob:border-Dark4`,
        )}
      >
        <GraphikTextSm>
          Artwork Value Details ({currencySymbol}
          {transaction.currency})
        </GraphikTextSm>
      </View>

      <View style={TailwindResponsive(itemStyle)}>
        <GraphikSemiTextMd>{priceValue.format()}</GraphikSemiTextMd>
        <GraphikTextSm>Artwork Value</GraphikTextSm>
      </View>

      {discount > 0 && (
        <View style={TailwindResponsive(itemStyle)}>
          <GraphikSemiTextMd>{`(${discountAmount})`}</GraphikSemiTextMd>
          <GraphikTextSm>Discount</GraphikTextSm>
        </View>
      )}
    </View>
  );
};

export default ArtworkValue;
