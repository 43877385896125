import { useLazyQuery } from '@apollo/client';
import Clipboard from '@react-native-clipboard/clipboard';
import { GET_ADMIN_STAND_IN_LINK } from 'api/requests.v2';
import { RoundedButton } from 'components/shared/button';
import { DefaultTextInput } from 'components/shared/form';
import { SpinLoader } from 'components/shared/loader';
import { UserContext } from 'contexts';
import React, { FunctionComponent, useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Text, View } from 'react-native';
import Styles from 'style';
import { trimFormData, useLogout } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ILoginAsUserForm } from './Helpers';

export const LoginAsUserForm: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const authUser = useContext(UserContext);
  const [adminStandInLinkError, setAdminStandInLinkError] = useState(null);
  const logout = useLogout();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const [getAdminStandInLink, { loading: adminStandInLinkLoading }] =
    useLazyQuery(GET_ADMIN_STAND_IN_LINK, {
      onCompleted: ({ response }) => {
        if (response) {
          let adminStandInLink = response;
          if (adminStandInLink.includes('Error: ')) {
            setAdminStandInLinkError(adminStandInLink);
            setTimeout(() => {
              setAdminStandInLinkError(null);
            }, 3000);
          } else {
            adminStandInLink += '&isAdmin=true';
            Clipboard.setString(adminStandInLink);
            logout(0);
          }
        }
        reset();
      },
    });

  const onSubmit = async (data: ILoginAsUserForm) => {
    const trimmedData = trimFormData(data);
    getAdminStandInLink({
      variables: {
        ...trimmedData,
        userId: authUser?.uid,
      },
    });
  };

  const ErrorMessage = (
    <Text style={{ color: Styles.Colours.Error }}>This is required.</Text>
  );

  return (
    <View style={{ width: '40vw', paddingBottom: '4vh', margin: '30px' }}>
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => (
          <DefaultTextInput
            value={value}
            label="* Email"
            handleChange={onChange}
          />
        )}
        name="email"
        defaultValue=""
      />
      {errors.email && ErrorMessage}

      {adminStandInLinkLoading ? (
        <SpinLoader />
      ) : (
        <View style={TailwindResponsive('mb-4')}>
          <RoundedButton
            label="Login as user"
            onPress={() => {
              handleSubmit(onSubmit)();
            }}
            backgroundColor={Styles.Colours.Primary}
            color={Styles.Colours.Light1}
          />
        </View>
      )}

      {!!adminStandInLinkError && (
        <Text style={{ color: Styles.Colours.Error }}>
          {adminStandInLinkError}
        </Text>
      )}
    </View>
  );
};
