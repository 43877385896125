import React, { FunctionComponent } from 'react';
import Svg, { G, Rect } from 'react-native-svg';
import { ISVGProps } from './Types';

export const GridOutline: FunctionComponent<ISVGProps> = ({ width }) => (
  <Svg width={width || '16'} height="16" viewBox="0 0 16 16">
    <G id="Group_171" data-name="Group 171" transform="translate(-252 -440)">
      <G
        id="Rectangle_1405"
        data-name="Rectangle 1405"
        transform="translate(252 440)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      >
        <Rect width="7" height="7" stroke="none" />
        <Rect x="0.5" y="0.5" width="6" height="6" fill="none" />
      </G>
      <G
        id="Rectangle_1407"
        data-name="Rectangle 1407"
        transform="translate(261 440)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      >
        <Rect width="7" height="7" stroke="none" />
        <Rect x="0.5" y="0.5" width="6" height="6" fill="none" />
      </G>
      <G
        id="Rectangle_1406"
        data-name="Rectangle 1406"
        transform="translate(252 449)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      >
        <Rect width="7" height="7" stroke="none" />
        <Rect x="0.5" y="0.5" width="6" height="6" fill="none" />
      </G>
      <G
        id="Rectangle_1408"
        data-name="Rectangle 1408"
        transform="translate(261 449)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      >
        <Rect width="7" height="7" stroke="none" />
        <Rect x="0.5" y="0.5" width="6" height="6" fill="none" />
      </G>
    </G>
  </Svg>
);

export default GridOutline;
