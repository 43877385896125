import { useReactiveVar } from '@apollo/client';
import InfoIconNoFill from 'components/graphics/InfoIconNoFill';
import { RoundedButton } from 'components/shared/button';
import { SpinLoader } from 'components/shared/loader';
import { GraphikSemiTextMd } from 'components/shared/styled';
import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import TDGQL, {
  useGetTransactionDetailsHook,
} from 'store/transactionDetails/apollo';
import Styles from 'style';
import { InvoiceStatus, Status } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useTitleTransfer, useTransferTitleHandler } from '../hooks';

export const TransferTitle: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const transaction = useGetTransactionDetailsHook();

  const transferTitleSucceeded = useReactiveVar(
    TDGQL.transferTitleSucceededVar,
  );

  const isSigned = transaction?.status === Status.signed;

  const [modalVisible, setModalVisible] = useState(false);

  const { transferTitle, transferTitleLoading } = useTitleTransfer();
  const transferTitleHandler = useTransferTitleHandler(
    transaction,
    transferTitle,
  );

  const transferTitleLabel = transaction?.items[0]?.work.certified
    ? 'Transfer Title'
    : 'Transfer Ownership';

  if (
    !transaction ||
    transferTitleSucceeded ||
    transaction.isBuyerView ||
    !isSigned
  ) {
    return null;
  }

  if (transferTitleLoading) {
    return <SpinLoader />;
  }

  if (modalVisible)
    return (
      <View style={TailwindResponsive(`border p-4 flex flex-col items-center`)}>
        <GraphikSemiTextMd style={TailwindResponsive(`text-center`)}>
          Are you sure you want to transfer the title?
        </GraphikSemiTextMd>
        <View style={TailwindResponsive('flex flex-row pt-2 justify-center')}>
          <View style={TailwindResponsive(`mr-2`)}>
            <RoundedButton
              paddingHorizontal={25}
              backgroundColor={Styles.Colours.Primary}
              color={Styles.Colours.Light1}
              label="Confirm"
              onPress={() => {
                transferTitleHandler();
                setModalVisible(false);
              }}
            />
          </View>
          <RoundedButton
            label="Cancel"
            onPress={() => {
              setModalVisible(false);
            }}
          />
        </View>
      </View>
    );

  return (
    <>
      <View
        style={TailwindResponsive(
          'flex flex-row w-full bg-Dark6 items-center p-4',
        )}
      >
        <View style={TailwindResponsive(`mr-4`)}>
          <InfoIconNoFill />
        </View>
        <GraphikSemiTextMd>
          {(!transaction.invoiceStatus ||
            (transaction.invoiceStatus &&
              transaction.invoiceStatus !== InvoiceStatus.paid)) && (
            <GraphikSemiTextMd>Payment received? </GraphikSemiTextMd>
          )}
          Click below to transfer certificate of title and authenticity to the
          buyer&apos;s account.
        </GraphikSemiTextMd>
      </View>
      <View style={TailwindResponsive('w-300px mb-60px mt-8 mobWeb:w-full')}>
        <RoundedButton
          label={transferTitleLabel}
          onPress={() => {
            setModalVisible(true);
          }}
          backgroundColor={Styles.Colours.Dark1}
          color={Styles.Colours.Light1}
        />
      </View>
    </>
  );
};
