import { useReactiveVar } from '@apollo/client';
import CloseIcon from 'assets/images/CloseIcon.png';
import MainMenuIcon from 'assets/images/MainMenu.png';
import { FairchainLogo } from 'components/graphics';
import React, { FunctionComponent } from 'react';
import { Image, Modal, Pressable, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { MainMenuNavigation } from './MainMenuNavigation';
import HeaderVars from './apollo.header';

interface IMainMenuMobileWebProps {
  userRole: number;
}

export const MainMenuMobileWeb: FunctionComponent<IMainMenuMobileWebProps> = ({
  userRole,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const showModal = useReactiveVar(HeaderVars.showMenuModal);

  return (
    <View>
      <Modal visible={showModal} style={TailwindResponsive('flex flex-col')}>
        <View
          style={TailwindResponsive(
            'flex flex-row items-center px-6 pt-6 mb-20',
          )}
        >
          <View style={TailwindResponsive('')}>
            <FairchainLogo />
          </View>
          <Pressable
            onPress={() => HeaderVars.showMenuModal(false)}
            style={[TailwindResponsive('m-2 h-4 w-4'), { marginLeft: 'auto' }]}
          >
            <Image
              source={CloseIcon}
              style={TailwindResponsive(`flex-1`)}
              resizeMode="contain"
            />
          </Pressable>
        </View>
        <MainMenuNavigation userRole={userRole} />
      </Modal>
      <Pressable
        onPress={() => HeaderVars.showMenuModal(true)}
        style={TailwindResponsive('h-4 w-4')}
      >
        <Image
          source={MainMenuIcon}
          style={TailwindResponsive(`flex-1`)}
          resizeMode="contain"
        />
      </Pressable>
    </View>
  );
};
