import { useMutation } from '@apollo/client';
import {
  VALIDATE_AUTH_CODE_PROFILE_2FA,
  VERIFY_PHONE_NUMBER_2FA,
} from 'api/requests.v2';
import ErrorIcon from 'components/graphics/ErrorIcon';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import DefaultTextInput from 'components/shared/form/DefaultTextInput';
import { GraphikTextSm } from 'components/shared/styled';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Pressable, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, updateTwoFactorEnabled } from 'store/user';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import UniversalPinInput from './UniversalPinInput';

interface ITwoFactorAffordanceProps {
  modalVisible: boolean;
  setModalVisible: (arg: any) => void;
  setShowSuccess: (arg: any) => void;
}
export const TwoFactorAffordance: FunctionComponent<
  ITwoFactorAffordanceProps
> = ({ modalVisible, setModalVisible, setShowSuccess }) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const [numberSent, setNumberSent] = useState(false);
  const [error, updateError] = useState(false);
  const dispatch = useDispatch();
  const userFromStore = useSelector(getUser);
  const userPhoneNumber =
    userFromStore?.twoFactorNumber || userFromStore?.phoneNumber || '';
  const [phoneNumber, setPhoneNumber] = useState(userPhoneNumber || '');

  const [verifyPhoneNumber2Fa] = useMutation(VERIFY_PHONE_NUMBER_2FA);

  const [validateAuthCodeProfile2Fa] = useMutation(
    VALIDATE_AUTH_CODE_PROFILE_2FA,
  );

  const handleCheckNumber = useCallback(
    (value) => {
      verifyPhoneNumber2Fa({
        variables: {
          phoneNumber: value,
          userId: userFromStore?.id,
        },
      });
    },
    [userFromStore, verifyPhoneNumber2Fa],
  );

  const handleCheckCode = useCallback(
    async (value) => {
      const authCodeResponse = await validateAuthCodeProfile2Fa({
        variables: {
          code: value,
          userId: userFromStore?.id,
        },
      });
      if (authCodeResponse.data.response === false) {
        updateError(true);
      }
      if (authCodeResponse.data.response && userFromStore) {
        setNumberSent(!numberSent);
        setModalVisible(false);
        dispatch(updateTwoFactorEnabled(true));
        setShowSuccess(true);
      }
    },
    [
      dispatch,
      numberSent,
      setModalVisible,
      setShowSuccess,
      userFromStore,
      validateAuthCodeProfile2Fa,
    ],
  );

  if (!modalVisible) return null;

  return (
    <View>
      <View style={TailwindResponsive('rounded-lg bg-Light1')}>
        <GraphikTextSm
          color={Styles.Colours.Dark1}
          style={TailwindResponsive('mt-6 mx-10')}
        >
          Enter the phone number that you’d like to use. It can be different
          from the phone number associated with your account profile.
        </GraphikTextSm>
        <View style={TailwindResponsive('flex flex-row mob:flex-col')}>
          <View style={TailwindResponsive('w-60 mx-10')}>
            <DefaultTextInput
              label="Phone Number"
              value={phoneNumber}
              handleChange={setPhoneNumber}
              editable
            />
          </View>
          <View style={TailwindResponsive('mt-6 mb-12 mx-10')}>
            <FairchainButton
              label="Get Code"
              testId="enable2FAsendCode"
              onPress={() => {
                handleCheckNumber(phoneNumber);
                setNumberSent(!numberSent);
              }}
              buttonStyle={{
                ...TailwindResponsive('bg-Primary rounded-full w-45'),
                width: 160,
              }}
              textStyle={{ color: 'white' }}
              disabled={numberSent}
            />
          </View>
        </View>
        {numberSent && (
          <View>
            <View style={TailwindResponsive('px-4 mx-6')}>
              <GraphikTextSm
                color={Styles.Colours.Dark1}
                style={TailwindResponsive('mb-8')}
              >
                Enter the 6 digit code
              </GraphikTextSm>
              <UniversalPinInput onComplete={handleCheckCode} />
              {error && (
                <View style={TailwindResponsive('mt-8 items-start')}>
                  <View style={TailwindResponsive('flex-row')}>
                    <ErrorIcon width={24} color="red" />
                    <GraphikTextSm
                      style={TailwindResponsive('ml-4 pt-1')}
                      color={Styles.Colours.Error}
                    >
                      Incorrect Authentication Code
                    </GraphikTextSm>
                  </View>
                </View>
              )}
              <View style={TailwindResponsive('flex-row mt-10 mob:flex-col')}>
                <GraphikTextSm color={Styles.Colours.Dark1}>
                  Didn&apos;t receive your code?
                </GraphikTextSm>
                <Pressable onPress={() => handleCheckNumber(phoneNumber)}>
                  <View style={TailwindResponsive('mob:-ml-2')}>
                    <GraphikTextSm
                      style={TailwindResponsive('underline ml-2')}
                      color={Styles.Colours.Dark1}
                    >
                      Resend a new code
                    </GraphikTextSm>
                  </View>
                </Pressable>
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

export default TwoFactorAffordance;
