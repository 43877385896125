import { useReactiveVar } from '@apollo/client';
import { Checkbox } from 'components/shared/checkbox';
import { DiminishingRoyalty } from 'components/shared/diminishingRoyalties/DiminishingRoyalty';
import { OptionPicker } from 'components/shared/optionPicker';
import {
  GraphikSemiTextSm,
  GraphikSemiTextTiny,
  GraphikTextTiny,
} from 'components/shared/styled';
import { DIMINISHED_OPTIONS } from 'constants/constants';
import { AssetType, RegistrationType } from 'constants/enums';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearRoyalties,
  getDiminishedRoyalties,
  setDiminishedRoyaltyDate,
} from 'store/forms';
import { RegisterStore } from 'store/registerWork/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useIsGallery } from '../Hooks';
import FixedRoyalty from '../components/FixedRoyalty';

const defaultDiminishDate = DIMINISHED_OPTIONS[0]!.label;

export const Royalty: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const dispatch = useDispatch();

  const isGallery = useIsGallery();

  const registrationType = useReactiveVar(RegisterStore.Vars.registrationType);
  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const isCollectible = detailsForm?.assetType === AssetType.Collectible;

  const diminishedRoyalties = useSelector(getDiminishedRoyalties);

  const [isInitialized, setIsInitialized] = useState(false);
  const [diminishedDate, setDiminishedDate] = useState(defaultDiminishDate);
  const [doesDiminish, setDoesDiminish] = useState(false);

  useEffect(() => {
    if (isInitialized === false) {
      const theRoyalty = diminishedRoyalties.find(
        (royalty) => royalty?.workId === 'RegisterWork',
      );
      if (theRoyalty) {
        setIsInitialized(true);
        setDiminishedDate(theRoyalty.diminishedDate || defaultDiminishDate);
        setDoesDiminish(theRoyalty.doesDiminish);
      }
    }
  }, [diminishedRoyalties, isInitialized, doesDiminish]);

  useEffect(() => {
    if (diminishedDate && doesDiminish) {
      dispatch(
        setDiminishedRoyaltyDate({
          diminishedDate,
          workId: 'RegisterWork',
        }),
      );
    }
  }, [diminishedDate, dispatch, doesDiminish]);

  return (
    <View style={TailwindResponsive(`mb-24`)}>
      <View
        style={TailwindResponsive('mb-12 w-full max-w-lg')}
        testID="ResaleVerbiage_Wrapper"
      >
        <GraphikTextTiny>
          {isCollectible ? (
            <GraphikTextTiny>{`Enter the resale commission rate each recipient will receive on subsequent sales. You'll be able to edit these values when issuing a sales agreement.`}</GraphikTextTiny>
          ) : (
            <>
              <GraphikTextTiny>{`Enter the resale commission rate${
                isGallery ? 's' : ''
              } the artist ${
                isGallery ? 'and gallery ' : ''
              }will receive on subsequent sales. We suggest resale commission rates of ${
                registrationType !== RegistrationType.Retro ? '5-8%' : '2-5%'
              }. `}</GraphikTextTiny>
              <GraphikSemiTextTiny>
                {registrationType !== RegistrationType.Retro
                  ? `You'll be able to edit these values when issuing a sales agreement.`
                  : `You'll be able to edit this value when you issue the certificate.`}
              </GraphikSemiTextTiny>
            </>
          )}
        </GraphikTextTiny>
      </View>
      {registrationType !== RegistrationType.Retro && (
        <>
          <Checkbox
            isChecked={!doesDiminish}
            onPress={() => {
              setDoesDiminish(false);
              setDiminishedDate(defaultDiminishDate);
              dispatch(clearRoyalties());
            }}
            label="Fixed Resale Commissions"
          />

          {!isCollectible && (
            <View style={TailwindResponsive(`mt-10`)}>
              <Checkbox
                isChecked={doesDiminish}
                onPress={() => {
                  setDoesDiminish(true);
                }}
                label="Diminishing Resale Commissions"
              />
              <View style={TailwindResponsive('ml-10')}>
                <GraphikTextTiny style={TailwindResponsive('w-full max-w-lg')}>
                  Allows you to set higher resale commissions for a fixed period
                  with subsequent reduction. This can be used to protect against
                  short-term speculation. (Beta)
                </GraphikTextTiny>
              </View>
            </View>
          )}
        </>
      )}

      {doesDiminish && (
        <View style={{ ...TailwindResponsive(`flex-1 mt-10`), zIndex: 2 }}>
          <View
            style={TailwindResponsive(
              `flex flex-1 flex-row items-center mobWeb:justify-start mob:justify-start`,
            )}
          >
            <GraphikSemiTextSm>For the first</GraphikSemiTextSm>
            <View style={TailwindResponsive(`mx-2 w-24`)}>
              <OptionPicker
                options={DIMINISHED_OPTIONS}
                defaultValue={1}
                value={diminishedDate}
                onChange={setDiminishedDate}
                showIcon
              />
            </View>
            <GraphikSemiTextSm>years:</GraphikSemiTextSm>
          </View>
        </View>
      )}

      <View style={{ ...TailwindResponsive('mt-6'), zIndex: 1 }}>
        <FixedRoyalty />
      </View>

      {doesDiminish && (
        <View style={{ zIndex: 1 }}>
          <DiminishingRoyalty isGallery={isGallery} workId="RegisterWork" />
        </View>
      )}
    </View>
  );
};

export default Royalty;
