import { ExternalLink } from 'components/shared/link/ExternalLink';
import {
  GraphikMediumTextMd,
  GraphikTextMd,
  MediumTitle,
} from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ArtistConsignScreen: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View>
      <MediumTitle style={TailwindResponsive(`mb-8`)}>
        Here&apos;s how it works:
      </MediumTitle>

      <GraphikMediumTextMd style={TailwindResponsive(`mb-6`)}>
        3 — Consign:{' '}
        <GraphikTextMd>
          Easily authorize your gallery to manage transactions for select works
          via the Consign feature or have the gallery register works on your
          behalf. Learn more{' '}
          <ExternalLink link="https://fairchain.art/consignment-faq/">
            here
          </ExternalLink>
          .
        </GraphikTextMd>
      </GraphikMediumTextMd>
    </View>
  );
};

export default ArtistConsignScreen;
