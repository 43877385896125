import { useMutation } from '@apollo/client';
import { ADD_USER_LOGO } from 'api/requests.v2';
import { RoundedButton } from 'components/shared/button';
import { DefaultTextInput } from 'components/shared/form';
import { ImagePicker } from 'components/shared/imagepicker';
import { ImageSelectionPage } from 'constants/enums';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getLogo, resetLogo, setLogo } from 'store/admin';
import Styles from 'style';
import { extractExtensionFromB64Data, trimFormData } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const AddUserLogo: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const [addUserLogo] = useMutation(ADD_USER_LOGO);
  const [isSuccessfullySubmitted, setSuccessfullySubmitted] = useState('');
  const [isSubmittedError, setSubmittedError] = useState('');

  const logoInfo = useSelector(getLogo);
  const dispatch = useDispatch();

  const {
    control,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: any) => {
    if (logoInfo) {
      const trimmedData = trimFormData(data);
      const imageExtension = extractExtensionFromB64Data(logoInfo.localURI);
      const logoResponse = await addUserLogo({
        variables: {
          email: trimmedData.email,
          base64Logo: logoInfo.localURI,
          imageExtension,
        },
      });

      if (logoResponse.data.response.success === true) {
        setSuccessfullySubmitted('🤠 User Logo Added Successfully 🎊');
      } else {
        setSubmittedError(logoResponse.data.response.errorMessage);
      }

      dispatch(resetLogo());
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setTimeout(() => {
        setSubmittedError('');
        setSuccessfullySubmitted('');
      }, 3000);
    }
  }, [reset, isSubmitSuccessful]);

  return (
    <View style={TailwindResponsive('border-b border-Dark1 pb-12')}>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <DefaultTextInput
            value={value}
            label="* Email"
            handleChange={onChange}
            errorMessage={errors.email?.message}
          />
        )}
        name="email"
        defaultValue=""
      />
      <View style={TailwindResponsive('items-center')}>
        <ImagePicker
          image={logoInfo?.filePath}
          imageSelectionHandler={(image) => dispatch(setLogo({ value: image }))}
          page={ImageSelectionPage.Admin}
        />
      </View>
      <View style={TailwindResponsive('m-5')}>
        <RoundedButton
          label="Add Logo"
          onPress={() => {
            handleSubmit(onSubmit)();
          }}
          backgroundColor={Styles.Colours.Primary}
          color={Styles.Colours.Light1}
        />
        {!!isSuccessfullySubmitted && (
          <Text style={{ color: Styles.Colours.Success }}>
            {isSuccessfullySubmitted}
          </Text>
        )}
        {!!isSubmittedError && (
          <Text style={{ color: Styles.Colours.Error }}>
            {isSubmittedError}
          </Text>
        )}
      </View>
    </View>
  );
};
