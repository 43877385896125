import { makeVar } from '@apollo/client';
import { UseFormReturn } from 'react-hook-form';
import { Work } from 'types';
import {
  ADSettingsForm,
  CollectorRetroRequestForm,
} from 'types/ArtworkDetails';

export enum CRModalScreens {
  one,
  two,
  three,
}

const artworkVar = makeVar<Work | null>(null);
const canConsignVar = makeVar<boolean>(false);
const isCertificateViewVar = makeVar<boolean>(false);

// Collector Retro
const collectorRetroComplete = makeVar<boolean>(false);
const collectorRetroDonezo = makeVar<boolean>(false);
const collectorRetroFormVar = makeVar<UseFormReturn<
  CollectorRetroRequestForm,
  any
> | null>(null);
const collectorRetroRequestLoadingVar = makeVar<boolean>(false);
const showCollectorRetroModalVar = makeVar<boolean>(false);

export const DetailsData = {
  canConsign: canConsignVar,
  CollectorRetro: {
    completed: collectorRetroComplete,
    donezo: collectorRetroDonezo,
    form: collectorRetroFormVar,
    modalScreen: makeVar<CRModalScreens>(CRModalScreens.one),
    requestLoading: collectorRetroRequestLoadingVar,
    showModal: showCollectorRetroModalVar,
  },
  isCertificateView: isCertificateViewVar,
  settingsForm: makeVar<UseFormReturn<ADSettingsForm, any> | null>(null),
  work: artworkVar,
};

export const ResetDetails = () => {
  artworkVar(null);
  canConsignVar(false);
  isCertificateViewVar(false);

  // Collector Retro
  collectorRetroComplete(false);
  collectorRetroDonezo(false);
  collectorRetroFormVar(null);
  collectorRetroRequestLoadingVar(false);
  showCollectorRetroModalVar(false);
};
