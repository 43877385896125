import React, { FunctionComponent } from 'react';
import { Text, View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ConsignmentHeader: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const headerTextStyle = {
    fontSize: Styles.Fonts.FontSize.Tiny,
    fontFamily: Styles.Fonts.FontFamily.SansRegular,
  };

  return (
    <View
      style={TailwindResponsive(
        `flex-row border-b border-Dark1 py-2 mobWeb:hidden`,
      )}
    >
      <View style={TailwindResponsive(`w-20`)} />
      <View style={TailwindResponsive(`w-40`)} />

      <View style={TailwindResponsive(`flex-1`)}>
        <Text style={headerTextStyle}>Artist</Text>
      </View>

      <View style={TailwindResponsive(`flex-1`)}>
        <Text style={headerTextStyle}>Title</Text>
      </View>

      <View style={TailwindResponsive(`flex-1`)}>
        <Text style={headerTextStyle}>Year</Text>
      </View>

      <View style={TailwindResponsive(`flex-1`)}>
        <Text style={headerTextStyle}>Consignee</Text>
      </View>

      <View style={TailwindResponsive(`flex-1`)}>
        <Text style={headerTextStyle}>Date Consigned</Text>
      </View>
    </View>
  );
};
