import ErrorIcon from 'components/graphics/ErrorIcon';
import { GraphikSemiTextMd, GraphikTextMd } from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import Styles from 'style';
import { KycMatch } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IKycLineItemProps {
  index: number;
  result: KycMatch;
}

export const KycLineItem: FunctionComponent<IKycLineItemProps> = ({
  index,
  result,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View style={TailwindResponsive(`mb-8 w-full`)}>
      <View>
        <GraphikSemiTextMd style={TailwindResponsive(`border-b mb-3`)}>{`${
          index + 1
        }. ${result.name}`}</GraphikSemiTextMd>
        <View
          style={TailwindResponsive(
            `border border-Dark5 p-2.5 flex flex-row items-center`,
          )}
        >
          <View style={TailwindResponsive(`pr-2.5`)}>
            <ErrorIcon color={Styles.Colours.Error} />
          </View>
          <GraphikTextMd color={Styles.Colours.Error}>
            {result.type}
          </GraphikTextMd>
        </View>
      </View>
    </View>
  );
};

export default KycLineItem;
