import { useLazyQuery } from '@apollo/client';
import {
  GET_BUYER_LOGIN_LINK,
  RESEND_BUYER_SALES_OFFER_LINK,
} from 'api/requests.v2';
import ShareIcon from 'assets/images/ShareIcon.png';
import { ConfirmationPopup } from 'components/shared/form';
import { FairchainImage } from 'components/shared/image';
import { SpinLoader } from 'components/shared/loader';
import {
  GraphikSemiTextMd,
  GraphikTextMd,
  GraphikTextSm,
} from 'components/shared/styled';
import { UserContext } from 'contexts';
import React, { FunctionComponent, useContext, useMemo, useState } from 'react';
import { Modal, Pressable, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';
import { sellerBuyerLink } from 'store/transferWork/apollo';
import { getUser } from 'store/user';
import Styles from 'style';
import { InvoiceStatus, Status } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useTitleTransfer, useTransferTitleHandler } from '../hooks';

export const TransactionProgress: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const { TailwindResponsive } = useTailwindResponsive();

  const transaction = useGetTransactionDetailsHook();

  const userInfo = useSelector(getUser);

  const transferTitleLabel = transaction?.items[0]?.work.certified
    ? 'Transfer Title'
    : 'Transfer Ownership';

  const canResendAgreement = useMemo(() => {
    if (userInfo?.isSubUser) {
      if (transaction?.subUserCreationId === userInfo.id) {
        return true;
      }
      return false;
    }
    return true;
  }, [userInfo, transaction]);

  const hasPayment =
    !!transaction?.stripePaymentIntentId || !!transaction?.stripeInvoiceId;
  const lineItemStyle = `flex flex-row mobWeb:flex-col items-center justify-between mobWeb:items-start mob:items-start mob: pb-2`;
  const titleTransferStep = hasPayment ? 4 : 3;

  const { transferTitle } = useTitleTransfer();

  const transferTitleHandler = useTransferTitleHandler(
    transaction,
    transferTitle,
  );

  const [modalVisible, setModalVisible] = useState(false);
  const [sendReminder, setSendReminder] = useState(true);
  const [
    resendBuyerSalesOfferLink,
    {
      data: resendBuyerSalesOfferData,
      loading: resendBuyerSalesOfferLoading,
      error: resendBuyerSalesOfferError,
    },
  ] = useLazyQuery(RESEND_BUYER_SALES_OFFER_LINK);

  const [getBuyerLink, { loading: buyerLinkLoading }] = useLazyQuery(
    GET_BUYER_LOGIN_LINK,
    {
      onCompleted: ({ response }) => {
        sellerBuyerLink(response);
      },
    },
  );

  if (!transaction || transaction.isBuyerView) return null;
  if (resendBuyerSalesOfferLoading || buyerLinkLoading) return <SpinLoader />;

  if (transaction.status === Status.void)
    return (
      <View>
        <GraphikTextSm
          style={TailwindResponsive(`mb-3 pb-2 border-b-2 border-Dark6`)}
        >
          Progress
        </GraphikTextSm>
        <View style={TailwindResponsive(`mb-3 border-b-2 border-Dark6`)}>
          <View
            style={TailwindResponsive(
              `flex flex-row mobWeb:flex-col items-center justify-between mobWeb:items-start`,
            )}
          >
            <GraphikSemiTextMd>
              1.&nbsp; Sent {new Date(transaction?.createDate).toDateString()}
            </GraphikSemiTextMd>
          </View>
        </View>
        <View style={TailwindResponsive(`mb-3 border-b-2 border-Dark6`)}>
          <View
            style={TailwindResponsive(
              `flex flex-row mobWeb:flex-col items-center justify-between mobWeb:items-start`,
            )}
          >
            <GraphikSemiTextMd>2. Transaction Voided</GraphikSemiTextMd>
          </View>
        </View>
      </View>
    );

  return (
    <View>
      <GraphikTextSm
        style={TailwindResponsive(`mb-3 pb-2 border-b border-Dark6`)}
      >
        Progress
      </GraphikTextSm>
      <View style={TailwindResponsive(`mb-3 border-b border-Dark6`)}>
        <View style={TailwindResponsive(lineItemStyle)}>
          <GraphikSemiTextMd>
            1.&nbsp; Sent on {new Date(transaction?.createDate).toDateString()}
          </GraphikSemiTextMd>
        </View>
      </View>
      <View style={TailwindResponsive(`mb-3 border-b border-Dark6`)}>
        <View style={TailwindResponsive(lineItemStyle)}>
          {transaction.status === Status.created && (
            <GraphikSemiTextMd style={{ color: Styles.Colours.Dark4 }}>
              2. Seller Signature Pending
            </GraphikSemiTextMd>
          )}
          {transaction.status === Status.sellerSigned && (
            <>
              <GraphikTextMd style={{ color: Styles.Colours.Dark4 }}>
                2. Signature Pending
              </GraphikTextMd>

              <View style={TailwindResponsive('flex flex-row')}>
                {sendReminder === true && canResendAgreement && (
                  <Pressable
                    onPress={() => {
                      setSendReminder(false);
                      resendBuyerSalesOfferLink({
                        variables: { transactionId: transaction?.id },
                      });
                    }}
                    style={TailwindResponsive(`flex justify-center`)}
                  >
                    <GraphikTextSm
                      style={{
                        color: Styles.Colours.Primary,
                        textDecorationLine: 'underline',
                      }}
                    >
                      Resend{' '}
                      {transaction.isRetro
                        ? 'Certification Agreement'
                        : 'Sales Agreement'}
                    </GraphikTextSm>
                  </Pressable>
                )}

                {canResendAgreement && (
                  <Pressable
                    onPress={() => {
                      getBuyerLink({
                        variables: {
                          transactionId: transaction?.id,
                          userId: authUser?.uid,
                        },
                      });
                    }}
                    style={TailwindResponsive(`ml-2 px-1 py-1`)}
                  >
                    <FairchainImage height={18} uri={ShareIcon} width={18} />
                  </Pressable>
                )}
              </View>
            </>
          )}
          {transaction.status === Status.buyerSigned && (
            <GraphikSemiTextMd>2. Finalizing Contract</GraphikSemiTextMd>
          )}
          {transaction.signedDate && transaction.signedDate !== '' && (
            <GraphikSemiTextMd>
              2. Signed on {new Date(transaction?.signedDate).toDateString()}
            </GraphikSemiTextMd>
          )}
        </View>
      </View>

      {hasPayment && (
        <View style={TailwindResponsive(`mb-3 border-b border-Dark6`)}>
          <View style={TailwindResponsive(lineItemStyle)}>
            {transaction.status === Status.completed ||
            transaction.invoiceStatus === InvoiceStatus.paid ? (
              <GraphikSemiTextMd>
                3. Paid{' '}
                {transaction.dateInvoicePaid
                  ? new Date(transaction.dateInvoicePaid).toDateString()
                  : ''}
              </GraphikSemiTextMd>
            ) : (
              <GraphikTextMd style={{ color: Styles.Colours.Dark4 }}>
                3. Payment Pending (Stripe Invoice)
              </GraphikTextMd>
            )}
          </View>
        </View>
      )}

      <View style={TailwindResponsive(`mb-3 border-b border-Dark6`)}>
        <View
          style={TailwindResponsive(
            `flex flex-row mobWeb:flex-col items-center justify-between mobWeb:items-start mob:flex-row mob:pb-2`,
          )}
        >
          {transaction.status === Status.completed && (
            <GraphikSemiTextMd>
              {titleTransferStep}. Title Transferred on{' '}
              {new Date(transaction?.finalizedDate).toDateString()}
            </GraphikSemiTextMd>
          )}
          {transaction.status === Status.pendingApproval && (
            <GraphikSemiTextMd style={{ color: Styles.Colours.Dark4 }}>
              {titleTransferStep}. Title Transfer in Progress
            </GraphikSemiTextMd>
          )}
          {transaction.status !== Status.completed &&
            transaction.status !== Status.pendingApproval && (
              <>
                <GraphikTextMd style={{ color: Styles.Colours.Dark4 }}>
                  {titleTransferStep}. Initiate Title Transfer
                </GraphikTextMd>
                {transaction.status === Status.signed && (
                  <Pressable
                    onPress={() => {
                      setModalVisible(true);
                    }}
                    style={TailwindResponsive(`flex justify-center mob:hidden`)}
                  >
                    <GraphikTextSm
                      style={{
                        color: Styles.Colours.Primary,
                        textDecorationLine: 'underline',
                      }}
                    >
                      {transferTitleLabel}
                    </GraphikTextSm>
                  </Pressable>
                )}
                {modalVisible && (
                  <Modal transparent>
                    <ConfirmationPopup
                      confirm={() => {
                        transferTitleHandler();
                        setModalVisible(false);
                      }}
                      cancel={() => setModalVisible(false)}
                    />
                  </Modal>
                )}
              </>
            )}
        </View>
      </View>
      {resendBuyerSalesOfferData && (
        <View style={TailwindResponsive('mob:items-center')}>
          <GraphikTextSm
            style={{
              ...TailwindResponsive(`mt-2.5`),
              color: Styles.Colours.Primary,
            }}
          >
            {transaction.isRetro
              ? 'Certification agreement email resent successfully'
              : 'Sales agreement email resent successfully'}
          </GraphikTextSm>
        </View>
      )}
      {resendBuyerSalesOfferError && (
        <GraphikTextSm
          style={{
            ...TailwindResponsive(`mt-2.5`),
            color: Styles.Colours.Error,
          }}
        >
          Error resending sales agreement email
        </GraphikTextSm>
      )}
    </View>
  );
};

export default TransactionProgress;
