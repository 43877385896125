import React, { FunctionComponent } from 'react';
import { Text, View } from 'react-native';
import Styles from 'style';

interface InputProps {
  label: string;
  value?: string | undefined;
  height: number;
  width: string;
}

export const InformationDisplay: FunctionComponent<InputProps> = ({
  label,
  value,
  height,
  width,
}) => (
  <View
    style={{
      height,
      width,
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      marginBottom: '20px',
    }}
  >
    <View style={{}}>
      <Text
        style={{
          fontFamily: Styles.Fonts.FontFamily.SansMedium,
          fontSize: Styles.Fonts.FontSize.Medium,
          color: Styles.Colours.Dark1,
        }}
      >
        {value}
      </Text>
    </View>
    <View
      style={{
        position: 'absolute',
        right: 0,
      }}
    >
      <Text
        style={{
          fontFamily: Styles.Fonts.FontFamily.SansRegular,
          fontSize: Styles.Fonts.FontSize.Tiny,
          color: Styles.Colours.Gray2,
        }}
      >
        {label}
      </Text>
    </View>
  </View>
);

export default InformationDisplay;
