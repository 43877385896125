import { useQuery } from '@apollo/client';
import { GET_ADMIN_TRANSACTIONS_BY_STATUS } from 'api/requests.v2';
import { ErrorMessage } from 'components/shared/error';
import { SpinLoader } from 'components/shared/loader';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Status, TransactionWithObjects } from 'types';
import { ViewTransactionsLineItem } from './TransactionsLineItem';

export const AdminTransactions: FunctionComponent = () => {
  const [pendingTransactions, setPendingTransactions] = useState<
    TransactionWithObjects[]
  >([]);

  const {
    loading: transactionLoading,
    data: transactionData,
    error: transactionError,
    refetch: transactionRefetch,
  } = useQuery(GET_ADMIN_TRANSACTIONS_BY_STATUS, {
    variables: {
      status: Status.pendingApproval,
    },
  });

  useEffect(() => {
    if (transactionData) {
      setPendingTransactions(transactionData.response);
    }
  }, [transactionData]);

  if (transactionError)
    return (
      <View>
        <ErrorMessage error={transactionError} />
      </View>
    );

  if (transactionLoading)
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <SpinLoader />
      </View>
    );

  return (
    <View>
      {pendingTransactions &&
        pendingTransactions.map((transaction) => (
          <View key={transaction.id}>
            <ViewTransactionsLineItem
              transaction={transaction}
              refetch={() => transactionRefetch()}
            />
          </View>
        ))}
    </View>
  );
};
