import { TrashIcon } from 'components/graphics';
import * as DocumentPicker from 'expo-document-picker';
import { EncodingType, readAsStringAsync } from 'expo-file-system';
import { FunctionComponent, useState } from 'react';
import { Platform, Pressable, View } from 'react-native';
import {
  FileUploaderApollo,
  base64FileNameVar,
  base64FileVar,
  fileDataUriVar,
} from 'store/general/file-uploader.apollo';
import Styles from 'style';
import { logError } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { FairchainButton } from '../button/FairchainButton';
import { GraphikTextSm } from '../styled';

interface IFileUploader {
  title: string;
  width: number;
}
export const FileUploader: FunctionComponent<IFileUploader> = ({
  title,
  width,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const [isDocResultName, setDocResultName] = useState('');
  const [loading, setLoading] = useState(false);

  const pickImage = async () => {
    const docResult: DocumentPicker.DocumentPickerResult =
      await DocumentPicker.getDocumentAsync();
    try {
      setLoading(true);
      if (docResult && !docResult.canceled && docResult.assets.length > 0) {
        const asset = docResult.assets[0]!;
        const fileName = asset.file?.name || asset.name || '';
        const splitBase64File =
          Platform.OS !== 'web'
            ? await readAsStringAsync(asset.uri, {
                encoding: EncodingType.Base64,
              })
            : asset.uri.split('base64,')[1];
        base64FileVar(splitBase64File);
        base64FileNameVar(fileName);
        fileDataUriVar(asset.uri);

        setDocResultName(fileName);
      }
    } catch (error) {
      logError(error, `Error in picking image.`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={TailwindResponsive('flex-row')}>
      {!isDocResultName && (
        <FairchainButton
          buttonStyle={TailwindResponsive(
            `bg-Dark1 items-center rounded-full w-${width}`,
          )}
          label={title}
          loading={loading}
          onPress={pickImage}
          textStyle={TailwindResponsive(`text-Light1`)}
        />
      )}

      {!!isDocResultName && (
        <View style={TailwindResponsive(`flex flex-row items-center pr-4`)}>
          <GraphikTextSm>{isDocResultName}</GraphikTextSm>
          <Pressable
            onPress={() => {
              FileUploaderApollo.Reset();
              setDocResultName('');
            }}
            style={TailwindResponsive(`ml-2`)}
          >
            <TrashIcon color={Styles.Colours.Dark1} width={22} />
          </Pressable>
        </View>
      )}
    </View>
  );
};
export default FileUploader;
