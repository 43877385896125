import { useMutation, useReactiveVar } from '@apollo/client';
import { CHANGE_AVAILABILITY_FOR_LOAN } from 'api/requests.v2';
import { Checkbox } from 'components/shared/checkbox';
import HeaderVars from 'components/shared/header/apollo.header';
import { useSubscriptionActive } from 'components/shared/header/hooks';
import { UserRole } from 'constants/enums';
import { UserContext } from 'contexts';
import { FunctionComponent, useContext } from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { DetailsData } from 'store/artworkDetails/apollo';
import { getUserRole } from 'store/user';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useSetupSettingsForm } from '../forms/settings.form';

export const ADSettings: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const { TailwindResponsive } = useTailwindResponsive();

  useSetupSettingsForm();
  const subscriptionActive = useSubscriptionActive();

  const artwork = useReactiveVar(DetailsData.work);
  const form = useReactiveVar(DetailsData.settingsForm);

  const role = useSelector(getUserRole);

  const [changeAvail] = useMutation(CHANGE_AVAILABILITY_FOR_LOAN);

  if (!artwork || !form || role !== UserRole.Collector) return null;

  return (
    <View style={TailwindResponsive(`mt-8`)}>
      <Controller
        control={form.control}
        name="availableForLoan"
        render={({ field: { onChange, value } }) => (
          <Checkbox
            isChecked={value}
            label="Available for Loan"
            onPress={() => {
              if (subscriptionActive) {
                onChange(!value);
                changeAvail({
                  variables: {
                    userId: authUser?.uid,
                    workId: artwork.id,
                  },
                });
              } else {
                HeaderVars.showNeedSubModal(true);
              }
            }}
          />
        )}
      />
    </View>
  );
};

export default ADSettings;
