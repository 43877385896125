import React, { FunctionComponent } from 'react';
import { Circle, G, Line, Svg } from 'react-native-svg';
import { ISVGProps } from './Types';

export const RegisterWorkIcon: FunctionComponent<ISVGProps> = ({
  color,
  width,
}) => {
  const iconWidth = width || 48;

  return (
    <Svg
      width={iconWidth ?? `40`}
      height={iconWidth ?? `40`}
      viewBox="0 0 40 40"
    >
      <G id="Group_130" data-name="Group 130" transform="translate(-168 -719)">
        <Circle
          id="Ellipse_50"
          data-name="Ellipse 50"
          cx="20"
          cy="20"
          r="20"
          transform="translate(168 719)"
          fill={color ?? `#1d00ff`}
        />
        <G id="_" data-name="+" transform="translate(180 731)">
          <Line
            id="Line_177"
            data-name="Line 177"
            y2="16"
            transform="translate(7.822)"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
          />
          <Line
            id="Line_178"
            data-name="Line 178"
            x1="16"
            transform="translate(0 7.822)"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
          />
        </G>
      </G>
    </Svg>
  );
};

export default RegisterWorkIcon;
