import { createSlice } from '@reduxjs/toolkit';

export interface IAgreementsState {
  hasAgreed: boolean;
}

const initialState: IAgreementsState = {
  hasAgreed: false,
};

const agreementsSlice = createSlice({
  name: 'agreements',
  initialState,
  reducers: {
    setHasAgreed: (state, action) => {
      state.hasAgreed = action.payload;
    },
  },
});

const { actions, reducer } = agreementsSlice;
export const { setHasAgreed } = actions;
export { reducer as agreementsReducer };

export type AgreementsState = ReturnType<typeof reducer>;
export type AgreementsAction = typeof setHasAgreed;
