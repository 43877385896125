import { Loader } from 'components/graphics';
import React, { FunctionComponent } from 'react';
import { ActivityIndicator, Platform, View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { GraphikMediumTextLg, GraphikSemiTextMd } from '../styled';

interface ISpinLoaderProps {
  color?: string;
  placeholderText?: string | null;
  width?: number;
}

export const SpinLoader: FunctionComponent<ISpinLoaderProps> = ({
  color,
  placeholderText,
  width,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const loaderWidth = width || 275;

  if (Platform.OS !== 'web') {
    return (
      <View style={TailwindResponsive(`flex-1 items-center justify-center`)}>
        <View style={TailwindResponsive(`items-center`)}>
          <ActivityIndicator
            size="large"
            color={color || Styles.Colours.Dark1}
          />
          {!!placeholderText && (
            <GraphikSemiTextMd style={TailwindResponsive(`mt-4 px-12`)}>
              {placeholderText}
            </GraphikSemiTextMd>
          )}
        </View>
      </View>
    );
  }

  return (
    <View style={TailwindResponsive(`flex-1 items-center justify-center`)}>
      <View style={TailwindResponsive(`items-center`)}>
        <Loader color={color || Styles.Colours.Dark1} width={loaderWidth} />
        {!!placeholderText && (
          <GraphikMediumTextLg style={TailwindResponsive(`mt-2 mobWeb:px-12`)}>
            {placeholderText}
          </GraphikMediumTextLg>
        )}
      </View>
    </View>
  );
};
