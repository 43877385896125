import { useRoute } from '@react-navigation/native';
import OpenInNewIcon from 'components/graphics/OpenInNewIcon';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import { GraphikMediumTextSm, GraphikTextSm } from 'components/shared/styled';
import { ENV } from 'constants/constants';
import { format, parseISO } from 'date-fns';
import React, { FunctionComponent, useMemo } from 'react';
import { View } from 'react-native';
import Styles from 'style';
import { ProvenanceItem } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IProvLineItemProps {
  isFinal: boolean;
  provenance: ProvenanceItem;
}

export const ProvenanceLineItem: FunctionComponent<IProvLineItemProps> = ({
  isFinal,
  provenance,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const route = useRoute();

  const ethLink = useMemo(() => {
    if (ENV !== 'PRODUCTION')
      return `https://goerli.etherscan.io/tx/${provenance.individualEtheriumTransaction}`;
    return `https://etherscan.io/tx/${provenance.individualEtheriumTransaction}`;
  }, [provenance]);

  return (
    <View style={TailwindResponsive('mb-4 border-b border-Gray1')}>
      <View
        style={TailwindResponsive(
          'flex flex-row justify-between mb-2 web:flex-wrap mobWeb:flex-col mob:w-full',
        )}
      >
        <GraphikMediumTextSm style={TailwindResponsive(`mr-4`)}>
          {format(parseISO(provenance.date!), 'PPP')}
        </GraphikMediumTextSm>
        <GraphikTextSm>{provenance.action}</GraphikTextSm>
      </View>

      {route.name !== 'ArtworkPublicView' && (
        <>
          {!!provenance.contractId && (
            <GraphikTextSm
              color={Styles.Colours.Dark3}
              style={TailwindResponsive('mb-2')}
            >
              {provenance.contractId}
            </GraphikTextSm>
          )}

          {!!provenance.certificateId && (
            <GraphikTextSm
              color={Styles.Colours.Dark3}
              style={TailwindResponsive('mb-2')}
            >
              {provenance.certificateId}
            </GraphikTextSm>
          )}

          {isFinal && !!provenance.individualEtheriumTransaction && (
            <GraphikTextSm
              color={Styles.Colours.Dark3}
              style={TailwindResponsive('mb-2')}
            >
              Current Blockchain Verification Information
            </GraphikTextSm>
          )}

          {!!provenance.contractId && !!provenance.certificateId && (
            <GraphikTextSm color={Styles.Colours.Dark3}>
              {`Transaction Hash: ${
                provenance.individualEtheriumTransaction
                  ? ''
                  : ' Blockchain write pending...'
              }`}
            </GraphikTextSm>
          )}

          {!!provenance.individualEtheriumTransaction && (
            <View
              style={TailwindResponsive(
                'flex flex-row flex-wrap justify-end mb-2 xl:flex-nowrap xl:justify-between',
              )}
            >
              <GraphikTextSm
                color={Styles.Colours.Dark3}
                style={TailwindResponsive('w-full xl:w-2/3')}
              >
                {`${provenance.individualBlockchainHash}`}
              </GraphikTextSm>

              <ExternalLink link={ethLink}>
                <View style={TailwindResponsive('flex flex-row')}>
                  <GraphikTextSm color={Styles.Colours.Primary}>
                    Etherscan Record
                  </GraphikTextSm>
                  <View style={TailwindResponsive('mt-0.5 ml-1')}>
                    <OpenInNewIcon color={Styles.Colours.Primary} width={16} />
                  </View>
                </View>
              </ExternalLink>
            </View>
          )}
        </>
      )}
    </View>
  );
};
