import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { StripePromise } from 'utilities/Stripe';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import StripePaymentFLow, {
  IPaymentFlowResponse,
} from '../stripe/StripePaymentFlow';
import { StripeCustomAppearance } from '../stripe/general';
import { GraphikSemiTextLg, GraphikTextSm } from '../styled';
import { SubscriptionOption } from './SubscriptionSelector';

export interface ISubPaymentProps {
  callback?: (input: IPaymentFlowResponse) => void;
  choice: SubscriptionOption;
  payId: string;
  redirectUrl?: string;
}

export const SubPayment: FunctionComponent<ISubPaymentProps> = ({
  callback,
  choice,
  payId,
  redirectUrl,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  let choiceHeader = 'Collector Standard';
  let priceHeader = '49';
  if (choice === SubscriptionOption.Basic) {
    choiceHeader = 'Collector Basic';
    priceHeader = '15';
  } else if (choice === SubscriptionOption.Premium) {
    choiceHeader = 'Collector Premium';
    priceHeader = '150';
  } else if (choice === SubscriptionOption.Gallery) {
    choiceHeader = 'Gallery Standard';
    priceHeader = '150';
  }

  const options: StripeElementsOptions = {
    clientSecret: payId,
    appearance: StripeCustomAppearance,
  };

  return (
    <View style={TailwindResponsive(`items-center`)}>
      <View style={TailwindResponsive('max-w-3xl w-full')}>
        <View
          style={TailwindResponsive(
            `border-b border-Dark1 flex flex-row justify-between pb-4 mb-6`,
          )}
        >
          <GraphikSemiTextLg>{choiceHeader}</GraphikSemiTextLg>

          <View>
            <GraphikSemiTextLg>${priceHeader}</GraphikSemiTextLg>
            <GraphikTextSm>per month</GraphikTextSm>
          </View>
        </View>

        <Elements options={options} stripe={StripePromise}>
          <StripePaymentFLow
            callback={callback}
            choice={choice}
            paymentIntentId={payId}
            redirectUrl={redirectUrl}
          />
        </Elements>
      </View>
    </View>
  );
};

export default SubPayment;
