import { useReactiveVar } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ExitButtonIcon } from 'components/graphics';
import { ProfileScreens } from 'components/views/profile/apollo';
import { FCStackNavProp, RegisterWorkRouteProp } from 'navigation';
import { FunctionComponent } from 'react';
import { Modal, Pressable, ScrollView, View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { FairchainButton } from '../button/FairchainButton';
import { GraphikSemiTextLg, GraphikTextMd } from '../styled';
import HeaderVars from './apollo.header';

export const SubSignUpModal: FunctionComponent = () => {
  const navigation = useNavigation<FCStackNavProp>();
  const route = useRoute<RegisterWorkRouteProp>();
  const { TailwindResponsive } = useTailwindResponsive();

  const showModal = useReactiveVar(HeaderVars.showNeedSubModal);

  return (
    <Modal
      style={TailwindResponsive('flex-1 items-center py-10')}
      transparent
      visible={showModal}
    >
      <Pressable
        onPress={() => {
          HeaderVars.showNeedSubModal(false);
        }}
        style={TailwindResponsive(
          `bg-black/50 flex-1 items-center justify-center mobWeb:p-4`,
        )}
      >
        <Pressable
          onStartShouldSetResponder={() => true}
          onTouchEnd={(e) => {
            e.stopPropagation();
          }}
          style={[
            TailwindResponsive(
              `bg-Light1 flex shrink rounded-lg max-w-2xl m-10 w-full`,
            ),
            { cursor: 'default' },
          ]}
        >
          <ScrollView style={TailwindResponsive(`p-6`)}>
            <View
              style={TailwindResponsive(
                `border-b flex flex-row justify-between mb-10 pb-2`,
              )}
            >
              <GraphikSemiTextLg>Subscription Required</GraphikSemiTextLg>

              <Pressable
                onPress={() => {
                  HeaderVars.showNeedSubModal(false);
                }}
                style={TailwindResponsive(`self-end`)}
              >
                <ExitButtonIcon color={Styles.Colours.Dark1} />
              </Pressable>
            </View>

            <View>
              <GraphikTextMd>
                To register artworks and access associated features, a
                supporting subscription is required.
              </GraphikTextMd>

              <View style={TailwindResponsive(`items-center`)}>
                <FairchainButton
                  buttonStyle={TailwindResponsive(
                    `bg-Primary mt-6 px-10 rounded-full`,
                  )}
                  label="View Options"
                  onPress={() => {
                    // So we can come back
                    if (route.name === 'RegisterWork')
                      HeaderVars.draftWorkId(route.params?.draftId);
                    else HeaderVars.draftWorkId(null);

                    navigation.navigate('Profile', {
                      screen: ProfileScreens.subscription,
                    });
                    HeaderVars.showNeedSubModal(false);
                  }}
                  textStyle={TailwindResponsive('text-Light1')}
                />
              </View>
            </View>
          </ScrollView>
        </Pressable>
      </Pressable>
    </Modal>
  );
};

export default SubSignUpModal;
