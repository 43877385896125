import { MESSAGES, createVeriffFrame } from '@veriff/incontext-sdk';
import { FairchainLogo } from 'components/graphics';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { SpinLoader } from 'components/shared/loader';
import { GraphikMediumTextSm } from 'components/shared/styled';
import { FunctionComponent, useState } from 'react';
import {
  Linking,
  Pressable,
  SafeAreaView,
  ScrollView,
  View,
} from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import {
  useGetVeriffSessionUrl,
  useHandleVerificationSubmitted,
} from './helpers';

export const Identify: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const [loading, setLoading] = useState<boolean>(false);

  const sessionUrl = useGetVeriffSessionUrl();
  const submitCallback = useHandleVerificationSubmitted();

  const onEventCallback = (msg: MESSAGES) => {
    switch (msg) {
      case MESSAGES.FINISHED:
        setLoading(true);
        submitCallback();
        break;
      default:
        break;
    }
  };

  const openVeriff = () => {
    createVeriffFrame({
      url: sessionUrl!,
      onEvent: onEventCallback,
      onReload: () => {
        window.location.reload();
      },
    });
  };

  return (
    <SafeAreaView
      style={TailwindResponsive(`flex-1 items-center justify-center`)}
    >
      <ScrollView style={TailwindResponsive(`p-8 w-1/2 mobWeb:w-full`)}>
        <FairchainLogo />

        <GraphikMediumTextSm style={TailwindResponsive(`mt-10`)}>
          Fairchain requires artists to verify their identity before they can
          register works. This ensures each work backed by Fairchain has been
          properly vetted and authenticated. Our partner, Veriff, will prompt
          you to upload or take an image of your government-issued ID document.
        </GraphikMediumTextSm>

        <GraphikMediumTextSm style={TailwindResponsive(`mt-8`)}>
          To successfully complete this step, please confirm the following:
        </GraphikMediumTextSm>

        <View style={TailwindResponsive(`ml-4 mt-1`)}>
          <View style={TailwindResponsive(`flex flex-row`)}>
            <GraphikMediumTextSm style={TailwindResponsive(`w-4`)}>
              &#x2022;
            </GraphikMediumTextSm>
            <GraphikMediumTextSm>
              You have an acceptable government-issued ID document on hand such
              as a passport, driver&apos;s license, residence permit, or state
              ID.
            </GraphikMediumTextSm>
          </View>

          <View style={TailwindResponsive(`flex flex-row`)}>
            <GraphikMediumTextSm style={TailwindResponsive(`w-4`)}>
              &#x2022;
            </GraphikMediumTextSm>
            <GraphikMediumTextSm>
              The name on the document matches the name you entered in the
              signup form.
            </GraphikMediumTextSm>
          </View>

          <View style={TailwindResponsive(`flex flex-row`)}>
            <GraphikMediumTextSm style={TailwindResponsive(`w-4`)}>
              &#x2022;
            </GraphikMediumTextSm>
            <GraphikMediumTextSm>
              Your device has a working and uncovered camera.
            </GraphikMediumTextSm>
          </View>
        </View>

        {!sessionUrl || loading ? (
          <SpinLoader />
        ) : (
          <FairchainButton
            buttonStyle={TailwindResponsive(
              `bg-VeriffGreen rounded-full my-12`,
            )}
            label="Continue to Verification"
            onPress={openVeriff}
            textStyle={TailwindResponsive(`text-Light1`)}
          />
        )}

        <GraphikMediumTextSm>
          Questions? Our team is available to help – email
          <Pressable
            onPress={() => {
              Linking.openURL('mailto: support@fairchain.art');
            }}
            style={TailwindResponsive(`ml-1 underline`)}
          >
            support@fairchain.art
          </Pressable>
          .
        </GraphikMediumTextSm>
      </ScrollView>
    </SafeAreaView>
  );
};
