export const Colours = {
  Dark1: '#000000',
  Dark2: 'rgba(0,0,0,0.8)',
  Dark3: 'rgba(0,0,0,0.6)',
  Dark4: 'rgba(0,0,0,0.4)',
  Dark5: 'rgba(0,0,0,0.2)',
  Dark6: 'rgba(0,0,0,0.05)',
  Light1: '#FFFFFF',
  Light2: '#FBFBFB',
  Light3: 'rgba(255,255,255,0.05)',
  Light4: '#0000000D',
  Light5: '#F5F5F5',
  MidGray: '#E8E8E8',
  Overlay: 'rgba(0,0,0,0,0.5)',
  Primary: '#1D00FF',
  Gray1: '#E5E5E5',
  Gray2: '#00000099',
  Gray3: '#F5F5F599',
  DarkGreen: '#004A0A',
  LightGreen: '#E3FFE6',
  Success: '#00E917',
  DarkRed: '#A70000',
  LightRed: '#FFD2D2',
  LightBlue: '#B3E5FC',
  Error: '#FF0000',
  Indigo: '#635BFF',
  BackgroundWhite: '#E8F0FE',
  Warning: '#f8ac00',
};

export default Colours;
