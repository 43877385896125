import { GridFilled, GridOutline } from 'components/graphics';
import React, { FunctionComponent } from 'react';
import { Pressable } from 'react-native';

interface IGridIconProps {
  isFilled: boolean;
  onPress: (arg: any) => void;
}

export const GridIcon: FunctionComponent<IGridIconProps> = ({
  isFilled,
  onPress,
}) => (
  <Pressable onPress={onPress}>
    {isFilled ? <GridFilled /> : <GridOutline />}
  </Pressable>
);
