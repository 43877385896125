import { makeVar } from '@apollo/client';
import { IBuyerForm } from 'components/views/transferWork';
import { UseFormReturn } from 'react-hook-form';
import { Work } from 'types';

export enum InvoiceChoice {
  INCLUDE,
  UPLOAD,
  DONTINCLUDE,
}

export const buyerRegistrationFormVar = makeVar<UseFormReturn<
  IBuyerForm,
  any
> | null>(null);

export const userInvoiceChoiceVar = makeVar<InvoiceChoice>(
  InvoiceChoice.INCLUDE,
);

export const createTransactionErrorVar = makeVar<string | null>(null);
export const sellerBuyerLink = makeVar<string | null>(null);
export const webViewOpenVar = makeVar<boolean>(false);
export const webViewUrlVar = makeVar<string>('');
export const worksVar = makeVar<Work[]>([]);
