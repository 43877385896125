import HEICPlaceholder from 'assets/images/HEIC-Upload.png';
import { ImageIcon } from 'components/graphics';
import React, { FunctionComponent } from 'react';
import { Image, Platform } from 'react-native';
import WebView from 'react-native-webview';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import ProgressiveImage from './ProgressiveImage';

export interface ICenteredImageProps {
  altText?: string;
  isLocal?: boolean;
  uri: string | undefined;
}

export const CenteredImage: FunctionComponent<ICenteredImageProps> = ({
  altText,
  isLocal,
  uri,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const containsImage = (uri && uri.startsWith('https://')) || isLocal;

  if (
    uri?.includes('youtube.com') ||
    uri?.includes('vimeo') ||
    uri?.includes('youtu.be')
  ) {
    if (Platform.OS !== 'web')
      return (
        <WebView
          javaScriptEnabled
          scalesPageToFit
          source={{
            uri,
          }}
        />
      );
    return (
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        height="100%"
        src={uri}
        title="YouTube video player"
      />
    );
  }

  if (
    uri?.includes('data:image/heic;base64') ||
    uri?.includes('data:application/octet-stream;base64')
  ) {
    return (
      <Image
        accessibilityLabel={altText}
        style={TailwindResponsive(`flex-1 m-3`)}
        resizeMode="contain"
        source={HEICPlaceholder}
      />
    );
  }

  if (!containsImage) return <ImageIcon width={40} />;

  if (uri && uri.includes('tr=w-2500')) {
    return <ProgressiveImage altText={altText} uri={uri} />;
  }

  return (
    <Image
      accessibilityLabel={altText}
      resizeMode="contain"
      source={{ uri }}
      style={TailwindResponsive(`flex-1 m-3`)}
    />
  );
};

export default CenteredImage;
