import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import { ISVGProps } from './Types';

export const ChaliceIcon: FunctionComponent<ISVGProps> = ({
  color,
  width,
  inverted,
}) => {
  const iconWidth = width || 40;

  return !inverted ? (
    <Svg height={iconWidth} viewBox="0 0 24 24" width={iconWidth} fill={color}>
      <Path d="M0 0h24v24H0z" fill="none" />
      <Path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </Svg>
  ) : (
    <Svg height={iconWidth} viewBox="0 0 24 24" width={iconWidth} fill={color}>
      <Path
        transform={`scale(1, -1) translate(0, -${iconWidth})`}
        d="M0 0h24v24H0z"
        fill="none"
      />
      <Path
        transform={`scale(1, -1) translate(0, -${iconWidth})`}
        d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
      />
    </Svg>
  );
};
