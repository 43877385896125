import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { ASSOCIATE_GALLERY_WITH_ARTIST } from 'api/requests.v2';
import { RoundedButton } from 'components/shared/button';
import { DefaultTextInput } from 'components/shared/form';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Text, View } from 'react-native';
import Styles from 'style';
import { trimFormData } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import * as yup from 'yup';

export const AssociateGallery: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const [associateGallery] = useMutation(ASSOCIATE_GALLERY_WITH_ARTIST);
  const [isSuccessfullySubmitted, setSuccessfullySubmitted] = useState('');
  const [isSubmittedError, setSubmittedError] = useState('');

  const associateGallerySchema = yup.object().shape({
    artistEmail: yup.string().required('This is a required field'),
    galleryEmail: yup.string().required('This is a required field'),
  });

  const {
    control,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      artistEmail: '',
      galleryEmail: '',
    },
    resolver: yupResolver(associateGallerySchema),
  });

  const onSubmit = async (data: any) => {
    const trimmedData = trimFormData(data);
    const associateResponse = await associateGallery({
      variables: {
        artistEmail: trimmedData.artistEmail,
        galleryEmail: trimmedData.galleryEmail,
      },
    });

    if (associateResponse.data.response.success === true) {
      setSuccessfullySubmitted('🤠 Artist and Gallery Associated! 🎊');
    } else {
      setSubmittedError(associateResponse.data.response.errorMessage);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setTimeout(() => {
        setSubmittedError('');
        setSuccessfullySubmitted('');
      }, 3000);
    }
  }, [reset, isSubmitSuccessful]);

  return (
    <View style={TailwindResponsive('pb-12')}>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <DefaultTextInput
            value={value}
            label="* Artist Email"
            handleChange={onChange}
            errorMessage={errors.artistEmail?.message}
          />
        )}
        name="artistEmail"
        defaultValue=""
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <DefaultTextInput
            value={value}
            label="* Gallery Email"
            handleChange={onChange}
            errorMessage={errors.galleryEmail?.message}
          />
        )}
        name="galleryEmail"
        defaultValue=""
      />
      <View style={TailwindResponsive('m-5')}>
        <RoundedButton
          label="Associate Gallery With Artist"
          onPress={() => {
            handleSubmit(onSubmit)();
          }}
          backgroundColor={Styles.Colours.Primary}
          color={Styles.Colours.Light1}
        />
        {!!isSuccessfullySubmitted && (
          <Text style={{ color: Styles.Colours.Success }}>
            {isSuccessfullySubmitted}
          </Text>
        )}
        {!!isSubmittedError && (
          <Text style={{ color: Styles.Colours.Error }}>
            {isSubmittedError}
          </Text>
        )}
      </View>
    </View>
  );
};
