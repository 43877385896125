import { UserRole } from 'constants/enums';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { getFeatureFlags } from 'store/general';
import { getSubUsers, getUserRole } from 'store/user';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ProfileScreens } from './apollo';
import ProfileNavButton from './components/ProfileNavButton';

export const ProfileNav: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const features = useSelector(getFeatureFlags);
  const subUsers = useSelector(getSubUsers);
  const userRole = useSelector(getUserRole);

  return (
    <View style={TailwindResponsive(`pl-10 py-4`)}>
      <View style={TailwindResponsive(`border-l border-Dark1 pl-4`)}>
        <ProfileNavButton label="My Details" screen={ProfileScreens.personal} />
        {(userRole === UserRole.Collector || features.gallerySubscription) && (
          <ProfileNavButton
            label="Subscription"
            screen={ProfileScreens.subscription}
          />
        )}
        {subUsers && subUsers.length !== 0 && (
          <ProfileNavButton
            label="User Management"
            screen={ProfileScreens.userManagement}
          />
        )}
      </View>
    </View>
  );
};

export default ProfileNav;
