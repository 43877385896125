import { FairchainLogo } from 'components/graphics';
import { CenteredImage } from 'components/shared/image';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import {
  GraphikSemiTextHuge,
  GraphikSemiTextLg,
  GraphikTextLarge,
  GraphikTextSm,
  IBMTextLarge,
} from 'components/shared/styled';
import { AssetType } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { Image, View } from 'react-native';
import { WorkPublicView, getDisplayName } from 'types';
import { formatEditionText, getWorkDimensions } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { Provenance } from '../artworkDetails/components/Provenance';

interface IArtowrkPublicViewMobWebProps {
  displayName: string;
  sigSize: number[];
  work: WorkPublicView;
  isRedactedView?: boolean;
}

export const ArtworkPublicViewMobWeb: FunctionComponent<
  IArtowrkPublicViewMobWebProps
> = ({ displayName, sigSize, work, isRedactedView }) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const imageBgColor = work?.publicViewOptions?.imageBgColor || '#F5F5F5';

  const headerCopy =
    work?.assetType === AssetType.Collectible
      ? 'This page verifies that the work has been registered on Fairchain. This work is accompanied by a secure blockchain-backed digital certificate of title and authenticity certifying that the creator declares the artwork listed below to be an authentic, original and unique piece of their own creative efforts.'
      : 'This page verifies that the work has been registered on Fairchain. Purchase of this work is accompanied by a secure blockchain-backed digital certificate of title and authenticity certifying that the artist or their estate declares the artwork listed below to be an authentic, original and unique piece of art of their own creative efforts and executed by them, the undersigned.';

  const assetIdentifier =
    work?.assetType === AssetType.Collectible ? 'Item' : 'Artwork';

  return (
    <View style={TailwindResponsive(`bg-Light1 p-4 pb-12`)}>
      <ExternalLink link="https://fairchain.art/">
        <FairchainLogo />
      </ExternalLink>
      <GraphikSemiTextLg style={TailwindResponsive('my-8 w-full')}>
        Certificate of Title and Authenticity — Public View
      </GraphikSemiTextLg>

      <GraphikTextSm style={TailwindResponsive(`mb-8 w-full`)}>
        {headerCopy}
      </GraphikTextSm>

      <View style={TailwindResponsive('flex flex-1')}>
        {work?.embeddedCode ? (
          <View
            style={{
              ...TailwindResponsive(`bg-Light5 mb-8 w-full`),
              backgroundColor: imageBgColor,
            }}
          >
            <div
              style={TailwindResponsive('flex flex-1')}
              className="content"
              dangerouslySetInnerHTML={{ __html: work.embeddedCode }}
            />
          </View>
        ) : (
          <View
            style={TailwindResponsive(
              `flex-1 bg-Light5 mb-8 p-4 w-full h-96 lg:w-165 lg:h-165`,
            )}
          >
            <CenteredImage uri={work?.images[0]} />
          </View>
        )}

        <View style={TailwindResponsive('flex flex-1 pr-8')}>
          <View style={TailwindResponsive('mb-1')}>
            {work.assetType === AssetType.Artwork && (
              <GraphikSemiTextHuge>
                {work?.artist.artistName ??
                  `${work?.artist.firstName} ${work?.artist.lastName}`}
              </GraphikSemiTextHuge>
            )}
            {work.assetType === AssetType.Collectible && (
              <GraphikSemiTextHuge>
                {getDisplayName(work?.artist)}
              </GraphikSemiTextHuge>
            )}
            <GraphikSemiTextHuge>{work?.title}</GraphikSemiTextHuge>
            <GraphikSemiTextHuge>{work?.yearProduced}</GraphikSemiTextHuge>
          </View>

          <View style={TailwindResponsive('mb-10')}>
            {work.assetType === AssetType.Artwork && (
              <GraphikTextLarge>{getWorkDimensions(work)}</GraphikTextLarge>
            )}
            {work.assetType === AssetType.Collectible && (
              <GraphikTextLarge>
                {work.collectible?.measurements}
              </GraphikTextLarge>
            )}
            {!!formatEditionText(work) && (
              <GraphikTextLarge>{formatEditionText(work)}</GraphikTextLarge>
            )}
            {work.assetType === AssetType.Artwork && (
              <GraphikTextLarge>{work?.medium}</GraphikTextLarge>
            )}
            {work.assetType === AssetType.Collectible && (
              <GraphikTextLarge>{work.collectible?.materials}</GraphikTextLarge>
            )}
          </View>

          {work.assetType === AssetType.Collectible &&
            work.collectible?.contributors &&
            work.collectible?.contributors?.length > 0 && (
              <View style={TailwindResponsive('mb-10')}>
                <GraphikSemiTextLg>Contributors</GraphikSemiTextLg>
                {work.collectible?.contributors?.map((contributor) => (
                  <GraphikTextLarge key={contributor.id}>
                    {contributor.firstName} {contributor.lastName},{' '}
                    {contributor.role}
                  </GraphikTextLarge>
                ))}
              </View>
            )}

          {!isRedactedView && (
            <View style={TailwindResponsive('mb-10')}>
              <GraphikSemiTextLg>
                Fairchain {assetIdentifier} ID
              </GraphikSemiTextLg>
              <IBMTextLarge>{work?.workIdentifier}</IBMTextLarge>
            </View>
          )}

          <Provenance
            isRedactedView={isRedactedView || false}
            provenance={work ? work.provenance : []}
          />

          {!isRedactedView && (
            <View style={TailwindResponsive('mb-10')}>
              <GraphikSemiTextLg>Title Manager</GraphikSemiTextLg>
              <GraphikTextLarge>{displayName}</GraphikTextLarge>
            </View>
          )}

          <View style={TailwindResponsive(`items-start h-48`)}>
            {work?.artist.signature && sigSize.length > 0 && (
              <View style={{ height: sigSize[1], minWidth: sigSize[0] }}>
                <Image
                  source={{ uri: work?.artist.signature }}
                  style={{
                    resizeMode: 'contain',
                    height: '100%',
                  }}
                />
                <GraphikTextSm>Artist&apos;s Signature</GraphikTextSm>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};
