import FileUploader from 'components/shared/fileUploader/FileUploader';
import { GraphikTextSm } from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const UploadInvoice: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View
      style={[
        TailwindResponsive(
          `border flex-row items-center justify-between py-6 px-8 w-full h-full mobWeb:flex-col`,
        ),
        { zIndex: -1 },
      ]}
    >
      <GraphikTextSm style={TailwindResponsive('mr-6 mobWeb:mb-6 mobWeb:mr-0')}>
        Upload your own invoice here. We will include it with the sales
        agreement that is sent to the client.
      </GraphikTextSm>
      <FileUploader title="Upload Invoice" width={60} />
    </View>
  );
};

export default UploadInvoice;
