import React, { FunctionComponent, ReactNode } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { GraphikSemiTextLg } from '../styled';

export interface IMainHeaderProps {
  children: ReactNode;
  hideIosBorder?: boolean;
  title?: string;
}

export const MainHeader: FunctionComponent<IMainHeaderProps> = ({
  children,
  hideIosBorder,
  title,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const borderStyle = hideIosBorder
    ? ''
    : 'border-b border-solid h-14 border-black border-opacity-50';
  const headerStyle = 'flex-row items-center w-full';

  return (
    <View style={[TailwindResponsive(`${headerStyle} ${borderStyle}`)]}>
      <GraphikSemiTextLg>{title}</GraphikSemiTextLg>

      {children}
    </View>
  );
};
export default MainHeader;
