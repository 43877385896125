import React, { FunctionComponent } from 'react';
import { Text, View } from 'react-native';
import Styles from 'style';

interface InputProps {
  label: string;
  value?: string | undefined;
  height: number;
  width: number;
}

export const ImmutableInput: FunctionComponent<InputProps> = ({
  label,
  value,
  height,
  width,
}) => (
  <View>
    <View
      style={{
        height,
        width,
        backgroundColor: Styles.Colours.Dark1,
        paddingHorizontal: 0,
        borderBottomWidth: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '5px',
      }}
    >
      <Text
        style={{
          fontFamily: Styles.Fonts.FontFamily.SansMedium,
          fontSize: Styles.Fonts.FontSize.Medium,
          color: Styles.Colours.Light1,
        }}
      >
        {value
          ? (parseInt(value, 10) / 100).toLocaleString(undefined, {
              style: 'percent',
            })
          : value}
      </Text>
    </View>
    <Text
      style={{
        color: Styles.Colours.Gray2,
        fontSize: Styles.Fonts.FontSize.Tiny,
        fontFamily: Styles.Fonts.FontFamily.SansRegular,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {label}
    </Text>
  </View>
);

export default ImmutableInput;
