import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { ADD_ASSET_PHYSICAL_ID } from 'api/requests.v2';
import { RoundedButton } from 'components/shared/button';
import { DefaultTextInput } from 'components/shared/form';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Text, View } from 'react-native';
import Styles from 'style';
import { trimFormData } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import * as yup from 'yup';

// Associates an asset with a physical identifier (NFC/RFID)
export const AddAssetPhysicalId: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const [addAssetPhysicalId] = useMutation(ADD_ASSET_PHYSICAL_ID);
  const [isSuccessfullySubmitted, setSuccessfullySubmitted] = useState('');
  const [isSubmittedError, setSubmittedError] = useState('');

  const addAssetPhysicalIdSchema = yup.object().shape({
    assetId: yup.string().required('This is a required field'),
    assetPhysicalId: yup.string().required('This is a required field'),
  });

  const {
    control,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      assetId: '',
      assetPhysicalId: '',
    },
    resolver: yupResolver(addAssetPhysicalIdSchema),
  });

  const onSubmit = async (data: any) => {
    const trimmedData = trimFormData(data);
    const updateResponse = await addAssetPhysicalId({
      variables: {
        assetId: trimmedData.assetId,
        assetPhysicalId: trimmedData.assetPhysicalId,
      },
    });

    if (updateResponse.data.response.success === true) {
      setSuccessfullySubmitted('🤠 Physical Identifier Added! 🚀');
    } else {
      setSubmittedError(updateResponse.data.response.errorMessage);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setTimeout(() => {
        setSubmittedError('');
        setSuccessfullySubmitted('');
      }, 3000);
    }
  }, [reset, isSubmitSuccessful]);

  return (
    <View style={TailwindResponsive('pb-12 w-1/2')}>
      <View style={TailwindResponsive(``)}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              value={value}
              label="* Fairchain Asset ID"
              handleChange={onChange}
              errorMessage={errors.assetId?.message}
            />
          )}
          name="assetId"
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              value={value}
              label="* Asset Physical Identifier"
              handleChange={onChange}
              errorMessage={errors.assetPhysicalId?.message}
            />
          )}
          name="assetPhysicalId"
          defaultValue=""
        />
        <View style={TailwindResponsive('m-5')}>
          <RoundedButton
            label="Add Physical Identifier"
            onPress={() => {
              handleSubmit(onSubmit)();
            }}
            backgroundColor={Styles.Colours.Primary}
            color={Styles.Colours.Light1}
          />
          {!!isSuccessfullySubmitted && (
            <Text style={{ color: Styles.Colours.Success }}>
              {isSuccessfullySubmitted}
            </Text>
          )}
          {!!isSubmittedError && (
            <Text style={{ color: Styles.Colours.Error }}>
              {isSubmittedError}
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};
