import Colours from './colours';
import Fonts from './fonts';
import Spacings from './spacings';
import Text from './text';

export const combinedStyles = {
  Colours,
  Fonts,
  Spacings,
  Text,
};
