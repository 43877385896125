import { useLazyQuery } from '@apollo/client';
import { GET_NEW_JWT } from 'api/requests.v2';
import * as Linking from 'expo-linking';
import { signInWithCustomToken } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import * as RNP from 'react-native-url-polyfill';
import { useDispatch } from 'react-redux';
import { setRedirect } from 'store/general';
import { setValidAuthToken } from 'store/user';
import { FCUser } from 'store/user/apollo';
import * as FBUtil from 'utilities/Firebaseutil';
import LogError from 'utilities/LogError';

export const useRedirect = () => {
  const dispatch = useDispatch();

  const [getNewJWT, { data }] = useLazyQuery(GET_NEW_JWT);

  const firebaseAuth = FBUtil.getFBAuth();

  const [firstRedirect, setFirstRedirect] = useState(true);
  const [initialAuth, setInitialAuth] = useState<string | null>(null);
  const [isAdminUser, setIsAdminUser] = useState<boolean>(false);

  useEffect(() => {
    if (data && data.response) {
      setInitialAuth(data.response);
    }
  }, [data]);

  useEffect(() => {
    const handleSignInWithCustomToken = async () => {
      try {
        if (isAdminUser) {
          await signInWithCustomToken(firebaseAuth, initialAuth!);
        } else {
          FCUser.AuthToken(initialAuth);
        }
      } catch (error) {
        LogError.logError(
          error,
          `Error in signing in with custom token: ${error}`,
        );
        await getNewJWT({ variables: { oldJWT: initialAuth! } });
      }
    };

    if (initialAuth) handleSignInWithCustomToken();
  }, [firebaseAuth, getNewJWT, initialAuth, isAdminUser]);

  useEffect(() => {
    const handleRedirect = async () => {
      try {
        setFirstRedirect(false);
        const url = await Linking.getInitialURL();
        if (url) {
          const urlObject =
            Platform.OS === 'web' ? new URL(url) : new RNP.URL(url);
          const { pathname, search } = urlObject;
          const redirect = search.includes('authToken')
            ? pathname
            : pathname + search;
          if (
            redirect &&
            redirect !== '' &&
            redirect !== '/' &&
            !redirect.includes('login')
          ) {
            dispatch(setRedirect(redirect));
          }
          const isAdmin = urlObject.searchParams.get('isAdmin');
          if (isAdmin === 'true') {
            dispatch(setValidAuthToken(true));
            setIsAdminUser(true);
          }

          const authToken = urlObject.searchParams.get('authToken');
          if (authToken) setInitialAuth(authToken);
        }
      } catch (error) {
        LogError.logError(error, `Error in setting redirect: ${error}`);
      }
    };

    if (firstRedirect) handleRedirect();
  }, [dispatch, firstRedirect]);
};
