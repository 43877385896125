import { FairchainLogo } from 'components/graphics';
import * as Updates from 'expo-updates';
import React, { FunctionComponent, useEffect } from 'react';
import { SafeAreaView, View } from 'react-native';
import LogError from 'utilities/LogError';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { SpinLoader } from '../loader';
import { GraphikTextTiny } from '../styled';

export const AppUpdate: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  useEffect(() => {
    const grabUpdate = async () => {
      try {
        await Updates.fetchUpdateAsync();
      } catch (error) {
        LogError.logError(error, `Error in fetching update.`);
      } finally {
        await Updates.reloadAsync();
      }
    };

    grabUpdate();
  }, []);

  return (
    <SafeAreaView
      style={TailwindResponsive(`flex-1 items-center justify-center`)}
    >
      <FairchainLogo width="500" height="35" />
      <GraphikTextTiny style={TailwindResponsive(`my-2`)}>
        Updating...
      </GraphikTextTiny>
      <View style={TailwindResponsive(`absolute bottom-14`)}>
        <SpinLoader />
      </View>
    </SafeAreaView>
  );
};

export default AppUpdate;
