import { useMutation, useReactiveVar } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import { SEND_AUTH_VERIFY_EMAIL, VALIDATE_AUTH_CODE } from 'api/requests.v2';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { WordmarkHeader } from 'components/shared/header';
import { SpinLoader } from 'components/shared/loader';
import { GraphikTextMd } from 'components/shared/styled';
import { signInWithCustomToken } from 'firebase/auth';
import { FunctionComponent, useCallback, useState } from 'react';
import { View } from 'react-native';
import { FCUser } from 'store/user/apollo';
import Styles from 'style';
import * as FBUtil from 'utilities/Firebaseutil';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import UniversalPinInput from '../profile/components/UniversalPinInput';

export const AutoSignInAuth: FunctionComponent = () => {
  const firebaseAuth = FBUtil.getFBAuth();
  const { TailwindResponsive } = useTailwindResponsive();

  const authToken = useReactiveVar(FCUser.AuthToken);
  const [error, setError] = useState<string>('');
  const [userId, setUserId] = useState<string>('');

  const [sendAuthEmail, { loading: getUserLoader }] = useMutation(
    SEND_AUTH_VERIFY_EMAIL,
    {
      onCompleted: ({ response }) => {
        if (response && response.success) setUserId(response.successMessage);
        else setError(response.errorMessage || 'ERROR');
      },
    },
  );
  const [validateAuthCode] = useMutation(VALIDATE_AUTH_CODE);

  useFocusEffect(
    useCallback(() => {
      sendAuthEmail({
        variables: {
          token: authToken,
        },
      });
    }, [authToken, sendAuthEmail]),
  );

  const handleCheckCode = async (value) => {
    const authCodeResponse = await validateAuthCode({
      variables: {
        code: value,
        userId,
      },
    });
    if (authCodeResponse.data.response) {
      await signInWithCustomToken(firebaseAuth, authToken!);
      FCUser.AuthToken(null);
    } else {
      setError('ERROR');
    }
  };

  if (getUserLoader) return <SpinLoader />;

  return (
    <View style={TailwindResponsive('bg-Dark1 flex-1')}>
      <WordmarkHeader color={Styles.Colours.Light1} />

      <View style={TailwindResponsive('flex-1 items-center mt-20')}>
        <GraphikTextMd style={TailwindResponsive(`text-Light1`)}>
          Please check your email for a verification code and enter it below.
        </GraphikTextMd>

        <UniversalPinInput
          inputFocusStyle={TailwindResponsive(
            'border-Light1 text-Light1 text-xl',
          )}
          inputStyle={TailwindResponsive(
            `border-Light1 mt-10 text-Light1 text-xl`,
          )}
          onComplete={handleCheckCode}
        />

        {!!error && (
          <GraphikTextMd style={TailwindResponsive('text-Light1')}>
            There was an error processing your code or this link has expired.
            Please{' '}
            <FairchainButton
              label="sign in"
              onPress={() => {
                FCUser.AuthToken(null);
              }}
            />{' '}
            to continue.
          </GraphikTextMd>
        )}
      </View>
    </View>
  );
};
