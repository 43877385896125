import { useReactiveVar } from '@apollo/client';
import LockIcon from 'components/graphics/LockIcon';
import { ImagesWithThumbnails } from 'components/shared/image';
import { Slideshow } from 'components/shared/slideshow';
import {
  GraphikSemiTextMd,
  GraphikSemiTextSm,
  GraphikTextSm,
} from 'components/shared/styled';
import { AssetType, RegistrationType } from 'constants/enums';
import { FunctionComponent } from 'react';
import { Platform, View } from 'react-native';
import { RegisterStore } from 'store/registerWork';
import Styles from 'style';
import { formatDims } from 'utilities/RegisterWork';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { formatEditionText } from 'utilities/Work';
import { useGetFinalForm, useGetImagesForReview } from '../Hooks';
import ReviewRoyalties from '../components/ReviewRoyalties';

export const FinalReview: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const finalForm = useGetFinalForm();
  const imagesForReview = useGetImagesForReview();

  const autoArtistSelected = useReactiveVar(
    RegisterStore.Vars.autoArtistSelected,
  );
  const docs = useReactiveVar(RegisterStore.Vars.supportingDocsVar);
  const registrationType = useReactiveVar(RegisterStore.Vars.registrationType);
  const isCollectible = detailsForm?.assetType === AssetType.Collectible;

  return (
    <>
      <View
        style={TailwindResponsive(`mb-14 mob:mb-2`)}
        testID="ReviewInfoWrapper"
      >
        <GraphikTextSm>
          Please review these details before publishing.
        </GraphikTextSm>
      </View>
      <View style={TailwindResponsive(`flex-1 flex-row mobWeb:flex-col mb-12`)}>
        {Platform.OS === 'web' ? (
          <View
            style={TailwindResponsive(`flex-1 px-24 mobWeb:px-0 mobWeb:mb-8`)}
          >
            <ImagesWithThumbnails
              isLocal
              images={imagesForReview}
              videoURL={finalForm.videoUrl}
            />
          </View>
        ) : (
          <Slideshow images={imagesForReview.map((image) => image)} />
        )}

        <View style={TailwindResponsive(`flex-1`)}>
          {!isCollectible && (
            <>
              <View style={TailwindResponsive(`pb-12 mobWeb:pb-8`)}>
                <View style={TailwindResponsive('mb-2')}>
                  <GraphikTextSm>Artist</GraphikTextSm>
                </View>
                <GraphikSemiTextSm testID="RWReviewArtistName">
                  {finalForm.artistName ||
                    `${finalForm.firstName} ${finalForm.lastName}`}
                </GraphikSemiTextSm>
                {!autoArtistSelected && (
                  <GraphikTextSm testID="RWReviewArtistEmail">
                    {finalForm.email}
                  </GraphikTextSm>
                )}
                <GraphikTextSm testID="RWReviewArtistBirthYear">
                  {finalForm.birthYear}
                </GraphikTextSm>
                <GraphikTextSm testID="RWReviewArtistNationality">
                  {finalForm.nationality}
                </GraphikTextSm>
              </View>

              <View style={TailwindResponsive(`pb-12 mobWeb:pb-8`)}>
                <View style={TailwindResponsive('mb-2')}>
                  <GraphikTextSm>Artwork Details</GraphikTextSm>
                </View>
                <GraphikSemiTextSm testID="RWReviewWorkTitle">
                  {finalForm.title}
                </GraphikSemiTextSm>
                <GraphikTextSm testID="RWReviewWorkYear">
                  {finalForm.yearProduced}
                </GraphikTextSm>
                {formatEditionText(finalForm).length > 0 && (
                  <GraphikTextSm>{formatEditionText(finalForm)}</GraphikTextSm>
                )}
                <GraphikTextSm testID="RWReviewWorkMedium">
                  {finalForm.medium}
                </GraphikTextSm>
                <GraphikTextSm>{formatDims(finalForm)}</GraphikTextSm>
                {finalForm.inventoryNumber && (
                  <GraphikTextSm>
                    Inventory Number: {finalForm.inventoryNumber}
                  </GraphikTextSm>
                )}
              </View>
            </>
          )}

          {isCollectible && (
            <>
              <View style={TailwindResponsive(`pb-12 mobWeb:pb-8`)}>
                <GraphikSemiTextMd testID="RWReviewBrandName">
                  {finalForm.brandName ||
                    `${finalForm.firstName} ${finalForm.lastName}`}
                </GraphikSemiTextMd>
              </View>
              <View style={TailwindResponsive(`pb-12 mobWeb:pb-8`)}>
                <View style={TailwindResponsive('mb-2')}>
                  <GraphikSemiTextSm>Item Details</GraphikSemiTextSm>
                </View>
                <GraphikTextSm testID="RWReviewItemTitle">
                  {finalForm.title}
                </GraphikTextSm>
                <GraphikTextSm testID="RWReviewItemYear">
                  {finalForm.yearProduced}
                </GraphikTextSm>
                {formatEditionText(finalForm).length > 0 && (
                  <GraphikTextSm>{formatEditionText(finalForm)}</GraphikTextSm>
                )}
                <GraphikTextSm testID="RWReviewItemMaterial">
                  {finalForm.materials}
                </GraphikTextSm>
                <GraphikTextSm testID="RWReviewItemMeasurements">
                  {finalForm.measurements}
                </GraphikTextSm>
                {finalForm.inventoryNumber && (
                  <GraphikTextSm>
                    Inventory Number: {finalForm.inventoryNumber}
                  </GraphikTextSm>
                )}
              </View>
            </>
          )}

          {registrationType !== RegistrationType.Uncertified && (
            <View style={TailwindResponsive(`pb-12 mobWeb:pb-8`)}>
              <ReviewRoyalties />
            </View>
          )}

          {!!finalForm.additionalInfo && (
            <View
              style={TailwindResponsive(`pb-12 w-full max-w-2xl mobWeb:pb-8`)}
            >
              <GraphikSemiTextSm>
                Additional Artwork Information
              </GraphikSemiTextSm>
              <GraphikTextSm>{finalForm.additionalInfo}</GraphikTextSm>
            </View>
          )}

          {!!finalForm.seller && (
            <View
              style={TailwindResponsive(`pb-12 w-full max-w-2xl mobWeb:pb-8`)}
            >
              <GraphikSemiTextMd
                style={TailwindResponsive(`mt-8 mb-12 border-b border-Gray2`)}
              >
                Private Inventory Information
              </GraphikSemiTextMd>
              <GraphikSemiTextSm>Purchase History Details</GraphikSemiTextSm>
              <GraphikTextSm>{finalForm.seller}</GraphikTextSm>
              <GraphikTextSm>{finalForm.purchaseAmount}</GraphikTextSm>
              <GraphikTextSm>{finalForm.purchaseDate}</GraphikTextSm>
              <GraphikTextSm>Location: {finalForm.location}</GraphikTextSm>
            </View>
          )}

          {!!finalForm.privateNotes && (
            <View
              style={TailwindResponsive(`pb-12 w-full max-w-2xl mobWeb:pb-8`)}
            >
              <GraphikSemiTextSm>Private Notes</GraphikSemiTextSm>
              <GraphikTextSm>{finalForm.privateNotes}</GraphikTextSm>
            </View>
          )}

          {docs.length > 0 && (
            <>
              <GraphikSemiTextSm>Supporting Documents</GraphikSemiTextSm>
              {docs.map((doc) => (
                <View style={TailwindResponsive(`flex flex-row`)}>
                  <GraphikTextSm style={TailwindResponsive(`mr-2`)}>
                    {doc.fileName}
                  </GraphikTextSm>

                  {doc.transferable ? (
                    <LockIcon color={Styles.Colours.Light1} width={18} />
                  ) : (
                    <LockIcon color={Styles.Colours.Dark1} width={18} />
                  )}
                </View>
              ))}
            </>
          )}
        </View>
      </View>
    </>
  );
};

export default FinalReview;
