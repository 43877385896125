import { useLazyQuery } from '@apollo/client';
import { GET_DRAFT_WORKS } from 'api/requests.v2';
import { CollectionTab } from 'constants/enums';
import { UserContext } from 'contexts';
import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCollectionTab, setCollectionTab } from 'store/collection';
import { getSelectedWorks, setSelectedWorks } from 'store/transferWork';
import { CollectionStore } from '../artworkDetails/apollo';

export const useCollectionHooks = () => {
  const dispatch = useDispatch();
  const selectedWorks = useSelector(getSelectedWorks);

  const toggleWorkSelection = useCallback(
    (workId: string) => {
      if (selectedWorks.includes(workId)) {
        const index = selectedWorks.indexOf(workId);
        const newSelectedWorks = [...selectedWorks];
        newSelectedWorks.splice(index, 1);
        dispatch(setSelectedWorks(newSelectedWorks));
      } else {
        dispatch(setSelectedWorks([...selectedWorks, workId]));
      }
    },
    [dispatch, selectedWorks],
  );

  return { toggleWorkSelection };
};

export const useGetDraftWorks = () => {
  const authUser = useContext(UserContext);
  const collectionTab = useSelector(getCollectionTab);
  const dispatch = useDispatch();

  const [getDrafts, { data, refetch }] = useLazyQuery(GET_DRAFT_WORKS, {
    fetchPolicy: 'network-only',
    variables: {
      userId: authUser?.uid,
    },
  });

  CollectionStore.Vars.draftWorksRefetch(refetch);

  useEffect(() => {
    if (data) {
      CollectionStore.Vars.draftWorks(data.response.drafts);

      if (
        data?.response &&
        data.response.drafts?.length === 0 &&
        collectionTab === CollectionTab.Drafts
      )
        dispatch(setCollectionTab(CollectionTab.Current));
    }
  }, [collectionTab, data, dispatch]);

  return {
    getDrafts,
  };
};
