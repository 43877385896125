import * as Device from 'expo-device';
import { useCallback, useEffect } from 'react';
import { Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { setDeviceType } from 'store/general';
import { getUser } from 'store/user';

export const useDeviceType = () => {
  const dispatch = useDispatch();

  const loadDeviceType = useCallback(async () => {
    dispatch(setDeviceType(await Device.getDeviceTypeAsync()));
  }, [dispatch]);
  loadDeviceType();
};

export const useIntercom = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const intercom = (window as any).Intercom;
  const fullUser = useSelector(getUser);

  useEffect(() => {
    if (fullUser && Platform.OS === 'web' && intercom) {
      intercom('boot', {
        app_id: 'wcyobfe5',
        name: `${fullUser.firstName} ${fullUser.lastName}`,
      });
    }
  }, [fullUser, intercom]);
};
