import {
  GraphikMediumTextMd,
  GraphikTextMd,
  MediumTitle,
} from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ArtistOnboardingHowItWorks: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View>
      <MediumTitle style={TailwindResponsive(`mb-8`)}>
        Here&apos;s how it works:
      </MediumTitle>

      <GraphikMediumTextMd style={TailwindResponsive(`mb-6`)}>
        1 — Certify:{' '}
        <GraphikTextMd>
          Register artwork information and images to create a secure digital
          certificate of title and authenticity. You&apos;ll have the option to
          set resale commissions on a per work basis.
        </GraphikTextMd>
      </GraphikMediumTextMd>
    </View>
  );
};

export default ArtistOnboardingHowItWorks;
