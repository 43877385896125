import React, { FunctionComponent } from 'react';
import { Platform } from 'react-native';
import Svg, { Circle } from 'react-native-svg';
import { ISVGProps } from './Types';

export const Loader: FunctionComponent<ISVGProps> = ({ color, width }) => (
  <Svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{
      margin: 'auto',
      background: 'none',
      display: 'block',
      shapeRendering: 'auto',
    }}
    width={width || '101px'}
    height="101px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <Circle
      cx="50"
      cy="50"
      fill="none"
      stroke={color || '#1d0e0b'}
      strokeWidth="10"
      r="35"
      strokeDasharray="164.93361431346415 56.97787143782138"
    >
      {Platform.OS === 'web' && (
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1.7857142857142856s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        />
      )}
    </Circle>
  </Svg>
);

export default Loader;
