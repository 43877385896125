import { ReactiveVar, useReactiveVar } from '@apollo/client';
import { AddIcon, TrashIcon } from 'components/graphics';
import DocumentIcon from 'components/graphics/DocumentIcon';
import * as DocumentPicker from 'expo-document-picker';
import { FunctionComponent, useEffect, useState } from 'react';
import { Platform, Pressable, View } from 'react-native';
import Styles from 'style';
import { SupportingDoc } from 'types/SupportingDocs';
import { logError } from 'utilities/LogError';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import * as uuid from 'uuid';
import { Checkbox } from '../checkbox';
import { SpinLoader } from '../loader';
import { GraphikTextTiny } from '../styled';

export interface IDocPickerProps {
  deleteHandler?: (doc: SupportingDoc) => void;
  index: number;
  pickHandler?: (doc: SupportingDoc) => void;
  supportingDocsVar: ReactiveVar<SupportingDoc[]>;
}

export const DocPicker: FunctionComponent<IDocPickerProps> = ({
  deleteHandler,
  index,
  pickHandler,
  supportingDocsVar,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const iconWidth = Platform.OS === 'web' ? 150 : 100;

  const docs = useReactiveVar(supportingDocsVar);

  const [loading, setLoading] = useState(false);
  const [theDoc, setDoc] = useState<SupportingDoc | null>(null);

  useEffect(() => {
    if (docs && docs.length >= index && !!docs[index]) {
      setDoc(docs[index]!);
    } else {
      setDoc(null);
    }
  }, [docs, index]);

  const pickDocument = async () => {
    const docResult: DocumentPicker.DocumentPickerResult =
      await DocumentPicker.getDocumentAsync();
    try {
      setLoading(true);
      if (docResult && !docResult.canceled) {
        const asset = docResult.assets[0];

        const pickedDoc: SupportingDoc = {
          id: uuid.v4(),
          fileName: asset?.file?.name || asset?.name || '',
          transferable: false,
          uri: asset?.uri || '',
        };
        setDoc(pickedDoc);

        // Doc picker handler
        if (pickHandler) pickHandler(pickedDoc);

        // update state
        supportingDocsVar([...docs, pickedDoc]);
      }
    } catch (error) {
      logError(error, `Error in picking image.`);
    } finally {
      setLoading(false);
    }
  };

  const deleteDocument = () => {
    if (theDoc && docs.some((x) => x.id === theDoc.id)) {
      if (deleteHandler) deleteHandler(theDoc);
      const splicedDocs = docs.filter((doc) => doc.id !== theDoc?.id);
      supportingDocsVar(splicedDocs);
    }
    setDoc(null);
  };

  const updateTransferable = (val: boolean) => {
    if (theDoc) {
      const updatedDocs = docs.map((x) =>
        x.id === theDoc.id
          ? {
              ...x,
              transferable: val,
            }
          : x,
      );
      supportingDocsVar(updatedDocs);

      setDoc({
        ...theDoc,
        transferable: val,
      });
    }
  };

  if (loading) {
    return (
      <View
        style={TailwindResponsive('mob:h-44 mob:w-1/2 mob:p-2 mobWeb:mb-8')}
      >
        <View
          style={TailwindResponsive(
            'border border-Dark1 web:mr-5 w-52 h-52 mob:w-full mob:h-full',
          )}
        >
          <SpinLoader />
        </View>
      </View>
    );
  }

  return (
    <View style={TailwindResponsive('mob:h-44 mob:w-1/2 mob:p-2 mobWeb:mb-12')}>
      <View>
        {!theDoc && (
          <Pressable
            onPress={pickDocument}
            style={TailwindResponsive(
              'items-center justify-center border border-Dark1 web:mr-5 w-52 h-52 mob:w-full mob:h-full',
            )}
          >
            <View style={TailwindResponsive('flex-row h-8')}>
              <AddIcon width={30} />
            </View>
          </Pressable>
        )}

        {!!theDoc && (
          <View
            style={TailwindResponsive(
              'border border-Dark1 items-center justify-center relative mr-5 w-52 h-52 mob:w-full mob:h-full',
            )}
          >
            <DocumentIcon color={Styles.Colours.Dark1} width={iconWidth} />

            <Pressable
              onPress={deleteDocument}
              style={TailwindResponsive(
                `absolute top-0 right-0 p-2 bg-red-100 rounded-bl-md`,
              )}
            >
              <View>
                <TrashIcon color={Styles.Colours.Error} width={22} />
              </View>
            </Pressable>
          </View>
        )}
      </View>

      <GraphikTextTiny style={TailwindResponsive(`mt-2 w-52`)}>
        {theDoc ? theDoc.fileName : 'Select a Document'}
      </GraphikTextTiny>

      {!!theDoc && (
        <View style={TailwindResponsive(`my-2 w-52`)}>
          <Checkbox
            isChecked={theDoc?.transferable}
            label="Transferable"
            labelSpacing={6}
            labelStyle={Styles.Fonts.FontFamily.SansRegular}
            onPress={() => {
              updateTransferable(!theDoc.transferable);
            }}
          />
        </View>
      )}
    </View>
  );
};
