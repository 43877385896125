/* Copyright (C) 2020 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */
import { useReactiveVar } from '@apollo/client';
import {
  GraphikMediumTextLg,
  GraphikMediumTextMd,
} from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { FlatList, View } from 'react-native';
import { InboxGQL } from 'store/inbox/apollo';
import { Transaction, Work } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import CertifyWorkLineItem from './CertifyWorkLineItem';
import { InboxLineItem } from './InboxLineItem';

export const InboxDetails: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const certWorks = useReactiveVar(InboxGQL.certifiedWorks);
  const transactions = useReactiveVar(InboxGQL.transactions);

  if (transactions.length === 0 && certWorks.length === 0)
    return (
      <View
        style={TailwindResponsive(
          'bg-Light5 flex-1 content-center justify-center p-12',
        )}
      >
        <GraphikMediumTextMd style={{ textAlign: 'center' }}>
          New sales offers from sellers will appear here.
        </GraphikMediumTextMd>
      </View>
    );

  return (
    <View style={TailwindResponsive('flex h-full')}>
      {certWorks.length > 0 && (
        <View style={TailwindResponsive(`border-b border-Dark1 mt-6`)}>
          <GraphikMediumTextLg>
            Retroactive Certification Request
          </GraphikMediumTextLg>

          <View>
            <FlatList
              contentContainerStyle={TailwindResponsive(
                `border-b border-Dark1 mt-2`,
              )}
              data={certWorks}
              keyExtractor={(item: Work) => `inbox-flatlist-key-${item.id}`}
              snapToAlignment="center"
              showsVerticalScrollIndicator={false}
              getItemLayout={(_, index) => ({
                length: 125,
                offset: 125 * index,
                index,
              })}
              renderItem={({ item, index }) => (
                <CertifyWorkLineItem
                  key={`inbox-list-item-${index}`}
                  work={item as Work}
                />
              )}
            />
          </View>
        </View>
      )}

      <FlatList
        contentContainerStyle={TailwindResponsive(`pb-44`)}
        data={transactions}
        keyExtractor={(item: Transaction) => `inbox-flatlist-key-${item.id}`}
        snapToAlignment="center"
        showsVerticalScrollIndicator={false}
        getItemLayout={(_, index) => ({
          length: 125,
          offset: 125 * index,
          index,
        })}
        renderItem={({ item, index }) => (
          <InboxLineItem
            key={`inbox-list-item-${index}`}
            transaction={item as Transaction}
          />
        )}
      />
    </View>
  );
};
