import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import {
  AGREE_TO_TERMS_AND_SETUP_STRIPE_ACCOUNT,
  UPDATE_AGREED_TO_TERMS,
} from 'api/requests.v2';
import { FairchainLogo } from 'components/graphics';
import { RoundedButton } from 'components/shared/button';
import { Checkbox } from 'components/shared/checkbox';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import { GraphikTextSm, LargeTitle } from 'components/shared/styled';
import { FCWebView } from 'components/shared/webView';
import { FCStackNavProp } from 'navigation';
import React, { useCallback, useRef, useState } from 'react';
import { Platform, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { SafeAreaView } from 'react-native-safe-area-context';
import WebView from 'react-native-webview';
import { useDispatch, useSelector } from 'react-redux';
import { getRedirectURL } from 'store/general';
import { agreeToTerms, getUser } from 'store/user';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const AgreementViewNoTransaction: React.FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const userInfo = useSelector(getUser);
  const navigation = useNavigation<FCStackNavProp>();
  const redirectUrl = useSelector(getRedirectURL);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [webviewOpen, setWebviewOpen] = useState(false);
  const [webviewUrl, setWebviewUrl] = useState('');
  const webviewRef: React.RefObject<WebView> = useRef(null);
  const dispatch = useDispatch();

  const [updateAgreedToTerms] = useMutation(UPDATE_AGREED_TO_TERMS);
  const [AgreeToTermsSetupStripeAccount] = useMutation(
    AGREE_TO_TERMS_AND_SETUP_STRIPE_ACCOUNT,
  );

  const handleSetupStripeAccount = useCallback(async () => {
    const redirect =
      userInfo?.isArtist && !userInfo.verified ? '/identify' : '/collection';
    const { data } = await AgreeToTermsSetupStripeAccount({
      variables: {
        termsStripeInput: {
          userId: userInfo?.id,
          agreedTimeStamp: new Date().toISOString(),
          agreedTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          isMobile: Platform.OS === 'ios',
          redirectUrl: redirect,
        },
      },
    });

    if (Platform.OS === 'web') {
      window.location = data.response.successMessage;
      dispatch(
        agreeToTerms({
          ...userInfo!,
          agreedTimeStamp: new Date().toISOString(),
        }),
      );
    } else {
      setWebviewOpen(true);
      setWebviewUrl(data.response.successMessage);
    }
  }, [AgreeToTermsSetupStripeAccount, dispatch, userInfo]);

  const handleUpdateAgreedToTerms = useCallback(async () => {
    const variables = {
      userId: userInfo?.id,
      agreedTimeStamp: new Date().toISOString(),
      agreedTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    await updateAgreedToTerms({ variables });

    dispatch(
      agreeToTerms({
        ...userInfo!,
        agreedTimeStamp: variables.agreedTimeStamp,
      }),
    );

    if (Platform.OS === 'web' && redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      navigation.navigate('Collection');
    }
  }, [dispatch, updateAgreedToTerms, navigation, redirectUrl, userInfo]);

  const buttonStyling = {
    backgroundColor: Styles.Colours.Light1,
    disabled: !agreedToTerms,
    color: agreedToTerms ? Styles.Colours.Dark1 : Styles.Colours.Dark5,
    buttonStyle: {
      ...TailwindResponsive(`border-2`),
      borderColor: agreedToTerms ? Styles.Colours.Dark1 : Styles.Colours.Dark5,
    },
  };

  if (webviewOpen)
    return (
      <FCWebView
        handleWebViewNavigationStateChange={(newNavState) => {
          const { url } = newNavState;
          if (!url) return;

          if (url.includes('fairchain')) {
            setWebviewOpen(false);
            webviewRef?.current?.stopLoading();

            dispatch(
              agreeToTerms({
                ...userInfo!,
                agreedTimeStamp: new Date().toISOString(),
              }),
            );
          }
        }}
        webviewRef={webviewRef}
        webviewUrl={webviewUrl}
      />
    );

  return (
    <SafeAreaView style={TailwindResponsive(`flex-1`)}>
      <ScrollView
        style={TailwindResponsive(
          `flex flex-1 flex-row px-12 py-6 bg-white mobWeb:flex-col`,
        )}
      >
        <View style={TailwindResponsive(`flex-1`)}>
          {Platform.OS === 'web' && <FairchainLogo />}
          <LargeTitle style={TailwindResponsive(`mt-14 mb-8 mob:my-12`)}>
            Welcome to Fairchain.
          </LargeTitle>
          <LargeTitle style={TailwindResponsive(`mt-24 mb-10 mob:my-12`)}>
            Please accept Fairchain&apos;s{' '}
            <ExternalLink link="/terms">
              <LargeTitle style={TailwindResponsive(`underline`)}>
                Terms of Service
              </LargeTitle>
            </ExternalLink>{' '}
            to continue.
          </LargeTitle>
          <GraphikTextSm style={TailwindResponsive(`mb-4`)}>
            Fairchain is a title management, authentication, and transaction
            platform for fine art and collectibles, organized around a
            high-impact social mission.
          </GraphikTextSm>

          <View style={TailwindResponsive(`mb-2 flex flex-row items-center`)}>
            <Checkbox
              isChecked={agreedToTerms}
              onPress={() => {
                setAgreedToTerms((agreed) => !agreed);
              }}
              size={18}
            />
            <GraphikTextSm>
              I accept Fairchain{' '}
              <ExternalLink link="/terms">
                <GraphikTextSm style={TailwindResponsive(`underline`)}>
                  Terms of Service
                </GraphikTextSm>
              </ExternalLink>
              .
            </GraphikTextSm>
          </View>

          <GraphikTextSm style={TailwindResponsive(`mt-12 mb-5`)}>
            If you want to receive payments via Fairchain, you&apos;ll need to
            verify your Stripe account. (You can always do this later)
          </GraphikTextSm>

          <View style={TailwindResponsive(`w-44 mob:w-full`)}>
            <RoundedButton
              {...buttonStyling}
              onPress={handleSetupStripeAccount}
              label="Connect to Stripe"
            />
          </View>

          <GraphikTextSm style={TailwindResponsive(`mt-12 mb-5`)}>
            If you want to skip Stripe verification for now, you can continue to
            Fairchain below.
          </GraphikTextSm>

          <View style={TailwindResponsive(`w-52 mob:w-full mob:pb-20`)}>
            <RoundedButton
              {...buttonStyling}
              onPress={handleUpdateAgreedToTerms}
              label="Continue to Fairchain"
            />
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};
