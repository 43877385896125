import { useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { DetailsData } from 'store/artworkDetails/apollo';
import { getUser } from 'store/user';
import { CollectorRetroRequestForm } from 'types/ArtworkDetails';
import * as yup from 'yup';

const CollectorRetroSchema = yup
  .object()
  .shape({
    addressCity: yup.string().required('Please enter a city'),
    addressCountry: yup.string().required('Please enter a country'),
    addressLine1: yup.string().required('Please enter a billing address'),
    addressState: yup.string().required('Please enter a state'),
    addressZipcode: yup.string().required('Please enter a postal code'),
    commission: yup
      .number()
      .required('Please enter a value')
      .typeError('Please enter a valid number')
      .min(0, 'Please enter a value between 0 and 50')
      .max(50, 'Artist resale commissions are capped at 50%'),
    purchaseAmount: yup.string().required('Please enter the purchase amount'),
    purchaseDate: yup.string().required('Please enter the purchase date'),
    purchaseSeller: yup
      .string()
      .required('Please enter the seller information'),
    shareIdentity: yup
      .bool()
      .oneOf(
        [true],
        'We must share your contact details with the artist in order to complete the certification process',
      ),
    titleConfirmation: yup
      .bool()
      .oneOf(
        [true],
        'You must hold clear title to the work in order to initiate the certification process',
      ),
  })
  .required();

export const useSetupCollectorRetroRequestForm = () => {
  const artwork = useReactiveVar(DetailsData.work);
  const user = useSelector(getUser);

  const form = useForm<CollectorRetroRequestForm>({
    defaultValues: {
      additionalInfo: '',
      addressCity: user?.address.billingAddressCity || '',
      addressCountry: user?.address.billingAddressCountry || '',
      addressLine1: user?.address.billingAddressLineOne || '',
      addressLine2: user?.address.billingAddressLineTwo || '',
      addressState: user?.address.billingAddressState || '',
      addressZipcode: user?.address.billingAddressZipcode || '',
      artistContact: '',
      commission: '0',
      purchaseAmount: artwork?.salesInfo?.purchaseAmount || '',
      purchaseDate: artwork?.salesInfo?.purchaseDate || '',
      purchaseSeller: artwork?.salesInfo?.seller || '',
      sellerContact: '',
      shareIdentity: false,
      titleConfirmation: false,
    },
    resolver: yupResolver(CollectorRetroSchema),
  });
  DetailsData.CollectorRetro.form(form);
};
