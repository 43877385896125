import { useReactiveVar } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { RegistrationType, UserRole } from 'constants/enums';
import { UserContext } from 'contexts';
import { FCStackNavProp, RegisterWorkRouteProp } from 'navigation';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearRoyalties } from 'store/forms';
import { IImageSelection } from 'store/registerWork';
import { RegisterStore } from 'store/registerWork/apollo';
import { getMainUser, getUser, getUserRole } from 'store/user';
import { IImagePickerResponse } from 'types';
import { deleteImageAsync, uploadImageAsync } from 'utilities';
import { trimFormData } from 'utilities/Forms';
import {
  useGetDefaultARValues,
  useGetFormDetails,
} from './forms/artist-registration.form';

export const useCanRegisterUncertifiedOnly = (): boolean => {
  const fullUser = useSelector(getUser);
  const mainUser = useSelector(getMainUser);
  const userRole = useSelector(getUserRole);

  const theUser = fullUser && !fullUser.isSubUser ? fullUser : mainUser;
  return (
    userRole === UserRole.Collector ||
    (userRole === UserRole.Artist && !theUser?.verified)
  );
};

export const useDeleteTempImage = () => {
  const route = useRoute<RegisterWorkRouteProp>();

  return (image: IImagePickerResponse | null) => {
    if (image) {
      const imagePath = `temp-images/${route.params.draftId}/${image.id}${image.ext}`;
      deleteImageAsync(imagePath);
    }
  };
};

export const useGetFinalForm = () => {
  const authUser = useContext(UserContext);

  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const arForm = useGetFormDetails(detailsForm);
  const royalForm = useReactiveVar(RegisterStore.Vars.royaltyForm);

  let reviewForm: any = {};

  if (arForm && royalForm) {
    const artistForm = arForm?.getValues();
    const trimmedArtistForm = trimFormData(artistForm);
    const royaltyForm = royalForm!.getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { totalRoyalty, ...royaltyFormWithoutTotal } = royaltyForm;

    reviewForm = {
      ...trimmedArtistForm,
      ...royaltyFormWithoutTotal,
      dimensionUnits: artistForm?.dimensionUnits ? 'in' : 'cm',
      dateRegistered: new Date().toISOString(),
      dateRegisteredTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      editionType: artistForm?.editionType ? 'Edition' : 'AP',
      ownerId: authUser?.uid,
      registeredById: authUser?.uid,
    };
  }

  return reviewForm;
};

export const useGetImages = (): IImageSelection => ({
  image1: useReactiveVar(RegisterStore.Vars.Images.image1),
  image2: useReactiveVar(RegisterStore.Vars.Images.image2),
  image3: useReactiveVar(RegisterStore.Vars.Images.image3),
  image4: useReactiveVar(RegisterStore.Vars.Images.image4),
});

export const useGetImagesForReview = (): string[] => {
  const primaryKey = useReactiveVar(RegisterStore.Vars.Images.primary);
  const primaryImage = useReactiveVar<IImagePickerResponse | null>(
    RegisterStore.Vars.Images[primaryKey],
  );

  const baseImages = useGetImages();
  let images: string[] = [];

  if (primaryImage) {
    const nonPrimaryImages = Object.entries(baseImages).filter(
      (image) => image[0] !== primaryKey && !!image[1],
    );
    images = nonPrimaryImages.map(
      (image) => image[1].remoteURI || image[1].localURI || '',
    );
    images.unshift(primaryImage.remoteURI || primaryImage.localURI);
  }

  return images;
};

export const useGetMaxStep = (): number => {
  const regType = useReactiveVar(RegisterStore.Vars.registrationType);
  return regType === RegistrationType.Uncertified ? 1 : 2;
};

export const useIsGallery = (): boolean => {
  const user = useSelector(getUser);
  const mainUser = useSelector(getMainUser);

  return (!!user && user.isGallery) || (!!mainUser && mainUser.isGallery);
};

export const useIsBrand = (): boolean => {
  const user = useSelector(getUser);
  const mainUser = useSelector(getMainUser);

  return (!!user && user.isBrand) || (!!mainUser && mainUser.isBrand);
};

export const useResetAll = (): (() => void) => {
  const dispatch = useDispatch();

  const arFormDefaults = useGetDefaultARValues();

  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const arForm = useGetFormDetails(detailsForm);
  const royalForm = useReactiveVar(RegisterStore.Vars.royaltyForm);

  const userRole = useSelector(getUserRole);

  return () => {
    // Reset apollo store values
    RegisterStore.Reset();

    // Reset redux store values
    dispatch(clearRoyalties());

    // Reset form values
    arForm?.reset(arFormDefaults);
    royalForm?.reset();

    // Reset registration type default
    if (userRole === UserRole.Collector) {
      arForm?.setValue('registrationType', RegistrationType.Uncertified);
      RegisterStore.Vars.registrationType(RegistrationType.Uncertified);
    }
  };
};

export const useSetParams = (): (() => void) => {
  const navigation = useNavigation<FCStackNavProp>();
  const route = useRoute<RegisterWorkRouteProp>();

  return () => {
    if (route && (!route.params || route.params.draftId === 'undefined'))
      navigation.setParams({ draftId: 'new' });
  };
};

export const useUploadTempImage = () => {
  const route = useRoute<RegisterWorkRouteProp>();

  return (image: IImagePickerResponse, path = ''): Promise<string> => {
    const storagePath = path || `temp-images/${route.params.draftId}/`;
    return uploadImageAsync(image.localURI, storagePath, image.id + image.ext);
  };
};
