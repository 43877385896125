import { useNavigation } from '@react-navigation/native';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { GraphikMediumTextSm, GraphikTextSm } from 'components/shared/styled';
import { FCStackNavProp } from 'navigation';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Work } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface Props {
  work: Work;
}

export const CertifyWorkLineItem: FunctionComponent<Props> = ({ work }) => {
  const navigation = useNavigation<FCStackNavProp>();
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View
      style={TailwindResponsive(
        `border-b border-Light1 flex flex-row items-center justify-between p-6 bg-slate-200 mob:flex-col mob:items-start mobWeb:flex-col mobWeb:items-start`,
      )}
    >
      <View>
        <GraphikMediumTextSm>
          {work.title} ({work.yearProduced})
        </GraphikMediumTextSm>
        <GraphikTextSm>{work.workIdentifier}</GraphikTextSm>
        <GraphikTextSm>
          Current Owner: {work.owner.firstName} {work.owner.lastName}
        </GraphikTextSm>
      </View>

      <FairchainButton
        buttonStyle={TailwindResponsive(
          `border border-black p-6 rounded-full mobWeb:mt-4 mobWeb:self-end`,
        )}
        label="View Details"
        onPress={() => {
          if (work.currentTransactionId)
            navigation.push('TransactionDetail', {
              transactionId: work.currentTransactionId,
            });
          else navigation.push('Details', { workId: work.id });
        }}
      />
    </View>
  );
};

export default CertifyWorkLineItem;
