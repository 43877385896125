import { UserRole } from 'constants/enums';
import { RootState } from 'store/appStore';
import { UserRootState } from 'store/types';
import { User } from 'types';

export const getArtistOfEstateUser = (store: UserRootState): User | null =>
  store?.user?.estateUser;

export const getAuthenticated = (store: RootState): boolean =>
  (store?.user?.hasBeen2FAD && store?.user?.currentUser?.twoFactorEnabled) ||
  !store?.user?.currentUser?.twoFactorEnabled;

export const getIntegrationsLoading = (store: UserRootState): boolean =>
  store?.user?.profile?.stripeLoading || store?.user?.profile?.subUsersLoading;

export const getMainUser = (store: UserRootState): User | null =>
  store?.user?.mainUser;

export const getSubUsers = (store: UserRootState): User[] =>
  store?.user?.profile?.subUsers || [];

export const getUser = (store: UserRootState): User | null =>
  store?.user?.currentUser;

export const getUserId = (store: UserRootState): string | undefined =>
  store?.user?.currentUser?.id;

export const getUserInitials = (store: UserRootState): string =>
  (store?.user?.currentUser?.firstName?.charAt(0) || '') +
  (store?.user?.currentUser?.lastName?.charAt(0) || '');

export const getUserHasCompletedCollectorOnboarding = (
  store: UserRootState,
): boolean | undefined => store?.user?.currentUser?.hasSeenCollectorOnboarding;

export const getUserHasCompletedOnboarding = (
  store: UserRootState,
): boolean | undefined => store?.user?.currentUser?.hasSeenOnboarding;

export const getUserRole = (store: UserRootState): number => {
  if (store?.user?.currentUser?.isAdmin) {
    return UserRole.Admin;
  }
  if (store?.user?.currentUser?.isGallery || store?.user?.mainUser?.isGallery) {
    return UserRole.Gallery;
  }
  if (store?.user?.currentUser?.isArtist || store?.user?.mainUser?.isArtist) {
    return UserRole.Artist;
  }
  if (store?.user?.currentUser?.isEstate || store?.user?.mainUser?.isEstate) {
    return UserRole.Estate;
  }
  if (store?.user?.currentUser?.isBrand || store?.user?.mainUser?.isBrand) {
    return UserRole.Brand;
  }
  return UserRole.Collector;
};
