import { useReactiveVar } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { DetailsData } from 'store/artworkDetails/apollo';
import { ADSettingsForm } from 'types/ArtworkDetails';

export const useSetupSettingsForm = () => {
  const artwork = useReactiveVar(DetailsData.work);

  const form = useForm<ADSettingsForm>({
    defaultValues: {
      availableForLoan: artwork?.availableForLoan ?? false,
    },
  });
  DetailsData.settingsForm(form);
};
