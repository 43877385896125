import {
  GraphikMediumTextSm,
  GraphikSemiTextSm,
  GraphikTextSm,
} from 'components/shared/styled';
import { RoyaltyType, UserRole } from 'constants/enums';
import currency from 'currency.js';
import { format } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { getDiminishedRoyalties } from 'store/forms';
import { getUserRole } from 'store/user';
import { WorkWithObject } from 'types';
import { getCombinedRoyalty, totalDiminishedRoyalty } from 'utilities';
import { formatRoyaltyCopy } from 'utilities/Royalties';
import { TransactionFinalForm } from '../Helpers';

interface IConfirmationRoyaltiesProps {
  finalForm: TransactionFinalForm;
  index: number;
  work: WorkWithObject;
}

export const ConfirmationRoyalties: FunctionComponent<
  IConfirmationRoyaltiesProps
> = ({ finalForm, index, work }) => {
  const userRole = useSelector(getUserRole);

  const diminishedRoyalties = useSelector(getDiminishedRoyalties);
  const correctRoyalty = diminishedRoyalties.find(
    (royalty) => royalty?.workId === work.id,
  );

  const formItem = finalForm.items[index];

  const artistRoyalty = currency(formItem?.artistRoyalty || 0, {
    pattern: `#!`,
    precision: 2,
    symbol: '%',
  });
  const galleryRoyalty = currency(formItem?.galleryRoyalty || 0, {
    pattern: `#!`,
    precision: 2,
    symbol: '%',
  });

  if (correctRoyalty && correctRoyalty.doesDiminish) {
    const diminishedTotal = totalDiminishedRoyalty(correctRoyalty);

    return (
      <View>
        <GraphikTextSm>Resale Commission</GraphikTextSm>
        {!work.lastCompletedTransactionId ? (
          <>
            <Text>
              <GraphikTextSm>Artist – </GraphikTextSm>
              <GraphikSemiTextSm>
                {artistRoyalty.value.toString()}%{' '}
              </GraphikSemiTextSm>
              <GraphikTextSm>for the first </GraphikTextSm>
              <GraphikSemiTextSm>
                {correctRoyalty.diminishedDate}{' '}
                {formatRoyaltyCopy(correctRoyalty.diminishedDate)}{' '}
              </GraphikSemiTextSm>
              <GraphikTextSm>following </GraphikTextSm>
              <GraphikSemiTextSm>
                {format(new Date(), 'MM/dd/yyyy')}{' '}
              </GraphikSemiTextSm>
              <GraphikTextSm>and </GraphikTextSm>
              <GraphikSemiTextSm>
                {correctRoyalty.diminishedArtistValue}%{' '}
              </GraphikSemiTextSm>
              <GraphikTextSm>thereafter</GraphikTextSm>
            </Text>
            {userRole === UserRole.Gallery && (
              <Text>
                <GraphikTextSm>Gallery — </GraphikTextSm>
                <GraphikSemiTextSm>
                  {parseFloat(galleryRoyalty.value.toString())}%{' '}
                </GraphikSemiTextSm>
                <GraphikTextSm>for the first </GraphikTextSm>
                <GraphikSemiTextSm>
                  {correctRoyalty.diminishedDate}{' '}
                  {formatRoyaltyCopy(correctRoyalty.diminishedDate)}{' '}
                </GraphikSemiTextSm>
                <GraphikTextSm>following </GraphikTextSm>
                <GraphikSemiTextSm>
                  {format(new Date(), 'MM/dd/yyyy')}{' '}
                </GraphikSemiTextSm>
                <GraphikTextSm>and </GraphikTextSm>
                <GraphikSemiTextSm>
                  {correctRoyalty.diminishedGalleryValue}%{' '}
                </GraphikSemiTextSm>
                <GraphikTextSm>thereafter</GraphikTextSm>
              </Text>
            )}
          </>
        ) : (
          <Text>
            <GraphikSemiTextSm>
              {getCombinedRoyalty(work.royalties, false)}%{' '}
            </GraphikSemiTextSm>
            <GraphikTextSm>for the first </GraphikTextSm>
            <GraphikSemiTextSm>
              {correctRoyalty.diminishedDate}{' '}
              {formatRoyaltyCopy(correctRoyalty.diminishedDate)}{' '}
            </GraphikSemiTextSm>
            <GraphikTextSm>following </GraphikTextSm>
            <GraphikSemiTextSm>
              {format(new Date(), 'MM/dd/yyyy')}{' '}
            </GraphikSemiTextSm>
            <GraphikTextSm>and </GraphikTextSm>
            <GraphikSemiTextSm>{diminishedTotal}% </GraphikSemiTextSm>
            <GraphikTextSm>thereafter</GraphikTextSm>
          </Text>
        )}
      </View>
    );
  }

  return (
    <>
      <GraphikTextSm>Resale Commission</GraphikTextSm>
      {!work.lastCompletedTransactionId ? (
        <>
          {work.royalties.map((royalty) => {
            if (royalty.type === RoyaltyType.artist) {
              return (
                <GraphikMediumTextSm key={royalty.user}>
                  Artist Resale Commission - {artistRoyalty.value.toString()}%
                </GraphikMediumTextSm>
              );
            }
            if (royalty.type === RoyaltyType.brand) {
              return (
                <GraphikMediumTextSm key={royalty.user}>
                  Brand Resale Commission - {royalty.value.toString()}%
                </GraphikMediumTextSm>
              );
            }
            if (royalty.type === RoyaltyType.contributor) {
              const matchedContributor = work.collectible?.contributors?.find(
                (contributor) => contributor.id === royalty.user,
              );
              const contributorName =
                (matchedContributor &&
                  `${matchedContributor.firstName} ${matchedContributor.lastName}`) ||
                'Contributor';
              const contributorRole = matchedContributor
                ? `, ${matchedContributor.role}`
                : '';
              return (
                <GraphikMediumTextSm key={royalty.user}>
                  {`${contributorName}${contributorRole} - ${royalty.value.toString()}%`}
                </GraphikMediumTextSm>
              );
            }
            if (royalty.type === RoyaltyType.gallery) {
              return (
                <GraphikMediumTextSm key={royalty.user}>
                  Gallery Resale Commission - {galleryRoyalty.value.toString()}%
                </GraphikMediumTextSm>
              );
            }
            return null;
          })}
        </>
      ) : (
        <GraphikMediumTextSm>
          {getCombinedRoyalty(work.royalties, false)}%
        </GraphikMediumTextSm>
      )}
    </>
  );
};

export default ConfirmationRoyalties;
