import { useNavigation } from '@react-navigation/native';
import { MainHeader } from 'components/shared/header';
import { useGetNumUserTransactions } from 'components/shared/header/hooks';
import { GraphikTextHuge, HugeTitle } from 'components/shared/styled';
import { CollectionPage, CollectionTab } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { Pressable, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentPage, setCollectionTab } from 'store/collection';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { CTSubHeader } from './CTSubHeader';

export const CTHeader: FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { TailwindResponsive } = useTailwindResponsive();

  const numTransactions = useGetNumUserTransactions();

  const currentPage = useSelector(getCurrentPage);

  const baseHeaderStyle = `self-start mobWeb:w-full`;

  const collectionHeaderStyle =
    currentPage === CollectionPage.Collection
      ? baseHeaderStyle
      : `${baseHeaderStyle} mobWeb:hidden`;
  const collectionHeaderColor =
    currentPage === CollectionPage.Collection
      ? Styles.Colours.Dark1
      : Styles.Colours.Dark4;

  const transactionHeaderStyle =
    currentPage === CollectionPage.Transaction
      ? baseHeaderStyle
      : `${baseHeaderStyle} mobWeb:hidden`;
  const transactionHeaderColor =
    currentPage === CollectionPage.Transaction
      ? Styles.Colours.Dark1
      : Styles.Colours.Dark4;

  return (
    <>
      <MainHeader />

      <View
        style={TailwindResponsive(
          `flex flex-row pb-1.5 border-b border-Dark1 mobWeb:justify-between mobWeb:pb-0`,
        )}
      >
        <View style={TailwindResponsive(`${collectionHeaderStyle} mr-8`)}>
          <Pressable
            onPress={() => {
              dispatch(setCollectionTab(CollectionTab.Current));
              navigation.navigate('Collection');
            }}
          >
            {currentPage === CollectionPage.Collection ? (
              <HugeTitle color={collectionHeaderColor}>Collection</HugeTitle>
            ) : (
              <GraphikTextHuge color={collectionHeaderColor}>
                Collection
              </GraphikTextHuge>
            )}
          </Pressable>
        </View>

        {numTransactions > 0 && (
          <View style={TailwindResponsive(transactionHeaderStyle)}>
            <Pressable
              onPress={() => {
                dispatch(setCollectionTab(CollectionTab.Current));
                navigation.navigate('Transactions');
              }}
            >
              <GraphikTextHuge color={transactionHeaderColor}>
                Transactions
              </GraphikTextHuge>
            </Pressable>
          </View>
        )}
      </View>

      <CTSubHeader />
    </>
  );
};
