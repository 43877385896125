import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import Styles from 'style';
import { ISVGProps } from './Types';

export const InfoIcon: FunctionComponent<ISVGProps> = ({ color, width }) => {
  const iconWidth = width || 48;

  return (
    <Svg
      viewBox="0 0 24 24"
      width={iconWidth}
      fill={color || Styles.Colours.Dark1}
    >
      <Path d="M0 0h24v24H0z" fill="none" />
      <Path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
    </Svg>
  );
};

export default InfoIcon;
