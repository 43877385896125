import { Footer } from 'components/shared/footer';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useSetupSignUpForm } from '../form';
import SelectionInfo from './SelectionInfo';

export const SignUpSelection: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  useSetupSignUpForm();

  return (
    <>
      <View style={TailwindResponsive(`pb-32 bg-Dark1 flex flex-1`)}>
        <SelectionInfo />
      </View>

      <Footer
        backgroundColor="Dark1"
        style={TailwindResponsive('mob:hidden')}
        textColor={Styles.Colours.Light1}
      />
    </>
  );
};
