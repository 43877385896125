import { ApolloClient, from, InMemoryCache, split } from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';
import { agentBuyerTypeDefs, agentInfoFields } from 'store/agentBuyer/apollo';
import { TransactionResolvers } from 'store/transactionDetails/apollo';
import { FBLogout, getFBAuth } from 'utilities/Firebaseutil';
import LogError from 'utilities/LogError';
import Constants from '../constants';

type FCNetworkError = NetworkError & {
  response: { status: number };
};

const apiv2Link = createUploadLink({
  uri: Constants.GraphQL.GRAPHQL_URL,
});

const errorLink = onError((errors) => {
  const netErrors = errors.networkError as FCNetworkError;
  if (netErrors && netErrors.response && netErrors.response.status === 403) {
    LogError.logError(`Apollo 403 error: ${netErrors.message}`);
    FBLogout();
  } else {
    const graphQlErrors = '';
    if (errors.graphQLErrors)
      graphQlErrors.concat(
        errors.graphQLErrors.map((error) => `${error.message}`).join(' '),
      );

    const networkErrors = '';
    if (errors.networkError)
      networkErrors.concat(` ${errors.networkError.message}`);

    /// The below was clogging Sentry logs—need to find a better way to report this info
    // LogError.logError(
    //   `Apollo Error || GraphQL Errors: ${graphQlErrors} | NetworkErrors: ${networkErrors}`,
    // );
  }
});

const authLink = setContext(async (_, { headers }) => {
  const fbAuth = getFBAuth();
  const token = fbAuth ? await fbAuth.currentUser?.getIdToken() : '';
  const bearer = `Bearer ${token}`;

  return {
    headers: {
      ...headers,
      authorization: bearer,
      'keep-alive': 'true',
    },
  };
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: Constants.GraphQL.WS_GRAPHQL_URL,
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  apiv2Link,
);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        ...agentInfoFields,
      },
    },
  },
});

export const FC_APOLLO_CLIENT = new ApolloClient({
  cache,
  link: authLink.concat(from([errorLink, splitLink])),
  resolvers: {
    Transaction: TransactionResolvers,
  },
  typeDefs: [agentBuyerTypeDefs],
});
