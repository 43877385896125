import { Checkbox } from 'components/shared/checkbox';
import { OptionPicker } from 'components/shared/optionPicker';
import { GraphikTextTiny } from 'components/shared/styled';
import { TextAlignment } from 'constants/enums';
import React, { FunctionComponent, useEffect } from 'react';
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ITransactionDetailsForm, TransferWorkForm } from '../Helpers';

interface IExpirationProps {
  control: Control<ITransactionDetailsForm>;
  getValues: UseFormGetValues<ITransactionDetailsForm>;
  setValues: UseFormSetValue<ITransactionDetailsForm>;
}

export const Expiration: FunctionComponent<IExpirationProps> = ({
  control,
  getValues,
  setValues,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const hasExpirationTime = !!getValues('expirationTime');
  const doesExpire = getValues('doesExpire');

  useEffect(() => {
    if (hasExpirationTime && !getValues('doesExpire')) {
      setValues('doesExpire', true);
    }
  }, [hasExpirationTime, getValues, setValues]);

  const expirationOptions = [
    { label: '1 hour', value: '1H' },
    { label: '3 hours', value: '3H' },
    { label: '6 hours', value: '6H' },
    { label: '24 hours', value: '1D' },
    { label: '3 days', value: '3D' },
    { label: '7 days', value: '7D' },
  ];

  return (
    <View style={{ zIndex: -1 }}>
      <View style={TailwindResponsive(`w-full items-center flex flex-row`)}>
        <View style={TailwindResponsive(`pr-4 flex-1`)}>
          <Controller
            control={control}
            name={TransferWorkForm.doesExpire}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                additionalText="(Beta)"
                isChecked={doesExpire}
                onPress={() => {
                  onChange(!value);
                  if (value) {
                    setValues('expirationTime', '');
                  }
                }}
                label="Expires in:"
              />
            )}
          />
          <GraphikTextTiny style={TailwindResponsive('ml-10')}>
            Set an optional expiration time for your sales agreement.
          </GraphikTextTiny>
        </View>
        <View style={TailwindResponsive(`flex-1`)}>
          <Controller
            control={control}
            name="expirationTime"
            render={({ field: { onChange } }) => (
              <OptionPicker
                bold
                defaultValue=""
                options={expirationOptions}
                onChange={onChange}
                showIcon
                placeholder="Duration"
                alignment={TextAlignment.Left}
                value={getValues('expirationTime')}
                dropDownDirection="BOTTOM"
              />
            )}
          />
        </View>
      </View>
    </View>
  );
};
