import { useNavigation } from '@react-navigation/native';
import { MainHeader } from 'components/shared/header';
import { CollectionTab } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { Pressable, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { setCollectionDisplay } from 'store/collection';
import Styles from 'style';
import { ICollectionTransactionHeaderProps } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { GraphikTextHuge, HugeTitle } from '../styled';

export const CollectionTransactionHeader: FunctionComponent<
  ICollectionTransactionHeaderProps
> = ({ showCollection }) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const dispatch = useDispatch();
  const navigation = useNavigation();

  return (
    <View>
      <MainHeader />
      <View
        style={{
          borderBottomWidth: 0.5,
          borderBottomColor: 'black',
          ...TailwindResponsive(
            `flex flex-row pb-1.5 border-b border-Dark1 mobWeb:justify-between mobWeb:pb-0`,
          ),
        }}
      >
        <View
          style={
            showCollection
              ? TailwindResponsive(`w-44 mobWeb:w-full`)
              : TailwindResponsive('w-44 mobWeb:w-full mobWeb:hidden')
          }
        >
          <Pressable
            onPress={() => {
              dispatch(setCollectionDisplay(CollectionTab.Current));
              navigation.navigate('Collection');
            }}
          >
            {showCollection ? (
              <HugeTitle>Collection</HugeTitle>
            ) : (
              <GraphikTextHuge
                color={Styles.Colours.Dark4}
                style={TailwindResponsive(`mobWeb:hidden`)}
              >
                Collection
              </GraphikTextHuge>
            )}
          </Pressable>
        </View>
        <View
          style={
            showCollection
              ? TailwindResponsive(`w-48 mobWeb:w-full mobWeb:hidden`)
              : TailwindResponsive('w-48 mobWeb:w-full')
          }
        >
          <Pressable
            onPress={() => {
              dispatch(setCollectionDisplay(CollectionTab.Current));
              navigation.navigate('Transactions');
            }}
          >
            {showCollection ? (
              <GraphikTextHuge
                color={Styles.Colours.Dark4}
                style={TailwindResponsive(`mobWeb:hidden`)}
              >
                Transactions
              </GraphikTextHuge>
            ) : (
              <HugeTitle>Transactions</HugeTitle>
            )}
          </Pressable>
        </View>
      </View>
    </View>
  );
};

export default CollectionTransactionHeader;
