import { useLazyQuery } from '@apollo/client';
import { GET_MY_ARCHIVE } from 'api/requests.v2';
import { ErrorMessage } from 'components/shared/error';
import { SpinLoader } from 'components/shared/loader';
import { CollectionTab } from 'constants/enums';
import { UserContext } from 'contexts';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { FlatList, SafeAreaView, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCollectionTab,
  getGridWorks,
  getPastWorksLoaded,
  setPastWorks,
} from 'store/collection';
import { Work } from 'types';
import { getResponsiveSizes } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { GridItem } from './GridItem';

export const GridViewWrapper: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const dispatch = useDispatch();
  const { TailwindResponsive } = useTailwindResponsive();

  let numColumns = 4;
  if (getResponsiveSizes().xxl) numColumns = 5;
  if (getResponsiveSizes().mob) numColumns = 2;
  if (getResponsiveSizes().mobWeb) numColumns = 2;

  const currentTab = useSelector(getCollectionTab);
  const pastWorksLoaded = useSelector(getPastWorksLoaded);
  const works = useSelector(getGridWorks);

  const [getArchive, { error, loading }] = useLazyQuery(GET_MY_ARCHIVE, {
    variables: { userId: authUser?.uid },
    onCompleted: (data) => {
      dispatch(setPastWorks(data.response));
    },
  });

  useEffect(() => {
    if (currentTab === CollectionTab.Past && !pastWorksLoaded) getArchive();
  }, [currentTab, getArchive, pastWorksLoaded]);

  const keyExtractor = useCallback(
    (item: Work) => `col-gridlist-key-${item.id}`,
    [],
  );

  const renderer = useCallback(
    ({ item }) => <GridItem item={item} numColumns={numColumns} />,
    [numColumns],
  );

  if (loading)
    return (
      <View style={TailwindResponsive(`flex-1 items-center justify-center`)}>
        <SpinLoader />
      </View>
    );

  if (error) return <ErrorMessage />;

  return (
    <SafeAreaView>
      <View style={TailwindResponsive(`mb-28 mob:mb-48 mob:-mx-4`)}>
        <FlatList
          data={works}
          key={numColumns}
          keyExtractor={keyExtractor}
          renderItem={renderer}
          numColumns={numColumns}
        />
      </View>
    </SafeAreaView>
  );
};

export default GridViewWrapper;
