import React, { FunctionComponent } from 'react';
import { Pressable, Text } from 'react-native';
import Styles from 'style';
import { Style } from 'tailwind-rn/dist';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IFlatButtonProps {
  buttonStyle?:
    | {
        [key: string]: string;
      }
    | Style;
  label: string;
  onPress?: () => void;
  textStyle?:
    | {
        [key: string]: string;
      }
    | Style;
}
export const FlatButton: FunctionComponent<IFlatButtonProps> = ({
  buttonStyle,
  label,
  onPress,
  textStyle,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const baseButtonStyle = TailwindResponsive('flex items-center px-6 py-4');
  const baseTextStyle = TailwindResponsive('text-black');

  return (
    <Pressable onPress={onPress} style={{ ...baseButtonStyle, ...buttonStyle }}>
      <Text
        style={{
          ...{ fontFamily: Styles.Fonts.FontFamily.SansMedium },
          ...baseTextStyle,
          ...textStyle,
        }}
      >
        {label}
      </Text>
    </Pressable>
  );
};

export default FlatButton;
