import { useReactiveVar } from '@apollo/client';
import { isFunction } from 'lodash';
import { FunctionComponent } from 'react';
import { Modal, Pressable, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { FairchainButton } from '../button/FairchainButton';
import { GraphikSemiTextLg, GraphikTextMd } from '../styled';
import { ConfirmModalStore } from './apollo';

export interface IConfirmModalProps {
  body: string;
  handleCancel?: () => void;
  handleConfirm?: () => void;
  header?: string;
}

export const ConfirmationModal: FunctionComponent<IConfirmModalProps> = ({
  body,
  handleCancel,
  handleConfirm,
  header,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const showThySelf = useReactiveVar(ConfirmModalStore.Vars.shouldShow);

  const handleClose = () => {
    if (handleCancel && isFunction(handleCancel)) handleCancel();
    ConfirmModalStore.Vars.shouldShow(false);
  };

  const handleAccept = () => {
    if (handleConfirm && isFunction(handleConfirm)) handleConfirm();
    ConfirmModalStore.Vars.shouldShow(false);
  };

  return (
    <Modal
      style={TailwindResponsive('flex-1 items-center py-10')}
      transparent
      visible={showThySelf}
    >
      <Pressable
        onPress={handleClose}
        style={TailwindResponsive(
          `bg-black/50 flex-1 items-center justify-center mob:px-4`,
        )}
      >
        <Pressable
          onStartShouldSetResponder={() => true}
          onTouchEnd={(e) => {
            e.stopPropagation();
          }}
          style={[
            TailwindResponsive(
              `bg-Light1 flex shrink rounded-lg max-w-lg m-10 w-full`,
            ),
            { cursor: 'default' },
          ]}
        >
          <View style={TailwindResponsive(`flex justify-center p-8`)}>
            <View style={TailwindResponsive(`border-b mb-8 pb-2`)}>
              <GraphikSemiTextLg>{header}</GraphikSemiTextLg>
            </View>
            <GraphikTextMd>{body}</GraphikTextMd>

            <View
              style={TailwindResponsive(
                'flex flex-row items-center justify-center mt-8',
              )}
            >
              <FairchainButton
                label="Cancel"
                onPress={handleClose}
                textStyle={TailwindResponsive(`underline`)}
              />

              <FairchainButton
                buttonStyle={TailwindResponsive(
                  `bg-Primary ml-20 px-8 rounded-full`,
                )}
                label="Delete"
                onPress={handleAccept}
                textStyle={TailwindResponsive(`text-Light1`)}
              />
            </View>
          </View>
        </Pressable>
      </Pressable>
    </Modal>
  );
};
