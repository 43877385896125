/* Copyright (C) 2022 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */
import { useLazyQuery } from '@apollo/client';
import { SETUP_QUICKBOOKS_AND_GET_LINK } from 'api/requests.v2';
import { CheckIcon } from 'components/graphics';
import { RoundedButton } from 'components/shared/button';
import { ErrorMessage } from 'components/shared/error';
import { SpinLoader } from 'components/shared/loader';
import { GraphikMediumTextMd } from 'components/shared/styled';
import { ButtonAlignment } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { getIntegrationsLoading, getUser } from 'store/user';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ConnectToQuickbooks: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const userFromStore = useSelector(getUser);
  const integrationsLoading = useSelector(getIntegrationsLoading);

  const [getQBAccountLink, { loading, error }] = useLazyQuery(
    SETUP_QUICKBOOKS_AND_GET_LINK,
    {
      onCompleted: (data) => {
        window.location = data.response;
      },
    },
  );

  if (integrationsLoading) return null;

  if (error) return <ErrorMessage />;

  if (loading) return <SpinLoader />;

  if (!userFromStore?.connectedToQb)
    return (
      <View style={{ marginTop: 100, width: 275 }}>
        <RoundedButton
          alignment={ButtonAlignment.Left}
          backgroundColor={Styles.Colours.Dark1}
          color={Styles.Colours.Light1}
          label="Connect to Quickbooks"
          onPress={() => getQBAccountLink()}
          paddingHorizontal={22}
        />
      </View>
    );

  return (
    <View style={TailwindResponsive(`flex flex-row mt-10`)}>
      <GraphikMediumTextMd style={TailwindResponsive(`mr-1`)}>
        Connected to Quickbooks
      </GraphikMediumTextMd>
      <CheckIcon color={Styles.Colours.Dark1} width={18} />
    </View>
  );
};

export default ConnectToQuickbooks;
