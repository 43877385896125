import { useQuery, useReactiveVar } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import { GET_ALL_ARTISTS } from 'api/requests.v2';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { ErrorMessage } from 'components/shared/error';
import Autocomplete from 'components/shared/form/Autocomplete';
import DefaultTextInput from 'components/shared/form/DefaultTextInput';
import { SpinLoader } from 'components/shared/loader';
import {
  GraphikMediumTextSm,
  GraphikSemiTextMd,
} from 'components/shared/styled';
import { RegistrationType, UserRole } from 'constants/enums';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { RegisterStore } from 'store/registerWork/apollo';
import { getUserRole } from 'store/user';
import { Artist, AutocompleteItem } from 'types';
import { ArtworkRegistrationForm } from 'types/RegisterWork';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { RegisterWorkFields } from '../Helpers';
import { useGetFormDetails } from '../forms/artist-registration.form';

export const ArtistRegistration: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const arForm = useGetFormDetails(detailsForm);
  const autoArtistSelected = useReactiveVar(
    RegisterStore.Vars.autoArtistSelected,
  );
  const registrationType = useReactiveVar(RegisterStore.Vars.registrationType);

  const userRole = useSelector(getUserRole);

  const [allArtists, setAllArtists] = useState<[Artist]>();
  const [autocompleteItems, setAutocompleteItems] =
    useState<[AutocompleteItem]>();

  const {
    loading,
    data,
    error: getAllArtistsError,
    refetch,
  } = useQuery(GET_ALL_ARTISTS);

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  useEffect(() => {
    if (data) {
      setAutocompleteItems(
        data?.response.map((artist: Artist) => ({
          name: artist.artistName || `${artist.firstName} ${artist.lastName}`,
          id: artist.id,
        })),
      );
      setAllArtists(data?.response);
    }
  }, [data]);

  const setArtistObjectToFields = (selectedArtist: AutocompleteItem) => {
    const targetArtistObject = allArtists!.find(
      (artistObject) => artistObject.id === selectedArtist.id,
    );
    if (targetArtistObject) {
      RegisterStore.Vars.autoArtistSelected(true);
      Object.entries(targetArtistObject).forEach(([key, value]) =>
        arForm?.setValue(key as keyof ArtworkRegistrationForm, value || ''),
      );
      // Set email to fairchain to hide artist's email
      arForm?.setValue('email', 'support@fairchain.art');
      arForm?.trigger(['artistName', 'firstName', 'lastName', 'email']);
    }
  };

  if (getAllArtistsError) return <ErrorMessage />;

  return (
    <View style={TailwindResponsive(`mb-10 mobWeb:mb-0`)}>
      <View style={TailwindResponsive(`mb-4 mobWeb:mb-0`)}>
        {loading && (
          <View
            style={TailwindResponsive(
              `flex-1 items-center justify-center mb-10 mobWeb:mb-0`,
            )}
          >
            <SpinLoader />
          </View>
        )}

        {getAllArtistsError && <ErrorMessage />}

        {data && (
          <View>
            <GraphikSemiTextMd style={TailwindResponsive(`mb-16`)}>
              Artist
            </GraphikSemiTextMd>
            <Autocomplete
              placeholder="Search Registered Artists"
              data={autocompleteItems!}
              handleOptionPress={(input) => setArtistObjectToFields(input)}
              numCharsRequired={3}
            />
          </View>
        )}
      </View>

      <View style={{ zIndex: -1 }}>
        <View
          style={TailwindResponsive(
            'flex flex-row justify-between mt-2 mob:mt-8',
          )}
        >
          <GraphikMediumTextSm>Register New Artist</GraphikMediumTextSm>

          {autoArtistSelected && (
            <FairchainButton
              buttonStyle={TailwindResponsive(`justify-start self-start`)}
              label="clear"
              onPress={() => {
                RegisterStore.Vars.autoArtistSelected(false);
                arForm?.setValue('id', '');
                arForm?.setValue('artistName', '');
                arForm?.setValue('birthYear', '');
                arForm?.setValue('email', '');
                arForm?.setValue('firstName', '');
                arForm?.setValue('lastName', '');
                arForm?.setValue('nationality', '');
              }}
              textStyle={TailwindResponsive(`underline`)}
            />
          )}
        </View>

        {autoArtistSelected ? (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.firstName}
            render={({ field: { value } }) => (
              <DefaultTextInput
                editable={false}
                label="* First Name"
                testId="RegisterAutoArtistFirstName"
                value={value}
              />
            )}
          />
        ) : (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.firstName}
            render={({ field: { onChange, value, ref } }) => (
              <DefaultTextInput
                errorMessage={
                  arForm?.formState?.errors?.firstName?.message as string
                }
                handleChange={(input) => {
                  arForm?.clearErrors('firstName');
                  onChange(input);
                }}
                inputRef={ref}
                label="* First Name"
                testId="RegisterArtistFirstName"
                value={value}
              />
            )}
          />
        )}

        {autoArtistSelected ? (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.lastName}
            render={({ field: { value } }) => (
              <DefaultTextInput
                editable={false}
                label="* Last Name"
                testId="RegisterAutoArtistLastName"
                value={value}
              />
            )}
          />
        ) : (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.lastName}
            render={({ field: { onChange, value, ref } }) => (
              <DefaultTextInput
                errorMessage={
                  arForm?.formState?.errors?.lastName?.message as string
                }
                handleChange={(input) => {
                  arForm?.clearErrors('lastName');
                  onChange(input);
                }}
                inputRef={ref}
                label="* Last Name"
                testId="RegisterArtistLastName"
                value={value}
              />
            )}
          />
        )}

        {autoArtistSelected ? (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.artistName}
            render={({ field: { value } }) => (
              <DefaultTextInput
                editable={false}
                label="Artist Name, if different from above"
                testId="RegisterAutoArtistName"
                value={value}
              />
            )}
          />
        ) : (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.artistName}
            render={({ field: { onChange, value } }) => (
              <DefaultTextInput
                handleChange={(input) => {
                  onChange(input);
                }}
                label="Artist Name, if different from above"
                value={value}
              />
            )}
          />
        )}

        {autoArtistSelected ? (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.birthYear}
            render={({ field: { value } }) => (
              <DefaultTextInput
                editable={false}
                label="Birth Year"
                testId="RegisterAutoArtistBirthYear"
                value={value}
              />
            )}
          />
        ) : (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.birthYear}
            render={({ field: { onChange, value } }) => (
              <DefaultTextInput
                handleChange={onChange}
                inputMode="numeric"
                label="Birth Year"
                value={value}
              />
            )}
          />
        )}

        {autoArtistSelected ? (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.nationality}
            render={({ field: { value } }) => (
              <DefaultTextInput
                editable={false}
                label="Nationality"
                testId="RegisterAutoArtistNationality"
                value={value}
              />
            )}
          />
        ) : (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.nationality}
            render={({ field: { onChange, value } }) => (
              <DefaultTextInput
                handleChange={onChange}
                label="Nationality"
                value={value}
              />
            )}
          />
        )}

        {(registrationType !== RegistrationType.Uncertified ||
          userRole !== UserRole.Collector) && (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.email}
            render={({ field: { onChange, value, ref } }) => (
              <View
                style={TailwindResponsive(autoArtistSelected ? 'hidden' : '')}
              >
                <DefaultTextInput
                  autoCapitalize="none"
                  autoCorrect={false}
                  inputMode="email"
                  value={value}
                  label={
                    registrationType === RegistrationType.Uncertified
                      ? 'Artist Email'
                      : '* Artist Email'
                  }
                  handleChange={(input) => {
                    arForm?.clearErrors('email');
                    onChange(input);
                  }}
                  inputRef={ref}
                  errorMessage={
                    arForm?.formState?.errors?.email?.message as string
                  }
                  testId="RegisterArtistEmail"
                />
              </View>
            )}
          />
        )}
      </View>
    </View>
  );
};

export default ArtistRegistration;
