import React, { FunctionComponent } from 'react';
import { Text, TextInput, View } from 'react-native';
import Styles from 'style';

interface SimpleTextInputProps {
  handleChange?: (text: string) => void;
  label: string;
  value?: string | undefined;
  height: number;
  width: number;
}

export const SimpleTextInput: FunctionComponent<SimpleTextInputProps> = ({
  handleChange,
  label,
  value,
  height,
  width,
}) => (
  <View>
    <TextInput
      value={value}
      onChangeText={handleChange}
      style={{
        fontFamily: Styles.Fonts.FontFamily.SansMedium,
        fontSize: Styles.Fonts.FontSize.Medium,
        backgroundColor: Styles.Colours.Gray1,
        paddingHorizontal: 0,
        borderBottomWidth: 0,
        width,
        height,
        marginBottom: '5px',
        paddingLeft: '10px',
      }}
    />
    <Text
      style={{
        color: Styles.Colours.Gray2,
        fontSize: Styles.Fonts.FontSize.Tiny,
        fontFamily: Styles.Fonts.FontFamily.SansRegular,
      }}
    >
      {label}
    </Text>
  </View>
);

export default SimpleTextInput;
