import { useReactiveVar } from '@apollo/client';
import Clipboard from '@react-native-clipboard/clipboard';
import { ExitButtonIcon } from 'components/graphics';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { GraphikSemiTextHuge, GraphikTextMd } from 'components/shared/styled';
import { FunctionComponent, useState } from 'react';
import { Modal, Pressable, ScrollView, View } from 'react-native';
import { sellerBuyerLink } from 'store/transferWork/apollo';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const BuyerLinkModal: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const buyerLink = useReactiveVar(sellerBuyerLink);
  const showModal = !!buyerLink;

  const [linkCopied, setLinkCopied] = useState<boolean>(false);

  return (
    <Modal
      style={TailwindResponsive('flex-1 items-center py-10')}
      transparent
      visible={showModal}
    >
      <Pressable
        onPress={() => {
          sellerBuyerLink(null);
        }}
        style={TailwindResponsive(
          `bg-black/50 flex-1 items-center justify-center  mob:px-4`,
        )}
      >
        <Pressable
          onStartShouldSetResponder={() => true}
          onTouchEnd={(e) => {
            e.stopPropagation();
          }}
          style={[
            TailwindResponsive(
              `bg-Light1 flex shrink rounded-lg max-w-md m-10 w-full`,
            ),
            { cursor: 'default' },
          ]}
        >
          <ScrollView style={TailwindResponsive(`pb-10 px-10 pt-6`)}>
            <View
              style={TailwindResponsive(
                `flex flex-row justify-end mb-6 -mr-7 -mt-3`,
              )}
            >
              <Pressable
                onPress={() => {
                  sellerBuyerLink(null);
                }}
                style={TailwindResponsive(`self-end`)}
              >
                <ExitButtonIcon color={Styles.Colours.Dark1} />
              </Pressable>
            </View>

            <View style={TailwindResponsive(`items-center`)}>
              <GraphikSemiTextHuge
                style={TailwindResponsive('text-center max-w-sm mb-8')}
              >
                We&apos;ve created a link for you to send to the seller.
              </GraphikSemiTextHuge>

              <GraphikTextMd style={TailwindResponsive('mb-8 text-center')}>
                Click below to copy the link and put in an email or share
                however you wish with the seller.
              </GraphikTextMd>

              {linkCopied ? (
                <GraphikTextMd style={TailwindResponsive(`mb-6 text-Success`)}>
                  Link copied!
                </GraphikTextMd>
              ) : (
                <FairchainButton
                  buttonStyle={TailwindResponsive(
                    'bg-Primary mb-6 px-8 rounded-full',
                  )}
                  label="Copy Link"
                  onPress={() => {
                    Clipboard.setString(buyerLink!);
                    setLinkCopied(true);
                    setTimeout(() => {
                      setLinkCopied(false);
                    }, 3000);
                  }}
                  textStyle={TailwindResponsive('text-Light1')}
                />
              )}

              <GraphikTextMd style={TailwindResponsive('text-center')}>
                This link will allow the buyer to easily sign in and view the
                sales offer.
              </GraphikTextMd>
            </View>
          </ScrollView>
        </Pressable>
      </Pressable>
    </Modal>
  );
};
