import { CheckboxChecked, CheckboxUnchecked } from 'components/graphics';
import React, { FunctionComponent } from 'react';
import { Pressable, Text, View } from 'react-native';
import Styles from 'style';
import { IFontFamily, IntegratedFonts } from 'style/fonts';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { GraphikTextTiny } from '../styled';

interface ICheckboxProps {
  additionalText?: string;
  checkColor?: string;
  isChecked: boolean | undefined;
  label?: string;
  labelColor?: string;
  labelFontSize?: IntegratedFonts;
  labelSpacing?: number;
  labelStyle?: IFontFamily;
  onPress: (arg: any) => void;
  size?: number;
  testId?: string;
}

export const Checkbox: FunctionComponent<ICheckboxProps> = ({
  additionalText,
  checkColor,
  isChecked,
  label,
  labelColor,
  labelFontSize,
  labelSpacing,
  labelStyle,
  onPress,
  size,
  testId,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const labelSpacingSize = labelSpacing ? `ml-${labelSpacing}` : 'ml-10';
  const labelFontStyle = labelStyle || Styles.Fonts.FontFamily.SansMedium;
  const lineHeight = labelFontSize
    ? labelFontSize.lineHeight
    : IntegratedFonts.small.lineHeight;
  const currentFontSize = labelFontSize
    ? labelFontSize.size
    : IntegratedFonts.small.size;

  return (
    <Pressable
      onPress={onPress}
      style={TailwindResponsive('self-start')}
      testID={testId}
    >
      <View style={TailwindResponsive('flex-row')}>
        <View style={TailwindResponsive('items-center h-6')}>
          <View style={TailwindResponsive(`absolute bottom-1.5 left-0`)}>
            {isChecked ? (
              <CheckboxChecked color={checkColor} width={size} />
            ) : (
              <CheckboxUnchecked
                color={checkColor}
                width={size ? size - 3 : undefined}
              />
            )}
          </View>
        </View>
        <Text
          style={{
            color: labelColor || Styles.Colours.Dark1,
            fontFamily: labelFontStyle,
            fontSize: currentFontSize,
            lineHeight,
            ...TailwindResponsive(labelSpacingSize),
          }}
        >
          {label}
          {!!additionalText && (
            <GraphikTextTiny
              style={label ? TailwindResponsive(`ml-2`) : {}}
              color={Styles.Colours.Gray2}
            >
              {additionalText}
            </GraphikTextTiny>
          )}
        </Text>
      </View>
    </Pressable>
  );
};
