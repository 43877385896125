import { useMutation, useReactiveVar } from '@apollo/client';
import { DELETE_DRAFT } from 'api/requests.v2';
import { ImageIcon, TrashIcon } from 'components/graphics';
import { FairchainImage } from 'components/shared/image';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import { SpinLoader } from 'components/shared/loader';
import { ConfirmationModal } from 'components/shared/modal/ConfirmationModal';
import { ConfirmModalStore } from 'components/shared/modal/apollo';
import { CollectionStore } from 'components/views/artworkDetails/apollo';
import { UserContext } from 'contexts';
import { format, parseISO } from 'date-fns';
import { FunctionComponent, useContext } from 'react';
import { Pressable, Text, View, ViewStyle } from 'react-native';
import Styles from 'style';
import { DraftWork, getDisplayName } from 'types';
import useTailwindResponsive, {
  getResponsiveSizes,
} from 'utilities/TailwindResponsive';
import { getWorkDimensions } from 'utilities/Work';

export interface IDraftLineItemProps {
  index: number;
  work: DraftWork;
}

export const DraftWorkLineItem: FunctionComponent<IDraftLineItemProps> = ({
  index,
  work,
}) => {
  const authUser = useContext(UserContext);
  const { mobWeb } = getResponsiveSizes();
  const { TailwindResponsive } = useTailwindResponsive();

  const boldStyle = {
    fontSize: Styles.Fonts.FontSize.Small,
    fontFamily: Styles.Fonts.FontFamily.SansMedium,
  };
  const columnSmallStyle: ViewStyle = { flex: 1, pointerEvents: 'auto' };
  const columnMediumStyle: ViewStyle = { flex: 2, pointerEvents: 'auto' };
  const columnLargeStyle: ViewStyle = { flex: 3, pointerEvents: 'auto' };
  const displayBorder = index === 0 ? '' : 'border-t border-Gray1';
  const link = `/register-work/${work.id}`;

  const refetch = useReactiveVar(CollectionStore.Vars.draftWorksRefetch);

  const [deleteDraft, { loading }] = useMutation(DELETE_DRAFT, {
    onCompleted: () => {
      refetch();
    },
  });

  const handleDelete = () => {
    deleteDraft({
      variables: {
        draftId: work.id,
        userId: authUser?.uid,
      },
    });
  };

  if (loading) {
    return (
      <View
        style={TailwindResponsive(
          `${displayBorder} flex-row min-h-[6rem] items-center`,
        )}
      >
        <SpinLoader />
      </View>
    );
  }

  return (
    <View
      style={TailwindResponsive(
        `${displayBorder} flex-row min-h-[6rem] items-center`,
      )}
    >
      <View style={TailwindResponsive(`w-20 mobWeb:w-7`)} />

      <View
        style={[
          columnSmallStyle,
          TailwindResponsive(`flex-row mobWeb:max-w-min mobWeb:mr-5`),
        ]}
      >
        <ExternalLink link={link}>
          {work.image ? (
            <FairchainImage uri={work.image} width={40} />
          ) : (
            <ImageIcon width={40} />
          )}
        </ExternalLink>
      </View>

      <View
        style={[
          columnMediumStyle,
          TailwindResponsive(`mobWeb:max-w-min mobWeb:mx-5 mobWeb:hidden`),
        ]}
      >
        <ExternalLink link={link}>
          <Text style={boldStyle}>
            {getDisplayName(work.artist) || 'No artist name'}
          </Text>
        </ExternalLink>
      </View>

      {mobWeb ? (
        <View style={TailwindResponsive(`flex-1`)}>
          <View style={[columnMediumStyle]}>
            <ExternalLink link={link}>
              <Text style={boldStyle}>
                {getDisplayName(work.artist) || 'No artist name'}
              </Text>
            </ExternalLink>
          </View>

          <View
            style={[
              TailwindResponsive('flex-row justify-between'),
              columnLargeStyle,
            ]}
          >
            <ExternalLink link={link}>
              <Text style={boldStyle}>{work.title}</Text>
            </ExternalLink>
          </View>
          <View style={TailwindResponsive(`pointer-events-auto w-24`)}>
            <ExternalLink link={link}>
              <Text style={boldStyle}>{work.yearProduced}</Text>
            </ExternalLink>
          </View>
          <View style={columnMediumStyle}>
            <ExternalLink link={link}>
              <View style={TailwindResponsive(`flex-col`)}>
                <Text
                  style={{ fontFamily: Styles.Fonts.FontFamily.SansRegular }}
                >
                  {work.medium}
                </Text>
              </View>
            </ExternalLink>
          </View>
        </View>
      ) : (
        <>
          <View style={columnLargeStyle}>
            <ExternalLink link={link}>
              <Text style={boldStyle}>{work.title}</Text>
            </ExternalLink>
          </View>
          <View style={TailwindResponsive(`pointer-events-auto w-24`)}>
            <ExternalLink link={link}>
              <Text style={boldStyle}>{work.yearProduced}</Text>
            </ExternalLink>
          </View>
          <View style={columnMediumStyle}>
            <ExternalLink link={link}>
              <View style={TailwindResponsive(`flex-col`)}>
                <Text style={boldStyle}>{work.medium}</Text>
              </View>
            </ExternalLink>
          </View>
          <View style={columnMediumStyle}>
            <ExternalLink link={link}>
              <Text style={boldStyle}>{getWorkDimensions(work)}</Text>
            </ExternalLink>
          </View>
          <View style={columnMediumStyle} />

          <View
            style={TailwindResponsive(
              `flex-row items-center pointer-events-auto w-44`,
            )}
          >
            <ExternalLink link={link}>
              <Text style={boldStyle}>
                {format(parseISO(work.lastSaved), 'PPP')}
              </Text>
            </ExternalLink>
          </View>
          <View style={TailwindResponsive('w-6 items-end')}>
            <Pressable
              onPress={() => {
                ConfirmModalStore.Vars.shouldShow(true);
              }}
              style={TailwindResponsive(
                'absolute bg-Error h-7 items-center justify-center -mt-3.5 right-0 rounded-lg top-1/2 w-7',
              )}
            >
              <TrashIcon color={Styles.Colours.Light1} width={18} />
            </Pressable>
          </View>
        </>
      )}

      <ConfirmationModal
        body="You are about to delete a work draft. Are you sure you want to do this?"
        handleConfirm={handleDelete}
        header="Confirm Delete"
      />
    </View>
  );
};
