import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { RegisterStore } from 'store/registerWork/apollo';
import { RoyaltyForm } from 'types/RegisterWork';
import * as yup from 'yup';

export const RoyaltySchema = yup
  .object()
  .shape({
    artistRoyalty: yup
      .number()
      .required('Please enter a value')
      .typeError('Please enter a valid number')
      .min(0, 'Please enter a value between 0 and 50')
      .max(50, 'Artist resale commissions are capped at 50%'),
    brandRoyalty: yup
      .number()
      .required('Please enter a value')
      .typeError('Please enter a valid number')
      .min(0, 'Please enter a value between 0 and 50')
      .max(50, 'Brand resale commissions are capped at 50%'),
    contributorRoyalties: yup.array().of(
      yup.object().shape({
        name: yup.string(),
        email: yup.string(),
        royalty: yup
          .number()
          .required('Please enter a value')
          .typeError('Please enter a valid number')
          .min(0, 'Please enter a value between 0 and 50')
          .max(50, 'Contributor resale commissions are capped at 50%'),
      }),
    ),
    galleryRoyalty: yup
      .number()
      .required('Please enter a value')
      .typeError('Please enter a valid number')
      .min(0, 'Please enter a value between 0 and 50')
      .max(50, 'Gallery resale commissions are capped at 50%')
      .test({
        name: 'Gallery Royalty Test',
        message:
          'Gallery resale commission cannot exceed the artist resale commission',
        test() {
          return (
            parseFloat(this.parent.galleryRoyalty) <=
            parseFloat(this.parent.artistRoyalty)
          );
        },
      }),
    totalRoyalty: yup
      .number()
      .required('Please enter a value')
      .typeError('Please enter a valid number')
      .min(0, 'Please enter a value between 0 and 50')
      .max(50, 'Total resale commissions are capped at 50%'),
  })
  .required();

export const useSetupRoyaltyForm = () => {
  const form = useForm<RoyaltyForm>({
    defaultValues: {
      artistRoyalty: '0',
      brandRoyalty: '0',
      contributorRoyalties: [],
      galleryRoyalty: '0',
      totalRoyalty: '0',
    },
    resolver: yupResolver(RoyaltySchema),
  });
  RegisterStore.Vars.royaltyForm(form);
};
