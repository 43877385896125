/* Copyright (C) 2020 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */
import { Footer } from 'components/shared/footer';
import { WordmarkHeader } from 'components/shared/header';
import { LoginRouteProp } from 'navigation';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ForgotPassword } from './ForgotPassword';
import { UserSignIn } from './UserSignIn';

interface ILoginProps {
  route: LoginRouteProp;
}

export const Login: FunctionComponent<ILoginProps> = ({ route }) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const [isLogin, setIsLogin] = useState(true);

  useEffect(() => {
    if (route.params?.error && route.params.error === 'expired-code') {
      setIsLogin(false);
    }
  }, [route.params?.error]);

  return (
    <>
      <View style={TailwindResponsive(`pb-32 bg-Dark1 flex flex-1`)}>
        <View style={TailwindResponsive('mt-10')}>
          <WordmarkHeader color={Styles.Colours.Light1} />
        </View>
        {isLogin && <UserSignIn setIsLogin={() => setIsLogin(false)} />}
        {!isLogin && <ForgotPassword setIsLogin={setIsLogin} />}
      </View>

      <Footer
        backgroundColor="Dark1"
        style={TailwindResponsive('mob:hidden')}
        textColor={Styles.Colours.Light1}
      />
    </>
  );
};

export default Login;
