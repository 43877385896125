import { FairchainLogo } from 'components/graphics';
import React, { FunctionComponent, ReactNode } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { getUserRole } from 'store/user';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ExternalLink } from '../link/ExternalLink';
import { MainMenuMobileWeb } from './MainMenuMobileWeb';
import { MainMenuWeb } from './MainMenuWeb';
import SubSignUpModal from './SubSignUpModal';

export interface IMainHeaderProps {
  children?: ReactNode;
  hideIosBorder?: boolean;
  title?: string;
}

export const MainHeader: FunctionComponent<IMainHeaderProps> = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const userRole = useSelector(getUserRole);
  const isMobileWeb = useMediaQuery({ maxWidth: 768 });

  return (
    <View
      style={TailwindResponsive(
        `flex flex-row w-full web:mb-20 items-center justify-between`,
      )}
    >
      <View>
        <ExternalLink link="/collection">
          <FairchainLogo />
        </ExternalLink>
      </View>
      {isMobileWeb ? (
        <MainMenuMobileWeb userRole={userRole} />
      ) : (
        <MainMenuWeb userRole={userRole} />
      )}
      <SubSignUpModal />
    </View>
  );
};

export default MainHeader;
