import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { showGSAButton } from 'store/collection';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { CollectionGSAButton } from './CollectionGSAButton';
import { Sorter } from './Sorter';
import { TabSelector } from './TabSelector';

export const CTSubHeader: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View style={TailwindResponsive(`mb-24`)}>
      <View style={TailwindResponsive(`flex flex-row`)}>
        <View style={TailwindResponsive(`w-24 mobWeb:flex-none mobWeb:mr-15`)}>
          <TabSelector />
        </View>

        <View style={TailwindResponsive(`flex-1 items-end mobWeb:items-start`)}>
          <Sorter />
        </View>
      </View>
      {!!useSelector(showGSAButton) && <CollectionGSAButton />}
    </View>
  );
};
