import Constants from 'expo-constants';

export const ENV: string =
  Constants.expoConfig?.extra?.environment || 'DEVELOPMENT';

export const EXPOCONFIG = Constants.expoConfig;

export const FIREBASE_CONFIG = {
  apiKey: EXPOCONFIG?.web?.config?.firebase?.apiKey,
  authDomain: EXPOCONFIG?.web?.config?.firebase?.authDomain,
  databaseURL: EXPOCONFIG?.web?.config?.firebase?.databaseURL,
  projectId: EXPOCONFIG?.web?.config?.firebase?.projectId,
  storageBucket: EXPOCONFIG?.web?.config?.firebase?.storageBucket,
  messagingSenderId: EXPOCONFIG?.web?.config?.firebase?.messagingSenderId,
  appId: EXPOCONFIG?.web?.config?.firebase?.appId,
  measurementId: EXPOCONFIG?.web?.config?.firebase?.measurementId,
};

let baseURL = 'http://localhost:19006';
if (ENV === 'STAGING') baseURL = 'https://stagingapp.fairchain.art';
else if (ENV === 'DEMO') baseURL = 'https://demo.fairchain.art';
else if (ENV === 'TEST') baseURL = 'https://test.fairchain.art';
else if (ENV === 'PRODUCTION') baseURL = 'https://app.fairchain.art';

// This is purely for some integrations that require https callbacks
export const SITE_BASE_URL_HTTPS = baseURL.includes('https://')
  ? baseURL
  : 'https://stagingapp.fairchain.art';

export const SITE_BASE_URL = baseURL;
export const SITE_VERSION = 'v1.8.0';

export const STRIPE_MERCHANT_ID = EXPOCONFIG?.extra?.stripeMerchantId;
export const STRIPE_PUBLISHABLE_KEY =
  ENV === 'PRODUCTION'
    ? EXPOCONFIG?.extra?.stripePublishableKeyProd
    : EXPOCONFIG?.extra?.stripePublishableKey;

export const VERIFF_KEY = EXPOCONFIG?.extra?.veriffKey;

export const DIMINISHED_OPTIONS = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
];

export default {
  DIMINISHED_OPTIONS,
  ENV,
  EXPOCONFIG,
  FIREBASE_CONFIG,
  SITE_BASE_URL,
  SITE_VERSION,
  STRIPE_MERCHANT_ID,
  STRIPE_PUBLISHABLE_KEY,
  VERIFF_KEY,
};
