import { useLazyQuery } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import { GET_MY_ARCHIVE } from 'api/requests.v2';
import { ErrorMessage } from 'components/shared/error';
import { SpinLoader } from 'components/shared/loader';
import { UserContext } from 'contexts';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import { setPastWorks } from 'store/collection';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ListView } from './ListView';

export const ArchiveWrapper: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const dispatch = useDispatch();
  const { TailwindResponsive } = useTailwindResponsive();

  const [getArchive, { error, loading, refetch }] = useLazyQuery(
    GET_MY_ARCHIVE,
    {
      variables: { userId: authUser?.uid },
      onCompleted: (data) => {
        dispatch(setPastWorks(data.response));
      },
    },
  );

  useEffect(() => {
    getArchive();
  }, [getArchive]);

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  if (loading)
    return (
      <View style={TailwindResponsive(`flex-1 items-center justify-center`)}>
        <SpinLoader />
      </View>
    );

  if (error) return <ErrorMessage />;

  return <ListView />;
};
