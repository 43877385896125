import { useMutation, useQuery } from '@apollo/client';
import { HANDLE_FIRST_LOGIN } from 'api/requests.v2';
import {
  GET_VERIFF_SESSION_URL,
  SAVE_VERIFF_SESSION_URL,
} from 'api/veriff.requests';
import { SITE_BASE_URL_HTTPS, VERIFF_KEY } from 'constants/constants';
import { UserContext } from 'contexts';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, submittedVerification } from 'store/user';

const getSessionUrl = async (veriffData) => {
  const response = await fetch(`https://stationapi.veriff.com/v1/sessions`, {
    body: JSON.stringify(veriffData),
    headers: {
      'Content-Type': 'application/json',
      'X-AUTH-CLIENT': VERIFF_KEY,
    },
    method: 'POST',
  });
  const json = await response.json();
  return json.verification.url;
};

export const useGetVeriffSessionUrl = () => {
  const authUser = useContext(UserContext);
  const user = useSelector(getUser);

  const [sessionUrl, setSessionUrl] = useState<string | null>(null);

  const { data } = useQuery(GET_VERIFF_SESSION_URL, {
    variables: {
      userId: authUser?.uid,
    },
  });

  const [saveSessionUrl] = useMutation(SAVE_VERIFF_SESSION_URL);

  const setupUrl = async () => {
    const veriffData = {
      verification: {
        callback: `${SITE_BASE_URL_HTTPS}/profile`,
        person: {
          firstName: user?.firstName,
          lastName: user?.lastName,
        },
        vendorData: `${user?.id}`,
      },
    };

    const newUrl = await getSessionUrl(veriffData);
    const { data: saveData } = await saveSessionUrl({
      variables: {
        userId: authUser?.uid,
        sessionUrl: newUrl,
      },
    });

    if (saveData && saveData.response && saveData.response.success) {
      setSessionUrl(newUrl);
    }
  };

  useEffect(() => {
    if (data && data.response) {
      setSessionUrl(data.response);
    } else if (data && !data.response) {
      setupUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return sessionUrl;
};

export const useHandleVerificationSubmitted = () => {
  const authUser = useContext(UserContext);
  const dispatch = useDispatch();
  const [handleFirstLogin] = useMutation(HANDLE_FIRST_LOGIN);
  return useCallback(async () => {
    await handleFirstLogin({
      variables: {
        userId: authUser?.uid,
      },
    });

    dispatch(submittedVerification(authUser?.uid || 'veriff'));
  }, [authUser, dispatch, handleFirstLogin]);
};
