import currency from 'currency.js';
import { DiminishedRoyalty } from 'store/forms';
import { FinalForm } from 'types/RegisterWork';

export const formatDims = (finalForm: FinalForm) => {
  if (finalForm.width === '' || finalForm.height === '') return '';

  return `${finalForm.height} x ${finalForm.width}${
    finalForm.depth === '' ? '' : ` x ${finalForm.depth}`
  } ${finalForm.dimensionUnits}`;
};

export const totalRoyalty = (finalForm: FinalForm, isCollectible = false) => {
  if (isCollectible) {
    const brandRoyalty = parseFloat(finalForm.brandRoyalty);
    const contributorRoyalties = finalForm.contributorRoyalties.reduce(
      (total, contributorRoyalty) => {
        const theRoyalty = Number.isNaN(parseFloat(contributorRoyalty.royalty))
          ? 0
          : parseFloat(contributorRoyalty.royalty);
        return total + theRoyalty;
      },
      0,
    );
    return currency(brandRoyalty).add(contributorRoyalties).value;
  }

  const artistRoyalty = parseFloat(finalForm.artistRoyalty);
  const galleryRoyalty = parseFloat(finalForm.galleryRoyalty);
  if (Number.isNaN(artistRoyalty)) return '-';
  if (Number.isNaN(galleryRoyalty)) return artistRoyalty;
  return currency(artistRoyalty).add(galleryRoyalty).value;
};

export const totalDiminishedRoyalty = (
  diminishedRoyalty: DiminishedRoyalty,
) => {
  const artistRoyalty = parseFloat(diminishedRoyalty.diminishedArtistValue);
  const galleryRoyalty = parseFloat(diminishedRoyalty.diminishedGalleryValue);
  if (Number.isNaN(artistRoyalty)) return '-';
  if (Number.isNaN(galleryRoyalty)) return artistRoyalty;
  return currency(artistRoyalty).add(galleryRoyalty).value;
};
