import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { AddUserLogo } from './AddUserLogo';
import { AddUserSignature } from './AddUserSignature';
import { AssociateGallery } from './AssociateGallery';
import { CreateUserForm } from './CreateUserForm';
import { DisableUser } from './DisableUser';
import { UpdateArtistName } from './UpdateArtistName';
import { VerifyUser } from './VerifyUser';

export const UserManagement: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View style={TailwindResponsive('flex-row')}>
      <CreateUserForm />
      <View
        style={TailwindResponsive(
          'flex-col w-1/2 ml-4 border-l border-Dark1 p-12',
        )}
      >
        <AddUserSignature />
        <AddUserLogo />
        <AssociateGallery />
        <DisableUser />
        <VerifyUser />
        <UpdateArtistName />
      </View>
    </View>
  );
};
