import { useReactiveVar } from '@apollo/client';
import { DefaultTextInput } from 'components/shared/form';
import { GraphikMediumTextLg } from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { signUpFormvar } from '../form';

export const PasswordStep: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const form = useReactiveVar(signUpFormvar);

  return (
    <>
      <GraphikMediumTextLg
        color={Styles.Colours.Light1}
        style={TailwindResponsive(`mb-8`)}
      >
        Create a strong password
      </GraphikMediumTextLg>

      <View style={TailwindResponsive('w-128 mobWeb:w-full')}>
        <Controller
          control={form?.control}
          name="password"
          render={({ field: { onChange, value, ref } }) => (
            <DefaultTextInput
              color={Styles.Colours.Light1}
              errorMessage={form?.formState.errors.password?.message}
              handleChange={(input) => {
                form?.clearErrors('password');
                onChange(input);
              }}
              infoMessage="Password must be a minimum of 8 characters and must include a number."
              inputRef={ref}
              label="* Password"
              secureTextEntry
              value={value}
            />
          )}
        />
      </View>

      <View style={TailwindResponsive('w-128 mobWeb:w-full')}>
        <Controller
          control={form?.control}
          name="confirmPassword"
          render={({ field: { onChange, value, ref } }) => (
            <DefaultTextInput
              color={Styles.Colours.Light1}
              errorMessage={form?.formState.errors.confirmPassword?.message}
              handleChange={(input) => {
                form?.clearErrors('confirmPassword');
                onChange(input);
              }}
              inputRef={ref}
              label="* Confirm Password"
              secureTextEntry
              value={value}
            />
          )}
        />
      </View>
    </>
  );
};
