import { useReactiveVar } from '@apollo/client';
import { ExitButtonIcon } from 'components/graphics';
import { GraphikSemiTextLg } from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { Modal, Pressable, ScrollView, View } from 'react-native';
import { CRModalScreens, DetailsData } from 'store/artworkDetails/apollo';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import CRAddressScreen from './modalScreens/AddressScreen';
import { CRScreen1 } from './modalScreens/Screen1';
import CRScreen2 from './modalScreens/Screen2';

export const CollectorRetroModal: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const artwork = useReactiveVar(DetailsData.work);
  const form = useReactiveVar(DetailsData.CollectorRetro.form);
  const screen = useReactiveVar(DetailsData.CollectorRetro.modalScreen);
  const showModal = useReactiveVar(DetailsData.CollectorRetro.showModal);

  // Reset to screen one on hide
  if (!showModal) DetailsData.CollectorRetro.modalScreen(CRModalScreens.one);

  if (!artwork || !form) return null;

  return (
    <Modal
      style={TailwindResponsive('flex-1 items-center py-10')}
      transparent
      visible={showModal}
    >
      <Pressable
        onPress={() => {
          DetailsData.CollectorRetro.showModal(false);
        }}
        style={TailwindResponsive(
          `bg-black/50 flex-1 items-center justify-center  mob:px-4`,
        )}
      >
        <Pressable
          onStartShouldSetResponder={() => true}
          onTouchEnd={(e) => {
            e.stopPropagation();
          }}
          style={[
            TailwindResponsive(
              `bg-Light1 flex shrink rounded-lg max-w-2xl m-10 w-full`,
            ),
            { cursor: 'default' },
          ]}
        >
          <ScrollView style={TailwindResponsive(`p-10 pt-6`)}>
            <View
              style={TailwindResponsive(
                `border-b flex flex-row justify-between mb-6 pb-2`,
              )}
            >
              <GraphikSemiTextLg>Request Certification</GraphikSemiTextLg>

              <Pressable
                onPress={() => {
                  DetailsData.CollectorRetro.showModal(false);
                }}
                style={TailwindResponsive(`self-end`)}
              >
                <ExitButtonIcon color={Styles.Colours.Dark1} />
              </Pressable>
            </View>

            {screen === CRModalScreens.one && <CRScreen1 />}
            {screen === CRModalScreens.two && <CRAddressScreen />}
            {screen === CRModalScreens.three && <CRScreen2 />}
          </ScrollView>
        </Pressable>
      </Pressable>
    </Modal>
  );
};
