import { useReactiveVar } from '@apollo/client';
import { SpinLoader } from 'components/shared/loader';
import { GraphikSemiTextLg } from 'components/shared/styled';
import { UserRole } from 'constants/enums';
import { FunctionComponent } from 'react';
import { Platform, View } from 'react-native';
import { useSelector } from 'react-redux';
import { getFeatureFlags } from 'store/general';
import { getUserRole } from 'store/user';
import { ProfileVars } from '../../apollo';
import ActiveSub from './ActiveSub';
import CanceledSub from './CanceledSub';
import InactiveSub from './InactiveSub';
import { useGetSubscription, useGetSubscriptionStatus } from './hook';

export enum SubscriptionStatus {
  active = 'active',
  canceled = 'canceled',
  inactive = 'inactive',
  trialing = 'trialing',
}

export const ProfileSubscription: FunctionComponent = () => {
  const subStatus = useGetSubscriptionStatus();

  const loading = useReactiveVar(ProfileVars.subScreen.loading);

  const feature = useSelector(getFeatureFlags);
  const role = useSelector(getUserRole);

  const refetch = useGetSubscription();
  ProfileVars.subScreen.refetch(refetch);

  if (role !== UserRole.Collector && !feature.gallerySubscription) return null;

  return (
    <>
      {Platform.OS === 'web' && (
        <GraphikSemiTextLg>Subscription</GraphikSemiTextLg>
      )}

      {loading ? (
        <SpinLoader />
      ) : (
        <View>
          {(subStatus === SubscriptionStatus.active ||
            subStatus === SubscriptionStatus.trialing) && <ActiveSub />}
          {subStatus === SubscriptionStatus.canceled && <CanceledSub />}
          {subStatus === SubscriptionStatus.inactive && <InactiveSub />}
        </View>
      )}
    </>
  );
};
