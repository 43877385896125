import React, { FunctionComponent } from 'react';
import { Text, TextInput, View } from 'react-native';
import Styles from 'style';

interface IUnitInputProps {
  disabled?: boolean;
  errorMessage?: string;
  handleChange?: (input: string) => void;
  label: string | undefined;
  percent?: boolean;
  testId?: string;
  value: string | undefined;
}

export const UnitInput: FunctionComponent<IUnitInputProps> = ({
  disabled,
  errorMessage,
  handleChange,
  label,
  percent,
  testId,
  value,
  ...props
}) => (
  <View
    style={{
      alignItems: 'center',
      marginTop: 10,
      marginRight: 10,
      flex: 1,
    }}
  >
    <View
      style={{
        width: '100%',
        height: 60,
        marginBottom: 10,
        justifyContent: 'center',
      }}
    >
      <TextInput
        inputMode="numeric"
        testID={testId}
        value={value}
        onChangeText={handleChange}
        style={{
          color: Styles.Colours.Dark1,
          backgroundColor: disabled ? 'transparent' : Styles.Colours.Gray1,
          fontSize: Styles.Fonts.FontSize.Medium,
          fontFamily: Styles.Fonts.FontFamily.SansMedium,
          height: '100%',
          textAlign: 'center',
        }}
        editable={!disabled}
        {...props}
      />
      {percent && (
        <Text
          style={{
            color: Styles.Colours.Dark1,
            fontFamily: Styles.Fonts.FontFamily.SansMedium,
            fontSize: Styles.Fonts.FontSize.Medium,
            position: 'absolute',
            right: 45,
          }}
        >
          %
        </Text>
      )}
    </View>
    <View style={{ opacity: 0.6 }}>
      <Text
        style={{
          fontSize: Styles.Fonts.FontSize.Tiny,
          fontFamily: Styles.Fonts.FontFamily.SansRegular,
        }}
      >
        {label}
      </Text>
    </View>
    <View style={{ marginTop: 5 }}>
      <Text style={{ color: Styles.Colours.Error }}>{errorMessage}</Text>
    </View>
  </View>
);

export default UnitInput;
