import { useReactiveVar } from '@apollo/client';
import RadioButton from 'components/shared/button/RadioButton';
import { GraphikSemiTextMd, GraphikTextTiny } from 'components/shared/styled';
import { RegistrationType } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { Pressable, View } from 'react-native';
import { RegisterStore } from 'store/registerWork/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { RegisterWorkFields } from '../Helpers';
import { useGetFormDetails } from '../forms/artist-registration.form';

export const RegistrationSelection: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const arForm = useGetFormDetails(detailsForm);
  const registrationType = useReactiveVar(RegisterStore.Vars.registrationType);

  return (
    <View style={TailwindResponsive(`border-b pb-8 mb-8`)}>
      <GraphikSemiTextMd>
        How would you like to register this work?
      </GraphikSemiTextMd>

      <View style={TailwindResponsive(`mt-4`)}>
        <View>
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.registrationType}
            render={({ field: { onChange } }) => (
              <Pressable
                onPress={() => {
                  onChange(RegistrationType.Certified);
                  RegisterStore.Vars.registrationType(
                    RegistrationType.Certified,
                  );
                }}
              >
                <RadioButton
                  borderWidth={1}
                  height={20}
                  label="Certify a work."
                  selected={registrationType === RegistrationType.Certified}
                  width={20}
                />
              </Pressable>
            )}
          />
          <GraphikTextTiny
            style={TailwindResponsive('-mt-4 ml-10 mobWeb:-mt-2')}
          >
            I am the originating artist or their authorized representative and
            would like to certify a work that I currently own or have under
            consignment.
          </GraphikTextTiny>
        </View>

        <View>
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.registrationType}
            render={({ field: { onChange } }) => (
              <Pressable
                onPress={() => {
                  onChange(RegistrationType.Retro);
                  RegisterStore.Vars.registrationType(RegistrationType.Retro);
                }}
              >
                <RadioButton
                  borderWidth={1}
                  height={20}
                  label="Issue a certificate to a current owner of my work."
                  selected={registrationType === RegistrationType.Retro}
                  width={20}
                />
              </Pressable>
            )}
          />
          <GraphikTextTiny
            style={TailwindResponsive('-mt-4 ml-10 mobWeb:-mt-1')}
          >
            I am the originating artist or their authorized representative and I
            would like to certify a work that is owned by someone else.
          </GraphikTextTiny>
        </View>

        <View style={TailwindResponsive(`mb-2`)}>
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.registrationType}
            render={({ field: { onChange } }) => (
              <Pressable
                onPress={() => {
                  onChange(RegistrationType.Uncertified);
                  arForm?.setValue('digitalRepoRights', false);
                  RegisterStore.Vars.registrationType(
                    RegistrationType.Uncertified,
                  );
                }}
              >
                <RadioButton
                  borderWidth={1}
                  height={20}
                  label="Register a work (without certification)."
                  selected={registrationType === RegistrationType.Uncertified}
                  width={20}
                />
              </Pressable>
            )}
          />
          <GraphikTextTiny
            style={TailwindResponsive('-mt-4 ml-10 mobWeb:-mt-2')}
          >
            I would like to register a work (to sell or to catalog in my
            collection) without certifying it with the artist. Resale
            commissions cannot be attached to uncertified works.
          </GraphikTextTiny>
        </View>
      </View>
    </View>
  );
};
