import React, { FunctionComponent } from 'react';
import { Linking, Text, View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { FairchainButton } from '../button/FairchainButton';
import {
  GraphikMediumTextSm,
  GraphikSemiTextLg,
  GraphikSemiTextMd,
  GraphikTextSm,
  GraphikTextTiny,
} from '../styled';
import BulletPoint from './BulletPoint';
import { ISubOptionProps, PriceText } from './SubscriptionSelector';

export const RecommendedOption: FunctionComponent<ISubOptionProps> = ({
  selectOption,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const subKeyPointStyle = TailwindResponsive('my-1.5');

  return (
    <View
      style={[
        {
          boxShadow: '0 0 10px rgb(0 0 0 / 0.2)',
        },
        TailwindResponsive(
          `border border-Dark1 flex items-center p-10 my-10 relative rounded-sm max-w-lg xl:flex-1 xl:mx-10 xl:my-0`,
        ),
      ]}
    >
      <View
        style={TailwindResponsive(
          `absolute bg-Light1 border border-Dark1 py-1 px-3 -top-4`,
        )}
      >
        <GraphikSemiTextMd>Recommended</GraphikSemiTextMd>
      </View>

      <View
        style={[
          TailwindResponsive(
            `absolute bg-Primary h-20 items-center justify-center -right-8 rounded-full -top-8 w-20`,
          ),
          {
            boxShadow: '0 0 10px rgb(0 0 0 / 0.5)',
          },
        ]}
      >
        <Text
          style={{
            color: Styles.Colours.Light1,
            fontFamily: Styles.Fonts.FontFamily.SansSemiBold,
            fontSize: 16,
          }}
        >
          3{' '}
          <Text
            style={{
              color: Styles.Colours.Light1,
              fontFamily: Styles.Fonts.FontFamily.SansSemiBold,
              fontSize: 12,
            }}
          >
            month
          </Text>
        </Text>

        <Text
          style={{
            color: Styles.Colours.Light1,
            fontFamily: Styles.Fonts.FontFamily.SansSemiBold,
            fontSize: 12,
          }}
        >
          free trial
        </Text>
      </View>

      <GraphikSemiTextLg>Collector Standard</GraphikSemiTextLg>

      <View style={TailwindResponsive(`flex flex-row items-start mt-6`)}>
        <GraphikTextSm>$</GraphikTextSm>
        <PriceText>49</PriceText>
      </View>

      <GraphikTextSm>per month</GraphikTextSm>

      <View style={TailwindResponsive('h-28 items-center w-full')}>
        <FairchainButton
          buttonStyle={TailwindResponsive(`bg-Primary mt-8 rounded-full w-4/5`)}
          label="Start free trial"
          onPress={selectOption}
          textStyle={TailwindResponsive(`text-Light1`)}
        />

        <FairchainButton
          label="Get a demo"
          onPress={() => {
            Linking.openURL('https://calendly.com/fairchain-support/help');
          }}
          textStyle={TailwindResponsive('underline')}
        />
      </View>

      <View
        style={TailwindResponsive(
          `border-t border-Dark3 items-center mt-8 pt-8`,
        )}
      >
        <GraphikSemiTextMd>Basic Features, Plus:</GraphikSemiTextMd>

        <View style={TailwindResponsive(`mt-2`)}>
          <View style={subKeyPointStyle}>
            <GraphikTextSm>
              <GraphikMediumTextSm>Unlimited</GraphikMediumTextSm> artist
              certification requests
            </GraphikTextSm>
            <View style={TailwindResponsive(`ml-4`)}>
              <BulletPoint>
                <GraphikTextTiny>
                  Request blockchain-backed certificates of authenticity
                  directly from artists whose works you already own.
                </GraphikTextTiny>
              </BulletPoint>
            </View>
          </View>

          <GraphikTextSm style={subKeyPointStyle}>
            Up to <GraphikMediumTextSm>300</GraphikMediumTextSm> Work Uploads
          </GraphikTextSm>

          <GraphikTextSm style={subKeyPointStyle}>
            <GraphikMediumTextSm>3</GraphikMediumTextSm> users included
          </GraphikTextSm>

          <GraphikTextSm style={subKeyPointStyle}>
            Free setup and data import
          </GraphikTextSm>

          <View style={subKeyPointStyle}>
            <GraphikTextSm>Documentation Storage</GraphikTextSm>
            <View style={TailwindResponsive(`ml-4`)}>
              <BulletPoint>
                <GraphikTextTiny>
                  Store all the information you need about a work (e.g. bills of
                  sale, photos, appraisal records) in one place.
                </GraphikTextTiny>
              </BulletPoint>
            </View>
          </View>

          <View style={subKeyPointStyle}>
            <GraphikTextSm>Location tracking</GraphikTextSm>
            <View style={TailwindResponsive(`ml-4`)}>
              <BulletPoint>
                <GraphikTextTiny>
                  Create location tags to track where your art resides at all
                  times.
                </GraphikTextTiny>
              </BulletPoint>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default RecommendedOption;
