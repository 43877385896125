import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import Styles from 'style';
import { ISVGProps } from './Types';

export const ScheduleIcon: FunctionComponent<ISVGProps> = ({
  color,
  width,
}) => {
  const iconWidth = width || 48;

  return (
    <Svg
      fill={color || Styles.Colours.Dark1}
      height={iconWidth}
      viewBox="0 0 48 48"
      width={iconWidth}
    >
      <Path d="m31.35 33.65 2.25-2.25-7.95-8V13.35h-3V24.6ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24t1.575-7.75q1.575-3.65 4.3-6.375 2.725-2.725 6.375-4.3Q19.9 4 24 4t7.75 1.575q3.65 1.575 6.375 4.3 2.725 2.725 4.3 6.375Q44 19.9 44 24t-1.575 7.75q-1.575 3.65-4.3 6.375-2.725 2.725-6.375 4.3Q28.1 44 24 44Zm0-20Zm0 17q7 0 12-5t5-12q0-7-5-12T24 7q-7 0-12 5T7 24q0 7 5 12t12 5Z" />
    </Svg>
  );
};

export default ScheduleIcon;
