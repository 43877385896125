import { useLazyQuery } from '@apollo/client';
import Clipboard from '@react-native-clipboard/clipboard';
import { GET_BUYER_OFFER_LINK } from 'api/requests.v2';
import { CopyIcon } from 'components/graphics';
import { RoundedButton } from 'components/shared/button';
import { SpinLoader } from 'components/shared/loader';
import {
  GraphikMediumTextHuge,
  GraphikTextMd,
  GraphikTextSm,
} from 'components/shared/styled';
import { SITE_BASE_URL } from 'constants/constants';
import { UserContext } from 'contexts';
import { format, parseISO } from 'date-fns';
import React, { FunctionComponent, useContext, useState } from 'react';
import { Pressable, View } from 'react-native';
import style from 'style';
import { Transaction } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IBuyerListItemProps {
  transaction: Transaction;
}

export const BuyerListItem: FunctionComponent<IBuyerListItemProps> = ({
  transaction,
}) => {
  const authUser = useContext(UserContext);
  const { TailwindResponsive } = useTailwindResponsive();

  const [cbColour, setCBColour] = useState(style.Colours.Dark1);
  const [genColour, setGenColour] = useState(style.Colours.Dark1);

  const [buyerOfferLink, { loading }] = useLazyQuery(GET_BUYER_OFFER_LINK, {
    onCompleted: (data) => {
      Clipboard.setString(data.response);
      setGenColour(style.Colours.Primary);
      setTimeout(() => {
        setGenColour(style.Colours.Dark1);
      }, 1000);
    },
  });

  const handleCopyTransactionDetailsLink = (transactionId: string) => {
    Clipboard.setString(
      `${SITE_BASE_URL}/transaction-details/${transactionId}`,
    );
    setCBColour(style.Colours.Primary);
    setTimeout(() => {
      setCBColour(style.Colours.Dark1);
    }, 1000);
  };

  const handleGetBuyerLink = (transactionId: string) => {
    buyerOfferLink({
      variables: {
        userId: authUser?.uid,
        transactionId,
      },
    });
  };

  return (
    <View
      key={transaction.id}
      style={TailwindResponsive(
        `bg-Light2 flex-row items-center justify-between my-2 p-8`,
      )}
    >
      <View style={TailwindResponsive(`flex-1`)}>
        {transaction.items.map((item) => (
          <View key={`${transaction.id}_${item.work.id}`}>
            <GraphikMediumTextHuge>{item.work.title}</GraphikMediumTextHuge>
            <GraphikTextSm>
              Buyer: {transaction.buyer.firstName} {transaction.buyer.lastName}
            </GraphikTextSm>
            <GraphikTextSm>
              Seller: {transaction.seller.firstName}{' '}
              {transaction.seller.lastName}
            </GraphikTextSm>
            <GraphikTextSm>
              Created: {format(parseISO(transaction.createDate), 'PPP')}
            </GraphikTextSm>
          </View>
        ))}
      </View>
      <View style={TailwindResponsive(`flex-1 flex-row items-center`)}>
        <GraphikTextMd>{`${SITE_BASE_URL}/transaction-details/${transaction.id}`}</GraphikTextMd>
        <Pressable
          onPress={() => handleCopyTransactionDetailsLink(transaction.id)}
          style={TailwindResponsive(`px-4`)}
        >
          <CopyIcon color={cbColour} />
        </Pressable>
      </View>
      <View>
        {loading ? (
          <SpinLoader width={50} />
        ) : (
          <RoundedButton
            label="Generate Buyer Link"
            onPress={() => handleGetBuyerLink(transaction.id)}
            textStyle={{
              color: genColour,
            }}
          />
        )}
      </View>
    </View>
  );
};
