import { useLazyQuery } from '@apollo/client';
import { GET_INVOICE_URL } from 'api/requests.v2';
import { ErrorMessage } from 'components/shared/error';
import { SpinLoader } from 'components/shared/loader';
import { GraphikMediumTextMd } from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { Linking, View } from 'react-native';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';
import { Status } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const CompleteAgreementInvoice: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const transaction = useGetTransactionDetailsHook();

  const [
    getInvoiceUrl,
    { error: getInvoiceURLError, loading: loadingInvoice },
  ] = useLazyQuery(GET_INVOICE_URL, {
    onCompleted: ({ response }) => {
      if (response) {
        Linking.openURL(response);
      }
    },
    fetchPolicy: 'network-only',
  });

  if (!transaction) return null;

  if (
    !(
      (transaction.status === Status.signed ||
        transaction.status === Status.buyerSigned) &&
      transaction.isBuyerView &&
      transaction.invoiceFile &&
      !transaction.stripePaymentIntentId
    )
  )
    return null;

  if (getInvoiceURLError)
    return <ErrorMessage error={getInvoiceURLError as Error} />;

  if (loadingInvoice)
    return (
      <View style={TailwindResponsive(`mt-10`)}>
        <SpinLoader />
      </View>
    );

  return (
    <GraphikMediumTextMd style={TailwindResponsive('mt-10')}>
      The agreement is now complete. If you haven&apos;t done so already, you
      can complete payment via the instructions in the{' '}
      <GraphikMediumTextMd
        onPress={() => {
          getInvoiceUrl({
            variables: { invoiceFile: transaction?.invoiceFile },
          });
        }}
        style={TailwindResponsive(`text-Primary`)}
      >
        seller&apos;s invoice
      </GraphikMediumTextMd>
      .
    </GraphikMediumTextMd>
  );
};

export default CompleteAgreementInvoice;
