import { useReactiveVar } from '@apollo/client';
import CheckOnlyIcon from 'components/graphics/CheckOnlyIcon';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { GraphikTextMd } from 'components/shared/styled';
import { UserRole } from 'constants/enums';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { DetailsData } from 'store/artworkDetails/apollo';
import { getUserRole } from 'store/user';
import Styles from 'style';
import { CertificationStatus } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useSetupCollectorRetroRequestForm } from '../../forms/collector-retro.form';
import { CollectorRetroModal } from './CollectorRetroModal';

export const CollectorRetroRequest: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const userRole = useSelector(getUserRole);

  // Initialize our collector retro form
  useSetupCollectorRetroRequestForm();

  const artwork = useReactiveVar(DetailsData.work);
  const completed = useReactiveVar(DetailsData.CollectorRetro.completed);
  const donezo = useReactiveVar(DetailsData.CollectorRetro.donezo);
  const form = useReactiveVar(DetailsData.CollectorRetro.form);
  const loading = useReactiveVar(DetailsData.CollectorRetro.requestLoading);

  if (
    userRole !== UserRole.Collector ||
    !artwork ||
    !form ||
    artwork.certified ||
    artwork.certificationStatus === CertificationStatus.artistApproved ||
    donezo
  )
    return <View />;

  if (
    completed ||
    artwork.certificationStatus === CertificationStatus.requested ||
    artwork.certificationStatus === CertificationStatus.adminApproved
  ) {
    return (
      <View style={TailwindResponsive(`flex flex-row ml-2 py-3`)}>
        <CheckOnlyIcon color={Styles.Colours.Success} width={22} />
        <GraphikTextMd style={TailwindResponsive(`text-Success`)}>
          Certification Requested
        </GraphikTextMd>
      </View>
    );
  }

  return (
    <>
      <View style={TailwindResponsive('items-start')}>
        <FairchainButton
          buttonStyle={TailwindResponsive('bg-Dark1 px-6 rounded-full w-full')}
          label="Request Certification"
          loading={loading}
          loadingStyle={TailwindResponsive(`w-48`)}
          onPress={() => {
            DetailsData.CollectorRetro.showModal(true);
          }}
          textStyle={TailwindResponsive('text-Light1')}
        />
      </View>
      <CollectorRetroModal />
    </>
  );
};
