export enum AgentType {
  gift = 'GIFT',
  advisor = 'ADVISOR',
  transfer = 'TRANSFER',
}

export enum ButtonAlignment {
  Left,
  Right,
  Center,
}

export enum IdVerificationMethod {
  IdViewed = 'ID Viewed',
  PreviouslyKnown = 'Previously Known',
  VerifiedViaJumio = 'ID verified via Jumio',
}

export enum HashPriority {
  Normal = 'NORMAL',
  Immediate = 'IMMEDIATE',
}

export enum RegistrationType {
  Certified = 'Certified',
  Retro = 'Retro',
  Uncertified = 'Uncertified',
}

export enum AssetType {
  Artwork = 'Artwork',
  Collectible = 'Collectible',
}

export const alignFromEnum = (alignment: ButtonAlignment) => {
  switch (alignment) {
    case ButtonAlignment.Center: {
      return 'center';
    }
    case ButtonAlignment.Right: {
      return 'flex-end';
    }
    case ButtonAlignment.Left: {
      return 'flex-start';
    }
    default: {
      return 'flex-start';
    }
  }
};

export enum CollectionPage {
  Collection,
  Transaction,
}

export enum CollectionSort {
  ArtistClient,
  Date,
}

export enum CollectionTab {
  Past,
  Current,
  Consigned,
  Drafts,
}

export enum CollectionView {
  Grid,
  Line,
}

export enum UserRole {
  Admin,
  Artist,
  Collector,
  Estate,
  Gallery,
  Brand,
}

export enum ImageSelectionPage {
  Admin,
  RegisterWork,
}

export enum RoyaltyType {
  artist = 'artistRoyalty',
  brand = 'brandRoyalty',
  collector = 'collectorRoyalty',
  contributor = 'contributorRoyalty',
  curator = 'curatorRoyalty',
  gallery = 'galleryRoyalty',
}

export enum TextAlignment {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

export enum TransactionItemPosition {
  First = 'first',
  Middle = 'Middle',
  Last = 'Last',
}

export enum UserRights {
  Collection = 'MainUserCollection',
  ContactList = 'MainUserClientList',
  Transactions = 'MainUserTransactions',
}

export const alignTextFromEnum = (alignment: TextAlignment) => {
  switch (alignment) {
    case TextAlignment.Center: {
      return 'center';
    }
    case TextAlignment.Right: {
      return 'right';
    }
    case TextAlignment.Left: {
      return 'left';
    }
    default: {
      return 'center';
    }
  }
};

export default {
  alignFromEnum,
  alignTextFromEnum,
  ButtonAlignment,
  CollectionPage,
  CollectionSort,
  CollectionTab,
  CollectionView,
  ImageSelectionPage,
  RoyaltyType,
  TextAlignment,
  UserRights,
  UserRole,
};
