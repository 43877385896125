import currency from 'currency.js';
import React, { FunctionComponent, useMemo } from 'react';
import { CurrencyType, WorkWithObject } from 'types';
import { getCurrencySymbol } from 'utilities';
import { TransferWorkConfirmationDetails } from './components/ConfirmationDetails';
import {
  calculatePaymentWithStripeFeePassedOn,
  TransactionFinalForm,
} from './Helpers';

export interface ConfirmationProps {
  finalForm: TransactionFinalForm;
  getVars: () => Promise<any>;
  works: WorkWithObject[];
}

export interface IConfirmationDetailsProps extends ConfirmationProps {
  cardFee: string;
  hasInvoice: boolean;
  invoiceTotal: string;
  passFees: boolean;
  shippingValue: string;
  taxValue: string;
}

export const Confirmation: FunctionComponent<ConfirmationProps> = ({
  finalForm,
  getVars,
  works,
}) => {
  const currencySymbol = getCurrencySymbol(finalForm.currency);

  const hasInvoice =
    finalForm &&
    finalForm.generateInvoice &&
    finalForm.currency === CurrencyType.USD;
  const passFees = finalForm && finalForm.passFeesToBuyer && hasInvoice;

  const totalWithShippingAndTax = useMemo(() => {
    const totalArtworkValue = finalForm.items.reduce((prevItem, currItem) => {
      const discount = currItem.discountApplied
        ? currency(currItem.artworkValue).multiply(currItem.discount / 100)
        : currency(0);
      const discountedValue = currency(currItem.artworkValue, {
        symbol: currencySymbol,
      }).subtract(discount);
      return prevItem.add(discountedValue);
    }, currency(0, { symbol: currencySymbol }));
    return totalArtworkValue.add(finalForm.tax).add(finalForm.shipping);
  }, [currencySymbol, finalForm]);

  const invoiceTotal = passFees
    ? calculatePaymentWithStripeFeePassedOn(
        totalWithShippingAndTax.value,
        currencySymbol,
      ).format()
    : totalWithShippingAndTax.format();

  const cardFee = calculatePaymentWithStripeFeePassedOn(
    totalWithShippingAndTax.value,
    currencySymbol,
  )
    .subtract(totalWithShippingAndTax)
    .format();

  const shippingValue = currency(finalForm.shipping, {
    symbol: currencySymbol,
  }).format();

  const taxValue = currency(finalForm.tax, {
    symbol: currencySymbol,
  }).format();

  return (
    <TransferWorkConfirmationDetails
      cardFee={cardFee}
      finalForm={finalForm}
      getVars={getVars}
      hasInvoice={hasInvoice}
      invoiceTotal={invoiceTotal}
      passFees={passFees}
      shippingValue={shippingValue}
      taxValue={taxValue}
      works={works}
    />
  );
};
