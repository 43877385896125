import InfoIconNoFill from 'components/graphics/InfoIconNoFill';
import { Checkbox } from 'components/shared/checkbox';
import { DefaultTextInput } from 'components/shared/form';
import { GraphikTextSm } from 'components/shared/styled';
import { AgentType } from 'constants/enums';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { Linking, Platform, View } from 'react-native';
import { IconButton } from 'react-native-paper';
import {
  agentEmailVar,
  agentNameVar,
  agentTypeVar,
  isAgentVar,
  useGetAgentInfo,
} from 'store/agentBuyer/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const GiftOption: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const agentInfo = useGetAgentInfo();

  const [agentBuyerEmail, setAgentBuyerEmail] = useState('');
  const [agentBuyerName, setAgentBuyerName] = useState('');

  const isAgent = !!agentInfo && agentInfo.isAgent;

  const agentTextOptions = useMemo(() => {
    switch (agentInfo.agentType) {
      case 'ADVISOR':
      case 'TRANSFER':
        return {
          name: '* Client Name',
          email: '* Client email',
        };
      default:
        return {
          name: '* Recipient name',
          email: '* Recipient email',
        };
    }
  }, [agentInfo]);

  return (
    <View>
      <Checkbox
        isChecked={isAgent}
        label="Are you buying this work on behalf of someone else (e.g., as an agent for another buyer or as a gift)?"
        onPress={() => {
          isAgentVar(!isAgent);
        }}
      />

      {isAgent && (
        <View style={TailwindResponsive(`mt-5`)}>
          <View
            style={TailwindResponsive(
              'flex flex-row items-start bg-Dark6 p-8 mob:p-2',
            )}
          >
            <View>
              {Platform.OS === 'web' ? (
                <InfoIconNoFill width={24} />
              ) : (
                <IconButton
                  icon="information-outline"
                  style={TailwindResponsive(`m-0`)}
                />
              )}
            </View>

            <View style={TailwindResponsive('flex-1 mx-8 mob:mx-1')}>
              <GraphikTextSm>
                Fairchain allows buyers to temporarily take ownership of the
                certificate of title in the case of purchase on behalf of
                another person. In these cases, title may be transferred without
                revealing the identities of the buyer and seller. If title is
                transferred to the eventual owner within 90 days, you will not
                be charged any transfer commissions, as per our{' '}
                <GraphikTextSm
                  style={TailwindResponsive('text-Primary')}
                  onPress={() => {
                    Linking.openURL('https://app.fairchain.art/terms');
                  }}
                >
                  terms of use
                </GraphikTextSm>
                .
              </GraphikTextSm>
            </View>
          </View>

          <View style={TailwindResponsive(`mt-4`)}>
            <Checkbox
              isChecked={agentInfo.agentType === AgentType.gift}
              label="I'm buying this as a gift"
              onPress={() => {
                agentTypeVar(AgentType.gift);
              }}
            />
          </View>

          <View style={TailwindResponsive(`mt-2`)}>
            <Checkbox
              isChecked={agentInfo.agentType === AgentType.advisor}
              label="I'm an advisor and I'm authorized to sign the sales agreement on behalf of my client"
              onPress={() => {
                agentTypeVar(AgentType.advisor);
              }}
            />
          </View>

          <View style={TailwindResponsive(`mt-2`)}>
            <View style={TailwindResponsive(`flex flex-column`)}>
              <Checkbox
                isChecked={agentInfo.agentType === AgentType.transfer}
                label="I am buying this for a specific client but will transfer title to them at a later date"
                onPress={() => {
                  agentTypeVar(AgentType.transfer);
                }}
              />
              <GraphikTextSm style={TailwindResponsive(`ml-10 mt-4 mr-4`)}>
                Note: Such an exchange will not be subject to title transfer
                fees or commissions provided the transfer occurs within the next
                90 days.
              </GraphikTextSm>
            </View>
          </View>

          <DefaultTextInput
            label={agentTextOptions.name}
            handleChange={(newValue) => {
              setAgentBuyerName(newValue);
              agentNameVar(newValue);
            }}
            value={agentBuyerName}
          />

          <DefaultTextInput
            label={agentTextOptions.email}
            handleChange={(newValue) => {
              setAgentBuyerEmail(newValue);
              agentEmailVar(newValue);
            }}
            value={agentBuyerEmail}
          />
        </View>
      )}
    </View>
  );
};
