import { makeVar } from '@apollo/client';

export class ConfirmModalStore {
  public static Vars = {
    shouldShow: makeVar<boolean>(false),
  };

  static Reset() {
    ConfirmModalStore.Vars.shouldShow(false);
  }
}
