import { FIREBASE_CONFIG } from 'constants/constants';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth, signOut, updateEmail } from 'firebase/auth';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadString,
} from 'firebase/storage';
import LogError from './LogError';

export enum FCAnalyticEvents {
  PasswordResetRequest = 'password_reset_request',
  PasswordUpdated = 'password_updated',
  RegisteredWork = 'registered_work',
  ScreenView = 'screen_view',
  TransactionUpdate = 'transaction_update',
  UserSignIn = 'user_sign_in',
  UserSignOut = 'user_sign_out',
  UserUpdate = 'user_update',
}

let firebaseApp: FirebaseApp;

export const initFB = () => {
  if (!firebaseApp) firebaseApp = initializeApp(FIREBASE_CONFIG);
};

export const getFBAuth = () => getAuth(firebaseApp);
export const getFBAnalytics = () => getAnalytics(firebaseApp);

export const logAnalyticsEvent = async (event: string, params?) => {
  logEvent(getFBAnalytics(), event, params);
};

export const FBLogout = () => signOut(getFBAuth());

export const updateAuthEmail = async (newEmail: string) => {
  const auth = getAuth(firebaseApp);
  if (auth.currentUser) await updateEmail(auth.currentUser, newEmail);
};

export const uploadBase64Async = async (
  base64: string,
  path: string,
  contentType?: string,
): Promise<boolean> => {
  try {
    const storage = getStorage(firebaseApp);
    const uploadRef = ref(storage, path);
    await uploadString(uploadRef, base64, 'base64', {
      contentType: contentType ?? 'application/pdf',
    });
    return true;
  } catch (error) {
    LogError.logError(error, `Error in uploadBase64Aync.`);
    return false;
  }
};

export const uploadDataUriAsync = async (
  uri: string,
  path: string,
): Promise<boolean> => {
  try {
    const storage = getStorage(firebaseApp);
    const uploadRef = ref(storage, path);
    await uploadString(uploadRef, uri, 'data_url');
    return true;
  } catch (error) {
    LogError.logError(error, `Error in uploadBase64Aync.`);
    return false;
  }
};

export const deleteImageAsync = async (imagePath) => {
  const storage = getStorage(firebaseApp);
  const imageRef = ref(storage, imagePath);
  await deleteObject(imageRef);
};

export const uploadImageAsync = async (uri, storagepath, imageName) => {
  // Why are we using XMLHttpRequest? See:
  // https://github.com/expo/expo/issues/2402#issuecomment-443726662
  const blob: Blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      resolve(xhr.response);
    };
    xhr.onerror = (e) => {
      reject(new TypeError(`Network request failed: ${e}`));
    };
    xhr.responseType = 'blob';
    xhr.open('GET', uri, true);
    xhr.send(null);
  });

  const storage = getStorage(firebaseApp);
  const imageRef = ref(storage, `${storagepath}${imageName}`);
  const snapshot = await uploadBytes(imageRef, blob);
  return getDownloadURL(snapshot.ref);
};

export default {
  getFBAuth,
  initFB,
  logAnalyticsEvent,
  uploadBase64Async,
  uploadDataUriAsync,
  uploadImageAsync,
};
