import { useMutation } from '@apollo/client';
import { DISABLE_USER } from 'api/requests.v2';
import { RoundedButton } from 'components/shared/button';
import { DefaultTextInput } from 'components/shared/form';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Text, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { resetLogo } from 'store/admin';
import Styles from 'style';
import { trimFormData } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const DisableUser: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const [disableUser] = useMutation(DISABLE_USER);
  const [isSuccessfullySubmitted, setSuccessfullySubmitted] = useState('');
  const [isSubmittedError, setSubmittedError] = useState('');

  const dispatch = useDispatch();

  const {
    control,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: any) => {
    const trimmedData = trimFormData(data);
    const disableUserResponse = await disableUser({
      variables: {
        email: trimmedData.email,
      },
    });

    if (disableUserResponse.data.response.success === true) {
      setSuccessfullySubmitted('🔒 User has been disabled 🚷');
    } else {
      setSubmittedError(disableUserResponse.data.response.errorMessage);
    }

    dispatch(resetLogo());
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setTimeout(() => {
        setSubmittedError('');
        setSuccessfullySubmitted('');
      }, 3000);
    }
  }, [reset, isSubmitSuccessful]);

  return (
    <View style={TailwindResponsive('pb-12')}>
      <View style={TailwindResponsive(`border-t border-Dark1 p-12`)}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              value={value}
              label="* Email"
              handleChange={onChange}
              errorMessage={errors.email?.message}
            />
          )}
          name="email"
          defaultValue=""
        />
        <View style={TailwindResponsive('m-5')}>
          <RoundedButton
            label="Disable User"
            onPress={() => {
              handleSubmit(onSubmit)();
            }}
            backgroundColor={Styles.Colours.Primary}
            color={Styles.Colours.Light1}
          />
          {!!isSuccessfullySubmitted && (
            <Text style={{ color: Styles.Colours.Success }}>
              {isSuccessfullySubmitted}
            </Text>
          )}
          {!!isSubmittedError && (
            <Text style={{ color: Styles.Colours.Error }}>
              {isSubmittedError}
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};
