export const GetEmbeddedURL = async (videoURL: string) => {
  let embeddedURI = '';
  let thumbnail = '';

  if (videoURL.includes('vimeo')) {
    const response = await fetch(
      `https://vimeo.com/api/oembed.json?url=${videoURL}`,
    );
    const json = await response.json();
    embeddedURI = `https://player.vimeo.com/video/${json.video_id}`;
    if (videoURL.includes('autoplay')) {
      embeddedURI += '?autoplay=1&loop=1&autopause=0&muted=1';
    }
    thumbnail = json.thumbnail_url;
  } else if (videoURL.includes('youtube')) {
    const split = videoURL.split('v=');
    if (split.length > 1 && split[1]) {
      embeddedURI = `https://www.youtube.com/embed/${split[1]}`;
      thumbnail = `https://img.youtube.com/vi/${split[1]}/default.jpg`;
    }
  } else if (videoURL.includes('youtu.be')) {
    const split = videoURL.split('be/');
    if (split.length > 1 && split[1]) {
      embeddedURI = `https://www.youtube.com/embed/${split[1]}`;
      thumbnail = `https://img.youtube.com/vi/${split[1]}/default.jpg`;
    }
  }

  return {
    thumbnail,
    uri: embeddedURI,
  };
};

export default {
  GetEmbeddedURL,
};
