import * as SplashScreen from 'expo-splash-screen';
import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { SpinLoader } from '../loader';

interface IProps {
  appIsready: boolean;
  children: ReactNode;
}

export const AppLoader: FunctionComponent<IProps> = ({
  appIsready,
  children,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const onLayoutRootView = useCallback(async () => {
    if (appIsready) await SplashScreen.hideAsync();
  }, [appIsready]);

  if (!appIsready) return <SpinLoader />;

  return (
    <View
      onLayout={onLayoutRootView}
      style={TailwindResponsive(`flex-1 w-full`)}
    >
      {children}
    </View>
  );
};
