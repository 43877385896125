import HiddenPasswordIcon from 'components/graphics/HiddenPasswordLogo';
import ShowPasswordIcon from 'components/graphics/ShowPasswordIcon';
import { RoundedButton } from 'components/shared/button';
import FlatButton from 'components/shared/button/FlatButton';
import DefaultTextInput from 'components/shared/form/DefaultTextInput';
import { SpinLoader } from 'components/shared/loader';
import { useAppStarter } from 'components/shared/page/AppStarter';
import { ButtonAlignment } from 'constants/enums';
import { UserContext } from 'contexts';
import { MultiFactorError, signInWithEmailAndPassword } from 'firebase/auth';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Pressable, View } from 'react-native';
import Styles from 'style';
import { FCAnalyticEvents, getFBAuth, logAnalyticsEvent } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import SignUpLink from './components/SignUpLink';

interface ILoginProps {
  setIsLogin: (bool: boolean) => void;
}

export const UserSignIn: FunctionComponent<ILoginProps> = ({ setIsLogin }) => {
  useAppStarter();
  const firebaseUser = useContext(UserContext);
  const { TailwindResponsive } = useTailwindResponsive();

  const firebaseAuth = getFBAuth();

  const [email, updateEmail] = useState('');
  const [error, updateError] = useState('');
  const [password, updatePassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = useCallback(
    async (event) => {
      if (event.key === 'Enter' || event === 0) {
        try {
          await signInWithEmailAndPassword(
            firebaseAuth,
            email.trim(),
            password,
          );
          logAnalyticsEvent(FCAnalyticEvents.UserSignIn, {
            email: email.trim(),
          });
        } catch (caughtError) {
          const authError = caughtError as MultiFactorError;

          if (authError.code === 'auth/wrong-password') {
            updateError('Your username or password was incorrect');
          } else if (authError.code === 'auth/invalid-verification-code') {
            updateError('Incorrect verification code');
          } else if (authError.code === 'auth/user-disabled') {
            updateError('This account has been disabled');
          } else {
            updateError('Your username or password was incorrect');
          }
        }
      }
    },
    [email, firebaseAuth, password],
  );

  if (firebaseUser) return <SpinLoader color={Styles.Colours.Light1} />;

  return (
    <View style={TailwindResponsive('flex mt-16 mb-28 mx-14 mob:mx-6')}>
      {/* Wrapper below hides login email/password entry for depracation purposes */}
      <View style={TailwindResponsive('hidden')}>
        <View style={TailwindResponsive('w-2/5 mobWeb:w-full')}>
          <DefaultTextInput
            value={email}
            label="Email"
            handleChange={updateEmail}
            color={Styles.Colours.Light1}
            onKeyPress={handleLogin}
            testId="LoginEmail"
          />
        </View>
        <View style={TailwindResponsive('flex-row')}>
          <View
            style={TailwindResponsive(
              'w-2/5 mb-10 mobWeb:w-full mobWeb:flex-1 mobWeb:-mt-6',
            )}
          >
            <DefaultTextInput
              value={password}
              label="Password"
              secureTextEntry={showPassword !== true}
              handleChange={updatePassword}
              color={Styles.Colours.Light1}
              errorMessage={error}
              onKeyPress={handleLogin}
              testId="LoginPassword"
            />
          </View>
          <View
            style={TailwindResponsive(
              `ml-2 top-12.5 pr-2 bg-Dark1 justify-start items-start mobWeb:mt-6 mobWeb:top-0 mobWeb:ml-0 mobWeb:pr-0 mobWeb:ml-2`,
            )}
          >
            <Pressable onPress={() => setShowPassword(!showPassword)}>
              {showPassword ? (
                <HiddenPasswordIcon width={24} color="white" />
              ) : (
                <ShowPasswordIcon width={24} color="white" />
              )}
            </Pressable>
          </View>
        </View>
      </View>
      {/* Wrapper below hides login button for depracation purposes */}
      <View style={TailwindResponsive('hidden')}>
        <View
          style={TailwindResponsive(
            'flex-row w-64 mt-2 mobWeb:w-full mobWeb:flex-col',
          )}
        >
          <RoundedButton
            testId="Login"
            alignment={ButtonAlignment.Left}
            label="Log In"
            onPress={() => handleLogin(0)}
            backgroundColor={Styles.Colours.Primary}
            color={Styles.Colours.Light1}
            paddingHorizontal={30}
          />

          <View style={TailwindResponsive('ml-10 mobWeb:ml-0')}>
            <FlatButton
              buttonStyle={TailwindResponsive(
                'items-start h-20 px-0 self-start',
              )}
              label="Forgot Password?"
              onPress={() => {
                setIsLogin(false);
              }}
              textStyle={TailwindResponsive('text-MidGray')}
            />
          </View>
        </View>
      </View>

      <SignUpLink />
    </View>
  );
};

export default UserSignIn;
