import { useReactiveVar } from '@apollo/client';
import { ImagesWithThumbnails } from 'components/shared/image';
import {
  GraphikMediumTextMd,
  GraphikSemiTextSm,
  GraphikTextMd,
  GraphikTextSm,
} from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import TDGQL, {
  useGetTransactionDetailsHook,
} from 'store/transactionDetails/apollo';
import Styles from 'style';
import { Status, Work, getDisplayName } from 'types';
import { isCollectible } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { TransactionItem, UserInformation } from './components';
import CancelTransaction from './components/CancelTransaction';
import CompleteAgreementInvoice from './components/CompleteAgreementInvoice';
import ExpirationHeader from './components/ExpirationHeader';
import OfferAcceptance from './components/OfferAcceptance';
import Payment from './components/Payment';
import SalesOfferHeader from './components/SalesOfferHeader';
import TransactionProgress from './components/TransactionProgress';
import TransactionValue from './components/TransactionValue';
import { TransferTitle } from './components/TransferTitle';
import ViewContract from './components/ViewContract';

export const TDWrapper: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const transaction = useGetTransactionDetailsHook();
  const titleTransferSucceeded = useReactiveVar(
    TDGQL.transferTitleSucceededVar,
  );

  const transactionIsForCollectibles =
    transaction?.items && transaction?.items.length > 0
      ? isCollectible(transaction?.items[0]?.work as Work)
      : false;
  const isTitleTransferInProgress =
    titleTransferSucceeded || transaction?.status === Status.pendingApproval;

  const sellerName = getDisplayName(transaction?.seller);

  if (!transaction) return null;

  return (
    <>
      {!transactionIsForCollectibles && (
        <GraphikMediumTextMd>Title Transfer In Progress: </GraphikMediumTextMd>
      )}
      <ViewContract />
      <SalesOfferHeader assetIsCollectible={transactionIsForCollectibles} />

      <View style={TailwindResponsive(`flex w-1/2 mobWeb:w-full`)}>
        <View style={TailwindResponsive(`mb-8 mobWeb:mb-4`)}>
          <UserInformation />
        </View>
        <ExpirationHeader />
      </View>
      <View style={TailwindResponsive(`relative`)}>
        {transaction?.items.map(({ discount, price, work }, index) => (
          <View
            key={`${work.id}_View`}
            style={TailwindResponsive(
              `flex flex-row mb-6 relative ${
                index !== transaction.items.length - 1 ? `border-b` : ''
              } pb-5 mobWeb:mb-2 mobWeb:flex-col-reverse mob:flex-wrap`,
            )}
          >
            <View style={TailwindResponsive(`flex-1 pb-16 pr-24 mobWeb:p-0`)}>
              <TransactionItem
                discount={discount}
                index={index}
                key={work.id}
                price={price}
                work={work}
              />
            </View>

            <View
              style={TailwindResponsive(
                `flex-1 pl-5 mobWeb:mt-16 mobWeb:mb-8 mobWeb:flex-none`,
              )}
            >
              <ImagesWithThumbnails
                isLocal
                images={work.images}
                videoURL={work.videoURL}
              />
            </View>
          </View>
        ))}
      </View>

      {transaction.isMultiWork && (
        <View style={TailwindResponsive(`mt-8 w-1/2 mobWeb:w-full`)}>
          <TransactionProgress />

          {!transaction.isRetro && (
            <View style={TailwindResponsive(`mt-4`)}>
              <TransactionValue
                assetIsCollectible={transactionIsForCollectibles}
              />
            </View>
          )}

          {transaction.isBuyerView && transaction.isVoided && (
            <GraphikSemiTextSm color={Styles.Colours.Error}>
              This sales agreement is no longer available. Please contact the
              seller for further details.
            </GraphikSemiTextSm>
          )}

          {isTitleTransferInProgress && (
            <GraphikTextMd
              style={{
                ...TailwindResponsive('my-2.5'),
                color: Styles.Colours.Primary,
              }}
            >
              Title transfer in progress
            </GraphikTextMd>
          )}

          {!transaction.isBuyerView && (
            <CancelTransaction
              label={
                transaction?.isRetro ? 'Cancel Certificate' : 'Cancel Sale'
              }
            />
          )}

          <OfferAcceptance />

          {(transaction.status === Status.signed ||
            transaction.status === Status.buyerSigned) &&
            transaction.isBuyerView &&
            !transaction.invoiceFile && (
              <GraphikMediumTextMd style={TailwindResponsive('mt-4')}>
                Transfer Pending: Once {sellerName} has indicated that this
                transaction is complete, the title(s) will be transferred.
              </GraphikMediumTextMd>
            )}

          <CompleteAgreementInvoice />

          {transaction.status === Status.completed &&
            transaction.isBuyerView && (
              <GraphikMediumTextMd style={TailwindResponsive('mt-4')}>
                This transaction is complete and the certificate has been issued
                to your collection.
              </GraphikMediumTextMd>
            )}

          {transaction.isBuyerView && transaction.optionalMessage !== '' && (
            <View style={TailwindResponsive(`flex flex-col mb-10 max-w-lg`)}>
              <GraphikMediumTextMd>Message from Seller</GraphikMediumTextMd>
              <GraphikTextSm>{transaction.optionalMessage}</GraphikTextSm>
            </View>
          )}

          <TransferTitle />

          <View style={TailwindResponsive(`mt-10`)}>
            <Payment />
          </View>
        </View>
      )}
    </>
  );
};

export default TDWrapper;
