import { useReactiveVar } from '@apollo/client';
import { DefaultTextInput } from 'components/shared/form';
import { GraphikMediumTextLg } from 'components/shared/styled';
import { CreateUserType } from 'components/views/admin';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { signUpFormvar } from '../form';

export const InfoFormStep: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const form = useReactiveVar(signUpFormvar);
  const formRoleVal = form?.watch('role');

  const firstNameInfoMsg =
    formRoleVal === CreateUserType.Artist
      ? 'Please make sure your name matches the name listed on your government issued identification. You will be prompted to verify your ID at first login.'
      : '';
  const lastNameInfoMsg =
    formRoleVal === CreateUserType.Artist
      ? `If the name you use as an artist is different from the above, please contact support@fairchain.art after completing the signup process`
      : '';

  return (
    <>
      <GraphikMediumTextLg
        color={Styles.Colours.Light1}
        style={TailwindResponsive(`mb-8`)}
      >
        Create Account
      </GraphikMediumTextLg>

      <View style={TailwindResponsive('w-128 mobWeb:w-full mobWeb:max-w-full')}>
        <Controller
          control={form?.control}
          name="firstName"
          render={({ field: { onChange, value, ref } }) => (
            <DefaultTextInput
              color={Styles.Colours.Light1}
              errorMessage={form?.formState.errors.firstName?.message}
              handleChange={(input) => {
                form?.clearErrors('firstName');
                onChange(input);
              }}
              infoMessage={firstNameInfoMsg}
              inputRef={ref}
              label="* First Name"
              value={value}
            />
          )}
        />
      </View>

      <View style={TailwindResponsive('w-128 mobWeb:w-full')}>
        <Controller
          control={form?.control}
          name="lastName"
          render={({ field: { onChange, value, ref } }) => (
            <DefaultTextInput
              color={Styles.Colours.Light1}
              errorMessage={form?.formState.errors.lastName?.message}
              handleChange={(input) => {
                form?.clearErrors('lastName');
                onChange(input);
              }}
              infoMessage={lastNameInfoMsg}
              inputRef={ref}
              label="* Last Name"
              value={value}
            />
          )}
        />
      </View>

      <View style={TailwindResponsive('w-128 mobWeb:w-full')}>
        <Controller
          control={form?.control}
          name="email"
          render={({ field: { onChange, value, ref } }) => (
            <DefaultTextInput
              autoCapitalize="none"
              autoCorrect={false}
              color={Styles.Colours.Light1}
              errorMessage={form?.formState.errors.email?.message}
              handleChange={(input) => {
                form?.clearErrors('email');
                onChange(input);
              }}
              inputRef={ref}
              inputMode="email"
              label="* Email"
              value={value}
            />
          )}
        />
      </View>
    </>
  );
};
