import { decode as atob } from 'base-64';
import { Platform } from 'react-native';

export const extractExtensionFromB64Data = (data) => {
  if (Platform.OS === 'web') {
    const base64Split = data.split(';base64,');
    const contentType = base64Split[0]?.split('data:')[1];
    const extension = contentType.split('/')[1];
    return extension ? `.${extension}` : '';
  }
  const splitOnPoint = data.split('.');
  return `.${splitOnPoint[splitOnPoint.length - 1]}`;
};

export const b64StringtoBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: BlobPart[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const base64DataToBlob = (data) => {
  const base64Split = data.split(';base64,');
  const imgBase64 = base64Split[1];
  const contentType = base64Split[0]?.split('data:')[1];
  return b64StringtoBlob(imgBase64, contentType);
};

export const getImageFileExtension = (filepath: string) => {
  // This is to get the extension text after the . of a filepath
  const extensionRegularExpression = /(?:\.([^.]+))?$/;
  const extensionSplit = extensionRegularExpression.exec(filepath);
  const extension =
    extensionSplit && extensionSplit.length > 1 ? extensionSplit[1] : '';
  return extension || '';
};
