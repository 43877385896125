import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';
import StripeCheckoutForm from './StripeCheckoutForm';

export const PaymentFLow: FunctionComponent = () => {
  const transaction = useGetTransactionDetailsHook();

  if (!transaction || !transaction.stripePaymentIntentId) return null;

  return (
    <View>
      <StripeCheckoutForm />
    </View>
  );
};

export default PaymentFLow;
