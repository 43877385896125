import { useNavigation } from '@react-navigation/native';
import { Footer } from 'components/shared/footer';
import { WorkDetailsHeader } from 'components/shared/header';
import React, { FunctionComponent } from 'react';
import { Platform, SafeAreaView, View } from 'react-native';
import { IWorkDetailsHeaderProps } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ExitPopUpButton } from '../button';
import MainHeader from '../header/MainHeader.ios';
import { SpinLoader } from '../loader';
import { useAppStarter } from './AppStarter';

export const DetailsPage: FunctionComponent<IWorkDetailsHeaderProps> = ({
  backScreenTitle,
  backScreen,
  hideExitButton,
  title,
  ...props
}) => {
  const appReady = useAppStarter();
  const navigation = useNavigation();
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <SafeAreaView style={TailwindResponsive(`flex-1 w-full bg-Light1`)}>
      <View
        style={TailwindResponsive(
          `bg-Light1 flex-1 p-16 mobWeb:p-4 mobWeb:pb-56 mob:p-4`,
        )}
      >
        <>
          {Platform.OS === 'web' ? (
            <WorkDetailsHeader
              backScreenTitle={backScreenTitle}
              backScreen={backScreen}
              title={title}
            />
          ) : (
            <MainHeader title={title}>
              {!hideExitButton && (
                <View style={{ marginLeft: 'auto' }}>
                  <ExitPopUpButton onPress={() => navigation.goBack()} />
                </View>
              )}
            </MainHeader>
          )}

          {appReady ? props.children : <SpinLoader />}
        </>
      </View>
      {Platform.OS === 'web' && <Footer />}
    </SafeAreaView>
  );
};

export default DetailsPage;
