import { useMutation } from '@apollo/client';
import { ADD_USER_EXPO_TOKEN } from 'api/requests.v2';
import { AppLoader } from 'components/shared/appLoader';
import AppUpdate from 'components/shared/appUpdate/AppUpdate';
import { UserContext } from 'contexts';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { onAuthStateChanged, User } from 'firebase/auth';
import { useAppUpdates } from 'hooks/AppUpdateHook';
import { setPushNotifications } from 'hooks/PushNotificationHook';
import { useRedirect } from 'hooks/RedirectHook';
import { useScreenRedirect } from 'hooks/ScreenRedirectHook';
import { useDeviceType, useIntercom } from 'hooks/StartupHooks';
import { useSubscriptions } from 'hooks/SubscriptionHook';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import withFirebaseAuth, {
  WrappedComponentProps,
} from 'react-with-firebase-auth';
import { FCUser } from 'store/user/apollo';
import { customFonts } from 'style/fonts';
import { loadIntercom, useResponsiveSizes } from 'utilities';
import * as FBUtil from 'utilities/Firebaseutil';
import * as LogError from 'utilities/LogError';
import { RootNavigator } from './navigation/RootNavigator';

LogError.init();
FBUtil.initFB();

const firebaseAuth = FBUtil.getFBAuth();

export const App: FunctionComponent<WrappedComponentProps> = () => {
  const updateAvailable = useAppUpdates();
  useDeviceType();
  useIntercom();
  useRedirect();
  useResponsiveSizes();
  useScreenRedirect();
  useSubscriptions();

  const [addUserExpoToken] = useMutation(ADD_USER_EXPO_TOKEN);
  const [appIsready, setAppIsReady] = useState(false);
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);

  useEffect(() => {
    const onFirebaseAuthLoaded = new Promise((resolve) => {
      onAuthStateChanged(firebaseAuth, (newFirebaseUser) => {
        setFirebaseUser(newFirebaseUser);
        FCUser.FirebaseUserVar(newFirebaseUser);
        resolve(newFirebaseUser);
      });
    });

    const prepare = async () => {
      try {
        await SplashScreen.preventAutoHideAsync();
        await Font.loadAsync(customFonts);

        if (Platform.OS === 'web') {
          await loadIntercom();
        }
        await onFirebaseAuthLoaded;
      } catch (error) {
        LogError.logError(error, `Error in preparing app: ${error}`);
      } finally {
        setAppIsReady(true);
      }
    };

    prepare();
  }, []);

  useEffect(() => {
    if (firebaseUser) {
      setPushNotifications(firebaseUser, addUserExpoToken);
    }
  }, [firebaseUser, addUserExpoToken]);

  return (
    <UserContext.Provider value={firebaseUser}>
      <AppLoader appIsready={appIsready}>
        {updateAvailable === true ? <AppUpdate /> : <RootNavigator />}
      </AppLoader>
    </UserContext.Provider>
  );
};

export default withFirebaseAuth({ firebaseAppAuth: firebaseAuth })(App);
