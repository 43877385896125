import { makeVar } from '@apollo/client';

export const base64FileVar = makeVar<string>('');
export const base64FileNameVar = makeVar<string>('');
export const fileDataUriVar = makeVar<string>('');

export class FileUploaderApollo {
  public static Reset() {
    base64FileVar('');
    base64FileNameVar('');
    fileDataUriVar('');
  }
}
