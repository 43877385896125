import { useReactiveVar } from '@apollo/client';
import { ToggleButton } from 'components/shared/button';
import DefaultTextInput from 'components/shared/form/DefaultTextInput';
import { UnitInput } from 'components/shared/form/UnitInput';
import { GraphikSemiTextMd } from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';
import { RegisterStore } from 'store/registerWork/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { RegisterWorkFields } from '../Helpers';
import { useGetFormDetails } from '../forms/artist-registration.form';
import { APInformation } from './APInformation';

export const Details: FunctionComponent = () => {
  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const arForm = useGetFormDetails(detailsForm);
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <>
      <View style={TailwindResponsive('mt-12')}>
        <GraphikSemiTextMd style={TailwindResponsive(`mb-16`)}>
          Artwork
        </GraphikSemiTextMd>
        <View style={TailwindResponsive(`flex justify-between mb-12`)}>
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.title}
            render={({ field: { onChange, value, ref } }) => (
              <DefaultTextInput
                inputRef={ref}
                value={value}
                label="* Title"
                handleChange={(input) => {
                  arForm?.clearErrors('title');
                  onChange(input);
                }}
                errorMessage={
                  arForm?.formState?.errors?.title?.message as string
                }
                testId="ArtworkTitle"
              />
            )}
          />
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.yearProduced}
            render={({ field: { onChange, value, ref } }) => (
              <DefaultTextInput
                inputRef={ref}
                value={value}
                inputMode="numeric"
                label="* Year Produced"
                handleChange={(input) => {
                  arForm?.clearErrors('yearProduced');
                  onChange(input);
                }}
                errorMessage={
                  arForm?.formState?.errors?.yearProduced?.message as string
                }
                testId="YearProduced"
              />
            )}
          />
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.medium}
            render={({ field: { onChange, value, ref } }) => (
              <DefaultTextInput
                inputRef={ref}
                value={value}
                label="* Medium"
                handleChange={(input) => {
                  arForm?.clearErrors('medium');
                  onChange(input);
                }}
                errorMessage={
                  arForm?.formState?.errors?.medium?.message as string
                }
                testId="Medium"
              />
            )}
          />

          <View style={TailwindResponsive(`mb-10 mobWeb:w-full`)}>
            <Controller
              control={arForm?.control}
              name={RegisterWorkFields.inventoryNumber}
              render={({ field: { onChange, value } }) => (
                <DefaultTextInput
                  value={value}
                  label="Inventory Number (Optional)"
                  handleChange={(input) => {
                    onChange(input);
                  }}
                  testId="Inventory Number"
                />
              )}
            />
          </View>
        </View>

        <View
          style={TailwindResponsive(
            'web:flex-row mobWeb:flex-col mob:flex-col mb-12 mt-6',
          )}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 2,
            }}
          >
            <Controller
              control={arForm?.control}
              name={RegisterWorkFields.height}
              render={({ field: { onChange, value } }) => (
                <UnitInput
                  errorMessage={
                    arForm?.formState?.errors?.height?.message as string
                  }
                  handleChange={onChange}
                  label="Height"
                  testId="RegisterWorkHeight"
                  value={value}
                />
              )}
            />
            <Controller
              control={arForm?.control}
              name={RegisterWorkFields.width}
              render={({ field: { onChange, value } }) => (
                <UnitInput
                  errorMessage={
                    arForm?.formState?.errors?.width?.message as string
                  }
                  handleChange={onChange}
                  label="Width"
                  testId="RegisterWorkWidth"
                  value={value}
                />
              )}
            />
            <Controller
              control={arForm?.control}
              name={RegisterWorkFields.depth}
              render={({ field: { onChange, value } }) => (
                <UnitInput
                  errorMessage={
                    arForm?.formState?.errors?.depth?.message as string
                  }
                  handleChange={onChange}
                  label="Depth"
                  testId="RegisterWorkDepth"
                  value={value}
                />
              )}
            />
          </View>
          <View
            style={TailwindResponsive(
              'flex-1 web:items-end mobWeb:items-center',
            )}
          >
            <Controller
              control={arForm?.control}
              name={RegisterWorkFields.dimensionUnits}
              render={({ field: { onChange, value } }) => (
                <ToggleButton
                  value={value}
                  setValue={onChange}
                  testId="DimensionsToggle"
                  toggleOptionOne="in"
                  toggleOptionTwo="cm"
                />
              )}
            />
          </View>
        </View>
      </View>

      <APInformation />
    </>
  );
};

export default Details;
