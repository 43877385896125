import { GraphikTextSm } from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';

export const UserInformation: FunctionComponent = () => {
  const transaction = useGetTransactionDetailsHook();

  const user = transaction?.isBuyerView
    ? transaction?.seller
    : transaction?.buyer;

  if (!user) {
    return null;
  }

  return (
    <View>
      <GraphikTextSm>{user.email || ''}</GraphikTextSm>
      <GraphikTextSm>{user.phoneNumber || ''}</GraphikTextSm>
      {!!user.address && (
        <View style={{}}>
          <GraphikTextSm>
            {user.address.billingAddressLineOne}{' '}
            {user.address.billingAddressLineTwo}
          </GraphikTextSm>
          <GraphikTextSm>
            {user.address.billingAddressCity
              ? `${user.address.billingAddressCity},`
              : user.address.billingAddressCity}{' '}
            {user.address.billingAddressState}
          </GraphikTextSm>
          <GraphikTextSm>{user.address.billingAddressZipcode}</GraphikTextSm>
          <GraphikTextSm>{user.address.billingAddressCountry}</GraphikTextSm>
        </View>
      )}
    </View>
  );
};

export default UserInformation;
