import { useNavigation, useRoute } from '@react-navigation/native';
import { ExitPopUpButton, TextButton } from 'components/shared/button';
import { FairchainImage } from 'components/shared/image';
import { ImageOverlayRouteProp } from 'navigation';
import React, { FunctionComponent, useEffect } from 'react';
import { Platform, Pressable, View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useImagePicker } from './utils';

export const ImageOverlay: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const navigation = useNavigation();
  const route = useRoute<ImageOverlayRouteProp>();
  const { imageKey, page } = route.params;
  const { onDeleteImage } = useImagePicker(imageKey, page);

  useEffect(() => {
    const handleUp = (event) => {
      const { key: releasedKey } = event;
      if (releasedKey === 'Escape') {
        navigation.goBack();
      }
    };

    if (Platform.OS === 'web') {
      window.addEventListener('keyup', handleUp);
    }

    return () => {
      window.removeEventListener('keyup', handleUp);
    };
  }, [navigation]);

  return (
    <View style={TailwindResponsive(`flex flex-1 flex-row`)}>
      <Pressable
        style={TailwindResponsive(
          `flex-1 bg-black bg-opacity-50 mobWeb:flex-none mobWeb:w-1/3`,
        )}
        onPress={navigation.goBack}
      />
      <View
        style={{
          backgroundColor: Styles.Colours.Light1,
          ...TailwindResponsive(
            'flex flex-1 flex-row justify-between p-14 mobWeb:flex-none mobWeb:w-2/3 mobWeb:flex-col-reverse mobWeb:justify-end mobWeb:p-2',
          ),
        }}
      >
        <View
          style={TailwindResponsive(
            `flex flex-col w-72 mobWeb:mt-8 mobWeb:w-full`,
          )}
        >
          <View style={TailwindResponsive(`w-72 mobWeb:w-full`)}>
            <FairchainImage uri={route.params.imageURI} />
          </View>
          <View
            style={TailwindResponsive(
              `flex flex-row flex-1 py-5 mobWeb:flex-col`,
            )}
          >
            <View style={TailwindResponsive(`flex flex-1`)}>
              <TextButton
                label="Delete"
                onPress={onDeleteImage}
                testID="DeleteImage_Button"
              />
            </View>
          </View>
        </View>
        <View>
          <ExitPopUpButton onPress={() => navigation.goBack()} />
        </View>
      </View>
    </View>
  );
};
