/* Copyright (C) 2021 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */

import React, { FunctionComponent } from 'react';
import Svg, { G, Line } from 'react-native-svg';
import { ISVGProps } from './Types';

export const ListOutline: FunctionComponent<ISVGProps> = () => (
  <Svg width="16" height="13" viewBox="0 0 16 13">
    <G id="Group_170" data-name="Group 170" transform="translate(-215 -441.5)">
      <Line
        id="Line_1"
        data-name="Line 1"
        x1="16"
        transform="translate(215 442)"
        fill="none"
        stroke="rgba(0,0,0,0.5)"
        strokeWidth="1"
      />
      <Line
        id="Line_1-2"
        data-name="Line 1"
        x1="16"
        transform="translate(215 450)"
        fill="none"
        stroke="rgba(0,0,0,0.5)"
        strokeWidth="1"
      />
      <Line
        id="Line_2"
        data-name="Line 2"
        x1="16"
        transform="translate(215 446)"
        fill="none"
        stroke="rgba(0,0,0,0.5)"
        strokeWidth="1"
      />
      <Line
        id="Line_1-3"
        data-name="Line 1"
        x1="16"
        transform="translate(215 454)"
        fill="none"
        stroke="rgba(0,0,0,0.5)"
        strokeWidth="1"
      />
    </G>
  </Svg>
);

export default ListOutline;
