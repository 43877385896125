import * as yup from 'yup';

export interface ICreateUserForm {
  artistName: string;
  brandName: string;
  estateArtistEmail: string;
  email: string;
  firstName: string;
  galleryName: string;
  lastName: string;
  mainUserEmail: string;
  phoneNumber: string;
  role: string;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  trustName: string;
}

export interface ILoginAsUserForm {
  email: string;
}

export const createUserValidationSchema = yup
  .object()
  .shape({
    firstName: yup.string().required('This is a required field'),
    lastName: yup.string().required('This is a required field'),
    estateArtistEmail: yup.string().email('Please enter a valid email'),
    mainUserEmail: yup.string().email('Please enter a valid email'),
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('This is a required field'),
    addressLineOne: yup.string().required('This is a required field'),
    city: yup.string().required('This is a required field'),
    state: yup.string().required('This is a required field'),
    zip: yup.string().required('This is a required field'),
    country: yup.string().required('This is a required field'),
    brandName: yup.string().when('role', {
      is: 'brand',
      then: yup.string().required('This is a required field'),
    }),
  })
  .required();
