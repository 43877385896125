import { useReactiveVar } from '@apollo/client';
import { DefaultTextInput } from 'components/shared/form';
import { ImagePicker } from 'components/shared/imagepicker';
import ImageDisplayModal from 'components/shared/imagepicker/components/ImageDisplayModal';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import { GraphikTextSm, GraphikTextTiny } from 'components/shared/styled';
import { AssetType, ImageSelectionPage } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { Text, View } from 'react-native';
import { RegisterStore } from 'store/registerWork/apollo';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { RegisterWorkFields } from '../Helpers';
import { SupportingDocs } from '../components/SupportingDocs';
import { useGetFormDetails } from '../forms/artist-registration.form';

export const ImageSelection: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const arForm = useGetFormDetails(detailsForm);
  const hasImageErrors = useReactiveVar(RegisterStore.Vars.Images.errors);
  const image1 = useReactiveVar(RegisterStore.Vars.Images.image1);
  const image2 = useReactiveVar(RegisterStore.Vars.Images.image2);
  const image3 = useReactiveVar(RegisterStore.Vars.Images.image3);
  const image4 = useReactiveVar(RegisterStore.Vars.Images.image4);
  const primary = useReactiveVar(RegisterStore.Vars.Images.primary);

  const assetType = detailsForm?.assetType;

  const instructionText =
    assetType === AssetType.Collectible
      ? 'Please upload at least one image for this item. We recommend four images: front, back, and two detail photos. Click an image to delete or mark as primary image.'
      : 'Please upload at least one image for this work. We recommend four images: front, back, signature detail, and work detail. Click an image to delete or mark as primary image.';

  const pdfURL =
    process.env.ENVIRONMENT === 'PRODUCTION'
      ? 'https://firebasestorage.googleapis.com/v0/b/abl-site-66caa.appspot.com/o/FC-Photo-Guide.pdf?alt=media&token=90e4356c-6214-4d73-951d-6f096443d3f3'
      : 'https://firebasestorage.googleapis.com/v0/b/platform-staging-c5054.appspot.com/o/FC-Photo-Guide.pdf?alt=media&token=30172e8e-2112-4ef8-b7db-1140c7aaf752';

  const setPrimary = (key: string) => {
    if (!primary) {
      RegisterStore.Vars.Images.primary(key);
    }
  };

  const instructionSection = () => {
    if (assetType === AssetType.Collectible) {
      return <GraphikTextSm>{instructionText}</GraphikTextSm>;
    }

    return (
      <GraphikTextSm>
        {instructionText}{' '}
        <ExternalLink link={pdfURL}>
          <GraphikTextSm style={TailwindResponsive(`underline`)}>
            View Image Guidelines
          </GraphikTextSm>
        </ExternalLink>
      </GraphikTextSm>
    );
  };

  return (
    <View style={TailwindResponsive('flex-1 items-start')}>
      <View
        style={TailwindResponsive('web:w-4/5 mb-8')}
        testID="ImageUploadInfoWrapper"
      >
        {instructionSection()}
      </View>

      <View
        style={TailwindResponsive(
          'flex-row flex-wrap content-between mb-12 web:p-4 mob:-mt-2',
        )}
      >
        <View>
          <ImagePicker
            image={image1?.remoteURI || image1?.localURI}
            imageKey="image1"
            imageSelectionHandler={async (image) => {
              RegisterStore.Vars.Images.image1(image);
              setPrimary('image1');
            }}
            page={ImageSelectionPage.RegisterWork}
          />
          <GraphikTextTiny style={TailwindResponsive(`mt-2`)}>
            Image 1 (Required)
          </GraphikTextTiny>
        </View>
        <View>
          <ImagePicker
            image={image2?.remoteURI || image2?.localURI}
            imageKey="image2"
            imageSelectionHandler={(image) => {
              setPrimary('image2');
              RegisterStore.Vars.Images.image2(image);
            }}
            page={ImageSelectionPage.RegisterWork}
          />
          <GraphikTextTiny style={TailwindResponsive(`mt-2`)}>
            Image 2
          </GraphikTextTiny>
        </View>
        <View>
          <ImagePicker
            image={image3?.remoteURI || image3?.localURI}
            imageKey="image3"
            imageSelectionHandler={(image) => {
              RegisterStore.Vars.Images.image3(image);
              setPrimary('image3');
            }}
            page={ImageSelectionPage.RegisterWork}
          />
          <GraphikTextTiny style={TailwindResponsive(`mt-2`)}>
            Image 3
          </GraphikTextTiny>
        </View>
        <View>
          <ImagePicker
            image={image4?.remoteURI || image4?.localURI}
            imageKey="image4"
            imageSelectionHandler={(image) => {
              RegisterStore.Vars.Images.image4(image);
              setPrimary('image4');
            }}
            page={ImageSelectionPage.RegisterWork}
          />
          <GraphikTextTiny style={TailwindResponsive(`mt-2`)}>
            Image 4
          </GraphikTextTiny>
        </View>
        <ImageDisplayModal />
      </View>

      {hasImageErrors && (
        <View>
          <Text
            style={{
              ...TailwindResponsive('ml-2 -mt-10'),
              color: Styles.Colours.Error,
            }}
          >
            You must upload at least one image
          </Text>
        </View>
      )}

      <GraphikTextSm style={TailwindResponsive(`mt-4 -mb-4`)}>
        You can attach a YouTube or Vimeo video to a certificate. Enter the URL
        below.
      </GraphikTextSm>
      <View style={TailwindResponsive(`w-1/2 mb-8 mobWeb:w-full mob:w-full`)}>
        <Controller
          control={arForm?.control}
          name={RegisterWorkFields.videoUrl}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              errorMessage={
                arForm?.formState?.errors?.videoUrl?.message as string
              }
              handleChange={(input) => {
                arForm?.clearErrors(RegisterWorkFields.videoUrl);
                onChange(input);
              }}
              label="Video URL"
              testId="VideoUrlInput"
              value={value}
            />
          )}
        />
      </View>

      <SupportingDocs />
    </View>
  );
};
