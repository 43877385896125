import React, { FunctionComponent, useCallback } from 'react';
import { Pressable, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { GraphikTextMd } from '../styled';

interface ButtonProps {
  value: boolean;
  setValue: any;
  testId?: string;
  toggleOptionOne: string;
  toggleOptionTwo: string;
}

export const ToggleButton: FunctionComponent<ButtonProps> = ({
  value,
  setValue,
  testId,
  toggleOptionOne,
  toggleOptionTwo,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const checkSetValue = useCallback(
    (newValue) => {
      if (newValue !== value) setValue(newValue);
    },
    [setValue, value],
  );

  return (
    <View
      style={TailwindResponsive(
        'flex-row mt-2.5 web:w-32 mob:w-full justify-around items-center',
      )}
    >
      <View
        style={
          !value
            ? TailwindResponsive(
                'web:h-16 mob:h-7 web:w-16 mob:w-44 justify-center items-center bg-Light1 border-Dark1 mob:border mob:border-opacity-20',
              )
            : TailwindResponsive(
                'web:h-16 mob:h-7 web:w-16 mob:w-44 justify-center items-center bg-Dark1 border-Light1 border',
              )
        }
      >
        <Pressable
          style={TailwindResponsive(
            'justify-center items-center web:h-pblg web:w-pblg web:border web:border-Light1 mob:w-full',
          )}
          onPress={() => checkSetValue(true)}
          testID={`${testId}_1`}
        >
          <GraphikTextMd
            style={
              value
                ? TailwindResponsive('text-Light1')
                : TailwindResponsive('web:text-Dark1 mob:text-Gray1')
            }
          >
            {toggleOptionOne}
          </GraphikTextMd>
        </Pressable>
      </View>
      <View
        style={
          value
            ? TailwindResponsive(
                'web:h-16 mob:h-7 web:w-16 mob:w-44 justify-center items-center bg-Light1 border-Dark1 mob:border mob:border-opacity-20',
              )
            : TailwindResponsive(
                'web:h-16 mob:h-7 web:w-16 mob:w-44 justify-center items-center bg-Dark1 border-Light1 border',
              )
        }
      >
        <Pressable
          style={TailwindResponsive(
            'justify-center items-center web:h-pblg web:w-pblg web:border web:border-Light1 mob:w-full',
          )}
          onPress={() => checkSetValue(false)}
          testID={`${testId}_2`}
        >
          <GraphikTextMd
            style={
              !value
                ? TailwindResponsive('text-Light1')
                : TailwindResponsive('web:text-Dark1 mob:text-Gray1')
            }
          >
            {toggleOptionTwo}
          </GraphikTextMd>
        </Pressable>
      </View>
    </View>
  );
};

export default ToggleButton;
