import { useNavigation, useRoute } from '@react-navigation/native';
import { SpinLoader } from 'components/shared/loader';
import { FCStackNavProp, StripePaymentReturnRouteProp } from 'navigation/types';
import React, { FunctionComponent, useEffect } from 'react';
import { View } from 'react-native';

export const StripePaymentReturn: FunctionComponent = () => {
  const navigation = useNavigation<FCStackNavProp>();
  const route = useRoute<StripePaymentReturnRouteProp>();

  useEffect(() => {
    if (route && navigation) {
      const { transactionId } = route.params;
      navigation.push('TransactionDetail', { transactionId });
    }
  }, [navigation, route]);

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SpinLoader />
    </View>
  );
};

export default StripePaymentReturn;
