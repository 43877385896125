import { makeVar } from '@apollo/client';
import { Work } from 'types';
import { Transaction } from 'types/Transactions';

const certifiedWorksVar = makeVar<Work[]>([]);
const transactionsVar = makeVar<Transaction[]>([]);

export const InboxGQL = {
  certifiedWorks: certifiedWorksVar,
  transactions: transactionsVar,
};
