/* This Header contains just the Fairchain Logo on the top left of the screen */

import { useNavigation } from '@react-navigation/native';
import { FairchainLogo } from 'components/graphics';
import { UserContext } from 'contexts';
import React, { FunctionComponent, useContext } from 'react';
import { Pressable, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IWordmarkHeaderProps {
  color?: string;
}

export const WordmarkHeader: FunctionComponent<IWordmarkHeaderProps> = ({
  color,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const authUser = useContext(UserContext);
  const navigation = useNavigation();

  return (
    <View style={TailwindResponsive('flex flex-row m-14 justify-between')}>
      <Pressable
        onPress={() => {
          if (authUser && authUser.uid) navigation.navigate('Collection');
          else navigation.navigate('Login', { redirectURL: null });
        }}
        style={TailwindResponsive('flex flex-row items-start justify-center')}
        testID="fairchain-logo-link"
      >
        <FairchainLogo color={color} />
      </Pressable>
    </View>
  );
};
