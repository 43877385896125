import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { WordmarkHeader } from 'components/shared/header';
import {
  GraphikMediumTextLg,
  GraphikMediumTextMd,
  GraphikTextMd,
} from 'components/shared/styled';
import { CreateUserType } from 'components/views/admin';
import { FunctionComponent } from 'react';
import { Linking, Pressable, View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { SignupStackNavProp } from '../SignUp';
import { signUpFormvar } from '../form';

export const SelectionInfo: FunctionComponent = () => {
  const navigation = useNavigation<SignupStackNavProp>();
  const { TailwindResponsive } = useTailwindResponsive();

  const form = useReactiveVar(signUpFormvar);

  return (
    <>
      <View style={TailwindResponsive('mt-10 mobWeb:mt-4 mob:-mt-40')}>
        <WordmarkHeader color={Styles.Colours.Light1} />
      </View>

      <View
        style={TailwindResponsive(
          `bg-Dark1 flex-1 pt-12 pb-28 mx-14 mob:pt-0 mob:px-4 mob:-mt-4`,
        )}
      >
        <GraphikMediumTextLg
          color={Styles.Colours.Light1}
          style={TailwindResponsive(`mb-20`)}
        >
          Account Sign Up
        </GraphikMediumTextLg>

        <GraphikMediumTextMd
          color={Styles.Colours.Light1}
          style={TailwindResponsive(`mt-4`)}
        >
          {/* Fairchain accounts are tailored for each user type. Which role best
          describes you?  // hidden for sign up depracation */}
          No new accounts are being created at this time. Please contact
          support@fairchain.art for assistance.
        </GraphikMediumTextMd>

        <View
          style={TailwindResponsive(
            `flex-row flex-wrap justify-start mt-20 mb-20 lg:flex-nowrap lg:justify-start hidden`,
          )}
        >
          <Pressable
            onPress={() => {
              form?.setValue('role', CreateUserType.Artist);
              navigation.navigate('Artist');
            }}
            style={TailwindResponsive(
              `bg-Primary items-center p-6 rounded-full w-full max-w-lg lg:w-72`,
            )}
          >
            <GraphikMediumTextMd
              color={Styles.Colours.Light1}
              // TODO: Add effect on hover
            >
              Artist
            </GraphikMediumTextMd>
          </Pressable>

          <Pressable
            onPress={() => {
              form?.setValue('role', CreateUserType.Collector);
              navigation.navigate('Collector');
            }}
            style={TailwindResponsive(
              `bg-Primary items-center my-6 p-6 rounded-full w-full max-w-lg lg:my-0 lg:mx-6 lg:w-72`,
            )}
          >
            <GraphikMediumTextMd
              color={Styles.Colours.Light1}
              // TODO: Add effect on hover
            >
              Collector
            </GraphikMediumTextMd>
          </Pressable>

          <Pressable
            onPress={() => {
              Linking.openURL('https://fairchain.art/request/');
            }}
            style={TailwindResponsive(
              `bg-Primary items-center p-6 rounded-full w-full max-w-lg lg:w-72`,
            )}
          >
            <GraphikMediumTextMd
              color={Styles.Colours.Light1}
              // TODO: Add effect on hover
            >
              Gallery / Other
            </GraphikMediumTextMd>
          </Pressable>
        </View>

        <View style={TailwindResponsive(`hidden`)}>
          <GraphikTextMd color={Styles.Colours.Light1}>
            Questions? Contact us at
            <Pressable
              onPress={() => {
                Linking.openURL('mailto: support@fairchain.art');
              }}
              style={TailwindResponsive(`ml-1 underline`)}
            >
              <GraphikTextMd
                color={Styles.Colours.Light1}
                style={TailwindResponsive(`mt-4`)}
              >
                support@fairchain.art
              </GraphikTextMd>
            </Pressable>
            .
          </GraphikTextMd>
        </View>
      </View>
    </>
  );
};

export default SelectionInfo;
