import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import { ISVGProps } from './Types';

export const DocumentIcon: FunctionComponent<ISVGProps> = ({
  color,
  width,
}) => {
  const iconWidth = width || 48;

  return (
    <Svg
      fill={color}
      height={iconWidth}
      viewBox="0 96 960 960"
      width={iconWidth}
    >
      <Path d="M319 806h322v-60H319v60Zm0-170h322v-60H319v60Zm-99 340q-24 0-42-18t-18-42V236q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-554V236H220v680h520V422H551ZM220 236v186-186 680-680Z" />
    </Svg>
  );
};

export default DocumentIcon;
