import { useMutation } from '@apollo/client';
import { RUN_MIGRATIONS } from 'api/requests.v2';
import { UserContext } from 'contexts';
import React, { FunctionComponent, useContext } from 'react';
import { Pressable, Text, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const Migrations: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const { TailwindResponsive } = useTailwindResponsive();
  const [runMigrations] = useMutation(RUN_MIGRATIONS);

  const handleRunMigrations = async () => {
    await runMigrations({
      variables: { userId: authUser?.uid },
    });
  };

  return (
    /* The below is for accruing mob/web specific tailwind tags for compiling */
    <View
      style={TailwindResponsive(
        `w-16 w-80 w-2/4 w-3/4 w-4/5 w-1/5 mb-48 h-7 text-Gray1 w-1/3 h-44 mr-0 w-1/4 w-1/2 h-12 h-10 -mt-2 -mt-12 -mt-40 ml-6 self-end justify-normal -mt-44`,
      )}
    >
      <Text>Migrations</Text>
      {authUser !== null && (
        <View style={{ width: 202 }}>
          <Pressable onPress={() => handleRunMigrations()}>
            Run Migrations
          </Pressable>
        </View>
      )}
    </View>
  );
};
