import CertifiedWorkBanner from 'components/shared/banners/CertifiedWorkBanner';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import { GraphikMediumTextMd, GraphikTextSm } from 'components/shared/styled';
import { UserRights } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { Platform, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';
import { getUser } from 'store/user';
import Styles from 'style';
import { Status, WorkWithObject, getDisplayName } from 'types';
import { isCollectible } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import ArtworkValue from './ArtworkValue';
import ArtworkView from './ArtworkView';
import CancelTransaction from './CancelTransaction';
import CompleteAgreementInvoice from './CompleteAgreementInvoice';
import OfferAcceptance from './OfferAcceptance';
import Payment from './Payment';
import TransactionProgress from './TransactionProgress';
import TransactionValue from './TransactionValue';
import { TransferTitle } from './TransferTitle';
import ViewContract from './ViewContract';
import ViewInvoice from './ViewInvoice';

interface ITransactionItemProps {
  discount: number;
  index: number;
  price: number;
  work: WorkWithObject;
}

export const TransactionItem: FunctionComponent<ITransactionItemProps> = ({
  discount,
  index,
  price,
  work,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const transaction = useGetTransactionDetailsHook();

  const userInfo = useSelector(getUser);

  const sellerName = getDisplayName(transaction?.seller);
  const assetIsCollectible = isCollectible(work);

  const canAccessDetails =
    !!userInfo &&
    (!userInfo.isSubUser || userInfo.rights?.includes(UserRights.Collection));

  if (!transaction) return null;

  return (
    <View style={TailwindResponsive(`flex mobWeb:flex-col`)}>
      <ArtworkView
        index={index}
        work={work}
        transactionDate={transaction.createDate}
      />

      {!transaction.isBuyerView &&
        transaction.status !== Status.completed &&
        canAccessDetails && (
          <View style={TailwindResponsive(`mt-4`)}>
            <ExternalLink link={`/details/${work.id}`}>
              <GraphikTextSm style={{ color: Styles.Colours.Primary }}>
                {`View ${assetIsCollectible ? 'Item' : 'Artwork'} Details`}
              </GraphikTextSm>
            </ExternalLink>
          </View>
        )}
      <ArtworkValue
        discount={discount}
        price={price}
        transaction={transaction}
      />

      {transaction.isBuyerView && transaction.optionalMessage !== '' && (
        <View style={TailwindResponsive(`flex flex-col mt-20 max-w-lg`)}>
          <GraphikMediumTextMd>Message from Seller</GraphikMediumTextMd>
          <GraphikTextSm>{transaction.optionalMessage}</GraphikTextSm>
        </View>
      )}

      {transaction.items[0]?.work.certified && (
        <View>
          <CertifiedWorkBanner assetIsCollectible={assetIsCollectible} />
        </View>
      )}

      {!transaction.isMultiWork && (
        <>
          {!transaction.isRetro && (
            <View style={TailwindResponsive(`mt-10`)}>
              <TransactionValue assetIsCollectible={assetIsCollectible} />
            </View>
          )}

          {!transaction.isBuyerView && (
            <View style={TailwindResponsive(`mt-10`)}>
              <TransactionProgress />
            </View>
          )}

          {Platform.OS !== 'web' && (
            <>
              <ViewContract />
              <ViewInvoice />
            </>
          )}

          {!transaction.isBuyerView && (
            <CancelTransaction
              label={
                transaction?.isRetro ? 'Cancel Certificate' : 'Cancel Sale'
              }
            />
          )}
          {!transaction.isBuyerView && (
            <View style={TailwindResponsive(`mt-10`)}>
              <TransferTitle />
            </View>
          )}

          <OfferAcceptance />

          {(transaction.status === Status.signed ||
            transaction.status === Status.buyerSigned) &&
            transaction.isBuyerView &&
            !transaction.invoiceFile && (
              <GraphikMediumTextMd style={TailwindResponsive('mt-10')}>
                Transfer Pending: Once {sellerName} has indicated that this
                transaction is complete, the title(s) will be transferred.
              </GraphikMediumTextMd>
            )}

          <CompleteAgreementInvoice />

          {transaction.status === Status.completed &&
            transaction.isBuyerView && (
              <GraphikMediumTextMd style={TailwindResponsive('mt-10')}>
                This transaction is complete and the certificate has been issued
                to your collection.
              </GraphikMediumTextMd>
            )}

          {Platform.OS === 'web' && (
            <View style={TailwindResponsive(`mt-10`)}>
              <Payment />
            </View>
          )}
        </>
      )}
    </View>
  );
};

export default TransactionItem;
