import { GraphikTextMd } from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useExpirationHeader } from '../hooks';
import CountdownTimer from './CountdownTimer';

export const ExpirationHeader: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const transaction = useGetTransactionDetailsHook();
  const expirationHeader = useExpirationHeader(transaction?.expirationTime);

  if (!transaction?.createDate) return null;

  if (transaction.canSeeExpiration) {
    return (
      <View style={TailwindResponsive(`mb-8 flex flex-col bg-Light5 p-4`)}>
        <GraphikTextMd>Sales Agreement Expiration</GraphikTextMd>
        <View
          style={TailwindResponsive(
            `flex flex-row justify-between mt-4 mobWeb:flex-col`,
          )}
        >
          <CountdownTimer endDate={transaction?.expirationTime} />
          <GraphikTextMd style={TailwindResponsive(`flex items-end`)}>
            {expirationHeader}
          </GraphikTextMd>
        </View>
      </View>
    );
  }

  return null;
};

export default ExpirationHeader;
