import { Checkbox } from 'components/shared/checkbox';
import {
  DiminishingRoyalty,
  DiminishingRoyaltyDisplay,
} from 'components/shared/diminishingRoyalties/DiminishingRoyalty';
import { RoyaltyInput } from 'components/shared/form';
import { OptionPicker } from 'components/shared/optionPicker';
import {
  GraphikMediumTextMd,
  GraphikSemiTextMd,
  GraphikSemiTextSm,
  GraphikTextSm,
  GraphikTextTiny,
} from 'components/shared/styled';
import { DIMINISHED_OPTIONS } from 'constants/constants';
import { RoyaltyType } from 'constants/enums';
import currency from 'currency.js';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  Control,
  FieldErrorsImpl,
  UseFormClearErrors,
  useController,
} from 'react-hook-form';
import { Platform, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearRoyalties,
  getDiminishedRoyalties,
  setDiminishedRoyaltyDate,
} from 'store/forms';
import { getIsRetro } from 'store/transferWork';
import Styles from 'style';
import { DetailsLineItemTitleStyle, Work } from 'types';
import { isRetroTransaction } from 'utilities';
import { formatRoyaltyCopy } from 'utilities/Royalties';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import {
  ITransactionDetailsForm,
  calculateTotalRoyalty,
  formatRoyaltyInput,
} from '../Helpers';
import DetailsLineItem from './DetailsLineItem';

interface IRoyaltyEntryProps {
  assetIsCollectible: boolean;
  clearErrors: UseFormClearErrors<ITransactionDetailsForm>;
  control: Control<ITransactionDetailsForm>;
  errors: FieldErrorsImpl;
  index: number;
  isGallery: boolean;
  trigger: (string) => void;
  work: Work;
}

type RoyaltyDisplay = {
  label: string;
  value: string;
};

const defaultDiminishDate = DIMINISHED_OPTIONS[0]!.label;

export const RoyaltyEntry: FunctionComponent<IRoyaltyEntryProps> = ({
  assetIsCollectible,
  clearErrors,
  control,
  errors,
  index,
  isGallery,
  trigger,
  work,
}) => {
  const dispatch = useDispatch();
  const { TailwindResponsive } = useTailwindResponsive();

  const diminishedRoyalties = useSelector(getDiminishedRoyalties);

  const [isInitialized, setIsInitialized] = useState(false);
  const [diminishedDate, setDiminishedDate] = useState(defaultDiminishDate);
  const [doesDiminish, setDoesDiminish] = useState(false);
  const transIsRetro = useSelector(getIsRetro);

  const titleStyle = doesDiminish
    ? DetailsLineItemTitleStyle.regular
    : DetailsLineItemTitleStyle.bold;

  const upperStyle = !isRetroTransaction(work) ? `border-t border-Dark1` : '';

  const collectibleRoyaltyHeaderText =
    'View the resale commission rates the brand and contributors will receive on subsequent sales.';
  const royaltyHeaderText = `Enter the resale commission rate${
    isGallery ? 's' : ''
  } the artist ${
    isGallery ? 'and gallery ' : ''
  }will receive on subsequent sales. We suggest resale commission rates of ${
    transIsRetro ? '2-5%' : '5-8%'
  }.`;

  const {
    field: { onChange: setArtistRoyalty, value: artistRoyalty },
  } = useController({ name: `items.${index}.artistRoyalty`, control });
  const {
    field: { onChange: setGalleryRoyalty, value: galleryRoyalty },
  } = useController({ name: `items.${index}.galleryRoyalty`, control });
  const {
    field: { onChange: setTotalRoyalty, value: totalRoyalty },
  } = useController({ name: `items.${index}.totalRoyalty`, control });

  useEffect(() => {
    if (isInitialized === false) {
      const theRoyalty = diminishedRoyalties.find(
        (royalty) => royalty?.workId === work.id,
      );
      if (theRoyalty) {
        setIsInitialized(true);
        setDiminishedDate(theRoyalty.diminishedDate || defaultDiminishDate);
        setDoesDiminish(theRoyalty.doesDiminish);
      }
    }
  }, [diminishedRoyalties, isInitialized, doesDiminish, work.id]);

  useEffect(() => {
    if (diminishedDate && doesDiminish) {
      dispatch(
        setDiminishedRoyaltyDate({
          diminishedDate,
          workId: work.id,
        }),
      );
    }
  }, [diminishedDate, dispatch, doesDiminish, work.id]);

  useEffect(() => {
    const newTotalRoyalty = calculateTotalRoyalty(
      artistRoyalty,
      galleryRoyalty,
    );
    if (newTotalRoyalty !== totalRoyalty) {
      setTotalRoyalty(newTotalRoyalty);
      trigger(`items.${index}.totalRoyalty`);
    }
  }, [
    artistRoyalty,
    galleryRoyalty,
    index,
    setTotalRoyalty,
    totalRoyalty,
    trigger,
  ]);

  const totalDiminishedRoyalties = useMemo(() => {
    const royalty = diminishedRoyalties.find((x) => x.workId === work.id);
    return royalty
      ? currency(0)
          .add(royalty.diminishedArtistValue)
          .add(royalty.diminishedGalleryValue).value
      : '';
  }, [diminishedRoyalties, work.id]);

  const artistResaleText = !isGallery
    ? `Enter the resale commission rate the artist will receive on subsequent sales. We suggest resale commission rates of ${
        transIsRetro ? '2-5%' : '5-8%'
      }.`
    : undefined;

  const getContributorName = (userId: string): string => {
    const contributor = work.collectible?.contributors?.find(
      (user) => user.id === userId,
    );
    if (contributor) {
      return `${contributor.firstName} ${contributor.lastName}`;
    }
    return 'Contributor';
  };

  const brandAndContributorRoyalties: RoyaltyDisplay[] = assetIsCollectible
    ? work.royalties.map((royalty) => {
        let label = '';
        if (royalty.type === RoyaltyType.brand) {
          label = 'Brand Resale Commission';
        } else if (royalty.type === RoyaltyType.contributor) {
          label = `${getContributorName(royalty.user)} Resale Commission`;
        }

        return {
          label,
          value: royalty.value,
        };
      })
    : [];

  return (
    <View
      style={{
        ...TailwindResponsive(`${upperStyle} pt-6 lg:pt-12`),
        zIndex: -3, // to allow option picker to overlay
      }}
    >
      <View style={TailwindResponsive(`flex pr-4 mb-10`)}>
        <GraphikSemiTextSm style={TailwindResponsive(`mb-2`)}>
          Resale Commissions
        </GraphikSemiTextSm>
        <GraphikTextTiny>
          {(!assetIsCollectible && royaltyHeaderText) ||
            collectibleRoyaltyHeaderText}
        </GraphikTextTiny>
      </View>
      {!transIsRetro && !assetIsCollectible && (
        <>
          <Checkbox
            isChecked={!doesDiminish}
            onPress={() => {
              setDoesDiminish(false);
              setDiminishedDate(defaultDiminishDate);
              dispatch(clearRoyalties());
            }}
            label="Fixed Resale Commissions"
          />
          <View style={TailwindResponsive(`mb-12 mt-4`)}>
            <Checkbox
              isChecked={doesDiminish}
              onPress={() => {
                setDoesDiminish(true);
              }}
              label="Diminishing Resale Commissions"
            />
            <View style={TailwindResponsive('ml-10')}>
              <GraphikTextTiny style={TailwindResponsive('w-full max-w-lg')}>
                Allows you to set higher resale commissions for a fixed period
                with subsequent reduction. This can be used to protect against
                short-term speculation. (Beta)
              </GraphikTextTiny>
            </View>
          </View>
        </>
      )}

      {doesDiminish && (
        <View
          style={TailwindResponsive(
            `flex flex-row items-center mb-12 mob:flex-1`,
          )}
        >
          <GraphikSemiTextSm>For the first</GraphikSemiTextSm>
          <View style={TailwindResponsive(`mx-2 w-24`)}>
            <OptionPicker
              options={DIMINISHED_OPTIONS}
              defaultValue={1}
              value={diminishedDate}
              onChange={setDiminishedDate}
              showIcon
            />
          </View>
          <GraphikSemiTextSm>years:</GraphikSemiTextSm>
        </View>
      )}

      <View style={{ zIndex: -1 }}>
        {!assetIsCollectible ? (
          <>
            <DetailsLineItem
              text={artistResaleText}
              title="Artist Resale Commission"
              titleStyle={titleStyle}
            >
              <RoyaltyInput
                errorMessage={
                  errors?.items
                    ? errors?.items[index]?.artistRoyalty?.message
                    : ''
                }
                handleChange={(input) => {
                  const parsedInput = formatRoyaltyInput(input);
                  setArtistRoyalty(parsedInput);
                  clearErrors(`items.${index}.artistRoyalty`);
                }}
                style={TailwindResponsive(`w-full`)}
                value={artistRoyalty}
              />
              <GraphikTextTiny color={Styles.Colours.Gray2}>
                * Artist Resale Commission Rate
              </GraphikTextTiny>
            </DetailsLineItem>

            {isGallery && (
              <>
                <DetailsLineItem
                  title="Gallery Resale Commission"
                  titleStyle={titleStyle}
                >
                  <RoyaltyInput
                    errorMessage={
                      errors?.items
                        ? errors?.items[index]?.galleryRoyalty?.message
                        : ''
                    }
                    handleChange={(input) => {
                      const parsedInput = formatRoyaltyInput(input);
                      setGalleryRoyalty(parsedInput);
                      clearErrors(`items.${index}.galleryRoyalty`);
                    }}
                    style={TailwindResponsive(`w-full`)}
                    value={galleryRoyalty}
                  />
                  <GraphikTextTiny color={Styles.Colours.Gray2}>
                    * Gallery Resale Commission Rate
                  </GraphikTextTiny>
                </DetailsLineItem>

                {doesDiminish && Platform.OS !== 'web' && (
                  <View style={TailwindResponsive(`mb-10`)}>
                    <View
                      style={TailwindResponsive(
                        'flex flex-row items-center justify-center mt-10',
                      )}
                    >
                      <View style={TailwindResponsive('mob:w-1/2')}>
                        <GraphikSemiTextMd>
                          Total Resale Commission Rate
                        </GraphikSemiTextMd>
                      </View>
                      <View
                        style={TailwindResponsive(
                          `flex h-14 mb-2 items-center justify-center mob:flex-row mob:w-1/2`,
                        )}
                      >
                        <GraphikMediumTextMd>{`${totalRoyalty}%`}</GraphikMediumTextMd>
                      </View>
                    </View>

                    <GraphikTextTiny
                      style={TailwindResponsive(`text-center text-Error`)}
                    >
                      {errors?.items
                        ? errors?.items[index]?.totalRoyalty?.message
                        : ''}
                    </GraphikTextTiny>
                  </View>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {brandAndContributorRoyalties.map((royalty) => (
              <DetailsLineItem title={royalty.label} titleStyle={titleStyle}>
                <RoyaltyInput
                  editable={false}
                  style={TailwindResponsive(`w-full`)}
                  value={royalty.value}
                />
                <GraphikTextTiny color={Styles.Colours.Gray2}>
                  {`${royalty.label} Rate`}
                </GraphikTextTiny>
              </DetailsLineItem>
            ))}
          </>
        )}
      </View>

      {doesDiminish && (
        <View style={TailwindResponsive(`flex-1 mb-12`)}>
          <DiminishingRoyalty
            isGallery={isGallery}
            style={DiminishingRoyaltyDisplay.SalesOffer}
            workId={work.id}
          />
        </View>
      )}

      {isGallery && !doesDiminish && (
        <View style={TailwindResponsive('mb-14')}>
          <GraphikSemiTextSm>{`Total Resale Commission: ${totalRoyalty}%`}</GraphikSemiTextSm>
          <GraphikTextTiny style={{ color: Styles.Colours.Error }}>
            {errors?.items ? errors?.items[index]?.totalRoyalty?.message : ''}
          </GraphikTextTiny>
        </View>
      )}

      {isGallery && doesDiminish && (
        <View style={TailwindResponsive('flex flex-row flex-wrap mb-14')}>
          <GraphikSemiTextSm>{`Total Resale Commission: ${totalRoyalty}% `}</GraphikSemiTextSm>
          <GraphikTextSm>for the first </GraphikTextSm>
          <GraphikSemiTextSm>
            {diminishedDate} {formatRoyaltyCopy(diminishedDate)}{' '}
          </GraphikSemiTextSm>
          <GraphikTextSm>following </GraphikTextSm>
          <GraphikSemiTextSm>date of first transaction </GraphikSemiTextSm>
          <GraphikTextSm>and </GraphikTextSm>
          <GraphikSemiTextSm>{totalDiminishedRoyalties}% </GraphikSemiTextSm>
          <GraphikTextSm>thereafter</GraphikTextSm>
        </View>
      )}
    </View>
  );
};

export default RoyaltyEntry;
