import step4 from 'assets/images/onboarding/step4.png';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import { GraphikTextMd } from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { Image, Platform, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const FinalScreen: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View>
      <View style={TailwindResponsive(`items-center mb-8`)}>
        {Platform.OS === 'web' ? (
          <img src={step4} style={TailwindResponsive(`h-52`)} />
        ) : (
          <Image
            source={step4}
            style={{ height: 200, width: 200 }}
            resizeMode="contain"
          />
        )}
      </View>

      <GraphikTextMd>
        It&apos;s that easy! Register your first artwork to get started.
      </GraphikTextMd>

      <GraphikTextMd style={TailwindResponsive(`mt-6`)}>
        Or,{' '}
        <ExternalLink link="https://calendly.com/fairchain-support/help">
          schedule a one-on-one demo here
        </ExternalLink>{' '}
        with one of our team members. We will guide you through each feature,
        answering questions along the way.
      </GraphikTextMd>
    </View>
  );
};

export default FinalScreen;
