/* Copyright (C) 2020 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */
import { ENV } from './constants';

let graphQLURL;

if (ENV === 'STAGING')
  graphQLURL = 'https://backend-dbw54b7qta-uc.a.run.app/graphql';
else if (ENV === 'TEST')
  graphQLURL = 'https://backend-wqcep6e3yq-uc.a.run.app/graphql';
else if (ENV === 'DEMO')
  graphQLURL = 'https://backend-vfwf2nyqsa-uc.a.run.app/graphql';
else if (ENV === 'PRODUCTION')
  graphQLURL = 'https://backend-in2fksj26a-uc.a.run.app/graphql';
else graphQLURL = 'http://localhost:4008/graphql';

let wsGraphQLURL;

if (ENV === 'STAGING')
  wsGraphQLURL = 'wss://backend-dbw54b7qta-uc.a.run.app/graphql';
else if (ENV === 'TEST')
  wsGraphQLURL = 'wss://backend-wqcep6e3yq-uc.a.run.app/graphql';
else if (ENV === 'DEMO')
  wsGraphQLURL = 'wss://backend-vfwf2nyqsa-uc.a.run.app/graphql';
else if (ENV === 'PRODUCTION')
  wsGraphQLURL = 'wss://backend-in2fksj26a-uc.a.run.app/graphql';
else wsGraphQLURL = 'ws://localhost:4008/graphql';

export default {
  GRAPHQL_URL: graphQLURL,
  WS_GRAPHQL_URL: wsGraphQLURL,
};
