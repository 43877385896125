import { useReactiveVar } from '@apollo/client';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { DefaultTextInput } from 'components/shared/form';
import { GraphikTextMd } from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';
import { CRModalScreens, DetailsData } from 'store/artworkDetails/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const CRAddressScreen: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const form = useReactiveVar(DetailsData.CollectorRetro.form);

  if (!form) return null;

  return (
    <View>
      <GraphikTextMd>
        Your address information is required in order to have the artist issue a
        certificate. Please fill out your address information below.
      </GraphikTextMd>

      <View style={TailwindResponsive('w-full')}>
        <Controller
          control={form?.control}
          name="addressLine1"
          render={({ field: { onChange, value, ref } }) => (
            <DefaultTextInput
              errorMessage={form?.formState.errors.addressLine1?.message}
              handleChange={(input) => {
                form?.clearErrors('addressLine1');
                onChange(input);
              }}
              inputRef={ref}
              label="* Address Line 1"
              value={value}
            />
          )}
        />
      </View>

      <View style={TailwindResponsive('w-128 mobWeb:w-full')}>
        <Controller
          control={form?.control}
          name="addressLine2"
          render={({ field: { onChange, value, ref } }) => (
            <DefaultTextInput
              handleChange={(input) => {
                form?.clearErrors('addressLine2');
                onChange(input);
              }}
              inputRef={ref}
              label="Address Line 2"
              value={value}
            />
          )}
        />
      </View>

      <View style={TailwindResponsive('w-128 mobWeb:w-full')}>
        <Controller
          control={form?.control}
          name="addressCity"
          render={({ field: { onChange, value, ref } }) => (
            <DefaultTextInput
              errorMessage={form?.formState.errors.addressCity?.message}
              handleChange={(input) => {
                form?.clearErrors('addressCity');
                onChange(input);
              }}
              inputRef={ref}
              label="* City"
              value={value}
            />
          )}
        />
      </View>

      <View style={TailwindResponsive('w-128 mobWeb:w-full')}>
        <Controller
          control={form?.control}
          name="addressState"
          render={({ field: { onChange, value, ref } }) => (
            <DefaultTextInput
              errorMessage={form?.formState.errors.addressState?.message}
              handleChange={(input) => {
                form?.clearErrors('addressState');
                onChange(input);
              }}
              inputRef={ref}
              label="* State / Province"
              value={value}
            />
          )}
        />
      </View>

      <View style={TailwindResponsive('w-128 mobWeb:w-full')}>
        <Controller
          control={form?.control}
          name="addressZipcode"
          render={({ field: { onChange, value, ref } }) => (
            <DefaultTextInput
              errorMessage={form?.formState.errors.addressZipcode?.message}
              handleChange={(input) => {
                form?.clearErrors('addressZipcode');
                onChange(input);
              }}
              inputRef={ref}
              label="* Zip / Post Code"
              value={value}
            />
          )}
        />
      </View>

      <View style={TailwindResponsive('w-128 mobWeb:w-full')}>
        <Controller
          control={form?.control}
          name="addressCountry"
          render={({ field: { onChange, value, ref } }) => (
            <DefaultTextInput
              errorMessage={form?.formState.errors.addressCountry?.message}
              handleChange={(input) => {
                form?.clearErrors('addressCountry');
                onChange(input);
              }}
              inputRef={ref}
              label="* Country"
              value={value}
            />
          )}
        />
      </View>

      <FairchainButton
        buttonStyle={TailwindResponsive(`bg-Dark1 mt-10 px-6 rounded-full`)}
        label="Continue"
        onPress={async () => {
          const formResult = await form.trigger([
            'addressCity',
            'addressCountry',
            'addressLine1',
            'addressState',
            'addressZipcode',
          ]);
          if (formResult) {
            DetailsData.CollectorRetro.modalScreen(CRModalScreens.three);
          }
        }}
        textStyle={TailwindResponsive(`text-Light1`)}
      />
    </View>
  );
};

export default CRAddressScreen;
