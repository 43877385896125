import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { FairchainButton } from '../button/FairchainButton';
import { MainMenuNavigation } from './MainMenuNavigation';
import { useHandleRegisterWork } from './hooks';

interface IMainMenuWebProps {
  userRole: number;
}

export const MainMenuWeb: FunctionComponent<IMainMenuWebProps> = ({
  userRole,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const handleRegisterWork = useHandleRegisterWork();

  return (
    <View
      style={TailwindResponsive(`flex flex-row items-center justify-center`)}
    >
      <FairchainButton
        buttonStyle={TailwindResponsive(`bg-Primary mr-3 rounded-full w-48`)}
        label="Register Artwork"
        onPress={handleRegisterWork}
        textStyle={TailwindResponsive(`text-Light1`)}
      />

      <MainMenuNavigation userRole={userRole} />
    </View>
  );
};
