import { useMutation } from '@apollo/client';
import { REQUEST_CERTIFICATION } from 'api/requests.v2';

export const useRequestCertificationMutation = () => {
  const [requestCertification, { data, loading }] = useMutation(
    REQUEST_CERTIFICATION,
  );

  return {
    requestCertification,
    data,
    loading,
  };
};
