import { useQuery } from '@apollo/client';
import { GET_TRANSACTIONS_PENDING_BUYER } from 'api/requests.v2';
import { ErrorMessage } from 'components/shared/error';
import { SpinLoader } from 'components/shared/loader';
import { UserContext } from 'contexts';
import { compareDesc, parseISO } from 'date-fns';
import React, { FunctionComponent, useContext, useState } from 'react';
import { View } from 'react-native';
import { Transaction } from 'types';
import { BuyerListItem } from './components/BuyerListItem';

export const BuyerTransactionList: FunctionComponent = () => {
  const authUser = useContext(UserContext);

  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const { error, loading } = useQuery(GET_TRANSACTIONS_PENDING_BUYER, {
    variables: { userId: authUser?.uid },
    onCompleted: (data) => {
      const sortedTransactions = [...data.response];
      sortedTransactions.sort((a, b) =>
        compareDesc(parseISO(a.createDate), parseISO(b.createDate)),
      );
      setTransactions(sortedTransactions);
    },
  });

  if (loading) return <SpinLoader />;
  if (error) return <ErrorMessage error={error} />;

  return (
    <View>
      {transactions.map((transaction: Transaction) => (
        <BuyerListItem key={transaction.id} transaction={transaction} />
      ))}
    </View>
  );
};
