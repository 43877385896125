import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { AddAssetPhysicalId } from './components/AddAssetPhysicalId';

export const AssetManagement: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View style={TailwindResponsive(`p-4`)}>
      <AddAssetPhysicalId />
    </View>
  );
};
