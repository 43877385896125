import { useReactiveVar } from '@apollo/client';
import {
  GraphikMediumTextMd,
  GraphikSemiTextLg,
} from 'components/shared/styled';
import { format, parseISO } from 'date-fns';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ProfileVars } from '../../apollo';

export const CanceledSub: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const sub = useReactiveVar(ProfileVars.subScreen.subscription);

  return (
    <View style={TailwindResponsive(`mt-10`)}>
      <GraphikSemiTextLg>Canceled</GraphikSemiTextLg>
      <GraphikMediumTextMd>
        Your subscription is active until{' '}
        {format(parseISO(sub.cancelAt), 'PPP')}.
      </GraphikMediumTextMd>
    </View>
  );
};

export default CanceledSub;
