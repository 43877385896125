/* Copyright (C) 2022 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */
import { useMutation } from '@apollo/client';
import { UPDATE_PUBLIC_CERTIFICATE } from 'api/requests.v2';
import { Checkbox } from 'components/shared/checkbox';
import { ErrorMessage } from 'components/shared/error';
import { debounce } from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setPublicCertificates } from 'store/user';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const PublicCertificates: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const dispatch = useDispatch();
  const [enablePublicCerts, setPublicCerts] = useState(false);
  const [updatePubCert, { error: updatePubCertError }] = useMutation(
    UPDATE_PUBLIC_CERTIFICATE,
  );
  const userFromStore = useSelector(getUser);

  useEffect(() => {
    if (userFromStore) {
      setPublicCerts(userFromStore?.enablePublicCerts || false);
    }
  }, [userFromStore]);

  // Handle the provenance checkbox click
  useEffect(() => {
    handlePublicCert(enablePublicCerts, userFromStore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enablePublicCerts]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePublicCert = useCallback(
    debounce(async (newValue, storeUser) => {
      if (storeUser) {
        await updatePubCert({
          variables: {
            userId: storeUser.id,
            enablePublicCerts: newValue,
          },
        });

        if (storeUser) {
          dispatch(setPublicCertificates(newValue));
        }
      }
    }, 250),
    [],
  );

  if (updatePubCertError) return <ErrorMessage error={updatePubCertError} />;

  return (
    <View style={TailwindResponsive(`mt-20`)}>
      <Checkbox
        labelStyle={Styles.Fonts.FontFamily.SansMedium}
        label="Enable Public View of Certificates: This will allow you to share redacted versions of your certificates."
        isChecked={enablePublicCerts}
        onPress={() => setPublicCerts(!enablePublicCerts)}
      />
    </View>
  );
};

export default PublicCertificates;
