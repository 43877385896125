import { useMutation, useReactiveVar } from '@apollo/client';
import { RouteProp, useRoute } from '@react-navigation/native';
import { REGISTER_BLOCKCHAIN } from 'api/requests.v2';
import { RoundedButton } from 'components/shared/button';
import { SpinLoader } from 'components/shared/loader';
import { GraphikTextSm } from 'components/shared/styled';
import { NativeStackParamList } from 'navigation';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { DetailsData } from 'store/artworkDetails/apollo';
import { getFeatureFlags } from 'store/general';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const RegisterBlockchain: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const route = useRoute<RouteProp<NativeStackParamList, 'Details'>>();
  const featureFlags = useSelector(getFeatureFlags);
  const [registerBlockchain, { error: registerBlockchainError, loading }] =
    useMutation(REGISTER_BLOCKCHAIN);
  const [didSucceed, setDidSucceed] = useState(false);
  const [error, setError] = useState('');

  const work = useReactiveVar(DetailsData.work);

  const registerBlockchainHandler = useCallback(async () => {
    await registerBlockchain({
      variables: {
        workId: route.params.workId,
      },
      onCompleted: (data) => {
        if (data.response.success) {
          setDidSucceed(true);
        } else {
          setError(data.response.errorMessage);
        }
      },
    });
  }, [registerBlockchain, route]);

  if (
    !featureFlags.registerBlockchain ||
    !work?.certified ||
    work?.lastCompletedTransactionId
  )
    return null;

  if (loading) return <SpinLoader />;

  return (
    <View style={TailwindResponsive('mt-8 w-60')}>
      <RoundedButton
        backgroundColor={Styles.Colours.Primary}
        color="white"
        label="Register on Blockchain"
        onPress={() => {
          registerBlockchainHandler();
        }}
        paddingHorizontal={24}
      />
      {(registerBlockchainError || error !== '') && (
        <GraphikTextSm color={Styles.Colours.Error}>
          {error || `Error registering to blockchain!`}
        </GraphikTextSm>
      )}
      {!!didSucceed && (
        <GraphikTextSm color={Styles.Colours.Success}>
          Successfully registered to blockchain!
        </GraphikTextSm>
      )}
    </View>
  );
};
