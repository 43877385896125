export const loadIntercom = async () => {
  // Set your APP_ID
  const APP_ID = 'wcyobfe5';

  (function (...args) {
    const w = window;
    const ic = (w as any).Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', (w as any).intercomSettings);
    } else {
      const d = document;
      const i: any = function () {
        i.c(args);
      };
      i.q = [];
      i.c = function (vars) {
        i.q.push(vars);
      };
      (w as any).Intercom = i;
      const l = function () {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${APP_ID}`;
        const x = d.getElementsByTagName('script')[0];
        x?.parentNode?.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if ((w as any).attachEvent) {
        (w as any).attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};
