import bankersRounding from 'bankers-rounding';
import currency from 'currency.js';
import * as yup from 'yup';

export enum TransferWorkForm {
  allowCreditCard = 'allowCreditCard',
  allowKlarna = 'allowKlarna',
  artistRoyalty = 'artistRoyalty',
  addressLineOne = 'addressLineOne',
  addressLineTwo = 'addressLineTwo',
  buyerId = 'buyerId',
  city = 'city',
  company = 'company',
  country = 'country',
  doesExpire = 'doesExpire',
  email = 'email',
  firstName = 'firstName',
  items = 'items',
  kycCheck = 'kycCheck',
  lastName = 'lastName',
  passFeesToBuyer = 'passFeesToBuyer',
  phoneNumber = 'phoneNumber',
  state = 'state',
  zipcode = 'zipcode',
  galleryRoyalty = 'galleryRoyalty',
  generateInvoice = 'generateInvoice',
  optionalMessage = 'optionalMessage',
  quickbooksInvoice = 'quickbooksInvoice',
  shipping = 'shipping',
  saveClientState = 'saveClientState',
  tax = 'tax',
  totalRoyalty = 'totalRoyalty',
  transactionValue = 'transactionValue',
}

export interface IBuyerForm {
  [TransferWorkForm.addressLineOne]: string;
  [TransferWorkForm.addressLineTwo]: string;
  [TransferWorkForm.buyerId]: string;
  [TransferWorkForm.city]: string;
  [TransferWorkForm.company]: string;
  [TransferWorkForm.country]: string;
  [TransferWorkForm.email]: string;
  [TransferWorkForm.firstName]: string;
  [TransferWorkForm.kycCheck]: boolean;
  [TransferWorkForm.lastName]: string;
  [TransferWorkForm.phoneNumber]: string;
  [TransferWorkForm.saveClientState]: boolean;
  [TransferWorkForm.state]: string;
  [TransferWorkForm.zipcode]: string;
  optionalMessage?: string;
}

export interface ISingleWork {
  artistRoyalty: string;
  artworkValue: number;
  discountApplied: boolean;
  discount: number;
  galleryRoyalty: string;
  totalRoyalty: string;
  workId: string;
}

export interface ITransactionDetailsForm {
  allowCreditCard: boolean;
  allowKlarna: boolean;
  callingUserId: string;
  currency: string;
  doesExpire: boolean;
  enableBuyerRejection: boolean;
  expirationTime: string;
  feesPassed: number;
  generateInvoice: boolean;
  items: ISingleWork[];
  passFeesToBuyer: boolean;
  quickbooksInvoice: boolean;
  shipping: number;
  tax: number;
  totalRoyalty: string;
}

export interface TransactionFinalForm
  extends IBuyerForm,
    ITransactionDetailsForm {}

export const BuyerDefaultValues: IBuyerForm = {
  addressLineOne: '',
  addressLineTwo: '',
  buyerId: '',
  city: '',
  company: '',
  country: '',
  email: '',
  firstName: '',
  kycCheck: false,
  lastName: '',
  phoneNumber: '',
  state: '',
  zipcode: '',
  optionalMessage: '',
  saveClientState: true,
};

export const TransactionDetailsDefaultValues: ITransactionDetailsForm = {
  allowCreditCard: true,
  allowKlarna: false,
  callingUserId: '',
  currency: 'USD',
  doesExpire: false,
  enableBuyerRejection: true,
  expirationTime: '',
  feesPassed: 0,
  generateInvoice: true,
  items: [],
  passFeesToBuyer: false,
  quickbooksInvoice: false,
  shipping: 0,
  tax: 0,
  totalRoyalty: '',
};

export const calculateTotalRoyalty = (royaltyA: string, royaltyB: string) => {
  let royaltyAPercentage = parseFloat(royaltyA);
  let royaltyBPercentage = parseFloat(royaltyB);

  if (Number.isNaN(royaltyAPercentage)) royaltyAPercentage = 0;
  if (Number.isNaN(royaltyBPercentage)) royaltyBPercentage = 0;

  const newTotalRoyalty = `${currency(royaltyAPercentage, {
    pattern: `#!`,
    precision: 2,
    symbol: '%',
  })
    .add(royaltyBPercentage)
    .value.toString()}`;
  return newTotalRoyalty;
};

const noNegative = (input) => {
  if (input.includes('-')) {
    const newString = input.split('-').join('');
    return newString;
  }
  return input;
};

export const formatRoyaltyInput = (input) => {
  const inputWithoutNegative = noNegative(input);
  const splitString = inputWithoutNegative.split('.');
  if (splitString[1] && splitString[1].length > 2) {
    const numInput = parseFloat(inputWithoutNegative).toFixed(2);
    const backToString = numInput.toString();
    return backToString;
  }
  return inputWithoutNegative;
};

export const formatDiscountInput = (input) => {
  const inputWithoutNegative = noNegative(input);
  const splitString = inputWithoutNegative.split('.');
  return splitString[0];
};

export const calculatePaymentWithStripeFeePassedOn = (
  totalValue,
  currencySymbol,
) => {
  const numerator = totalValue + 0.55;
  const denominator = 1 - 0.0315;
  const finalValue = bankersRounding(numerator / denominator, 2);
  return currency(finalValue, { symbol: currencySymbol });
};

export const dateInPast = (firstDate, secondDate) => {
  if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
    return true;
  }

  return false;
};

export const PHONE_REG_EXP =
  /(^$|^(?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7})*$)/;

const transactionDetailsSchema = yup
  .object()
  .shape({
    items: yup.array().of(
      yup.object().shape({
        artistRoyalty: yup
          .number()
          .required('Please enter a value')
          .typeError('Please enter a valid number')
          .min(0, 'Please enter a value between 0 and 50')
          .max(50, 'Artist resale commissions are capped at 50%'),
        discount: yup
          .number()
          .typeError('Please enter a valid number')
          .min(0, 'Please enter a value between 0 and 50')
          .max(50, 'Discounts are capped at 50%'),
        galleryRoyalty: yup
          .number()
          .required('Please enter a value')
          .typeError('Please enter a valid number')
          .min(0, 'Please enter a value between 0 and 50')
          .max(50, 'Gallery resale commissions are capped at 50%')
          .test({
            name: 'Gallery Royalty Test',
            message:
              'Gallery resale commission cannot exceed the artist resale commission',
            test() {
              return (
                parseFloat(this.parent.galleryRoyalty) <=
                parseFloat(this.parent.artistRoyalty)
              );
            },
          }),
        totalRoyalty: yup
          .number()
          .typeError('Please enter a valid number')
          .required('Please enter a value')
          .min(0, 'Please enter a value between 0 and 50')
          .max(50, 'Total resale commissions are capped at 50%'),
      }),
    ),
    tax: yup
      .number()
      .typeError('Please enter a valid number')
      .min(0, 'Value cannot be negative'),
    shipping: yup
      .number()
      .typeError('Please enter a valid number')
      .min(0, 'Value cannot be negative'),
  })
  .required();

export { transactionDetailsSchema };
