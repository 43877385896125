import { GraphikTextMd } from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { Platform, View } from 'react-native';
import { Style } from 'tailwind-rn/dist';
import { logError } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IErrorMessageProps {
  error?: Error;
  errorMessage?: string;
  errorStyle?: Style;
  textStyle?: Style;
}

export const ErrorMessage: FunctionComponent<IErrorMessageProps> = ({
  error,
  errorMessage,
  errorStyle,
  textStyle,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  if (error) logError(error);

  const displayMessage = errorMessage || `An error occurred.`;

  const link =
    Platform.OS === 'web' ? (
      <a href="mailto: support@fairchain.art">Fairchain</a>
    ) : (
      <GraphikTextMd>support@fairchain.art</GraphikTextMd>
    );

  return (
    <View style={[TailwindResponsive(`items-center`), errorStyle]}>
      <GraphikTextMd
        style={[TailwindResponsive(`p-5 mb-2 mob:mt-20`), textStyle]}
      >
        {displayMessage} Contact {link} for assistance.
      </GraphikTextMd>
    </View>
  );
};
