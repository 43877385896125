import { createSlice } from '@reduxjs/toolkit';
import { IImagePickerResponse } from 'types/Images';

export interface IAdminState {
  logo: IImagePickerResponse | null;
  signature: IImagePickerResponse | null;
  rider: IImagePickerResponse | null;
}

const initialState: IAdminState = {
  logo: null,
  signature: null,
  rider: null,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    resetLogo: (state) => {
      state.logo = null;
    },
    resetSignature: (state) => {
      state.signature = null;
    },
    setLogo: (state, action) => {
      state.logo = action.payload.value;
    },
    setSignature: (state, action) => {
      state.signature = action.payload.value;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = adminSlice;
export const { resetLogo, resetSignature, setLogo, setSignature } = actions;
export { reducer as adminReducer };

export type AdminState = ReturnType<typeof reducer>;
export type AdminAction =
  | typeof resetLogo
  | typeof resetSignature
  | typeof setLogo
  | typeof setSignature;
