import React, { FunctionComponent } from 'react';
import { Pressable } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { GraphikMediumTextTiny } from '../styled';

interface BadgeProps {
  amount: number;
  onPress?: () => void;
}

export const NotificationBadge: FunctionComponent<BadgeProps> = ({
  amount,
  onPress,
}) => {
  const isMobileWeb = useMediaQuery({ maxWidth: 768 });

  if (amount === 0) return null;

  const getWidth = () => {
    if (amount > 9) return 27;
    if (amount > 99) return 36;
    return 20;
  };

  return (
    <Pressable
      style={{
        alignItems: 'center',
        backgroundColor: '#FF0000',
        borderRadius: 10,
        height: 20,
        justifyContent: 'center',
        paddingHorizontal: 7,
        width: getWidth(),
        marginLeft: isMobileWeb ? 0 : -5,
        paddingTop: isMobileWeb ? 0 : 2.5,
        marginTop: isMobileWeb ? 0 : 2.5,
      }}
      onPress={onPress}
    >
      <GraphikMediumTextTiny color="#FFFFFF">{amount}</GraphikMediumTextTiny>
    </Pressable>
  );
};

export default NotificationBadge;
