import { useQuery } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import { GET_CLICKWRAP_FOR_BUYER } from 'api/requests.v2';
import { SpinLoader } from 'components/shared/loader';
import { LoadContractRouteProp } from 'navigation';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const LoadContract: FunctionComponent = () => {
  const route = useRoute<LoadContractRouteProp>();
  const { TailwindResponsive } = useTailwindResponsive();

  useQuery(GET_CLICKWRAP_FOR_BUYER, {
    variables: { transactionId: route.params.transactionId },
    onCompleted: ({ response }) => {
      if (response) {
        window.location.href = response;
      }
    },
  });

  return (
    <View style={TailwindResponsive(`flex-1`)}>
      <SpinLoader />
    </View>
  );
};

export default LoadContract;
