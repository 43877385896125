import { useMutation, useReactiveVar } from '@apollo/client';
import { ACTIVATE_SUBSCRIPTION } from 'api/requests.v2';
import HeaderVars from 'components/shared/header/apollo.header';
import { IPaymentFlowResponse } from 'components/shared/stripe/StripePaymentFlow';
import { GraphikMediumTextLg, GraphikTextMd } from 'components/shared/styled';
import { GallerySubSelector } from 'components/shared/subscription/GallerySubSelector';
import { SubscriptionSelector } from 'components/shared/subscription/SubscriptionSelector';
import { UserRole } from 'constants/enums';
import { UserContext } from 'contexts';
import { FunctionComponent, useContext } from 'react';
import { Platform, View } from 'react-native';
import { useSelector } from 'react-redux';
import { getUserRole } from 'store/user';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ProfileVars } from '../../apollo';

export const InactiveSub: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const { TailwindResponsive } = useTailwindResponsive();

  const draftId = useReactiveVar(HeaderVars.draftWorkId);
  const sub = useReactiveVar(ProfileVars.subScreen.subscription);

  const role = useSelector(getUserRole);

  const collectorRedirectUrl = draftId
    ? `register-work/${draftId}`
    : 'profile?screen=SUBSCRIPTION';

  const [activateSub] = useMutation(ACTIVATE_SUBSCRIPTION, {
    onCompleted: ({ response }) => {
      if (response?.success) {
        ProfileVars.subScreen.subscription({
          ...sub,
          status: 'active',
        });
      }
    },
  });

  const callback = (input: IPaymentFlowResponse) => {
    if (input.success) {
      ProfileVars.subScreen.loading(true);
      activateSub({
        variables: {
          userId: authUser?.uid,
        },
      });
    }
  };

  return (
    <View
      style={TailwindResponsive(`mt-10 mob:border-t mob:border-Dark1 mob:pt-6`)}
    >
      {Platform.OS !== 'web' && (
        <GraphikMediumTextLg>Subscription</GraphikMediumTextLg>
      )}

      <GraphikTextMd style={TailwindResponsive(`mb-8 mob:mt-4`)}>
        Fairchain is an artist-connected digital ownership infrastructure for
        your art and collectibles. Leverage our suite of collection management
        tools to securely catalog your collection and maintain a comprehensive
        record of past, current, and future acquisitions.
      </GraphikTextMd>

      {role === UserRole.Collector ? (
        <SubscriptionSelector
          callback={callback}
          redirectUrl={collectorRedirectUrl}
        />
      ) : (
        <GallerySubSelector
          callback={callback}
          redirectUrl="profile?screen=SUBSCRIPTION"
        />
      )}
    </View>
  );
};

export default InactiveSub;
