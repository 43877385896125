import { useQuery } from '@apollo/client';
import { GET_DASHBOARD } from 'api/requests.v2';
import { ErrorMessage } from 'components/shared/error';
import { SpinLoader } from 'components/shared/loader';
import { GraphikMediumTextMd, GraphikTextSm } from 'components/shared/styled';
import { UserContext } from 'contexts';
import currency from 'currency.js';
import React, { FunctionComponent, useContext, useState } from 'react';
import { View } from 'react-native';

type TDashboard = {
  completedTransactions: number;
  totalTransactedValue: number;
};

export const Dashboard: FunctionComponent = () => {
  const authUser = useContext(UserContext);

  const [dashboard, setDashboard] = useState<TDashboard>();

  const { error, loading } = useQuery(GET_DASHBOARD, {
    variables: {
      userId: authUser?.uid,
    },
    onCompleted: (data) => {
      setDashboard(data.response);
    },
  });

  if (loading) return <SpinLoader />;
  if (error) return <ErrorMessage />;

  return (
    <View>
      <GraphikMediumTextMd>Total Transacted Value</GraphikMediumTextMd>
      <GraphikTextSm>
        {currency(dashboard?.totalTransactedValue || 0).format()}
      </GraphikTextSm>

      <GraphikMediumTextMd>Total Transactions Completed</GraphikMediumTextMd>
      <GraphikTextSm>{dashboard?.completedTransactions}</GraphikTextSm>
    </View>
  );
};
