import { Text } from 'react-native';
import Styles from 'style';
import styled from 'styled-components/native';

export const SmallTitle = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansSemiBold}';
  font-size: ${Styles.Fonts.FontSize.Small}px;
`;

export const LargeTitle = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansSemiBold}';
  font-size: ${Styles.Fonts.FontSize.Large}px;
`;

export const GraphikTextLg = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-size: ${Styles.Fonts.FontSize.Large}px;
  font-family: '${Styles.Fonts.FontFamily.SansRegular}';
  line-height: ${Styles.Fonts.LineHeights.Large}px;
`;

export const MediumTitle = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansSemiBold}';
  font-size: ${Styles.Fonts.FontSize.Medium}px;
`;

export const HugeTitle = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansSemiBold}';
  font-size: ${Styles.Fonts.FontSize.Huge}px;
`;

export const GraphikSemiTextHuge = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansSemiBold}';
  font-size: ${Styles.Fonts.FontSize.Huge}px;
  line-height: ${Styles.Fonts.LineHeights.Huge}px;
`;

export const GraphikSemiTextLg = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansSemiBold}';
  font-size: ${Styles.Fonts.FontSize.Large}px;
  line-height: ${Styles.Fonts.LineHeights.Large}px;
`;

export const GraphikSemiTextMd = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansSemiBold}';
  font-size: ${Styles.Fonts.FontSize.Medium}px;
  line-height: ${Styles.Fonts.LineHeights.Medium}px;
`;

export const GraphikSemiTextSm = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansSemiBold}';
  font-size: ${Styles.Fonts.FontSize.Small}px;
  line-height: ${Styles.Fonts.LineHeights.Small}px;
`;

export const GraphikSemiTextTiny = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansSemiBold}';
  font-size: ${Styles.Fonts.FontSize.Tiny}px;
  line-height: ${Styles.Fonts.LineHeights.Tiny}px;
`;

export const GraphikSemiTextXTiny = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansSemiBold}';
  font-size: ${Styles.Fonts.FontSize.XTiny}px;
  line-height: ${Styles.Fonts.LineHeights.XTiny}px;
`;

export const GraphikMediumTextHuge = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansMedium}';
  font-size: ${Styles.Fonts.FontSize.Huge}px;
  line-height: ${Styles.Fonts.LineHeights.Huge}px;
`;

export const GraphikMediumTextLg = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansMedium}';
  font-size: ${Styles.Fonts.FontSize.Large}px;
  line-height: ${Styles.Fonts.LineHeights.Large}px;
`;

export const GraphikMediumTextMd = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansMedium}';
  font-size: ${Styles.Fonts.FontSize.Medium}px;
  line-height: ${Styles.Fonts.LineHeights.Regular}px;
`;

export const GraphikMediumTextSm = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansMedium}';
  font-size: ${Styles.Fonts.FontSize.Small}px;
  line-height: ${Styles.Fonts.LineHeights.Small}px;
`;

export const GraphikMediumTextTiny = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansMedium}';
  font-size: ${Styles.Fonts.FontSize.Tiny}px;
  line-height: ${Styles.Fonts.LineHeights.Tiny}px;
`;

export const GraphikTextHuge = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansRegular}';
  font-size: ${Styles.Fonts.FontSize.Huge}px;
  line-height: ${(props) =>
    props.lineHeight || Styles.Fonts.LineHeights.Huge}px;
`;

export const GraphikTextLarge = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansRegular}';
  font-size: ${Styles.Fonts.FontSize.Large}px;
  line-height: ${Styles.Fonts.LineHeights.Large}px;
`;

export const GraphikTextMd = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansRegular}';
  font-size: ${Styles.Fonts.FontSize.Medium}px;
  line-height: ${Styles.Fonts.LineHeights.Medium}px;
`;

export const GraphikTextSm = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansRegular}';
  font-size: ${Styles.Fonts.FontSize.Small}px;
  line-height: ${Styles.Fonts.LineHeights.Small}px;
`;

export const GraphikTextTiny = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansRegular}';
  font-size: ${Styles.Fonts.FontSize.Tiny}px;
  line-height: ${Styles.Fonts.LineHeights.Tiny}px;
`;

export const GraphikItalicLg = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansItalic}';
  font-size: ${Styles.Fonts.FontSize.Large}px;
  line-height: ${Styles.Fonts.LineHeights.Large}px;
`;
export const GraphikItalicMd = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansItalic}';
  font-size: ${Styles.Fonts.FontSize.Medium}px;
  line-height: ${Styles.Fonts.LineHeights.Medium}px;
`;
export const GraphikItalicSm = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansItalic}';
  font-size: ${Styles.Fonts.FontSize.Small}px;
  line-height: ${Styles.Fonts.LineHeights.Small}px;
`;

export const GraphikLightTextMd = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark3};
  font-family: '${Styles.Fonts.FontFamily.SansRegular}';
  font-size: ${Styles.Fonts.FontSize.Medium}px;
  line-height: ${Styles.Fonts.LineHeights.Medium}px;
`;

export const IBMTextLarge = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansRegularIBM}';
  font-size: ${Styles.Fonts.FontSize.Large}px;
  line-height: ${Styles.Fonts.LineHeights.Large}px;
`;

export const IBMTextMd = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansRegularIBM}';
  font-size: ${Styles.Fonts.FontSize.Medium}px;
  line-height: ${Styles.Fonts.LineHeights.Medium}px;
`;

export const IBMTextSm = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansRegularIBM}';
  font-size: ${Styles.Fonts.FontSize.Small}px;
  line-height: ${Styles.Fonts.LineHeights.Small}px;
`;

export const IBMTextTiny = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansRegularIBM}';
  font-size: ${Styles.Fonts.FontSize.Tiny}px;
  line-height: ${Styles.Fonts.LineHeights.Tiny}px;
`;

export const IBMPlexMonoMediumMd = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansMediumIBM}';
  font-size: ${Styles.Fonts.FontSize.Medium}px;
  line-height: ${Styles.Fonts.LineHeights.Medium}px;
`;
