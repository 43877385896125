export enum VeriffCodes {
  Started = 7001,
  Submitted = 7002,
  Approved = 9001,
  Declined = 9102,
  Resubmitted = 9103,
  Expired = 9104,
}

export interface VeriffResponse {
  status: string;
  verification: VeriffVerification;
}

export interface VeriffVerification {
  id: string;
  url: string;
}
