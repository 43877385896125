import { RootState } from 'store';

export const getContractAccepted = (store: RootState) =>
  store.transferWork.contractAccepted;

export const getContractLoading = (store: RootState) =>
  store.transferWork.contractLoading;

export const getContractSignDate = (store: RootState) =>
  store.transferWork.contractSignDate;

export const getContractSignTimezone = (store: RootState) =>
  store.transferWork.contractSignTimezone;

export const getContractVersion = (store: RootState) =>
  store.transferWork.contractVersion;

export const getIsRetro = (store: RootState) => store.transferWork.isRetro;

export const getRider = (store: RootState) => store.transferWork.rider;

export const getSelectedWorks = (store: RootState) =>
  store.transferWork.selectedWorks;

export const getSelectedWorksCount = (store: RootState) =>
  store.transferWork.selectedWorks.length;

export const getStripeFeesAmount = (store: RootState) =>
  store.transferWork.transactionDetails.stripeFeesAmount;
