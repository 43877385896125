import { GraphikTextSm } from 'components/shared/styled';
import currency from 'currency.js';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import DetailsLineItem from './DetailsLineItem';

interface IInvoiceCostsProps {
  achFee: currency;
  achStripeTransferFee: currency;
  allowCreditCard: boolean;
  ccStripeTransferFee: currency;
  creditCardFee: currency;
  passFees: boolean;
  wireStripeTransferFee: currency;
  wireTransferFee: currency;
}

export const InvoiceCosts: FunctionComponent<IInvoiceCostsProps> = ({
  achFee,
  achStripeTransferFee,
  allowCreditCard,
  ccStripeTransferFee,
  creditCardFee,
  passFees,
  wireStripeTransferFee,
  wireTransferFee,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  return (
    <DetailsLineItem vertical title="Payment Processing:">
      <View style={TailwindResponsive(`mb-4 pb-0 mob:mb-0 mob:py-4`)}>
        {!passFees && (
          <GraphikTextSm>
            If paid via ACH Bank Transfer (recommended):{' '}
            {`${currency(achFee.add(achStripeTransferFee), {
              precision: 0,
            }).format()}`}
          </GraphikTextSm>
        )}
        {allowCreditCard && (
          <GraphikTextSm>
            If paid via Credit Card:{' '}
            {passFees
              ? `${currency(0).format()}`
              : `${creditCardFee.add(ccStripeTransferFee).format()}`}
          </GraphikTextSm>
        )}
        {!passFees && (
          <GraphikTextSm>
            If paid via Wire Transfer:{' '}
            {`${wireTransferFee.add(wireStripeTransferFee).format()}`}
          </GraphikTextSm>
        )}
      </View>
    </DetailsLineItem>
  );
};
