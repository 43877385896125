import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IBreadcrumbBubbleProps {
  checked: boolean;
}

export const BreadcrumbBubble: FunctionComponent<IBreadcrumbBubbleProps> = ({
  checked,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  return (
    <View
      style={
        checked
          ? TailwindResponsive(
              'bg-black border border-black rounded-full w-1.5 h-1.5 mr-1',
            )
          : TailwindResponsive(
              'bg-white border border-black rounded-full w-1.5 h-1.5 mr-1',
            )
      }
    />
  );
};
