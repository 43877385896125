import React, { FunctionComponent } from 'react';
import Svg, { Path, Polygon, Rect } from 'react-native-svg';
import { ISVGProps } from './Types';

export const WordmarkBlack: FunctionComponent<ISVGProps> = ({ width }) => (
  <Svg id="Logo" viewBox="0 0 1057.836 133.62" width={width}>
    <Path d="M497.393,10.769c21.279,0,38.974,9.857,48.66,27.072h12.341C548.182,14.452,524.972,0,497.393,0c-17.937,0-34.62,6.109-46.975,17.2a65.3,65.3,0,0,0-21.1,41.885h12.7C445.517,30.582,468.145,10.769,497.393,10.769Z" />
    <Rect x="596.373" y="2.614" width="12.635" height="56.473" />
    <Path d="M351.581,2.614H303.068V59.086H315.7V13.2h34.383c20.677,0,34.57,12.325,34.57,30.668a30.854,30.854,0,0,1-3.836,15.222h13.407a40.412,40.412,0,0,0,2.878-15.222C397.106,19.962,377.96,2.614,351.581,2.614Z" />
    <Polygon points="168.577 59.086 180.316 59.086 156.597 2.614 145.803 2.614 122.085 59.086 133.783 59.086 151.201 15.54 168.577 59.086" />
    <Rect x="244.445" y="2.614" width="12.636" height="56.473" />
    <Rect x="688.418" y="2.614" width="12.636" height="56.473" />
    <Rect x="1046.32" y="2.614" width="11.516" height="56.473" />
    <Polygon points="1004.175 59.086 959.292 2.614 949.422 2.614 949.422 59.086 960.937 59.086 960.937 21.506 990.806 59.086 1004.175 59.086" />
    <Rect x="890.799" y="2.614" width="12.636" height="56.473" />
    <Polygon points="76.675 2.614 0 2.614 0 59.086 12.636 59.086 12.636 13.382 76.675 13.382 76.675 2.614" />
    <Rect x="890.799" y="74.533" width="12.636" height="56.473" />
    <Polygon points="1057.836 131.006 1057.836 74.533 1046.32 74.533 1046.32 112.113 1016.452 74.533 1003.082 74.533 1047.966 131.006 1057.836 131.006" />
    <Rect x="949.422" y="74.533" width="11.515" height="56.473" />
    <Polygon points="701.054 131.006 701.054 74.533 596.373 74.533 596.373 131.006 609.009 131.006 609.009 85.333 688.418 85.333 688.418 131.006 701.054 131.006" />
    <Rect x="244.445" y="74.533" width="12.636" height="56.473" />
    <Polygon points="74.995 74.533 0 74.533 0 131.006 12.636 131.006 12.636 85.302 74.995 85.302 74.995 74.533" />
    <Path d="M546.053,95.779c-9.686,17.215-27.381,27.072-48.66,27.072-29.248,0-51.876-19.814-55.374-48.318h-12.7a65.309,65.309,0,0,0,21.1,41.886c12.356,11.092,29.038,17.2,46.975,17.2,27.579,0,50.789-14.453,61-37.841Z" />
    <Polygon points="179.098 85.333 197.531 131.006 210.522 131.006 186.803 74.533 115.597 74.533 91.878 131.006 104.867 131.006 123.247 85.333 179.098 85.333" />
    <Path d="M384.046,131.006h15.838l-39.4-46.582,1.874-.4a48.313,48.313,0,0,0,20.392-9.488H303.068v56.473H315.7V85.115h30.068Z" />
    <Polygon points="812.93 59.086 824.669 59.086 800.95 2.614 790.156 2.614 766.438 59.086 778.136 59.086 795.554 15.54 812.93 59.086" />
    <Polygon points="823.452 85.333 841.885 131.006 854.875 131.006 831.156 74.533 759.95 74.533 736.232 131.006 749.221 131.006 767.601 85.333 823.452 85.333" />
  </Svg>
);

export default WordmarkBlack;
