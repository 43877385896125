import SearchIconPNG from 'assets/images/SearchIcon.png';
import SearchIcon from 'components/graphics/SearchIcon';
import React, { FunctionComponent, useState } from 'react';
import { Image, Platform, Pressable, TextInput, View } from 'react-native';
import AutocompleteComponent from 'react-native-autocomplete-input';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { GraphikSemiTextSm, GraphikTextSm } from '../styled';

interface IData {
  name: string;
  id: string;
}

interface IAutocompleteProps {
  data: IData[];
  handleOptionPress: (string) => void;
  numCharsRequired: number;
  placeholder: string;
}

export const Autocomplete: FunctionComponent<IAutocompleteProps> = ({
  data,
  handleOptionPress,
  numCharsRequired,
  placeholder,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const [isFocused, setIsFocused] = useState(false);
  const [currentOptions, setCurrentOptions] = useState(data);
  const [value, setValue] = useState('');
  const [currentHover, setCurrentHover] = useState(null);

  const boldText = (inputText: string, currentValue: string) => {
    if (inputText && currentValue) {
      return [
        inputText.substring(0, currentValue.length),
        inputText.substring(currentValue.length),
      ];
    }
    return ['', ''];
  };

  const handleChange = (input: string) => {
    if (data && input.trim().length >= numCharsRequired) {
      setCurrentOptions(
        data.filter((item: IData) =>
          item.name.toLowerCase().startsWith(input.trim().toLowerCase()),
        ),
      );
    } else {
      setCurrentOptions([]);
    }
    setValue(input.trimStart());
  };

  const checkValue = () => {
    if (!currentOptions) return [];
    if (value) {
      const arrayCheck = currentOptions.find((option) => option.name === value);
      if (arrayCheck) return [];
    }
    return currentOptions;
  };

  const selectOption = (input) => {
    handleChange(input.name);
    handleOptionPress(input);
    setIsFocused(false);
    setCurrentHover(null);
  };

  const renderItem = ({ item, index }) => {
    const boldedText = boldText(item.name, value);
    return (
      <Pressable
        style={{
          backgroundColor:
            currentHover === index
              ? Styles.Colours.Light5
              : Styles.Colours.Light1,
          zIndex: 4,
          paddingVertical: 10,
        }}
        onPress={() => selectOption(item)}
        // @ts-ignore -- TS claims onMouseEnter doesn't exist, but it works
        onMouseEnter={() => {
          setCurrentHover(index);
        }}
        onMouseExit={() => {
          setCurrentHover(null);
        }}
        testID="AutoCompleteItem"
      >
        <View
          style={[
            TailwindResponsive(`flex flex-col pl-14 pr-5 h-full`),
            {
              marginHorizontal: Platform.OS !== 'web' ? 8 : 0,
            },
          ]}
        >
          <View style={TailwindResponsive(`flex flex-row mb-1`)}>
            <GraphikSemiTextSm>{boldedText[0]}</GraphikSemiTextSm>
            <GraphikTextSm>{boldedText[1]}</GraphikTextSm>
          </View>
          <GraphikTextSm style={TailwindResponsive(`italic`)}>
            {item.address}
          </GraphikTextSm>
        </View>
      </Pressable>
    );
  };

  const textInput = () => (
    <View
      style={[
        TailwindResponsive(
          `mob:border-b flex-row web:border border-black items-center`,
        ),
        {
          backgroundColor: isFocused
            ? Styles.Colours.Dark6
            : Styles.Colours.Light1,
        },
      ]}
    >
      <View style={TailwindResponsive(`mx-4 h-6 items-center w-6`)}>
        {Platform.OS !== 'web' ? (
          <Image
            source={SearchIconPNG}
            style={TailwindResponsive(`flex-1`)}
            resizeMode="contain"
          />
        ) : (
          <SearchIcon />
        )}
      </View>
      <TextInput
        value={value}
        onChangeText={(input) => handleChange(input)}
        style={[
          TailwindResponsive('h-16 web:pr-6 flex-1 font-bold'),
          {
            fontFamily: Styles.Fonts.FontFamily.SansRegular,
            fontSize: Styles.Fonts.FontSize.Small,
          },
          Platform.OS === 'web' && { outlineStyle: 'none' }, // added for web
        ]}
        placeholderTextColor={Styles.Colours.Dark1}
        placeholder={placeholder}
        clearButtonMode="while-editing"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        testID="AutoCompleteInput"
      />
    </View>
  );

  return (
    <View style={TailwindResponsive(`mb-6`)}>
      <AutocompleteComponent
        data={checkValue()}
        flatListProps={{
          horizontal: Platform.OS !== 'web',
          keyExtractor: (item) => `${item.id}-${item.name}`,
          renderItem,
        }}
        listContainerStyle={{
          maxHeight: 300,
          position: 'absolute',
          width: '100%',
          zIndex: 4,
          top: 66,
        }}
        inputContainerStyle={TailwindResponsive(`border-0`)}
        renderTextInput={textInput}
        hideResults={value === ''}
        onChangeText={(input) => handleChange(input)}
        value={value}
      />
    </View>
  );
};

export default Autocomplete;
