import { useLazyQuery } from '@apollo/client';
import {
  GET_STRIPE_INVOICE_STATUS,
  GET_STRIPE_INVOICE_URL,
} from 'api/requests.v2';
import { RoundedButton } from 'components/shared/button';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';
import Styles from 'style';
import { InvoiceStatus } from 'types';

export const InvoiceButton: FunctionComponent = () => {
  const transaction = useGetTransactionDetailsHook();

  const [showPayButton, setShowPayButton] = useState(false);

  const [
    getStripeInvoiceStatus,
    { data: stripeInvoiceStatus, startPolling, stopPolling },
  ] = useLazyQuery(GET_STRIPE_INVOICE_STATUS);

  const [getStripeInvoiceUrl, { data: stripeInvoiceURL }] = useLazyQuery(
    GET_STRIPE_INVOICE_URL,
  );

  useEffect(() => {
    if (transaction?.isBuyerView && transaction?.stripeInvoiceId) {
      getStripeInvoiceUrl({
        variables: { invoiceId: transaction?.stripeInvoiceId },
      });

      getStripeInvoiceStatus({
        variables: { invoiceId: transaction?.stripeInvoiceId },
      });
    }
  }, [getStripeInvoiceStatus, getStripeInvoiceUrl, transaction]);

  useEffect(() => {
    const transactionInPayableState =
      transaction?.invoiceStatus === InvoiceStatus.failed ||
      transaction?.invoiceStatus === InvoiceStatus.created;
    const invoiceUnPaid = stripeInvoiceStatus?.response === 'open';

    setShowPayButton(transactionInPayableState && invoiceUnPaid);

    if (!invoiceUnPaid && stopPolling) stopPolling();
  }, [stopPolling, stripeInvoiceStatus, transaction]);

  if (!showPayButton) return null;

  return (
    <View
      style={{
        marginVertical: Styles.Spacings.VerticalSpace.Small,
        width: 300,
      }}
    >
      <RoundedButton
        label="Pay Invoice"
        onPress={() => {
          window.open(stripeInvoiceURL.response, '_blank');
          if (startPolling) startPolling(1000);
        }}
        backgroundColor={Styles.Colours.Dark1}
        color={Styles.Colours.Light1}
      />
    </View>
  );
};

export default InvoiceButton;
