/* Copyright (C) 2022 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */
import { useLazyQuery } from '@apollo/client';
import {
  GET_STRIPE_ACCOUNT_LINK,
  GET_STRIPE_ACCOUNT_STATUS,
  SETUP_STRIPE_ACCOUNT_AND_GET_LINK,
} from 'api/requests.v2';
import { CheckIcon } from 'components/graphics';
import { RoundedButton } from 'components/shared/button';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { ErrorMessage } from 'components/shared/error';
import { GraphikMediumTextMd, GraphikTextSm } from 'components/shared/styled';
import { ButtonAlignment } from 'constants/enums';
import { UserContext } from 'contexts';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { Linking, Platform, Pressable, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIntegrationsLoading,
  getUser,
  setStripeAccountStatus,
  setStripeLoading,
} from 'store/user';
import Styles from 'style';
import LogError from 'utilities/LogError';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ConnectToStripe: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const authUser = useContext(UserContext);
  const dispatch = useDispatch();
  const integrationsLoading = useSelector(getIntegrationsLoading);
  const user = useSelector(getUser);

  const [
    getStripeAccountStatus,
    { error: getStripeAccountStatusError, loading: stripeLoading },
  ] = useLazyQuery(GET_STRIPE_ACCOUNT_STATUS, {
    onCompleted: (data) => {
      dispatch(setStripeAccountStatus(data.response));
      dispatch(setStripeLoading(false));
    },
    onError: () => {
      dispatch(setStripeLoading(false));
    },
  });

  const [getStripeAccountLink, { data: accountLinkData }] = useLazyQuery(
    GET_STRIPE_ACCOUNT_LINK,
    {
      variables: {
        stripeAccountId: user?.stripeAccountId,
        isMobile: Platform.OS !== 'web',
      },
      onCompleted: () => {
        if (accountLinkData && accountLinkData.response)
          Linking.openURL(accountLinkData.response);
      },
      onError: (error) => {
        LogError.logError(
          error,
          `Error in getting stripe account link for ${user?.stripeAccountId}`,
        );
      },
    },
  );

  const [setupStripeAccount, { data: stripeAccountLink }] = useLazyQuery(
    SETUP_STRIPE_ACCOUNT_AND_GET_LINK,
    {
      variables: {
        userId: authUser?.uid,
        isMobile: Platform.OS === 'ios',
      },
      onCompleted: () => {
        Linking.openURL(stripeAccountLink.response);
      },
    },
  );

  useEffect(() => {
    if (user && !user.stripeAccountIsSetup) {
      getStripeAccountStatus({ variables: { userId: authUser?.uid } });
    } else {
      dispatch(setStripeLoading(false));
    }
  }, [authUser, getStripeAccountStatus, user, dispatch]);

  const addloading = Platform.OS === 'web' ? integrationsLoading : false;
  if (stripeLoading || addloading) return null;

  if (getStripeAccountStatusError) return <ErrorMessage />;

  if (user && !user.stripeAccountIsSetup) {
    if (Platform.OS !== 'web')
      return (
        <FairchainButton
          buttonStyle={TailwindResponsive('flex-1 items-start self-start')}
          label="Connect to Stripe"
          onPress={() => setupStripeAccount()}
          textStyle={TailwindResponsive('underline')}
        />
      );

    return (
      <View style={TailwindResponsive('mt-12 w-72')}>
        <GraphikTextSm
          style={TailwindResponsive('mb-4 pl-2')}
          color={Styles.Colours.Gray2}
        >
          Verify your Stripe account to generate invoices and receive payments
        </GraphikTextSm>

        <RoundedButton
          alignment={ButtonAlignment.Left}
          backgroundColor={Styles.Colours.Dark1}
          color={Styles.Colours.Light1}
          label="Connect to Stripe"
          onPress={() => setupStripeAccount()}
          paddingHorizontal={22}
        />
      </View>
    );
  }

  if (Platform.OS !== 'web')
    return (
      <FairchainButton
        buttonStyle={TailwindResponsive('flex-1 items-start self-start')}
        label="Connected to Stripe"
        onPress={() => getStripeAccountLink()}
        textStyle={TailwindResponsive('underline')}
      />
    );

  return (
    <Pressable
      onPress={() => getStripeAccountLink()}
      style={TailwindResponsive(`flex flex-row self-start mt-12 mob:mt-1`)}
    >
      <GraphikMediumTextMd style={TailwindResponsive(`mr-1`)}>
        Connected to Stripe
      </GraphikMediumTextMd>
      <CheckIcon color={Styles.Colours.Dark1} width={18} />
    </Pressable>
  );
};

export default ConnectToStripe;
