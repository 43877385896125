import AsyncStorage from '@react-native-async-storage/async-storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import { adminReducer as admin } from './admin';
import { agreementsReducer as agreements } from './agreements';
import { collectionReducer as collection } from './collection';
import { formReducer as form } from './forms';
import { generalReducer as general } from './general';
import { transferWorkReducer as transferWork } from './transferWork';
import { userReducer as user } from './user';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
};

export const LOGOUT_ACTION = 'USER_LOGOUT';

const appReducer = combineReducers({
  admin,
  agreements,
  form,
  general,
  transferWork,
  user,
  collection,
});

const rootReducer = (state, action) => {
  if (action.type === 'user/userDisconnect') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
