/* eslint-disable react/no-danger */
import { useLazyQuery } from '@apollo/client';
import {
  GET_CLICKWRAP_FOR_BUYER,
  GET_CLICKWRAP_FOR_SELLER,
} from 'api/requests.v2';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { Checkbox } from 'components/shared/checkbox';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import { SpinLoader } from 'components/shared/loader';
import {
  GraphikMediumTextMd,
  GraphikMediumTextSm,
  GraphikTextMd,
  GraphikTextTiny,
} from 'components/shared/styled';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Linking, Platform, Pressable, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';
import {
  getContractLoading,
  getIsRetro,
  getRider,
  setContractAccepted,
  setContractLoading,
  setContractVersion,
  signContract,
} from 'store/transferWork';
import { Work, getDisplayName } from 'types';
import { isCollectible } from 'utilities';
import LogError from 'utilities/LogError';
import useTailwindResponsive from 'utilities/TailwindResponsive';

const NUM_RETRIES = 1;

interface IClickwrapProps {
  getVars?: () => Promise<any>;
  transactionId?: string;
  assetsAreCollectibles?: boolean;
}

export const Clickwrap: FunctionComponent<IClickwrapProps> = ({
  getVars,
  transactionId,
  assetsAreCollectibles,
}) => {
  const dispatch = useDispatch();
  const { TailwindResponsive } = useTailwindResponsive();
  const isSellerView = !transactionId;

  const [agreedToClickwrap, setAgreedToClickwrap] = useState(false);
  const [clickVars, setClickVars] = useState<any>(null);
  const [clickwrap, setClickwrap] = useState('');
  const [retryCount, setRetryCount] = useState(0);

  const transaction = useGetTransactionDetailsHook();

  const buyerTransIsRetro = transaction?.isBuyerView && transaction?.isRetro;
  const seller = transaction?.seller;
  const workTitles = (() => {
    if (!transaction || !transaction.items || transaction.items.length === 0)
      return '';
    return transaction.items.reduce<string>(
      (titles, current) => titles.concat(`${current.work.title}, `),
      '',
    );
  })();

  const contractLoading = useSelector(getContractLoading);
  const transIsRetro = useSelector(getIsRetro);
  const transactionIsForCollectibles =
    assetsAreCollectibles ||
    (transaction?.items && transaction?.items.length > 0
      ? isCollectible(transaction?.items[0]?.work as Work)
      : false);
  const riderOnContract = useSelector(getRider);
  const riderIncludedText =
    riderOnContract.fileName && isSellerView ? '(contract rider included)' : '';

  const linkLabel =
    transIsRetro || buyerTransIsRetro
      ? 'terms of the certification agreement'
      : 'terms of the title transfer';

  const [getClickwrap] = useLazyQuery(GET_CLICKWRAP_FOR_SELLER, {
    onCompleted: (data) => {
      setClickwrap(data?.response?.contract || '');
      dispatch(setContractVersion(data?.response?.version));
      dispatch(setContractLoading(false));
    },
    onError: (error) => {
      LogError.logError(error, `Error in getting clickwrap for seller.`);
      setRetryCount(retryCount + 1);
    },
  });

  const [getBuyerClickwrap] = useLazyQuery(GET_CLICKWRAP_FOR_BUYER, {
    onCompleted: ({ response }) => {
      setClickwrap(response);
      dispatch(setContractLoading(false));
    },
  });

  useEffect(() => {
    if (agreedToClickwrap) {
      dispatch(signContract());
    }
    dispatch(setContractAccepted(agreedToClickwrap));
  }, [agreedToClickwrap, dispatch]);

  useEffect(() => {
    if (retryCount <= NUM_RETRIES) {
      // Set contract loading from outset
      dispatch(setContractLoading(true));

      if (getVars) {
        const fetchVars = async () => {
          const vars = await getVars();
          setClickVars(vars);
        };

        fetchVars();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retryCount]);

  useEffect(() => {
    if (transactionId) {
      getBuyerClickwrap({
        variables: { transactionId },
      });
    }
  }, [getBuyerClickwrap, transactionId]);

  useEffect(() => {
    if (clickVars) {
      getClickwrap({
        variables: {
          input: clickVars,
        },
      });
    }
  }, [clickVars, getClickwrap]);

  if (contractLoading)
    return (
      <View style={TailwindResponsive(`border border-Dark1 mt-12 p-8 flex-1`)}>
        <SpinLoader />
      </View>
    );

  return (
    <View
      style={TailwindResponsive(
        `border border-Dark1 flex-1 mt-12 mb-12 p-12 mob:p-4 mob:my-8`,
      )}
    >
      {isSellerView ? (
        <>
          {transIsRetro && (
            <GraphikMediumTextMd style={TailwindResponsive(`mb-4`)}>
              By issuing this certificate of authenticity, you are attesting to
              the authenticity of this work.
            </GraphikMediumTextMd>
          )}
          <GraphikMediumTextMd style={TailwindResponsive(`mb-8`)}>
            This work comes with a sales agreement that ensures a continued
            connection between the{' '}
            {transactionIsForCollectibles ? 'creator' : 'artist'} and their
            work(s). After you accept this agreement, the{' '}
            {transIsRetro ? 'owner' : 'buyer'} will be sent a copy to
            counter-sign where they will make three main commitments:
          </GraphikMediumTextMd>
          <GraphikTextMd style={TailwindResponsive(`my-4`)}>
            1. To accept transfer of title to the{' '}
            {transactionIsForCollectibles ? 'work(s)' : 'artwork(s)'} and agree
            to pay any applicable purchase price.
          </GraphikTextMd>
          <GraphikTextMd>
            2. If the work is resold in the future, to direct a percentage of
            the sale price of the transferred{' '}
            {transactionIsForCollectibles ? 'work' : 'artwork'} to the{' '}
            {transactionIsForCollectibles ? 'creator' : 'artist'}, if and where
            indicated, and/or charitable support for the greater artistic
            community.
          </GraphikTextMd>
          <GraphikTextMd style={TailwindResponsive(`my-4`)}>
            3. To apply these terms to any subsequent transfer of title of the{' '}
            {transactionIsForCollectibles ? 'work' : 'artwork'}.
          </GraphikTextMd>
        </>
      ) : (
        <>
          {buyerTransIsRetro ? (
            <>
              <GraphikMediumTextMd style={TailwindResponsive(`mb-4`)}>
                {getDisplayName(seller)} is using Fairchain to retroactively
                certify their works. As the current owner of <i>{workTitles}</i>
                you can click below to electronically sign an agreement and
                issue the certificate to your account.
              </GraphikMediumTextMd>

              <GraphikMediumTextMd style={TailwindResponsive(`mb-4`)}>
                By accepting this certificate of authenticity, you are asserting
                that you hold title to this work and you are agreeing to use
                this framework to record provenance and execute future transfers
                of ownership of the work.
              </GraphikMediumTextMd>
            </>
          ) : (
            <View>
              <GraphikMediumTextMd style={TailwindResponsive(`mb-8`)}>
                This work comes with a sales agreement that ensures a continued
                connection between the{' '}
                {transactionIsForCollectibles ? 'creator' : 'artist'} and their
                work(s).
              </GraphikMediumTextMd>
              <GraphikTextMd>
                By accepting this agreement, you are making three main
                commitments:
              </GraphikTextMd>
            </View>
          )}
          <GraphikTextMd style={TailwindResponsive(`my-4`)}>
            1. To accept transfer of title to the{' '}
            {transactionIsForCollectibles ? 'work(s)' : 'artwork(s)'} and agree
            to pay any applicable purchase price.
          </GraphikTextMd>
          <GraphikTextMd>
            2. If the work is resold in the future, to direct a percentage of
            the sale price to the{' '}
            {transactionIsForCollectibles ? 'creator' : 'artist'}, and/or
            charitable support for the greater artistic community, if and where
            indicated.
          </GraphikTextMd>
          <GraphikTextMd style={TailwindResponsive(`my-4`)}>
            3. To apply these terms to any subsequent transfer of title of the{' '}
            {transactionIsForCollectibles ? 'work' : 'artwork'}.
          </GraphikTextMd>
        </>
      )}

      <View
        style={TailwindResponsive(
          `flex flex-row items-center mt-6 mob:flex-1 mobWeb:flex-1`,
        )}
      >
        <Checkbox
          isChecked={agreedToClickwrap}
          onPress={() => {
            setAgreedToClickwrap(!agreedToClickwrap);
          }}
        />
        <View
          style={TailwindResponsive(
            `flex flex-row flex-wrap mob:flex-1 mobWeb:flex-1`,
          )}
        >
          <Pressable
            onPress={() => {
              setAgreedToClickwrap(!agreedToClickwrap);
            }}
          >
            <GraphikMediumTextSm style={TailwindResponsive(`pb-1 pr-1`)}>
              I accept the
            </GraphikMediumTextSm>
          </Pressable>
          <ExternalLink link={clickwrap}>
            <View style={TailwindResponsive('flex-row ')}>
              <FairchainButton
                buttonStyle={TailwindResponsive(`h-5 self-start`)}
                label={linkLabel}
                onPress={() => {
                  if (Platform.OS !== 'web') Linking.openURL(clickwrap);
                }}
                textStyle={TailwindResponsive(`text-Primary`)}
              />
              <GraphikMediumTextSm
                style={TailwindResponsive('mobWeb:mt-1 mob:mt-1')}
              >
                {riderIncludedText}
              </GraphikMediumTextSm>
              <GraphikMediumTextSm>.</GraphikMediumTextSm>
            </View>
          </ExternalLink>
        </View>
      </View>
      {!!transaction?.contractRider && !isSellerView && (
        <GraphikTextTiny
          style={TailwindResponsive('mt-4 ml-10 mobWeb:mt-1 mob:mt-1')}
        >
          The seller has included a rider with this agreement. Click through
          above to view the details.
        </GraphikTextTiny>
      )}
    </View>
  );
};
