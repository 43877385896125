import { useFocusEffect } from '@react-navigation/native';
import { SpinLoader } from 'components/shared/loader';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

const techniquesList = [
  'Gessoing the canvas',
  'Adjusting the easel',
  'Mixing egg shells with the paint',
  'Adjusting the white balance for cool',
  'Revising our drip technique',
  'Adding paint thinner',
  'Burning the screen',
  'Adjusting our smock sleeves',
  'Finding the right shade of vermillion',
  "Firing up the ol' kiln",
  'Arranging the plinths',
  'Rendering the video',
  'Mixing the plaster',
  'Stretching the canvas',
  'Cleaning the paintbrushes',
  'Kneading the clay',
  'Sharpening our chisels',
  'Trying to hire a studio assistant',
  'Wishing Pearl Paint were still in business',
];

const MIN_VAL = 0;
const MAX_VAL = techniquesList.length - 1;

export const ArtLoader: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const [currentTextPick, setCurrentTextPick] = useState(0);

  const randomTechIndex = useCallback(() => {
    let newIndex = 0;
    do {
      newIndex = Math.floor(Math.random() * (MAX_VAL - MIN_VAL + 1) + MIN_VAL);
    } while (newIndex === currentTextPick);
    return newIndex;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useFocusEffect(
    useCallback(() => {
      const timer = setInterval(() => {
        setCurrentTextPick(randomTechIndex());
      }, 2000);

      return () => clearInterval(timer);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  const theText = useMemo(
    () =>
      // In case we must go back - `Generating secure certificate of title and authenticity, this may take a moment...`;
      techniquesList[currentTextPick],
    [currentTextPick],
  );

  return (
    <View style={TailwindResponsive(`flex-1 items-center justify-center`)}>
      <View style={TailwindResponsive(`flex items-center`)}>
        <SpinLoader placeholderText={theText} />
      </View>
    </View>
  );
};
