import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IBulletPointProps {
  children: React.ReactNode;
}

export const BulletPoint: FunctionComponent<IBulletPointProps> = ({
  children,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View style={TailwindResponsive(`flex flex-row items-start my-1`)}>
      <View
        style={TailwindResponsive('bg-Dark1 h-2 mr-2 mt-1.5 rounded-full w-2')}
      />
      {children}
    </View>
  );
};

export default BulletPoint;
