import React, { FunctionComponent } from 'react';
import { Text, View } from 'react-native';
import CurrencyInput from 'react-native-currency-input';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IUnitInputProps {
  errorMessage?: string;
  handleChange?: (value: number | null) => void;
  label: string | undefined;
  precision?: number;
  testID?: string;
  value: number | null;
}

export const FCCurrencyInput: FunctionComponent<IUnitInputProps> = ({
  errorMessage,
  handleChange,
  label,
  precision,
  testID,
  value,
  ...props
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const inputPrecision = precision || 0;

  return (
    <View style={TailwindResponsive('flex flex-1 items-center')}>
      <View style={TailwindResponsive('h-16 justify-center w-full mob:h-full')}>
        <CurrencyInput
          clearTextOnFocus={value === 0}
          delimiter=","
          inputMode="numeric"
          onBlur={() => handleChange && !value && handleChange(0)}
          onChangeValue={handleChange}
          precision={inputPrecision}
          separator="."
          style={{
            fontSize: Styles.Fonts.FontSize.Medium,
            fontFamily: Styles.Fonts.FontFamily.SansMedium,
            textAlign: 'center',
            height: '100%',
            backgroundColor: Styles.Colours.Gray1,
          }}
          testID={`${testID}_Input`}
          value={value}
          {...props}
        />
      </View>
      <View style={{ opacity: 0.6 }}>
        <Text
          style={{
            fontSize: Styles.Fonts.FontSize.Tiny,
            fontFamily: Styles.Fonts.FontFamily.SansRegular,
          }}
        >
          {label}
        </Text>
      </View>
      <View style={{ marginTop: 5 }}>
        <Text style={{ color: Styles.Colours.Error }}>{errorMessage}</Text>
      </View>
    </View>
  );
};
