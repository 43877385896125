import { useQuery } from '@apollo/client';
import { GET_ADMIN_PENDING_CERTS } from 'api/requests.v2';
import { SpinLoader } from 'components/shared/loader';
import { UserContext } from 'contexts';
import { FunctionComponent, useContext } from 'react';
import { View } from 'react-native';
import { Work } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { PendingCertItem } from './components/PendingCertItem';

export const PendingCerts: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const { TailwindResponsive } = useTailwindResponsive();

  const { data, loading } = useQuery(GET_ADMIN_PENDING_CERTS, {
    variables: {
      userId: authUser?.uid,
    },
  });

  if (!data || !data.response) return null;

  if (loading) return <SpinLoader />;

  return (
    <View style={TailwindResponsive(`p-4`)}>
      {data.response.map((work: Work) => (
        <PendingCertItem key={work.id} work={work} />
      ))}
    </View>
  );
};

export default PendingCerts;
