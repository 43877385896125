import { useReactiveVar } from '@apollo/client';
import { RegistrationType } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { RegisterStore } from 'store/registerWork/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useCanRegisterUncertifiedOnly } from '../Hooks';
import FinalReview from '../steps/FinalReview';
import { ImageSelection } from '../steps/ImageSelection';
import Royalty from '../steps/Royalty';
import { WorkDetails } from '../steps/WorkDetails';
import { ArtLoader } from './ArtLoader';
import RWFooter from './RWFooter';

export const RWMain: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const currentStep = useReactiveVar(RegisterStore.Vars.currentStep);
  const loading = useReactiveVar(RegisterStore.Vars.loading);

  // Use this to collector specific set anything
  const uncertOnly = useCanRegisterUncertifiedOnly();
  if (uncertOnly) {
    RegisterStore.Vars.registrationType(RegistrationType.Uncertified);
  }

  if (loading) return <ArtLoader />;

  return (
    <>
      <View style={TailwindResponsive('flex-1 mobWeb:w-full')}>
        {currentStep === 0 && <WorkDetails />}
        {currentStep === 1 && <ImageSelection />}
        {currentStep === 2 && <Royalty />}
        {currentStep === 3 && <FinalReview />}
      </View>

      <RWFooter />
    </>
  );
};

export default RWMain;
