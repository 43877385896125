import { DefaultTheme } from 'react-native-paper';
import { combinedStyles as Styles } from './combinedStyles';

export const FairchainTheme = {
  // Paper Theme
  dark: false,
  roundness: 0,
  colors: {
    ...DefaultTheme.colors,
    primary: Styles.Colours.Dark1,
    background: Styles.Colours.Light1,
  },
  fonts: {
    ...DefaultTheme.fonts,
    regular: {
      fontFamily: Styles.Fonts.FontFamily.SansRegular,
    },
    medium: {
      fontFamily: Styles.Fonts.FontFamily.SansMedium,
    },
    light: {
      fontFamily: Styles.Fonts.FontFamily.SansSemiBold,
    },
  },
  animation: {
    ...DefaultTheme.animation,
  },
};

export default FairchainTheme;
