import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { NativeStackParamList } from './types';

export const linking: LinkingOptions<NativeStackParamList> = {
  enabled: true,
  prefixes: [
    Linking.createURL('/'),
    'http://localhost:19000',
    'http://localhost:19006',
    'https://*.fairchain.art',
  ],
  config: {
    screens: {
      Admin: {
        screens: {
          Admin: 'admin',
          Dashboard: 'admin/dashboard',
          'Pending Approval Transactions': 'admin/pending-transactions',
          'Buyer Pending Transactions': 'admin/buyer-transactions',
          'User Management': 'admin/create-user',
          'Login as User': 'admin/login-as-user',
          'Pending Certs': 'admin/pending-certifications',
        },
      },
      Agreements: 'agreements',
      ArtworkPublicView: 'details/:workId/public',
      Authentication: 'auth',
      AuthVerify: 'verify',
      Collection: 'collection',
      Contact: 'contact',
      Consignment: 'consignment/:workId',
      Details: 'details/:workId',
      Identify: 'identify',
      Inbox: 'inbox',
      Import: 'import',
      LoadContract: 'load-contract/:transactionId',
      Loading: 'loading',
      Login: 'login',
      PrivacyPolicy: 'privacy',
      QBReturn: 'quickbooks',
      Profile: 'profile',
      RegisterWork: 'register-work/:draftId',
      ResetPassword: 'reset-password',
      ResetPasswordProfile: 'profile/reset-password',
      SignUp: {
        screens: {
          SignUp: 'signup',
          Artist: 'signup/artist',
          Collector: 'signup/collector',
        },
      },
      StripeRefetch: 'stripereauth/:stripeAccountId',
      StripePaymentReturn: 'stripe-payment/:transactionId',
      Subscription: 'subscription',
      SubscriptionRedirect: 'subscription-redirect',
      Terms: 'terms',
      TransferWork: 'transfer-work',
      Transactions: 'transactions',
      TransactionDetail: 'transaction-details/:transactionId',
      TransactionSignReturn: 'transaction-sign/:transactionId/:userId',
      NotFound: '*',
    },
  },
};
