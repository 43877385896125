import { useLazyQuery } from '@apollo/client';
import { GET_SUBSCRIPTION } from 'api/requests.v2';
import { UserContext } from 'contexts';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Text, View } from 'react-native';
import Styles from 'style';
import styled from 'styled-components/native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { SpinLoader } from '../loader';
import { IPaymentFlowResponse } from '../stripe/StripePaymentFlow';
import BasicOption from './BasicOption';
import PremiumOption from './PremiumOption';
import RecommendedOption from './RecommendedOption';
import SubPayment from './SubPayment';

export const PriceText = styled(Text)`
  color: ${(props) => props.color || Styles.Colours.Dark1};
  font-family: '${Styles.Fonts.FontFamily.SansMedium}';
  font-size: 50px;
  line-height: 55px;
`;

export interface ISubOptionProps {
  selectOption: () => void;
}

export interface ISubscriptionSelectorProps {
  callback?: (input: IPaymentFlowResponse) => void;
  redirectUrl?: string;
}

export enum SubscriptionOption {
  Basic = 'BASIC',
  Standard = 'STANDARD',
  Premium = 'PREMIUM',
  Gallery = 'GALLERY',
}

export const SubscriptionSelector: FunctionComponent<
  ISubscriptionSelectorProps
> = ({ callback, redirectUrl }) => {
  const authUser = useContext(UserContext);
  const { TailwindResponsive } = useTailwindResponsive();

  const [choice, setChoice] = useState<SubscriptionOption | null>(null);
  const [payId, setPayId] = useState<string | null>(null);

  const [getSubscription, { loading }] = useLazyQuery(GET_SUBSCRIPTION, {
    onCompleted({ response }) {
      if (response.success) {
        setPayId(response.successMessage);
      }
    },
  });

  useEffect(() => {
    if (choice) {
      getSubscription({
        variables: {
          userId: authUser?.uid,
          subChoice: choice,
        },
      });
    }
  }, [authUser, choice, getSubscription]);

  if (loading) return <SpinLoader />;

  if (choice && payId)
    return (
      <SubPayment
        callback={callback}
        choice={choice}
        payId={payId}
        redirectUrl={redirectUrl}
      />
    );

  return (
    <View style={TailwindResponsive(`items-center px-2 xl:mt-10 xl:w-full`)}>
      <View style={TailwindResponsive(`flex xl:flex-row`)}>
        <BasicOption
          selectOption={() => {
            setChoice(SubscriptionOption.Basic);
          }}
        />
        <RecommendedOption
          selectOption={() => {
            setChoice(SubscriptionOption.Standard);
          }}
        />
        <PremiumOption
          selectOption={() => {
            setChoice(SubscriptionOption.Premium);
          }}
        />
      </View>
    </View>
  );
};
