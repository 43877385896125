/* Copyright (C) 2020 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */
/* Copyright (C) 2020 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */
import { useNavigation } from '@react-navigation/native';
import { SmallTitle } from 'components/shared/styled';
import { FCStackNavProp } from 'navigation';
import React, { FunctionComponent } from 'react';
import { Pressable, View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ProfileFooter: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const navigator = useNavigation<FCStackNavProp>();

  return (
    <View style={TailwindResponsive('flex-col bg-black')}>
      <View
        style={{
          ...TailwindResponsive('border-b px-6 py-4'),
          borderColor: 'grey',
        }}
      >
        <Pressable
          onPress={() => {
            navigator.navigate('Terms');
          }}
        >
          <View style={TailwindResponsive('flex flex-row items-center h-8')}>
            <SmallTitle color={Styles.Colours.Light2}>Terms</SmallTitle>
          </View>
        </Pressable>
      </View>

      <View
        style={{
          ...TailwindResponsive('px-6 py-4'),
        }}
      >
        <Pressable
          onPress={() => {
            navigator.navigate('Contact');
          }}
        >
          <View style={TailwindResponsive('flex flex-row items-center h-8')}>
            <SmallTitle color={Styles.Colours.Light2}>Contact</SmallTitle>
          </View>
        </Pressable>
      </View>
    </View>
  );
};

export default ProfileFooter;
