import { Link } from '@react-navigation/native';
import React, { FunctionComponent } from 'react';
import { Linking, Platform, Pressable } from 'react-native';
import Styles from 'style';
import { Style } from 'tailwind-rn/dist';

interface IExternalLinkProps {
  children?: any;
  link: string;
  style?:
    | {
        [key: string]: string;
      }
    | Style;
  testId?: string;
}

export const ExternalLink: FunctionComponent<IExternalLinkProps> = ({
  children,
  link,
  style,
  testId,
}) => {
  if (!link.includes('https') && !link.includes('http')) {
    return (
      <Link testID={testId} to={link} style={style}>
        {children}
      </Link>
    );
  }

  if (Platform.OS === 'web') {
    return (
      <a
        href={link}
        target="_blank"
        data-testid={testId}
        rel="noreferrer"
        style={{ color: Styles.Colours.Primary, textDecoration: 'none' }}
      >
        {children}
      </a>
    );
  }

  return (
    <Pressable onPress={() => Linking.openURL(link)} testID={testId}>
      {children}
    </Pressable>
  );
};
