import { useMutation, useReactiveVar } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { SIGN_UP_USER } from 'api/requests.v2';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { Checkbox } from 'components/shared/checkbox/Checkbox';
import { DefaultTextInput } from 'components/shared/form';
import { WordmarkHeader } from 'components/shared/header';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import {
  GraphikMediumTextLg,
  GraphikMediumTextMd,
  GraphikTextSm,
} from 'components/shared/styled';
import { CreateUserType } from 'components/views/admin';
import { FunctionComponent, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { SignUpVars, signUpFormvar } from '../form';

export const FormInfo: FunctionComponent = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { TailwindResponsive } = useTailwindResponsive();

  const form = useReactiveVar(signUpFormvar);
  const formRoleVal = form?.watch('role');
  const errorResponse = useReactiveVar(SignUpVars.errorMessage);
  const signInLoading = useReactiveVar(SignUpVars.signInLoading);

  const firstNameInfoMsg =
    formRoleVal === CreateUserType.Artist
      ? 'Please make sure your name matches the name listed on your government issued identification. You will be prompted to verify your ID at first login.'
      : '';
  const lastNameInfoMsg =
    formRoleVal === CreateUserType.Artist
      ? `If the name you use as an artist is different from the above, please contact support@fairchain.art after completing the signup process`
      : '';

  const [signUpQuery, { loading }] = useMutation(SIGN_UP_USER);

  const onSubmit = async (data) => {
    SignUpVars.errorMessage('');
    SignUpVars.signInLoading(true);

    const response = await signUpQuery({
      variables: {
        signUpInfo: {
          ...data,
          agreedTimeStamp: new Date().toISOString(),
          agreedTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    });
    SignUpVars.response(response?.data?.response);
  };

  useEffect(() => {
    if (route && form) {
      if (route.name === 'Artist') {
        form.setValue('role', CreateUserType.Artist);
      } else {
        form.setValue('role', CreateUserType.Collector);
      }
    }
  }, [form, route]);

  return (
    <>
      <View style={TailwindResponsive('mt-10 mobWeb:mt-4 mob:-mt-40')}>
        <WordmarkHeader color={Styles.Colours.Light1} />
      </View>

      <View
        style={TailwindResponsive(
          'pt-12 pb-28 mx-14 mob:pt-0 mob:px-4 mob:-mt-4',
        )}
      >
        <GraphikMediumTextLg
          color={Styles.Colours.Light1}
          style={TailwindResponsive(`mb-10`)}
        >
          Create Account
        </GraphikMediumTextLg>

        <GraphikMediumTextMd color={Styles.Colours.Light1}>
          {/* Welcome to Fairchain! Fill out the form below to create an account. // Sign up depracation */}
          No new Fairchain accounts are being created at this time. Contact
          support@fairchain.art for assistance.
        </GraphikMediumTextMd>
        {/* hidden for sign up depracation */}
        <View
          style={TailwindResponsive(
            'w-128  hidden mobWeb:w-full mobWeb:max-w-full',
          )}
        >
          <Controller
            control={form?.control}
            name="firstName"
            render={({ field: { onChange, value, ref } }) => (
              <DefaultTextInput
                color={Styles.Colours.Light1}
                errorMessage={form?.formState.errors.firstName?.message}
                handleChange={(input) => {
                  form?.clearErrors('firstName');
                  onChange(input);
                }}
                infoMessage={firstNameInfoMsg}
                inputRef={ref}
                label="* First Name"
                value={value}
              />
            )}
          />
        </View>

        <View style={TailwindResponsive('w-128 mobWeb:w-full')}>
          <Controller
            control={form?.control}
            name="lastName"
            render={({ field: { onChange, value, ref } }) => (
              <DefaultTextInput
                color={Styles.Colours.Light1}
                errorMessage={form?.formState.errors.lastName?.message}
                handleChange={(input) => {
                  form?.clearErrors('lastName');
                  onChange(input);
                }}
                infoMessage={lastNameInfoMsg}
                inputRef={ref}
                label="* Last Name"
                value={value}
              />
            )}
          />
        </View>

        <View style={TailwindResponsive('w-128 mobWeb:w-full')}>
          <Controller
            control={form?.control}
            name="email"
            render={({ field: { onChange, value, ref } }) => (
              <DefaultTextInput
                autoCapitalize="none"
                autoCorrect={false}
                color={Styles.Colours.Light1}
                errorMessage={form?.formState.errors.email?.message}
                handleChange={(input) => {
                  form?.clearErrors('email');
                  onChange(input);
                }}
                inputRef={ref}
                inputMode="email"
                label="* Email"
                value={value}
              />
            )}
          />
        </View>

        <View style={TailwindResponsive('w-128 mobWeb:w-full')}>
          <Controller
            control={form?.control}
            name="password"
            render={({ field: { onChange, value, ref } }) => (
              <DefaultTextInput
                color={Styles.Colours.Light1}
                errorMessage={form?.formState.errors.password?.message}
                handleChange={(input) => {
                  form?.clearErrors('password');
                  onChange(input);
                }}
                infoMessage="Password must be a minimum of 8 characters and must include a number."
                inputRef={ref}
                label="* Password"
                secureTextEntry
                value={value}
              />
            )}
          />
        </View>

        <View style={TailwindResponsive('w-128 mobWeb:w-full')}>
          <Controller
            control={form?.control}
            name="confirmPassword"
            render={({ field: { onChange, value, ref } }) => (
              <DefaultTextInput
                color={Styles.Colours.Light1}
                errorMessage={form?.formState.errors.confirmPassword?.message}
                handleChange={(input) => {
                  form?.clearErrors('confirmPassword');
                  onChange(input);
                }}
                inputRef={ref}
                label="* Confirm Password"
                secureTextEntry
                value={value}
              />
            )}
          />
        </View>

        <View style={TailwindResponsive('w-128 mobWeb:w-full')}>
          <Controller
            control={form?.control}
            name="reference"
            render={({ field: { onChange, value, ref } }) => (
              <DefaultTextInput
                color={Styles.Colours.Light1}
                handleChange={(input) => {
                  form?.clearErrors('reference');
                  onChange(input);
                }}
                inputRef={ref}
                label="How did you hear about Fairchain?"
                value={value}
              />
            )}
          />
        </View>

        <View style={TailwindResponsive('mt-8 w-2/5 mobWeb:w-full')}>
          <View style={TailwindResponsive(`mb-2 flex flex-row items-center`)}>
            <Controller
              control={form?.control}
              name="agreedToTerms"
              render={({ field: { onChange, value } }) => (
                <>
                  <Checkbox
                    checkColor={Styles.Colours.Light1}
                    isChecked={value}
                    onPress={() => {
                      onChange(!value);
                    }}
                    size={18}
                  />
                  <GraphikTextSm color={Styles.Colours.Light1}>
                    I accept the Fairchain{' '}
                    <ExternalLink link="/terms">
                      <GraphikTextSm
                        style={TailwindResponsive(`text-Light1 underline`)}
                      >
                        Terms of Service
                      </GraphikTextSm>
                    </ExternalLink>
                    .
                  </GraphikTextSm>
                </>
              )}
            />
          </View>
          <GraphikTextSm style={TailwindResponsive(`text-Error`)}>
            {form?.formState?.errors?.agreedToTerms?.message}
          </GraphikTextSm>
        </View>

        <View style={TailwindResponsive('mt-10 w-2/5 mobWeb:w-full')}>
          {!!errorResponse && (
            <>
              <GraphikMediumTextMd
                color={Styles.Colours.Error}
                style={TailwindResponsive(`color-Error mb-6`)}
              >
                {errorResponse}
              </GraphikMediumTextMd>

              {errorResponse.includes('already in use') && (
                <View style={TailwindResponsive(`mb-6 -mt-4`)}>
                  <GraphikMediumTextMd color={Styles.Colours.Light1}>
                    Please try to log in{' '}
                    <FairchainButton
                      label="here"
                      onPress={() => {
                        navigation.navigate('Login', { redirectURL: null });
                      }}
                      textStyle={TailwindResponsive('underline')}
                    />{' '}
                    or reset your password.
                  </GraphikMediumTextMd>
                </View>
              )}
            </>
          )}
          {/* hidden for sign up depracation */}
          <FairchainButton
            buttonStyle={TailwindResponsive(
              `bg-Primary rounded-full px-8 w-40 hidden`,
            )}
            label="Sign Up"
            loading={loading || signInLoading}
            onPress={form?.handleSubmit(onSubmit)}
            textStyle={TailwindResponsive('text-Light1')}
          />
        </View>
      </View>
    </>
  );
};

export default FormInfo;
