import React, { FunctionComponent } from 'react';
import { Pressable } from 'react-native';
import style from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { GraphikTextSm } from '../styled';

interface ILeftButtonProps {
  label: string;
  onPress?: () => void;
}

export const LeftButton: FunctionComponent<ILeftButtonProps> = ({
  label,
  onPress,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <Pressable
      onPress={onPress}
      style={TailwindResponsive(
        `bg-Dark1 h-12 justify-center pl-6 rounded-full w-60 mobWeb:w-full`,
      )}
    >
      <GraphikTextSm color={style.Colours.Light1}>{label}</GraphikTextSm>
    </Pressable>
  );
};
