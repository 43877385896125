import { useStripe } from '@stripe/react-stripe-js';
import {
  GraphikMediumTextMd,
  GraphikSemiTextSm,
  GraphikTextSm,
} from 'components/shared/styled';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';
import Styles from 'style';
import { InvoiceStatus } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ACHInfo: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const stripe = useStripe();

  const transaction = useGetTransactionDetailsHook();

  const [hidden, setHidden] = useState(true);

  const paymentIntentId = transaction?.stripePaymentIntentId;
  const seller = transaction?.seller;

  useEffect(() => {
    if (!stripe || !paymentIntentId) {
      return;
    }

    stripe.retrievePaymentIntent(paymentIntentId).then(({ paymentIntent }) => {
      switch (paymentIntent!.status) {
        case 'succeeded':
          setHidden(true);
          break;
        case 'processing':
          setHidden(true);
          break;
        case 'requires_payment_method':
          setHidden(false);
          break;
        default:
          setHidden(false);
          break;
      }
    });
  }, [stripe, paymentIntentId]);

  const itemStyle = `border-b border-Dark6 flex flex-row py-1 items-center justify-between mobWeb:flex-col mob:flex-col mob:items-start`;
  const sellerAddressLineOne = seller?.address.billingAddressLineOne || '';
  const sellerAddressLineTwo = seller?.address.billingAddressLineTwo || '';
  const sellerAddressCity = seller?.address.billingAddressCity || '';
  const sellerAddressState = seller?.address.billingAddressState || '';
  const sellerAddressZipcode = seller?.address.billingAddressZipcode || '';
  const sellerAddressCountry = seller?.address.billingAddressCountry || '';
  const sellerAddressNull =
    !seller?.address.billingAddressLineOne &&
    !seller?.address.billingAddressLineTwo &&
    !seller?.address.billingAddressCity &&
    !seller?.address.billingAddressZipcode &&
    !seller?.address.billingAddressCountry;

  const sellerAddress = sellerAddressNull
    ? '--'
    : `${sellerAddressLineOne}${sellerAddressLineTwo}, ${sellerAddressCity}, ${sellerAddressState} ${sellerAddressZipcode} ${sellerAddressCountry}`;

  if (
    !transaction ||
    !transaction?.stripeSource ||
    transaction?.invoiceStatus === InvoiceStatus.paid ||
    !transaction.isBuyerView ||
    hidden
  )
    return null;

  return (
    <View
      style={TailwindResponsive(
        `bg-Light2 border border-Dark4 mt-4 p-4 rounded-lg`,
      )}
    >
      <GraphikMediumTextMd
        color={Styles.Colours.Dark3}
        style={TailwindResponsive(`mb-2`)}
      >
        Or, pay by wire transfer. This is a unique address for this
        transactions, managed by Stripe.
      </GraphikMediumTextMd>

      <View style={TailwindResponsive(itemStyle)}>
        <GraphikSemiTextSm>
          {transaction.stripeSource.accountNumber}
        </GraphikSemiTextSm>
        <GraphikTextSm>Account Number</GraphikTextSm>
      </View>
      <View style={TailwindResponsive(itemStyle)}>
        <GraphikSemiTextSm>
          {transaction.stripeSource.routingNumber}
        </GraphikSemiTextSm>
        <GraphikTextSm>Routing Number</GraphikTextSm>
      </View>
      <View style={TailwindResponsive(itemStyle)}>
        <GraphikSemiTextSm>
          {seller?.galleryName ||
            seller?.artistName ||
            `${seller?.firstName} ${seller?.lastName}`}
        </GraphikSemiTextSm>
        <GraphikTextSm>Seller Name</GraphikTextSm>
      </View>
      <View style={TailwindResponsive(itemStyle)}>
        <GraphikSemiTextSm>{sellerAddress}</GraphikSemiTextSm>
        <GraphikTextSm>Seller Address</GraphikTextSm>
      </View>
    </View>
  );
};

export default ACHInfo;
