import { useLazyQuery } from '@apollo/client';
import { GET_INVOICE_URL } from 'api/requests.v2';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { ErrorMessage } from 'components/shared/error';
import React, { FunctionComponent } from 'react';
import { Linking, Platform, View } from 'react-native';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';
import Styles from 'style';
import { Status } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ViewInvoice: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const transaction = useGetTransactionDetailsHook();
  const wrapperStyle = Platform.OS === 'web' ? '' : 'self-start items-start';

  const buyerCanViewInvoice =
    !transaction?.isBuyerView ||
    transaction.status === Status.buyerSigned ||
    transaction.status === Status.signed ||
    transaction.status === Status.pendingApproval ||
    transaction.status === Status.completed;

  const [
    getInvoiceUrl,
    { error: getInvoiceURLError, loading: loadingInvoice },
  ] = useLazyQuery(GET_INVOICE_URL, {
    onCompleted: ({ response }) => {
      if (response) {
        Linking.openURL(response);
      }
    },
    fetchPolicy: 'network-only',
  });

  if (getInvoiceURLError)
    return <ErrorMessage error={getInvoiceURLError as Error} />;

  if (!transaction?.invoiceFile || !buyerCanViewInvoice) return null;

  return (
    <View style={TailwindResponsive(wrapperStyle)}>
      <FairchainButton
        buttonStyle={TailwindResponsive(`flex h-6 mobWeb:mt-2 mobWeb:mb-1`)}
        label="View Invoice"
        loading={loadingInvoice}
        loadingStyle={TailwindResponsive(`w-24`)}
        onPress={() => {
          getInvoiceUrl({
            variables: { invoiceFile: transaction?.invoiceFile },
          });
        }}
        textStyle={{
          ...TailwindResponsive(`text-Primary`),
          fontFamily: Styles.Fonts.FontFamily.SansRegular,
          fontSize:
            Platform.OS === 'web'
              ? `${Styles.Fonts.FontSize.Small}px`
              : Styles.Fonts.FontSize.Small,
          lineHeight:
            Platform.OS === 'web'
              ? `${Styles.Fonts.LineHeights.Small}px`
              : Styles.Fonts.LineHeights.Small,
        }}
      />
    </View>
  );
};

export default ViewInvoice;
