import { useMutation, useReactiveVar } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import { SIGN_UP_USER } from 'api/requests.v2';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { WordmarkHeader } from 'components/shared/header';
import { CreateUserType } from 'components/views/admin';
import { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { SignUpVars, signUpFormvar } from '../form';
import { InfoFormStep } from './InfoFormStep';
import { PasswordStep } from './PasswordStep';
import { TermsFormStep } from './TermsFormStep';

enum FormStep {
  Info,
  Password,
  Terms,
}

export const FormWrapper: FunctionComponent = () => {
  const route = useRoute();
  const { TailwindResponsive } = useTailwindResponsive();

  const [signUpQuery, { loading }] = useMutation(SIGN_UP_USER);

  const form = useReactiveVar(signUpFormvar);
  const signInLoading = useReactiveVar(SignUpVars.signInLoading);

  const [formScreen, setFormScreen] = useState<FormStep>(FormStep.Info);

  let nextLabel = 'Sign Up';
  if (formScreen === FormStep.Password) nextLabel = 'Next';
  else if (formScreen === FormStep.Terms) nextLabel = 'Create Account';

  const handleSubmit = async () => {
    if (formScreen === FormStep.Info) {
      const result = await form?.trigger(['firstName', 'lastName', 'email']);
      if (result) {
        setFormScreen(FormStep.Password);
      }
    } else if (formScreen === FormStep.Password) {
      const result = await form?.trigger(['password', 'confirmPassword']);
      if (result) {
        setFormScreen(FormStep.Terms);
      }
    } else if (formScreen === FormStep.Terms) {
      const result = await form?.trigger();
      if (result) {
        SignUpVars.errorMessage('');
        SignUpVars.signInLoading(true);

        const formVals = form?.getValues();
        const response = await signUpQuery({
          variables: {
            signUpInfo: {
              ...formVals,
              agreedTimeStamp: new Date().toISOString(),
              agreedTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          },
        });
        SignUpVars.response(response?.data?.response);
        setFormScreen(FormStep.Info);
      }
    }
  };

  useEffect(() => {
    if (route && form) {
      if (route.name === 'Artist') {
        form.setValue('role', CreateUserType.Artist);
      } else {
        form.setValue('role', CreateUserType.Collector);
      }
    }
  }, [form, route]);

  if (!form) return null;

  return (
    <>
      <View style={TailwindResponsive('mt-10 mobWeb:mt-4 mob:-mt-40')}>
        <WordmarkHeader color={Styles.Colours.Light1} />
      </View>

      <View style={TailwindResponsive('mx-14 mob:pt-0 mob:px-4 mob:-mt-4')}>
        {formScreen === FormStep.Info && <InfoFormStep />}
        {formScreen === FormStep.Password && <PasswordStep />}
        {formScreen === FormStep.Terms && <TermsFormStep />}

        <View style={TailwindResponsive(`flex flex-row items-center mt-8`)}>
          {formScreen !== FormStep.Info && (
            <FairchainButton
              buttonStyle={TailwindResponsive(`px-4 mr-8`)}
              label="Back"
              onPress={() => {
                if (formScreen === FormStep.Terms) {
                  setFormScreen(FormStep.Password);
                } else if (formScreen === FormStep.Password) {
                  setFormScreen(FormStep.Info);
                }
              }}
              textStyle={TailwindResponsive(`text-Light1 underline`)}
            />
          )}

          <FairchainButton
            buttonStyle={TailwindResponsive(
              `bg-Primary rounded-full px-8 self-start`,
            )}
            label={nextLabel}
            loading={loading || signInLoading}
            loadingStyle={TailwindResponsive('w-40')}
            onPress={handleSubmit}
            textStyle={TailwindResponsive('text-Light1')}
          />
        </View>
      </View>
    </>
  );
};
