import { GraphikTextSm } from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export interface IPrimaryFooterProps {
  testID?: string;
}

const PrimaryFooter: FunctionComponent<IPrimaryFooterProps> = ({ testID }) => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View
      style={TailwindResponsive(
        'pt-6 border-t-2 border-black w-52 h-20 mob:w-40 mob:h-40 mobWeb:pt-3',
      )}
    >
      <GraphikTextSm testID={testID}>Primary</GraphikTextSm>
    </View>
  );
};

export default PrimaryFooter;
