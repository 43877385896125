import {
  CollectionPage,
  CollectionSort,
  CollectionTab,
  CollectionView,
} from 'constants/enums';
import { groupBy, orderBy } from 'lodash';
import { CollectionRootState } from 'store/types';
import { Work } from 'types';

export const getArtistBlocks = (store: CollectionRootState): Work[][] => {
  let sortedWorks: Work[] = store.collection.myCollection.map((x) => ({
    ...x,
  }));
  sortedWorks = orderBy(sortedWorks, ['artist.artistName'], ['asc']);
  const worksGroupedByArtist: Work[][] = Object.values(
    groupBy(sortedWorks, (work) => work.artistId),
  );
  return worksGroupedByArtist;
};

export const getCollectionDisplayType = (
  store: CollectionRootState,
): CollectionTab => store.collection?.currentState;

export const getCollectionLoading = (store: CollectionRootState): boolean =>
  store.collection.loading;

export const getCollectionTab = (store: CollectionRootState): CollectionTab =>
  store.collection.collectionTab;

export const getCurrentPage = (store: CollectionRootState): CollectionPage =>
  store.collection.currentPage;

export const getCurrentView = (store: CollectionRootState): CollectionView =>
  store.collection.currentView;

export const getErrorMessage = (store: CollectionRootState): string =>
  store.collection.errorMessage;

export const getDateWorks = (store: CollectionRootState): Work[] => {
  let theWorks: Work[] = [];
  if (store.collection.collectionTab === CollectionTab.Consigned)
    theWorks = store.collection.myConsignedWorks;
  else if (store.collection.collectionTab === CollectionTab.Past)
    theWorks = store.collection.pastWorks;
  else theWorks = store.collection.myCollection;

  if (!theWorks) return [];
  const sortedWorks: Work[] = theWorks.map((x) => ({
    ...x,
  }));

  return orderBy(sortedWorks, ['dateRegistered'], ['desc']);
};

export const getGridWorks = (store: CollectionRootState): Work[] => {
  let theWorks: Work[] = [];
  if (store.collection.collectionTab === CollectionTab.Consigned)
    theWorks = store.collection.myConsignedWorks;
  else if (store.collection.collectionTab === CollectionTab.Past)
    theWorks = store.collection.pastWorks;
  else theWorks = store.collection.myCollection;

  if (!theWorks) return [];
  const sortedWorks: Work[] = theWorks.map((x) => ({ ...x }));

  if (
    store.collection.sortType === CollectionSort.ArtistClient &&
    store.collection.collectionTab === CollectionTab.Current
  )
    return orderBy(sortedWorks, ['artist.artistName'], ['asc']);
  return orderBy(sortedWorks, ['dateRegistered'], ['desc']);
};

export const getPastWorksLoaded = (store: CollectionRootState): boolean =>
  store.collection.pastWorksLoaded;

export const getSortModalVisible = (store: CollectionRootState): boolean =>
  store.collection.sortModalVisible;

export const hasConsignedWorks = (store: CollectionRootState): boolean =>
  store.collection.myConsignedWorks &&
  store.collection.myConsignedWorks.length > 0;

export const showGSAButton = (store: CollectionRootState): boolean =>
  !store.collection.loading &&
  store.collection.currentPage === CollectionPage.Collection &&
  store.collection.collectionTab === CollectionTab.Current &&
  store.collection.myCollection.length > 0;

export const showMobileSelector = (store: CollectionRootState): boolean =>
  store.collection.showMobileSelector;

export const showSortModal = (store: CollectionRootState): boolean =>
  store.collection.sortModalVisible;

export const sortingByDate = (store: CollectionRootState): boolean =>
  store.collection.sortType === CollectionSort.Date;
