import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { SITE_BASE_URL } from 'constants/constants';
import { RoyaltyType } from 'constants/enums';
import { UserContext } from 'contexts';
import currency from 'currency.js';
import { add, differenceInYears } from 'date-fns';
import * as mime from 'mime';
import { useCallback, useContext } from 'react';
import { Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearRoyalties,
  getDefaultsInitialized,
  getDiminishedRoyalties,
  getDiminishedRoyaltiesErrors,
  setDefaultsInitialized,
  setDiminishedRoyalty,
} from 'store/forms';
import {
  base64FileNameVar,
  base64FileVar,
  fileDataUriVar,
} from 'store/general/file-uploader.apollo';
import {
  getContractSignDate,
  getContractSignTimezone,
  getContractVersion,
  getIsRetro,
  getRider,
  getStripeFeesAmount,
  setContractLoading,
} from 'store/transferWork';
import {
  buyerRegistrationFormVar,
  createTransactionErrorVar,
  sellerBuyerLink,
  worksVar,
} from 'store/transferWork/apollo';
import { getMainUser, getUser } from 'store/user';
import { CertificationStatus, CurrencyType, WorkWithObject } from 'types';
import {
  getCombinedRoyalty,
  getRoyaltyByType,
  getUserAgentInfo,
  trimFormData,
  uploadBase64Async,
  uploadDataUriAsync,
} from 'utilities';
import * as yup from 'yup';
import { ISingleWork } from '.';
import { PHONE_REG_EXP } from './Helpers';

export const useGetBuyerRegistrationSchema = () => {
  const storeUser = useSelector(getUser);

  return yup
    .object()
    .shape({
      email: yup
        .string()
        .email('Please enter a valid email')
        .required('This is a required field')
        .notOneOf([storeUser?.email], 'Cannot issue a sales offer to yourself'),
      firstName: yup.string().required('This is a required field'),
      lastName: yup.string().required('This is a required field'),
      phoneNumber: yup
        .string()
        .optional()
        .matches(PHONE_REG_EXP, 'Phone number is not valid'),
      addressLineOne: yup.string().required('This is a required field'),
      city: yup.string().required('This is a required field'),
      country: yup.string().required('This is a required field'),
      state: yup.string().required('This is a required field'),
      zipcode: yup.string(),
    })
    .required();
};

const formatExpirationTime = (expirationTime) => {
  switch (expirationTime) {
    case '1H':
      return add(new Date(), { hours: 1 });
    case '3H':
      return add(new Date(), { hours: 3 });
    case '6H':
      return add(new Date(), { hours: 6 });
    case '1D':
      return add(new Date(), { days: 1 });
    case '3D':
      return add(new Date(), { days: 3 });
    case '7D':
      return add(new Date(), { days: 7 });
    default:
      return '';
  }
};

export const useValidateSubsection = (
  currentStep,
  isKycScreen,
  keyboardScrollRef,
  setCurrentStep,
  setFinalForm,
  setKycScreen,
  transactionDetailsGetValues,
  transactionDetailsSetValue,
  transactionDetailsTrigger,
) => {
  const buyerForm = useReactiveVar(buyerRegistrationFormVar);
  const diminishedRoyaltyErrors = useSelector(getDiminishedRoyaltiesErrors);

  const validateSubsection = async () => {
    switch (currentStep) {
      case 0: {
        const resultBuyer = await buyerForm?.trigger();
        if (resultBuyer) {
          if (buyerForm?.getValues('kycCheck') && !isKycScreen) {
            setKycScreen(true);
          } else {
            setKycScreen(false);
            setCurrentStep(currentStep + 1);
          }
        }
        break;
      }
      case 1: {
        const items = transactionDetailsGetValues('items');
        items.forEach((item, index) => {
          if (!item.artistRoyalty) {
            transactionDetailsSetValue(`items.${index}.artistRoyalty`, '0');
          }
          if (!item.galleryRoyalty) {
            transactionDetailsSetValue(`items.${index}.galleryRoyalty`, '0');
          }
        });
        const resultTransaction = await transactionDetailsTrigger();
        const buyerFormData = buyerForm?.getValues();
        const trimmedBuyerForm = trimFormData(buyerFormData);
        const transactionDetailsForm = transactionDetailsGetValues();

        const confirmationForm: any = {
          ...trimmedBuyerForm,
          ...transactionDetailsForm,
        };
        setFinalForm(confirmationForm);
        if (resultTransaction && !diminishedRoyaltyErrors) {
          setCurrentStep(currentStep + 1);
        }
        break;
      }
      default:
        setCurrentStep(currentStep + 1);
    }
    keyboardScrollRef?.current?.scrollToPosition(0, 0);
    if (Platform.OS === 'web')
      window.scroll({ top: 0, left: 0, behavior: 'auto' });
  };

  return validateSubsection;
};

export const useGenerateSalesAgreement = (
  finalForm,
  callGenerateSalesAgreement,
  setCurrentStep,
) => {
  const contractRiderFromStore = useSelector(getRider);
  const contractSignDate = useSelector(getContractSignDate);
  const contractTimezone = useSelector(getContractSignTimezone);
  const contractVersion = useSelector(getContractVersion);
  const currentUser = useSelector(getUser);
  const diminishedRoyalties = useSelector(getDiminishedRoyalties);
  const dispatch = useDispatch();
  const mainUser = useSelector(getMainUser);
  const navigation = useNavigation();
  const stripeFeesPassedOn = useSelector(getStripeFeesAmount);
  const transIsRetro = useSelector(getIsRetro);

  const invoiceBase64 = useReactiveVar(base64FileVar);
  const invoiceDataUri = useReactiveVar(fileDataUriVar);
  const invoiceFileName = useReactiveVar(base64FileNameVar);

  const sellerUser =
    currentUser?.isSubUser && mainUser ? mainUser : currentUser;

  const assembleItems = () =>
    finalForm?.items.map((item) => {
      const correctRoyalty = diminishedRoyalties.find(
        (royalty) => royalty?.workId === item.workId,
      );

      return {
        artistRoyalty: item.artistRoyalty,
        artworkValue: item.artworkValue,
        digitalRepoRights: item.digitalRepoRights,
        discount: item.discountApplied
          ? parseInt(item.discount.toString(), 10)
          : 0,
        diminishedArtistValue: correctRoyalty?.doesDiminish
          ? correctRoyalty?.diminishedArtistValue
          : '',
        diminishedGalleryValue: correctRoyalty?.doesDiminish
          ? correctRoyalty?.diminishedGalleryValue
          : '',
        diminishedDate: correctRoyalty?.doesDiminish
          ? correctRoyalty?.diminishedDate
          : '',
        galleryRoyalty: item.galleryRoyalty,
        workId: item.workId,
      };
    });

  const uploadContractRider = async () => {
    if (contractRiderFromStore.fileName) {
      const filename = `temp-riders/${sellerUser?.id}_${finalForm?.items[0]?.workId}.pdf`;
      await uploadBase64Async(contractRiderFromStore.base64, filename);
      return filename;
    }
    return '';
  };

  const uploadInvoice = async () => {
    if (invoiceDataUri) {
      const fileExtension = invoiceFileName.substring(
        invoiceFileName.lastIndexOf('.') + 1,
        invoiceFileName.length,
      );
      const fileName = `temp-invoices/${sellerUser?.id}_${finalForm.items[0].workId}.${fileExtension}`;
      if (Platform.OS !== 'web') {
        const mimeType = mime.getType(invoiceFileName) || 'application/pdf';
        await uploadBase64Async(invoiceBase64, fileName, mimeType);
      } else {
        await uploadDataUriAsync(invoiceDataUri, fileName);
      }
      return fileName;
    }
    return '';
  };

  const getVariables = async () => {
    const genInvoice =
      !!finalForm?.generateInvoice &&
      finalForm?.currency === CurrencyType.USD &&
      !transIsRetro;
    const passFees = finalForm!.passFeesToBuyer && genInvoice;

    return {
      ...finalForm,
      email: finalForm.email.toLocaleLowerCase(),
      callingUserId: currentUser?.id,
      contractRider: await uploadContractRider(),
      contractSignDate,
      contractTimezone,
      contractVersion,
      createDate: new Date().toISOString(),
      createDateTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      expirationTime: finalForm.doesExpire
        ? formatExpirationTime(finalForm.expirationTime)
        : '',
      generateInvoice: genInvoice,
      invoiceFileName: await uploadInvoice(),
      isMobile: Platform.OS === 'ios',
      isRetro: transIsRetro,
      items: assembleItems(),
      feesPassed: passFees ? stripeFeesPassedOn : 0,
      quickbooksInvoice: genInvoice && !!finalForm?.quickbooksInvoice,
      sellerId: sellerUser?.id,
      shipping: genInvoice ? currency(finalForm!.shipping).intValue : 0,
      tax: genInvoice ? currency(finalForm!.tax).intValue : 0,
      userAgentInfo: getUserAgentInfo(),
    };
  };

  const generateSalesAgreement = async () => {
    dispatch(setContractLoading(true));
    const gsaVariables = await getVariables();
    await callGenerateSalesAgreement({
      variables: { transferWorkFinalForm: gsaVariables },
    }).then((result) => {
      const response = result.data?.response;

      if (response.includes('transaction-details')) {
        dispatch(setContractLoading(false));
        dispatch(clearRoyalties());
        dispatch(setDefaultsInitialized(false));

        setCurrentStep(0);

        const split = response.toString().split('/transaction-details/');
        const tokenSplit = split[1].split('?authToken=');
        if (tokenSplit && tokenSplit.length >= 1) {
          const urlLink = tokenSplit[1]
            ? `${SITE_BASE_URL}/transaction-details/${tokenSplit[0]}?authToken=${tokenSplit[1]}`
            : null;
          sellerBuyerLink(urlLink);
          navigation.navigate('TransactionDetail', {
            transactionId: tokenSplit[0],
          });
        } else navigation.navigate('Collection');
      } else {
        // we got an error
        createTransactionErrorVar(response);
        setCurrentStep(2);
      }
    });
  };

  return { generateSalesAgreement, getVariables };
};

export const useIsCollectorRetroTrans = (): boolean => {
  const authUser = useContext(UserContext);
  const works = useReactiveVar(worksVar);

  if (!works || works.length > 1) return false;

  return (
    works[0]?.certificationStatus === CertificationStatus.adminApproved &&
    works[0].artist.id === authUser?.uid
  );
};

export const useSetupWorks = (transactionDetailsSetValue) =>
  useCallback(
    (listOfWorkData) => {
      let itemsForForm: ISingleWork[] = [];
      listOfWorkData.forEach((work) => {
        itemsForForm = [
          ...itemsForForm,
          {
            artistRoyalty: getRoyaltyByType(
              work.royalties,
              RoyaltyType.artist,
              false,
            ),
            artworkValue: 0,
            discount: 0,
            discountApplied: false,
            galleryRoyalty: getRoyaltyByType(
              work.royalties,
              RoyaltyType.gallery,
              false,
            ),
            totalRoyalty: getCombinedRoyalty(work.royalties, false),
            workId: work.id,
          },
        ];
      });
      worksVar(listOfWorkData);
      transactionDetailsSetValue('items', itemsForForm);
    },
    [transactionDetailsSetValue],
  );

export const useSetupDiminishedRoyalties = (works: WorkWithObject[]) => {
  const dispatch = useDispatch();
  const isInitialized = useSelector(getDefaultsInitialized);

  const formattedRoyalties: any[] = [];

  works.forEach((work) => {
    const diminishedRoyalties = work.royalties.filter(
      (royalty) => !!royalty.diminishedDate && !!royalty.diminishedValue,
    );

    const formattedRoyalty = {
      doesDiminish: false,
      diminishedArtistValue: '',
      diminishedDate: '',
      diminishedGalleryValue: '',
      workId: '',
    };

    diminishedRoyalties.forEach((royalty) => {
      if (royalty.type === RoyaltyType.artist) {
        formattedRoyalty.doesDiminish = true;
        formattedRoyalty.diminishedArtistValue = royalty.diminishedValue!;
        formattedRoyalty.diminishedDate = differenceInYears(
          new Date(royalty.diminishedDate!),
          new Date(work.dateRegistered),
        ).toString();
        formattedRoyalty.diminishedGalleryValue =
          formattedRoyalty?.diminishedGalleryValue || '';
        formattedRoyalty.workId = work.id;
      } else if (royalty.type === RoyaltyType.gallery) {
        formattedRoyalty.doesDiminish = true;
        formattedRoyalty.diminishedArtistValue =
          formattedRoyalty?.diminishedArtistValue || '';
        formattedRoyalty.diminishedDate = differenceInYears(
          new Date(royalty.diminishedDate!),
          new Date(work.dateRegistered),
        ).toString();
        formattedRoyalty.diminishedGalleryValue = royalty.diminishedValue!;
        formattedRoyalty.workId = work.id;
      }
    });

    if (formattedRoyalty.doesDiminish) {
      formattedRoyalties.push(formattedRoyalty);
    }
  });

  if (!isInitialized) {
    dispatch(setDefaultsInitialized(true));
    formattedRoyalties.forEach((formattedRoyalty) => {
      dispatch(setDiminishedRoyalty(formattedRoyalty));
    });
  }
};
