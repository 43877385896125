import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { GET_ARTWORK_PUBLIC_VIEW } from 'api/requests.v2';
import { FairchainLogo } from 'components/graphics';
import { ErrorMessage } from 'components/shared/error';
import { CenteredImage } from 'components/shared/image';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import { SpinLoader } from 'components/shared/loader';
import {
  GraphikSemiTextHuge,
  GraphikSemiTextLg,
  GraphikTextLarge,
  GraphikTextSm,
  IBMTextLarge,
} from 'components/shared/styled';
import { AssetType } from 'constants/enums';
import { ArtworkPublicViewRouteProp, FCStackNavProp } from 'navigation';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Image, View, ViewStyle } from 'react-native';
import { WorkPublicView, getDisplayName } from 'types';
import { formatEditionText, getWorkDimensions } from 'utilities';
import useTailwindResponsive, {
  getResponsiveSizes,
} from 'utilities/TailwindResponsive';
import { Provenance } from '../artworkDetails/components/Provenance';
import { ArtworkPublicViewMobWeb } from './ArtworkPublicViewMobWeb';
import { formatPageTitle } from './utils';

interface IArtworkPublicViewProps {
  route: ArtworkPublicViewRouteProp;
}

export const ArtworkPublicView: FunctionComponent<IArtworkPublicViewProps> = ({
  route,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const navigation = useNavigation<FCStackNavProp>();
  const [work, setWork] = useState<WorkPublicView | null>(null);
  const [sigSize, setSigSize] = useState<number[]>([]);

  const displayName = getDisplayName(work?.owner);

  const sizes = getResponsiveSizes();

  const headerCopy =
    work?.assetType === AssetType.Collectible
      ? 'This page verifies that the work has been registered on Fairchain. This work is accompanied by a secure blockchain-backed digital certificate of title and authenticity certifying that the creator declares the artwork listed below to be an authentic, original and unique piece of their own creative efforts.'
      : 'This page verifies that the work has been registered on Fairchain. Purchase of this work is accompanied by a secure blockchain-backed digital certificate of title and authenticity certifying that the artist or their estate declares the artwork listed below to be an authentic, original and unique piece of art of their own creative efforts and executed by them, the undersigned.';

  const isRedactedView =
    !work?.hasPublicCertificate && work?.assetType === AssetType.Collectible;

  const assetIdentifier =
    work?.assetType === AssetType.Collectible ? 'Item' : 'Artwork';

  const customInfoStyles: ViewStyle = {
    width:
      work?.publicViewOptions?.col1 && !sizes.mob && !sizes.mobWeb
        ? work.publicViewOptions.col1
        : 'auto',
  };

  const customImageStyles: ViewStyle = {
    width:
      work?.publicViewOptions?.col2 && !sizes.mob && !sizes.mobWeb
        ? work.publicViewOptions.col2
        : 'auto',
  };

  const tailwindFlexInfo =
    work?.publicViewOptions?.col1 && !sizes.mob && !sizes.mobWeb
      ? ''
      : 'flex-1';

  const tailwindFlexImage = work?.publicViewOptions?.col2 || 'flex-1';

  const imagePaddingTop =
    work?.publicViewOptions?.paddingTop && !sizes.mob && !sizes.mobWeb
      ? work.publicViewOptions.paddingTop
      : '32px';

  const headerCopyWidth = work?.publicViewOptions?.col1
    ? work.publicViewOptions.col1
    : '50%';

  const { error: workError } = useQuery(GET_ARTWORK_PUBLIC_VIEW, {
    variables: {
      artworkId: route.params.workId,
    },
    onCompleted: async (data) => {
      if (
        !data ||
        !data.response ||
        (!data.response.hasPublicCertificate &&
          data.response.assetType !== AssetType.Collectible)
      )
        navigation.push('NotFound', { error: 'not-public' });
      if (!data.response.certified)
        navigation.push('NotFound', {
          error: 'Cannot publicly view an uncertified work',
        });
      setWork(data.response);

      const newTitle = formatPageTitle(data.response);
      navigation.setOptions({ title: newTitle });
    },
    onError: () => {
      navigation.push('NotFound', { error: 'not-public' });
    },
  });

  useEffect(() => {
    if (work?.artist.signature) {
      Image.getSize(work?.artist.signature, (width, height) => {
        let x;
        if (width > 300) {
          x = width / 300;
          setSigSize([width / x, height / x]);
        } else {
          x = 300 / width;
          setSigSize([width * x, height * x]);
        }
      });
    }
  }, [work]);

  if (workError) return <ErrorMessage error={workError} />;

  if (!work) return <SpinLoader />;

  if (sizes.mobWeb)
    return (
      <ArtworkPublicViewMobWeb
        work={work}
        displayName={displayName}
        sigSize={sigSize}
        isRedactedView={isRedactedView}
      />
    );

  return (
    <View
      style={TailwindResponsive(
        `bg-Light1 flex-1 p-16 mob:p-4 mobWeb:p-4 mobWeb:pb-12`,
      )}
    >
      <ExternalLink link="https://fairchain.art/">
        <FairchainLogo />
      </ExternalLink>

      <GraphikSemiTextLg style={TailwindResponsive('my-8 mobWeb:w-full')}>
        Certificate of Title and Authenticity — Public View
      </GraphikSemiTextLg>

      <GraphikTextSm
        style={{
          ...TailwindResponsive(`mb-8 mobWeb:w-full`),
          width: headerCopyWidth,
        }}
      >
        {headerCopy}
      </GraphikTextSm>

      <View
        style={TailwindResponsive(
          'flex flex-1 flex-row mobWeb:flex-col-reverse',
        )}
      >
        <View
          style={{
            ...TailwindResponsive(`${tailwindFlexInfo} pr-8`),
            ...customInfoStyles,
          }}
        >
          <View style={TailwindResponsive('mb-1')}>
            {work.assetType === AssetType.Artwork && (
              <GraphikSemiTextHuge>
                {work?.artist.artistName ??
                  `${work?.artist.firstName} ${work?.artist.lastName}`}
              </GraphikSemiTextHuge>
            )}
            {work.assetType === AssetType.Collectible && (
              <GraphikSemiTextHuge>
                {getDisplayName(work?.artist)}
              </GraphikSemiTextHuge>
            )}
            <GraphikSemiTextHuge>{work?.title}</GraphikSemiTextHuge>
            <GraphikSemiTextHuge>{work?.yearProduced}</GraphikSemiTextHuge>
          </View>

          <View style={TailwindResponsive('mb-10')}>
            {work.assetType === AssetType.Artwork && (
              <GraphikTextLarge>{getWorkDimensions(work)}</GraphikTextLarge>
            )}
            {work.assetType === AssetType.Collectible && (
              <GraphikTextLarge>
                {work.collectible?.measurements}
              </GraphikTextLarge>
            )}
            {!!formatEditionText(work) && (
              <GraphikTextLarge>{formatEditionText(work)}</GraphikTextLarge>
            )}
            {work.assetType === AssetType.Artwork && (
              <GraphikTextLarge>{work?.medium}</GraphikTextLarge>
            )}
            {work.assetType === AssetType.Collectible && (
              <GraphikTextLarge>{work.collectible?.materials}</GraphikTextLarge>
            )}
          </View>

          {work.assetType === AssetType.Collectible &&
            work.collectible?.contributors &&
            work.collectible?.contributors?.length > 0 && (
              <View style={TailwindResponsive('mb-10')}>
                <GraphikSemiTextLg>Contributors</GraphikSemiTextLg>
                {work.collectible?.contributors?.map((contributor) => (
                  <GraphikTextLarge key={contributor.id}>
                    {contributor.firstName} {contributor.lastName},{' '}
                    {contributor.role}
                  </GraphikTextLarge>
                ))}
              </View>
            )}

          {/* Making the strong assumption that isRedactedView is only true for Collectibles */}
          {/* Therefore this should still always show for publicly viewable Artwork */}
          {!isRedactedView && (
            <View style={TailwindResponsive('mb-10')}>
              <GraphikSemiTextLg>
                Fairchain {assetIdentifier} ID
              </GraphikSemiTextLg>
              <IBMTextLarge>{work?.workIdentifier}</IBMTextLarge>
            </View>
          )}

          <Provenance
            provenance={work ? work.provenance : []}
            isRedactedView={isRedactedView}
          />

          {!isRedactedView && (
            <View style={TailwindResponsive('mb-10')}>
              <GraphikSemiTextLg>Title Manager</GraphikSemiTextLg>
              <GraphikTextLarge>{displayName}</GraphikTextLarge>
            </View>
          )}

          <View style={TailwindResponsive(`items-start mobWeb:h-96`)}>
            {work?.artist.signature && sigSize.length > 0 && (
              <View style={{ height: sigSize[1], minWidth: sigSize[0] }}>
                <Image
                  source={{ uri: work?.artist.signature }}
                  style={{
                    resizeMode: 'contain',
                    height: '100%',
                  }}
                />
                <GraphikTextSm>Artist&apos;s Signature</GraphikTextSm>
              </View>
            )}
          </View>
        </View>

        {work.embeddedCode ? (
          <View
            style={{
              ...TailwindResponsive(
                `${tailwindFlexImage} w-96 lg:w-165 mob:w-full`,
              ),
              ...customImageStyles,
            }}
          >
            <View
              style={{
                ...TailwindResponsive('p-8'),
                backgroundColor:
                  work?.publicViewOptions?.imageBgColor || '#F5F5F5',
                paddingTop: imagePaddingTop,
              }}
            >
              <div
                style={TailwindResponsive(`${tailwindFlexImage}`)}
                className="content"
                dangerouslySetInnerHTML={{ __html: work.embeddedCode }}
              />
            </View>
          </View>
        ) : (
          <View
            style={TailwindResponsive(
              `flex-1 bg-Light5 p-8 w-96 h-96 lg:w-165 lg:h-165 mob:p-4 mob:w-full mobWeb:mb-8 mobWeb:p-4 mobWeb:w-full`,
            )}
          >
            <CenteredImage uri={work?.images[0]} />
          </View>
        )}
      </View>
    </View>
  );
};
