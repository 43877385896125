import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DiminishedRoyalty {
  diminishedArtistValue: string;
  diminishedGalleryValue: string;
  diminishedDate: string;
  doesDiminish: boolean;
  workId: string;
}

export interface DateChangeInput {
  diminishedDate: string;
  workId: string;
}

interface FormSliceErrors {
  diminishedRoyalties: boolean;
}

export interface IFormState {
  defaultsInitialized: boolean;
  diminishedRoyalties: DiminishedRoyalty[];
  errors: FormSliceErrors;
}

const initialState: IFormState = {
  defaultsInitialized: false,
  diminishedRoyalties: [],
  errors: { diminishedRoyalties: false },
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setDiminishedRoyalty: (state, action: PayloadAction<DiminishedRoyalty>) => {
      if (!state.diminishedRoyalties)
        state.diminishedRoyalties = initialState.diminishedRoyalties;

      const index = state.diminishedRoyalties.findIndex(
        (royalty) => royalty?.workId === action.payload.workId,
      );

      if (index >= 0) {
        const royalty = state.diminishedRoyalties[index]!;
        state.diminishedRoyalties[index] = {
          ...royalty,
          diminishedArtistValue: action.payload.diminishedArtistValue,
          diminishedGalleryValue: action.payload.diminishedGalleryValue,
        };
      } else {
        state.diminishedRoyalties.push(action.payload);
      }
    },
    setDiminishedRoyaltyDate: (
      state,
      action: PayloadAction<DateChangeInput>,
    ) => {
      if (!state.diminishedRoyalties)
        state.diminishedRoyalties = initialState.diminishedRoyalties;

      const index = state.diminishedRoyalties.findIndex(
        (royalty) => royalty?.workId === action.payload.workId,
      );

      if (index >= 0) {
        const royalty = state.diminishedRoyalties[index]!;
        state.diminishedRoyalties[index] = {
          ...royalty,
          diminishedDate: action.payload.diminishedDate,
        };
      } else {
        state.diminishedRoyalties.push({
          diminishedArtistValue: '0',
          diminishedGalleryValue: '0',
          diminishedDate: action.payload.diminishedDate,
          doesDiminish: true,
          workId: action.payload.workId,
        });
      }
    },
    clearRoyalties: (state) => ({
      ...state,
      diminishedRoyalties: [],
    }),
    setDefaultsInitialized: (state, action: PayloadAction<boolean>) => {
      state.defaultsInitialized = action.payload;
    },
    setDiminishedRoyaltiesErrors: (state, action: PayloadAction<boolean>) => {
      if (!state.errors) state.errors = initialState.errors;
      state.errors = { diminishedRoyalties: action.payload };
    },
  },
});

const { actions, reducer } = formSlice;
export const {
  setDiminishedRoyalty,
  setDiminishedRoyaltyDate,
  clearRoyalties,
  setDefaultsInitialized,
  setDiminishedRoyaltiesErrors,
} = actions;
export { reducer as formReducer };

export type FormsState = ReturnType<typeof reducer>;
export type FormAction =
  | typeof setDiminishedRoyalty
  | typeof setDiminishedRoyaltyDate
  | typeof clearRoyalties
  | typeof setDiminishedRoyaltiesErrors
  | typeof setDefaultsInitialized;
