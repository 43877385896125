import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { RoundedButton } from 'components/shared/button';
import { Checkbox } from 'components/shared/checkbox';
import { DefaultTextInput } from 'components/shared/form';
import {
  GraphikMediumTextSm,
  GraphikSemiTextMd,
  GraphikTextTiny,
} from 'components/shared/styled';
import { RegistrationType, UserRole } from 'constants/enums';
import { FCStackNavProp } from 'navigation';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Pressable, View } from 'react-native';
import { useSelector } from 'react-redux';
import { RegisterStore } from 'store/registerWork/apollo';
import { getUserRole } from 'store/user';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { RegisterWorkFields } from '../Helpers';
import { useCanRegisterUncertifiedOnly } from '../Hooks';
import { AdditionalInformation } from '../components/AdditionalInformation';
import { ArtistRegistration } from '../components/ArtistRegistration';
import { Details } from '../components/Details';
import { RegistrationSelection } from '../components/RegistrationSelection';
import { useGetFormDetails } from '../forms/artist-registration.form';

export const WorkDetails: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const navigation = useNavigation<FCStackNavProp>();
  const userRole = useSelector(getUserRole);

  const isForcedUncertified = useCanRegisterUncertifiedOnly();

  const [showRepoRights, setShowRepoRights] = useState(false);

  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const arForm = useGetFormDetails(detailsForm);

  const keyboardScrollRef = useReactiveVar(
    RegisterStore.Vars.keyboardScrollRef,
  );
  const registrationType = useReactiveVar(RegisterStore.Vars.registrationType);

  useEffect(() => {
    if (arForm && arForm.formState) {
      const firstError = Object.keys(arForm.formState.errors).reduce<any>(
        (field, a) => (arForm.formState.errors[field] ? field : a),
        null,
      );
      if (firstError) arForm.setFocus(firstError);
    }
  }, [arForm, arForm?.formState]);

  if (!keyboardScrollRef) return null;

  return (
    <View>
      <View
        style={TailwindResponsive(
          `-mb-12 flex-row-reverse mob:mb-12 mobWeb:mb-12 mobWeb:flex-col`,
        )}
      >
        <View style={TailwindResponsive('w-48')}>
          <RoundedButton
            label="Import From Artlogic"
            onPress={() => {
              navigation.push('Import');
            }}
            backgroundColor={Styles.Colours.Dark1}
            color={Styles.Colours.Light1}
            buttonStyle={{ borderRadius: 0 }}
            paddingHorizontal={20}
          />
        </View>
      </View>

      <View style={TailwindResponsive('w-2/5 mobWeb:w-full')}>
        {!isForcedUncertified && <RegistrationSelection />}

        {(userRole !== UserRole.Artist ||
          registrationType === RegistrationType.Uncertified) &&
          userRole !== UserRole.Estate && <ArtistRegistration />}
        <Details />

        <AdditionalInformation
          control={arForm?.control}
          keyboardScrollRef={keyboardScrollRef}
          name={RegisterWorkFields.additionalInfo}
          placeholder="Work Description, Installation Information, etc."
          title="Additional Artwork Information (Optional)"
        />

        {registrationType === RegistrationType.Uncertified &&
          userRole === UserRole.Collector && (
            <>
              <GraphikSemiTextMd style={TailwindResponsive(`mt-8 mb-16`)}>
                Private Inventory Information
              </GraphikSemiTextMd>

              <GraphikMediumTextSm style={TailwindResponsive(`mt-8`)}>
                Purchase History Details (Optional)
              </GraphikMediumTextSm>

              <View style={TailwindResponsive(`mt-4 mb-16`)}>
                <Controller
                  control={arForm?.control}
                  name={RegisterWorkFields.purchaseDate}
                  render={({ field: { onChange, value, ref } }) => (
                    <DefaultTextInput
                      handleChange={(input) => {
                        arForm?.clearErrors(RegisterWorkFields.purchaseDate);
                        onChange(input);
                      }}
                      inputRef={ref}
                      label="Purchase Date"
                      testId="RegisterPurchaseDate"
                      value={value}
                    />
                  )}
                />

                <Controller
                  control={arForm?.control}
                  name={RegisterWorkFields.seller}
                  render={({ field: { onChange, value, ref } }) => (
                    <DefaultTextInput
                      handleChange={(input) => {
                        arForm?.clearErrors(RegisterWorkFields.seller);
                        onChange(input);
                      }}
                      inputRef={ref}
                      label="Seller"
                      testId="RegisterSeller"
                      value={value}
                    />
                  )}
                />

                <Controller
                  control={arForm?.control}
                  name={RegisterWorkFields.purchaseAmount}
                  render={({ field: { onChange, value, ref } }) => (
                    <DefaultTextInput
                      handleChange={(input) => {
                        arForm?.clearErrors(RegisterWorkFields.purchaseAmount);
                        onChange(input);
                      }}
                      inputRef={ref}
                      label="Purchase Amount"
                      testId="RegisterPurchaseAmount"
                      value={value}
                    />
                  )}
                />

                <Controller
                  control={arForm?.control}
                  name={RegisterWorkFields.location}
                  render={({ field: { onChange, value, ref } }) => (
                    <DefaultTextInput
                      handleChange={(input) => {
                        arForm?.clearErrors(RegisterWorkFields.location);
                        onChange(input);
                      }}
                      inputRef={ref}
                      label="Location"
                      testId="RegisterLocation"
                      value={value}
                    />
                  )}
                />
              </View>
            </>
          )}

        <AdditionalInformation
          control={arForm?.control}
          keyboardScrollRef={keyboardScrollRef}
          name={RegisterWorkFields.privateNotes}
          placeholder="Document any other details related to the work. These notes will only ever be visible to you. If this work is ever sold, these details will not be shared."
          title="Private Notes (Optional)"
        />

        {registrationType !== RegistrationType.Uncertified && (
          <View
            style={{
              ...TailwindResponsive(
                'border-t border-Dark1 pt-12 pb-12 mob:pt-10 mob:mb-10',
              ),
              zIndex: -1,
            }}
          >
            <GraphikSemiTextMd>
              Limited Digital Reproduction Rights
            </GraphikSemiTextMd>
            <View style={TailwindResponsive('mt-12')}>
              <Controller
                control={arForm?.control}
                name="digitalRepoRights"
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    isChecked={value}
                    onPress={() => {
                      onChange(!value);
                    }}
                    label="Grant Limited Digital Reproduction Rights to Buyer"
                  />
                )}
              />
            </View>
            <GraphikTextTiny style={TailwindResponsive('ml-10')}>
              Grant a limited license allowing certificate holder to display a
              digital reproduction of the artwork in virtual environments for
              non-commercial use.
            </GraphikTextTiny>
            <View style={TailwindResponsive('ml-10 mt-5')}>
              {!showRepoRights ? (
                <Pressable
                  onPress={() => {
                    setShowRepoRights(true);
                  }}
                >
                  <GraphikTextTiny style={TailwindResponsive(`underline`)}>
                    View Reproduction Rights
                  </GraphikTextTiny>
                </Pressable>
              ) : (
                <GraphikTextTiny>
                  Subject to any terms set forth elsewhere in this Certificate,
                  Owner has a limited, personal, non-exclusive,
                  non-transferrable (except in connection with a Transfer of the
                  Artwork), royalty-free, revocable (as provided below),
                  worldwide right and license, while Owner continues to hold
                  this certificate in their Fairchain account, to display a
                  digital reproduction of the Artwork publicly: (a) for purposes
                  of offering the Artwork for sale; and (b) for non-commercial
                  purposes within virtual shared worlds and other virtual
                  environments such as that currently known as the “Metaverse”;
                  provided that, in each case, Owner shall not display the
                  Artwork in more than one context or instance at any given
                  time. Artist may suspend or revoke the foregoing license if
                  Owner materially breaches this license, the Transfer
                  Agreement, or the Fairchain Terms of Use, or uses a digital
                  reproduction of the Artwork that is unacceptable to Artist.
                  This license includes the right of the Owner to have such
                  rights exercised by a third party on behalf of Owner such as a
                  third-party virtual reality service provider.
                </GraphikTextTiny>
              )}
            </View>
          </View>
        )}
      </View>
    </View>
  );
};
