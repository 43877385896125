import { makeVar } from '@apollo/client';
import { RegistrationType } from 'constants/enums';
import { UseFormReturn } from 'react-hook-form';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { RegistrationForm, RoyaltyForm } from 'types/RegisterWork';
import { SupportingDoc } from 'types/SupportingDocs';
import { IImagePickerResponse } from '../../types/Images';

/// REGISTER STORE
/// Handles all the apollo variables
export class RegisterStore {
  public static Vars = {
    autoArtistSelected: makeVar<boolean>(false),
    currentStep: makeVar<number>(0),
    detailsForm: makeVar<RegistrationForm | null>(null),
    Images: {
      errors: makeVar<boolean>(false),
      image1: makeVar<IImagePickerResponse | null>(null),
      image2: makeVar<IImagePickerResponse | null>(null),
      image3: makeVar<IImagePickerResponse | null>(null),
      image4: makeVar<IImagePickerResponse | null>(null),
      modalImage: makeVar<string>('image1'),
      primary: makeVar<string>(''),
      showModal: makeVar<boolean>(false),
    },
    keyboardScrollRef: makeVar<React.RefObject<KeyboardAwareScrollView> | null>(
      null,
    ),
    loading: makeVar<boolean>(false),
    registrationError: makeVar<string | null>(null),
    registrationType: makeVar<RegistrationType>(RegistrationType.Certified),
    royaltyForm: makeVar<UseFormReturn<RoyaltyForm, any> | null>(null),
    saveLoading: makeVar<boolean>(false),
    savingDraft: makeVar<boolean>(false),
    showCancelModal: makeVar<boolean>(false),
    supportingDocsVar: makeVar<SupportingDoc[]>([]),
  };

  static Reset() {
    RegisterStore.Vars.autoArtistSelected(false);
    RegisterStore.Vars.currentStep(0);
    RegisterStore.Vars.detailsForm(null);
    RegisterStore.Vars.Images.errors(false);
    RegisterStore.Vars.Images.image1(null);
    RegisterStore.Vars.Images.image2(null);
    RegisterStore.Vars.Images.image3(null);
    RegisterStore.Vars.Images.image4(null);
    RegisterStore.Vars.Images.modalImage('image1');
    RegisterStore.Vars.Images.primary('');
    RegisterStore.Vars.Images.showModal(false);
    RegisterStore.Vars.keyboardScrollRef(null);
    RegisterStore.Vars.loading(false);
    RegisterStore.Vars.registrationError(null);
    RegisterStore.Vars.registrationType(RegistrationType.Certified);
    RegisterStore.Vars.saveLoading(false);
    RegisterStore.Vars.savingDraft(false);
    RegisterStore.Vars.supportingDocsVar([]);
  }
}
