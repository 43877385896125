import { useReactiveVar } from '@apollo/client';
import { RoyaltyInput } from 'components/shared/form';
import {
  GraphikMediumTextMd,
  GraphikSemiTextMd,
  GraphikTextSm,
  GraphikTextTiny,
} from 'components/shared/styled';
import { AssetType } from 'constants/enums';
import React, { FunctionComponent, useEffect } from 'react';
import { Controller, useController, useFieldArray } from 'react-hook-form';
import { Platform, View } from 'react-native';
import { RegisterStore } from 'store/registerWork/apollo';
import Styles from 'style';
import { ContributorRoyalty } from 'types/RegisterWork';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import {
  calculateTotalRoyalty,
  formatRoyaltyInput,
} from '../../transferWork/Helpers';
import { useIsBrand, useIsGallery } from '../Hooks';
import { useGetFormDetails } from '../forms/artist-registration.form';

export const FixedRoyalty: FunctionComponent = () => {
  const royalForm = useReactiveVar(RegisterStore.Vars.royaltyForm);
  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const arForm = useGetFormDetails(detailsForm);
  const isCollectible = detailsForm?.assetType === AssetType.Collectible;

  const { fields, replace } = useFieldArray({
    control: royalForm?.control,
    name: 'contributorRoyalties',
  });

  const { TailwindResponsive } = useTailwindResponsive();
  const isBrand = useIsBrand();
  const isGallery = useIsGallery();

  useEffect(() => {
    if (isBrand) {
      const contributorRoyaltiesCache = {};

      fields.forEach((contributorRoyalty) => {
        contributorRoyaltiesCache[contributorRoyalty.email] =
          contributorRoyalty.royalty;
      });

      // Sync royalty form with any updates from the ar form
      const { contributors } = arForm.getValues();
      const contributorRoyalties: ContributorRoyalty[] = [];
      contributors.forEach((contributor) => {
        const contributorName = `${contributor.firstName} ${contributor.lastName}`;
        const cachedRoyalty = contributorRoyaltiesCache[contributor.email];
        contributorRoyalties.push({
          name: contributorName,
          email: contributor.email,
          royalty: cachedRoyalty || '0',
        });
      });

      replace(contributorRoyalties);
    }
  }, []);

  const {
    field: { onChange: setArtistRoyalty, value: artistRoyalty },
  } = useController({ name: 'artistRoyalty', control: royalForm?.control });
  const {
    field: { onChange: setBrandRoyalty, value: brandRoyalty },
  } = useController({ name: 'brandRoyalty', control: royalForm?.control });
  const {
    field: { onChange: setGalleryRoyalty, value: galleryRoyalty },
  } = useController({ name: 'galleryRoyalty', control: royalForm?.control });
  const {
    field: { onChange: setTotalRoyalty, value: totalRoyalty },
  } = useController({ name: 'totalRoyalty', control: royalForm?.control });

  const contributorRoyaltiesWatched = royalForm?.watch('contributorRoyalties');

  const updateTotalRoyalty = () => {
    let newTotalRoyalty = '0';

    if (isBrand && isCollectible) {
      const contributorRoyalties: ContributorRoyalty[] | undefined =
        contributorRoyaltiesWatched;
      let contributorsRoyaltyPercentage = 0;
      if (contributorRoyalties) {
        contributorsRoyaltyPercentage = contributorRoyalties.reduce(
          (total, contributorRoyalty) => {
            const theRoyalty = Number.isNaN(
              parseFloat(contributorRoyalty.royalty),
            )
              ? 0
              : parseFloat(contributorRoyalty.royalty);
            return total + theRoyalty;
          },
          0,
        );
      }

      newTotalRoyalty = calculateTotalRoyalty(
        brandRoyalty,
        contributorsRoyaltyPercentage.toString(),
      );
      if (newTotalRoyalty !== totalRoyalty) {
        setTotalRoyalty(newTotalRoyalty);
        royalForm?.trigger('totalRoyalty');
      }
    } else {
      let artistPercentage = parseFloat(artistRoyalty);
      let galleryPercentage = parseFloat(galleryRoyalty);
      if (Number.isNaN(artistPercentage) && Number.isNaN(galleryPercentage)) {
        setTotalRoyalty('0');
        return;
      }
      if (Number.isNaN(artistPercentage)) artistPercentage = 0;
      if (Number.isNaN(galleryPercentage)) galleryPercentage = 0;

      newTotalRoyalty = calculateTotalRoyalty(artistRoyalty, galleryRoyalty);
    }

    if (newTotalRoyalty !== totalRoyalty) {
      setTotalRoyalty(newTotalRoyalty);
      royalForm?.trigger('totalRoyalty');
    }
  };

  useEffect(() => {
    updateTotalRoyalty();
  }, [
    artistRoyalty,
    brandRoyalty,
    contributorRoyaltiesWatched,
    galleryRoyalty,
    totalRoyalty,
    royalForm,
    setTotalRoyalty,
  ]);

  return (
    <View
      style={TailwindResponsive(
        'flex-row mt-6 mob:flex-col mobWeb:flex-col web:items-start mob:justify-center mobWeb:items-center',
      )}
    >
      {!isCollectible && (
        <View
          style={TailwindResponsive(
            'flex flex-row items-center justify-center mob:mb-10 mobWeb:mb-10',
          )}
        >
          {Platform.OS === 'ios' && (
            <View style={TailwindResponsive('pr-2 w-1/2')}>
              <GraphikTextSm>Artist Resale Commission Rate</GraphikTextSm>
            </View>
          )}
          <RoyaltyInput
            errorMessage={
              royalForm?.formState?.errors?.artistRoyalty?.message as string
            }
            handleChange={(input) => {
              royalForm?.clearErrors('artistRoyalty');
              const parsedInput = formatRoyaltyInput(input);
              setArtistRoyalty(parsedInput);
            }}
            label={
              Platform.OS === 'web' ? '* Artist Resale Commission' : undefined
            }
            style={TailwindResponsive(`mob:w-1/2`)}
            testID="ArtistRoyaltyInput"
            value={artistRoyalty}
          />
        </View>
      )}
      {isBrand && isCollectible && (
        <View
          style={TailwindResponsive(
            'flex flex-row items-center justify-center mob:mb-10 mobWeb:mb-10',
          )}
        >
          {Platform.OS === 'ios' && (
            <View style={TailwindResponsive('pr-2 w-1/2')}>
              <GraphikTextSm>Brand Resale Commission Rate</GraphikTextSm>
            </View>
          )}
          <RoyaltyInput
            errorMessage={
              royalForm?.formState?.errors?.brandRoyalty?.message as string
            }
            handleChange={(input) => {
              royalForm?.clearErrors('brandRoyalty');
              const parsedInput = formatRoyaltyInput(input);
              setBrandRoyalty(parsedInput);
            }}
            label={
              Platform.OS === 'web' ? '* Brand Resale Commission' : undefined
            }
            style={TailwindResponsive(`mob:w-1/2`)}
            testID="BrandRoyaltyInput"
            value={brandRoyalty}
          />
          {fields.map((item, index) => (
            <Controller
              key={item.id}
              control={royalForm?.control}
              name={`contributorRoyalties.${index}.royalty`}
              render={({ field: { onChange, value } }) => (
                <RoyaltyInput
                  value={value}
                  label={
                    Platform.OS === 'web'
                      ? `* ${item.name} Resale Commission`
                      : undefined
                  }
                  handleChange={(input) => {
                    const parsedInput = formatRoyaltyInput(input);
                    onChange(parsedInput);
                    updateTotalRoyalty();
                    royalForm?.clearErrors(
                      `contributorRoyalties.${index}.royalty`,
                    );
                  }}
                  style={TailwindResponsive('mob:w-1/2')}
                  testID="ContributorRoyaltyInput"
                  errorMessage={
                    royalForm?.formState?.errors?.contributorRoyalties?.[index]
                      ?.royalty?.message as string
                  }
                />
              )}
            />
          ))}
        </View>
      )}
      {isGallery && (
        <View
          style={TailwindResponsive(
            'flex flex-row items-center justify-center mob:mb-10 mobWeb:mb-10',
          )}
        >
          {Platform.OS === 'ios' && (
            <View style={TailwindResponsive('pr-2 w-1/2')}>
              <GraphikTextSm>Gallery Resale Commission Rate</GraphikTextSm>
            </View>
          )}
          <RoyaltyInput
            value={galleryRoyalty}
            label={
              Platform.OS === 'web' ? '* Gallery Resale Commission' : undefined
            }
            handleChange={(input) => {
              const parsedInput = formatRoyaltyInput(input);
              setGalleryRoyalty(parsedInput);
              royalForm?.clearErrors('galleryRoyalty');
            }}
            style={TailwindResponsive('mob:w-1/2')}
            testID="GalleryRoyaltyInput"
            errorMessage={
              royalForm?.formState?.errors?.galleryRoyalty?.message as string
            }
          />
        </View>
      )}
      {(isGallery || isBrand) && (
        <>
          <View style={TailwindResponsive(`flex mob:flex-row items-center`)}>
            {Platform.OS === 'ios' && (
              <View style={TailwindResponsive('mob:w-1/2')}>
                <GraphikSemiTextMd>
                  Total Resale Commission Rate
                </GraphikSemiTextMd>
              </View>
            )}
            <View
              style={TailwindResponsive(
                `flex mob:flex-row h-14 mb-2 items-center justify-center mob:w-1/2`,
              )}
            >
              <GraphikMediumTextMd>{`${totalRoyalty}%`}</GraphikMediumTextMd>
            </View>
            {Platform.OS === 'web' && (
              <GraphikTextTiny
                color={Styles.Colours.Dark3}
                style={TailwindResponsive(`px-5`)}
              >
                Total Resale Commission
              </GraphikTextTiny>
            )}
            {Platform.OS !== 'ios' && (
              <GraphikTextTiny
                style={{
                  ...TailwindResponsive(``),
                  color: Styles.Colours.Error,
                }}
              >
                {royalForm?.formState?.errors?.totalRoyalty?.message}
              </GraphikTextTiny>
            )}
          </View>
          {Platform.OS === 'ios' && (
            <GraphikTextTiny
              style={{
                ...TailwindResponsive(`h-24`),
                color: Styles.Colours.Error,
              }}
            >
              {royalForm?.formState?.errors?.totalRoyalty?.message}
            </GraphikTextTiny>
          )}
        </>
      )}
    </View>
  );
};

export default FixedRoyalty;
