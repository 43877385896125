import { useReactiveVar } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import { Checkbox } from 'components/shared/checkbox';
import { InvoiceAffordance } from 'components/shared/copy/InvoiceAffordance';
import { GraphikTextSm } from 'components/shared/styled';
import { Clickwrap } from 'components/views/transferWork/components/Clickwrap';
import { GiftOption } from 'components/views/transferWork/components/GiftOption';
import { SITE_BASE_URL } from 'constants/constants';
import React, { FunctionComponent } from 'react';
import { Linking, Platform, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getAgreedToTerms, setHasAgreed } from 'store/agreements';
import TDGQL, {
  useGetTransactionDetailsHook,
} from 'store/transactionDetails/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { AcceptAndCancel } from './AcceptAndCancel';

export const OfferAcceptance: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const route = useRoute();

  const transaction = useGetTransactionDetailsHook();

  const clickwrapAccepted = useReactiveVar(TDGQL.clickwrapAcceptedVar);

  const agreedToTerms = useSelector(getAgreedToTerms);
  const dispatch = useDispatch();

  const isAgreementsPage = route.name === 'Agreements';

  if (!transaction?.canAcceptOffer) return null;

  return (
    <>
      {!clickwrapAccepted && !transaction?.isRetro ? (
        <>
          <View style={TailwindResponsive(`mb-4`)}>
            <GiftOption />
          </View>
          {transaction.hasPayment && <InvoiceAffordance />}
        </>
      ) : (
        <View style={TailwindResponsive(`mt-16`)}>
          {transaction.hasPayment && <InvoiceAffordance />}
        </View>
      )}

      {transaction?.isClickwrap && !clickwrapAccepted && (
        <Clickwrap transactionId={transaction?.id} />
      )}

      {isAgreementsPage && (
        <View style={TailwindResponsive(`mob:my-3`)}>
          <View
            style={TailwindResponsive(`px-8 flex-row items-center mobWeb:mb-8`)}
          >
            <Checkbox
              isChecked={agreedToTerms}
              onPress={() => {
                dispatch(setHasAgreed(!agreedToTerms));
              }}
              size={18}
            />
            <GraphikTextSm>
              I agree to the{' '}
              <GraphikTextSm
                onPress={() => Linking.openURL(`${SITE_BASE_URL}/terms`)}
                style={TailwindResponsive(`underline`)}
              >
                Fairchain Terms of Service
              </GraphikTextSm>
              .
            </GraphikTextSm>
          </View>
        </View>
      )}

      {Platform.OS === 'web' && <AcceptAndCancel />}
    </>
  );
};

export default OfferAcceptance;
