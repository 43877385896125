import { GraphikMediumTextLg, GraphikTextTiny } from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { TextInput, View } from 'react-native';
import Styles from 'style';
import { Style } from 'tailwind-rn/dist';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IRoyaltyInputProps {
  editable?: boolean;
  errorMessage?: string;
  handleChange?: (value: string) => void;
  label?: string | undefined;
  style?: { [key: string]: string } | Style;
  testID?: string;
  value: string | undefined;
}

export const RoyaltyInput: FunctionComponent<IRoyaltyInputProps> = ({
  editable = true,
  errorMessage,
  handleChange,
  label,
  style,
  testID,
  value,
  ...props
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const baseStyle = style || {};

  return (
    <View style={TailwindResponsive(`flex flex-1 flex-col`)}>
      <View
        style={[
          TailwindResponsive(`bg-Gray1 flex-row h-14 w-48 web:mr-5 web:mb-2`),
          baseStyle,
        ]}
      >
        <View style={TailwindResponsive(`flex-1 items-center`)}>
          <TextInput
            editable={editable}
            clearTextOnFocus={value === '0'}
            onBlur={() => {
              if ((value === '' || value === '0') && handleChange) {
                handleChange('0');
              }
            }}
            onChangeText={handleChange}
            style={{
              ...TailwindResponsive(`text-center text-Dark1 w-full h-full`),
              fontSize: Styles.Fonts.FontSize.Medium,
              fontFamily: Styles.Fonts.FontFamily.SansMedium,
            }}
            testID={`${testID}_Input`}
            value={value}
            {...props}
          />
        </View>
        <View
          style={TailwindResponsive(`h-full px-3 items-center justify-center`)}
        >
          <GraphikMediumTextLg>%</GraphikMediumTextLg>
        </View>
      </View>

      <GraphikTextTiny color={Styles.Colours.Dark3}>{label}</GraphikTextTiny>
      <GraphikTextTiny
        style={{
          color: Styles.Colours.Error,
          ...TailwindResponsive(`max-w-fit`),
        }}
      >
        {errorMessage}
      </GraphikTextTiny>
    </View>
  );
};
