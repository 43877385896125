/* Copyright (C) 2020 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */
import { useQuery } from '@apollo/client';
import { GET_INBOX_ITEMS } from 'api/requests.v2';
import { ErrorMessage } from 'components/shared/error';
import { AppBar } from 'components/shared/footer';
import { SpinLoader } from 'components/shared/loader';
import { WorkflowPage } from 'components/shared/page';
import { GraphikSemiTextHuge } from 'components/shared/styled';
import { UserContext } from 'contexts';
import { orderBy } from 'lodash';
import React, { FunctionComponent, useContext } from 'react';
import { Platform, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { InboxGQL } from 'store/inbox/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { InboxDetails } from './InboxDetails';

export const Inbox: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const { TailwindResponsive } = useTailwindResponsive();

  const { loading, error: getBuyerTransactionsError } = useQuery(
    GET_INBOX_ITEMS,
    {
      fetchPolicy: 'network-only',
      variables: {
        buyerId: authUser?.uid,
      },
      onCompleted: (data) => {
        if (data && data.response) {
          const certWorks = data.response.works ?? [];
          const sortedCertWorks = orderBy(
            certWorks,
            ['certificationRequestedDate'],
            'desc',
          );
          InboxGQL.certifiedWorks(sortedCertWorks);

          const unsorted = data.response.transactions ?? [];
          const sortedTransactions = orderBy(
            unsorted,
            ['createDate'],
            ['desc'],
          );
          InboxGQL.transactions(sortedTransactions);
        }
      },
    },
  );

  if (loading) return <SpinLoader />;
  if (getBuyerTransactionsError)
    return <ErrorMessage error={getBuyerTransactionsError} />;

  if (Platform.OS !== 'web') {
    return (
      <SafeAreaView style={TailwindResponsive('flex-1 bg-Light1')}>
        <View style={TailwindResponsive('flex-1 p-4')}>
          <InboxDetails />
          <AppBar />
        </View>
      </SafeAreaView>
    );
  }

  return (
    <WorkflowPage>
      <View style={TailwindResponsive(`border-b`)}>
        <GraphikSemiTextHuge>Inbox</GraphikSemiTextHuge>
      </View>

      <InboxDetails />
    </WorkflowPage>
  );
};

export default Inbox;
