import { useQuery, useReactiveVar } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import { GET_ALL_BRANDS } from 'api/requests.v2';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { ErrorMessage } from 'components/shared/error';
import Autocomplete from 'components/shared/form/Autocomplete';
import DefaultTextInput from 'components/shared/form/DefaultTextInput';
import { SpinLoader } from 'components/shared/loader';
import {
  GraphikMediumTextSm,
  GraphikSemiTextMd,
} from 'components/shared/styled';
import { RegistrationType } from 'constants/enums';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';
import { RegisterStore } from 'store/registerWork/apollo';
import { AutocompleteItem, User } from 'types';
import { ArtworkRegistrationForm } from 'types/RegisterWork';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { RegisterWorkFields } from '../../Helpers';
import { useGetFormDetails } from '../../forms/artist-registration.form';

export const BrandRegistration: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const arForm = useGetFormDetails(detailsForm);
  const autoArtistSelected = useReactiveVar(
    RegisterStore.Vars.autoArtistSelected,
  );
  const registrationType = useReactiveVar(RegisterStore.Vars.registrationType);

  const [allBrands, setAllBrands] = useState<[User]>();
  const [autocompleteItems, setAutocompleteItems] =
    useState<[AutocompleteItem]>();

  const {
    loading,
    data,
    error: getAllBrandsError,
    refetch,
  } = useQuery(GET_ALL_BRANDS);

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  useEffect(() => {
    if (data) {
      setAutocompleteItems(
        data?.response.map((brand: User) => ({
          name: brand.brandName,
          id: brand.id,
        })),
      );
      setAllBrands(data?.response);
    }
  }, [data]);

  const setBrandObjectToFields = (selectedBrand: AutocompleteItem) => {
    const targetBrandObject = allBrands!.find(
      (brandObject) => brandObject.id === selectedBrand.id,
    );
    if (targetBrandObject) {
      RegisterStore.Vars.autoArtistSelected(true);
      Object.entries(targetBrandObject).forEach(([key, value]) =>
        arForm?.setValue(key as keyof ArtworkRegistrationForm, value || ''),
      );
      // Set email to fairchain to hide brand's email
      arForm?.setValue('email', 'support@fairchain.art');
      arForm?.trigger(['brandName', 'firstName', 'lastName', 'email']);
    }
  };

  if (getAllBrandsError) return <ErrorMessage />;

  return (
    <View style={TailwindResponsive(`mb-10 mobWeb:mb-0`)}>
      <View style={TailwindResponsive(`mb-4 mobWeb:mb-0`)}>
        {loading && (
          <View
            style={TailwindResponsive(
              `flex-1 items-center justify-center mb-10 mobWeb:mb-0`,
            )}
          >
            <SpinLoader />
          </View>
        )}

        {getAllBrandsError && <ErrorMessage />}

        {data && (
          <View>
            <GraphikSemiTextMd style={TailwindResponsive(`mb-16`)}>
              Brand
            </GraphikSemiTextMd>
            <Autocomplete
              placeholder="Search Registered Brands"
              data={autocompleteItems!}
              handleOptionPress={(input) => setBrandObjectToFields(input)}
              numCharsRequired={3}
            />
          </View>
        )}
      </View>

      <View style={{ zIndex: -1 }}>
        <View
          style={TailwindResponsive(
            'flex flex-row justify-between mt-2 mob:mt-8',
          )}
        >
          <GraphikMediumTextSm>Register New Brand</GraphikMediumTextSm>

          {autoArtistSelected && (
            <FairchainButton
              buttonStyle={TailwindResponsive(`justify-start self-start`)}
              label="clear"
              onPress={() => {
                RegisterStore.Vars.autoArtistSelected(false);
                arForm?.setValue('id', '');
                arForm?.setValue('brandName', '');
                arForm?.setValue('email', '');
                arForm?.setValue('firstName', '');
                arForm?.setValue('lastName', '');
              }}
              textStyle={TailwindResponsive(`underline`)}
            />
          )}
        </View>

        {autoArtistSelected ? (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.firstName}
            render={({ field: { value } }) => (
              <DefaultTextInput
                editable={false}
                label="* First Name of Primary Contact"
                testId="RegisterAutoBrandFirstName"
                value={value}
              />
            )}
          />
        ) : (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.firstName}
            render={({ field: { onChange, value, ref } }) => (
              <DefaultTextInput
                errorMessage={
                  arForm?.formState?.errors?.firstName?.message as string
                }
                handleChange={(input) => {
                  arForm?.clearErrors('firstName');
                  onChange(input);
                }}
                inputRef={ref}
                label="* First Name of Primary Contact"
                testId="RegisterBrandFirstName"
                value={value}
              />
            )}
          />
        )}

        {autoArtistSelected ? (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.lastName}
            render={({ field: { value } }) => (
              <DefaultTextInput
                editable={false}
                label="* Last Name of Primary Contact"
                testId="RegisterAutoBrandLastName"
                value={value}
              />
            )}
          />
        ) : (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.lastName}
            render={({ field: { onChange, value, ref } }) => (
              <DefaultTextInput
                errorMessage={
                  arForm?.formState?.errors?.lastName?.message as string
                }
                handleChange={(input) => {
                  arForm?.clearErrors('lastName');
                  onChange(input);
                }}
                inputRef={ref}
                label="* Last Name of Primary Contact"
                testId="RegisterBrandLastName"
                value={value}
              />
            )}
          />
        )}

        {autoArtistSelected ? (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.brandName}
            render={({ field: { value } }) => (
              <DefaultTextInput
                editable={false}
                label="Brand Name, if different from above"
                testId="RegisterAutoBrandName"
                value={value}
              />
            )}
          />
        ) : (
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.brandName}
            render={({ field: { onChange, value } }) => (
              <DefaultTextInput
                handleChange={(input) => {
                  onChange(input);
                }}
                label="Brand Name, if different from above"
                value={value}
              />
            )}
          />
        )}
        <Controller
          control={arForm?.control}
          name={RegisterWorkFields.email}
          render={({ field: { onChange, value, ref } }) => (
            <View
              style={TailwindResponsive(autoArtistSelected ? 'hidden' : '')}
            >
              <DefaultTextInput
                autoCapitalize="none"
                autoCorrect={false}
                inputMode="email"
                value={value}
                label={
                  registrationType === RegistrationType.Uncertified
                    ? 'Brand Primary Contact Email'
                    : '* Brand Primary Contact Email'
                }
                handleChange={(input) => {
                  arForm?.clearErrors('email');
                  onChange(input);
                }}
                inputRef={ref}
                errorMessage={
                  arForm?.formState?.errors?.email?.message as string
                }
                testId="RegisterBrandEmail"
              />
            </View>
          )}
        />
      </View>
    </View>
  );
};

export default BrandRegistration;
