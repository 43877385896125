import { useMutation, useQuery } from '@apollo/client';
import {
  GET_FOUR_DIGIT_NUMBER,
  SEND_AUTH_CODE,
  VALIDATE_AUTH_CODE,
} from 'api/requests.v2';
import ErrorIcon from 'components/graphics/ErrorIcon';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { Footer } from 'components/shared/footer';
import { WordmarkHeader } from 'components/shared/header';
import { SpinLoader } from 'components/shared/loader';
import { useAppStarter } from 'components/shared/page/AppStarter';
import { GraphikSemiTextHuge, GraphikTextSm } from 'components/shared/styled';
import { UserContext } from 'contexts';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Pressable, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { setValidAuthToken } from 'store/user';
import Styles from 'style';
import { useLogout } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import UniversalPinInput from '../profile/components/UniversalPinInput';

export const TwoFactorAuthentication: FunctionComponent = () => {
  const appReady = useAppStarter();
  const authUser = useContext(UserContext);
  const dispatch = useDispatch();
  const logout = useLogout();
  const { TailwindResponsive } = useTailwindResponsive();

  const successTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [codeSent, setCodeSent] = useState(false);
  const [error, updateError] = useState(false);
  const [fourDigit, setFourDigit] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const [sendAuthCode, { loading: sendAuthLoading }] =
    useMutation(SEND_AUTH_CODE);
  const [validateAuthCode, { data: validateData, loading: verifyAuthLoading }] =
    useMutation(VALIDATE_AUTH_CODE);

  const { loading: get4DigitPhoneLoading } = useQuery(GET_FOUR_DIGIT_NUMBER, {
    variables: {
      userId: authUser?.uid,
    },
    onCompleted: (data) => {
      setFourDigit(data.response);
    },
  });

  const handleSendCode = useCallback(async () => {
    await sendAuthCode({
      variables: {
        userId: authUser?.uid,
      },
    });
    if (codeSent) {
      setShowSuccess(true);
      if (successTimeout.current) clearTimeout(successTimeout.current);
      successTimeout.current = setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    }
    setCodeSent(true);
  }, [authUser, codeSent, sendAuthCode]);

  useEffect(() => {
    handleSendCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckCode = useCallback(
    async (value) => {
      const authCodeResponse = await validateAuthCode({
        variables: {
          code: value,
          userId: authUser?.uid,
        },
      });
      if (authCodeResponse.data.response === false) {
        updateError(true);
      }
    },
    [authUser, validateAuthCode],
  );

  const handleLogout = () => {
    logout(0);
  };

  useEffect(() => {
    if (validateData && validateData.response) {
      dispatch(setValidAuthToken(true));
    }
  }, [dispatch, validateData]);

  return (
    <>
      <View style={TailwindResponsive(`bg-Dark1 pb-32 flex flex-1`)}>
        <View style={TailwindResponsive('mt-10 mob:-mt-10')}>
          <Pressable onPress={handleLogout}>
            <WordmarkHeader color={Styles.Colours.Light1} />
          </Pressable>
        </View>
        <View style={TailwindResponsive(`mt-28 mb-28 mx-14 mob:mt-2`)}>
          <GraphikSemiTextHuge
            color={Styles.Colours.Light1}
            style={TailwindResponsive('mb-10')}
          >
            Two Factor Authentication
          </GraphikSemiTextHuge>

          {!get4DigitPhoneLoading && (
            <GraphikTextSm color={Styles.Colours.Light1}>
              You have 2FA enabled on this account. An authentication code has
              been sent via SMS to (***) *** {fourDigit}.
            </GraphikTextSm>
          )}

          {(verifyAuthLoading ||
            !appReady ||
            !codeSent ||
            get4DigitPhoneLoading) && (
            <View style={TailwindResponsive(`my-16 w-1/4`)}>
              <SpinLoader color={Styles.Colours.Light1} width={102} />
            </View>
          )}

          {codeSent && !get4DigitPhoneLoading && !verifyAuthLoading && (
            <>
              <View style={TailwindResponsive('mt-16 mb-16')}>
                <UniversalPinInput
                  inputFocusStyle={{ borderColor: 'white' }}
                  inputStyle={{
                    marginRight: '20px',
                    color: 'white',
                    borderColor: 'white',
                    fontFamily: Styles.Fonts.FontFamily.SansMedium,
                    fontSize: '20px',
                  }}
                  onComplete={handleCheckCode}
                />
                {error && (
                  <View style={TailwindResponsive('bg-Dark1 mt-8 items-start')}>
                    <View style={TailwindResponsive('flex-row')}>
                      <ErrorIcon width={24} color="red" />
                      <GraphikTextSm
                        style={TailwindResponsive('ml-4 pt-1')}
                        color={Styles.Colours.Error}
                      >
                        Incorrect Authentication Code
                      </GraphikTextSm>
                    </View>
                  </View>
                )}
              </View>

              <View style={TailwindResponsive('mb-24')}>
                <View
                  style={TailwindResponsive(
                    'flex-row items-center mob:flex-col mob:items-start',
                  )}
                >
                  <GraphikTextSm color={Styles.Colours.Gray1}>
                    Didn&apos;t receive your code?
                  </GraphikTextSm>
                  <View style={TailwindResponsive('mob:-ml-2')}>
                    <FairchainButton
                      label="Resend a new code"
                      loading={sendAuthLoading}
                      loadingStyle={TailwindResponsive(`ml-2 w-40`)}
                      onPress={handleSendCode}
                      textStyle={TailwindResponsive(
                        'ml-2 text-Gray1 underline',
                      )}
                    />
                  </View>
                </View>

                {showSuccess && (
                  <GraphikTextSm color={Styles.Colours.Success}>
                    Code successfully resent.
                  </GraphikTextSm>
                )}
              </View>
            </>
          )}
        </View>
      </View>
      {(!sendAuthLoading || !verifyAuthLoading) && (
        <Footer
          backgroundColor="Dark1"
          style={TailwindResponsive('mob:hidden')}
          textColor={Styles.Colours.Light1}
        />
      )}
    </>
  );
};

export default TwoFactorAuthentication;
