export interface BreadcrumbStep {
  step: number;
  retroTitle?: string;
  title: string;
}

export const artworkRegistrationSteps: BreadcrumbStep[] = [
  { step: 0, title: 'Artwork Details' },
  { step: 1, title: 'Images + Documents' },
  { step: 2, title: 'Resale Commission Rate' },
];

export const collectibleRegistrationSteps: BreadcrumbStep[] = [
  { step: 0, title: 'Item Details' },
  { step: 1, title: 'Images + Documents' },
  { step: 2, title: 'Resale Commission Rate' },
];

export const transferSteps: BreadcrumbStep[] = [
  { step: 0, title: 'Buyer', retroTitle: 'Owner' },
  { step: 1, title: 'Transaction Details', retroTitle: 'Transaction Details' },
  { step: 2, title: 'Review', retroTitle: 'Review' },
];
