import { RoundedButton } from 'components/shared/button';
import { SignatureInput } from 'components/shared/form';
import { DefaultTextInput } from 'components/shared/form/DefaultTextInput';
import { GetEmbeddedURL } from 'components/shared/image/ImageHelpers';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import {
  GraphikMediumTextMd,
  GraphikSemiTextMd,
  GraphikTextMd,
  GraphikTextSm,
  GraphikTextTiny,
} from 'components/shared/styled';
import { SITE_BASE_URL } from 'constants/constants';
import { AssetType } from 'constants/enums';
import { format, parseISO } from 'date-fns';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Image, Platform, View } from 'react-native';
import { useSelector } from 'react-redux';
import { getUser } from 'store/user';
import Styles from 'style';
import { IADCertificateProps, getDisplayName } from 'types';
import {
  formatEditionText,
  getWorkDimensions,
  isRetroTransaction,
} from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ProvenanceLineItem } from './ProvenanceLineItem';

export const ADCertificate: FunctionComponent<IADCertificateProps> = ({
  work,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const userInfo = useSelector(getUser);
  const [videoThumbnail, setVideoThumbnail] = useState('');

  const assetIsCollectible = work.assetType === AssetType.Collectible;
  const defaultMarginBottom = Platform.OS === 'web' ? 'mb-4' : 'mb-0';

  useEffect(() => {
    const setup = async () => {
      if (work.videoURL) {
        const videoInfo = await GetEmbeddedURL(work.videoURL);
        setVideoThumbnail(videoInfo.thumbnail);
      }
    };

    setup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const creatorLabel = assetIsCollectible ? 'Creator' : 'Artist';
  const assetTypeLabel = assetIsCollectible ? 'Item' : 'Artwork';

  const hasProvenance = useMemo(
    () => work && work.provenance && work.provenance.length > 0,
    [work],
  );

  const showVerificationDownload = useMemo(
    () =>
      !!work.certificateURL &&
      work.certificateURL !== '' &&
      !!work.ethereumTransaction &&
      work.ethereumTransaction !== '',
    [work],
  );

  const showPublicCert = useMemo(
    () => userInfo && userInfo.enablePublicCerts,
    [userInfo],
  );

  return (
    <View
      style={{
        borderColor: Styles.Colours.Gray1,
        borderWidth: 0.5,
        backgroundColor: 'white',
        padding: 60,
      }}
    >
      <View
        style={[
          TailwindResponsive(`w-full`),
          {
            marginBottom: Platform.OS === 'web' ? '2vh' : '0',
          },
        ]}
      >
        <GraphikTextSm>
          This document certifies that the{' '}
          {assetIsCollectible ? 'creator' : 'artist'} (&quot;{creatorLabel}
          &quot;) declares the {assetIsCollectible ? 'item' : 'artwork'} listed
          below (&quot;{assetTypeLabel}&quot;) to be an authentic, original and
          unique {assetIsCollectible ? 'item' : 'piece of art'} of their own
          creative efforts and executed by them, the undersigned. The{' '}
          {creatorLabel} retains all copyright. {assetTypeLabel} may not be
          reproduced in any way or form without the {`${creatorLabel}'s`}{' '}
          consent.{' '}
          {!isRetroTransaction(work) && (
            <GraphikTextSm>
              This document further certifies that the current owner of the{' '}
              {assetTypeLabel} identified below has full title to the{' '}
              {assetTypeLabel}.
            </GraphikTextSm>
          )}
        </GraphikTextSm>
      </View>
      <View style={{ marginBottom: Platform.OS === 'web' ? '4vh' : '0' }}>
        <GraphikSemiTextMd
          style={{
            marginTop: Platform.OS === 'web' ? '4vh' : '0',
            marginBottom: Platform.OS === 'web' ? '2vh' : '0',
          }}
        >
          {creatorLabel}
        </GraphikSemiTextMd>
        <DefaultTextInput
          value={getDisplayName(work.artist)}
          label={`${creatorLabel} Name`}
          editable={false}
        />
        <DefaultTextInput
          value={work.artist.externalId}
          label={`Fairchain ${creatorLabel} ID`}
          editable={false}
        />
      </View>
      <View style={{ marginBottom: Platform.OS === 'web' ? '4vh' : '0' }}>
        <GraphikSemiTextMd
          style={{
            marginTop: Platform.OS === 'web' ? '4vh' : '0',
            marginBottom: Platform.OS === 'web' ? '2vh' : '0',
          }}
        >
          {assetTypeLabel}
        </GraphikSemiTextMd>
        <DefaultTextInput value={work.title} label="Title" editable={false} />
        <DefaultTextInput
          value={work.yearProduced}
          label="Production Date"
          editable={false}
        />
        {!!formatEditionText(work) && (
          <DefaultTextInput
            value={formatEditionText(work)}
            label="Edition Information"
            editable={false}
          />
        )}
        {!assetIsCollectible ? (
          <>
            <DefaultTextInput
              value={work.medium}
              label="Medium"
              editable={false}
            />
            <DefaultTextInput
              value={getWorkDimensions(work)}
              label="Dimensions"
              editable={false}
            />
          </>
        ) : (
          <>
            <DefaultTextInput
              value={work.collectible?.materials}
              label="Materials/Fabric"
              editable={false}
            />
            <DefaultTextInput
              value={work.collectible?.measurements}
              label="Size/Measurements"
              editable={false}
            />
          </>
        )}
        <DefaultTextInput
          value={work.inventoryNumber}
          label="Inventory Number"
          editable={false}
        />
        <DefaultTextInput
          value={work.workIdentifier}
          label={`Fairchain ${assetTypeLabel} ID`}
          editable={false}
        />
        <View
          style={TailwindResponsive(`border-b pb-2.5 pt-6 border-gray-300`)}
        >
          <GraphikTextTiny
            color={Styles.Colours.Dark3}
            style={TailwindResponsive(`absolute right-0 bottom-2.5`)}
          >
            Issuer Notes
          </GraphikTextTiny>
          <GraphikMediumTextMd style={TailwindResponsive(`pr-24`)}>
            {work.additionalInfo || work.notes}
          </GraphikMediumTextMd>
        </View>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '4vh',
          justifyContent: 'space-between',
        }}
      >
        {work.images.map((image) => {
          let height = 52;
          if (work.images.length === 4) {
            height = 36;
          }
          return (
            <View
              key={image}
              style={{
                ...TailwindResponsive(
                  `flex-1 mr-2 p-6 bg-Light5 justify-center h-${height} mobWeb:h-12 mobWeb:p-0`,
                ),
              }}
            >
              {Platform.OS === 'web' && (
                <Image
                  source={image as any}
                  style={TailwindResponsive(`flex-1`)}
                  resizeMode="contain"
                  height={undefined}
                  width={undefined}
                />
              )}
            </View>
          );
        })}
        {videoThumbnail && Platform.OS === 'web' && (
          <View
            key={videoThumbnail}
            style={{
              ...TailwindResponsive(
                `flex-1 mr-2 p-6 bg-Light5 justify-center h-${
                  work.images.length === 4 ? 36 : 52
                } mobWeb:h-12 mobWeb:p-0`,
              ),
            }}
          >
            <Image
              source={videoThumbnail as any}
              style={TailwindResponsive(`flex-1`)}
              resizeMode="contain"
              height={undefined}
              width={undefined}
            />
          </View>
        )}
      </View>
      <View style={{ marginBottom: '4vh' }}>
        {work.artist.signature ? (
          <SignatureInput
            label={`${creatorLabel} Signature`}
            signatureImage={work.artist.signature}
          />
        ) : (
          <DefaultTextInput
            editable={false}
            label={`${creatorLabel} Signature`}
            value={getDisplayName(work.artist)}
          />
        )}
        <DefaultTextInput
          value={format(parseISO(work.dateRegistered), 'PPP')}
          label="Registration Date"
          editable={false}
        />

        {!isRetroTransaction(work) && (
          <DefaultTextInput
            value={getDisplayName(work.owner)}
            label="Current Owner or Consignee"
            editable={false}
          />
        )}
      </View>
      <View>
        <GraphikTextSm style={{ marginBottom: '4vh' }}>
          This document further certifies that the current holder of this
          digital certificate is: (a) now the sole owner of work or their
          designated agent; and (b) has good and complete right to hold or
          manage title, and interest in and to the {assetTypeLabel}, subject to
          the limitations set forth in Fairchain Sales and Royalty Management
          Agreement (the Fairchain Agreement) referenced below and Fairchain’s
          terms of use at www.fairchain.art. By offering this {assetTypeLabel}{' '}
          for sale, the holder affirms they have full authority to give,
          transfer and assign right, title and interest in and to the{' '}
          {assetTypeLabel} subject to the limitations set forth in the Fairchain
          Agreement referenced below and Fairchain’s terms of use.
        </GraphikTextSm>
      </View>
      <View>
        {!isRetroTransaction(work) && work.certified && (
          <>
            <GraphikSemiTextMd
              style={{
                marginBottom: '4vh',
              }}
            >
              Provenance of {assetTypeLabel}
            </GraphikSemiTextMd>
            <GraphikTextMd
              style={{
                marginBottom: '4vh',
              }}
            >
              Ownership of the above titled {assetTypeLabel} has been
              transferred as follows:
            </GraphikTextMd>
            {hasProvenance === true ? (
              work.provenance.map((item, index) => (
                <ProvenanceLineItem
                  isFinal={index === work.provenance.length - 1}
                  key={item.date! + item.action!}
                  provenance={item}
                />
              ))
            ) : (
              <GraphikTextMd>None</GraphikTextMd>
            )}
          </>
        )}

        {!!work.digitalRepoRights && (
          <View>
            <GraphikSemiTextMd style={TailwindResponsive(`my-8`)}>
              Reproduction Rights
            </GraphikSemiTextMd>
            <View style={TailwindResponsive(`w-full ${defaultMarginBottom}`)}>
              <GraphikTextSm>
                Subject to any terms set forth elsewhere in this Certificate,
                Owner has a limited, personal, non-exclusive, non-transferrable
                (except in connection with a Transfer of the {assetTypeLabel}),
                royalty-free, revocable (as provided below), worldwide right and
                license, while Owner continues to hold this certificate in their
                Fairchain account, to display a digital reproduction of the
                {assetTypeLabel} publicly: (a) for purposes of offering the{' '}
                {assetTypeLabel} for sale; and (b) for non-commercial purposes
                within virtual shared worlds and other virtual environments such
                as that currently known as the “Metaverse”; provided that, in
                each case, Owner shall not display the {assetTypeLabel} in more
                than one context or instance at any given time. {creatorLabel}{' '}
                may suspend or revoke the foregoing license if Owner materially
                breaches this license, the Transfer Agreement, or the Fairchain
                Terms of Use, or uses a digital reproduction of the{' '}
                {assetTypeLabel} that is unacceptable to
                {creatorLabel}. This license includes the right of the Owner to
                have such rights exercised by a third party on behalf of Owner
                such as a third-party virtual reality service provider.
              </GraphikTextSm>
            </View>
          </View>
        )}

        {showVerificationDownload ? (
          <View
            style={TailwindResponsive(
              `flex flex-col justify-between mt-5 mobWeb:justify-start mobWeb:items-center`,
            )}
          >
            <RoundedButton
              backgroundColor={Styles.Colours.Light1}
              buttonStyle={TailwindResponsive(
                `w-auto border-solid border border-Dark1 mobWeb:max-w-60`,
              )}
              textStyle={TailwindResponsive(`text-Dark1`)}
              onPress={() => {
                window.open(work.certificateURL);
              }}
              label="Download Verification Copy of Certificate"
              paddingHorizontal={15}
            />

            {!!showPublicCert &&
              !isRetroTransaction(work) &&
              !work.certified && (
                <View
                  style={TailwindResponsive(`flex flex-row items-center mt-6`)}
                >
                  <ExternalLink
                    link={`${SITE_BASE_URL}/details/${work.id}/public`}
                  >
                    <GraphikMediumTextMd
                      style={TailwindResponsive(`underline mr-2`)}
                    >
                      View Public Certificate
                    </GraphikMediumTextMd>
                  </ExternalLink>
                </View>
              )}
          </View>
        ) : (
          <View>
            {!!showPublicCert &&
              !isRetroTransaction(work) &&
              work.certified && (
                <View style={TailwindResponsive(`flex flex-row mt-5`)}>
                  <ExternalLink
                    link={`${SITE_BASE_URL}/details/${work.id}/public`}
                  >
                    <GraphikMediumTextMd
                      style={TailwindResponsive(`underline mr-2`)}
                    >
                      View Public Certificate
                    </GraphikMediumTextMd>
                  </ExternalLink>
                </View>
              )}
          </View>
        )}
      </View>
    </View>
  );
};

export default ADCertificate;
