import { RouteProp, useRoute } from '@react-navigation/native';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { RoundedButton } from 'components/shared/button';
import { SpinLoader } from 'components/shared/loader';
import { GraphikTextMd, GraphikTextTiny } from 'components/shared/styled';
import { SITE_BASE_URL } from 'constants/constants';
import { NativeStackParamList } from 'navigation';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';
import Styles from 'style';
import { InvoiceStatus } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const StripeCheckoutForm: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const transaction = useGetTransactionDetailsHook();

  const transactionInvoiceStatus = transaction?.invoiceStatus;

  const stripe = useStripe();
  const elements = useElements();

  const route =
    useRoute<RouteProp<NativeStackParamList, 'TransactionDetail'>>();

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [paidSuccess, setPaidSuccess] = useState(true);

  useEffect(() => {
    if (!stripe || !transaction?.stripePaymentIntentId) {
      return;
    }

    if (transactionInvoiceStatus === InvoiceStatus.paid) {
      setPaidSuccess(true);
      setMessage('Payment succeeded!');
      return;
    }

    stripe
      .retrievePaymentIntent(transaction?.stripePaymentIntentId)
      .then(({ paymentIntent }) => {
        switch (paymentIntent!.status) {
          case 'succeeded':
            setPaidSuccess(true);
            setMessage('Payment succeeded!');
            break;
          case 'processing':
            setPaidSuccess(true);
            setMessage('Your payment is processing');
            break;
          case 'requires_payment_method':
            setPaidSuccess(false);
            break;
          default:
            setPaidSuccess(false);
            setMessage('Something went wrong');
            break;
        }
      });
    if (transactionInvoiceStatus === InvoiceStatus.failed) {
      setPaidSuccess(false);
      setMessage('Payment failed');
    }
  }, [stripe, transaction, transactionInvoiceStatus]);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${SITE_BASE_URL}/stripe-payment/${route?.params?.transactionId}`,
      },
    });
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message || 'There was an error confirming the payment');
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  return (
    <View>
      {!paidSuccess && (
        <View>
          <PaymentElement id="payment-element" />
          <View style={TailwindResponsive(`mt-2`)}>
            {isLoading ? (
              <SpinLoader width={62} />
            ) : (
              <RoundedButton
                backgroundColor={Styles.Colours.Primary}
                buttonStyle={TailwindResponsive(`my-2`)}
                color={Styles.Colours.Light1}
                label="Pay now"
                onPress={handleSubmit}
              />
            )}
          </View>
          <GraphikTextTiny style={TailwindResponsive(`mt-2`)}>
            Connecting a US bank account allows for one tap payments.
          </GraphikTextTiny>
        </View>
      )}
      {!isLoading && message && (
        <GraphikTextMd
          color={paidSuccess ? Styles.Colours.Success : Styles.Colours.Error}
        >
          {message}
        </GraphikTextMd>
      )}
    </View>
  );
};

export default StripeCheckoutForm;
