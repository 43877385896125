import { gql } from '@apollo/client';

export const ACTIVATE_SUBSCRIPTION = gql`
  mutation activateSubscription($userId: ID!) {
    response: activateSubscription(userId: $userId) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const ADD_USER_EXPO_TOKEN = gql`
  mutation addUserExpoToken($expoPushToken: String!, $userId: ID!) {
    addUserExpoToken(expoPushToken: $expoPushToken, userId: $userId)
  }
`;

export const ADD_USER_LOGO = gql`
  mutation addUserLogo(
    $email: String!
    $base64Logo: String!
    $imageExtension: String!
  ) {
    response: addUserLogo(
      email: $email
      base64Logo: $base64Logo
      imageExtension: $imageExtension
    ) {
      success
      errorMessage
    }
  }
`;

export const ADD_USER_SIGNATURE = gql`
  mutation addUserSignature(
    $email: String!
    $base64Signature: String!
    $imageExtension: String!
  ) {
    response: addUserSignature(
      email: $email
      base64Signature: $base64Signature
      imageExtension: $imageExtension
    ) {
      success
      errorMessage
    }
  }
`;

export const ADMIN_CREATE_USER = gql`
  mutation adminCreateUser($createUserInput: CreateUserInput!) {
    adminCreateUser(createUserInput: $createUserInput) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const AGREE_TO_TERMS_AND_SETUP_STRIPE_ACCOUNT = gql`
  mutation agreeToTermsAndSetupStripeAccount(
    $termsStripeInput: TermsStripeInput!
  ) {
    response: agreeToTermsAndSetupStripeAccount(
      termsStripeInput: $termsStripeInput
    ) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const APPROVE_TRANSFER = gql`
  mutation approveTransfer(
    $adminId: ID!
    $transactionId: ID!
    $priority: String!
  ) {
    approveTransfer(
      adminId: $adminId
      transactionId: $transactionId
      priority: $priority
    )
  }
`;

export const ASSOCIATE_GALLERY_WITH_ARTIST = gql`
  mutation associateGallery($artistEmail: String!, $galleryEmail: String!) {
    response: associateGallery(
      artistEmail: $artistEmail
      galleryEmail: $galleryEmail
    ) {
      success
      errorMessage
    }
  }
`;

export const BUYER_ACCEPT_CLICKWRAP = gql`
  mutation buyerAcceptClickwrap($input: SignInfoInput!) {
    response: buyerAcceptClickwrap(input: $input)
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($userId: ID!) {
    response: cancelSubscription(userId: $userId) {
      success
    }
  }
`;

export const CANCEL_TRANSACTION = gql`
  mutation cancelTransaction($transactionId: ID!) {
    response: cancelTransaction(transactionId: $transactionId)
  }
`;

export const CHANGE_AVAILABILITY_FOR_LOAN = gql`
  mutation changeAvailabilityForLoan($userId: ID!, $workId: ID!) {
    response: changeAvailabilityForLoan(userId: $userId, workId: $workId) {
      errorMessage
      success
    }
  }
`;

export const DELETE_DRAFT = gql`
  mutation deleteDraft($draftId: ID!, $userId: ID!) {
    response: deleteDraft(draftId: $draftId, userId: $userId) {
      errorMessage
      success
    }
  }
`;

export const DISABLE_USER = gql`
  mutation disableUser($email: String!) {
    response: disableUser(email: $email) {
      success
      errorMessage
    }
  }
`;

export const GENERATE_COTA = gql`
  mutation generateCota($artworkId: String!) {
    generateCota(artworkId: $artworkId)
  }
`;

export const GENERATE_SALES_AGREEMENT = gql`
  mutation generateSalesAgreement($transferWorkFinalForm: TransferWorkInput!) {
    response: generateSalesAgreement(
      transferWorkFinalForm: $transferWorkFinalForm
    )
  }
`;

export const GET_ADMIN_PENDING_CERTS = gql`
  query getAdminPendingCerts($userId: ID!) {
    response: getAdminPendingCerts(userId: $userId) {
      id
      artist {
        id
        artistName
        brandName
        incomplete
        firstName
        lastName
      }
      owner {
        id
        firstName
        lastName
      }
      title
      workIdentifier
      yearProduced
    }
  }
`;

export const GET_ADMIN_STAND_IN_LINK = gql`
  query adminStandInLink($email: String!, $userId: ID!) {
    response: adminStandInLink(email: $email, userId: $userId)
  }
`;

export const GET_ADMIN_TRANSACTIONS_BY_STATUS = gql`
  query getAdminTransactionsByStatus($status: String!) {
    response: getAdminTransactionsByStatus(status: $status) {
      id
      status
      buyer {
        id
        firstName
        lastName
      }
      currency
      items {
        discount
        price
        work {
          id
          artist {
            firstName
            lastName
          }
          title
        }
      }
      seller {
        id
        firstName
        lastName
      }
      shipping
      tax
    }
  }
`;

export const GET_AFFILIATED_USERS = gql`
  query getAffiliatedUsers($userId: ID!) {
    response: getAffiliatedUsers(userId: $userId) {
      id
      address {
        id
        addedById
        userId
        billingAddressLineOne
        billingAddressLineTwo
        billingAddressCity
        billingAddressCountry
        billingAddressState
        billingAddressZipcode
        shippingAddressLineOne
        shippingAddressLineTwo
        shippingAddressCity
        shippingAddressCountry
        shippingAddressState
        shippingAddressZipcode
      }
      company
      email
      firstName
      lastName
      phoneNumber
    }
  }
`;

export const GET_ALL_ARTISTS = gql`
  query getAllArtists {
    response: getAllArtists {
      artistName
      firstName
      lastName
      birthYear
      nationality
      id
    }
  }
`;

export const GET_ALL_BRANDS = gql`
  query getAllBrands {
    response: getAllBrands {
      brandName
      firstName
      lastName
      id
    }
  }
`;

export const GET_INCOMPLETE_ARTISTS = gql`
  query getIncompleteArtists {
    response: getIncompleteArtists {
      artistName
      firstName
      lastName
      id
    }
  }
`;

export const GET_USER_FEATURE_FLAGS = gql`
  query getUserFeatureFlags($userId: ID!) {
    response: getUserFeatureFlags(userId: $userId) {
      allowBuyerRejection
      galleryCcEmail
      gallerySubscription
      kyc
      pushNotifications
      quickbooks
      registerBlockchain
      workTransDetails
    }
  }
`;

export const GET_APP_STARTER = gql`
  query getAppStarter($userId: ID!) {
    response: getAppStarter(userId: $userId) {
      featureFlags {
        allowBuyerRejection
        galleryCcEmail
        gallerySubscription
        kyc
        pushNotifications
        quickbooks
        registerBlockchain
        workTransDetails
      }
      artistOfEstateUser {
        id
        artistName
        birthYear
        email
        externalId
        firstName
        lastName
        nationality
        phoneNumber
        signature
      }
      mainUser {
        id
        address {
          id
          addedById
          userId
          billingAddressLineOne
          billingAddressLineTwo
          billingAddressCity
          billingAddressCountry
          billingAddressState
          billingAddressZipcode
          shippingAddressLineOne
          shippingAddressLineTwo
          shippingAddressCity
          shippingAddressCountry
          shippingAddressState
          shippingAddressZipcode
        }
        agreedTimeStamp
        artistName
        brandName
        birthYear
        brandName
        createDate
        connectedToQb
        discountEndDate
        email
        enablePublicCerts
        enablePublicProvenance
        externalId
        firstLogin
        firstName
        hasSeenCollectorOnboarding
        hasSeenOnboarding
        isAdmin
        isArtist
        isBrand
        isCollector
        isGallery
        isTrust
        lastName
        nationality
        phoneNumber
        signature
        stripeAccountId
        stripeAccountIsSetup
        subscriptionActive
        subTrialUsed
      }
      user {
        id
        address {
          id
          addedById
          userId
          billingAddressLineOne
          billingAddressLineTwo
          billingAddressCity
          billingAddressCountry
          billingAddressState
          billingAddressZipcode
          shippingAddressLineOne
          shippingAddressLineTwo
          shippingAddressCity
          shippingAddressCountry
          shippingAddressState
          shippingAddressZipcode
        }
        agreedTimeStamp
        agreedToTerms
        artistName
        brandName
        birthYear
        brandName
        connectedToQb
        createDate
        discountEndDate
        email
        enablePublicCerts
        enablePublicProvenance
        estateOf
        externalId
        firstLogin
        firstName
        galleryName
        hasSeenCollectorOnboarding
        hasSeenOnboarding
        isAdmin
        isArtist
        isBrand
        isCollector
        isEstate
        isGallery
        isSubUser
        isTrust
        lastName
        mainUser
        nationality
        phoneNumber
        rights
        signature
        signatureTemp
        stripeAccountId
        stripeAccountIsSetup
        stripeSubscriptionId
        subscriptionActive
        subTrialUsed
        trustName
        twoFactorEnabled
        veriffId
        veriffStatus
        verified
      }
    }
  }
`;

export const GET_APP_VERSION = gql`
  query getAppVersion {
    getAppVersion
  }
`;

export const GET_ARTWORK_PUBLIC_VIEW = gql`
  query artworkPublicView($artworkId: ID!) {
    response: artworkPublicView(artworkId: $artworkId) {
      id
      additionalInfo
      artist {
        id
        artistName
        brandName
        firstName
        lastName
        signature
      }
      assetType
      certified
      collectible {
        materials
        measurements
        contributors {
          id
          firstName
          lastName
          role
        }
      }
      dateRegistered
      depth
      dimensions
      dimensionUnits
      editionNumber
      editionTotal
      editionType
      embeddedCode
      hasPublicCertificate
      height
      images
      isEditionWork
      medium
      numAP
      owner {
        id
        brandName
        galleryName
        firstName
        lastName
        artistName
      }
      provenance {
        action
        date
      }
      publicViewOptions {
        col1
        col2
        imageBgColor
        paddingTop
      }
      title
      totalNumberOfAPs
      videoURL
      width
      workIdentifier
      yearProduced
    }
  }
`;

export const GET_ARTWORK_DETAILS = gql`
  query artworkDetails($userId: ID!, $artworkId: ID!) {
    response: artworkDetails(userId: $userId, artworkId: $artworkId) {
      canConsign
      canView
      work {
        id
        additionalInfo
        artist {
          id
          artistName
          brandName
          birthYear
          externalId
          firstName
          lastName
          signature
        }
        availableForLoan
        assetType
        blockchainData
        certificationStatus
        certified
        certificateURL
        collectible {
          materials
          measurements
          rfid
          contributors {
            id
            firstName
            lastName
            role
          }
        }
        consignment {
          consignee
          consignedDate
          consignor
        }
        currentTransactionId
        dateOfFirstTransaction
        dateRegistered
        depth
        digitalAssetURLs {
          title
          url
        }
        digitalRepoRights
        dimensions
        dimensionUnits
        editionNumber
        editionTotal
        editionType
        ethereumTransaction
        hasPublicCertificate
        height
        images
        inventoryNumber
        isEditionWork
        isRetro
        lastCompletedTransactionId
        medium
        notes
        numAP
        owner {
          id
          artistName
          brandName
          galleryName
          firstName
          lastName
        }
        privateNote
        provenance {
          action
          certificateId
          contractId
          date
          individualBlockchainHash
          individualEtheriumTransaction
        }
        registeredById
        royalties {
          type
          diminishedDate
          diminishedValue
          user
          value
        }
        salesInfo {
          purchaseAmount
          purchaseDate
          seller
          location
        }
        supportingDocUris {
          transferable
          uri
        }
        title
        totalNumberOfAPs
        userConsignment {
          consignee
          consignedDate
          consignor
        }
        videoURL
        width
        workIdentifier
        yearProduced
      }
    }
  }
`;

export const GET_BUYER_LOGIN_LINK = gql`
  query getBuyerLoginLink($userId: ID!, $transactionId: ID!) {
    response: getBuyerLoginLink(userId: $userId, transactionId: $transactionId)
  }
`;

export const GET_BUYER_OFFER_LINK = gql`
  query buyerOfferLink($userId: ID!, $transactionId: ID!) {
    response: buyerOfferLink(userId: $userId, transactionId: $transactionId)
  }
`;

export const GET_BUYER_SIGNLINK = gql`
  query buyerSignLink(
    $transactionId: ID!
    $isMobile: Boolean!
    $agentBuyerName: String
    $agentBuyerEmail: String
    $agentType: String
  ) {
    response: buyerSignLink(
      transactionId: $transactionId
      isMobile: $isMobile
      agentBuyerName: $agentBuyerName
      agentBuyerEmail: $agentBuyerEmail
      agentType: $agentType
    )
  }
`;

export const GET_BUYER_TRANSACTIONS = gql`
  query buyerTransactions($buyerId: ID!) {
    response: buyerTransactions(buyerId: $buyerId) {
      id
      createDate
      currency
      expirationTime
      isRetro
      items {
        discount
        price
        work {
          id
          images
          artist {
            id
            artistName
            brandName
          }
          title
          yearProduced
        }
      }
      seller {
        id
        firstName
        lastName
        artistName
        brandName
        galleryName
      }
      signedDate
      shipping
      status
      tax
      wasViewed
    }
  }
`;

export const GET_CLICKWRAP_FOR_BUYER = gql`
  query getClickwrapForBuyer($transactionId: ID!) {
    response: getClickwrapForBuyer(transactionId: $transactionId)
  }
`;

export const GET_CLICKWRAP_FOR_SELLER = gql`
  query getClickwrap($input: TransferWorkInput!) {
    response: getClickwrap(input: $input) {
      contract
      templateId
      version
    }
  }
`;

export const GET_CONSIGNED_WORKS = gql`
  query getConsignedWorks($consignor: ID!) {
    response: getConsignedWorks(consignor: $consignor) {
      id
      artist {
        id
        artistName
        externalId
        firstName
        lastName
      }
      artistId
      consignment {
        consignedDate
        consignee
        consignor
      }
      currentTransactionId
      dateRegistered
      depth
      dimensions
      dimensionUnits
      editionNumber
      height
      images
      isAP
      isEditionWork
      medium
      numAP
      owner {
        galleryName
        id
        artistName
        firstName
        lastName
      }
      ownerId
      title
      totalNumberOfAPs
      totalNumberOfPrints
      width
      workIdentifier
      yearProduced
    }
  }
`;

export const GET_CONTRACT_URL = gql`
  query getContractUrl($transactionId: ID!) {
    response: getContractUrl(transactionId: $transactionId)
  }
`;

export const GET_DASHBOARD = gql`
  query dashboard($userId: ID!) {
    response: dashboard(userId: $userId) {
      completedTransactions
      totalTransactedValue
    }
  }
`;

export const GET_DRAFT_WORKS = gql`
  query getDraftWorks($userId: ID!) {
    response: getDraftWorks(userId: $userId) {
      drafts {
        id
        artist {
          id
          artistName
          brandName
          firstName
          lastName
        }
        assetType
        dimensions
        image
        lastSaved
        medium
        title
        yearProduced
      }
    }
  }
`;

export const GET_FEE_TIERS = gql`
  query getFeeTiers {
    response: getFeeTiers {
      tiers {
        id
        percentage
        value
      }
    }
  }
`;

export const GET_FOUR_DIGIT_NUMBER = gql`
  query getFourDigitNumber($userId: ID!) {
    response: getFourDigitNumber(userId: $userId)
  }
`;

export const GET_INBOX_ITEMS = gql`
  query getInboxItems($buyerId: ID!) {
    response: getInboxItems(buyerId: $buyerId) {
      transactions {
        id
        createDate
        currency
        expirationTime
        isRetro
        items {
          discount
          price
          work {
            id
            images
            artist {
              id
              artistName
              brandName
            }
            title
            yearProduced
          }
        }
        seller {
          id
          firstName
          lastName
          artistName
          brandName
          galleryName
        }
        signedDate
        shipping
        status
        tax
        wasViewed
      }
      works {
        id
        certificationRequestedDate
        currentTransactionId
        owner {
          id
          firstName
          lastName
        }
        title
        workIdentifier
        yearProduced
      }
    }
  }
`;

export const GET_INVOICE_URL = gql`
  query getInvoiceUrl($invoiceFile: String!) {
    response: getInvoiceUrl(invoiceFile: $invoiceFile)
  }
`;

export const GET_LAST_SELLER_TRANSACTION = gql`
  query getLastSellerTransaction($sellerId: ID!, $workId: ID!) {
    response: getLastSellerTransaction(sellerId: $sellerId, workId: $workId)
  }
`;

export const GET_MY_ARCHIVE = gql`
  query myArchive($userId: ID!) {
    response: myArchive(userId: $userId) {
      id
      artist {
        id
        artistName
        externalId
        firstName
        lastName
      }
      currentTransactionId
      dateRegistered
      depth
      dimensions
      dimensionUnits
      editionNumber
      height
      images
      isAP
      isEditionWork
      lastCompletedTransactionId
      medium
      numAP
      title
      totalNumberOfAPs
      totalNumberOfPrints
      width
      workIdentifier
      yearProduced
    }
  }
`;

export const GET_MY_COLLECTION = gql`
  query myCollection($userId: ID!) {
    response: myCollection(userId: $userId) {
      id
      artist {
        id
        artistName
        brandName
        externalId
        firstName
        lastName
      }
      artistId
      assetType
      certified
      collectible {
        materials
        measurements
      }
      currentTransactionId
      dateRegistered
      depth
      dimensions
      dimensionUnits
      editionNumber
      height
      images
      inventoryNumber
      isAP
      isEditionWork
      isRetro
      lastCompletedTransactionId
      medium
      numAP
      ownerId
      title
      totalNumberOfAPs
      totalNumberOfPrints
      width
      workIdentifier
      yearProduced
    }
  }
`;

export const GET_NEW_JWT = gql`
  query getNewJWT($oldJWT: String!) {
    response: getNewJWT(oldJWT: $oldJWT)
  }
`;

export const GET_PUBLIC_URL = gql`
  query getPublicUrl($url: String!) {
    response: getPublicUrl(url: $url)
  }
`;

export const GET_QB_CONNECTED = gql`
  query getQbConnected($userId: ID!) {
    response: getQbConnected(userId: $userId)
  }
`;

export const GET_SAVED_REGISTRATION = gql`
  query getSavedRegistration($draftId: ID!) {
    response: getSavedRegistration(draftId: $draftId)
  }
`;

export const GET_SELECTED_WORKS = gql`
  query getSelectedWorks($workIds: [ID!]!) {
    response: getSelectedWorks(workIds: $workIds) {
      id
      artist {
        artistName
        brandName
        firstName
        lastName
        id
      }
      certificationStatus
      certified
      collectible {
        contributors {
          firstName
          id
          lastName
          role
        }
        materials
        measurements
      }
      dateRegistered
      depth
      digitalRepoRights
      dimensions
      dimensionUnits
      editionNumber
      editionTotal
      editionType
      height
      isEditionWork
      isRetro
      medium
      numAP
      lastCompletedTransactionId
      images
      owner {
        id
        address {
          id
          addedById
          billingAddressLineOne
          billingAddressLineTwo
          billingAddressCity
          billingAddressCountry
          billingAddressState
          billingAddressZipcode
          shippingAddressLineOne
          shippingAddressLineTwo
          shippingAddressCity
          shippingAddressCountry
          shippingAddressState
          shippingAddressZipcode
          userId
        }
        company
        email
        firstName
        lastName
        phoneNumber
      }
      royalties {
        diminishedDate
        diminishedValue
        type
        user
        value
      }
      title
      totalNumberOfAPs
      width
      workIdentifier
      yearProduced
    }
  }
`;

export const GET_SELLER_SIGNLINK = gql`
  query sellerSignLink($transactionId: ID!, $isMobile: Boolean!) {
    response: sellerSignLink(transactionId: $transactionId, isMobile: $isMobile)
  }
`;

export const GET_SELLER_TRANSACTIONS = gql`
  query sellerTransactions($sellerId: ID!) {
    response: sellerTransactions(sellerId: $sellerId) {
      id
      createDate
      currency
      expirationTime
      feesPassed
      finalizedDate
      isRetro
      shipping
      signedDate
      status
      tax
      buyer {
        id
        firstName
        lastName
      }
      seller {
        id
        firstName
        lastName
      }
      items {
        price
        discount
        work {
          id
          certificationStatus
          certified
          artist {
            id
            artistName
            brandName
          }
          images
          title
          yearProduced
        }
      }
    }
  }
`;

export const GET_STRIPE_ACCOUNT_LINK = gql`
  query getStripeAccountLink($stripeAccountId: ID!, $isMobile: Boolean!) {
    response: getStripeAccountLink(
      stripeAccountId: $stripeAccountId
      isMobile: $isMobile
    )
  }
`;

export const GET_STRIPE_ACCOUNT_STATUS = gql`
  query getStripeAccountStatus($userId: ID!) {
    response: getStripeAccountStatus(userId: $userId)
  }
`;

export const GET_STRIPE_INVOICE_STATUS = gql`
  query getInvoiceStatus($invoiceId: String!) {
    response: getInvoiceStatus(invoiceId: $invoiceId)
  }
`;

export const GET_STRIPE_INVOICE_URL = gql`
  query getPaymentUrl($invoiceId: String!) {
    response: getPaymentUrl(invoiceId: $invoiceId)
  }
`;

export const GET_SUB_USERS = gql`
  query getSubUsers($userId: ID!) {
    response: getSubUsers(userId: $userId) {
      id
      firstName
      lastName
      rights
    }
  }
`;

export const GET_SUBSCRIPTION = gql`
  query getSubscription($userId: ID!, $subChoice: String!) {
    response: getSubscription(userId: $userId, subChoice: $subChoice) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const GET_SUBSCRIPTION_INFO = gql`
  query getSubscriptionInfo($userId: ID!) {
    response: getSubscriptionInfo(userId: $userId) {
      id
      amount
      canceled
      cancelAt
      description
      nextBillingDate
      paymentSecret
      status
    }
  }
`;

export const GET_TRANSACTION_COUNT = gql`
  query getTransactionCount($userId: ID!) {
    response: getTransactionCount(userId: $userId)
  }
`;

export const GET_TRANSACTION_DETAILS = gql`
  query transactionDetails($transactionId: ID!) {
    transactionDetails(transactionId: $transactionId) {
      canAcceptOffer @client
      canCancel @client
      canSeeContract @client
      canSeeExpiration @client
      hasPayment @client
      isBuyerView @client
      isClickwrap @client
      isMultiWork @client
      isVoided @client
      stripePaymentFlowReady @client
      id
      buyer {
        id
        artistName
        brandName
        email
        firstName
        galleryName
        lastName
        phoneNumber
        address {
          id
          addedById
          userId
          billingAddressLineOne
          billingAddressLineTwo
          billingAddressCity
          billingAddressCountry
          billingAddressState
          billingAddressZipcode
          shippingAddressLineOne
          shippingAddressLineTwo
          shippingAddressCity
          shippingAddressCountry
          shippingAddressState
          shippingAddressZipcode
        }
      }
      buyerId
      contractId
      contractRider
      contractURL
      createDate
      currency
      dateInvoicePaid
      enableBuyerRejection
      envelopeId
      expirationTime
      feesPassed
      invoiceFile
      invoiceStatus
      isRetro
      items {
        price
        discount
        work {
          id
          artist {
            id
            artistName
            brandName
            firstName
            lastName
          }
          certificationStatus
          certified
          collectible {
            materials
            measurements
          }
          dateBought
          dateOfFirstTransaction
          depth
          dimensions
          dimensionUnits
          editionNumber
          editionTotal
          editionType
          height
          isEditionWork
          medium
          images
          royalties {
            diminishedDate
            diminishedValue
            type
            user
            value
          }
          title
          totalNumberOfAPs
          videoURL
          width
          workIdentifier
          yearProduced
        }
      }
      optionalMessage
      seller {
        id
        artistName
        brandName
        galleryName
        firstName
        isGallery
        lastName
        logo
        phoneNumber
        email
        address {
          id
          addedById
          userId
          billingAddressLineOne
          billingAddressLineTwo
          billingAddressCity
          billingAddressCountry
          billingAddressState
          billingAddressZipcode
          shippingAddressLineOne
          shippingAddressLineTwo
          shippingAddressCity
          shippingAddressCountry
          shippingAddressState
          shippingAddressZipcode
        }
      }
      sellerId
      sellerSignatureInfo {
        contractServerSignDate
        contractSignDate
        contractSignTimezone
        userId
      }
      shipping
      signedDate
      status
      stripeInvoiceId
      stripePaymentIntentId
      stripeSource {
        accountNumber
        routingNumber
      }
      subUserCreationId
      tax
      finalizedDate
      wasViewed
    }
  }
`;

export const TRANSACTION_DETAILS_SUBSCRIPTION = gql`
  subscription transactionDetails($transactionId: ID!) {
    transactionDetails(transactionId: $transactionId) {
      canAcceptOffer @client
      canCancel @client
      canSeeContract @client
      canSeeExpiration @client
      hasPayment @client
      isBuyerView @client
      isClickwrap @client
      isMultiWork @client
      isVoided @client
      stripePaymentFlowReady @client
      id
      buyer {
        id
        artistName
        brandName
        email
        firstName
        galleryName
        lastName
        phoneNumber
        address {
          id
          addedById
          userId
          billingAddressLineOne
          billingAddressLineTwo
          billingAddressCity
          billingAddressCountry
          billingAddressState
          billingAddressZipcode
          shippingAddressLineOne
          shippingAddressLineTwo
          shippingAddressCity
          shippingAddressCountry
          shippingAddressState
          shippingAddressZipcode
        }
      }
      buyerId
      contractId
      contractURL
      createDate
      currency
      dateInvoicePaid
      enableBuyerRejection
      envelopeId
      expirationTime
      feesPassed
      invoiceStatus
      items {
        price
        discount
        work {
          id
          artist {
            id
            artistName
            brandName
            firstName
            lastName
          }
          dateBought
          dateOfFirstTransaction
          depth
          dimensions
          dimensionUnits
          editionNumber
          editionTotal
          editionType
          height
          isEditionWork
          medium
          images
          royalties {
            diminishedDate
            diminishedValue
            type
            user
            value
          }
          title
          totalNumberOfAPs
          width
          workIdentifier
          yearProduced
        }
      }
      optionalMessage
      seller {
        id
        artistName
        brandName
        galleryName
        firstName
        isGallery
        lastName
        logo
        phoneNumber
        email
        address {
          id
          addedById
          userId
          billingAddressLineOne
          billingAddressLineTwo
          billingAddressCity
          billingAddressCountry
          billingAddressState
          billingAddressZipcode
          shippingAddressLineOne
          shippingAddressLineTwo
          shippingAddressCity
          shippingAddressCountry
          shippingAddressState
          shippingAddressZipcode
        }
      }
      sellerId
      shipping
      signedDate
      status
      stripeInvoiceId
      stripePaymentIntentId
      stripeSource {
        accountNumber
        routingNumber
      }
      subUserCreationId
      tax
      finalizedDate
      wasViewed
    }
  }
`;

export const GET_TRANSACTIONS_PENDING_BUYER = gql`
  query transactionsPendingBuyer($userId: ID!) {
    response: transactionsPendingBuyer(userId: $userId) {
      id
      createDate
      buyer {
        id
        email
        firstName
        lastName
      }
      envelopeId
      items {
        price
        work {
          id
          title
        }
      }
      seller {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($userId: ID!) {
    response: getUser(userId: $userId) {
      id
      address {
        id
        addedById
        userId
        billingAddressLineOne
        billingAddressLineTwo
        billingAddressCity
        billingAddressCountry
        billingAddressState
        billingAddressZipcode
        shippingAddressLineOne
        shippingAddressLineTwo
        shippingAddressCity
        shippingAddressCountry
        shippingAddressState
        shippingAddressZipcode
      }
      agreedTimeStamp
      agreedToTerms
      artistName
      brandName
      birthYear
      brandName
      connectedToQb
      createDate
      discountEndDate
      email
      enablePublicCerts
      enablePublicProvenance
      estateOf
      externalId
      firstLogin
      firstName
      galleryName
      hasSeenCollectorOnboarding
      hasSeenOnboarding
      isAdmin
      isArtist
      isBrand
      isCollector
      isEstate
      isGallery
      isSubUser
      isTrust
      lastName
      mainUser
      nationality
      phoneNumber
      rights
      signature
      signatureTemp
      stripeAccountId
      stripeAccountIsSetup
      stripeSubscriptionId
      subscriptionActive
      subTrialUsed
      trustName
      twoFactorEnabled
      veriffId
      veriffStatus
      verified
    }
  }
`;

export const GET_KYC_MATCHES = gql`
  query getKycMatches($name: String!) {
    response: getKycMatches(name: $name) {
      name
      type
    }
  }
`;

export const IMPORT_ARTLOGIC_WORKS = gql`
  mutation importArtlogicWorks(
    $artistRoyalty: String!
    $artlogicUrl: String!
    $diminishedArtistRoyalty: String!
    $diminishedDate: String!
    $diminishedGalleryRoyalty: String!
    $galleryRoyalty: String!
    $userId: ID!
  ) {
    importArtlogicWorks(
      artistRoyalty: $artistRoyalty
      artlogicUrl: $artlogicUrl
      diminishedArtistRoyalty: $diminishedArtistRoyalty
      diminishedDate: $diminishedDate
      diminishedGalleryRoyalty: $diminishedGalleryRoyalty
      galleryRoyalty: $galleryRoyalty
      userId: $userId
    ) {
      importedWorks {
        artistName
        artlogicId
        dimensions
        previewImage
        medium
        title
        yearProduced
        videoUrl
      }
      erroredWorks {
        artistName
        artlogicId
        dimensions
        previewImage
        medium
        title
        yearProduced
      }
    }
  }
`;

export const REGISTER_BLOCKCHAIN = gql`
  mutation registerBlockchain($workId: ID!) {
    response: registerBlockchain(workId: $workId) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const REGISTER_WORK = gql`
  mutation registerWork($registerWorkInput: RegisterWorkInput!) {
    response: registerWork(registerWorkInput: $registerWorkInput) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const REMOVE_EXPO_PUSH_TOKEN = gql`
  mutation removeExpoPushToken($userId: ID!) {
    response: removeExpoPushToken(userId: $userId)
  }
`;

export const REMOVE_SUB_USER = gql`
  mutation removeSubUser($userId: ID!, $subUserId: ID!) {
    response: removeSubUser(userId: $userId, subUserId: $subUserId)
  }
`;

export const REQUEST_CERTIFICATION = gql`
  mutation requestCertification($requestCertInput: RequestCertInput!) {
    response: requestCertification(requestCertInput: $requestCertInput) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const RESEND_BUYER_SALES_OFFER_LINK = gql`
  query resendBuyerSalesOfferLink($transactionId: ID!) {
    response: resendBuyerSalesOfferLink(transactionId: $transactionId)
  }
`;

export const RUN_MIGRATIONS = gql`
  mutation runMigrations($userId: ID) {
    response: runMigrations(userId: $userId) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const SEND_AUTH_VERIFY_EMAIL = gql`
  mutation sendAuthVerifyEmail($token: String!) {
    response: sendAuthVerifyEmail(token: $token) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const SEND_CERT_TO_ARTIST = gql`
  mutation sendCertToArtist($userId: ID!, $workId: String!) {
    response: sendCertToArtist(userId: $userId, workId: $workId) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const SIGN_UP_USER = gql`
  mutation signUpUser($signUpInfo: SignUpInfoInput!) {
    response: signUpUser(signUpInfo: $signUpInfo) {
      errorMessage
      signInUrl
    }
  }
`;

export const SEND_AUTH_CODE = gql`
  mutation sendAuthCode($userId: ID!) {
    response: sendAuthCode(userId: $userId)
  }
`;

export const SEND_CONSIGNMENT_REQUEST = gql`
  mutation sendConsignmentRequest(
    $additionalInfo: String!
    $artworkId: ID!
    $consigneeName: String!
    $consigneePhone: String!
    $consigneeEmail: String!
    $userId: ID!
  ) {
    response: sendConsignmentRequest(
      additionalInfo: $additionalInfo
      artworkId: $artworkId
      consigneeName: $consigneeName
      consigneePhone: $consigneePhone
      consigneeEmail: $consigneeEmail
      userId: $userId
    )
  }
`;

export const SET_VERIFF_ID = gql`
  mutation setVeriffId($userId: ID!, $veriffId: ID!) {
    response: setVeriffId(userId: $userId, veriffId: $veriffId)
  }
`;

export const SETUP_QUICKBOOKS_AND_GET_LINK = gql`
  query setupQuickbooksAndGetLink {
    response: setupQuickbooksAndGetLink
  }
`;

export const SETUP_STRIPE_ACCOUNT_AND_GET_LINK = gql`
  query setupStripeAccountAndGetLink($userId: ID!, $isMobile: Boolean!) {
    response: setupStripeAccountAndGetLink(userId: $userId, isMobile: $isMobile)
  }
`;

export const TRANSFER_TITLE = gql`
  mutation transferTitle($transactionId: String!) {
    response: transferTitle(transactionId: $transactionId) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const UPDATE_AGREED_TO_TERMS = gql`
  mutation updateAgreedToTerms(
    $userId: ID!
    $agreedTimeStamp: String!
    $agreedTimeZone: String!
  ) {
    response: updateAgreedToTerms(
      userId: $userId
      agreedTimeStamp: $agreedTimeStamp
      agreedTimeZone: $agreedTimeZone
    ) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const HANDLE_FIRST_LOGIN = gql`
  mutation handleFirstLogin($userId: ID!) {
    response: handleFirstLogin(userId: $userId) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const UPDATE_GALLERY_CCEMAILS = gql`
  mutation updateGalleryCcEmails($userId: ID!, $ccYourself: Boolean!) {
    response: updateGalleryCcEmails(userId: $userId, ccYourself: $ccYourself)
  }
`;

export const UPDATE_HAS_SEEN_COLLECTOR_ONBOARDING = gql`
  mutation updateHasSeenCollectorOnboarding(
    $userId: ID!
    $hasSeenCollectorOnboarding: Boolean!
  ) {
    response: updateHasSeenCollectorOnboarding(
      userId: $userId
      hasSeenCollectorOnboarding: $hasSeenCollectorOnboarding
    )
  }
`;

export const UPDATE_HAS_SEEN_ONBOARDING = gql`
  mutation updateHasSeenOnboarding($userId: ID!, $newValue: Boolean!) {
    response: updateHasSeenOnboarding(userId: $userId, newValue: $newValue)
  }
`;

export const UPDATE_PUBLIC_CERTIFICATE = gql`
  mutation updatePubCert($userId: ID!, $enablePublicCerts: Boolean!) {
    response: updatePubCert(
      userId: $userId
      enablePublicCerts: $enablePublicCerts
    )
  }
`;

export const UPDATE_PUBLIC_PROVENANCE = gql`
  mutation updatePubProvenance(
    $userId: ID!
    $enablePublicProvenance: Boolean!
  ) {
    response: updatePubProvenance(
      userId: $userId
      enablePublicProvenance: $enablePublicProvenance
    )
  }
`;

export const UPDATE_SUB_USER_RIGHTS = gql`
  mutation updateSubUserRights($userId: ID!, $rights: [String!]!) {
    updateSubUserRights(userId: $userId, rights: $rights)
  }
`;

export const UPDATE_TRANSACTION_SIGN_STATUS = gql`
  mutation updateTransactionSignStatus($transactionId: ID!, $userId: ID!) {
    updateTransactionSignStatus(
      transactionId: $transactionId
      userId: $userId
    ) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const UPDATE_TRANSACTION_INVOICE_STATUS = gql`
  mutation updateTransactionInvoiceStatus(
    $transactionId: ID!
    $invoiceStatus: String!
  ) {
    updateTransactionInvoiceStatus(
      transactionId: $transactionId
      invoiceStatus: $invoiceStatus
    ) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const UPDATE_USER_SIGNATURE = gql`
  mutation updateUserSignature($userId: ID!, $image: String!) {
    response: updateUserSignature(userId: $userId, image: $image)
  }
`;

export const SAVE_DRAFT = gql`
  mutation saveDraft($draftId: ID!, $json: String!, $userId: ID!) {
    response: saveDraft(draftId: $draftId, json: $json, userId: $userId) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const SAVE_QB_TOKENS = gql`
  mutation saveQbTokens($code: String!, $realmId: ID!, $userId: ID!) {
    response: saveQbTokens(code: $code, realmId: $realmId, userId: $userId)
  }
`;

export const UNCHECK_TWO_FACTOR_ENABLED = gql`
  mutation uncheckTwoFactorEnabled($userId: ID!) {
    response: uncheckTwoFactorEnabled(userId: $userId)
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation updateUser($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const VALIDATE_AUTH_CODE = gql`
  mutation validateAuthCode($code: String!, $userId: ID!) {
    response: validateAuthCode(code: $code, userId: $userId)
  }
`;

export const VALIDATE_AUTH_CODE_PROFILE_2FA = gql`
  mutation validateAuthCodeProfile2Fa($code: String!, $userId: ID!) {
    response: validateAuthCodeProfile2Fa(code: $code, userId: $userId)
  }
`;

export const VERIFY_PHONE_NUMBER_2FA = gql`
  mutation verifyPhoneNumber2Fa($phoneNumber: String!, $userId: ID!) {
    verifyPhoneNumber2Fa(phoneNumber: $phoneNumber, userId: $userId) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const VERIFY_USER = gql`
  mutation verifyUser($userVerificationInput: UserVerificationInput!) {
    verifyUser(userVerificationInput: $userVerificationInput) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const GET_NAVIGATION_INFO = gql`
  query getNavigationInfo($userId: ID!) {
    getNavigationInfo(userId: $userId) {
      unviewedTransactions
      transfersToInitiate
    }
  }
`;

export const MARK_TRANSACTION_VIEWED = gql`
  mutation markTransactionViewed($transactionId: ID!, $userId: ID!) {
    markTransactionViewed(transactionId: $transactionId, userId: $userId) {
      errorMessage
      success
      successMessage
    }
  }
`;

export const NAV_INFO_SUBSCRIPTION = gql`
  subscription navUpdate($userId: ID!) {
    navUpdate(userId: $userId) {
      unviewedTransactions
      transfersToInitiate
    }
  }
`;

export const USER_SUBSCRIPTION = gql`
  subscription userUpdate($userId: ID!) {
    userUpdate(userId: $userId) {
      id
      address {
        id
        addedById
        userId
        billingAddressLineOne
        billingAddressLineTwo
        billingAddressCity
        billingAddressCountry
        billingAddressState
        billingAddressZipcode
        shippingAddressLineOne
        shippingAddressLineTwo
        shippingAddressCity
        shippingAddressCountry
        shippingAddressState
        shippingAddressZipcode
      }
      agreedTimeStamp
      artistName
      brandName
      birthYear
      brandName
      connectedToQb
      createDate
      discountEndDate
      email
      enablePublicCerts
      enablePublicProvenance
      estateOf
      externalId
      firstLogin
      firstName
      galleryName
      hasSeenCollectorOnboarding
      hasSeenOnboarding
      isAdmin
      isArtist
      isBrand
      isCollector
      isEstate
      isGallery
      isSubUser
      isTrust
      lastName
      mainUser
      nationality
      phoneNumber
      rights
      signature
      signatureTemp
      stripeAccountId
      stripeAccountIsSetup
      subscriptionActive
      subTrialUsed
      trustName
      twoFactorEnabled
      veriffId
      veriffStatus
      verified
    }
  }
`;

export const UPDATE_ARTWORK_PRIVATE_INFO = gql`
  mutation updateArtworkPrivateInfo(
    $updateArtworkPrivateInfoInput: UpdateArtworkPrivateInfoInput!
  ) {
    response: updateArtworkPrivateInfo(
      updateArtworkPrivateInfoInput: $updateArtworkPrivateInfoInput
    )
  }
`;

export const UPDATE_ARTIST_NAME = gql`
  mutation updateArtistName($artistEmail: String!, $artistName: String!) {
    response: updateArtistName(
      artistEmail: $artistEmail
      artistName: $artistName
    ) {
      success
      errorMessage
    }
  }
`;

export const ADD_ASSET_PHYSICAL_ID = gql`
  mutation addAssetPhysicalId($assetId: String!, $assetPhysicalId: String!) {
    response: addAssetPhysicalId(
      assetId: $assetId
      assetPhysicalId: $assetPhysicalId
    ) {
      success
      errorMessage
    }
  }
`;
