/* Copyright (C) 2021 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */

import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import { ISVGProps } from './Types';

export const SearchIcon: FunctionComponent<ISVGProps> = ({ width, height }) => (
  <Svg height={height || 24} width={width || 24}>
    <Path d="m19.6 21.225-6.35-6.35q-.75.575-1.725.925-.975.35-2.075.35-2.775 0-4.7-1.938Q2.825 12.275 2.825 9.5q0-2.775 1.925-4.713Q6.675 2.85 9.45 2.85q2.775 0 4.713 1.937Q16.1 6.725 16.1 9.5q0 1.1-.337 2.075-.338.975-.913 1.7l6.325 6.35ZM9.45 13.85q1.825 0 3.1-1.263 1.275-1.262 1.275-3.087 0-1.825-1.275-3.088-1.275-1.262-3.1-1.262-1.825 0-3.087 1.262Q5.1 7.675 5.1 9.5q0 1.825 1.263 3.087Q7.625 13.85 9.45 13.85Z" />
  </Svg>
);

export default SearchIcon;
