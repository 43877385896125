/* Copyright (C) 2022 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */
import { useMutation } from '@apollo/client';
import { UPDATE_PUBLIC_PROVENANCE } from 'api/requests.v2';
import { Checkbox } from 'components/shared/checkbox';
import { ErrorMessage } from 'components/shared/error';
import { debounce } from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setPublicProvenance } from 'store/user';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const PublicProvenance: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const dispatch = useDispatch();
  const [enablePublicProvenance, setPubProv] = useState(false);
  const [updatePubProvenance, { error: updatePubProvenanceError }] =
    useMutation(UPDATE_PUBLIC_PROVENANCE);
  const userFromStore = useSelector(getUser);

  useEffect(() => {
    if (userFromStore) {
      setPubProv(userFromStore?.enablePublicProvenance || false);
    }
  }, [userFromStore]);

  // Handle the provenance checkbox click
  useEffect(() => {
    handlePublicProvenance(enablePublicProvenance, userFromStore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enablePublicProvenance]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePublicProvenance = useCallback(
    debounce(async (newValue, storeUser) => {
      if (storeUser) {
        await updatePubProvenance({
          variables: {
            userId: storeUser.id,
            enablePublicProvenance: newValue,
          },
        });

        if (storeUser) {
          dispatch(setPublicProvenance(newValue));
        }
      }
    }, 250),
    [],
  );

  if (updatePubProvenanceError)
    return <ErrorMessage error={updatePubProvenanceError} />;

  return (
    <View style={TailwindResponsive(`mt-6`)}>
      <Checkbox
        labelStyle={Styles.Fonts.FontFamily.SansMedium}
        label="Show name publicly in artwork provenance."
        isChecked={enablePublicProvenance}
        onPress={() => setPubProv(!enablePublicProvenance)}
      />
    </View>
  );
};

export default PublicProvenance;
