import BlueSquareCheckIcon from 'components/graphics/BlueSquareCheckIcon';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { GraphikTextSm } from '../styled';

type CertifiedWorkBannerProps = {
  assetIsCollectible?: boolean;
};

export const CertifiedWorkBanner: FunctionComponent<
  CertifiedWorkBannerProps
> = ({ assetIsCollectible = false }) => {
  const { TailwindResponsive } = useTailwindResponsive();
  return (
    <View
      style={TailwindResponsive(`mt-10 h-8 bg-LightBlue flex-row items-center`)}
    >
      <View style={TailwindResponsive('ml-2')}>
        <BlueSquareCheckIcon width={14} />
      </View>
      <GraphikTextSm style={TailwindResponsive('ml-2')}>
        This work has been certified by the{' '}
        {assetIsCollectible ? 'creator' : 'artist'}.
      </GraphikTextSm>
    </View>
  );
};

export default CertifiedWorkBanner;
