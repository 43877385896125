import { useNavigation, useRoute } from '@react-navigation/native';
import { RoundedButton, TextButton } from 'components/shared/button';
import { DefaultTextInput } from 'components/shared/form';
import { ButtonAlignment } from 'constants/enums';
import { AuthError, sendPasswordResetEmail } from 'firebase/auth';
import { LoginRouteProp } from 'navigation';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Text, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { setRedirect } from 'store/general';
import Styles from 'style';
import {
  FCAnalyticEvents,
  getFBAuth,
  logAnalyticsEvent,
  logError,
} from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface Props {
  setIsLogin: (bool: boolean) => void;
}

export const ForgotPassword: FunctionComponent<Props> = ({ setIsLogin }) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const firebaseAuth = getFBAuth();
  const route = useRoute() as LoginRouteProp;
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const [email, updateEmail] = useState('');
  const [error, updateError] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);

  useEffect(() => {
    if (route.params?.error && route.params.error === 'expired-code') {
      setIsLogin(false);
      updateError(
        'Reset password link has expired. Please enter your email and submit again.',
      );
      dispatch(setRedirect(null));
    } else {
      updateError('');
    }
  }, [route.params?.error, setIsLogin, dispatch]);

  const handleNewPassword = useCallback(async () => {
    if (email.length > 0) {
      try {
        await sendPasswordResetEmail(firebaseAuth, email);
        logAnalyticsEvent(FCAnalyticEvents.PasswordResetRequest, { email });
        setIsEmailSent(true);
      } catch (caughtError) {
        const authError = caughtError as AuthError;
        if (authError.code === 'auth/user-not-found') {
          setIsEmailSent(true);
        } else logError(caughtError, 'Error handling email in reset password');
      }
    } else {
      updateError('Please enter your email');
    }
  }, [email, firebaseAuth]);

  return (
    <View style={TailwindResponsive('flex web:mt-36 web:mx-14')}>
      {!isEmailSent ? (
        <>
          <View style={TailwindResponsive('web:w-2/5 mobWeb:w-full')}>
            <Text
              style={{
                color: Styles.Colours.Light1,
                fontSize: Styles.Fonts.FontSize.Medium,
              }}
            >
              Enter your email to receive a link to reset your password.
            </Text>
            <DefaultTextInput
              value={email}
              label="Email"
              handleChange={updateEmail}
              color={Styles.Colours.Light1}
              errorMessage={error}
            />
          </View>
          <View
            style={TailwindResponsive(
              'pt-28 w-64 web:self-start mob:self-center mobWeb:self-center',
            )}
          >
            <RoundedButton
              label="Reset Password"
              onPress={() => {
                handleNewPassword();
              }}
              backgroundColor={Styles.Colours.Light1}
              color={Styles.Colours.Dark1}
            />
          </View>
        </>
      ) : (
        <View style={TailwindResponsive('flex flex-col py-10')}>
          <Text
            style={{
              color: Styles.Colours.Light1,
            }}
          >
            An email has been sent to {email} with a link to reset your
            password.
          </Text>
          <View style={TailwindResponsive('-mx-6 py-20')}>
            <TextButton
              alignment={ButtonAlignment.Left}
              label="Return To Login"
              onPress={() => {
                setIsEmailSent(false);
                setIsLogin(true);
                navigation.setParams({ error: '', redirectURL: null });
              }}
              style={TailwindResponsive('text-sm')}
              color={Styles.Colours.Light1}
            />
          </View>
        </View>
      )}
    </View>
  );
};

export default ForgotPassword;
