import { CollectionTab } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { getCollectionTab, sortingByDate } from 'store/collection';
import { ArchiveWrapper } from './ArchiveWrapper';
import { ArtistBlockGroup } from './ArtistBlockGroup';
import { CollectionWrapper } from './CollectionWrapper';
import { DraftWrapper } from './DraftWrapper';

export const ListViewWrapper: FunctionComponent = () => {
  const currentTab = useSelector(getCollectionTab);
  const sortByDate = useSelector(sortingByDate);
  if (currentTab === CollectionTab.Past) return <ArchiveWrapper />;
  if (currentTab === CollectionTab.Drafts) return <DraftWrapper />;
  if (
    currentTab === CollectionTab.Current &&
    !sortByDate &&
    Platform.OS === 'web'
  )
    return <ArtistBlockGroup />;

  return <CollectionWrapper />;
};
