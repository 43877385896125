import { User, Work, WorkWithObject } from '.';

interface TransactionItem {
  discount: number;
  price: number;
  work: Work;
}

export interface KycMatch {
  name: string;
  type: string;
}

export interface Transaction {
  id: string;
  buyer: User;
  buyerAgentEmail: string;
  buyerAgentName: string;
  canAcceptOffer: boolean;
  canCancel: boolean;
  canSeeContract: boolean;
  canSeeExpiration: boolean;
  contractId: string;
  contractRider: string;
  contractURL: string;
  createDate: string;
  dateInvoicePaid: string;
  currency: string;
  discount: number;
  enableBuyerRejection?: boolean;
  envelopeId: string;
  expirationTime: string;
  feesPassed: number;
  hasPayment: boolean;
  invoiceFile: string;
  invoiceStatus: string;
  isBuyerView: string;
  isClickwrap: boolean;
  isMultiWork: boolean;
  isRetro: boolean;
  isVoided: boolean;
  items: TransactionItem[];
  optionalMessage: string;
  seller: User;
  sellerSignatureInfo?: SignatureInfo;
  shipping: number;
  signedDate: string;
  status: string;
  stripeInvoiceId: string;
  stripePaymentIntentId?: string;
  stripePaymentFlowReady: boolean;
  stripeSource?: StripeSource;
  subUserCreationId?: string;
  tax: number;
  finalizedDate: string;
  wasViewed: boolean;
}

interface TransactionItemObj {
  discount: number;
  price: number;
  work: WorkWithObject;
}

export interface TransactionWithObjects extends Transaction {
  buyer: User;
  seller: User;
  items: TransactionItemObj[];
}

export interface SignatureInfo {
  brand?: string;
  contractServerSignDate?: string;
  contractSignDate?: string;
  contractSignTimezone?: string;
  deviceName?: string;
  ipAddress?: string;
  locale?: string;
  manufacturer?: string;
  modelName?: string;
  osName?: string;
  osVersion?: string;
  userAgent?: string;
  userId: string;
}

export enum Status {
  created = 'CREATED',
  sellerSigned = 'SELLER_SIGNED',
  buyerSigned = 'BUYER_SIGNED',
  signed = 'SIGNED',
  pendingApproval = 'PENDING_APPROVAL',
  completed = 'COMPLETED',
  void = 'VOID',
}

export interface StripeSource {
  accountNumber: string;
  routingNumber: string;
}

export enum InvoiceStatus {
  created = 'CREATED',
  processing = 'PROCESSING',
  paid = 'PAID',
  failed = 'FAILED',
}

export interface ContractDataResponse {
  contract: string;
  templateId: string;
  version: string;
}

export enum CurrencyType {
  USD = 'USD',
  CNY = 'CNY',
  EUR = 'EUR',
  MXN = 'MXN',
  JPY = 'JPY',
  GBP = 'GBP',
  AUD = 'AUD',
  CAD = 'CAD',
  CHF = 'CHF',
  SEK = 'SEK',
}
