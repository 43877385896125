import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { FairchainButton } from '../button/FairchainButton';
import { GraphikSemiTextLg, GraphikSemiTextMd, GraphikTextSm } from '../styled';
import { ISubOptionProps, PriceText } from './SubscriptionSelector';

export const BasicOption: FunctionComponent<ISubOptionProps> = ({
  selectOption,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const subKeyPointStyle = TailwindResponsive('my-1.5');

  return (
    <View
      style={[
        {
          boxShadow: '0 0 10px rgb(0 0 0 / 0.2)',
        },
        TailwindResponsive(
          `flex items-center p-10 rounded-sm max-w-lg xl:flex-1 mob:border`,
        ),
      ]}
    >
      <GraphikSemiTextLg>Collector Basic</GraphikSemiTextLg>

      <View style={TailwindResponsive(`flex flex-row items-start mt-6`)}>
        <GraphikTextSm>$</GraphikTextSm>
        <PriceText>15</PriceText>
      </View>

      <GraphikTextSm>per month</GraphikTextSm>

      <View style={TailwindResponsive('h-28 items-center w-full')}>
        <FairchainButton
          buttonStyle={TailwindResponsive(`bg-Primary mt-8 rounded-full w-4/5`)}
          label="Select"
          onPress={selectOption}
          textStyle={TailwindResponsive(`text-Light1`)}
        />
      </View>

      <View
        style={TailwindResponsive(
          `border-t border-Dark3 items-center mt-8 pt-8`,
        )}
      >
        <GraphikSemiTextMd>Key Features</GraphikSemiTextMd>

        <View style={TailwindResponsive(`mt-2`)}>
          <GraphikTextSm style={subKeyPointStyle}>
            View and manage certificates sent by partner galleries
          </GraphikTextSm>

          <GraphikTextSm style={subKeyPointStyle}>
            10 work uploads/yr
          </GraphikTextSm>

          <GraphikTextSm style={subKeyPointStyle}>Single user</GraphikTextSm>

          <GraphikTextSm style={subKeyPointStyle}>
            Online chat and email support
          </GraphikTextSm>

          <GraphikTextSm style={subKeyPointStyle}>
            Integrated payment processing
          </GraphikTextSm>
        </View>
      </View>
    </View>
  );
};

export default BasicOption;
