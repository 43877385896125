import { Platform } from 'react-native';
import { IImageSelection } from 'store/registerWork';
import { extractExtensionFromB64Data, uploadImageAsync } from 'utilities';
import * as uuid from 'uuid';
import * as yup from 'yup';

export const ArtlogicImportSchema = yup
  .object()
  .shape({
    artlogicUrl: yup.string().required('This is a required field'),
  })
  .required();

export enum RegisterWorkFields {
  artistName = 'artistName',
  birthYear = 'birthYear',
  brandName = 'brandName',
  contributors = 'contributors',
  firstName = 'firstName',
  lastName = 'lastName',
  nationality = 'nationality',
  email = 'email',
  title = 'title',
  yearProduced = 'yearProduced',
  medium = 'medium',
  width = 'width',
  height = 'height',
  depth = 'depth',
  dimensionUnits = 'dimensionUnits',
  inventoryNumber = 'inventoryNumber',
  isEditionWork = 'isEditionWork',
  editionType = 'editionType',
  editionNumber = 'editionNumber',
  editionTotal = 'editionTotal',
  numAP = 'numAP',
  registrationType = 'registrationType',
  totalNumberOfAPs = 'totalNumberOfAPs',
  additionalInfo = 'additionalInfo',
  privateNotes = 'privateNotes',
  artistRoyalty = 'artistRoyalty',
  galleryRoyalty = 'galleryRoyalty',
  videoUrl = 'videoUrl',
  purchaseAmount = 'purchaseAmount',
  purchaseDate = 'purchaseDate',
  seller = 'seller',
  location = 'location',
  materials = 'materials',
  measurements = 'measurements',
}

export const scrollToTop = () => {
  if (Platform.OS === 'web')
    window.scroll({ top: 0, left: 0, behavior: 'auto' });
};

const uploadImage = async (storagePath, image): Promise<any> => {
  const imgExtension = extractExtensionFromB64Data(image.localURI);
  return uploadImageAsync(image.localURI, storagePath, image.id + imgExtension);
};

export const uploadTempImages2Permenance = async (
  images: IImageSelection,
  primaryImageKey: string,
) => {
  const storagePath = `workimages/${uuid.v4()}/`;

  const previewImageRequests = Object.entries(images).reduce<Promise<any>[]>(
    (acc, [key, image]) => {
      if (!image) {
        return acc;
      }
      const imageUri = uploadImage(storagePath, image);
      if (key === primaryImageKey) {
        return [imageUri, ...acc];
      }
      return [...acc, imageUri];
    },
    [],
  );

  return Promise.all(previewImageRequests);
};
