import { Slider } from '@miblanchard/react-native-slider';
import { Checkbox } from 'components/shared/checkbox';
import { FCCurrencyInput } from 'components/shared/form';
import { RoyaltyInput } from 'components/shared/form/RoyaltyInput';
import { OptionPicker } from 'components/shared/optionPicker';
import {
  GraphikSemiTextLg,
  GraphikSemiTextMd,
  GraphikTextMd,
  GraphikTextSm,
  GraphikTextTiny,
} from 'components/shared/styled';
import { TextAlignment } from 'constants/enums';
import { UserContext } from 'contexts';
import currency from 'currency.js';
import React, { FunctionComponent, useContext, useMemo, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
  useController,
} from 'react-hook-form';
import { Platform, View } from 'react-native';
import { useSelector } from 'react-redux';
import { getMainUser, getUser } from 'store/user';
import Styles from 'style';
import { CertificationStatus, WorkWithObject } from 'types';
import { getCurrencySymbol, isRetroTransaction } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ITransactionDetailsForm, formatDiscountInput } from './Helpers';
import DetailsLineItem from './components/DetailsLineItem';
import RoyaltyEntry from './components/RoyaltyEntry';
import { TransactionSettings } from './components/TransactionSettings';

interface TransferWorkDetailsProps {
  clearErrors: UseFormClearErrors<ITransactionDetailsForm>;
  control: Control<ITransactionDetailsForm>;
  errors: FieldErrorsImpl;
  getValues: UseFormGetValues<ITransactionDetailsForm>;
  index: number;
  isFinal: boolean;
  isMultipleWorks: boolean;
  setValues: UseFormSetValue<ITransactionDetailsForm>;
  trigger: (string) => void;
  work: WorkWithObject;
  assetIsCollectible: boolean;
}

const TransferWorkDetails: FunctionComponent<TransferWorkDetailsProps> = ({
  clearErrors,
  control,
  errors,
  getValues,
  isMultipleWorks,
  index,
  isFinal,
  setValues,
  trigger,
  work,
  assetIsCollectible,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const authUser = useContext(UserContext);
  const [discountApplied, setDiscountApplied] = useState(
    getValues(`items.${index}.discountApplied`),
  );

  const userInfo = useSelector(getUser);
  const mainUser = useSelector(getMainUser);

  const notRetroTrans = !isRetroTransaction(work);

  const isSubUser = !!userInfo?.isSubUser;
  const isAdmin =
    !!userInfo?.isAdmin || (userInfo?.isSubUser && mainUser?.isAdmin);
  const isGallery =
    !!userInfo?.isGallery || (userInfo?.isSubUser && mainUser?.isGallery);
  const isBrand =
    !!userInfo?.isBrand || (userInfo?.isSubUser && mainUser?.isBrand);

  const borderStyling = isMultipleWorks && !isFinal ? 'border-b mb-12' : '';

  const canEditWorkRoyalty = () => {
    const userCanUpdateRoyalties =
      work.artist.id === authUser?.uid ||
      isGallery ||
      isAdmin ||
      (isSubUser && work.artist.id === mainUser?.id) ||
      (isBrand && assetIsCollectible);
    const isPrimarySale = !work.lastCompletedTransactionId;
    return (
      userCanUpdateRoyalties &&
      isPrimarySale &&
      (work.certified ||
        work.certificationStatus === CertificationStatus.adminApproved)
    );
  };

  const {
    field: { onChange: setDiscount, value: discount },
  } = useController({ name: `items.${index}.discount`, control });

  const currencyOptions = useMemo(
    () => [
      { label: 'USD ($)', value: 'USD' },
      { label: 'CNY (¥)', value: 'CNY' },
      { label: 'JPY (¥)', value: 'JPY' },
      { label: 'EUR (€)', value: 'EUR' },
      { label: 'MXN ($)', value: 'MXN' },
      { label: 'GBP (£)', value: 'GBP' },
      { label: 'AUD ($)', value: 'AUD' },
      { label: 'CAD ($)', value: 'CAD' },
      { label: 'CHF', value: 'CHF' },
      { label: 'SEK', value: 'SEK' },
    ],
    [],
  );

  const currencyType = getValues('currency');
  const currencySymbol = getCurrencySymbol(currencyType);
  const artworkValue = getValues(`items.${index}.artworkValue`);

  const discountAmount = useMemo(
    () =>
      currency(discount || 0, {
        symbol: currencySymbol,
      })
        .divide(100)
        .multiply(artworkValue),
    [artworkValue, currencySymbol, discount],
  );
  const discountedPrice = useMemo(
    () =>
      currency(artworkValue, {
        symbol: currencySymbol,
      }).subtract(discountAmount),
    [artworkValue, currencySymbol, discountAmount],
  );

  const trackMarkComponent = (number) => (
    <View style={TailwindResponsive(`pt-10`)}>
      <GraphikTextSm>{number * 10}%</GraphikTextSm>
    </View>
  );

  return (
    <View
      style={TailwindResponsive(
        `pr-16 w-1/2 ${borderStyling} small:w-full mobWeb:w-full mobWeb:pr-0`,
      )}
    >
      {isMultipleWorks && notRetroTrans && (
        <View>
          <GraphikSemiTextLg>{`${assetIsCollectible ? 'Item' : 'Artwork'} #${
            index + 1
          }`}</GraphikSemiTextLg>
          <GraphikSemiTextLg style={TailwindResponsive(`mb-5`)}>
            <GraphikSemiTextLg style={TailwindResponsive(`italic`)}>
              {work.title}
            </GraphikSemiTextLg>
            {`, by ${
              work.artist.artistName ||
              `${work.artist.firstName} ${work.artist.lastName}`
            }`}
          </GraphikSemiTextLg>
        </View>
      )}

      {notRetroTrans && (
        <View style={{ zIndex: -1 }}>
          <DetailsLineItem
            text={`Enter the sale price of the ${
              assetIsCollectible ? 'item' : 'artwork'
            }.`}
            title={`${assetIsCollectible ? 'List Price' : 'Artwork Value'}`}
          >
            <View style={TailwindResponsive(`flex flex-row`)}>
              {Platform.OS === 'web' && (
                <Controller
                  control={control}
                  name="currency"
                  render={({ field: { onChange, value } }) => (
                    <View style={TailwindResponsive(`mr-2`)}>
                      <OptionPicker
                        bold
                        defaultValue="USD"
                        options={currencyOptions}
                        onChange={onChange}
                        showIcon
                        alignment={TextAlignment.Left}
                        value={value}
                      />
                    </View>
                  )}
                />
              )}
              <View
                style={TailwindResponsive(`web:flex-1 mob:w-full mob:h-20`)}
              >
                <Controller
                  control={control}
                  name={`items.${index}.artworkValue`}
                  render={({ field: { onChange, value } }) => (
                    <FCCurrencyInput
                      errorMessage={
                        errors?.items
                          ? errors?.items[index]?.artworkValue?.message
                          : ''
                      }
                      handleChange={(input) => {
                        clearErrors(`items.${index}.artworkValue`);
                        onChange(input || 0);
                      }}
                      label=""
                      testID="GSA_ArtworkValue"
                      value={value}
                    />
                  )}
                />
                <GraphikTextTiny color={Styles.Colours.Gray2}>
                  {`* ${assetIsCollectible ? 'List Price' : 'Artwork Value'}`}
                </GraphikTextTiny>
              </View>
            </View>
          </DetailsLineItem>

          <View style={[TailwindResponsive(`pb-12`), { zIndex: -1 }]}>
            <Controller
              control={control}
              name={`items.${index}.discountApplied`}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  isChecked={value}
                  onPress={() => {
                    onChange(!value);
                    setDiscountApplied(!value);
                    setDiscount(0);
                  }}
                  label="Apply Discount"
                />
              )}
            />
          </View>

          {discountApplied && (
            // to allow option picker to overlay
            <View style={{ zIndex: -1 }}>
              <View
                style={TailwindResponsive(
                  `flex flex-row justify-between pb-12 mobWeb:flex-col mob:flex-col`,
                )}
              >
                <View style={TailwindResponsive(`flex-1 pr-4 mobWeb:mb-2`)}>
                  <Slider
                    containerStyle={TailwindResponsive(`w-full`)}
                    value={discount}
                    maximumValue={50}
                    minimumValue={0}
                    onValueChange={(value) => setDiscount(value[0] || 0)}
                    step={1}
                    trackMarks={[0, 10, 20, 30, 40, 50]}
                    renderTrackMarkComponent={trackMarkComponent}
                  />
                </View>
                <View style={TailwindResponsive(`flex-1 mob:w-full mob:mt-8`)}>
                  <RoyaltyInput
                    errorMessage={
                      errors?.items
                        ? errors?.items[index]?.discount?.message
                        : ''
                    }
                    style={TailwindResponsive(`w-full`)}
                    handleChange={(input) => {
                      const parsedInput = formatDiscountInput(input);
                      setDiscount(parsedInput);
                      clearErrors(`items.${index}.discount`);
                    }}
                    value={discount.toString()}
                  />
                </View>
              </View>
              <DetailsLineItem>
                <View style={TailwindResponsive(`flex flex-col`)}>
                  <GraphikTextMd>Discount:</GraphikTextMd>
                  <GraphikSemiTextMd>
                    ({discountAmount.format()})
                  </GraphikSemiTextMd>
                </View>

                <View style={TailwindResponsive(`flex flex-col mt-8`)}>
                  <GraphikTextMd>Price After Discount</GraphikTextMd>
                  <GraphikSemiTextMd>
                    {discountedPrice.format()}
                  </GraphikSemiTextMd>
                </View>
              </DetailsLineItem>
            </View>
          )}
        </View>
      )}

      {!isMultipleWorks && notRetroTrans && (
        <View style={{ ...TailwindResponsive(`mb-16`), zIndex: -2 }}>
          <TransactionSettings
            control={control}
            getValues={getValues}
            setValues={setValues}
          />
        </View>
      )}

      {canEditWorkRoyalty() && (
        <RoyaltyEntry
          assetIsCollectible={assetIsCollectible}
          clearErrors={clearErrors}
          control={control}
          errors={errors}
          index={index}
          isGallery={isGallery!}
          trigger={trigger}
          work={work}
        />
      )}
    </View>
  );
};

export default TransferWorkDetails;
