import { useLazyQuery } from '@apollo/client';
import { GET_APP_VERSION } from 'api/requests.v2';
import { EXPOCONFIG } from 'constants/constants';
import * as Linking from 'expo-linking';
import * as Updates from 'expo-updates';
import { useEffect, useRef, useState } from 'react';
import { Alert, AppState, AppStateStatus, Platform } from 'react-native';
import LogError from 'utilities/LogError';

export const useAppUpdates = () => {
  const appState = useRef(AppState.currentState);

  const [getAppVersion] = useLazyQuery(GET_APP_VERSION);

  const [updateAvailable, setUpdateAvailable] = useState(false);

  const checkForNewAppVersion = ({ data: appVersionData }) => {
    try {
      const appVersion: string = appVersionData.getAppVersion;
      const appStoreVersionSplit = appVersion.split('.');

      const baseVersion: string = EXPOCONFIG?.version || '';
      const baseVersionSplit = baseVersion.split('.');
      const buildNumber = EXPOCONFIG?.ios?.buildNumber;

      const appStoreMajorVersion = parseInt(appStoreVersionSplit[0] || '0', 10);
      const appStoreMinorVersion = parseInt(appStoreVersionSplit[1] || '0', 10);
      const appStoreReleaseVersion = parseInt(
        appStoreVersionSplit[2] || '0',
        10,
      );
      const appStoreBuildVersion = parseInt(appStoreVersionSplit[3] || '0', 10);

      const deviceMajorVersion = parseInt(baseVersionSplit[0] || '0', 10);
      const deviceMinorVersion = parseInt(baseVersionSplit[1] || '0', 10);
      const deviceReleaseVersion = parseInt(baseVersionSplit[2] || '0', 10);
      const deviceBuildVersion = parseInt(buildNumber || '0', 10);

      const majorVDiff = appStoreMajorVersion > deviceMajorVersion;
      const minorVDiff =
        appStoreMajorVersion === deviceMajorVersion &&
        appStoreMinorVersion > deviceMinorVersion;
      const releaseVDiff =
        appStoreMajorVersion === deviceMajorVersion &&
        appStoreMinorVersion === deviceMinorVersion &&
        appStoreReleaseVersion > deviceReleaseVersion;
      const buildVDiff =
        appStoreMajorVersion === deviceMajorVersion &&
        appStoreMinorVersion === deviceMinorVersion &&
        appStoreReleaseVersion === deviceReleaseVersion &&
        appStoreBuildVersion > deviceBuildVersion;

      if (majorVDiff || minorVDiff || releaseVDiff || buildVDiff) {
        Alert.alert(
          'Update Required',
          'A new update to the Fairchain app is available on the AppStore.',
          [
            {
              text: 'Update',
              onPress: () => {
                const link =
                  'itms-apps://apps.apple.com/id/app/fairchain/id1604458973?l=id';
                Linking.canOpenURL(link).then((supported) => {
                  if (supported) Linking.openURL(link);
                });
              },
            },
          ],
        );
      }
    } catch (error: any) {
      LogError.logError(
        `(checkForNewAppVersion) Error: ${error.message || error}`,
      );
    }
  };

  const handleAppStateChanged = (nextAppState: AppStateStatus) => {
    if (
      appState.current.match(/inactive|background/) &&
      nextAppState === 'active' &&
      Platform.OS !== 'web'
    ) {
      try {
        // Check for EXPO EAS Updates
        Updates.checkForUpdateAsync().then((result) => {
          setUpdateAvailable(result.isAvailable);
        });

        // Check for AppStore Updates
        getAppVersion()
          .then(checkForNewAppVersion)
          .catch((error) => {
            LogError.logError(
              `Error in getting app version from server | ${error.message}`,
            );
          });
      } catch {
        // No updates found
      }
    }

    appState.current = nextAppState;
  };

  useEffect(() => {
    const subscription = AppState.addEventListener(
      'change',
      handleAppStateChanged,
    );
    return () => subscription.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return updateAvailable;
};
