import intro from 'assets/images/onboarding/intro.png';
import { GraphikTextMd, MediumTitle } from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { Image, Platform, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ColOnboardingScreen1: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View style={TailwindResponsive(`items-center`)}>
      <MediumTitle style={TailwindResponsive(`mb-8`)}>
        Welcome to Fairchain! 👋
      </MediumTitle>

      <View style={TailwindResponsive(`mb-4`)}>
        {Platform.OS === 'web' ? (
          <img src={intro} style={TailwindResponsive(`h-52`)} />
        ) : (
          <Image
            source={intro}
            style={{ height: 200, width: 200 }}
            resizeMode="contain"
          />
        )}
      </View>

      <GraphikTextMd>
        Fairchain makes managing collections of any size simple and secure.
        Leverage our suite of collection management tools to create a
        comprehensive and private digital record of your entire collection.
      </GraphikTextMd>
    </View>
  );
};

export default ColOnboardingScreen1;
