import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import InfoIcon from 'components/graphics/InfoIcon';
import CertifiedWorkBanner from 'components/shared/banners/CertifiedWorkBanner';
import { TextButton } from 'components/shared/button';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { DefaultTextInput } from 'components/shared/form';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import {
  GraphikLightTextMd,
  GraphikMediumTextMd,
  GraphikSemiTextMd,
  GraphikTextMd,
  GraphikTextSm,
  IBMTextMd,
} from 'components/shared/styled';
import { SITE_BASE_URL } from 'constants/constants';
import { AssetType, ButtonAlignment } from 'constants/enums';
import { FCStackNavProp } from 'navigation';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Linking, Pressable, TextInput, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { DetailsData } from 'store/artworkDetails/apollo';
import { setSelectedWorks } from 'store/transferWork';
import { getUser } from 'store/user';
import Styles from 'style';
import { CertificationStatus, getDisplayName } from 'types';
import { formatEditionText } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { getWorkDimensions, isRetroTransaction } from 'utilities/Work';
import {
  useArtistCanIssueCertificate,
  useCanAccessTransaction,
  useConsignView,
  useGetContract,
  useGetIsOwner,
  useUpdateArtworkPrivateInfo,
} from '../hooks';
import ADSettings from './ADSettings';
import { ADSupportingDocuments } from './ADSupportingDocuments';
import { Provenance } from './Provenance';
import { RegisterBlockchain } from './RegisterBlockchain';
import { Royalties } from './Royalties';
import { CollectorRetroRequest } from './collectorRetro/CollectorRetroRequest';

export const ADView: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const dispatch = useDispatch();
  const navigation = useNavigation<FCStackNavProp>();
  const userInfo = useSelector(getUser);

  const canConsign = useReactiveVar(DetailsData.canConsign);
  const work = useReactiveVar(DetailsData.work);

  const [isEditing, setIsEditing] = useState(false);
  const [tempSeller, setTempSeller] = useState('');
  const [tempPurchaseAmount, setTempPurchaseAmount] = useState('');
  const [tempPurchaseDate, setTempPurchaseDate] = useState('');
  const [tempLocation, setTempLocation] = useState('');
  const [tempPrivateNote, setTempPrivateNote] = useState('');

  const setDefaults = useCallback(() => {
    setTempSeller(work?.salesInfo?.seller || '');
    setTempPurchaseAmount(work?.salesInfo?.purchaseAmount || '');
    setTempPurchaseDate(work?.salesInfo?.purchaseDate || '');
    setTempLocation(work?.salesInfo?.location || '');
    setTempPrivateNote(work?.privateNote || '');
  }, [work]);

  useEffect(() => {
    if (work) {
      setDefaults();
    }
  }, [setDefaults, work]);

  const { updateArtworkPrivateInfoHandler } = useUpdateArtworkPrivateInfo(
    work?.id,
    userInfo?.id,
    tempSeller,
    tempPurchaseAmount,
    tempPurchaseDate,
    tempLocation,
    tempPrivateNote,
    work,
  );

  const showPublicCert = useMemo(
    () => userInfo && userInfo.enablePublicCerts && work?.certified,
    [userInfo, work],
  );

  const artistCanIssueCert = useArtistCanIssueCertificate();
  const canAccessTransaction = useCanAccessTransaction();
  const isConsignView = useConsignView();
  const isOwner = useGetIsOwner();
  const workDimensions = getWorkDimensions(work);
  const showPrivateWorkInfo =
    userInfo?.isCollector && !work?.lastCompletedTransactionId;

  const buttonLabel = isRetroTransaction(work)
    ? 'Issue Certificate'
    : 'Generate Sales Agreement';
  const viewCertificateText = isRetroTransaction(work)
    ? 'View Draft Certificate'
    : 'View Certificate';
  const viewPublicCertText = isRetroTransaction(work)
    ? 'View Draft of Public Certificate'
    : 'View Public Certificate';

  const { getContract } = useGetContract(
    work?.lastCompletedTransactionId || '',
  );

  const contractHandler = useCallback(async () => {
    await getContract();
  }, [getContract]);

  const isCollectible = work?.assetType === AssetType.Collectible;

  const assetType = work?.assetType || '';
  let assetText;
  if (assetType === AssetType.Collectible) {
    assetText = 'Item';
  } else if (assetType === AssetType.Artwork) {
    assetText = 'Artwork';
  } else {
    // Items in the DB with no assetType are assumed to be Artwork
    assetText = 'Artwork';
  }

  if (!work) return null;

  return (
    <View style={TailwindResponsive('flex-1')}>
      <View style={TailwindResponsive('mb-12')}>
        <View style={TailwindResponsive(`flex flex-row justify-between`)}>
          <GraphikSemiTextMd testID="ADArtistName">
            {getDisplayName(work.artist)}
          </GraphikSemiTextMd>

          {(work.certified ||
            work.certificationStatus === CertificationStatus.adminApproved) &&
            isOwner && (
              <Pressable
                onPress={() => {
                  DetailsData.isCertificateView(true);
                }}
              >
                <GraphikTextSm color={Styles.Colours.Primary}>
                  {viewCertificateText}
                </GraphikTextSm>
              </Pressable>
            )}
        </View>

        <GraphikSemiTextMd testID="ADWorkTitle">{work.title}</GraphikSemiTextMd>
        {isCollectible && (
          <>
            <View style={TailwindResponsive(`flex flex-row justify-between`)}>
              <GraphikSemiTextMd testID="ADWorkYear">
                {work.yearProduced}
              </GraphikSemiTextMd>
              {!!work.lastCompletedTransactionId &&
                work.owner.id === userInfo?.id && (
                  <Pressable
                    onPress={() => {
                      contractHandler();
                    }}
                  >
                    <GraphikTextSm color={Styles.Colours.Primary}>
                      View Item Contract
                    </GraphikTextSm>
                  </Pressable>
                )}
            </View>
            {!!formatEditionText(work) && (
              <GraphikLightTextMd>{formatEditionText(work)}</GraphikLightTextMd>
            )}
            <GraphikLightTextMd>
              {work.collectible?.materials}
            </GraphikLightTextMd>
            <GraphikLightTextMd>
              {work.collectible?.measurements}
            </GraphikLightTextMd>
            <GraphikLightTextMd>{work.medium}</GraphikLightTextMd>
            {work.inventoryNumber && (
              <GraphikLightTextMd>
                Inventory Number: {work.inventoryNumber}
              </GraphikLightTextMd>
            )}
          </>
        )}
        {!isCollectible && (
          <>
            <View style={TailwindResponsive(`flex flex-row justify-between`)}>
              <GraphikSemiTextMd testID="ADWorkYear">
                {work.yearProduced}
              </GraphikSemiTextMd>
              {!!work.lastCompletedTransactionId &&
                work.owner.id === userInfo?.id && (
                  <Pressable
                    onPress={() => {
                      contractHandler();
                    }}
                  >
                    <GraphikTextSm color={Styles.Colours.Primary}>
                      View Artwork Contract
                    </GraphikTextSm>
                  </Pressable>
                )}
            </View>
            {!!formatEditionText(work) && (
              <GraphikLightTextMd>{formatEditionText(work)}</GraphikLightTextMd>
            )}
            {!!workDimensions && (
              <GraphikLightTextMd>{workDimensions}</GraphikLightTextMd>
            )}
            <GraphikLightTextMd>{work.medium}</GraphikLightTextMd>
            {work.inventoryNumber && (
              <GraphikLightTextMd>
                Inventory Number: {work.inventoryNumber}
              </GraphikLightTextMd>
            )}
          </>
        )}
      </View>

      {isCollectible &&
        work?.collectible?.contributors &&
        work.collectible.contributors.length > 0 && (
          <View style={TailwindResponsive('mb-12')}>
            <View style={TailwindResponsive(`flex flex-col justify-between`)}>
              <GraphikSemiTextMd>Contributors</GraphikSemiTextMd>
              {work.collectible.contributors.map((contributor) => (
                <GraphikLightTextMd>
                  {contributor.firstName} {contributor.lastName},{' '}
                  {contributor.role}
                </GraphikLightTextMd>
              ))}
            </View>
          </View>
        )}

      <View style={TailwindResponsive('flex flex-col mb-12')}>
        <GraphikSemiTextMd>Fairchain {assetText} ID</GraphikSemiTextMd>
        <IBMTextMd>{work.workIdentifier}</IBMTextMd>
      </View>

      {(work.certified || (isOwner && userInfo?.isGallery)) && (
        <Royalties work={work} />
      )}

      {!isRetroTransaction(work) && work.certified && isOwner && (
        <View style={TailwindResponsive(`mt-12`)}>
          <Provenance provenance={work.provenance} />
        </View>
      )}

      {work.digitalAssetURLs && work.digitalAssetURLs.length > 0 && (
        <>
          <GraphikSemiTextMd
            style={TailwindResponsive('mb-4 border-b border-Gray2')}
          >
            Download
          </GraphikSemiTextMd>

          {work.notes && <GraphikTextMd>{work.notes}</GraphikTextMd>}

          <View style={TailwindResponsive('mb-12')}>
            {work.digitalAssetURLs.map((digitalAsset) => (
              <TextButton
                key={digitalAsset.url}
                label={`${digitalAsset.title}`}
                style={{
                  textDecorationLine: 'underline',
                }}
                paddingHorizontal={0}
                alignment={ButtonAlignment.Left}
                onPress={() => {
                  window.open(digitalAsset.url);
                }}
              />
            ))}
          </View>
        </>
      )}
      {!!work.additionalInfo && (
        <View style={TailwindResponsive('mb-12 mt-12')}>
          <GraphikSemiTextMd
            style={TailwindResponsive('mb-4 border-b border-Gray2')}
          >
            Additional {assetText} Information
          </GraphikSemiTextMd>

          <GraphikTextMd>{work.additionalInfo}</GraphikTextMd>
        </View>
      )}

      {showPrivateWorkInfo &&
        (!!tempSeller ||
          !!tempPurchaseAmount ||
          !!tempPurchaseDate ||
          !!tempLocation ||
          isEditing) && (
          <GraphikSemiTextMd
            style={TailwindResponsive('mb-4 border-b border-Gray2 mt-12')}
          >
            Private Inventory Information
          </GraphikSemiTextMd>
        )}

      {showPrivateWorkInfo && (!!work.salesInfo || isEditing) && (
        <>
          {(!!tempSeller || isEditing) && (
            <DefaultTextInput
              value={tempSeller}
              label="Seller"
              handleChange={(input) => {
                setTempSeller(input);
              }}
              editable={isEditing}
            />
          )}
          {(!!tempPurchaseAmount || isEditing) && (
            <DefaultTextInput
              value={tempPurchaseAmount}
              label="Purchase Amount"
              handleChange={(input) => {
                setTempPurchaseAmount(input);
              }}
              editable={isEditing}
            />
          )}
          {(!!tempPurchaseDate || isEditing) && (
            <DefaultTextInput
              value={tempPurchaseDate}
              label="Purchase Date"
              handleChange={(input) => {
                setTempPurchaseDate(input);
              }}
              editable={isEditing}
            />
          )}
          {(!!tempLocation || isEditing) && (
            <DefaultTextInput
              value={tempLocation}
              label="Location"
              handleChange={(input) => {
                setTempLocation(input);
              }}
              editable={isEditing}
            />
          )}
        </>
      )}

      {(!!tempPrivateNote || isEditing) && (
        <>
          <GraphikSemiTextMd style={TailwindResponsive('mt-4 mb-4 ')}>
            Private Notes
          </GraphikSemiTextMd>
          <TextInput
            style={{
              borderWidth: 0.5,
              height: 200,
              fontSize: Styles.Fonts.FontSize.Medium,
              fontFamily: Styles.Fonts.FontFamily.SansRegular,
              borderColor: '#00000099',
              paddingHorizontal: 25,
              paddingTop: 25,
              verticalAlign: 'top',
            }}
            value={tempPrivateNote}
            onChangeText={(input) => {
              setTempPrivateNote(input);
            }}
            editable={isEditing}
            placeholder="Document any other details related to the work. These notes will only ever be visible to you. If this work is ever sold, these details will not be shared."
            placeholderTextColor={Styles.Colours.Gray2}
            multiline
          />
        </>
      )}

      <ADSupportingDocuments work={work} />
      <ADSettings />

      {work.certified && (
        <View>
          <CertifiedWorkBanner assetIsCollectible={isCollectible} />
        </View>
      )}
      {!isConsignView && (
        <View style={TailwindResponsive('mt-12')}>
          {artistCanIssueCert && (
            <GraphikTextSm style={TailwindResponsive(`mb-8`)}>
              The owner of this work has requested that you certify this work
              using Fairchain. Please review the work details and images, and if
              you approve of the collector&apos;s request, click “Issue
              Certificate” below. Please contact{' '}
              <Pressable
                onPress={() => {
                  Linking.openURL('mailto: support@fairchain.art');
                }}
              >
                support@faichain.art
              </Pressable>{' '}
              with any questions.
            </GraphikTextSm>
          )}

          {canAccessTransaction &&
            !work.currentTransactionId &&
            isRetroTransaction(work) &&
            !work.certified && (
              <View style={TailwindResponsive('flex-row mb-4')}>
                <View style={TailwindResponsive('mr-2 pt-0.5')}>
                  <InfoIcon width={18} />
                </View>
                <GraphikTextSm>
                  Certificate issuance requires the work to be certified. Once
                  the artist has certified this work you will be able to issue
                  this certificate.
                </GraphikTextSm>
              </View>
            )}

          <View
            style={TailwindResponsive(
              `flex flex-row justify-between mob:flex-col mob:mb-24 mobWeb:flex-col`,
            )}
          >
            <View style={TailwindResponsive('flex-1 xl:flex xl:flex-row')}>
              {showPrivateWorkInfo &&
                isOwner &&
                (isEditing ? (
                  <>
                    <TextButton
                      label="Save"
                      onPress={() => {
                        setIsEditing(false);
                        updateArtworkPrivateInfoHandler();
                      }}
                    />
                    <TextButton
                      label="Cancel"
                      onPress={() => {
                        setIsEditing(false);
                      }}
                    />
                  </>
                ) : (
                  <TextButton
                    style={{ textDecorationLine: 'underline', paddingLeft: 0 }}
                    label="Edit Private Details"
                    onPress={() => {
                      setIsEditing(true);
                    }}
                    color={Styles.Colours.Gray2}
                  />
                ))}
              <View
                style={TailwindResponsive(
                  `mb-2 w-full xl:w-fit xl:mb-0 xl:mr-2`,
                )}
              >
                <CollectorRetroRequest />
              </View>
              {canAccessTransaction && (
                <View style={TailwindResponsive(`w-full xl:w-fit`)}>
                  {!work.currentTransactionId ? (
                    <FairchainButton
                      textStyle={TailwindResponsive('text-Light1')}
                      buttonStyle={TailwindResponsive(
                        'bg-Primary px-6 rounded-full',
                      )}
                      disabled={isRetroTransaction(work) && !work.certified}
                      label={buttonLabel}
                      onPress={() => {
                        dispatch(setSelectedWorks([work.id]));
                        navigation.push('TransferWork');
                      }}
                    />
                  ) : (
                    <FairchainButton
                      textStyle={TailwindResponsive('text-Light1')}
                      buttonStyle={TailwindResponsive(
                        'bg-Dark1 px-6 rounded-full',
                      )}
                      label="View Sales Offer"
                      onPress={() => {
                        navigation.push('TransactionDetail', {
                          transactionId: work.currentTransactionId,
                        });
                      }}
                    />
                  )}
                </View>
              )}

              {!!canConsign && (
                <View
                  style={TailwindResponsive(
                    `mt-2 w-full xl:mt-0 xl:ml-2 xl:w-fit`,
                  )}
                >
                  <FairchainButton
                    textStyle={TailwindResponsive('text-Light1')}
                    buttonStyle={TailwindResponsive(
                      'bg-Dark1 px-6 rounded-full',
                    )}
                    label="Consign"
                    onPress={() => {
                      navigation.push('Consignment', {
                        workId: work.id,
                      });
                    }}
                  />
                </View>
              )}
            </View>

            {((!!showPublicCert && work.certified) ||
              work.assetType === AssetType.Collectible) && ( // Link is always viewable for collectibles
              <View
                style={TailwindResponsive(
                  `flex flex-row items-center mobWeb:mt-8`,
                )}
              >
                <ExternalLink
                  link={`${SITE_BASE_URL}/details/${work.id}/public`}
                >
                  <GraphikMediumTextMd
                    style={TailwindResponsive(`underline mr-2`)}
                  >
                    {viewPublicCertText}
                  </GraphikMediumTextMd>
                </ExternalLink>
              </View>
            )}
          </View>

          <RegisterBlockchain />
        </View>
      )}
    </View>
  );
};
export default ADView;
