import { RoyaltyInput } from 'components/shared/form';
import {
  GraphikMediumTextMd,
  GraphikSemiTextMd,
  GraphikSemiTextSm,
  GraphikTextTiny,
} from 'components/shared/styled';
import { formatRoyaltyInput } from 'components/views/transferWork';
import DetailsLineItem from 'components/views/transferWork/components/DetailsLineItem';
import React, { FunctionComponent } from 'react';
import { Platform, View } from 'react-native';
import Styles from 'style';
import { DetailsLineItemTitleStyle } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { IDiminishedRoyaltySection } from './DiminishedRoyaltySection';

export const DiminishedRoyaltyGSASection: FunctionComponent<
  IDiminishedRoyaltySection
> = ({
  diminishedArtistError,
  diminishedArtistValue,
  diminishedGalleryError,
  diminishedGalleryValue,
  diminishedTotalValueError,
  isGallery,
  setDiminishedArtistError,
  setDiminishedArtistValue,
  setDiminishedGalleryError,
  setDiminishedGalleryValue,
  totalDiminishedValue,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View style={TailwindResponsive(`flex-1 mt-6 mobWeb:mt-3`)}>
      <View style={TailwindResponsive(`mb-8`)}>
        <GraphikSemiTextSm>And thereafter:</GraphikSemiTextSm>
      </View>

      <DetailsLineItem
        title="Artist Resale Commission"
        titleStyle={DetailsLineItemTitleStyle.regular}
      >
        <RoyaltyInput
          errorMessage={diminishedArtistError}
          handleChange={(input) => {
            const parsedInput = formatRoyaltyInput(input);
            setDiminishedArtistValue(parsedInput);
            setDiminishedArtistError('');
          }}
          style={TailwindResponsive('w-full')}
          value={diminishedArtistValue}
        />
        <GraphikTextTiny color={Styles.Colours.Gray2}>
          * Artist Resale Commission Rate
        </GraphikTextTiny>
      </DetailsLineItem>

      {isGallery && (
        <>
          <DetailsLineItem
            title="Gallery Resale Commission"
            titleStyle={DetailsLineItemTitleStyle.regular}
          >
            <RoyaltyInput
              errorMessage={diminishedGalleryError}
              handleChange={(input) => {
                const parsedInput = formatRoyaltyInput(input);
                setDiminishedGalleryValue(parsedInput);
                setDiminishedGalleryError('');
              }}
              style={TailwindResponsive('w-full')}
              value={diminishedGalleryValue}
            />
            <GraphikTextTiny color={Styles.Colours.Gray2}>
              * Gallery Resale Commission Rate
            </GraphikTextTiny>
          </DetailsLineItem>

          {Platform.OS !== 'web' && (
            <View
              style={TailwindResponsive(
                'flex flex-row items-center justify-center mt-4',
              )}
            >
              <View style={TailwindResponsive('mob:w-1/2')}>
                <GraphikSemiTextMd>
                  Total Resale Commission Rate
                </GraphikSemiTextMd>
              </View>
              <View
                style={TailwindResponsive(
                  `flex mob:flex-row h-14 mb-2 items-center justify-center mob:w-1/2`,
                )}
              >
                <GraphikMediumTextMd>{`${totalDiminishedValue}%`}</GraphikMediumTextMd>
              </View>
            </View>
          )}

          <GraphikTextTiny
            style={{
              ...TailwindResponsive(``),
              color: Styles.Colours.Error,
            }}
          >
            {diminishedTotalValueError}
          </GraphikTextTiny>
        </>
      )}
    </View>
  );
};

export default DiminishedRoyaltyGSASection;
