import { FunctionComponent } from 'react';
import { Animated, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ICenteredImageProps } from './CenteredImage';

export const ProgressiveImage: FunctionComponent<ICenteredImageProps> = ({
  altText,
  uri,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const split = uri?.split('&tr=') || [''];

  const imageAnimated = new Animated.Value(0);
  const middleAnimated = new Animated.Value(0);
  const thumbnailAnimated = new Animated.Value(0);
  const thumbnailSrc = `${split[0]}&tr=w-50`;
  const middleSrc = `${split[0]}&tr=w-500`;

  const handleThumbnailLoad = () => {
    Animated.timing(thumbnailAnimated, {
      toValue: 1,
      useNativeDriver: false,
    }).start();
  };
  const handleMiddleLoad = () => {
    Animated.timing(middleAnimated, {
      toValue: 1,
      useNativeDriver: false,
    }).start();
  };
  const onImageLoad = () => {
    Animated.timing(imageAnimated, {
      toValue: 1,
      useNativeDriver: false,
    }).start();
  };

  return (
    <View style={TailwindResponsive('flex-1')}>
      <Animated.Image
        accessibilityLabel={altText}
        blurRadius={2}
        onLoad={handleThumbnailLoad}
        resizeMode="contain"
        source={{ uri: thumbnailSrc }}
        style={[
          TailwindResponsive(`flex-1 m-3`),
          { opacity: thumbnailAnimated },
        ]}
      />
      <Animated.Image
        accessibilityLabel={altText}
        blurRadius={1}
        onLoad={handleMiddleLoad}
        resizeMode="contain"
        source={{ uri: middleSrc }}
        style={[
          TailwindResponsive(
            `flex-1 m-3 absolute top-0 left-0 right-0 bottom-0`,
          ),
          { opacity: middleAnimated },
        ]}
      />
      <Animated.Image
        accessibilityLabel={altText}
        onLoad={onImageLoad}
        resizeMode="contain"
        source={{ uri }}
        style={[
          TailwindResponsive(
            `flex-1 m-3 absolute top-0 left-0 right-0 bottom-0`,
          ),
          { opacity: imageAnimated },
        ]}
      />
    </View>
  );
};

export default ProgressiveImage;
