import React, { FunctionComponent } from 'react';
import Svg, { G, Line } from 'react-native-svg';
import { ISVGProps } from './Types';

export const ExitButtonIcon: FunctionComponent<ISVGProps> = ({ color }) => (
  <Svg width="28.284" height="28.284" viewBox="0 0 28.284 28.284" fill={color}>
    <G id="Group_9" data-name="+" transform="translate(14.142) rotate(45)">
      <Line
        id="Line_177"
        data-name="Line 177"
        y2="20"
        transform="translate(9.778)"
        fill="none"
        stroke={color}
        strokeWidth="1"
      />
      <Line
        id="Line_178"
        data-name="Line 178"
        x1="20"
        transform="translate(0 9.778)"
        fill="none"
        stroke={color}
        strokeWidth="1"
      />
    </G>
  </Svg>
);

export default ExitButtonIcon;
