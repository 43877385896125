import { ImagesWithThumbnails } from 'components/shared/image';
import {
  GraphikMediumTextMd,
  GraphikMediumTextSm,
  GraphikSemiTextLg,
  GraphikTextMd,
  GraphikTextSm,
  IBMPlexMonoMediumMd,
  MediumTitle,
} from 'components/shared/styled';
import currency from 'currency.js';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { getIsRetro } from 'store/transferWork';
import { CertificationStatus, WorkWithObject, getDisplayName } from 'types';
import {
  formatEditionText,
  getCurrencySymbol,
  getWorkDimensions,
  isCollectible,
  isRetroTransaction,
} from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { IConfirmationDetailsProps } from '../Confirmation';
import { Clickwrap } from './Clickwrap';
import ConfirmationRoyalties from './ConfirmationRoyalties';
import ConfirmationTransactionValue from './ConfirmationTransactionValue';

export const TransferWorkConfirmationDetails: FunctionComponent<
  IConfirmationDetailsProps
> = ({
  cardFee,
  finalForm,
  getVars,
  hasInvoice,
  invoiceTotal,
  passFees,
  shippingValue,
  taxValue,
  works,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const isMultiwork = works.length > 1;
  const assetsAreCollectibles =
    works.length > 0 ? isCollectible(works[0] as WorkWithObject) : false;
  const currencySymbol = getCurrencySymbol(finalForm.currency);
  const transIsRetro = useSelector(getIsRetro);

  const headerVerbiage = transIsRetro
    ? 'Review Certification Details'
    : 'Review Sales Offer';

  const bottomMarginTop = works.length === 1 ? '-mt-44' : '';

  return (
    <View>
      <GraphikSemiTextLg>{headerVerbiage}</GraphikSemiTextLg>
      <View
        style={TailwindResponsive(`flex flex-row mb-5 mt-14 mobWeb:flex-col`)}
      >
        <View style={TailwindResponsive(`flex-1 pr-16 mobWeb:pr-0`)}>
          {works.map((work, index) => {
            const formItem = finalForm.items[index];
            const artworkValue = currency(formItem?.artworkValue || 0, {
              symbol: currencySymbol,
              precision: 0,
            }).format();
            const discount = formItem?.discountApplied
              ? currency(formItem?.discount || 0).value
              : 0;
            const discountAmount = currency(formItem?.artworkValue || 0, {
              symbol: currencySymbol,
            })
              .multiply((formItem?.discount || 0) / 100)
              .format();
            const discountedValue = currency(formItem?.artworkValue || 0, {
              symbol: currencySymbol,
            })
              .subtract(
                currency(formItem?.artworkValue || 0).multiply(
                  (formItem?.discount || 0) / 100,
                ),
              )
              .format();
            return (
              <>
                <View
                  style={TailwindResponsive(`flex-row mb-4 mobWeb:flex-col`)}
                >
                  <View key={work.id} style={TailwindResponsive(`flex-1`)}>
                    <View style={TailwindResponsive(`border-b pb-12`)}>
                      {!!isMultiwork && (
                        <GraphikMediumTextSm>
                          {`${assetsAreCollectibles ? 'Item' : 'Artwork'} #${
                            index + 1
                          }`}
                        </GraphikMediumTextSm>
                      )}
                      <GraphikMediumTextSm>
                        {getDisplayName(work.artist)!}
                      </GraphikMediumTextSm>
                      <GraphikMediumTextSm>{work.title}</GraphikMediumTextSm>
                      {formatEditionText(work).length > 0 && (
                        <GraphikMediumTextSm>
                          {formatEditionText(work)}
                        </GraphikMediumTextSm>
                      )}
                      <GraphikMediumTextSm style={TailwindResponsive(`mb-6`)}>
                        {work.yearProduced}
                      </GraphikMediumTextSm>

                      <GraphikTextSm>
                        {getWorkDimensions(work) ||
                          work.collectible?.measurements}
                      </GraphikTextSm>
                      <GraphikTextSm style={TailwindResponsive(`mb-10`)}>
                        {work.medium || work.collectible?.materials}
                      </GraphikTextSm>

                      <GraphikTextSm style={TailwindResponsive(`mb-1`)}>
                        {`Fairchain ${
                          assetsAreCollectibles ? 'Item' : 'Artwork'
                        } ID`}
                      </GraphikTextSm>
                      <IBMPlexMonoMediumMd>
                        {work.workIdentifier}
                      </IBMPlexMonoMediumMd>
                    </View>

                    {(work.certified ||
                      work.certificationStatus ===
                        CertificationStatus.adminApproved) && (
                      <View style={TailwindResponsive(`border-b pb-12 pt-12`)}>
                        <ConfirmationRoyalties
                          finalForm={finalForm}
                          work={work}
                          index={index}
                        />
                      </View>
                    )}

                    {!isRetroTransaction(work) && (
                      <View style={TailwindResponsive(`border-b pb-12 pt-12`)}>
                        {discount > 0 ? (
                          <>
                            <View
                              style={TailwindResponsive(
                                `flex flex-row justify-between`,
                              )}
                            >
                              <GraphikTextSm>
                                {`${
                                  assetsAreCollectibles
                                    ? 'List Price'
                                    : 'Artwork Value'
                                } `}
                                ({`${currencySymbol} ${finalForm.currency}`})
                              </GraphikTextSm>
                              <GraphikMediumTextSm>
                                {artworkValue}
                              </GraphikMediumTextSm>
                            </View>
                            <View
                              style={TailwindResponsive(
                                `flex flex-row justify-between`,
                              )}
                            >
                              <GraphikTextSm>
                                Discount ({`${discount}%`})
                              </GraphikTextSm>
                              <GraphikMediumTextSm>
                                {`-${discountAmount}`}
                              </GraphikMediumTextSm>
                            </View>
                            <View
                              style={TailwindResponsive(
                                `flex flex-row justify-between`,
                              )}
                            >
                              <GraphikTextSm>
                                Adjusted Value (
                                {`${currencySymbol} ${finalForm.currency}`})
                              </GraphikTextSm>
                              <GraphikMediumTextSm>
                                {discountedValue}
                              </GraphikMediumTextSm>
                            </View>
                          </>
                        ) : (
                          <>
                            <GraphikTextSm>
                              {`${
                                assetsAreCollectibles
                                  ? 'List Price'
                                  : 'Artwork Value'
                              } `}
                              ({`${currencySymbol} ${finalForm.currency}`})
                            </GraphikTextSm>
                            <GraphikMediumTextSm>
                              {artworkValue}
                            </GraphikMediumTextSm>
                          </>
                        )}
                      </View>
                    )}
                  </View>

                  <View
                    key={`${work.id}_Images`}
                    style={TailwindResponsive(
                      `flex-1 pl-10 mobWeb:flex-none mobWeb:mb-8 mobWeb:mt-8 mobWeb:pl-0`,
                    )}
                  >
                    <ImagesWithThumbnails isLocal images={work.images} />
                  </View>
                </View>
                {works.length > 1 && index !== works.length - 1 && (
                  <View
                    style={TailwindResponsive(
                      `w-full border-b border-gray-400 mb-8 mt-4`,
                    )}
                  />
                )}
              </>
            );
          })}

          <View
            style={TailwindResponsive(
              `w-1/2 pr-5 ${bottomMarginTop} mobWeb:mt-0 mobWeb:pr-0 mobWeb:w-full`,
            )}
          >
            {hasInvoice && !transIsRetro && (
              <View style={TailwindResponsive(`border-b pb-12 pt-12 w-full`)}>
                <GraphikTextSm>Shipping and Handling</GraphikTextSm>
                <GraphikMediumTextSm>{shippingValue}</GraphikMediumTextSm>

                <GraphikTextSm style={TailwindResponsive(`mt-6`)}>
                  Tax
                </GraphikTextSm>
                <GraphikMediumTextSm>{taxValue}</GraphikMediumTextSm>

                {passFees && (
                  <>
                    <GraphikTextSm style={TailwindResponsive(`mt-6`)}>
                      Credit Card Processing Fee
                    </GraphikTextSm>
                    <GraphikMediumTextSm>{cardFee}</GraphikMediumTextSm>
                  </>
                )}
              </View>
            )}

            <View style={TailwindResponsive(`border-b pb-12 pt-12 w-full`)}>
              <GraphikTextSm>{transIsRetro ? 'Owner' : 'Buyer'}</GraphikTextSm>
              <GraphikMediumTextSm>
                {finalForm.firstName} {finalForm.lastName}
              </GraphikMediumTextSm>
              <GraphikMediumTextSm>{finalForm.email}</GraphikMediumTextSm>
              <GraphikMediumTextSm>{finalForm.phoneNumber}</GraphikMediumTextSm>

              <View style={TailwindResponsive(`mt-6`)}>
                <GraphikTextSm>
                  {transIsRetro ? 'Owner' : 'Buyer'} Address
                </GraphikTextSm>
                <GraphikMediumTextSm>
                  {finalForm.addressLineOne}
                </GraphikMediumTextSm>
                <GraphikMediumTextSm>
                  {finalForm.addressLineTwo}
                </GraphikMediumTextSm>
                <GraphikMediumTextSm>
                  {finalForm.city}, {finalForm.state} {finalForm.zipcode}
                </GraphikMediumTextSm>
                <GraphikMediumTextSm>{finalForm.country}</GraphikMediumTextSm>
              </View>
            </View>

            {!!finalForm.optionalMessage && (
              <View style={TailwindResponsive(`mt-12 w-full`)}>
                <GraphikMediumTextMd>Message to Buyer</GraphikMediumTextMd>
                <GraphikTextMd>{finalForm.optionalMessage}</GraphikTextMd>
              </View>
            )}

            {works.length > 1 && (
              <ConfirmationTransactionValue
                finalForm={finalForm}
                works={works}
                currencySymbol={currencySymbol}
              />
            )}

            {!!hasInvoice && !transIsRetro && (
              <MediumTitle style={TailwindResponsive(`mt-12`)}>
                An invoice for {invoiceTotal} will be sent to{' '}
                {finalForm.firstName} {finalForm.lastName}.
              </MediumTitle>
            )}

            <Clickwrap
              assetsAreCollectibles={assetsAreCollectibles}
              getVars={getVars}
            />
          </View>
        </View>
      </View>
    </View>
  );
};
