import React, { FunctionComponent } from 'react';
import { Image, Platform } from 'react-native';

interface IFCImageProps {
  altText?: string;
  height?: number;
  uri: string;
  width?: number;
}

export const FairchainImage: FunctionComponent<IFCImageProps> = ({
  altText,
  height,
  uri,
  width,
}) => {
  const adjustedAltText = altText || '';
  const adjustedHeight = height || 'auto';
  const adjustedWidth = width || 'auto';
  return (
    <>
      {Platform.OS === 'web' && (
        <img
          alt={adjustedAltText}
          src={uri}
          style={{ height: adjustedHeight, width: adjustedWidth }}
        />
      )}
      {Platform.OS !== 'web' && (
        <Image
          source={{ uri }}
          style={{ height: adjustedHeight, width: adjustedWidth }}
        />
      )}
    </>
  );
};
