import * as Font from 'expo-font';
import { Platform } from 'react-native';
import GraphikMediumFont from '../assets/fonts/Graphik-Medium.otf';
import GraphikRegularFont from '../assets/fonts/Graphik-Regular.otf';
import GraphikSemiBoldFont from '../assets/fonts/Graphik-Semibold.otf';
import GraphikItalicFont from '../assets/fonts/GraphikRegularItalic.otf';
import IBMPlexMonoMediumFont from '../assets/fonts/IBMPlexMono-Medium.otf';
import IBMPlexMonoFont from '../assets/fonts/IBMPlexMono-Regular.otf';

export const customFonts = {
  GraphikSemiBold: {
    uri: GraphikSemiBoldFont,
    display: Font.FontDisplay.SWAP,
  },
  GraphikRegular: {
    uri: GraphikRegularFont,
    display: Font.FontDisplay.SWAP,
  },
  GraphikItalic: {
    uri: GraphikItalicFont,
    display: Font.FontDisplay.SWAP,
  },
  GraphikMedium: {
    uri: GraphikMediumFont,
    display: Font.FontDisplay.SWAP,
  },
  IBMPlexMono: {
    uri: IBMPlexMonoFont,
    display: Font.FontDisplay.SWAP,
  },
  IBMPlexMonoMedium: {
    uri: IBMPlexMonoMediumFont,
    display: Font.FontDisplay.SWAP,
  },
};

let FontFamily = {
  SansSemiBold: 'GraphikSemiBold, Arial, Helvetica, serif',
  SansRegular: 'GraphikRegular, Arial, Helvetica, sans-serif',
  SansItalic: 'GraphikItalic, Arial, Helvetica, sans-serif',
  SansMedium: 'GraphikMedium, Arial, Helvetica, sans-serif',
  SansMediumIBM: 'IBMPlexMonoMedium, Arial, Helvetica, sans-serif',
  SansRegularIBM: 'IBMPlexMono, Arial, Helvetica, sans-serif',
};
if (Platform.OS !== 'web')
  FontFamily = {
    SansSemiBold: 'GraphikSemiBold',
    SansRegular: 'GraphikRegular',
    SansItalic: 'GraphikItalic',
    SansMedium: 'GraphikMedium',
    SansMediumIBM: 'IBMPlexMonoMedium',
    SansRegularIBM: 'IBMPlexMono',
  };

export type IFontFamily = (typeof FontFamily)[keyof typeof FontFamily];

export const FontSize = {
  Huge: 30,
  Large: 20,
  Medium: 16,
  Small: 15,
  Tiny: 13,
  XTiny: 9,
};

export const LineHeights = {
  Huge: 35,
  Large: 25,
  Regular: 24,
  Medium: 21,
  Small: 20,
  Tiny: 18,
  XTiny: 14,
};

export const Fonts = {
  FontFamily,
  FontSize,
  LineHeights,
};

export const IntegratedFonts = {
  tiny: { size: 13, lineHeight: 18 },
  small: { size: 15, lineHeight: 20 },
  large: { size: 20, lineHeight: 25 },
};
export type IntegratedFonts =
  (typeof IntegratedFonts)[keyof typeof IntegratedFonts];

export default Fonts;
