import { RoyaltyType } from 'constants/enums';
import currency from 'currency.js';
import { ArtworkRoyalty, CertificationStatus, Work } from 'types';

type EditionInfo = {
  isEditionWork: boolean;
  editionNumber: string;
  editionTotal: string;
  editionType: string;
  numAP: string;
  totalNumberOfAPs: string;
};

export const formatEditionText = (editionInfo: EditionInfo) => {
  if (editionInfo.isEditionWork) {
    if (editionInfo.editionType === 'Edition') {
      const baseEditionText = `Edition ${editionInfo.editionNumber} of ${editionInfo.editionTotal}`;
      return editionInfo.totalNumberOfAPs
        ? `${baseEditionText}, ${editionInfo.totalNumberOfAPs} AP`
        : baseEditionText;
    }
    if (editionInfo.editionType === 'AP') {
      const baseApText = `Artist Proof ${editionInfo.numAP} of ${editionInfo.totalNumberOfAPs}`;
      return editionInfo.editionTotal
        ? `${baseApText}, Edition of ${editionInfo.editionTotal}`
        : baseApText;
    }
  }
  return '';
};

export const getWorkDimensions = (workInput) => {
  if (!workInput) return '';
  if (workInput.dimensions) return workInput.dimensions;

  const units = workInput.dimensionUnits || 'in';
  if (workInput.depth?.trim())
    return `${workInput.height} x ${workInput.width} x ${workInput.depth} ${units}`;
  if (workInput.width?.trim() && workInput.height?.trim())
    return `${workInput.height} x ${workInput.width} ${units}`;

  return '';
};

export const getRoyaltyByType = (
  royalties: ArtworkRoyalty[],
  royaltyType: RoyaltyType,
  doesDiminish: boolean,
) => {
  const royaltiesFilteredByType = royalties.filter(
    (royaltyItem) => royaltyItem.type === royaltyType,
  );

  if (doesDiminish) {
    return royaltiesFilteredByType && royaltiesFilteredByType.length > 0
      ? royaltiesFilteredByType
          .reduce(
            (prevRoyalty, currRoyalty) =>
              prevRoyalty.add(currRoyalty.diminishedValue || 0),
            currency(0),
          )
          .value.toString()
      : '0';
  }

  return royaltiesFilteredByType && royaltiesFilteredByType.length > 0
    ? royaltiesFilteredByType
        .reduce(
          (prevRoyalty, currRoyalty) => prevRoyalty.add(currRoyalty.value),
          currency(0),
        )
        .value.toString()
    : '0';
};

export const getCombinedRoyalty = (
  royalties: ArtworkRoyalty[],
  isDiminished: boolean,
) => {
  if (isDiminished) {
    return (
      royalties
        .reduce(
          (prevRoyalty: currency, currRoyalty) =>
            prevRoyalty.add(currRoyalty.diminishedValue || 0),
          currency(0),
        )
        .value.toString() || '0'
    );
  }

  return (
    royalties
      .reduce(
        (prevRoyalty: currency, currRoyalty) =>
          prevRoyalty.add(currRoyalty.value),
        currency(0),
      )
      .value.toString() || '0'
  );
};

export const isRetroTransaction = (work: Work | null) => {
  if (
    work &&
    !work.lastCompletedTransactionId &&
    (!!work.isRetro ||
      work.certificationStatus === CertificationStatus.adminApproved)
  ) {
    return true;
  }
  return false;
};

export const isCollectible = (work: Work) => {
  if (work.collectible) return Object.keys(work.collectible).length > 0;
  return false;
};
