import React, { FunctionComponent } from 'react';
import { Text, View } from 'react-native';
import Styles from 'style';

interface IARBreadcrumbProps {
  currentStep: number;
  myStep: number;
  title: string;
}

export const BreadcrumbItem: FunctionComponent<IARBreadcrumbProps> = ({
  currentStep,
  myStep,
  title,
}) => {
  if (currentStep === myStep) {
    return (
      <View
        style={{
          borderTopColor: Styles.Colours.Dark1,
          borderTopWidth: 1,
          borderStyle: 'solid',
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: 'auto',
          paddingTop: 1.5,
        }}
      >
        <Text
          style={{
            paddingTop: 10,
            paddingBottom: 5,
            fontSize: Styles.Fonts.FontSize.Medium,
            fontFamily: Styles.Fonts.FontFamily.SansMedium,
          }}
        >
          <Text
            style={{
              borderStyle: 'solid',
              paddingBottom: 5,
            }}
          >
            {title}
          </Text>
        </Text>
      </View>
    );
  }

  if (currentStep >= myStep) {
    return (
      <View
        style={{
          borderTopColor: Styles.Colours.Dark1,
          borderTopWidth: 1,
          borderStyle: 'solid',
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: 'auto',
          paddingTop: 1.5,
        }}
      >
        <Text
          style={{
            paddingTop: 10,
            paddingBottom: 5,
            fontSize: Styles.Fonts.FontSize.Medium,
          }}
        >
          <Text
            style={{
              paddingBottom: 5,
            }}
          >
            {title}
          </Text>
        </Text>
      </View>
    );
  }

  return (
    <View
      style={{
        borderTopColor: Styles.Colours.Dark5,
        borderTopWidth: 1,
        borderStyle: 'solid',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto',
        paddingTop: 1.5,
      }}
    >
      <Text
        style={{
          paddingTop: 10,
          paddingBottom: 5,
          fontSize: Styles.Fonts.FontSize.Medium,
        }}
      >
        <Text
          style={{
            paddingBottom: 5,
            color: Styles.Colours.Dark3,
          }}
        >
          {title}
        </Text>
      </Text>
    </View>
  );
};
export default BreadcrumbItem;
