import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { ADMIN_CREATE_USER, GET_INCOMPLETE_ARTISTS } from 'api/requests.v2';
import { RoundedButton } from 'components/shared/button';
import { Checkbox } from 'components/shared/checkbox';
import { Autocomplete, DefaultTextInput } from 'components/shared/form';
import { ImagePicker } from 'components/shared/imagepicker';
import { OptionPicker } from 'components/shared/optionPicker';
import {
  GraphikMediumTextHuge,
  GraphikTextTiny,
} from 'components/shared/styled';
import { ImageSelectionPage, TextAlignment } from 'constants/enums';
import { UserContext } from 'contexts';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getSignature, resetSignature, setSignature } from 'store/admin';
import Styles from 'style';
import { Artist, AutocompleteItem } from 'types';
import {
  extractExtensionFromB64Data,
  trimFormData,
  uploadImageAsync,
} from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ICreateUserForm, createUserValidationSchema } from './Helpers';

export enum CreateUserType {
  Admin = 'admin',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Artist = 'artist',
  Brand = 'brand',
  Collector = 'collector',
  Estate = 'estate',
  Gallerist = 'gallerist',
  Other = 'other',
  SubUser = 'subuser',
  Trust = 'trust',
}

export const CreateUserForm: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const authUser = useContext(UserContext);
  const [adminCreateUser] = useMutation(ADMIN_CREATE_USER);
  const [isSuccessfullySubmitted, setSuccessfullySubmitted] = useState('');
  const [isSubmittedError, setSubmittedError] = useState('');
  const [incompleteArtists, setIncompleteArtists] = useState<Artist[]>();
  const [autocompleteItems, setAutocompleteItems] =
    useState<AutocompleteItem[]>();
  const [incompleteArtistId, setIncompleteArtistId] = useState('');

  const signatureInfo = useSelector(getSignature);
  const dispatch = useDispatch();

  const {
    control,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      artistName: '',
      brandName: '',
      estateArtistEmail: '',
      email: '',
      firstName: '',
      galleryName: '',
      getsDiscount: false,
      isBenefitOrg: false,
      lastName: '',
      mainUserEmail: '',
      phoneNumber: '',
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      role: 'artist',
      trustName: '',
    },
    resolver: yupResolver(createUserValidationSchema),
  });
  const role = watch('role');

  const onSubmit = async (data: ICreateUserForm) => {
    const trimmedData = trimFormData(data);
    let signaturePathName = '';
    if (signatureInfo) {
      const extension = extractExtensionFromB64Data(signatureInfo?.localURI);
      signaturePathName = data.firstName
        ? `${data.firstName}_${data.lastName}_Signature${extension}`
        : `${data.artistName || data.galleryName}_Signature${extension}`;
      await uploadImageAsync(
        signatureInfo.localURI,
        'artist-signatures/',
        signaturePathName,
      );
    }

    const adminResponse = await adminCreateUser({
      variables: {
        createUserInput: {
          addressLineOne: trimmedData.addressLineOne,
          addressLineTwo: trimmedData.addressLineTwo,
          adminId: authUser?.uid,
          artistName: trimmedData.artistName,
          brandName: trimmedData.brandName,
          city: trimmedData.city,
          country: trimmedData.country,
          createDate: new Date().toISOString(),
          createDateTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          email: trimmedData.email,
          estateArtistEmail: trimmedData.estateArtistEmail,
          firstName: trimmedData.firstName,
          galleryName: trimmedData.galleryName,
          getsDiscount: trimmedData.getsDiscount,
          incompleteRecord: incompleteArtistId,
          isAdmin: data.role === CreateUserType.Admin,
          isArtist: data.role === CreateUserType.Artist,
          isBenefitOrg: trimmedData.isBenefitOrg,
          isBrand: data.role === CreateUserType.Brand,
          isEstate: data.role === CreateUserType.Estate,
          isGallery: data.role === CreateUserType.Gallerist,
          isSubUser: data.role === CreateUserType.SubUser,
          isTrust: data.role === CreateUserType.Trust,
          lastName: trimmedData.lastName,
          mainUserEmail: trimmedData.mainUserEmail,
          phoneNumber: trimmedData.phoneNumber,
          signature: signatureInfo ? signaturePathName : null,
          state: trimmedData.state,
          trustName: trimmedData.trustName,
          zip: trimmedData.zip,
        },
      },
    });
    setSuccessfullySubmitted(adminResponse.data.adminCreateUser.successMessage);
    setSubmittedError(adminResponse.data.adminCreateUser.errorMessage);
    dispatch(resetSignature());
  };

  const { data } = useQuery(GET_INCOMPLETE_ARTISTS);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setTimeout(() => {
        setSuccessfullySubmitted('');
      }, 3000);
    }
  }, [reset, isSubmitSuccessful]);

  useEffect(() => {
    if (data) {
      setAutocompleteItems(
        data?.response.map((artist: Artist) => ({
          name: artist.artistName || `${artist.firstName} ${artist.lastName}`,
          firstName: artist.firstName,
          lastName: artist.lastName,
          id: artist.id,
        })),
      );
      setIncompleteArtists(data?.response);
    }
  }, [data]);

  const parseIncompleteArtists = (selectedArtist: AutocompleteItem) => {
    const targetArtistObject = incompleteArtists!.find(
      (artistObject) => artistObject.id === selectedArtist.id,
    );
    setIncompleteArtistId(targetArtistObject!.id);
    setValue('firstName', targetArtistObject!.firstName);
    setValue('lastName', targetArtistObject!.lastName);
    setValue(
      'artistName',
      `${targetArtistObject!.firstName} ${targetArtistObject!.lastName}`,
    );
  };

  const ErrorMessage = (
    <Text style={{ color: Styles.Colours.Error }}>This is required</Text>
  );

  const userTypes = useMemo(
    () => [
      { label: 'Artist', value: CreateUserType.Artist },
      { label: 'Brand', value: CreateUserType.Brand },
      { label: 'Collector', value: CreateUserType.Collector },
      { label: 'Gallerist', value: CreateUserType.Gallerist },
      { label: 'Sub User', value: CreateUserType.SubUser },
      { label: 'Admin', value: CreateUserType.Admin },
      { label: 'Estate', value: CreateUserType.Estate },
      { label: 'Trust', value: CreateUserType.Trust },
    ],
    [],
  );

  return (
    <View style={TailwindResponsive('w-1/2 pb-12')}>
      <GraphikMediumTextHuge style={TailwindResponsive(`mt-6 mb-2`)}>
        Create User
      </GraphikMediumTextHuge>

      <Controller
        control={control}
        name="role"
        render={({ field: { onChange, value } }) => (
          <View
            style={{
              ...TailwindResponsive(`mb-3 mt-3 relative`),
              zIndex: 10,
            }}
          >
            <OptionPicker
              absolute
              defaultValue="artist"
              options={userTypes}
              onChange={onChange}
              showIcon
              alignment={TextAlignment.Left}
              value={value}
            />
          </View>
        )}
      />
      {role === CreateUserType.Artist && (
        <View style={{ zIndex: 2 }}>
          <Autocomplete
            placeholder="Search Incomplete Artists"
            data={autocompleteItems!}
            handleOptionPress={(input) => {
              parseIncompleteArtists(input);
            }}
            numCharsRequired={1}
          />
        </View>
      )}
      <View>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              value={value}
              label="* First Name"
              handleChange={onChange}
              errorMessage={errors.firstName?.message}
            />
          )}
          name="firstName"
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              value={value}
              label="* Last Name"
              handleChange={onChange}
              errorMessage={errors.lastName?.message}
            />
          )}
          name="lastName"
          defaultValue=""
        />
      </View>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <DefaultTextInput
            value={value}
            label="* Email"
            handleChange={onChange}
            errorMessage={errors.email?.message}
          />
        )}
        name="email"
        defaultValue=""
      />
      {role === CreateUserType.SubUser && (
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              value={value}
              label="* Main User Email"
              handleChange={onChange}
              errorMessage={errors.mainUserEmail?.message}
            />
          )}
          name="mainUserEmail"
          defaultValue=""
        />
      )}
      {role === CreateUserType.Estate && (
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              value={value}
              label="* Artist Email (for Estates)"
              handleChange={onChange}
              errorMessage={errors.estateArtistEmail?.message}
            />
          )}
          name="estateArtistEmail"
          defaultValue=""
        />
      )}
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <DefaultTextInput
            value={value}
            label="Phone Number"
            handleChange={onChange}
          />
        )}
        name="phoneNumber"
        defaultValue=""
      />

      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <DefaultTextInput
            value={value}
            label="* Address Line One"
            handleChange={onChange}
            errorMessage={errors.addressLineOne?.message}
          />
        )}
        name="addressLineOne"
        defaultValue=""
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <DefaultTextInput
            value={value}
            label="Address Line Two"
            handleChange={onChange}
          />
        )}
        name="addressLineTwo"
        defaultValue=""
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <DefaultTextInput
            value={value}
            label="* City"
            handleChange={onChange}
            errorMessage={errors.city?.message}
          />
        )}
        name="city"
        defaultValue=""
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <DefaultTextInput
            value={value}
            label="* State"
            handleChange={onChange}
            errorMessage={errors.state?.message}
          />
        )}
        name="state"
        defaultValue=""
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <DefaultTextInput
            value={value}
            label="* Zip"
            handleChange={onChange}
            errorMessage={errors.zip?.message}
          />
        )}
        name="zip"
        defaultValue=""
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <DefaultTextInput
            value={value}
            label="* Country"
            handleChange={onChange}
            errorMessage={errors.country?.message}
          />
        )}
        name="country"
        defaultValue=""
      />

      <View style={TailwindResponsive(`mt-8`)}>
        <Controller
          control={control}
          name="getsDiscount"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              isChecked={value}
              label="Grant Intro Pricing"
              labelStyle={Styles.Fonts.FontFamily.SansRegular}
              onPress={() => {
                onChange(!value);
              }}
            />
          )}
        />
      </View>

      {role === CreateUserType.Artist && (
        <Controller
          control={control}
          render={({ field }) => (
            <DefaultTextInput
              value={field.value}
              label="Artist Name"
              handleChange={field.onChange}
            />
          )}
          name="artistName"
          defaultValue=""
        />
      )}

      {role === CreateUserType.Gallerist && (
        <View style={TailwindResponsive(`mt-8`)}>
          <Controller
            control={control}
            name="isBenefitOrg"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                isChecked={value}
                label="Is Benefit Organization"
                labelStyle={Styles.Fonts.FontFamily.SansRegular}
                onPress={() => {
                  onChange(!value);
                }}
              />
            )}
          />

          <Controller
            control={control}
            render={({ field }) => (
              <DefaultTextInput
                value={field.value}
                label="Gallery Name"
                handleChange={field.onChange}
              />
            )}
            name="galleryName"
            defaultValue=""
          />
        </View>
      )}

      {role === CreateUserType.Trust && (
        <View>
          <Controller
            control={control}
            defaultValue=""
            name="trustName"
            render={({ field }) => (
              <DefaultTextInput
                value={field.value}
                label="Trust Name"
                handleChange={field.onChange}
              />
            )}
          />
        </View>
      )}

      {role === CreateUserType.Brand && (
        <View>
          <Controller
            control={control}
            render={({ field }) => (
              <DefaultTextInput
                value={field.value}
                label="* Brand Name"
                handleChange={field.onChange}
                errorMessage={errors.brandName?.message}
              />
            )}
            name="brandName"
            defaultValue=""
          />
        </View>
      )}

      {(role === CreateUserType.Artist ||
        role === CreateUserType.Gallerist) && (
        <View style={TailwindResponsive('items-center')}>
          <ImagePicker
            image={signatureInfo?.filePath}
            imageSelectionHandler={(image) =>
              dispatch(setSignature({ value: image }))
            }
            page={ImageSelectionPage.Admin}
          />
          <GraphikTextTiny style={TailwindResponsive(`mt-2 pr-2`)}>
            Signature
          </GraphikTextTiny>
        </View>
      )}
      {!!errors.role && ErrorMessage}
      <View style={{ zIndex: -1, margin: 20 }}>
        <RoundedButton
          label="Add user"
          onPress={() => {
            handleSubmit(onSubmit)();
          }}
          backgroundColor={Styles.Colours.Primary}
          color={Styles.Colours.Light1}
        />
        {!!isSuccessfullySubmitted && (
          <Text style={{ color: Styles.Colours.Success }}>
            {isSuccessfullySubmitted}
          </Text>
        )}
        {!!isSubmittedError && (
          <Text style={{ color: Styles.Colours.Error }}>
            {isSubmittedError}
          </Text>
        )}
      </View>
    </View>
  );
};
