import { useMutation } from '@apollo/client';
import { SEND_CERT_TO_ARTIST } from 'api/requests.v2';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { GraphikSemiTextSm, GraphikTextSm } from 'components/shared/styled';
import { UserContext } from 'contexts';
import { FunctionComponent, useContext, useState } from 'react';
import { View } from 'react-native';
import Styles from 'style';
import { Work } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IPendingCertItemProps {
  work: Work;
}

export const PendingCertItem: FunctionComponent<IPendingCertItemProps> = ({
  work,
}) => {
  const authUser = useContext(UserContext);
  const { TailwindResponsive } = useTailwindResponsive();

  const [sent, setSent] = useState(false);

  const [sendCertToArtist, { loading }] = useMutation(SEND_CERT_TO_ARTIST, {
    onCompleted: () => {
      setSent(true);
    },
  });

  if (sent) return null;

  return (
    <View
      key={work.id}
      style={TailwindResponsive(
        `bg-Light5 flex flex-row flex-1 m-8 p-8 justify-between`,
      )}
    >
      <View>
        <GraphikSemiTextSm>{work.title}</GraphikSemiTextSm>
        <GraphikTextSm>{work.yearProduced}</GraphikTextSm>
        <GraphikTextSm>{work.workIdentifier}</GraphikTextSm>
      </View>

      <View>
        <GraphikSemiTextSm>
          {work.owner.firstName} {work.owner.lastName}
        </GraphikSemiTextSm>
        <GraphikTextSm>Owner</GraphikTextSm>
      </View>
      <View>
        <GraphikSemiTextSm>{work.artist.artistName}</GraphikSemiTextSm>
        {work.artist.incomplete && (
          <GraphikSemiTextSm color={Styles.Colours.Warning}>
            Incomplete
          </GraphikSemiTextSm>
        )}
        <GraphikTextSm>Artist</GraphikTextSm>
      </View>

      <FairchainButton
        buttonStyle={TailwindResponsive(`bg-Primary p-6 rounded-full`)}
        label="Send to Artist"
        loading={loading}
        loadingStyle={TailwindResponsive(`w-44`)}
        onPress={() => {
          sendCertToArtist({
            variables: {
              userId: authUser?.uid,
              workId: work.id,
            },
          });
        }}
        textStyle={TailwindResponsive(`text-Light1`)}
      />
    </View>
  );
};
