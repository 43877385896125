/* Copyright (C) 2020 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */
import { useReactiveVar } from '@apollo/client';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { WorkflowPage } from 'components/shared/page';
import { GraphikSemiTextLg, GraphikTextMd } from 'components/shared/styled';
import { ProfileRouteProp } from 'navigation';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { useSelector } from 'react-redux';
import { getUser } from 'store/user';
import Styles from 'style';
import { getDisplayName } from 'types';
import { useLogout } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import ProfileNav from './ProfileNav';
import { ProfileScreens, ProfileVars, resetProfileVars } from './apollo';
import { useGetSubUsers } from './hooks';
import PersonalInfoProfile from './screens/PersonalInfo';
import SubUserManagement from './screens/SubUserManagement';
import { ProfileSubscription } from './screens/subscription/ProfileSubscription';

export const Profile: FunctionComponent = () => {
  const logout = useLogout();
  const route = useRoute<ProfileRouteProp>();
  const { TailwindResponsive } = useTailwindResponsive();

  const userFromStore = useSelector(getUser);

  const displayName = useMemo(
    () => getDisplayName(userFromStore),
    [userFromStore],
  );

  const profileScreen = useReactiveVar(ProfileVars.screen);

  useGetSubUsers();

  useFocusEffect(
    useCallback(() => {
      resetProfileVars(
        route.params ? route.params.screen : ProfileScreens.personal,
      );

      const theParams = route.params as any;
      if (
        theParams &&
        theParams.scroll === 'signature' &&
        Platform.OS === 'web'
      ) {
        window.scroll({ top: 1600, left: 0, behavior: 'auto' });
      }
    }, [route]),
  );

  return (
    <WorkflowPage>
      <ScrollView style={TailwindResponsive(`flex flex-1`)}>
        <View style={TailwindResponsive(`pb-8`)}>
          <GraphikSemiTextLg>{displayName}</GraphikSemiTextLg>
          <GraphikTextMd color={Styles.Colours.Gray2}>
            Fairchain Account Information
          </GraphikTextMd>
        </View>

        <View
          style={TailwindResponsive(
            'flex-1 pb-12 max-w-[2000px] w-full md:flex-row',
          )}
        >
          <View style={TailwindResponsive('w-full md:w-2/3 xl:w-3/4')}>
            {profileScreen === ProfileScreens.personal && (
              <PersonalInfoProfile />
            )}

            {profileScreen === ProfileScreens.subscription && (
              <ProfileSubscription />
            )}

            {profileScreen === ProfileScreens.userManagement && (
              <SubUserManagement />
            )}
          </View>

          <View style={TailwindResponsive(`hidden md:flex md:flex-1`)}>
            <ProfileNav />
          </View>
        </View>

        <FairchainButton
          label="Sign Out"
          onPress={() => {
            if (Platform.OS === 'web') {
              (window as any).Intercom('shutdown');
            }
            logout(0);
          }}
          textStyle={TailwindResponsive(`self-start text-Dark1 underline`)}
        />
      </ScrollView>
    </WorkflowPage>
  );
};

export default Profile;
