import { useMutation } from '@apollo/client';
import { UPDATE_HAS_SEEN_COLLECTOR_ONBOARDING } from 'api/requests.v2';
import { ExitButtonIcon } from 'components/graphics';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { GraphikTextSm } from 'components/shared/styled';
import { FunctionComponent, useState } from 'react';
import { Modal, Pressable, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setSeenOnboarding } from 'store/user';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import ColOnboardingScreen1 from './Screen1';
import ColOnboardingScreen2 from './Screen2';
import ColOnboardingScreen3 from './Screen3';
import ColOnboardingScreen4 from './Screen4';
import ColOnboardingScreen5 from './Screen5';
import ColOnboardingScreen6 from './Screen6';

const MAX_ONBOARD_STEP = 5;

export const CollectorOnboarding: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { TailwindResponsive } = useTailwindResponsive();
  const user = useSelector(getUser);
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  let buttonText = 'Next';
  if (onboardingStep === 0) buttonText = 'Got it!';
  else if (onboardingStep === 5) buttonText = 'Get Started';

  const [updateHasSeenCollectorOnboarding, { loading }] = useMutation(
    UPDATE_HAS_SEEN_COLLECTOR_ONBOARDING,
    {
      onCompleted: () => {
        dispatch(setSeenOnboarding(true));
      },
    },
  );

  async function markHasSeenCollectorOnboarding() {
    const onboarding = await updateHasSeenCollectorOnboarding({
      variables: {
        userId: user?.id,
        hasSeenCollectorOnboarding: true,
      },
    });
    if (onboarding.data.response) {
      setIsVisible(false);
    }
  }

  return (
    <Modal
      style={TailwindResponsive('flex-1 items-center py-10')}
      transparent
      visible={isVisible}
    >
      <Pressable
        onPress={async () => {
          await markHasSeenCollectorOnboarding();
        }}
        style={TailwindResponsive(
          `bg-black/50 flex-1 items-center justify-center`,
        )}
      >
        <Pressable
          onStartShouldSetResponder={() => true}
          onTouchEnd={(e) => {
            e.stopPropagation();
          }}
          style={[
            TailwindResponsive(`bg-Light1 flex rounded-lg max-w-2xl w-full`),
            { cursor: 'default' },
          ]}
        >
          <View style={TailwindResponsive(`items-end pr-2 pt-2`)}>
            <Pressable
              onPress={async () => {
                await markHasSeenCollectorOnboarding();
              }}
              style={TailwindResponsive(`self-end`)}
            >
              <ExitButtonIcon color={Styles.Colours.Dark1} />
            </Pressable>
          </View>

          <View
            style={TailwindResponsive(`flex flex-col py-4 px-10 items-center`)}
          >
            {onboardingStep === 0 && <ColOnboardingScreen1 />}
            {onboardingStep === 1 && <ColOnboardingScreen2 />}
            {onboardingStep === 2 && <ColOnboardingScreen3 />}
            {onboardingStep === 3 && <ColOnboardingScreen4 />}
            {onboardingStep === 4 && <ColOnboardingScreen5 />}
            {onboardingStep === 5 && <ColOnboardingScreen6 />}

            <View
              style={TailwindResponsive(
                `mt-10 flex flex-row items-center ${
                  onboardingStep === 0 ? 'justify-end' : 'justify-between'
                } w-full`,
              )}
            >
              {onboardingStep > 0 && (
                <FairchainButton
                  buttonStyle={TailwindResponsive(
                    'bg-MidGray px-8 rounded-full',
                  )}
                  label="Back"
                  onPress={() => {
                    setOnboardingStep(onboardingStep - 1);
                  }}
                />
              )}

              {onboardingStep > 0 && (
                <GraphikTextSm style={{ color: Styles.Colours.Dark4 }}>
                  {onboardingStep} / {MAX_ONBOARD_STEP}
                </GraphikTextSm>
              )}

              <FairchainButton
                buttonStyle={TailwindResponsive(`bg-Dark1 px-8 rounded-full`)}
                label={buttonText}
                loading={loading}
                loadingStyle={TailwindResponsive(`w-28`)}
                onPress={async () => {
                  if (onboardingStep === MAX_ONBOARD_STEP) {
                    await markHasSeenCollectorOnboarding();
                  } else {
                    setOnboardingStep(onboardingStep + 1);
                  }
                }}
                textStyle={TailwindResponsive(`text-Light1`)}
              />
            </View>
          </View>
        </Pressable>
      </Pressable>
    </Modal>
  );
};

export default CollectorOnboarding;
