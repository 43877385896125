import { useReactiveVar } from '@apollo/client';
import RadioButton from 'components/shared/button/RadioButton';
import { FunctionComponent } from 'react';
import { Pressable, View } from 'react-native';
import { FileUploaderApollo } from 'store/general/file-uploader.apollo';
import { InvoiceChoice, userInvoiceChoiceVar } from 'store/transferWork/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IInvoiceSelectionProps {
  setValues;
}

export const InvoiceSelection: FunctionComponent<IInvoiceSelectionProps> = ({
  setValues,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const userInvoiceChoice = useReactiveVar(userInvoiceChoiceVar);

  return (
    <>
      <Pressable
        style={TailwindResponsive(
          `border border-r -mr-px ${
            userInvoiceChoice === InvoiceChoice.INCLUDE
              ? `border-r-white mobWeb:border-r-black`
              : 'border-r-black'
          }`,
        )}
        onPress={() => {
          userInvoiceChoiceVar(InvoiceChoice.INCLUDE);
          setValues('generateInvoice', true);
          FileUploaderApollo.Reset();
        }}
      >
        <View style={TailwindResponsive(`pl-6 pr-2`)}>
          <RadioButton
            height={20}
            width={20}
            borderWidth={1}
            label="Collect Payment & Generate Invoice"
            selected={userInvoiceChoice === InvoiceChoice.INCLUDE}
          />
        </View>
      </Pressable>

      <Pressable
        style={TailwindResponsive(
          `border-l border-r border-b -mr-px ${
            userInvoiceChoice === InvoiceChoice.UPLOAD
              ? `border-r-white mobWeb:border-r-black`
              : 'border-r-black'
          }`,
        )}
        onPress={() => {
          userInvoiceChoiceVar(InvoiceChoice.UPLOAD);
          setValues('generateInvoice', false);
        }}
      >
        <View style={TailwindResponsive(`pl-6 pr-2`)}>
          <RadioButton
            height={20}
            width={20}
            borderWidth={1}
            label="Upload Your Own Invoice"
            selected={userInvoiceChoice === InvoiceChoice.UPLOAD}
          />
        </View>
      </Pressable>

      <Pressable
        style={TailwindResponsive(
          `border-l border-r border-b -mr-px ${
            userInvoiceChoice === InvoiceChoice.DONTINCLUDE
              ? `border-r-white mobWeb:border-r-black`
              : 'border-r-black'
          }`,
        )}
        onPress={() => {
          userInvoiceChoiceVar(InvoiceChoice.DONTINCLUDE);
          setValues('generateInvoice', false);
          FileUploaderApollo.Reset();
        }}
      >
        <View style={TailwindResponsive(`pl-6 pr-2`)}>
          <RadioButton
            height={20}
            width={20}
            borderWidth={1}
            label="Don't Include an Invoice"
            selected={userInvoiceChoice === InvoiceChoice.DONTINCLUDE}
          />
        </View>
      </Pressable>
    </>
  );
};
