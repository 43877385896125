import { useReactiveVar } from '@apollo/client';
import {
  Breadcrumbs,
  artworkRegistrationSteps,
  collectibleRegistrationSteps,
} from 'components/shared/breadcrumb';
import { AssetType } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { RegisterStore } from 'store/registerWork/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useGetMaxStep } from '../Hooks';

export const RWHeader: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const assetType = detailsForm?.assetType;

  const currentStep = useReactiveVar(RegisterStore.Vars.currentStep);
  const maxStep = useGetMaxStep();

  const registrationSteps =
    assetType === AssetType.Collectible
      ? collectibleRegistrationSteps
      : artworkRegistrationSteps;

  return (
    <View style={TailwindResponsive(`mob:h-14`)}>
      {currentStep !== 3 && (
        <Breadcrumbs
          currentStep={currentStep}
          maxStep={maxStep}
          steps={registrationSteps}
        />
      )}
    </View>
  );
};

export default RWHeader;
