import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import { ISVGProps } from './Types';

export const CopyIcon: FunctionComponent<ISVGProps> = ({ color }) => (
  <Svg height="24px" width="24px" fill={color || '#000000'} viewBox="0 0 24 24">
    <Path d="M0 0h24v24H0V0z" fill="none" />
    <Path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
  </Svg>
);

export default CopyIcon;
