import { useMutation, useQuery } from '@apollo/client';
import {
  GET_USER,
  UPDATE_GALLERY_CCEMAILS,
  UPDATE_USER_INFO,
} from 'api/requests.v2';
import { RoundedButton, TextButton } from 'components/shared/button';
import { Checkbox } from 'components/shared/checkbox';
import { ErrorMessage } from 'components/shared/error';
import { DefaultTextInput } from 'components/shared/form';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import { SpinLoader } from 'components/shared/loader';
import {
  GraphikMediumTextMd,
  GraphikSemiTextLg,
} from 'components/shared/styled';
import { ButtonAlignment, UserRole } from 'constants/enums';
import { UserContext } from 'contexts';
import { debounce } from 'lodash';
import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getFeatureFlags } from 'store/general';
import {
  getIntegrationsLoading,
  getUser,
  getUserRole,
  userConnect,
  userUpdate,
} from 'store/user';
import Styles from 'style';
import { FCAnalyticEvents, logAnalyticsEvent } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import ConnectToQuickbooks from '../components/ConnectToQuickbooks';
import ConnectToStripe from '../components/ConnectToStripe';
import IdentifyWithVeriff from '../components/IdentifyWithVeriff';
import PublicCertificates from '../components/PublicCertificates';
import PublicProvenance from '../components/PublicProvenance';
import SignatureUpload from '../components/SignatureUpload';
import TwoFactorEnable from '../components/TwoFactorEnable';

export const PersonalInfoProfile: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const dispatch = useDispatch();
  const { TailwindResponsive } = useTailwindResponsive();

  const [updateUser, { error: updateUserInfoError }] =
    useMutation(UPDATE_USER_INFO);
  const [updateGalleryCcEmails, { error: updateGalleryCcEmailsError }] =
    useMutation(UPDATE_GALLERY_CCEMAILS);

  const userFromStore = useSelector(getUser);
  const featureFlags = useSelector(getFeatureFlags);
  const userRole = useSelector(getUserRole);
  const integrationsLoading = useSelector(getIntegrationsLoading);

  const [isEditing, setIsEditing] = useState(false);
  const [tempFirstName, setTempFirstName] = useState('');
  const [tempLastName, setTempLastName] = useState('');
  const [tempEmail, setTempEmail] = useState('');
  const [tempPhoneNumber, setTempPhoneNumber] = useState('');
  const [tempAddressLineOne, setTempAddressLineOne] = useState('');
  const [tempAddressLineTwo, setTempAddressLineTwo] = useState('');
  const [tempCity, setTempCity] = useState('');
  const [tempCountry, setTempCountry] = useState('');
  const [tempState, setTempState] = useState('');
  const [tempZipCode, setTempZipCode] = useState('');
  const [ccYourself, setCcYourself] = useState(true);

  const setDefaults = useCallback(() => {
    setTempFirstName(userFromStore?.firstName || '');
    setTempLastName(userFromStore?.lastName || '');
    setTempEmail(userFromStore?.email || '');
    setTempPhoneNumber(userFromStore?.phoneNumber || '');
    setTempAddressLineOne(userFromStore?.address?.billingAddressLineOne || '');
    setTempAddressLineTwo(userFromStore?.address?.billingAddressLineTwo || '');
    setTempCity(userFromStore?.address?.billingAddressCity || '');
    setTempCountry(userFromStore?.address?.billingAddressCountry || '');
    setTempState(userFromStore?.address?.billingAddressState || '');
    setTempZipCode(userFromStore?.address?.billingAddressZipcode || '');
    setCcYourself(userFromStore?.ccSalesOfferEmails || true);
  }, [userFromStore]);

  useQuery(GET_USER, {
    variables: {
      userId: authUser?.uid,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ response }) => {
      dispatch(userConnect(response));
    },
  });

  useEffect(() => {
    if (userFromStore) {
      setDefaults();
    }
  }, [setDefaults, userFromStore]);

  const updateUserHandler = useCallback(async () => {
    if (userFromStore) {
      const addressVariables = {
        billingAddressLineOne: tempAddressLineOne,
        billingAddressLineTwo: tempAddressLineTwo,
        billingAddressState: tempState,
        billingAddressCity: tempCity,
        billingAddressCountry: tempCountry,
        billingAddressZipcode: tempZipCode,
        shippingAddressLineOne: tempAddressLineOne,
        shippingAddressLineTwo: tempAddressLineTwo,
        shippingAddressState: tempState,
        shippingAddressCity: tempCity,
        shippingAddressCountry: tempCountry,
        shippingAddressZipcode: tempZipCode,
      };
      const userVariables = {
        id: userFromStore.id,
        firstName: tempFirstName,
        lastName: tempLastName,
        phoneNumber: tempPhoneNumber,
      };

      await updateUser({
        variables: {
          updateUserInput: {
            ...addressVariables,
            ...userVariables,
          },
        },
      })
        .then(() => {
          logAnalyticsEvent(FCAnalyticEvents.UserUpdate, {
            uid: authUser?.uid,
          });
          dispatch(
            userUpdate({
              ...userFromStore,
              ...userVariables,
              address: {
                ...userFromStore.address,
                ...addressVariables,
              },
            }),
          );
        })
        .catch((err) => err);
    }
  }, [
    authUser,
    dispatch,
    tempAddressLineOne,
    tempAddressLineTwo,
    tempCity,
    tempCountry,
    tempFirstName,
    tempLastName,
    tempPhoneNumber,
    tempState,
    tempZipCode,
    updateUser,
    userFromStore,
  ]);

  const handleCcSwitch = useCallback(async () => {
    const newCc = await updateGalleryCcEmails({
      variables: { userId: userFromStore?.id, ccYourself: !ccYourself },
    });
    if (newCc) setCcYourself(newCc.data.response);
  }, [ccYourself, updateGalleryCcEmails, userFromStore]);

  const disabledProp = isEditing ? { disabled: false } : { disabled: true };

  if (updateUserInfoError) return <ErrorMessage error={updateUserInfoError} />;
  if (updateGalleryCcEmailsError)
    return <ErrorMessage error={updateGalleryCcEmailsError} />;

  return (
    <>
      <GraphikSemiTextLg style={TailwindResponsive('mb-10')}>
        My Details
      </GraphikSemiTextLg>

      <DefaultTextInput
        value={tempFirstName}
        label="First Name"
        handleChange={(input) => {
          setTempFirstName(input);
        }}
        editable={isEditing && userRole !== UserRole.Artist}
      />
      <DefaultTextInput
        value={tempLastName}
        label="Last Name"
        handleChange={(input) => {
          setTempLastName(input);
        }}
        infoMessage={
          isEditing && userRole === UserRole.Artist
            ? 'Please contact support@fairchain.art if you need to edit your name'
            : ''
        }
        editable={isEditing && userRole !== UserRole.Artist}
      />
      {userRole === UserRole.Brand && userFromStore?.brandName && (
        <DefaultTextInput
          value={userFromStore?.brandName}
          label="Brand Name"
          editable={false}
        />
      )}
      <DefaultTextInput value={tempEmail} label="Email" editable={false} />
      <DefaultTextInput
        value={tempPhoneNumber}
        label="Phone Number"
        handleChange={(input) => {
          setTempPhoneNumber(input);
        }}
        {...disabledProp}
      />
      <DefaultTextInput
        value={tempAddressLineOne}
        label="Address Line One"
        handleChange={(input) => {
          setTempAddressLineOne(input);
        }}
        {...disabledProp}
      />
      <DefaultTextInput
        value={tempAddressLineTwo}
        label="Address Line Two"
        handleChange={(input) => {
          setTempAddressLineTwo(input);
        }}
        {...disabledProp}
      />
      <DefaultTextInput
        value={tempCity}
        label="City"
        handleChange={(input) => {
          setTempCity(input);
        }}
        {...disabledProp}
      />
      <DefaultTextInput
        value={tempState}
        label="State"
        handleChange={(input) => {
          setTempState(input);
        }}
        {...disabledProp}
      />
      <DefaultTextInput
        value={tempZipCode}
        label="Zip"
        handleChange={(input) => {
          setTempZipCode(input);
        }}
        {...disabledProp}
      />
      <DefaultTextInput
        value={tempCountry}
        label="Country"
        handleChange={(input) => {
          setTempCountry(input);
        }}
        {...disabledProp}
      />

      <View
        style={TailwindResponsive(
          `flex flex-row justify-between items-center mt-4 mobWeb:flex-col`,
        )}
      >
        {isEditing ? (
          <View style={TailwindResponsive(`flex flex-row`)}>
            <TextButton
              paddingHorizontal={47.5}
              onPress={() => {
                setIsEditing(!isEditing);
                updateUserHandler();
              }}
              label="Save"
            />
            <TextButton
              onPress={() => {
                setIsEditing(!isEditing);
                setDefaults();
              }}
              label="Cancel"
            />
          </View>
        ) : (
          <View style={TailwindResponsive(``)}>
            <RoundedButton
              alignment={ButtonAlignment.Left}
              backgroundColor={Styles.Colours.Dark1}
              color={Styles.Colours.Light1}
              label="Edit Account Information"
              onPress={() => {
                setIsEditing(!isEditing);
              }}
              paddingHorizontal={47.5}
            />
          </View>
        )}

        <View style={TailwindResponsive(`py-4`)}>
          <ExternalLink link="/profile/reset-password">
            <GraphikMediumTextMd color={Styles.Colours.Primary}>
              Change Password
            </GraphikMediumTextMd>
          </ExternalLink>
        </View>
      </View>

      <PublicCertificates />
      <PublicProvenance />

      <TwoFactorEnable />

      {userFromStore &&
        userRole === UserRole.Gallery &&
        featureFlags.galleryCcEmail && (
          <View style={TailwindResponsive(`mt-20`)}>
            <Checkbox
              label="CC Yourself on Sales Offer Emails?"
              isChecked={ccYourself}
              onPress={debounce(() => handleCcSwitch(), 250)}
            />
          </View>
        )}

      {integrationsLoading && (
        <View style={TailwindResponsive(`mt-12`)}>
          <SpinLoader />
        </View>
      )}

      <ConnectToStripe />
      <IdentifyWithVeriff />
      <SignatureUpload />

      {userFromStore && featureFlags.quickbooks && <ConnectToQuickbooks />}
    </>
  );
};

export default PersonalInfoProfile;
