import React, { FunctionComponent } from 'react';
import {
  ActivityIndicator,
  Platform,
  Pressable,
  Text,
  View,
} from 'react-native';
import Styles from 'style';
import { Style } from 'tailwind-rn/dist';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export interface IFairchainButtonProps {
  buttonStyle?: Style;
  disabled?: boolean;
  label: string;
  loading?: boolean;
  loadingStyle?: Style;
  onPress?: () => void;
  spinnerColor?: string;
  testId?: string;
  textStyle?:
    | {
        [key: string]: string | number;
      }
    | Style;
}

export const FairchainButton: FunctionComponent<IFairchainButtonProps> = ({
  buttonStyle,
  disabled,
  label,
  loading,
  loadingStyle,
  onPress,
  spinnerColor,
  testId,
  textStyle,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const buttonHeight: string = Platform.OS === 'web' ? 'h-12' : 'h-10';
  const disabledButtonStyle = disabled ? TailwindResponsive(`bg-Gray1`) : {};
  const disabledTextStyle = disabled ? TailwindResponsive(`text-Dark4`) : {};
  const finalButtonStyle = {
    ...TailwindResponsive(`${buttonHeight} items-center justify-center`),
    ...buttonStyle,
  };

  if (loading)
    return (
      <View
        style={[
          finalButtonStyle,
          TailwindResponsive(`items-center p-0`),
          loadingStyle,
        ]}
      >
        <ActivityIndicator color={spinnerColor} />
      </View>
    );

  return (
    <Pressable
      disabled={disabled}
      onPress={onPress}
      style={[finalButtonStyle, disabledButtonStyle]}
    >
      <Text
        testID={testId}
        style={{
          fontFamily: Styles.Fonts.FontFamily.SansMedium,
          fontSize: Styles.Fonts.FontSize.Small,
          ...textStyle,
          ...disabledTextStyle,
        }}
      >
        {label}
      </Text>
    </Pressable>
  );
};
