/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormReturn, useForm } from 'react-hook-form';
import * as yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(yup);

export interface ISignUpForm {
  addressLine1: string;
  addressLine2: string;
  agreedTimeStamp: string;
  agreedTimeZone: string;
  agreedToTerms: boolean;
  artistName: string;
  city: string;
  confirmPassword: string;
  country: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  reference: string;
  role: string;
  state: string;
  zipcode: string;
}

export const signUpFormvar = makeVar<UseFormReturn<ISignUpForm, any> | null>(
  null,
);

// Signup tracking vars
const errorMessageVar = makeVar<string>('');
const loadingVar = makeVar<boolean>(false);
const responseVar = makeVar<any | null>(null);
const signInLoadingVar = makeVar<boolean>(false);

export const SignUpVars = {
  errorMessage: errorMessageVar,
  loading: loadingVar,
  response: responseVar,
  signInLoading: signInLoadingVar,
};

export const useSetupSignUpForm = () => {
  const form = useForm<ISignUpForm>({
    defaultValues: {
      addressLine1: '',
      addressLine2: '',
      agreedTimeStamp: '',
      agreedTimeZone: '',
      agreedToTerms: false,
      artistName: '',
      city: '',
      confirmPassword: '',
      country: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      reference: '',
      role: '',
      state: '',
      zipcode: '',
    },
    resolver: yupResolver(yupSchema),
  });

  signUpFormvar(form);
};

const yupSchema = yup
  .object()
  .shape({
    agreedToTerms: yup
      .bool()
      .oneOf([true], 'You must accept our terms of service to continue'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .required('Please enter a password'),
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('This is a required field'),
    firstName: yup.string().required('This is a required field'),
    lastName: yup.string().required('This is a required field'),
    password: yup
      .string()
      .min(8, 'Password must be 8 characters and must include a number')
      .minNumbers(1, 'Password must be 8 characters and must include a number')
      .required('Please enter a password'),
  })
  .required();
