import { useNavigation } from '@react-navigation/native';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRedirectURL, setRedirect } from 'store/general';
import { AgreementViewTransaction } from './AgreeementViewTransaction';
import { AgreementViewNoTransaction } from './AgreementViewNoTransaction';

export const Agreements: FunctionComponent = () => {
  const redirectUrl = useSelector(getRedirectURL);
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [voided, setVoided] = useState(false);

  const transactionId = useMemo(() => {
    if (redirectUrl && redirectUrl.includes('transaction-details')) {
      return redirectUrl.split('/')[2];
    }
    if (redirectUrl && redirectUrl.includes('transactionId')) {
      return redirectUrl.split('=')[1];
    }
    return undefined;
  }, [redirectUrl]);

  useEffect(() => {
    if (transactionId && !voided) {
      navigation.setParams({ transactionId });
      dispatch(setRedirect(`/agreements?transactionId=${transactionId}`));
    } else {
      navigation.setParams({ transactionId: undefined });
      dispatch(setRedirect(null));
    }
  }, [transactionId, navigation, dispatch, voided]);

  return transactionId ? (
    <AgreementViewTransaction
      setVoided={setVoided}
      transactionId={transactionId}
    />
  ) : (
    <AgreementViewNoTransaction />
  );
};
