import Styles from 'style';

export const StripeCustomAppearance = {
  variables: {
    colorPrimary: Styles.Colours.Primary,
    colorBackground: '#ffffff',
    colorText: '#30313d',
    colorDanger: '#df1b41',
    fontFamily: Styles.Fonts.FontFamily.SansRegular,
    fontLineHeight: `${Styles.Fonts.LineHeights.Regular.toString()}px`,
    spacingUnit: '4px',
    borderRadius: '0px',
  },
  rules: {
    '.TabLabel': {
      fontWeight: 'normal',
    },
    '.TabLabel--selected': {
      fontWeight: 'bold',
    },
    '.Input:focus': {
      borderColor: Styles.Colours.Primary,
    },
  },
};
