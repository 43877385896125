import { useReactiveVar } from '@apollo/client';
import HeaderVars from 'components/shared/header/apollo.header';
import { GraphikTextMd } from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { Pressable } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ProfileScreens, ProfileVars } from '../apollo';

interface Props {
  label: string;
  screen: ProfileScreens;
}

export const ProfileNavButton: FunctionComponent<Props> = ({
  label,
  screen,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const currentScreen = useReactiveVar(ProfileVars.screen);

  const highLight =
    currentScreen === screen ? 'bg-Primary/10 pr-8 rounded-br-full' : '';

  return (
    <Pressable
      onPress={() => {
        ProfileVars.screen(screen);
        HeaderVars.showMenuModal(false);
      }}
      style={TailwindResponsive(`p-2 self-start ${highLight}`)}
    >
      <GraphikTextMd>{label}</GraphikTextMd>
    </Pressable>
  );
};

export default ProfileNavButton;
