import { useReactiveVar, useSubscription } from '@apollo/client';
import { USER_SUBSCRIPTION } from 'api/requests.v2';
import { useDispatch } from 'react-redux';
import { userConnect } from 'store/user';
import { FCUser } from 'store/user/apollo';

export const useSubscriptions = () => {
  const fbUser = useReactiveVar(FCUser.FirebaseUserVar);
  const dispatch = useDispatch();

  useSubscription(USER_SUBSCRIPTION, {
    variables: {
      userId: fbUser?.uid,
    },
    onData: ({ data }) => {
      dispatch(userConnect(data.data.userUpdate));
    },
  });
};
