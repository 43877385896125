import { CollectionTab } from 'constants/enums';
import React, { FunctionComponent, useCallback } from 'react';
import { FlatList } from 'react-native';
import { useSelector } from 'react-redux';
import { getCollectionTab, getGridWorks } from 'store/collection';
import { Work } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ConsignmentLineItem } from './ConsignmentLineItem';
import { ListTableLineItem } from './ListTableLineItem';

const ITEM_HEIGHT = 80;

export const ListTable: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const currentTab = useSelector(getCollectionTab);
  const works = useSelector(getGridWorks);

  const itemLayout = useCallback(
    (_, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    [],
  );

  const keyExtractor = useCallback(
    (item: Work) => `col-flatlist-key-${item.id}`,
    [],
  );

  const renderer = useCallback(
    ({ item, index }) =>
      currentTab === CollectionTab.Consigned ? (
        <ConsignmentLineItem id={index} work={item} />
      ) : (
        <ListTableLineItem
          key={`coll-list-item-${index}`}
          id={index}
          work={item}
        />
      ),
    [currentTab],
  );

  return (
    <FlatList
      contentContainerStyle={TailwindResponsive(`pb-24`)}
      data={works}
      getItemLayout={itemLayout}
      keyExtractor={keyExtractor}
      maxToRenderPerBatch={18}
      renderItem={renderer}
      snapToInterval={80}
      snapToAlignment="center"
      showsVerticalScrollIndicator={false}
      style={[{ height: 'auto' }]}
    />
  );
};
