import {
  useMutation,
  useQuery,
  useReactiveVar,
  useSubscription,
} from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  GET_TRANSACTION_DETAILS,
  MARK_TRANSACTION_VIEWED,
  TRANSACTION_DETAILS_SUBSCRIPTION,
} from 'api/requests.v2';
import { ErrorMessage } from 'components/shared/error';
import { SpinLoader } from 'components/shared/loader';
import { DetailsPage } from 'components/shared/page/DetailsPage';
import { UserContext } from 'contexts';
import { FCStackNavProp, TransactionDetailRouteProp } from 'navigation/types';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { resetAgentInfo } from 'store/agentBuyer/apollo';
import TDGQL from 'store/transactionDetails/apollo';
import { getMainUser, getUser } from 'store/user';
import LogError from 'utilities/LogError';
import TDWrapper from './TDWrapper';
import { BuyerLinkModal } from './components/BuyerLinkModal';

export const TransactionDetail: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const navigation = useNavigation<FCStackNavProp>();
  const mainUser = useSelector(getMainUser);
  const route = useRoute<TransactionDetailRouteProp>();
  const userInfo = useSelector(getUser);

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const clickwrapAccepted = useReactiveVar(TDGQL.clickwrapAcceptedVar);

  const pageTitle = (() => {
    if (Platform.OS === 'web') return '';
    return data?.isBuyerView ? 'Sales Offer' : 'Transaction Details';
  })();

  const [markAsViewed] = useMutation(MARK_TRANSACTION_VIEWED);

  const { data, loading, error, refetch } = useQuery(GET_TRANSACTION_DETAILS, {
    variables: {
      transactionId: route.params.transactionId,
    },
    onCompleted: ({ transactionDetails }) => {
      TDGQL.selectedTransactionIdVar(route.params.transactionId);

      if (
        !transactionDetails.wasViewed &&
        transactionDetails.buyerId === authUser?.uid
      ) {
        markAsViewed({
          variables: {
            transactionId: route.params.transactionId,
            userId: authUser?.uid,
          },
        });
      }
    },
  });

  resetAgentInfo();

  useEffect(() => {
    if (clickwrapAccepted && refetch) {
      const id = setTimeout(() => {
        refetch();
      }, 3000);
      setTimeoutId(id);
    }
  }, [clickwrapAccepted, refetch]);

  useEffect(() => {
    const transaction = data ? data.transactionDetails : null;

    if (transaction) {
      const userAccessDenied =
        !(
          authUser?.uid === transaction?.buyer.id ||
          authUser?.uid === transaction?.seller.id
        ) &&
        !(
          mainUser &&
          (mainUser.id === transaction?.buyer.id ||
            mainUser.id === transaction?.seller.id)
        ) &&
        !userInfo?.isAdmin;
      if (userAccessDenied) {
        LogError.logMessage(
          `User: ${authUser?.uid} was denied access to view transaction: ${transaction.id}`,
        );
        navigation.navigate('Collection');
      }

      let title = 'Sales Offer';
      if (transaction.items.length === 1) {
        const workData = transaction.items[0]?.work;
        const artistName =
          workData?.artist.artistName ||
          `${workData?.artist.firstName} ${workData?.artist.lastName}`;
        title = `Sales Offer for ${workData?.title} by ${artistName}`;
      }

      navigation.setOptions({
        title,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useSubscription(TRANSACTION_DETAILS_SUBSCRIPTION, {
    variables: {
      transactionId: route.params.transactionId,
    },
    onData: ({ data: subdata }) => {
      if (subdata && subdata.data) {
        if (timeoutId) {
          clearTimeout(timeoutId);
          setTimeoutId(null);
        }
      }
    },
  });

  return (
    <DetailsPage
      backScreen={Platform.OS !== 'web' ? 'transactions' : ''}
      title={pageTitle}
    >
      {loading && <SpinLoader />}
      {!loading && error && <ErrorMessage error={error} />}

      {data && <TDWrapper />}

      <BuyerLinkModal />
    </DetailsPage>
  );
};

export default TransactionDetail;
