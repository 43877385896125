import { useQuery } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import { GET_NAVIGATION_INFO } from 'api/requests.v2';
import AccountActive from 'assets/images/AccountActive.png';
import AccountUnfilled from 'assets/images/AccountInactive.png';
import CollectionFilled from 'assets/images/CollectionActive.png';
import CollectionUnfilled from 'assets/images/CollectionInactive.png';
import InboxFilled from 'assets/images/InboxActive.png';
import InboxUnfilled from 'assets/images/InboxInactive.png';
import TransactionsFilled from 'assets/images/TransactionsActive.png';
import TransactionsUnfilled from 'assets/images/TransactionsInactive.png';
import { RegisterWorkIcon } from 'components/graphics';
import { UserContext } from 'contexts';
import React, { FunctionComponent, useContext } from 'react';
import { Image, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications, setNotifications } from 'store/general';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import NotificationBadge from '../header/NotificationBadge';
import SubSignUpModal from '../header/SubSignUpModal';
import { useHandleRegisterWork } from '../header/hooks';
import { AppBarIcon } from './AppBarIcon';

export const AppBar: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);
  const route = useRoute();
  const { TailwindResponsive } = useTailwindResponsive();

  const handleRegisterWork = useHandleRegisterWork();

  const currentRoute = route.name;

  useQuery(GET_NAVIGATION_INFO, {
    variables: {
      userId: authUser?.uid,
    },
    onCompleted: ({ getNavigationInfo }) => {
      dispatch(setNotifications(getNavigationInfo));
    },
    pollInterval: 10000,
    skip: !authUser,
  });

  return (
    <View
      style={TailwindResponsive(
        'absolute bg-white border-black border-t bottom-0 flex flex-row h-20 inset-x-0 items-center justify-between px-4 py-4',
      )}
    >
      <AppBarIcon screen="Collection" title="Collection">
        <Image
          source={
            currentRoute === 'Collection'
              ? CollectionFilled
              : CollectionUnfilled
          }
          style={TailwindResponsive(`flex-1`)}
          resizeMode="contain"
        />
      </AppBarIcon>

      <AppBarIcon screen="Transactions" title="Transactions">
        <NotificationBadge amount={notifications.transfersToInitiate} />
        <Image
          source={
            currentRoute === 'Transactions'
              ? TransactionsFilled
              : TransactionsUnfilled
          }
          style={TailwindResponsive(`flex-1`)}
          resizeMode="contain"
        />
      </AppBarIcon>

      <AppBarIcon onPress={handleRegisterWork} title="">
        <RegisterWorkIcon />
      </AppBarIcon>

      <AppBarIcon screen="Inbox" title="Inbox">
        <NotificationBadge amount={notifications.unviewedTransactions} />
        <Image
          source={currentRoute === 'Inbox' ? InboxFilled : InboxUnfilled}
          style={TailwindResponsive(`flex-1`)}
          resizeMode="contain"
        />
      </AppBarIcon>

      <AppBarIcon screen="Profile" title="Account">
        <Image
          source={currentRoute === 'Profile' ? AccountActive : AccountUnfilled}
          style={TailwindResponsive(`flex-1`)}
          resizeMode="contain"
        />
      </AppBarIcon>

      <SubSignUpModal />
    </View>
  );
};
