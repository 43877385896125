import { RoyaltyInput } from 'components/shared/form';
import {
  GraphikMediumTextMd,
  GraphikSemiTextSm,
  GraphikTextTiny,
} from 'components/shared/styled';
import { formatRoyaltyInput } from 'components/views/transferWork';
import React, { FunctionComponent } from 'react';
import { Platform, View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export interface IDiminishedRoyaltySection {
  diminishedArtistError: string;
  diminishedArtistValue: string;
  diminishedGalleryError: string;
  diminishedGalleryValue: string;
  diminishedTotalValueError: string;
  isGallery: boolean;
  setDiminishedArtistError: React.Dispatch<React.SetStateAction<string>>;
  setDiminishedArtistValue: React.Dispatch<React.SetStateAction<string>>;
  setDiminishedGalleryError: React.Dispatch<React.SetStateAction<string>>;
  setDiminishedGalleryValue: React.Dispatch<React.SetStateAction<string>>;
  totalDiminishedValue: string;
}

export const DiminishedRoyaltySection: FunctionComponent<
  IDiminishedRoyaltySection
> = ({
  diminishedArtistError,
  diminishedArtistValue,
  diminishedGalleryError,
  diminishedGalleryValue,
  diminishedTotalValueError,
  isGallery,
  setDiminishedArtistError,
  setDiminishedArtistValue,
  setDiminishedGalleryError,
  setDiminishedGalleryValue,
  totalDiminishedValue,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View style={TailwindResponsive(`flex-1 mt-6 mobWeb:mt-3`)}>
      <View>
        <GraphikSemiTextSm>And thereafter:</GraphikSemiTextSm>
      </View>
      <View
        style={{
          ...TailwindResponsive(
            `flex flex-1 flex-row mobWeb:flex-col mobWeb:items-center mobWeb:mt-4`,
          ),
          zIndex: 1,
        }}
      >
        <View style={TailwindResponsive(`pt-4`)}>
          <RoyaltyInput
            value={diminishedArtistValue}
            label={
              Platform.OS === 'web' ? '* Artist Resale Commission' : undefined
            }
            handleChange={(input) => {
              const parsedInput = formatRoyaltyInput(input);
              setDiminishedArtistValue(parsedInput);
              setDiminishedArtistError('');
            }}
            errorMessage={diminishedArtistError}
            style={TailwindResponsive('mob:w-1/2')}
          />
        </View>

        {isGallery && (
          <View style={TailwindResponsive(`pt-4 flex-wrap`)}>
            <RoyaltyInput
              value={diminishedGalleryValue}
              label={
                Platform.OS === 'web'
                  ? '* Gallery Resale Commission'
                  : undefined
              }
              handleChange={(input) => {
                const parsedInput = formatRoyaltyInput(input);
                setDiminishedGalleryValue(parsedInput);
                setDiminishedGalleryError('');
              }}
              errorMessage={diminishedGalleryError}
              style={TailwindResponsive('mob:w-1/2')}
            />
          </View>
        )}

        {isGallery && (
          <View style={TailwindResponsive('mt-4 items-center')}>
            <View
              style={TailwindResponsive(
                `flex mob:flex-row h-14 mb-2 items-center justify-center mob:w-1/2`,
              )}
            >
              <GraphikMediumTextMd>{`${totalDiminishedValue}%`}</GraphikMediumTextMd>
            </View>
            {Platform.OS === 'web' && (
              <GraphikTextTiny
                color={Styles.Colours.Dark3}
                style={TailwindResponsive(`px-5`)}
              >
                Total Resale Commission
              </GraphikTextTiny>
            )}
            {Platform.OS !== 'ios' && (
              <GraphikTextTiny
                style={{
                  ...TailwindResponsive(``),
                  color: Styles.Colours.Error,
                }}
              >
                {diminishedTotalValueError}
              </GraphikTextTiny>
            )}
          </View>
        )}
      </View>
    </View>
  );
};

export default DiminishedRoyaltySection;
