import { VeriffCodes } from './Veriff';

export interface User {
  id: string;
  address: Address;
  agreedTimeStamp: string;
  artistName: string;
  birthYear: string;
  brandName: string;
  ccSalesOfferEmails: boolean;
  connectedToQb: boolean;
  company: string;
  createDate: string;
  discountEndDate: string;
  email: string;
  enablePublicCerts?: boolean;
  enablePublicProvenance?: boolean;
  estateOf?: string;
  isEstate?: boolean;
  externalId: string;
  firstLogin: boolean;
  firstName: string;
  galleryName: string;
  hasSeenCollectorOnboarding: boolean;
  hasSeenOnboarding: boolean;
  incomplete: boolean;
  isAdmin: boolean;
  isArtist: boolean;
  isCollector: boolean;
  isGallery: boolean;
  isSubUser: boolean;
  isBrand: boolean;
  lastName: string;
  logo: string;
  nationality: string;
  mainUser?: string;
  phoneNumber: string;
  rights?: string[];
  signature: string;
  signatureTemp?: string;
  stripeAccountId: string;
  stripeAccountIsSetup: boolean;
  stripeSubscriptionId: string;
  subscriptionActive: boolean;
  subTrialUsed: boolean;
  twoFactorEnabled: boolean;
  twoFactorNumber: string;
  veriffId: string;
  veriffStatus: VeriffCodes;
  verified: boolean;
}

export interface Address {
  id: string;
  addedById: string;
  userId: string;

  billingAddressLineOne: string;
  billingAddressLineTwo: string;
  billingAddressCity: string;
  billingAddressCountry: string;
  billingAddressState: string;
  billingAddressZipcode: string;

  shippingAddressLineOne: string;
  shippingAddressLineTwo: string;
  shippingAddressCity: string;
  shippingAddressCountry: string;
  shippingAddressState: string;
  shippingAddressZipcode: string;
}

export interface Artist extends User {
  artistName: string;
  docusignTemplateId: string;
  externalId: string;
  nationality: string;
  signature: string;
}

export interface Contributor extends User {
  role: string;
}

export const getDisplayName = (user: User | null | undefined) => {
  if (!user) return '';
  return (
    user.artistName ||
    user.galleryName ||
    user.brandName ||
    `${user.firstName} ${user.lastName}`
  );
};
