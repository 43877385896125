import { FunctionComponent } from 'react';
import { View } from 'react-native';
import PinInput from 'react-pin-input';
import { Style } from 'tailwind-rn/dist';

export interface IUniversalPinInputProps {
  inputStyle?: Style;
  inputFocusStyle?: Style;
  onComplete: (arg: any) => void;
}

export const UniversalPinInput: FunctionComponent<IUniversalPinInputProps> = ({
  onComplete,
  inputFocusStyle,
  inputStyle,
}) => (
  <View>
    <PinInput
      length={6}
      initialValue=""
      secret={false}
      onComplete={onComplete}
      focus
      type="numeric"
      inputMode="number"
      inputStyle={inputStyle}
      inputFocusStyle={inputFocusStyle}
      autoSelect={false}
      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
    />
  </View>
);

export default UniversalPinInput;
