import { GraphikSemiTextSm, GraphikTextSm } from 'components/shared/styled';
import currency from 'currency.js';
import React, { FunctionComponent, useMemo } from 'react';
import { ITransactionDetailsProceeds } from 'types';
import DetailsLineItem from './DetailsLineItem';

export const TransactionDetailsProceeds: FunctionComponent<
  ITransactionDetailsProceeds
> = ({
  achFee,
  achTransferFee,
  allowCreditCard,
  ccTransferFee,
  creditCardFee,
  currencySymbol,
  fairchainFee,
  fairchainFundContribution,
  passFees,
  royaltyFees,
  totalArtworkValue,
  totalArtistResaleCommissions,
  totalValueAmount,
  wireStripeTransferFee,
  wireTransferFee,
}) => {
  const totalProceeds = useMemo(
    () =>
      totalValueAmount > currency(0, { symbol: currencySymbol })
        ? totalValueAmount
            .subtract(fairchainFee)
            .subtract(royaltyFees?.value || 0)
            .subtract(fairchainFundContribution?.value || 0)
        : currency(0, { symbol: currencySymbol }),
    [
      totalValueAmount,
      currencySymbol,
      fairchainFee,
      fairchainFundContribution,
      royaltyFees,
    ],
  );

  const totalValueAmountACH = useMemo(
    () =>
      totalArtworkValue > 0
        ? totalProceeds.subtract(achFee).subtract(achTransferFee)
        : currency(0, { symbol: currencySymbol }),
    [achFee, achTransferFee, currencySymbol, totalArtworkValue, totalProceeds],
  );

  const totalValueAmountCreditCard = useMemo(
    () =>
      totalArtworkValue > 0
        ? totalProceeds.subtract(creditCardFee).subtract(ccTransferFee)
        : currency(0, { symbol: currencySymbol }),
    [
      ccTransferFee,
      creditCardFee,
      currencySymbol,
      totalArtworkValue,
      totalProceeds,
    ],
  );

  const totalValueAmountWireTransfer = useMemo(
    () =>
      totalArtworkValue > 0
        ? totalProceeds
            .subtract(wireTransferFee)
            .subtract(wireStripeTransferFee)
        : currency(0, { symbol: currencySymbol }),
    [
      currencySymbol,
      totalArtworkValue,
      totalProceeds,
      wireTransferFee,
      wireStripeTransferFee,
    ],
  );

  return (
    <DetailsLineItem vertical title="Proceeds to Seller:">
      <>
        <GraphikTextSm>
          Title Management: ({`${fairchainFee.format().split('.')[0]}`})
        </GraphikTextSm>
        {totalArtistResaleCommissions !== null && (
          <GraphikTextSm>
            Artist Resale Commission: (
            {`${totalArtistResaleCommissions.format()}`})
          </GraphikTextSm>
        )}
        {fairchainFundContribution !== null && (
          <GraphikTextSm>
            Fairchain Fund Contribution: (
            {`${fairchainFundContribution.format()}`})
          </GraphikTextSm>
        )}
        {!passFees && (
          <GraphikSemiTextSm>
            If paid via ACH: {`${totalValueAmountACH.format()}`}
          </GraphikSemiTextSm>
        )}
        {allowCreditCard && (
          <GraphikSemiTextSm>
            If paid via Credit Card:{' '}
            {passFees
              ? `${totalProceeds.format()}`
              : `${totalValueAmountCreditCard.format()}`}
          </GraphikSemiTextSm>
        )}
        {!passFees && (
          <GraphikSemiTextSm>
            If paid via Wire Transfer:{' '}
            {`${totalValueAmountWireTransfer.format()}`}
          </GraphikSemiTextSm>
        )}
      </>
    </DetailsLineItem>
  );
};

export default DetailsLineItem;
