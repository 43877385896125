import { useMutation } from '@apollo/client';
import { UPDATE_USER_SIGNATURE } from 'api/requests.v2';
import { AddIcon, TrashIcon } from 'components/graphics';
import CheckOnlyIcon from 'components/graphics/CheckOnlyIcon';
import { CenteredImage } from 'components/shared/image';
import { SpinLoader } from 'components/shared/loader';
import { GraphikMediumTextMd } from 'components/shared/styled';
import { EncodingType, readAsStringAsync } from 'expo-file-system';
import * as ExpoImagePicker from 'expo-image-picker';
import { FunctionComponent, useEffect, useState } from 'react';
import { Alert, Platform, Pressable, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setSignatureTemp } from 'store/user';
import Styles from 'style';
import { uploadBase64Async } from 'utilities';
import { logError } from 'utilities/LogError';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const SignatureUpload: FunctionComponent = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const { TailwindResponsive } = useTailwindResponsive();

  const [updateSign] = useMutation(UPDATE_USER_SIGNATURE);

  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');

  useEffect(() => {
    (async () => {
      const { status } = await ExpoImagePicker.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  useEffect(() => {
    if (user) setImage(user.signature || '');
  }, [user]);

  const deleteSig = async () => {
    setLoading(true);
    await updateSign({
      variables: {
        userId: user?.id,
        image: '',
      },
    });
    setImage('');
    setLoading(false);
  };

  const getExtension = (asset: ExpoImagePicker.ImagePickerAsset) => {
    let fileExtension = '';
    if (Platform.OS === 'ios') {
      fileExtension =
        asset.fileName?.substring(
          asset.fileName.lastIndexOf('.') + 1,
          asset.fileName.length,
        ) ?? '';
    } else {
      const splitB = asset.uri.split(';base64')[0];
      const splitI = splitB?.split('image/');
      if (splitI && splitI[1]) {
        fileExtension = splitI[1];
      }
    }
    return fileExtension;
  };

  const pickImage = async () => {
    if (Platform.OS !== 'web' && hasPermission === false) {
      // eslint-disable-next-line no-alert
      alert('Sorry, we need camera permissions to make this work!');
    } else {
      try {
        setLoading(true);

        const result: ExpoImagePicker.ImagePickerResult =
          await ExpoImagePicker.launchImageLibraryAsync({
            base64: true,
            mediaTypes: ExpoImagePicker.MediaTypeOptions.All,
            quality: 1,
          });

        if (result && !result.canceled && result.assets[0]) {
          const pickedImage: ExpoImagePicker.ImagePickerAsset =
            result.assets[0];
          await saveImage(pickedImage);
        }
      } catch (error) {
        logError(error, `Error in picking image.`);
      } finally {
        setLoading(false);
      }
    }
  };

  const saveImage = async (pickedImage: ExpoImagePicker.ImagePickerAsset) => {
    const { uri } = pickedImage;
    const splitBase64File =
      Platform.OS !== 'web'
        ? await readAsStringAsync(uri, {
            encoding: EncodingType.Base64,
          })
        : uri.split('base64,')[1];
    const ext = getExtension(pickedImage);
    const fileName = `temp-artist-sigs/${user?.id}.${ext}`;

    // We upload from front end to not run into file size issues on the API
    await uploadBase64Async(splitBase64File || '', fileName, `image/${ext}`);

    // Now we update the backend data
    await updateSign({
      variables: {
        userId: user?.id,
        image: `${user?.id}.${ext}`,
      },
    });

    dispatch(setSignatureTemp(fileName));
  };

  const selectImage = () =>
    Alert.alert(
      'Select Image',
      '',
      [
        {
          text: 'Take a Photo',
          onPress: () => takeImage(),
        },
        {
          text: 'Choose From Library',
          onPress: () => pickImage(),
        },
        {
          text: 'Cancel',
          style: 'cancel',
        },
      ],
      {
        cancelable: true,
        onDismiss: () =>
          Alert.alert(
            'This alert was dismissed by tapping outside of the alert dialog.',
          ),
      },
    );

  const takeImage = async () => {
    if (Platform.OS === 'ios') {
      setTimeout(() => {
        setLoading(true);
      }, 300);
    }

    try {
      setLoading(true);

      const result: ExpoImagePicker.ImagePickerResult =
        await ExpoImagePicker.launchCameraAsync({
          base64: true,
          mediaTypes: ExpoImagePicker.MediaTypeOptions.All,
          quality: 1,
        });

      if (result && !result.canceled && result.assets[0]) {
        const pickedImage: ExpoImagePicker.ImagePickerAsset = result.assets[0];
        await saveImage(pickedImage);
      }
    } catch (error) {
      logError(error, `Error in taking image.`);
    } finally {
      setLoading(false);
    }
  };

  if (!user || !user.isArtist || user.signature) return null;

  if (user.signatureTemp) {
    return (
      <View
        style={TailwindResponsive(`flex flex-row items-center mb-4 mob:my-4`)}
      >
        <CheckOnlyIcon color={Styles.Colours.Success} width={24} />
        <GraphikMediumTextMd style={TailwindResponsive(`ml-2`)}>
          Signature Submitted For Review
        </GraphikMediumTextMd>
      </View>
    );
  }

  return (
    <View style={TailwindResponsive('my-10')}>
      <GraphikMediumTextMd style={TailwindResponsive(`mb-4`)}>
        Signature
      </GraphikMediumTextMd>

      {loading && (
        <View style={TailwindResponsive(`w-52 h-52`)}>
          <SpinLoader />
        </View>
      )}

      {!image && !loading && (
        <Pressable
          onPress={() => {
            if (Platform.OS === 'web') {
              pickImage();
            } else {
              selectImage();
            }
          }}
          style={TailwindResponsive(
            'items-center justify-center border border-Dark1 web:mr-5 w-52 h-52',
          )}
          testID="ImagePicker_Signature_Selector"
        >
          <View style={TailwindResponsive('flex-row h-8')}>
            <AddIcon width={30} />
          </View>
        </Pressable>
      )}

      {!!image && !loading && (
        <View
          style={TailwindResponsive(
            'border border-Dark1 h-52 relative mr-5 w-52',
          )}
        >
          <Pressable
            onPress={deleteSig}
            style={TailwindResponsive(
              `absolute top-0 right-0 p-2 bg-red-100 rounded-bl-md z-20`,
            )}
          >
            <TrashIcon color={Styles.Colours.Error} width={22} />
          </Pressable>
          <CenteredImage isLocal uri={image} />
        </View>
      )}
    </View>
  );
};

export default SignatureUpload;
