import { yupResolver } from '@hookform/resolvers/yup';
import { AssetType, RegistrationType, UserRole } from 'constants/enums';
import { useForm } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { useSelector } from 'react-redux';
import { RegisterStore } from 'store/registerWork/apollo';
import {
  getArtistOfEstateUser,
  getMainUser,
  getUser,
  getUserRole,
} from 'store/user';
import {
  ArtworkRegistrationForm,
  CollectibleRegistrationForm,
} from 'types/RegisterWork';
import * as yup from 'yup';
import { useCanRegisterUncertifiedOnly } from '../Hooks';

const BaseSchema = {
  artistName: yup.string(),
  firstName: yup.string().required('This is a required field'),
  lastName: yup.string().required('This is a required field'),
  editionNumber: yup.string().when('isEditionWork', {
    is: true,
    then: yup.string().when('editionType', {
      is: true,
      then: yup.string().required('This is a required field'),
    }),
  }),
  editionTotal: yup.string().when('isEditionWork', {
    is: true,
    then: yup.string().when('editionType', {
      is: true,
      then: yup
        .string()
        .required('This is a required field')
        .test({
          name: 'Edition test',
          message: 'Edition # can not be higher than Edition Total',
          test() {
            return (
              parseInt(this.parent.editionTotal, 10) >=
              parseInt(this.parent.editionNumber, 10)
            );
          },
        }),
    }),
  }),
  email: yup
    .string()
    .email('Please enter a valid email')
    .when('registrationType', {
      is: (registrationType) =>
        registrationType !== RegistrationType.Uncertified,
      then: yup
        .string()
        .email('Please enter a valid email')
        .required('This is a required field'),
    }),
  isEditionWork: yup.bool(),
  editionType: yup.bool(),
  numAP: yup.string().when('isEditionWork', {
    is: true,
    then: yup.string().when('editionType', {
      is: false,
      then: yup.string().required('This is a required field'),
    }),
  }),
  totalNumberOfAPs: yup.string().when('isEditionWork', {
    is: true,
    then: yup.string().when('editionType', {
      is: false,
      then: yup
        .string()
        .required('This is a required field')
        .test({
          name: ' AP test',
          message: 'AP # can not be higher than AP Total',
          test() {
            return (
              parseInt(this.parent.totalNumberOfAPs, 10) >=
              parseInt(this.parent.numAP, 10)
            );
          },
        }),
    }),
  }),
  title: yup.string().required('This is a required field'),
  videoUrl: yup.string().test({
    name: 'Video URL Test',
    message: 'The URL you entered is invalid or unsupported.',
    test(value) {
      if (value) {
        return (
          value.includes('youtube.com') ||
          value.includes('vimeo.com') ||
          value.includes('youtu.be')
        );
      }
      return true;
    },
  }),
  yearProduced: yup.string().required('This is a required field'),
  contributors: yup.array().of(
    yup.object().shape({
      firstName: yup.string().required('This is a required field'),
      lastName: yup.string().required('This is a required field'),
      email: yup
        .string()
        .email('Please enter a valid email')
        .required('This is a required field'),
      role: yup.string().required('This is a required field'),
    }),
  ),
};

const ArtworkRegistrationSchema = yup
  .object()
  .shape({
    ...BaseSchema,
    medium: yup.string().required('This is a required field'),
  })
  .required();

const CollectibleRegistrationSchema = yup
  .object()
  .shape({
    ...BaseSchema,
    materials: yup.string().required('This is a required field'),
    measurements: yup.string().required('This is a required field'),
  })
  .required();

export const useGetDefaultARValues = () => {
  const uncertOnly = useCanRegisterUncertifiedOnly();

  const artistOfEstateUser = useSelector(getArtistOfEstateUser);
  const fullUser = useSelector(getUser);
  const mainUser = useSelector(getMainUser);
  const userRole = useSelector(getUserRole);

  let artistNameDefault = '';
  let firstNameDefault = '';
  let lastNameDefault = '';
  let birthYearDefault = '';
  let emailDefault = '';
  let nationalityDefault = '';
  let idDefault = '';

  const theUser = fullUser && !fullUser.isSubUser ? fullUser : mainUser;
  if (userRole === UserRole.Artist && theUser) {
    artistNameDefault = theUser.artistName;
    firstNameDefault = theUser.firstName;
    lastNameDefault = theUser.lastName;
    birthYearDefault = theUser.birthYear;
    emailDefault = theUser.email;
    nationalityDefault = theUser.nationality;
    idDefault = theUser.id;
  } else if (userRole === UserRole.Estate && fullUser && artistOfEstateUser) {
    artistNameDefault = artistOfEstateUser.artistName;
    firstNameDefault = artistOfEstateUser.firstName;
    lastNameDefault = artistOfEstateUser.lastName;
    birthYearDefault = artistOfEstateUser.birthYear;
    emailDefault = artistOfEstateUser.email;
    nationalityDefault = artistOfEstateUser.nationality;
    idDefault = artistOfEstateUser.id;
  }

  return {
    id: idDefault,
    artistName: artistNameDefault,
    brandRoyalty: '',
    collectibleInfo: {},
    brandName: '',
    contributors: [],
    contributorRoyalties: [],
    digitalRepoRights: false,
    firstName: firstNameDefault,
    lastName: lastNameDefault,
    birthYear: birthYearDefault,
    nationality: nationalityDefault,
    email: emailDefault,
    title: '',
    yearProduced: '',
    medium: '',
    width: '',
    height: '',
    depth: '',
    dimensionUnits: true,
    inventoryNumber: '',
    isEditionWork: false,
    editionType: true,
    editionNumber: '',
    editionTotal: '',
    numAP: '',
    registrationType: uncertOnly
      ? RegistrationType.Uncertified
      : RegistrationType.Certified,
    totalNumberOfAPs: '',
    additionalInfo: '',
    privateNotes: '',
    purchaseAmount: '',
    purchaseDate: '',
    location: '',
    seller: '',
    videoUrl: '',
  };
};

// currently only available for Brand accounts
export const useGetDefaultCRValues = () => ({
  id: '',
  artistName: '',
  brandRoyalty: '',
  collectibleInfo: {},
  brandName: '',
  contributors: [],
  contributorRoyalties: [],
  firstName: '',
  lastName: '',
  birthYear: '',
  nationality: '',
  email: '',
  title: '',
  yearProduced: '',
  inventoryNumber: '',
  isEditionWork: false,
  editionType: true,
  editionNumber: '',
  editionTotal: '',
  height: '',
  width: '',
  depth: '',
  dimensionUnits: true,
  materials: '',
  medium: '',
  measurements: '',
  numAP: '',
  registrationType: RegistrationType.Certified, // Collectibles will always be certified
  totalNumberOfAPs: '',
  additionalInfo: '',
  privateNotes: '',
  purchaseAmount: '',
  purchaseDate: '',
  location: '',
  seller: '',
  videoUrl: '',
});

export const useSetupRegistrationForm = (assetType) => {
  const collectibleDefaultValues = useGetDefaultCRValues();
  const artworkDefaultValues = useGetDefaultARValues();

  const collectibleForm = useForm<CollectibleRegistrationForm>({
    defaultValues: collectibleDefaultValues,
    resolver: yupResolver(CollectibleRegistrationSchema),
  }) as UseFormReturn<CollectibleRegistrationForm, any>;

  const artworkForm = useForm<ArtworkRegistrationForm>({
    defaultValues: artworkDefaultValues,
    resolver: yupResolver(ArtworkRegistrationSchema),
  }) as UseFormReturn<ArtworkRegistrationForm, any>;

  if (assetType === AssetType.Artwork) {
    RegisterStore.Vars.detailsForm({
      assetType,
      formData: artworkForm,
    });
  } else if (assetType === AssetType.Collectible) {
    RegisterStore.Vars.detailsForm({
      assetType,
      formData: collectibleForm,
    });
  }
};

export const useGetFormDetails = (registrationForm) => {
  let arForm;
  if (!registrationForm) {
    return registrationForm;
  }
  // Old form details, needed when pulling existing drafts from DB
  if (
    registrationForm.assetType === null ||
    registrationForm.assetType === ''
  ) {
    arForm =
      registrationForm.formData as UseFormReturn<ArtworkRegistrationForm>;
  } else if (registrationForm?.assetType === AssetType.Artwork) {
    arForm =
      registrationForm?.formData as UseFormReturn<ArtworkRegistrationForm>;
  } else if (registrationForm?.assetType === AssetType.Collectible) {
    arForm =
      registrationForm?.formData as UseFormReturn<CollectibleRegistrationForm>;
  }
  return arForm;
};

const BASE_TRIGGERS: any = [
  'id',
  'artistName',
  'contributors',
  'firstName',
  'lastName',
  'birthYear',
  'nationality',
  'email',
  'title',
  'yearProduced',
  'inventoryNumber',
  'isEditionWork',
  'editionType',
  'editionNumber',
  'editionTotal',
  'numAP',
  'registrationType',
  'totalNumberOfAPs',
  'additionalInfo',
  'privateNotes',
  'purchaseAmount',
  'purchaseDate',
  'location',
  'seller',
  'videoUrl',
];

export const ARTWORK_FIRST_PAGE_TRIGGER: any = [
  ...BASE_TRIGGERS,
  'digitalRepoRights',
  'medium',
  'width',
  'height',
  'depth',
  'dimensionUnits',
];

export const COLLECTIBLE_FIRST_PAGE_TRIGGER: any = [
  ...BASE_TRIGGERS,
  'materials',
  'measurements',
];
