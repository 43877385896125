import { GridIcon, ListIcon } from 'components/shared/collectionPage';
import { CollectionSort, CollectionTab, CollectionView } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { getCollectionDisplayType } from 'store/collection';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export interface ISortSelector {
  isByDate: boolean;
  isByGrid?: boolean;
  onSortChange: (sort: CollectionSort) => void;
  onViewChange?: (view: CollectionView) => void;
  transactionView?: boolean;
}

export const SortSelector: FunctionComponent<ISortSelector> = ({
  isByDate,
  isByGrid,
  onSortChange,
  onViewChange,
  transactionView,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const collectionView = useSelector(getCollectionDisplayType);

  const sortSelectorStyles =
    Platform.OS === 'web'
      ? StyleSheet.create({
          sortSelectorContainer: TailwindResponsive(
            `flex flex-row items-center mt-1.5`,
          ),
          sortSelectorText: {},
        })
      : StyleSheet.create({
          sortSelectorContainer: TailwindResponsive(`flex flex-col mt-0`),
          sortSelectorText: TailwindResponsive(`mt-7`),
        });

  const tabButtonStyle = {
    fontSize: Styles.Fonts.FontSize.Medium,
    marginHorizontal: 5,
  };

  const active = {
    fontFamily: Styles.Fonts.FontFamily.SansMedium,
    color: Styles.Colours.Dark1,
  };

  const inactive = {
    fontFamily: Styles.Fonts.FontFamily.SansRegular,
    color: Styles.Colours.Dark3,
  };
  return (
    <View
      style={TailwindResponsive(
        `flex flex-row mobWeb:justify-end mobWeb:w-full`,
      )}
    >
      <View
        style={TailwindResponsive(
          `flex flex-row items-center mt-1.5 mob:flex-col mob:mt-0 mobWeb:flex-col mobWeb:items-end mobWeb:mt-0`,
        )}
      >
        {collectionView === CollectionTab.Current && (
          <>
            <Text
              style={[
                tabButtonStyle,
                active,
                TailwindResponsive(`mobWeb:hidden`),
              ]}
            >
              Sort By:{' '}
            </Text>

            <Text
              style={[
                tabButtonStyle,
                isByDate ? inactive : active,
                sortSelectorStyles.sortSelectorText,
                TailwindResponsive(`mobWeb:mt-1.5 mobWeb:mx-0`),
              ]}
              onPress={() => onSortChange(CollectionSort.ArtistClient)}
            >
              {transactionView ? 'Client' : 'Artist'}
            </Text>

            <Text
              style={[
                tabButtonStyle,
                isByDate ? active : inactive,
                sortSelectorStyles.sortSelectorText,
                TailwindResponsive(`mobWeb:mt-1.5 mobWeb:mx-0`),
              ]}
              onPress={() => {
                onSortChange(CollectionSort.Date);
              }}
            >
              {transactionView ? 'Date Sent' : 'Date Registered'}
            </Text>
          </>
        )}
      </View>
      {!transactionView && onViewChange && (
        <View
          style={TailwindResponsive(
            `flex flex-1 flex-row items-center justify-end ml-8 mt-1.5 mobWeb:mt-0 mobWeb:ml-0`,
          )}
        >
          <View style={{ marginRight: 10 }}>
            <ListIcon
              onPress={() => onViewChange(CollectionView.Line)}
              isFilled={!isByGrid}
            />
          </View>
          <GridIcon
            onPress={() => onViewChange(CollectionView.Grid)}
            isFilled={isByGrid!}
          />
        </View>
      )}
    </View>
  );
};
