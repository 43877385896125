/* Copyright (C) 2022 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */
import { useReactiveVar } from '@apollo/client';
import { ErrorMessage } from 'components/shared/error';
import {
  GraphikMediumTextSm,
  GraphikSemiTextLg,
} from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { getIntegrationsLoading, getSubUsers } from 'store/user';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ProfileVars } from '../apollo';
import SubUserManagementLineItem from '../components/SubUserManagementLineItem';

export const SubUserManagement: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const error = useReactiveVar(ProfileVars.subUsers.error);
  const integrationsLoading = useSelector(getIntegrationsLoading);
  const subUsers = useSelector(getSubUsers);

  if (!subUsers || subUsers.length === 0 || integrationsLoading) return null;

  if (error) return <ErrorMessage />;

  return (
    <>
      <GraphikSemiTextLg style={TailwindResponsive('mb-10')}>
        User Management
      </GraphikSemiTextLg>
      <View
        style={TailwindResponsive(
          'flex-row justify-between border-b-2 border-Dark4',
        )}
      >
        <GraphikMediumTextSm>Users</GraphikMediumTextSm>
        <GraphikMediumTextSm>Permissions</GraphikMediumTextSm>
      </View>
      <View>
        {subUsers.map((subUser) => (
          <View key={subUser.id}>
            <SubUserManagementLineItem subUser={subUser} />
          </View>
        ))}
      </View>
    </>
  );
};

export default SubUserManagement;
