import { useNavigation } from '@react-navigation/native';
import { RoundedButton } from 'components/shared/button';
import React, { FunctionComponent } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { getSelectedWorksCount } from 'store/transferWork';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const CollectionGSAButton: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const navigation = useNavigation();
  const numSelectedWorks = useSelector(getSelectedWorksCount);
  const bLabel =
    numSelectedWorks > 1
      ? `Generate Sales Agreement (${numSelectedWorks})`
      : 'Generate Sales Agreement';

  const styles = StyleSheet.create({
    gsaButtonContainerWeb: {
      width: '316px',
      marginTop: '30px',
      ...TailwindResponsive(`mobWeb:w-full`),
    },
    gsaButtonContaineriOS: {
      width: '100%',
    },
  });

  return (
    <View
      style={
        Platform.OS === 'web'
          ? styles.gsaButtonContainerWeb
          : styles.gsaButtonContaineriOS
      }
    >
      <RoundedButton
        backgroundColor={
          numSelectedWorks > 0 ? Styles.Colours.Primary : Styles.Colours.Dark5
        }
        color={Styles.Colours.Light1}
        disabled={numSelectedWorks < 1}
        label={bLabel}
        onPress={() => {
          if (numSelectedWorks > 0) {
            navigation.navigate('TransferWork');
          }
        }}
        testId="gsa-main-button"
      />
    </View>
  );
};
