import { useReactiveVar } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import { Footer } from 'components/shared/footer';
import { useAppStarter } from 'components/shared/page/AppStarter';
import { signInWithCustomToken } from 'firebase/auth';
import { FunctionComponent, useEffect } from 'react';
import { Platform, View } from 'react-native';
import Styles from 'style';
import * as FBUtil from 'utilities/Firebaseutil';
import LogError from 'utilities/LogError';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { SignUpVars, useSetupSignUpForm } from '../form';
import { FormWrapper } from '../form/FormWrapper';
import FormInfo from './FormInfo';

export const SignUpForm: FunctionComponent = () => {
  useAppStarter(); // This is needed to auto-login the user

  const route = useRoute();
  const { TailwindResponsive } = useTailwindResponsive();

  useSetupSignUpForm();
  const response = useReactiveVar(SignUpVars.response);

  useEffect(() => {
    const signIn = async () => {
      await signInWithCustomToken(FBUtil.getFBAuth(), response.signInUrl);
      SignUpVars.signInLoading(false);
    };

    if (response && !response.errorMessage && response.signInUrl) {
      signIn();
    } else if (response && response.errorMessage) {
      SignUpVars.errorMessage(response?.errorMessage);
      LogError.logError(response?.errorMessage, 'Error in signing up user');
      SignUpVars.signInLoading(false);
    } else {
      SignUpVars.signInLoading(false);
    }
  }, [response]);

  if (!route) return null;

  return (
    <>
      <View style={TailwindResponsive(`pb-12 bg-Dark1 flex flex-1`)}>
        {route.name === 'Collector' ? <FormWrapper /> : <FormInfo />}
      </View>

      {Platform.OS === 'web' && (
        <Footer
          backgroundColor="Dark1"
          style={TailwindResponsive('mob:hidden')}
          textColor={Styles.Colours.Light1}
        />
      )}
    </>
  );
};

export default SignUpForm;
