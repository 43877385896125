import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_SUBSCRIPTION_INFO } from 'api/requests.v2';
import { UserContext } from 'contexts';
import { useContext } from 'react';
import { ProfileVars } from '../../apollo';
import { SubscriptionStatus } from './ProfileSubscription';

export const useGetSubscription = () => {
  const authUser = useContext(UserContext);

  const { refetch } = useQuery(GET_SUBSCRIPTION_INFO, {
    fetchPolicy: 'network-only',
    variables: {
      userId: authUser?.uid,
    },
    onCompleted({ response }) {
      if (response) {
        ProfileVars.subScreen.subscription(response);
        ProfileVars.subScreen.loading(false);
      }
    },
  });

  return refetch;
};

export const useGetSubscriptionStatus = (): string => {
  const sub = useReactiveVar(ProfileVars.subScreen.subscription);

  if (!sub) return SubscriptionStatus.inactive;
  if (
    sub.status === SubscriptionStatus.active ||
    sub.status === SubscriptionStatus.canceled ||
    sub.status === SubscriptionStatus.trialing
  ) {
    return sub.status;
  }
  return SubscriptionStatus.inactive;
};
