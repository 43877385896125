import { useLazyQuery } from '@apollo/client';
import { SETUP_STRIPE_ACCOUNT_AND_GET_LINK } from 'api/requests.v2';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { GraphikTextSm } from 'components/shared/styled';
import { UserContext } from 'contexts';
import { FunctionComponent, useContext } from 'react';
import { Platform, View } from 'react-native';
import { webViewOpenVar, webViewUrlVar } from 'store/transferWork/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ConnectToStripe: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const { TailwindResponsive } = useTailwindResponsive();

  const [getStripeAccountLink] = useLazyQuery(
    SETUP_STRIPE_ACCOUNT_AND_GET_LINK,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (Platform.OS === 'web') {
          window.location = data.response;
        } else {
          webViewUrlVar(data.response);
          webViewOpenVar(true);
        }
      },
      variables: {
        userId: authUser?.uid,
        isMobile: Platform.OS === 'ios',
      },
    },
  );

  return (
    <View
      style={[
        TailwindResponsive(
          `border flex-row items-center justify-between py-6 px-8 w-full h-full mobWeb:flex-col`,
        ),
        { zIndex: -1 },
      ]}
    >
      <GraphikTextSm style={TailwindResponsive('mr-6 mobWeb:mb-6 mobWeb:mr-0')}>
        We offer the ability to issue invoices to buyers, including tax and
        shipping costs, but we need you to verify your account with Stripe, our
        payment processor.
      </GraphikTextSm>
      <FairchainButton
        buttonStyle={TailwindResponsive(`bg-Dark1 p-6 rounded-full`)}
        label="Connect to Stripe"
        onPress={() => getStripeAccountLink()}
        textStyle={TailwindResponsive(`text-Light1`)}
      />
    </View>
  );
};

export default ConnectToStripe;
