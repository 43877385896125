import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import Styles from 'style';
import { ISVGProps } from './Types';

export const OpenInNewIcon: FunctionComponent<ISVGProps> = ({
  color,
  width,
}) => {
  const iconWidth = width || 48;

  return (
    <Svg
      viewBox="0 0 48 48"
      width={iconWidth}
      fill={color || Styles.Colours.Dark1}
      height={iconWidth}
    >
      <Path d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h13.95v3H9v30h30V25.05h3V39q0 1.2-.9 2.1-.9.9-2.1.9Zm10.1-10.95L17 28.9 36.9 9H25.95V6H42v16.05h-3v-10.9Z" />
    </Svg>
  );
};

export default OpenInNewIcon;
