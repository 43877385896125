import { ApolloProvider } from '@apollo/client';
import {
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import { FC_APOLLO_CLIENT } from 'navigation/ApolloSetup';
import { linking } from 'navigation/linking';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import 'react-native-get-random-values';
import { Provider as PaperProvider } from 'react-native-paper';
import { Provider } from 'react-redux';
import { clearSelectedWorks } from 'store/transferWork';
import { TailwindProvider } from 'tailwind-rn/dist';
import { FCAnalyticEvents, logAnalyticsEvent } from 'utilities';
import utilities from '../tailwind.json';
import App from './App';
import { store } from './store';
import FairchainTheme from './style/fairchain-theme';

const setRouteNameRefCurrent = (routeNameRef, navigationRef) => {
  const theRoute = routeNameRef;
  theRoute.current = navigationRef?.current?.getCurrentRoute()?.name;
};

export const Index: FunctionComponent = () => {
  const navigationRef = useNavigationContainerRef();
  const routeNameRef = React.useRef<HTMLElement | undefined | null | string>(
    null,
  );

  return (
    <ApolloProvider client={FC_APOLLO_CLIENT}>
      <Provider store={store}>
        <NavigationContainer
          ref={navigationRef}
          linking={linking}
          onReady={() => {
            setRouteNameRefCurrent(routeNameRef, navigationRef);
          }}
          documentTitle={{
            formatter: (options, route) =>
              options?.title || route?.name
                ? `${options?.title ?? route?.name} | Fairchain`
                : 'Fairchain',
          }}
          onStateChange={async () => {
            const previousRouteName = routeNameRef.current;
            const currentRouteName =
              navigationRef?.current?.getCurrentRoute()?.name;

            if (previousRouteName !== currentRouteName) {
              if (currentRouteName === 'Collection') {
                store.dispatch(clearSelectedWorks());
              }

              logAnalyticsEvent(FCAnalyticEvents.ScreenView, {
                firebase_screen: currentRouteName,
                firebase_screen_class: '',
              });
            }

            routeNameRef.current = currentRouteName;
          }}
        >
          <TailwindProvider utilities={utilities}>
            <PaperProvider theme={FairchainTheme}>
              <View style={{ flex: 1 }}>
                <App />
              </View>
            </PaperProvider>
          </TailwindProvider>
        </NavigationContainer>
      </Provider>
    </ApolloProvider>
  );
};
