import { Footer } from 'components/shared/footer';
import { CollectionTransactionHeader } from 'components/shared/header';
import React, { FunctionComponent } from 'react';
import { Platform, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { ICollectionTransactionHeaderProps } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { SpinLoader } from '../loader';
import { useAppStarter } from './AppStarter';

export const DefaultPage: FunctionComponent<
  ICollectionTransactionHeaderProps
> = ({
  disableGridView,
  isByGrid,
  onTabChange,
  open,
  setOpen,
  showCollection,
  toggleSortModalVisible,
  viewByGrid,
  viewByLine,
  ...props
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const appReady = useAppStarter();

  return (
    <SafeAreaView style={TailwindResponsive(`flex-1 w-full bg-Light1`)}>
      <View
        style={TailwindResponsive(`bg-Light1 flex-1 p-16 mob:p-4 mobWeb:p-4`)}
      >
        <CollectionTransactionHeader
          disableGridView={disableGridView}
          isByGrid={isByGrid}
          onTabChange={onTabChange}
          open={open}
          setOpen={setOpen}
          showCollection={showCollection}
          toggleSortModalVisible={toggleSortModalVisible}
          viewByGrid={viewByGrid}
          viewByLine={viewByLine}
        />
        {appReady ? props.children : <SpinLoader />}
      </View>
      {Platform.OS === 'web' && <Footer />}
    </SafeAreaView>
  );
};

export default DefaultPage;
