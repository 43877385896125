import React, { FunctionComponent, useState } from 'react';
import { ActivityIndicator, SafeAreaView } from 'react-native';
import WebView from 'react-native-webview';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IFCWebViewProps {
  handleWebViewNavigationStateChange: (newNavState: any) => void;
  webviewRef: React.RefObject<WebView>;
  webviewUrl: string;
}

export const FCWebView: FunctionComponent<IFCWebViewProps> = ({
  handleWebViewNavigationStateChange,
  webviewRef,
  webviewUrl,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const [loading, setLoading] = useState(true);

  return (
    <SafeAreaView style={TailwindResponsive('flex-1')}>
      <WebView
        onLoad={() => setLoading(false)}
        onNavigationStateChange={handleWebViewNavigationStateChange}
        ref={webviewRef}
        source={{ uri: webviewUrl }}
      />
      {!!loading && (
        <ActivityIndicator
          size="large"
          style={{ position: 'absolute', left: 0, right: 0, bottom: 0, top: 0 }}
        />
      )}
    </SafeAreaView>
  );
};
