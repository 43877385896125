import step3 from 'assets/images/onboarding/step3.png';
import { GraphikTextMd, MediumTitle } from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { Image, Platform, View } from 'react-native';
import { useSelector } from 'react-redux';
import { getUser } from 'store/user';
import { getDisplayName } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ArtistOnboardingIntroScreen: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const user = useSelector(getUser);

  return (
    <View style={TailwindResponsive(`items-center`)}>
      <MediumTitle style={TailwindResponsive(`mb-8`)}>
        Welcome to Fairchain, {getDisplayName(user)}!
      </MediumTitle>

      <View style={TailwindResponsive(`mb-8`)}>
        {Platform.OS === 'web' ? (
          <img src={step3} style={TailwindResponsive(`h-52`)} />
        ) : (
          <Image
            source={step3}
            style={{ height: 200, width: 200 }}
            resizeMode="contain"
          />
        )}
      </View>

      <GraphikTextMd style={TailwindResponsive(`text-center`)}>
        Whether you work with a gallery or sell directly from the studio,
        Fairchain makes it easy to protect and create an enduring catalog of
        your creative output. Leverage our trusted legal framework to issue
        sales contracts, create blockchain-backed certificates of title and
        authenticity, and establish shareable resale commissions for each work.
      </GraphikTextMd>
    </View>
  );
};

export default ArtistOnboardingIntroScreen;
