import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDocumentPickerResponse } from 'types';
import { IBillingItem, IClientItem, IWorkItem } from './types';

export interface InitialTransferWorkState {
  billing: IBillingItem;
  clientRegistration: IClientItem;
  contractAccepted: boolean;
  contractLoading: boolean;
  contractSignDate: string;
  contractSignTimezone: string;
  contractVersion: string;
  isRetro: boolean;
  optionalMessage: string;
  rider: IDocumentPickerResponse;
  selectedWorks: string[];
  transactionDetails: {
    generateInvoice: boolean;
    stripeFeesAmount: number;
    works: IWorkItem[];
  };
}

export interface IPayload {
  id?: string;
  key: string;
  value: string;
}

export interface ISelectWorkPayload {
  clear?: boolean;
  multiWorkTransferEnabled?: boolean;
  workId: string;
}

const initialState: InitialTransferWorkState = {
  billing: {
    title: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
  },
  clientRegistration: {
    userId: null,
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    phoneNumber: '',
  },
  contractAccepted: false,
  contractLoading: true,
  contractSignDate: '',
  contractSignTimezone: '',
  contractVersion: '',
  isRetro: false,
  optionalMessage: '',
  rider: {
    base64: '',
    fileName: '',
  },
  selectedWorks: [],
  transactionDetails: {
    generateInvoice: false,
    stripeFeesAmount: 0,
    works: [],
  },
};

const transferWorkSlice = createSlice({
  name: 'transferWork',
  initialState,
  reducers: {
    resetClickwrap: (state) => {
      state.contractAccepted = initialState.contractAccepted;
      state.contractLoading = initialState.contractLoading;
      state.contractSignDate = initialState.contractSignDate;
      state.contractSignTimezone = initialState.contractSignTimezone;
      state.contractVersion = initialState.contractVersion;
    },
    setBilling: (state, action: PayloadAction<IPayload>) => ({
      ...state,
      billing: {
        ...state.billing,
        [action.payload.key]: action.payload.value,
      },
    }),
    setBillingAll: (state, action: PayloadAction<IBillingItem>) => ({
      ...state,
      billing: action.payload,
    }),
    setClientRegistration: (state, action: PayloadAction<IPayload>) => ({
      ...state,
      clientRegistration: {
        ...state.clientRegistration,
        [action.payload.key]: action.payload.value,
      },
    }),
    setClientRegistrationAll: (state, action: PayloadAction<IClientItem>) => ({
      ...state,
      clientRegistration: action.payload,
    }),
    setContractAccepted: (state, action: PayloadAction<boolean>) => {
      state.contractAccepted = action.payload;
    },
    setContractLoading: (state, action: PayloadAction<boolean>) => {
      state.contractLoading = action.payload;
    },
    setContractVersion: (state, action: PayloadAction<string>) => {
      state.contractVersion = action.payload;
    },
    setGenerateInvoice: (state, action: PayloadAction<boolean>) => ({
      ...state,
      transactionDetails: {
        ...state.transactionDetails,
        generateInvoice: action.payload,
      },
    }),
    setIsRetro: (state, action: PayloadAction<boolean>) => {
      state.isRetro = action.payload;
    },
    setSelectedWorks: (state, action: PayloadAction<string[]>) => ({
      ...state,
      contractAccepted: initialState.contractAccepted,
      contractLoading: initialState.contractLoading,
      contractSignDate: initialState.contractSignDate,
      contractSignTimezone: initialState.contractSignTimezone,
      contractVersion: initialState.contractVersion,
      rider: initialState.rider,
      selectedWorks: action.payload,
    }),
    signContract: (state) => {
      state.contractSignDate = new Date().toISOString();
      state.contractSignTimezone =
        Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    clearSelectedWorks: (state) => ({
      ...state,
      selectedWorks: [],
    }),
    setStripeFeesAmount: (state, action: PayloadAction<number>) => {
      state.transactionDetails.stripeFeesAmount = action.payload;
    },
    setWorkObjects: (state, action: PayloadAction<IWorkItem>) => ({
      ...state,
      transactionDetails: {
        ...state.transactionDetails,
        works: [...state.transactionDetails.works, action.payload],
      },
    }),
    setOptionalMessage: (state, action: PayloadAction<string>) => ({
      ...state,
      optionalMessage: action.payload,
    }),
    resetRider: (state) => {
      state.rider = {
        base64: '',
        fileName: '',
      };
    },
    setRider: (state, action: PayloadAction<IDocumentPickerResponse>) => {
      state.rider = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = transferWorkSlice;
export const {
  clearSelectedWorks,
  resetRider,
  setBilling,
  setBillingAll,
  setClientRegistration,
  setClientRegistrationAll,
  setContractAccepted,
  setContractLoading,
  setContractVersion,
  setGenerateInvoice,
  setIsRetro,
  setRider,
  setSelectedWorks,
  setStripeFeesAmount,
  setWorkObjects,
  setOptionalMessage,
  signContract,
} = actions;
export { reducer as transferWorkReducer };

export type TransferWorkState = ReturnType<typeof reducer>;
export type TransferWorkAction =
  | typeof clearSelectedWorks
  | typeof resetRider
  | typeof setBilling
  | typeof setBillingAll
  | typeof setClientRegistration
  | typeof setClientRegistrationAll
  | typeof setContractAccepted
  | typeof setContractLoading
  | typeof setContractVersion
  | typeof setGenerateInvoice
  | typeof setIsRetro
  | typeof setRider
  | typeof setSelectedWorks
  | typeof setStripeFeesAmount
  | typeof setWorkObjects
  | typeof setOptionalMessage
  | typeof signContract;
