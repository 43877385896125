import { useMutation } from '@apollo/client';
import { CANCEL_TRANSACTION, GET_TRANSACTION_DETAILS } from 'api/requests.v2';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { GraphikSemiTextSm } from 'components/shared/styled';
import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';
import { getUser } from 'store/user';
import Styles from 'style';
import { FCAnalyticEvents, logAnalyticsEvent } from 'utilities/Firebaseutil';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface ICancelTransactionProps {
  label: string;
}

export const CancelTransaction: FunctionComponent<ICancelTransactionProps> = ({
  label,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const transaction = useGetTransactionDetailsHook();

  const userInfo = useSelector(getUser);

  const [isCanceled, setIsCanceled] = useState(false);

  const canCancelAsBuyer =
    transaction?.isBuyerView && transaction?.enableBuyerRejection !== false;
  const canCancelAsSeller =
    !transaction?.isBuyerView &&
    (!userInfo?.isSubUser || transaction?.subUserCreationId === userInfo.id);
  const canCancelAsUser = canCancelAsBuyer || canCancelAsSeller;

  const [
    cancelTransaction,
    {
      data: cancelTransactionData,
      loading: cancelTransactionLoading,
      error: cancelTransactionError,
    },
  ] = useMutation(CANCEL_TRANSACTION, {
    refetchQueries: [GET_TRANSACTION_DETAILS],
  });

  if (!transaction || !transaction.canCancel || isCanceled || !canCancelAsUser)
    return null;

  return (
    <View style={TailwindResponsive(`max-w-sm`)}>
      <FairchainButton
        buttonStyle={TailwindResponsive('items-start my-10 self-start')}
        label={label}
        loadingStyle={TailwindResponsive('w-40')}
        loading={cancelTransactionLoading}
        onPress={async () => {
          if (transaction) {
            const variables = {
              transactionId: transaction.id,
            };
            await cancelTransaction({ variables });
            setIsCanceled(true);
            logAnalyticsEvent(FCAnalyticEvents.TransactionUpdate, {
              transactionId: transaction.id,
            });
          }
        }}
        testId="CancelSale"
        textStyle={{
          ...TailwindResponsive('text-Gray2'),
          fontFamily: Styles.Fonts.FontFamily.SansSemiBold,
          fontSize: Styles.Fonts.FontSize.Medium,
        }}
      />

      {((cancelTransactionData && !cancelTransactionData.response) ||
        cancelTransactionError) && (
        <GraphikSemiTextSm style={TailwindResponsive('text-Dark1')}>
          Unable to cancel transaction
        </GraphikSemiTextSm>
      )}
    </View>
  );
};

export default CancelTransaction;
