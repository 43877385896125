import { GridIcon, ListIcon } from 'components/shared/collectionPage';
import {
  CollectionPage,
  CollectionSort,
  CollectionTab,
  CollectionView,
} from 'constants/enums';
import React, { FunctionComponent, useMemo } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCollectionTab,
  getCurrentPage,
  getCurrentView,
  setCurrentView,
  setSort,
  sortingByDate,
} from 'store/collection';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const Sorter: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const dispatch = useDispatch();
  const collectionTab = useSelector(getCollectionTab);
  const collectionPage = useSelector(getCurrentPage);
  const isByDate = useSelector(sortingByDate);
  const isByGrid = useSelector(getCurrentView) === CollectionView.Grid;
  const transactionView = useMemo(
    () => collectionPage === CollectionPage.Transaction,
    [collectionPage],
  );

  const sortSelectorStyles =
    Platform.OS === 'web'
      ? StyleSheet.create({
          sortSelectorContainer: TailwindResponsive(
            `flex flex-row items-center mt-1.5`,
          ),
          sortSelectorText: {},
        })
      : StyleSheet.create({
          sortSelectorContainer: TailwindResponsive(`flex flex-col mt-0`),
          sortSelectorText: TailwindResponsive(`mt-7`),
        });

  const tabButtonStyle = {
    fontSize: Styles.Fonts.FontSize.Medium,
    marginHorizontal: 5,
  };

  const active = {
    fontFamily: Styles.Fonts.FontFamily.SansMedium,
    color: Styles.Colours.Dark1,
  };

  const inactive = {
    fontFamily: Styles.Fonts.FontFamily.SansRegular,
    color: Styles.Colours.Dark3,
  };
  return (
    <View
      style={TailwindResponsive(
        `flex flex-row mobWeb:justify-end mobWeb:w-full`,
      )}
    >
      <View
        style={TailwindResponsive(
          `flex flex-row items-center mt-1.5 mob:flex-col mob:mt-0 mobWeb:flex-col mobWeb:items-end mobWeb:mt-0`,
        )}
      >
        {collectionTab === CollectionTab.Current && (
          <>
            <Text
              style={[
                tabButtonStyle,
                active,
                TailwindResponsive(`mobWeb:hidden`),
              ]}
            >
              Sort By:{' '}
            </Text>

            <Text
              style={[
                tabButtonStyle,
                isByDate ? inactive : active,
                sortSelectorStyles.sortSelectorText,
                TailwindResponsive(`mobWeb:mt-1.5 mobWeb:mx-0`),
              ]}
              onPress={() => dispatch(setSort(CollectionSort.ArtistClient))}
            >
              {transactionView ? 'Client' : 'Artist'}
            </Text>

            <Text
              style={[
                tabButtonStyle,
                isByDate ? active : inactive,
                sortSelectorStyles.sortSelectorText,
                TailwindResponsive(`mobWeb:mt-1.5 mobWeb:mx-0`),
              ]}
              onPress={() => {
                dispatch(setSort(CollectionSort.Date));
              }}
            >
              {transactionView ? 'Date Sent' : 'Date Registered'}
            </Text>
          </>
        )}
      </View>
      {!transactionView && (
        <View
          style={TailwindResponsive(
            `flex flex-1 flex-row items-center justify-end ml-8 mt-1.5 mobWeb:mt-0 mobWeb:ml-0`,
          )}
        >
          <View style={{ marginRight: 10 }}>
            <ListIcon
              onPress={() => dispatch(setCurrentView(CollectionView.Line))}
              isFilled={!isByGrid}
            />
          </View>
          <GridIcon
            onPress={() => dispatch(setCurrentView(CollectionView.Grid))}
            isFilled={isByGrid!}
          />
        </View>
      )}
    </View>
  );
};
