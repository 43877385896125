import { CollectionTab } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { getCollectionTab, sortingByDate } from 'store/collection';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const CollectionHeader: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const currentTab = useSelector(getCollectionTab);
  const isByDate = useSelector(sortingByDate);

  const headerTextStyle = {
    fontSize: Styles.Fonts.FontSize.Tiny,
    fontFamily: Styles.Fonts.FontFamily.SansRegular,
  };
  const columnSmallStyle = {
    flex: 1,
  };
  const columnMediumStyle = {
    flex: 2,
  };
  const columnLargeStyle = {
    flex: 3,
  };

  return (
    <View
      style={TailwindResponsive(
        `flex flex-row border-b-0.5 border-Dark1 py-1 mobWeb:hidden`,
      )}
    >
      {!isByDate && (
        <View style={columnMediumStyle}>
          <Text style={headerTextStyle}>Artist</Text>
        </View>
      )}
      <View style={TailwindResponsive(`w-20`)} />
      <View style={columnSmallStyle} />
      {isByDate && (
        <View style={columnMediumStyle}>
          <Text style={headerTextStyle}>Artist</Text>
        </View>
      )}
      <View style={columnLargeStyle}>
        <Text style={headerTextStyle}>Title</Text>
      </View>
      <View style={TailwindResponsive(`w-24`)}>
        <Text style={headerTextStyle}>Year</Text>
      </View>

      <View style={columnMediumStyle}>
        <Text style={headerTextStyle}>Medium</Text>
      </View>

      <View style={columnMediumStyle}>
        <Text style={headerTextStyle}>Dimensions</Text>
      </View>

      <View style={columnMediumStyle}>
        <Text style={headerTextStyle}>Artwork ID</Text>
      </View>

      <View style={TailwindResponsive(`w-44`)}>
        {currentTab === CollectionTab.Drafts ? (
          <Text style={headerTextStyle}>Last Saved</Text>
        ) : (
          <Text style={headerTextStyle}>Date Registered</Text>
        )}
      </View>
      <View style={TailwindResponsive(`w-6`)} />
    </View>
  );
};
