import { FairchainButton } from 'components/shared/button/FairchainButton';
import { GraphikTextMd } from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { CRModalScreens, DetailsData } from 'store/artworkDetails/apollo';
import { getUser } from 'store/user';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const CRScreen1: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const user = useSelector(getUser);

  return (
    <View>
      <GraphikTextMd>
        Fairchain can, in some cases, help you to get a work certified by the
        artist or their estate. After you request certification, we may be in
        contact to facilitate certification.
      </GraphikTextMd>

      <GraphikTextMd style={TailwindResponsive(`my-4`)}>
        You will need to attest that you hold clear title to this work, and we
        will need your authorization to share your identity with the artist so
        that they can generate a digital certificate of title and authenticity.
        We will not share the purchase price.
      </GraphikTextMd>

      <GraphikTextMd>
        In addition you will be given the option, should you ever choose to sell
        the work, to provide the artist with a commission.
      </GraphikTextMd>

      <FairchainButton
        buttonStyle={TailwindResponsive(`bg-Dark1 mt-10 px-6 rounded-full`)}
        label="Continue"
        onPress={() => {
          const nextScreen = user?.address?.billingAddressLineOne
            ? CRModalScreens.three
            : CRModalScreens.two;
          DetailsData.CollectorRetro.modalScreen(nextScreen);
        }}
        textStyle={TailwindResponsive(`text-Light1`)}
      />
    </View>
  );
};

export default CRScreen1;
