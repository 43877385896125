import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import {
  BUYER_ACCEPT_CLICKWRAP,
  CANCEL_TRANSACTION,
  GET_BUYER_SIGNLINK,
  GET_TRANSACTION_DETAILS,
  UPDATE_AGREED_TO_TERMS,
} from 'api/requests.v2';
import { TextButton } from 'components/shared/button';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { Checkbox } from 'components/shared/checkbox';
import { InvoiceAffordance } from 'components/shared/copy/InvoiceAffordance';
import { ErrorMessage } from 'components/shared/error';
import { WordmarkHeader } from 'components/shared/header';
import { SpinLoader } from 'components/shared/loader';
import {
  GraphikSemiTextLg,
  GraphikSemiTextSm,
  GraphikTextSm,
} from 'components/shared/styled';
import { SITE_BASE_URL } from 'constants/constants';
import { UserContext } from 'contexts';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Image, ImageSourcePropType, Linking, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { resetAgentInfo, useGetAgentInfo } from 'store/agentBuyer/apollo';
import { setHasAgreed } from 'store/agreements';
import { GeneralApollo } from 'store/general/apollo-nav';
import TDGQL, {
  useGetTransactionDetailsHook,
} from 'store/transactionDetails/apollo';
import {
  getContractAccepted,
  getContractLoading,
  getContractSignDate,
  getContractSignTimezone,
} from 'store/transferWork';
import { getUserId, userUpdateFirstLogin } from 'store/user';
import Styles from 'style';
import { Status } from 'types';
import {
  FCAnalyticEvents,
  getUserAgentInfo,
  logAnalyticsEvent,
} from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { TransactionItem } from '../transactionDetails/components';
import CountdownTimer from '../transactionDetails/components/CountdownTimer';
import TransactionValue from '../transactionDetails/components/TransactionValue';
import { useExpirationHeader } from '../transactionDetails/hooks';
import { Clickwrap } from '../transferWork/components/Clickwrap';
import { GiftOption } from '../transferWork/components/GiftOption';
import { SellerBranding } from './components/SellerBranding';

interface Props {
  transactionId: string;
  setVoided: (isVoided: boolean) => void;
}

export const AgreementViewTransaction: React.FunctionComponent<Props> = ({
  transactionId,
  setVoided,
}) => {
  const authUser = useContext(UserContext);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { TailwindResponsive } = useTailwindResponsive();

  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const agentInfo = useGetAgentInfo();

  const contractAccepted = useSelector(getContractAccepted);
  const contractLoading = useSelector(getContractLoading);
  const contractSignDate = useSelector(getContractSignDate);
  const contractSignTimezone = useSelector(getContractSignTimezone);
  const userId = useSelector(getUserId);

  const { loading: transactionLoading } = useQuery(GET_TRANSACTION_DETAILS, {
    onCompleted: () => {
      TDGQL.selectedTransactionIdVar(transactionId);
      resetAgentInfo();
      dispatch(setHasAgreed(false));
    },
    skip: !transactionId,
    variables: {
      transactionId,
      userId: authUser?.uid,
    },
  });

  // Initialize the selectors
  const transaction = useGetTransactionDetailsHook();

  const [buyerAcceptClickwrap, { loading: acceptLoading }] = useMutation(
    BUYER_ACCEPT_CLICKWRAP,
    {
      onCompleted: () => {
        GeneralApollo.navTransactionId(transaction?.id);
        dispatch(userUpdateFirstLogin(false));
      },
    },
  );

  const [updateAgreedToTerms] = useMutation(UPDATE_AGREED_TO_TERMS, {
    onCompleted: async (didUpdate) => {
      if (didUpdate && didUpdate.response.success) {
        if (transaction?.isClickwrap) {
          await buyerAcceptClickwrap({
            variables: {
              input: {
                contractSignDate,
                contractSignTimezone,
                transactionId: transaction?.id,
                userAgentInfo: getUserAgentInfo(),
                agentType: agentInfo.agentType,
                agentBuyerEmail: agentInfo.email,
                agentBuyerName: agentInfo.name,
              },
            },
          });
        } else {
          await getBuyerSignURL({
            variables: {
              transactionId: transaction?.id,
              isMobile: false,
              agentType: agentInfo.agentType,
              agentBuyerEmail: agentInfo.email,
              agentBuyerName: agentInfo.name,
            },
          });
        }
      }
    },
  });

  const [
    cancelTransaction,
    {
      data: cancelTransactionData,
      loading: cancelTransactionLoading,
      error: cancelTransactionError,
    },
  ] = useMutation(CANCEL_TRANSACTION, {
    refetchQueries: [GET_TRANSACTION_DETAILS],
  });

  const [
    getBuyerSignURL,
    {
      data: buyerSignURL,
      loading: getBuyerSignURLLoading,
      error: getBuyerSignURLError,
    },
  ] = useLazyQuery(GET_BUYER_SIGNLINK, {
    onCompleted: (buyerSignLink) => {
      if (buyerSignLink && buyerSignLink.response) {
        dispatch(userUpdateFirstLogin(false));
        window.location.href = buyerSignURL.response;
      }
    },
    fetchPolicy: 'network-only',
  });

  const artworkData = transaction?.items?.[0];
  const workData = artworkData?.work;

  useEffect(() => {
    if (workData) {
      const artistName =
        workData.artist.artistName ||
        `${workData.artist.firstName} ${workData.artist.lastName}`;
      navigation.setOptions({
        title: `Sales Offer for ${workData.title} by ${artistName}`,
      });
    }
  }, [workData, navigation]);

  useEffect(() => {
    if (transaction && transaction?.status === Status.void) {
      setVoided(true);
    }
  }, [transaction, dispatch, setVoided]);

  const handleUpdateAgreedToTerms = async () => {
    const variables = {
      userId,
      agreedTimeStamp: new Date().toISOString(),
      agreedTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    await updateAgreedToTerms({ variables });
  };

  const cancelTransactionHandler = useCallback(async () => {
    if (transaction) {
      const variables = {
        transactionId: transaction?.id,
        userId: transaction?.buyer.id,
      };
      await cancelTransaction({ variables });
      logAnalyticsEvent(FCAnalyticEvents.TransactionUpdate, {
        transactionId: transaction?.id,
      });
    }
  }, [cancelTransaction, transaction]);

  const expirationHeader = useExpirationHeader(transaction?.expirationTime);

  if (getBuyerSignURLError)
    return <ErrorMessage error={getBuyerSignURLError} />;

  if (
    transactionLoading ||
    getBuyerSignURLLoading ||
    buyerSignURL ||
    cancelTransactionLoading
  ) {
    return (
      <View style={{ alignItems: 'center', flex: 1 }}>
        <SpinLoader
          placeholderText={
            cancelTransactionLoading
              ? ''
              : 'Loading contract, this may take a few moments.'
          }
        />
      </View>
    );
  }

  return (
    <View
      style={TailwindResponsive(`flex-col flex-1 p-16 mobWeb:p-8 bg-white`)}
    >
      <View
        style={TailwindResponsive(
          `flex-row justify-between w-1/2 mobWeb:w-full mobWeb:mb-10 mobWeb:flex-col mob:flex-col mob:mb-24`,
        )}
      >
        <SellerBranding seller={transaction?.seller} />
        {!!transaction?.expirationTime && (
          <View>
            <GraphikTextSm
              style={TailwindResponsive(
                `text-right mob:text-left mobWeb:text-left mb-1`,
              )}
            >
              {`Expires ${expirationHeader}`}
            </GraphikTextSm>
            <CountdownTimer endDate={transaction?.expirationTime} />
          </View>
        )}
      </View>

      <GraphikSemiTextLg
        style={TailwindResponsive(`mb-2 flex flex-col w-1/2 mobWeb:w-full`)}
        lineHeight="0px"
        color={Styles.Colours.Dark1}
      >
        {`${
          transaction?.isRetro ? 'Certification Agreement' : 'Sales Agreement'
        } to ${transaction?.buyer.firstName} ${transaction?.buyer.lastName}`}
        {!!transaction?.createDate && (
          <GraphikTextSm style={TailwindResponsive(`mb-4 mt-1`)}>
            {`Sent ${new Date(transaction?.createDate).toDateString()}`}
          </GraphikTextSm>
        )}
      </GraphikSemiTextLg>

      <View style={TailwindResponsive(`relative`)}>
        {transaction?.items.map(({ discount, price, work }, index) => (
          <View
            key={`${work.id}_View`}
            style={TailwindResponsive(
              `flex flex-row mb-6 relative mobWeb:mb-2 mobWeb:flex-col-reverse mob:flex-wrap`,
            )}
          >
            <View style={TailwindResponsive(`flex-1`)}>
              <TransactionItem
                index={index}
                discount={discount}
                key={work.id}
                price={price}
                work={work}
              />
            </View>

            <View
              style={TailwindResponsive(
                `flex-1 pl-14 h-96 lg:h-165 mobWeb:mt-16 mobWeb:mb-8 mobWeb:p-0 mobWeb:flex-none`,
              )}
            >
              <Image
                source={work.images[0] as ImageSourcePropType}
                style={TailwindResponsive(`flex-1`)}
                resizeMode="contain"
              />
            </View>
          </View>
        ))}
      </View>

      {transaction?.isMultiWork && (
        <View style={TailwindResponsive(`w-1/2 mobWeb:w-full`)}>
          <View style={TailwindResponsive(`mb-20 mt-5`)}>
            <TransactionValue />
          </View>

          {transaction?.canAcceptOffer && (
            <>
              <View style={TailwindResponsive(`mb-10`)}>
                <GiftOption />
              </View>

              {(transaction?.stripeInvoiceId ||
                transaction?.stripePaymentIntentId) && <InvoiceAffordance />}

              <View style={TailwindResponsive(`mt-10 mob:my-3`)}>
                <View style={TailwindResponsive(`flex-row items-center`)}>
                  <Checkbox
                    isChecked={agreedToTerms}
                    onPress={() => {
                      setAgreedToTerms((agreed) => !agreed);
                    }}
                    size={18}
                  />
                  <GraphikTextSm style={TailwindResponsive('ml-8')}>
                    Accept Fairchain&apos;s{' '}
                    <GraphikTextSm
                      onPress={() => Linking.openURL(`${SITE_BASE_URL}/terms`)}
                      style={TailwindResponsive(`underline`)}
                    >
                      Terms of Service
                    </GraphikTextSm>{' '}
                    to continue.
                  </GraphikTextSm>
                </View>
              </View>
              {!!transaction && transaction?.isClickwrap && (
                <Clickwrap transactionId={transaction?.id} />
              )}
              <View
                style={TailwindResponsive(
                  `w-full flex-row mob:flex-col mobWeb:flex-col mt-5 justify-between`,
                )}
              >
                <View style={TailwindResponsive(`w-64 justify-center`)}>
                  <FairchainButton
                    buttonStyle={TailwindResponsive(
                      `bg-Primary items-start pl-6 rounded-full w-64`,
                    )}
                    label={
                      transaction?.isRetro
                        ? 'Accept Certificate'
                        : 'Accept Offer'
                    }
                    onPress={() => {
                      handleUpdateAgreedToTerms();
                    }}
                    loading={
                      getBuyerSignURLLoading ||
                      !!buyerSignURL ||
                      (contractLoading && transaction?.isClickwrap) ||
                      acceptLoading
                    }
                    disabled={
                      transaction?.isClickwrap
                        ? !agreedToTerms || !contractAccepted
                        : !agreedToTerms
                    }
                    spinnerColor={Styles.Colours.Light1}
                    textStyle={TailwindResponsive(`pl-6 text-Light1`)}
                  />
                </View>

                {transaction &&
                  (transaction?.enableBuyerRejection ||
                    transaction?.enableBuyerRejection == null) && (
                    <View>
                      <TextButton
                        onPress={() => {
                          cancelTransactionHandler();
                        }}
                        label={
                          transaction?.isRetro
                            ? 'Decline Certificate'
                            : 'Decline Sales Offer'
                        }
                        color={Styles.Colours.Gray2}
                        style={TailwindResponsive(`px-0`)}
                        textStyle={{
                          fontSize: Styles.Fonts.FontSize.Small.toString(),
                          fontFamily: Styles.Fonts.FontFamily.SansRegular,
                        }}
                      />
                    </View>
                  )}
                {(cancelTransactionData && !cancelTransactionData.response) ||
                  (cancelTransactionError && (
                    <GraphikSemiTextSm style={TailwindResponsive('text-Dark1')}>
                      Unable to cancel transaction
                    </GraphikSemiTextSm>
                  ))}
              </View>
            </>
          )}
        </View>
      )}

      {transaction?.seller.isGallery && (
        <View style={TailwindResponsive('flex-row mt-10 -mb-20 mr-5')}>
          <GraphikTextSm style={TailwindResponsive('mt-14 -mr-12')}>
            Powered by
          </GraphikTextSm>
          <WordmarkHeader />
        </View>
      )}
    </View>
  );
};
