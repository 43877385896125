import { useQuery } from '@apollo/client';
import { GET_STRIPE_ACCOUNT_LINK } from 'api/requests.v2';
import { SpinLoader } from 'components/shared/loader';
import { StripeRefetchRouteProp } from 'navigation/types';
import React, { FunctionComponent, useEffect } from 'react';
import { View } from 'react-native';

interface IStripeRefetchProps {
  route: StripeRefetchRouteProp;
}

export const StripeRefetch: FunctionComponent<IStripeRefetchProps> = ({
  route,
}) => {
  const { data } = useQuery(GET_STRIPE_ACCOUNT_LINK, {
    variables: { stripeAccountId: route.params.stripeAccountId },
  });

  useEffect(() => {
    if (data) {
      window.location = data.response;
    }
  }, [data]);

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <SpinLoader />
    </View>
  );
};
