import { useMutation, useQuery } from '@apollo/client';
import { GET_SUB_USERS, UPDATE_USER_INFO } from 'api/requests.v2';
import { EXPOCONFIG, SITE_VERSION } from 'constants/constants';
import { UserContext } from 'contexts';
import { Dispatch, useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSubUsers, setSubUsersLoading, userUpdate } from 'store/user';
import { User } from 'types';
import { FCAnalyticEvents, logAnalyticsEvent } from 'utilities';
import { ProfileVars } from './apollo';

export const useGetSubUsers = () => {
  const authUser = useContext(UserContext);
  const dispatch = useDispatch();

  const { error } = useQuery(GET_SUB_USERS, {
    variables: {
      userId: authUser?.uid,
    },
    onCompleted: ({ response }) => {
      if (response) {
        dispatch(setSubUsers(response));
        dispatch(setSubUsersLoading(false));
      }
    },
    onError: () => {
      dispatch(setSubUsersLoading(false));
    },
  });

  useEffect(() => {
    ProfileVars.subUsers.error(error);
  }, [error]);
};

export const useUpdateUser = (
  authUserUid: string | undefined,
  dispatch: Dispatch<any>,
  tempAddressLineOne: string,
  tempAddressLineTwo: string,
  tempCity: string,
  tempFirstName: string,
  tempLastName: string,
  tempPhoneNumber: string,
  tempState: string,
  tempZipCode: string,
  userFromStore: User | null,
) => {
  const [updateUser, { error: updateUserInfoError }] =
    useMutation(UPDATE_USER_INFO);

  const updateUserHandler = useCallback(async () => {
    if (userFromStore) {
      const addressVariables = {
        billingAddressLineOne: tempAddressLineOne,
        billingAddressLineTwo: tempAddressLineTwo,
        billingAddressState: tempState,
        billingAddressCity: tempCity,
        billingAddressZipcode: tempZipCode,
        shippingAddressLineOne: tempAddressLineOne,
        shippingAddressLineTwo: tempAddressLineTwo,
        shippingAddressState: tempState,
        shippingAddressCity: tempCity,
        shippingAddressZipcode: tempZipCode,
      };
      const userVariables = {
        id: userFromStore.id,
        firstName: tempFirstName,
        lastName: tempLastName,
        phoneNumber: tempPhoneNumber,
      };

      await updateUser({
        variables: {
          updateUserInput: {
            ...addressVariables,
            ...userVariables,
          },
        },
      })
        .then(() => {
          logAnalyticsEvent(FCAnalyticEvents.UserUpdate, { uid: authUserUid });
          dispatch(
            userUpdate({
              ...userFromStore,
              ...userVariables,
              address: {
                ...userFromStore.address,
                ...addressVariables,
              },
            }),
          );
        })
        .catch((err) => err);
    }
  }, [
    authUserUid,
    dispatch,
    tempAddressLineOne,
    tempAddressLineTwo,
    tempCity,
    tempFirstName,
    tempLastName,
    tempPhoneNumber,
    tempState,
    tempZipCode,
    updateUser,
    userFromStore,
  ]);
  return { updateUserHandler, updateUserInfoError };
};

export const useVersion = () => {
  const baseVersion = EXPOCONFIG?.version;
  const buildNumber = EXPOCONFIG?.ios?.buildNumber;

  const numSiteVersion = SITE_VERSION.substring(1);
  const siteVersion =
    numSiteVersion !== baseVersion ? `—${numSiteVersion}` : '';

  return `${baseVersion}.${buildNumber}${siteVersion}`;
};
