import { useReactiveVar } from '@apollo/client';
import { ErrorMessage } from 'components/shared/error';
import React, { FunctionComponent } from 'react';
import { createTransactionErrorVar } from 'store/transferWork/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const TransErrorBanner: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const transError = useReactiveVar(createTransactionErrorVar);

  if (!transError) return null;

  return (
    <ErrorMessage
      errorMessage={`There was an error in generating your transaction. ${transError}.`}
      errorStyle={TailwindResponsive(
        `-mt-14 mb-14 bg-red-200 items-center p-4`,
      )}
      textStyle={TailwindResponsive(`m-0 p-0`)}
    />
  );
};

export default TransErrorBanner;
