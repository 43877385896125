import React, { FunctionComponent } from 'react';
import { Pressable } from 'react-native';
import Styles from 'style';
import ExitButtonIcon from '../../graphics/ExitButtonIcon';

interface ExitPopUpButtonProps {
  onPress?: () => void;
}

export const ExitPopUpButton: FunctionComponent<ExitPopUpButtonProps> = ({
  onPress,
}) => (
  <Pressable style={{}} onPress={onPress}>
    <ExitButtonIcon color={Styles.Colours.Dark1} />
  </Pressable>
);

export default ExitPopUpButton;
