/* eslint-disable no-nested-ternary */
import { useReactiveVar } from '@apollo/client';
import { useLinkTo } from '@react-navigation/native';
import {
  createStackNavigator,
  StackNavigationOptions,
} from '@react-navigation/stack';
import { SpinLoader } from 'components/shared/loader';
import { ArtworkDetails } from 'components/views/artworkDetails';
import { ArtworkPublicView } from 'components/views/artworkPublicView/ArtworkPublicView';
import { AutoSignInAuth } from 'components/views/auth/AutoSignInAuth';
import { Collection } from 'components/views/collection/Collection';
import { Identify } from 'components/views/identify/Identify';
import { Inbox } from 'components/views/inbox';
import LoadContract from 'components/views/loadContract/LoadContract';
import { TwoFactorAuthentication } from 'components/views/login/2FA';
import Login from 'components/views/login/Login';
import { Profile, StripeRefetch } from 'components/views/profile';
import { RegisterWork } from 'components/views/registerWork';
import SignUp from 'components/views/signup/SignUp';
import { NotFound } from 'components/views/siteInfo/notFound';
import StripePaymentReturn from 'components/views/transactionDetails/StripePaymentReturn';
import TransactionDetail from 'components/views/transactionDetails/TransactionDetails';
import { Agreements } from 'components/views/userOnboarding/Agreements';
import { UserContext } from 'contexts';
import { NativeStackParamList } from 'navigation';
import React, {
  FunctionComponent,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getRedirectURL, setRedirect } from 'store/general';
import { getAuthenticated, getUser } from 'store/user';
import { FCUser } from 'store/user/apollo';
import { logError } from 'utilities';

const TransferWork = React.lazy(() =>
  import('components/views/transferWork').then((module) => ({
    default: module.TransferWork,
  })),
);

const ResetPassword = React.lazy(() =>
  import('components/views/login').then((module) => ({
    default: module.ResetPassword,
  })),
);

const ResetPasswordProfile = React.lazy(() =>
  import('components/views/profile').then((module) => ({
    default: module.ResetPasswordProfile,
  })),
);

const Admin = React.lazy(() =>
  import('components/views/admin').then((module) => ({
    default: module.Admin,
  })),
);

const Consignment = React.lazy(() =>
  import('components/views/consignment').then((module) => ({
    default: module.Consignment,
  })),
);

const Import = React.lazy(() =>
  import('components/views/import').then((module) => ({
    default: module.Import,
  })),
);

const ImageOverlay = React.lazy(() =>
  import('components/shared/imagepicker/ImageOverlay').then((module) => ({
    default: module.ImageOverlay,
  })),
);
const Contact = React.lazy(() =>
  import('components/views/siteInfo/contact').then((module) => ({
    default: module.Contact,
  })),
);
const TermsOfServiceStatic = React.lazy(() =>
  import('components/views/siteInfo/termsOfService').then((module) => ({
    default: module.TermsOfServiceStatic,
  })),
);
const PrivacyPolicy = React.lazy(() =>
  import('components/views/siteInfo/privacyPolicy').then((module) => ({
    default: module.PrivacyPolicy,
  })),
);
const TransactionSignReturn = React.lazy(() =>
  import('components/views/transactionDetails/TransactionSignReturn').then(
    (module) => ({
      default: module.TransactionSignReturn,
    }),
  ),
);
const Transactions = React.lazy(() =>
  import('components/views/transactions').then((module) => ({
    default: module.Transactions,
  })),
);

const QBReturn = React.lazy(() =>
  import('components/views/profile/QBReturn').then((module) => ({
    default: module.QBReturn,
  })),
);

const Stack = createStackNavigator<NativeStackParamList>();

export const StackScreenOptions: StackNavigationOptions =
  Platform.OS !== 'web'
    ? {
        animationEnabled: false,
        headerShown: false,
      }
    : {
        headerShown: false,
      };

export const RootNavigator: FunctionComponent = () => {
  const dispatch = useDispatch();
  const linkTo = useLinkTo();

  const firebaseUser = useContext(UserContext);

  const authToken = useReactiveVar(FCUser.AuthToken);

  const hasBeen2FAD = useSelector(getAuthenticated);
  const redirectUrl = useSelector(getRedirectURL);
  const userInfo = useSelector(getUser);

  const [redirectRoute, setRedirectRoute] = useState<string | null>(null);
  const isFirstLogin = userInfo?.firstLogin;
  const agreedToTerms = userInfo?.agreedTimeStamp;

  useEffect(() => {
    if (firebaseUser && redirectUrl && userInfo && !isFirstLogin) {
      setRedirectRoute(redirectUrl);
    }
  }, [firebaseUser, dispatch, redirectUrl, userInfo, isFirstLogin]);

  useEffect(() => {
    if (redirectRoute && hasBeen2FAD) {
      try {
        linkTo(`${redirectRoute}`);
      } catch (error) {
        logError(error, `Error in handling redirect for: ${redirectRoute}`);
      }
      dispatch(setRedirect(null));
      setRedirectRoute(null);
    }
  }, [dispatch, hasBeen2FAD, linkTo, redirectRoute]);

  if (Platform.OS === 'web' && !!firebaseUser && !!userInfo && hasBeen2FAD) {
    return (
      <Suspense fallback={<SpinLoader />}>
        <Stack.Navigator screenOptions={StackScreenOptions}>
          {isFirstLogin ? (
            <>
              {agreedToTerms ? (
                <Stack.Screen name="Identify" component={Identify} />
              ) : (
                <Stack.Screen name="Agreements" component={Agreements} />
              )}
              <Stack.Screen name="Terms" component={TermsOfServiceStatic} />
            </>
          ) : (
            <>
              <Stack.Screen name="Collection" component={Collection} />
              <Stack.Screen name="Admin" component={Admin} />
              <Stack.Screen
                name="ArtworkPublicView"
                component={ArtworkPublicView}
              />
              <Stack.Screen name="Consignment" component={Consignment} />
              <Stack.Screen name="Contact" component={Contact} />
              <Stack.Screen name="Details" component={ArtworkDetails} />
              <Stack.Screen name="Inbox" component={Inbox} />
              <Stack.Screen name="LoadContract" component={LoadContract} />
              <Stack.Screen
                name="PrivacyPolicy"
                component={PrivacyPolicy}
                options={{ title: 'Privacy Policy' }}
              />
              <Stack.Screen
                name="RegisterWork"
                component={RegisterWork}
                options={{ title: 'Register Work' }}
              />
              <Stack.Screen name="Import" component={Import} />
              <Stack.Screen name="Terms" component={TermsOfServiceStatic} />
              <Stack.Screen name="Transactions" component={Transactions} />
              <Stack.Screen
                name="TransferWork"
                component={TransferWork}
                options={{ title: 'Generate Sales Agreement' }}
              />
              <Stack.Screen name="Profile" component={Profile} />
              <Stack.Screen
                name="ResetPasswordProfile"
                component={ResetPasswordProfile}
              />
              <Stack.Screen name="StripeRefetch" component={StripeRefetch} />
              <Stack.Screen
                name="TransactionDetail"
                component={TransactionDetail}
                options={{ title: 'Sales Offer' }}
              />
              <Stack.Screen
                name="TransactionSignReturn"
                component={TransactionSignReturn}
                options={{ title: 'Fairchain' }}
              />
              <Stack.Screen
                name="StripePaymentReturn"
                component={StripePaymentReturn}
                options={{ title: 'Payment' }}
              />
              <Stack.Screen
                name="ImageOverlay"
                component={ImageOverlay}
                options={{
                  presentation: 'transparentModal',
                }}
              />
              <Stack.Screen name="NotFound" component={NotFound} />
              <Stack.Screen name="QBReturn" component={QBReturn} />
            </>
          )}
        </Stack.Navigator>
      </Suspense>
    );
  }

  if (Platform.OS === 'ios' && !!firebaseUser && !!userInfo && hasBeen2FAD) {
    return (
      <Suspense fallback={<SpinLoader />}>
        <Stack.Navigator
          screenOptions={StackScreenOptions}
          initialRouteName="Collection"
        >
          {isFirstLogin && (
            <>
              {agreedToTerms ? (
                <Stack.Screen name="Identify" component={Identify} />
              ) : (
                <Stack.Screen name="Agreements" component={Agreements} />
              )}
              <Stack.Screen name="NotFound" component={NotFound} />
              <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
              <Stack.Screen name="ResetPassword" component={ResetPassword} />
              <Stack.Screen name="Terms" component={TermsOfServiceStatic} />
            </>
          )}
          {!isFirstLogin && (
            <>
              <Stack.Screen name="Collection" component={Collection} />
              <Stack.Screen name="Consignment" component={Consignment} />
              <Stack.Screen name="Contact" component={Contact} />
              <Stack.Screen name="Details" component={ArtworkDetails} />
              <Stack.Screen
                name="ImageOverlay"
                component={ImageOverlay}
                options={{
                  presentation: 'transparentModal',
                }}
              />
              <Stack.Screen name="Inbox" component={Inbox} />
              <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
              <Stack.Screen name="Profile" component={Profile} />
              <Stack.Screen name="RegisterWork" component={RegisterWork} />
              <Stack.Screen
                name="ResetPasswordProfile"
                component={ResetPasswordProfile}
              />
              <Stack.Screen name="StripeRefetch" component={StripeRefetch} />
              <Stack.Screen name="Terms" component={TermsOfServiceStatic} />
              <Stack.Screen
                name="TransactionDetail"
                component={TransactionDetail}
                options={{ title: 'Sales Offer' }}
              />
              <Stack.Screen name="Transactions" component={Transactions} />
              <Stack.Screen
                name="TransactionSignReturn"
                component={TransactionSignReturn}
                options={{ title: 'Fairchain' }}
              />
              <Stack.Screen
                name="StripePaymentReturn"
                component={StripePaymentReturn}
                options={{ title: 'Fairchain' }}
              />
              <Stack.Screen
                name="TransferWork"
                component={TransferWork}
                options={{ title: 'Generate Sales Agreement' }}
              />
              <Stack.Screen name="QBReturn" component={QBReturn} />
            </>
          )}
        </Stack.Navigator>
      </Suspense>
    );
  }

  if (firebaseUser && !hasBeen2FAD) {
    return (
      <Suspense fallback={<SpinLoader />}>
        <Stack.Navigator screenOptions={StackScreenOptions}>
          <Stack.Screen
            name="Authentication"
            component={TwoFactorAuthentication}
          />
        </Stack.Navigator>
      </Suspense>
    );
  }

  if (authToken) {
    return (
      <Suspense fallback={<SpinLoader />}>
        <Stack.Navigator screenOptions={StackScreenOptions}>
          <Stack.Screen name="AuthVerify" component={AutoSignInAuth} />
        </Stack.Navigator>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<SpinLoader />}>
      <Stack.Navigator screenOptions={StackScreenOptions}>
        <Stack.Screen
          name="Login"
          component={Login}
          initialParams={redirectUrl ? { redirectURL: redirectUrl } : {}}
        />
        <Stack.Screen name="SignUp" component={SignUp} />

        {Platform.OS === 'web' && (
          <>
            <Stack.Screen
              name="ArtworkPublicView"
              component={ArtworkPublicView}
            />
            <Stack.Screen name="Contact" component={Contact} />
            <Stack.Screen name="NotFound" component={NotFound} />
            <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
            <Stack.Screen name="Terms" component={TermsOfServiceStatic} />
            <Stack.Screen name="ResetPassword" component={ResetPassword} />
          </>
        )}
      </Stack.Navigator>
    </Suspense>
  );
};
