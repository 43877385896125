import { useQuery, useReactiveVar } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import { GET_ALL_ARTISTS } from 'api/requests.v2';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { ErrorMessage } from 'components/shared/error';
import DefaultTextInput from 'components/shared/form/DefaultTextInput';
import { GraphikMediumTextSm } from 'components/shared/styled';
import React, { FunctionComponent, useCallback } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Pressable, View } from 'react-native';
import { RegisterStore } from 'store/registerWork/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useGetFormDetails } from '../forms/artist-registration.form';

export const AddContributors: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const arForm = useGetFormDetails(detailsForm);
  const { fields, append, remove } = useFieldArray({
    control: arForm?.control,
    name: 'contributors',
  });

  const { error: getAllArtistsError, refetch } = useQuery(GET_ALL_ARTISTS);

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  if (getAllArtistsError) return <ErrorMessage />;

  const renderNewContributor = () => {
    const newContributor = {
      email: '',
      firstName: '',
      lastName: '',
      role: '',
    };

    append(newContributor);
  };

  return (
    <View style={TailwindResponsive(`mb-10 mobWeb:mb-0`)}>
      <View style={{ zIndex: -1 }}>
        {fields.map((item, index) => (
          <View key={item.id}>
            <View
              style={TailwindResponsive('flex flex-row justify-between mt-16')}
            >
              <GraphikMediumTextSm>Contributor {index + 1}</GraphikMediumTextSm>
              <FairchainButton
                buttonStyle={TailwindResponsive(`justify-start self-start`)}
                label="Remove"
                onPress={() => remove(index)}
                textStyle={TailwindResponsive(`underline`)}
              />
            </View>
            <Controller
              control={arForm?.control}
              name={`contributors.${index}.firstName`}
              render={({ field: { onChange, value, ref } }) => (
                <DefaultTextInput
                  errorMessage={
                    arForm?.formState?.errors?.contributors?.[index]?.firstName
                      ?.message as string
                  }
                  handleChange={(input) => {
                    arForm?.clearErrors(`contributors.${index}.firstName`);
                    onChange(input);
                  }}
                  inputRef={ref}
                  label="* First Name"
                  testId={`contributors.${index}.firstName`}
                  value={value}
                />
              )}
            />
            <Controller
              control={arForm?.control}
              name={`contributors.${index}.lastName`}
              render={({ field: { onChange, value, ref } }) => (
                <DefaultTextInput
                  errorMessage={
                    arForm?.formState?.errors?.contributors?.[index]?.lastName
                      ?.message as string
                  }
                  handleChange={(input) => {
                    arForm?.clearErrors(`contributors.${index}.lastName`);
                    onChange(input);
                  }}
                  inputRef={ref}
                  label="* Last Name"
                  testId={`contributors.${index}.lastName`}
                  value={value}
                />
              )}
            />
            <Controller
              control={arForm?.control}
              name={`contributors.${index}.role`}
              render={({ field: { onChange, value, ref } }) => (
                <DefaultTextInput
                  errorMessage={
                    arForm?.formState?.errors?.contributors?.[index]?.role
                      ?.message as string
                  }
                  handleChange={(input) => {
                    arForm?.clearErrors(`contributors.${index}.role`);
                    onChange(input);
                  }}
                  inputRef={ref}
                  label="* Role"
                  testId={`contributors.${index}.role`}
                  value={value}
                />
              )}
            />
            <Controller
              control={arForm?.control}
              name={`contributors.${index}.email`}
              render={({ field: { onChange, value, ref } }) => (
                <DefaultTextInput
                  errorMessage={
                    arForm?.formState?.errors?.contributors?.[index]?.email
                      ?.message as string
                  }
                  handleChange={(input) => {
                    arForm?.clearErrors(`contributors.${index}.email`);
                    onChange(input);
                  }}
                  inputRef={ref}
                  label="* Email"
                  testId={`contributors.${index}.email`}
                  value={value}
                />
              )}
            />
          </View>
        ))}
        <View
          style={TailwindResponsive(
            'flex flex-row justify-between mt-2 mob:mt-8',
          )}
        >
          <Pressable onPress={renderNewContributor}>
            <GraphikMediumTextSm style={TailwindResponsive('text-Primary')}>
              Link Additional Contributor +
            </GraphikMediumTextSm>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

export default AddContributors;
