import { GraphikTextMd, MediumTitle } from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { Linking, Pressable, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ColOnboardingScreen6: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View style={TailwindResponsive(`items-center`)}>
      <MediumTitle style={TailwindResponsive(`mb-8`)}>
        It&apos;s that easy!
      </MediumTitle>

      <GraphikTextMd>
        Ready to start cataloguing and certifying works in your collection? Make
        sure to have artwork details and at least one photo on hand or contact
        <Pressable
          onPress={() => {
            Linking.openURL('mailto: support@fairchain.art');
          }}
        >
          <GraphikTextMd style={TailwindResponsive(`underline`)}>
            support@fairchain.art
          </GraphikTextMd>
        </Pressable>{' '}
        for assistance with importing your inventory.
      </GraphikTextMd>
    </View>
  );
};

export default ColOnboardingScreen6;
