import { useNavigation } from '@react-navigation/native';
import { ImageSelectionPage } from 'constants/enums';
import { NativeStackParamList } from 'navigation';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { resetLogo, resetSignature } from 'store/admin';
import { IImageKey } from 'store/registerWork';

export const useImagePicker = (
  imageKey: IImageKey | undefined,
  page: number,
  image?: string,
) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const linkConfig: {
    screen: keyof NativeStackParamList;
    params: NativeStackParamList[keyof NativeStackParamList];
  } = useMemo(() => {
    if (page === ImageSelectionPage.Admin) {
      return {
        screen: 'ImageOverlay',
        params: {
          imageURI: image!,
          page: ImageSelectionPage.Admin,
        },
      };
    }
    return {
      screen: 'ImageOverlay',
      params: {
        imageURI: image!,
        imageKey,
        page: ImageSelectionPage.RegisterWork,
      },
    };
  }, [image, imageKey, page]);

  const onDeleteImage = useCallback(() => {
    if (page === ImageSelectionPage.Admin) {
      dispatch(resetSignature());
      dispatch(resetLogo());
      navigation.goBack();
    }
  }, [dispatch, navigation, page]);

  return { linkConfig, onDeleteImage };
};
