import { useQuery, useReactiveVar } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/core';
import { GET_AFFILIATED_USERS } from 'api/requests.v2';
import { Checkbox } from 'components/shared/checkbox';
import { ErrorMessage } from 'components/shared/error';
import Autocomplete from 'components/shared/form/Autocomplete';
import DefaultTextInput from 'components/shared/form/DefaultTextInput';
import { SpinLoader } from 'components/shared/loader';
import { GraphikSemiTextSm } from 'components/shared/styled';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useSelector } from 'react-redux';
import { getFeatureFlags } from 'store/general';
import { getIsRetro } from 'store/transferWork';
import { buyerRegistrationFormVar, worksVar } from 'store/transferWork/apollo';
import { getUser } from 'store/user';
import Styles from 'style';
import { AutocompleteItem, User } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { AdditionalInformation } from '../transferWork/AdditionalInformation';
import { TransferWorkForm } from './Helpers';
import KYC from './components/KYC';
import { useIsCollectorRetroTrans } from './hooks';

interface IBuyerRegistrationProps {
  isKycScreen: boolean;
  keyboardScrollRef: React.RefObject<KeyboardAwareScrollView>;
}

const formatAddress = (addressObject) =>
  `${addressObject.billingAddressLineOne} ${addressObject.billingAddressCity} ${addressObject.billingAddressState} ${addressObject.billingAddressZipcode}`;

export const BuyerRegistration: FunctionComponent<IBuyerRegistrationProps> = ({
  isKycScreen,
  keyboardScrollRef,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const buyerForm = useReactiveVar(buyerRegistrationFormVar);
  const control = buyerForm?.control;
  const works = useReactiveVar(worksVar);

  const isCollectorRetro = useIsCollectorRetroTrans();

  const currentUser = useSelector(getUser);
  const featureFlags = useSelector(getFeatureFlags);
  const isRetro = useSelector(getIsRetro);

  const {
    loading,
    data,
    error: getAffiliatedUsersError,
    refetch: refetchAffiliatedUsers,
  } = useQuery(GET_AFFILIATED_USERS, {
    variables: {
      userId: currentUser?.id,
    },
  });

  const [affiliatedUsers, setAffiliatedUsers] = useState<[User]>();
  const [autocompleteItems, setAutocompleteItems] =
    useState<[AutocompleteItem]>();

  useFocusEffect(
    useCallback(() => {
      refetchAffiliatedUsers();
    }, [refetchAffiliatedUsers]),
  );

  useEffect(() => {
    if (!loading && data) {
      setAutocompleteItems(
        data?.response.map((item: User) => ({
          name: `${item.firstName} ${item.lastName}`,
          id: item.id,
          address: formatAddress(item.address),
        })),
      );
      setAffiliatedUsers(data?.response);
    }
  }, [data, loading]);

  useEffect(() => {
    if (buyerForm && isCollectorRetro) {
      // Buyer prefill for collector retros
      buyerForm.setValue(
        TransferWorkForm.firstName,
        works[0]?.owner?.firstName || '',
      );
      buyerForm.setValue(
        TransferWorkForm.lastName,
        works[0]?.owner?.lastName || '',
      );
      buyerForm.setValue(
        TransferWorkForm.company,
        works[0]?.owner?.company || '',
      );
      buyerForm.setValue(TransferWorkForm.email, works[0]?.owner?.email || '');
      buyerForm.setValue(
        TransferWorkForm.phoneNumber,
        works[0]?.owner?.phoneNumber || '',
      );

      // Address prefill
      buyerForm.setValue(
        TransferWorkForm.addressLineOne,
        works[0]?.owner?.address.billingAddressLineOne || '',
      );
      buyerForm.setValue(
        TransferWorkForm.addressLineTwo,
        works[0]?.owner?.address.billingAddressLineTwo || '',
      );
      buyerForm.setValue(
        TransferWorkForm.city,
        works[0]?.owner?.address.billingAddressCity || '',
      );
      buyerForm.setValue(
        TransferWorkForm.state,
        works[0]?.owner?.address.billingAddressState || '',
      );
      buyerForm.setValue(
        TransferWorkForm.zipcode,
        works[0]?.owner?.address.billingAddressZipcode || '',
      );
      buyerForm.setValue(
        TransferWorkForm.country,
        works[0]?.owner?.address.billingAddressCountry || '',
      );
    }
  }, [buyerForm, isCollectorRetro, works]);

  const setBuyerObjectToFields = (selectedUser: AutocompleteItem) => {
    const targetBuyerObject = affiliatedUsers!.find(
      (buyerObject) => buyerObject.id === selectedUser.id,
    );
    if (targetBuyerObject) {
      Object.entries(targetBuyerObject).forEach(([key, value]) => {
        switch (key) {
          case 'company':
            buyerForm?.setValue(TransferWorkForm.company, value || '');
            break;
          case 'email':
            buyerForm?.setValue(TransferWorkForm.email, value || '');
            break;
          case 'firstName':
            buyerForm?.setValue(TransferWorkForm.firstName, value || '');
            break;
          case 'lastName':
            buyerForm?.setValue(TransferWorkForm.lastName, value || '');
            break;
          case 'phoneNumber':
            buyerForm?.setValue(TransferWorkForm.phoneNumber, value || '');
            break;
          case 'id':
            buyerForm?.setValue(TransferWorkForm.buyerId, value || '');
            break;
          default:
            break;
        }
      });
      Object.entries(targetBuyerObject.address).forEach(([key, value]) => {
        switch (key) {
          case 'billingAddressLineOne':
            buyerForm?.setValue(TransferWorkForm.addressLineOne, value || '');
            break;
          case 'billingAddressLineTwo':
            buyerForm?.setValue(TransferWorkForm.addressLineTwo, value || '');
            break;
          case 'billingAddressCity':
            buyerForm?.setValue(TransferWorkForm.city, value || '');
            break;
          case 'billingAddressState':
            buyerForm?.setValue(TransferWorkForm.state, value || '');
            break;
          case 'billingAddressZipcode':
            buyerForm?.setValue(TransferWorkForm.zipcode, value || '');
            break;
          case 'billingAddressCountry':
            buyerForm?.setValue(TransferWorkForm.country, value || '');
            break;
          default:
            break;
        }
      });
      buyerForm?.trigger();
    }
  };

  if (
    isKycScreen &&
    buyerForm?.getValues('firstName') &&
    buyerForm?.getValues('lastName')
  ) {
    return (
      <KYC
        buyer={`${buyerForm?.getValues('firstName')} ${buyerForm?.getValues(
          'lastName',
        )}`}
      />
    );
  }

  return (
    <View
      style={TailwindResponsive(
        `mb-8 pr-16 w-1/2 small:w-full mobWeb:w-full mobWeb:pr-0`,
      )}
    >
      {!isCollectorRetro && (
        <View style={TailwindResponsive(`mb-4 mob:mb-12`)}>
          {loading && <SpinLoader />}
          {getAffiliatedUsersError && <ErrorMessage />}

          {data && (
            <Autocomplete
              placeholder="Search from client list"
              data={autocompleteItems!}
              handleOptionPress={(input: AutocompleteItem) => {
                setBuyerObjectToFields(input);
              }}
              numCharsRequired={1}
            />
          )}
        </View>
      )}

      {/* <GraphikTextSm style={TailwindResponsive('mb-24 mob:mb-12')}>
        After entering a buyer&apos;s information, you will have the option to
        save their information for future transactions.
      </GraphikTextSm> */}

      <View style={[TailwindResponsive(`mb-7 mob:mb-4`), { zIndex: -1 }]}>
        <GraphikSemiTextSm>
          {isRetro ? 'Owner' : 'Buyer'} Information
        </GraphikSemiTextSm>
      </View>

      <View style={{ zIndex: -1 }}>
        <Controller
          control={control}
          name={TransferWorkForm.firstName}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              label="* First Name"
              handleChange={(input) => {
                buyerForm?.clearErrors('firstName');
                onChange(input);
              }}
              editable={!isCollectorRetro}
              errorMessage={
                buyerForm?.formState?.errors?.firstName?.message as string
              }
              testId="GSAnewBuyerFirstName"
              value={value}
            />
          )}
        />

        <Controller
          control={control}
          name={TransferWorkForm.lastName}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              label="* Last Name"
              handleChange={(input) => {
                buyerForm?.clearErrors('lastName');
                onChange(input);
              }}
              editable={!isCollectorRetro}
              errorMessage={
                buyerForm?.formState?.errors?.lastName?.message as string
              }
              testId="GSAnewBuyerLastName"
              value={value}
            />
          )}
        />

        <Controller
          control={control}
          name={TransferWorkForm.company}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              label="Company (if applicable)"
              handleChange={onChange}
              editable={!isCollectorRetro}
              errorMessage={
                buyerForm?.formState?.errors?.company?.message as string
              }
              testId="GSAnewBuyerCompany"
              value={value}
            />
          )}
        />

        <Controller
          control={control}
          name={TransferWorkForm.email}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              autoCapitalize="none"
              autoCorrect={false}
              label="* Email"
              inputMode="email"
              handleChange={(input) => {
                buyerForm?.clearErrors('email');
                onChange(input);
              }}
              editable={!isCollectorRetro}
              errorMessage={
                buyerForm?.formState?.errors?.email?.message as string
              }
              testId="GSAnewBuyerEmail"
              value={value}
            />
          )}
        />

        <Controller
          control={control}
          name={TransferWorkForm.phoneNumber}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              editable={!isCollectorRetro}
              inputMode="tel"
              label="Phone Number"
              handleChange={onChange}
              testId="GSAnewBuyerPhoneNumber"
              value={value}
            />
          )}
        />

        <GraphikSemiTextSm
          style={TailwindResponsive(`mb-7 mt-16 mob:mt-7 mob:mb-4`)}
        >
          {isRetro ? 'Owner' : 'Buyer'} Billing Address
        </GraphikSemiTextSm>

        <Controller
          control={control}
          name={TransferWorkForm.addressLineOne}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              editable={!isCollectorRetro}
              label="* Address Line One"
              handleChange={(input) => {
                buyerForm?.clearErrors('addressLineOne');
                onChange(input);
              }}
              errorMessage={
                buyerForm?.formState?.errors?.addressLineOne?.message as string
              }
              testId="GSAnewBuyerAddressLineOne"
              value={value}
            />
          )}
        />

        <Controller
          control={control}
          name={TransferWorkForm.addressLineTwo}
          render={({ field }) => (
            <DefaultTextInput
              editable={!isCollectorRetro}
              value={field.value}
              label="Address Line Two"
              handleChange={field.onChange}
              testId="GSAnewBuyerAddressLineTwo"
            />
          )}
        />

        <Controller
          control={control}
          name={TransferWorkForm.city}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              editable={!isCollectorRetro}
              label="* City"
              handleChange={(input) => {
                buyerForm?.clearErrors('city');
                onChange(input);
              }}
              errorMessage={
                buyerForm?.formState?.errors?.city?.message as string
              }
              testId="GSAnewBuyerCity"
              value={value}
            />
          )}
        />

        <Controller
          control={control}
          name={TransferWorkForm.state}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              editable={!isCollectorRetro}
              label="* State / Province"
              handleChange={(input) => {
                buyerForm?.clearErrors('state');
                onChange(input);
              }}
              errorMessage={
                buyerForm?.formState?.errors?.state?.message as string
              }
              testId="GSAnewBuyerState"
              value={value}
            />
          )}
        />

        <Controller
          control={control}
          name={TransferWorkForm.zipcode}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              editable={!isCollectorRetro}
              label="Zip / Post code"
              handleChange={(input) => {
                buyerForm?.clearErrors('zipcode');
                onChange(input);
              }}
              errorMessage={
                buyerForm?.formState?.errors?.zipcode?.message as string
              }
              testId="GSAnewBuyerZip"
              value={value}
            />
          )}
        />

        <Controller
          control={control}
          name={TransferWorkForm.country}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              editable={!isCollectorRetro}
              label="* Country"
              handleChange={(input) => {
                buyerForm?.clearErrors('country');
                onChange(input);
              }}
              errorMessage={
                buyerForm?.formState?.errors?.country?.message as string
              }
              testId="GSAnewBuyerCountry"
              value={value}
            />
          )}
        />
        {/* <View style={TailwindResponsive('mt-6')}>
          <Controller
            control={control}
            name="saveClientState"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                labelStyle={Styles.Fonts.FontFamily.SansRegular}
                label="Save client data for future use?"
                isChecked={value}
                onPress={() => {
                  onChange(!value);
                }}
              />
            )}
          />
        </View> */}
      </View>

      <AdditionalInformation
        keyboardScrollRef={keyboardScrollRef}
        name={TransferWorkForm.optionalMessage}
        title={isRetro ? 'Message to Owner' : 'Message to Buyer'}
        placeholder="Want to add a message with this sales agreement? Type it here. (Optional)"
      />

      {featureFlags.kyc && (
        <View style={TailwindResponsive('mt-12')}>
          <Controller
            control={control}
            name={TransferWorkForm.kycCheck}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                labelStyle={Styles.Fonts.FontFamily.SansRegular}
                label="To protect your transactions from bad actors, you can opt for Fairchain to run a check on the buyer through our Know Your Customer service."
                isChecked={value}
                onPress={() => {
                  onChange(!value);
                }}
              />
            )}
          />
        </View>
      )}
    </View>
  );
};

export default BuyerRegistration;
