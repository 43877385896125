import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { FairchainButton } from '../button/FairchainButton';
import {
  GraphikMediumTextSm,
  GraphikSemiTextLg,
  GraphikSemiTextMd,
  GraphikTextSm,
  GraphikTextTiny,
} from '../styled';
import BulletPoint from './BulletPoint';
import { ISubOptionProps, PriceText } from './SubscriptionSelector';

export const PremiumOption: FunctionComponent<ISubOptionProps> = ({
  selectOption,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const subKeyPointStyle = TailwindResponsive('my-1.5');

  return (
    <View
      style={[
        {
          boxShadow: '0 0 10px rgb(0 0 0 / 0.2)',
        },
        TailwindResponsive(
          `flex items-center p-10 rounded-sm max-w-lg xl:flex-1 mob:border`,
        ),
      ]}
    >
      <GraphikSemiTextLg>Collector Premium</GraphikSemiTextLg>

      <View style={TailwindResponsive(`flex flex-row items-start mt-6`)}>
        <GraphikTextSm>$</GraphikTextSm>
        <PriceText>150</PriceText>
      </View>

      <GraphikTextSm>per month</GraphikTextSm>

      <View style={TailwindResponsive('h-28 items-center w-full')}>
        <FairchainButton
          buttonStyle={TailwindResponsive(`bg-Primary mt-8 rounded-full w-4/5`)}
          label="Select"
          onPress={selectOption}
          textStyle={TailwindResponsive(`text-Light1`)}
        />
      </View>

      <View
        style={TailwindResponsive(
          `border-t border-Dark3 items-center mt-8 pt-8`,
        )}
      >
        <GraphikSemiTextMd>Standard Features, Plus:</GraphikSemiTextMd>

        <View style={TailwindResponsive(`mt-2`)}>
          <GraphikTextSm style={subKeyPointStyle}>
            <GraphikMediumTextSm>Unlimited</GraphikMediumTextSm> work uploads
          </GraphikTextSm>

          <GraphikTextSm style={subKeyPointStyle}>
            <GraphikMediumTextSm>5</GraphikMediumTextSm> users included
          </GraphikTextSm>

          <GraphikTextSm style={subKeyPointStyle}>1-on-1 demo</GraphikTextSm>

          <GraphikTextSm style={subKeyPointStyle}>
            Unlimited phone and online priority support
          </GraphikTextSm>

          <GraphikTextSm style={subKeyPointStyle}>
            Preferred fixed-cost appraisals
          </GraphikTextSm>

          <View style={subKeyPointStyle}>
            <GraphikTextSm>Loan Facilitation & Assistance</GraphikTextSm>
            <View style={TailwindResponsive(`ml-4`)}>
              <BulletPoint>
                <GraphikTextTiny>
                  Designate select works for availability for inclusion in shows
                  by our vetted partner network of top curators.
                </GraphikTextTiny>
              </BulletPoint>
            </View>
          </View>

          <View style={subKeyPointStyle}>
            <GraphikTextSm>Artist Context Feeds</GraphikTextSm>
            <View style={TailwindResponsive(`ml-4`)}>
              <BulletPoint>
                <GraphikTextTiny>
                  Stay up to date on the latest news, shows and sales related to
                  artists in your collection.
                </GraphikTextTiny>
              </BulletPoint>
            </View>
          </View>

          <GraphikTextSm style={subKeyPointStyle}>
            Free data exports
          </GraphikTextSm>
        </View>
      </View>
    </View>
  );
};

export default PremiumOption;
