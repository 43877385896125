import { GraphikMediumTextMd, GraphikTextMd } from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { getUser } from 'store/user';
import { VeriffCodes } from 'types/Veriff';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const IdentifyWithVeriff: FunctionComponent = () => {
  const user = useSelector(getUser);
  const { TailwindResponsive } = useTailwindResponsive();

  let statusText = 'Pending';
  switch (user?.veriffStatus) {
    case VeriffCodes.Approved:
      statusText = 'Verified';
      break;
    case VeriffCodes.Declined:
      statusText = 'Unverified';
      break;
    case VeriffCodes.Expired:
      statusText = 'Unverified';
      break;
    default:
      statusText = 'Pending';
  }

  if (!user || !user.veriffId || user.verified) return null;

  return (
    <View style={TailwindResponsive('flex flex-row items-center my-10')}>
      <GraphikMediumTextMd>
        ID Verification Status: {statusText}
      </GraphikMediumTextMd>
      {statusText === 'Unverified' && (
        <GraphikTextMd style={TailwindResponsive(`ml-2`)}>
          (Please contact support@fairchain.art for further information)
        </GraphikTextMd>
      )}
    </View>
  );
};

export default IdentifyWithVeriff;
