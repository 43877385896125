import Schedule from 'components/graphics/Schedule';
import { GraphikSemiTextHuge } from 'components/shared/styled';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';
import Styles from 'style';
import { IntegratedFonts } from 'style/fonts';
import styled from 'styled-components/native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useCountdown } from '../hooks';

interface ITimeDisplayProps {
  colour?: string;
  text?: unknown;
  value: string;
}

const TimeDisplay: FunctionComponent<ITimeDisplayProps> = ({
  colour,
  text,
  value,
}) => {
  const color = colour || Styles.Colours.Dark1;
  const TDText = text || GraphikSemiTextHuge;

  return <TDText color={color}>{value}</TDText>;
};

interface ICountdownTimerProps {
  colour?: string;
  endDate: string;
  size?: IntegratedFonts;
  withIcon?: boolean;
}

export const CountdownTimer: FunctionComponent<ICountdownTimerProps> = ({
  colour,
  endDate,
  size,
  withIcon,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const fontSize = size ? size.size : Styles.Fonts.FontSize.Huge;
  const hookReturn = useCountdown(endDate);
  const lineHeight = size ? size.lineHeight : Styles.Fonts.LineHeights.Huge;

  const [color, setColor] = useState(colour || Styles.Colours.Dark1);

  const CTText = styled.Text`
    color: ${(props) => props.color || Styles.Colours.Dark1};
    font-family: '${Styles.Fonts.FontFamily.SansSemiBold}';
    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;
  `;

  useEffect(() => {
    if (hookReturn[1] && Number(hookReturn[1]) < 3) {
      setColor(Styles.Colours.Error);
    }
  }, [hookReturn]);

  if (new Date(endDate) <= new Date() && withIcon) {
    return (
      <View style={TailwindResponsive(`flex flex-row`)}>
        <View style={TailwindResponsive(`mr-2`)}>
          <Schedule color={Styles.Colours.Dark5} width={20} />
        </View>
        <CTText color={Styles.Colours.Dark5}>Expired</CTText>
      </View>
    );
  }

  if (new Date(endDate) <= new Date())
    return <CTText>Sales Agreement Has Expired</CTText>;

  return (
    <View style={TailwindResponsive(`flex flex-row`)}>
      {withIcon && (
        <View style={TailwindResponsive(`mr-2`)}>
          <Schedule color={color} width={20} />
        </View>
      )}
      {hookReturn[0] && Number(hookReturn[0]) > 0 && (
        <>
          <TimeDisplay
            colour={color || ''}
            text={CTText}
            value={`${hookReturn[0]!}d`}
          />
          <CTText color={color} style={TailwindResponsive(`mx-1`)}>
            :
          </CTText>
        </>
      )}
      {hookReturn[1] && Number(hookReturn[1]) > 0 && (
        <>
          <TimeDisplay
            colour={color}
            text={CTText}
            value={`${hookReturn[1]!}hr`}
          />
          <CTText color={color} style={TailwindResponsive(`mx-1`)}>
            :
          </CTText>
        </>
      )}
      {hookReturn[2] && Number(hookReturn[2]) > 0 && (
        <>
          <TimeDisplay
            colour={color}
            text={CTText}
            value={`${hookReturn[2]!}m`}
          />
          <CTText color={color} style={TailwindResponsive(`mx-1`)}>
            :
          </CTText>
        </>
      )}
      <TimeDisplay colour={color} text={CTText} value={`${hookReturn[3]!}s`} />
    </View>
  );
};

export default CountdownTimer;
