export const VerticalSpace = {
  iOSMarginTop: 225,
  SiteMarginTop: 80,
  Large: 60,
  Medium: 50,
  Small: 30,
  Tiny: 20,
  ExtraTiny: 10,
};

export const HorizontalSpace = {
  iOSMarginLeft: 60,
  SiteMarginLeft: 60,
};

export const Spacings = {
  HorizontalSpace,
  iOSSpacing: 25,
  SiteSpacing: 60,
  VerticalSpace,
};

export default Spacings;
