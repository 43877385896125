import { makeVar, useLazyQuery, useReactiveVar } from '@apollo/client';
import { GET_TRANSACTION_DETAILS } from 'api/requests.v2';
import { useEffect } from 'react';
import { FCUser } from 'store/user/apollo';
import { Status, Transaction } from 'types';

const clickwrapAcceptedVar = makeVar<boolean>(false);
const selectedTransactionIdVar = makeVar<string>('');
const transferTitleSucceededVar = makeVar<boolean>(false);

export const TDGQL = {
  clickwrapAcceptedVar,
  selectedTransactionIdVar,
  transferTitleSucceededVar,
};

export const useGetTransactionDetailsHook = (): Transaction | null => {
  const tId = useReactiveVar(selectedTransactionIdVar);

  const [getTransaction, { data }] = useLazyQuery(GET_TRANSACTION_DETAILS, {
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    if (tId) {
      getTransaction({
        variables: {
          transactionId: tId,
        },
      });
    }
  }, [getTransaction, tId]);

  return data ? (data.transactionDetails as Transaction) : null;
};

export const TransactionResolvers = {
  canAcceptOffer: (transaction: Transaction) =>
    transaction &&
    getIsBuyerView(transaction) &&
    transaction.status === Status.sellerSigned &&
    (!!transaction.envelopeId || !!transaction.sellerSignatureInfo),
  canCancel: (transaction: Transaction) =>
    transaction &&
    (transaction.status === Status.sellerSigned ||
      transaction.status === Status.created),
  canSeeContract: (transaction: Transaction) =>
    (!getIsBuyerView(transaction) && !(transaction.status === Status.void)) ||
    getBothPartiesHaveSigned(transaction),
  canSeeExpiration: (transaction: Transaction) =>
    transaction &&
    transaction.expirationTime !== '' &&
    transaction.status !== Status.signed &&
    transaction.status !== Status.pendingApproval &&
    transaction.status !== Status.completed &&
    transaction.status !== Status.void,
  hasPayment: (transaction: Transaction) =>
    transaction &&
    (!!transaction.stripeInvoiceId || !!transaction.stripePaymentIntentId),
  isBuyerView: (transaction: Transaction) => getIsBuyerView(transaction),
  isClickwrap: (transaction: Transaction) =>
    transaction && !transaction.envelopeId,
  isMultiWork: (transaction: Transaction) =>
    transaction && transaction.items.length > 1,
  isVoided: (transaction: Transaction) =>
    transaction && transaction.status === Status.void,
  stripePaymentFlowReady: (transaction: Transaction) =>
    transaction &&
    !!transaction.stripePaymentIntentId &&
    (transaction.status === Status.buyerSigned ||
      transaction.status === Status.signed),
};

const getBothPartiesHaveSigned = (transaction: Transaction): boolean =>
  transaction &&
  (transaction.status === Status.signed ||
    transaction.status === Status.pendingApproval ||
    transaction.status === Status.completed);

const getIsBuyerView = (transaction: Transaction): boolean => {
  const authUser = FCUser.FirebaseUserVar();
  const mainUser = FCUser.MainUserVar();

  return (
    (!!authUser && authUser.uid === transaction.buyer.id) ||
    (!!mainUser && mainUser.id === transaction.buyer.id)
  );
};

export default TDGQL;
