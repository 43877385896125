import { useReactiveVar } from '@apollo/client';
import { CollectionStore } from 'components/views/artworkDetails/apollo';
import { FunctionComponent, useCallback } from 'react';
import { FlatList, Platform } from 'react-native';
import { DraftWork } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { DraftWorkLineItem } from './DraftWorkLineItem';
import { ListTableHeader } from './ListTableHeader';

const ITEM_HEIGHT = 80;

export const DraftWrapper: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const drafts = useReactiveVar(CollectionStore.Vars.draftWorks);

  const itemLayout = useCallback(
    (_, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    [],
  );

  const keyExtractor = useCallback(
    (item: DraftWork) => `col-draft-key-${item.id}`,
    [],
  );

  const renderer = useCallback(
    ({ item, index }) => (
      <DraftWorkLineItem
        index={index}
        key={`col-draft-item-${item.id}`}
        work={item}
      />
    ),
    [],
  );

  return (
    <>
      {Platform.OS === 'web' && <ListTableHeader />}
      <FlatList
        contentContainerStyle={TailwindResponsive(`pb-24`)}
        data={drafts}
        getItemLayout={itemLayout}
        keyExtractor={keyExtractor}
        maxToRenderPerBatch={18}
        renderItem={renderer}
        snapToInterval={80}
        snapToAlignment="center"
        showsVerticalScrollIndicator={false}
        style={[{ height: 'auto' }]}
      />
    </>
  );
};
