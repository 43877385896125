import { useReactiveVar } from '@apollo/client';
import { DefaultTextInput } from 'components/shared/form';
import {
  GraphikMediumTextSm,
  GraphikSemiTextMd,
} from 'components/shared/styled';
import { RegistrationType, UserRole } from 'constants/enums';
import React, { FunctionComponent, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { RegisterStore } from 'store/registerWork/apollo';
import { getUserRole } from 'store/user';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { RegisterWorkFields } from '../../Helpers';
import AddContributors from '../../components/AddContributors';
import { AdditionalInformation } from '../../components/AdditionalInformation';
import { BrandRegistration } from '../../components/collectibles/BrandRegistration';
import { Details } from '../../components/collectibles/Details';
import { useGetFormDetails } from '../../forms/artist-registration.form';

export const CollectibleDetails: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const userRole = useSelector(getUserRole);

  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const arForm = useGetFormDetails(detailsForm);

  const keyboardScrollRef = useReactiveVar(
    RegisterStore.Vars.keyboardScrollRef,
  );
  const registrationType = useReactiveVar(RegisterStore.Vars.registrationType);

  useEffect(() => {
    if (arForm && arForm.formState) {
      const firstError = Object.keys(arForm.formState.errors).reduce<any>(
        (field, a) => (arForm.formState.errors[field] ? field : a),
        null,
      );
      if (firstError) arForm.setFocus(firstError);
    }
  }, [arForm, arForm?.formState]);

  if (!keyboardScrollRef) return null;

  return (
    <View>
      <View style={TailwindResponsive('w-2/5 mobWeb:w-full')}>
        {(userRole !== UserRole.Artist ||
          registrationType === RegistrationType.Uncertified) &&
          userRole !== UserRole.Estate && <BrandRegistration />}
        <AddContributors />
        <Details />

        <AdditionalInformation
          control={arForm?.control}
          keyboardScrollRef={keyboardScrollRef}
          name={RegisterWorkFields.additionalInfo}
          placeholder="Item Description, Origin, etc."
          title="Additional Information (Optional)"
        />

        {registrationType === RegistrationType.Uncertified &&
          userRole === UserRole.Collector && (
            <>
              <GraphikSemiTextMd style={TailwindResponsive(`mt-8 mb-16`)}>
                Private Inventory Information
              </GraphikSemiTextMd>

              <GraphikMediumTextSm style={TailwindResponsive(`mt-8`)}>
                Purchase History Details (Optional)
              </GraphikMediumTextSm>

              <View style={TailwindResponsive(`mt-4 mb-16`)}>
                <Controller
                  control={arForm?.control}
                  name={RegisterWorkFields.purchaseDate}
                  render={({ field: { onChange, value, ref } }) => (
                    <DefaultTextInput
                      handleChange={(input) => {
                        arForm?.clearErrors(RegisterWorkFields.purchaseDate);
                        onChange(input);
                      }}
                      inputRef={ref}
                      label="Purchase Date"
                      testId="RegisterPurchaseDate"
                      value={value}
                    />
                  )}
                />

                <Controller
                  control={arForm?.control}
                  name={RegisterWorkFields.seller}
                  render={({ field: { onChange, value, ref } }) => (
                    <DefaultTextInput
                      handleChange={(input) => {
                        arForm?.clearErrors(RegisterWorkFields.seller);
                        onChange(input);
                      }}
                      inputRef={ref}
                      label="Seller"
                      testId="RegisterSeller"
                      value={value}
                    />
                  )}
                />

                <Controller
                  control={arForm?.control}
                  name={RegisterWorkFields.purchaseAmount}
                  render={({ field: { onChange, value, ref } }) => (
                    <DefaultTextInput
                      handleChange={(input) => {
                        arForm?.clearErrors(RegisterWorkFields.purchaseAmount);
                        onChange(input);
                      }}
                      inputRef={ref}
                      label="Purchase Amount"
                      testId="RegisterPurchaseAmount"
                      value={value}
                    />
                  )}
                />

                <Controller
                  control={arForm?.control}
                  name={RegisterWorkFields.location}
                  render={({ field: { onChange, value, ref } }) => (
                    <DefaultTextInput
                      handleChange={(input) => {
                        arForm?.clearErrors(RegisterWorkFields.location);
                        onChange(input);
                      }}
                      inputRef={ref}
                      label="Location"
                      testId="RegisterLocation"
                      value={value}
                    />
                  )}
                />
              </View>
            </>
          )}

        <AdditionalInformation
          control={arForm?.control}
          keyboardScrollRef={keyboardScrollRef}
          name={RegisterWorkFields.privateNotes}
          placeholder="Document any other details related to the work. These notes will only ever be visible to you. If this work is ever sold, these details will not be shared."
          title="Private Notes (Optional)"
        />
      </View>
    </View>
  );
};
