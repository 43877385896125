import { useEffect } from 'react';
import { Platform } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useTailwind } from 'tailwind-rn';
import { ITailWindResponsiveSizes } from 'types';

let responsiveSizes: ITailWindResponsiveSizes = {
  mob: false,
  mobWeb: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  xxl: false,
  web: false,
};

export const getResponsiveSizes = () => responsiveSizes;

export const parseResponsiveString = (tailwindString: string) => {
  const stringArray = tailwindString
    .split(' ')
    .reduce((filteredParams: string[], tailwindParam) => {
      const colonSplit = tailwindParam.split(':');
      if (colonSplit.length === 1) filteredParams.push(colonSplit[0]!);
      else if (
        (colonSplit[0] === 'mob' && responsiveSizes.mob) ||
        (colonSplit[0] === 'web' && responsiveSizes.web)
      )
        filteredParams.push(colonSplit[1]!);
      else if (colonSplit[0] !== 'mob' && colonSplit[0] !== 'web')
        filteredParams.push(tailwindParam);

      return filteredParams;
    }, []);
  return stringArray.join(' ');
};

export const setResponsiveSizes = (sizes: ITailWindResponsiveSizes) => {
  responsiveSizes = sizes;
  responsiveSizes.mob = Platform.OS !== 'web';
  responsiveSizes.web = Platform.OS === 'web';
};

export const useResponsiveSizes = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isSmall = useMediaQuery({ minWidth: 640 });
  const isMedium = useMediaQuery({ minWidth: 768 });
  const isLg = useMediaQuery({ minWidth: 1024 });
  const isXl = useMediaQuery({ minWidth: 1280 });
  const is2Xl = useMediaQuery({ minWidth: 1536 });

  useEffect(() => {
    setResponsiveSizes({
      mob: Platform.OS === 'ios',
      mobWeb: isMobile,
      sm: isSmall,
      md: isMedium,
      lg: isLg,
      xl: isXl,
      xxl: is2Xl,
    });
  }, [isMobile, isSmall, isMedium, isLg, isXl, is2Xl]);
};

const useTailwindResponsive = () => {
  const tailwind = useTailwind();

  const TailwindResponsive = (tailwindString: string) =>
    tailwind(parseResponsiveString(tailwindString));

  return { TailwindResponsive };
};

export const getIsMobileWebOrNative = () => {
  const size = getResponsiveSizes();
  return size.mobWeb || size.mob;
};

export default useTailwindResponsive;
