import { useReactiveVar } from '@apollo/client';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { Checkbox } from 'components/shared/checkbox';
import { DefaultTextInput, RoyaltyInput } from 'components/shared/form';
import { GraphikTextMd, GraphikTextTiny } from 'components/shared/styled';
import { formatRoyaltyInput } from 'components/views/transferWork';
import { UserContext } from 'contexts';
import { FunctionComponent, useContext } from 'react';
import { Controller } from 'react-hook-form';
import { Platform, TextInput, View } from 'react-native';
import { DetailsData } from 'store/artworkDetails/apollo';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useRequestCertificationMutation } from '../apollo';

export const CRScreen2: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const { TailwindResponsive } = useTailwindResponsive();

  const artwork = useReactiveVar(DetailsData.work);
  const form = useReactiveVar(DetailsData.CollectorRetro.form);

  const { requestCertification, data, loading } =
    useRequestCertificationMutation();

  DetailsData.CollectorRetro.requestLoading(loading);
  if (data && data.response && data.response.success) {
    DetailsData.CollectorRetro.completed(true);
  }

  if (!artwork || !form) return null;

  return (
    <View>
      <View style={TailwindResponsive('-mt-10')}>
        <Controller
          control={form.control}
          name="purchaseAmount"
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              errorMessage={
                form?.formState?.errors?.purchaseAmount?.message as string
              }
              handleChange={(input) => {
                form?.clearErrors('purchaseAmount');
                onChange(input.trimStart());
              }}
              label="* Purchase Amount"
              testId="RCPurchaseAmount"
              value={value}
            />
          )}
        />
      </View>

      <View style={TailwindResponsive('-mt-10')}>
        <Controller
          control={form.control}
          name="purchaseDate"
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              errorMessage={
                form?.formState?.errors?.purchaseDate?.message as string
              }
              handleChange={(input) => {
                form?.clearErrors('purchaseDate');
                onChange(input.trimStart());
              }}
              label="* Purchase Date"
              testId="RCPurchaseDate"
              value={value}
            />
          )}
        />
      </View>

      <View style={TailwindResponsive('-mt-10')}>
        <Controller
          control={form.control}
          name="purchaseSeller"
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              errorMessage={
                form?.formState?.errors?.purchaseSeller?.message as string
              }
              handleChange={(input) => {
                form?.clearErrors('purchaseSeller');
                onChange(input.trimStart());
              }}
              label="* Purchase Seller"
              testId="RCPurchaseSeller"
              value={value}
            />
          )}
        />
      </View>

      <View style={TailwindResponsive('-mt-10')}>
        <Controller
          control={form.control}
          name="sellerContact"
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              errorMessage={
                form?.formState?.errors?.sellerContact?.message as string
              }
              handleChange={(input) => {
                form?.clearErrors('sellerContact');
                onChange(input);
              }}
              label="Seller Contact"
              testId="RCSellerContact"
              value={value}
            />
          )}
        />
      </View>

      <View style={TailwindResponsive('-mt-10')}>
        <Controller
          control={form.control}
          name="artistContact"
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              errorMessage={
                form?.formState?.errors?.artistContact?.message as string
              }
              handleChange={(input) => {
                form?.clearErrors('artistContact');
                onChange(input);
              }}
              label="Artist Contact"
              testId="RCArtistContact"
              value={value}
            />
          )}
        />
      </View>

      <View style={TailwindResponsive(`mt-6`)}>
        <GraphikTextMd style={TailwindResponsive(`mb-2`)}>
          Please provide any additional information about the work:
        </GraphikTextMd>
        <Controller
          control={form.control}
          name="additionalInfo"
          render={({ field: { onChange, value } }) => (
            <TextInput
              style={[
                TailwindResponsive(`p-4 mb-2`),
                {
                  borderWidth: 0.5,
                  height: Platform.OS === 'web' ? 100 : 200,
                  fontSize: Styles.Fonts.FontSize.Medium,
                  fontFamily: Styles.Fonts.FontFamily.SansRegular,
                  borderColor: '#00000099',
                  verticalAlign: 'top',
                },
              ]}
              placeholderTextColor={Styles.Colours.Gray2}
              multiline
              blurOnSubmit
              value={value}
              onChangeText={onChange}
            />
          )}
        />
        <GraphikTextTiny style={TailwindResponsive(`text-Error`)}>
          {form.formState?.errors?.additionalInfo?.message}
        </GraphikTextTiny>
      </View>

      <View style={TailwindResponsive(`mt-6`)}>
        <GraphikTextMd style={TailwindResponsive(`mb-1`)}>
          Artist Resale Commission
        </GraphikTextMd>
        <Controller
          control={form.control}
          name="commission"
          render={({ field: { onChange, value } }) => (
            <RoyaltyInput
              errorMessage={
                form.formState?.errors?.commission?.message as string
              }
              handleChange={(input) => {
                form.clearErrors('commission');
                const parsedInput = formatRoyaltyInput(input);
                onChange(parsedInput);
              }}
              testID="CollectorRetroCommission"
              value={value}
            />
          )}
        />
      </View>

      <View style={TailwindResponsive(`mb-2 mt-6`)}>
        <Controller
          control={form.control}
          name="titleConfirmation"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              isChecked={value}
              label="I hold clear title to this work"
              onPress={() => {
                onChange(!value);
              }}
            />
          )}
        />
        <GraphikTextTiny style={TailwindResponsive(`text-Error`)}>
          {form.formState?.errors?.titleConfirmation?.message}
        </GraphikTextTiny>
      </View>

      <View>
        <Controller
          control={form.control}
          name="shareIdentity"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              isChecked={value}
              label="I authorize Fairchain to share my identity with the artist"
              onPress={() => {
                onChange(!value);
              }}
            />
          )}
        />
        <GraphikTextTiny style={TailwindResponsive(`text-Error`)}>
          {form.formState?.errors?.shareIdentity?.message}
        </GraphikTextTiny>
      </View>

      <FairchainButton
        buttonStyle={TailwindResponsive(`bg-Primary mt-10 rounded-full`)}
        label="Request Certification"
        loading={loading}
        onPress={async () => {
          const formResult = await form.trigger();
          if (formResult) {
            requestCertification({
              variables: {
                requestCertInput: {
                  ...form.getValues(),
                  userId: authUser?.uid,
                  workId: artwork.id,
                },
              },
            });
          }
        }}
        textStyle={TailwindResponsive(`text-Light1`)}
      />
    </View>
  );
};

export default CRScreen2;
