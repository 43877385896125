/* eslint-disable no-underscore-dangle */
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { GET_SAVED_REGISTRATION, SAVE_DRAFT } from 'api/requests.v2';
import HeaderVars from 'components/shared/header/apollo.header';
import { RegistrationType } from 'constants/enums';
import { UserContext } from 'contexts';
import { isFunction } from 'lodash';
import { FCStackNavProp, RegisterWorkRouteProp } from 'navigation';
import { useContext, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { RegisterStore } from 'store/registerWork';
import { IImagePickerResponse } from 'types';
import { RegistrationForm, RoyaltyForm } from 'types/RegisterWork';
import { SupportingDoc } from 'types/SupportingDocs';
import { useGetFormDetails } from './forms/artist-registration.form';

interface IRegisterStore {
  autoArtistSelected: boolean;
  detailsForm: RegistrationForm | null;
  Images: {
    image1: IImagePickerResponse | null;
    image2: IImagePickerResponse | null;
    image3: IImagePickerResponse | null;
    image4: IImagePickerResponse | null;
    primary: string;
  };
  registrationType: RegistrationType;
  royaltyForm: UseFormReturn<RoyaltyForm, any> | null;
  supportingDocsVar: SupportingDoc[];
}

export const useAutoSave = (
  callback: any = null,
): { handleSave: () => void; loading: boolean } => {
  const authUser = useContext(UserContext);
  const getJson = usePackageJson();
  const navigation = useNavigation<FCStackNavProp>();
  const route = useRoute<RegisterWorkRouteProp>();

  const [saveDraft, { loading }] = useMutation(SAVE_DRAFT, {
    onCompleted: ({ response }) => {
      if (response.success && route.params.draftId === 'new') {
        navigation.setParams({ draftId: response.successMessage });
      }

      if (callback && isFunction(callback)) callback();
    },
  });

  const handleSave = () => {
    RegisterStore.Vars.savingDraft(true);

    // package up json
    const json = getJson();
    const jsonString = JSON.stringify(json);
    saveDraft({
      variables: {
        draftId: route.params.draftId,
        json: jsonString,
        userId: authUser?.uid,
      },
    });

    RegisterStore.Vars.savingDraft(false);
  };

  return {
    handleSave,
    loading,
  };
};

export const useGetSavedRegistration = (): (() => void) => {
  const loadJson = useLoadFromJson();
  const route = useRoute<RegisterWorkRouteProp>();

  const [getSavedData, { loading }] = useLazyQuery(GET_SAVED_REGISTRATION, {
    fetchPolicy: 'network-only',
    onCompleted: ({ response }) => {
      loadJson(response);
    },
  });

  useEffect(() => {
    RegisterStore.Vars.saveLoading(loading);
  }, [loading]);

  return () => {
    if (
      route &&
      route.params &&
      route.params.draftId &&
      route.params.draftId !== 'new' &&
      route.params.draftId !== 'undefined'
    ) {
      getSavedData({
        variables: {
          draftId: route.params.draftId,
        },
      });
    }
  };
};

export const useLoadFromJson = (): ((jsonStr: string) => void) => {
  const route = useRoute<RegisterWorkRouteProp>();
  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const formDetails = useGetFormDetails(detailsForm);
  const royaltyForm = useReactiveVar(RegisterStore.Vars.royaltyForm);

  return (jsonStr: string) => {
    const json: IRegisterStore = JSON.parse(jsonStr);
    if (json) {
      RegisterStore.Vars.autoArtistSelected(json.autoArtistSelected);

      // @ts-ignore Old schema had .control as a top level property
      // need to check if this is still the case
      if (json.detailsForm?.control?._formValues) {
        // @ts-ignore
        formDetails?.reset(json.detailsForm?.control?._formValues);
      } else {
        // If the new form schema is used, the formData property will be present
        formDetails?.reset(json.detailsForm?.formData.control?._formValues);
      }
      royaltyForm?.reset(json.royaltyForm?.control?._formValues);

      RegisterStore.Vars.Images.image1(json.Images.image1);
      RegisterStore.Vars.Images.image2(json.Images.image2);
      RegisterStore.Vars.Images.image3(json.Images.image3);
      RegisterStore.Vars.Images.image4(json.Images.image4);
      RegisterStore.Vars.Images.primary(json.Images.primary);
      RegisterStore.Vars.registrationType(json.registrationType);
      RegisterStore.Vars.supportingDocsVar(json.supportingDocsVar);
    }

    if (
      route &&
      route.params &&
      Object.prototype.hasOwnProperty.call(route.params, 'redirect_status')
    ) {
      HeaderVars.draftWorkId(null);
      RegisterStore.Vars.currentStep(3);
    }
  };
};

export const usePackageJson = (): (() => IRegisterStore) => {
  const image1 = useReactiveVar(RegisterStore.Vars.Images.image1);
  const image2 = useReactiveVar(RegisterStore.Vars.Images.image2);
  const image3 = useReactiveVar(RegisterStore.Vars.Images.image3);
  const image4 = useReactiveVar(RegisterStore.Vars.Images.image4);
  const supportingDocs = useReactiveVar(RegisterStore.Vars.supportingDocsVar);

  // Grab all apollo store values
  const json: IRegisterStore = {
    autoArtistSelected: useReactiveVar(RegisterStore.Vars.autoArtistSelected),
    detailsForm: useReactiveVar(RegisterStore.Vars.detailsForm),
    Images: {
      image1: image1 ? { ...image1, localURI: '' } : null,
      image2: image2 ? { ...image2, localURI: '' } : null,
      image3: image3 ? { ...image3, localURI: '' } : null,
      image4: image4 ? { ...image4, localURI: '' } : null,
      primary: useReactiveVar(RegisterStore.Vars.Images.primary),
    },
    registrationType: useReactiveVar(RegisterStore.Vars.registrationType),
    royaltyForm: useReactiveVar(RegisterStore.Vars.royaltyForm),
    supportingDocsVar: supportingDocs.map((doc) => ({ ...doc, uri: '' })),
  };

  return () => json;
};
