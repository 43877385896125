/* Copyright (C) 2021 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */

import React, { FunctionComponent } from 'react';
import Svg, { G, Rect } from 'react-native-svg';
import { ISVGProps } from './Types';

export const GridIcon: FunctionComponent<ISVGProps> = ({ color }) => (
  <Svg width="15" height="15" viewBox="0 0 15 15" fill={color}>
    <G id="Group_81" data-name="Group 81" transform="translate(-1808 -207)">
      <Rect
        id="Rectangle_1027"
        data-name="Rectangle 1027"
        width="7.143"
        height="7.143"
        transform="translate(1808 207)"
        fill={color}
      />
      <Rect
        id="Rectangle_1028"
        data-name="Rectangle 1028"
        width="7.143"
        height="7.143"
        transform="translate(1815.857 207)"
        fill={color}
      />
      <Rect
        id="Rectangle_1121"
        data-name="Rectangle 1121"
        width="7.143"
        height="7.143"
        transform="translate(1808 214.857)"
        fill={color}
      />
      <Rect
        id="Rectangle_1122"
        data-name="Rectangle 1122"
        width="7.143"
        height="7.143"
        transform="translate(1815.857 214.857)"
        fill={color}
      />
    </G>
  </Svg>
);

export default GridIcon;
