import { ListFilled, ListOutline } from 'components/graphics';
import React, { FunctionComponent } from 'react';
import { Pressable } from 'react-native';

interface IListIconProps {
  isFilled: boolean;
  onPress: (arg: any) => void;
}

export const ListIcon: FunctionComponent<IListIconProps> = ({
  isFilled,
  onPress,
}) => (
  <Pressable onPress={onPress}>
    {isFilled ? <ListFilled /> : <ListOutline />}
  </Pressable>
);
