import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CollectionPage,
  CollectionSort,
  CollectionTab,
  CollectionView,
} from 'constants/enums';
import { Work } from 'types';

export interface ICollectionState {
  collectionTab: CollectionTab;
  currentPage: CollectionPage;
  currentState: CollectionTab;
  currentView: CollectionView;
  errorMessage: string;
  loading: boolean;
  myCollection: Work[];
  myConsignedWorks: Work[];
  pastWorks: Work[];
  pastWorksError: string;
  pastWorksLoaded: boolean;
  pastWorksLoading: boolean;
  showMobileSelector: boolean;
  sortModalVisible: boolean;
  sortType: CollectionSort;
}

const initialState: ICollectionState = {
  collectionTab: CollectionTab.Current,
  currentPage: CollectionPage.Collection,
  currentState: CollectionTab.Current,
  currentView: CollectionView.Line,
  errorMessage: '',
  loading: false,
  myCollection: [],
  myConsignedWorks: [],
  pastWorks: [],
  pastWorksError: '',
  pastWorksLoaded: false,
  pastWorksLoading: false,
  showMobileSelector: false,
  sortModalVisible: false,
  sortType: CollectionSort.Date,
};

export const collectionSlice = createSlice({
  name: 'collection',
  initialState,
  reducers: {
    setCollectionDisplay: (state, action: PayloadAction<CollectionTab>) => {
      state.currentState = action.payload;
    },
    setCollectionLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCollectionTab: (state, action: PayloadAction<CollectionTab>) => {
      state.collectionTab = action.payload;
      state.sortType =
        state.collectionTab !== CollectionTab.Current
          ? CollectionSort.Date
          : state.sortType;
    },
    setCurrentView: (state, action: PayloadAction<CollectionView>) => {
      state.currentView = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setMobileSelector: (state, action: PayloadAction<boolean>) => {
      state.showMobileSelector = action.payload;
    },
    setMyCollection: (state, action: PayloadAction<Work[]>) => {
      state.myCollection = action.payload;
    },
    setMyConsignedWorks: (state, action: PayloadAction<Work[]>) => {
      state.myConsignedWorks = action.payload;
    },
    setPage: (state, action: PayloadAction<CollectionPage>) => {
      state.currentPage = action.payload;
    },
    setPastWorks: (state, action: PayloadAction<Work[]>) => {
      state.pastWorksLoaded = true;
      state.pastWorks = action.payload;
    },
    setSortModalVisible: (state, action: PayloadAction<boolean>) => {
      state.sortModalVisible = action.payload;
    },
    setSort: (state, action: PayloadAction<CollectionSort>) => {
      state.sortType = action.payload;
    },
  },
});

const { actions, reducer } = collectionSlice;
export const {
  setCollectionDisplay,
  setCollectionLoading,
  setCollectionTab,
  setCurrentView,
  setErrorMessage,
  setMobileSelector,
  setMyCollection,
  setMyConsignedWorks,
  setPage,
  setPastWorks,
  setSortModalVisible,
  setSort,
} = actions;
export { reducer as collectionReducer };

export type CollectionState = ReturnType<typeof reducer>;
export type CollectionAction =
  | typeof setCollectionDisplay
  | typeof setCollectionLoading
  | typeof setCollectionTab
  | typeof setCurrentView
  | typeof setErrorMessage
  | typeof setMobileSelector
  | typeof setMyCollection
  | typeof setMyConsignedWorks
  | typeof setPage
  | typeof setPastWorks
  | typeof setSortModalVisible
  | typeof setSort;
