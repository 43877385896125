import { WordmarkHeader } from 'components/shared/header';
import { GraphikTextMd, LargeTitle } from 'components/shared/styled';
import { NotFoundRouteProp } from 'navigation';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Linking, Pressable, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

const DEFAULT_ERROR_TEXT =
  'The page you are looking for does not exist or an error has occured';
const PUBLIC_CERTIFICATE_NOT_VIEWABLE =
  'This certificate is no longer publicly viewable. Please contact the current title holder directly to verify ownership. For more information, email us at ';

interface INotFoundProps {
  route: NotFoundRouteProp;
}

export const NotFound: FunctionComponent<INotFoundProps> = ({ route }) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const [errorText, setErrorText] = useState<string>(DEFAULT_ERROR_TEXT);
  const [shouldEmail, setShouldEmail] = useState<boolean>(false);
  const [is404, setIs404] = useState<boolean>(false);

  useEffect(() => {
    if (route.params?.error && route.params.error === 'not-public') {
      setErrorText(PUBLIC_CERTIFICATE_NOT_VIEWABLE);
      setShouldEmail(true);
    } else {
      setIs404(true);
    }
  }, [route.params?.error]);

  return (
    <View style={TailwindResponsive(`flex flex-col flex-1 bg-white`)}>
      <View style={TailwindResponsive(`flex flex-row w-full items-center`)}>
        <View style={TailwindResponsive(`items-start justify-center`)}>
          <WordmarkHeader />
        </View>
      </View>
      <View style={TailwindResponsive(`flex flex-row items-start ml-14`)}>
        <View
          style={{
            maxWidth: '50%',
          }}
        >
          {!!is404 && (
            <LargeTitle style={TailwindResponsive(`mb-4`)}>404</LargeTitle>
          )}
          <GraphikTextMd>
            {errorText}
            {!!shouldEmail && (
              <Pressable
                onPress={() => {
                  Linking.openURL('mailto: support@fairchain.art');
                }}
              >
                <GraphikTextMd style={TailwindResponsive(`underline`)}>
                  support@fairchain.art
                </GraphikTextMd>
              </Pressable>
            )}
            .
          </GraphikTextMd>
        </View>
      </View>
    </View>
  );
};

export default NotFound;
