import React, { FunctionComponent } from 'react';
import { Image, View } from 'react-native';
import DefaultTextInput from './DefaultTextInput';

interface ISignatureInputProps {
  label: string;
  signatureImage: string;
}

export const SignatureInput: FunctionComponent<ISignatureInputProps> = ({
  label,
  signatureImage,
}) => (
  <View>
    <View style={{ width: 150, position: 'absolute', top: 15 }}>
      <Image
        source={{ uri: signatureImage }}
        style={{ height: '30px', resizeMode: 'contain' }}
      />
    </View>
    <DefaultTextInput value={' '} label={label} />
  </View>
);

export default SignatureInput;
