import { Checkbox } from 'components/shared/checkbox';
import { FunctionComponent } from 'react';
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { getFeatureFlags } from 'store/general';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ITransactionDetailsForm } from '../Helpers';
import { Expiration } from './Expiration';

interface ITransactionSettingsProps {
  control: Control<ITransactionDetailsForm>;
  getValues: UseFormGetValues<ITransactionDetailsForm>;
  setValues: UseFormSetValue<ITransactionDetailsForm>;
}

export const TransactionSettings: FunctionComponent<
  ITransactionSettingsProps
> = ({ control, getValues, setValues }) => {
  const featureFlags = useSelector(getFeatureFlags);
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <>
      <View style={TailwindResponsive(`mb-12`)}>
        <Expiration
          control={control}
          getValues={getValues}
          setValues={setValues}
        />
      </View>

      {!!featureFlags.allowBuyerRejection && (
        <Controller
          control={control}
          name="enableBuyerRejection"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              isChecked={value}
              onPress={() => {
                onChange(!value);
              }}
              label="Allow buyer to reject transaction"
            />
          )}
        />
      )}
    </>
  );
};
