import { MainHeader } from 'components/shared/header/MainHeader';
import { alignFromEnum, ButtonAlignment } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import Styles from 'style';
import { IWorkDetailsHeaderProps } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ExternalLink } from '../link/ExternalLink';
import { GraphikSemiTextLg, GraphikTextMd } from '../styled';

export const WorkDetailsHeader: FunctionComponent<IWorkDetailsHeaderProps> = ({
  backScreenTitle,
  backScreen,
  title,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View>
      <MainHeader />
      <View
        style={{
          ...TailwindResponsive(`flex flex-row justify-between pb-1.5`),
        }}
      >
        <GraphikSemiTextLg lineHeight="0px" color={Styles.Colours.Dark1}>
          {title}
        </GraphikSemiTextLg>
      </View>
      {!!backScreen && (
        <View style={TailwindResponsive(`text-Dark4 text-lg`)}>
          <ExternalLink
            link={`/${backScreen}`}
            style={TailwindResponsive('self-start')}
          >
            <View
              style={{
                alignItems: alignFromEnum(ButtonAlignment.Left),
                backgroundColor: 'transparent',
                justifyContent: 'flex-start',
                paddingHorizontal: 0,
                paddingVertical: 18,
              }}
            >
              <GraphikTextMd
                style={{ color: Styles.Colours.Primary }}
              >{`Back to ${backScreenTitle ?? backScreen}`}</GraphikTextMd>
            </View>
          </ExternalLink>
        </View>
      )}
    </View>
  );
};

export default WorkDetailsHeader;
