import { GraphikSemiTextMd, GraphikTextMd } from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';
import { getDisplayName } from 'types/Users';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import ViewInvoice from './ViewInvoice';

type SalesOfferHeaderProps = {
  assetIsCollectible?: boolean;
};

export const SalesOfferHeader: FunctionComponent<SalesOfferHeaderProps> = ({
  assetIsCollectible,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const transaction = useGetTransactionDetailsHook();

  const buyerName = getDisplayName(transaction?.buyer);
  const sellerName = getDisplayName(transaction?.seller);

  if (!transaction?.createDate) return null;

  return (
    <View
      style={TailwindResponsive(
        `flex flex-row justify-between mb-10 web:w-2/4 web:pr-14 mobWeb:mb-4 mobWeb:w-full mobWeb:flex-col mob:w-full mob:flex-col `,
      )}
    >
      {assetIsCollectible ? (
        <View style={TailwindResponsive(`flex flex-row`)}>
          <GraphikTextMd style={TailwindResponsive(`mobWeb:w-16`)}>
            {!transaction.isBuyerView ? `Sent on` : `Received on`}
          </GraphikTextMd>
          <GraphikTextMd style={TailwindResponsive(`mx-3`)}>:</GraphikTextMd>
          <View>
            <GraphikSemiTextMd>
              {new Date(transaction?.createDate).toDateString()}
            </GraphikSemiTextMd>
          </View>
        </View>
      ) : (
        <View style={TailwindResponsive(`flex flex-row`)}>
          <GraphikTextMd
            style={TailwindResponsive(`mb-4 mob:w-16 mobWeb:w-16`)}
          >
            {transaction.isRetro ? 'Certificate' : 'Sales Agreement'}
          </GraphikTextMd>
          <GraphikTextMd style={TailwindResponsive(`mx-3`)}>:</GraphikTextMd>
          <View>
            <GraphikSemiTextMd>
              {!transaction.isBuyerView
                ? `Sent to ${buyerName}`
                : `Received from ${sellerName}`}
            </GraphikSemiTextMd>
            <GraphikTextMd style={TailwindResponsive(`mt-1`)}>
              {new Date(transaction?.createDate).toDateString()}
            </GraphikTextMd>
          </View>
        </View>
      )}
      <ViewInvoice />
    </View>
  );
};

export default SalesOfferHeader;
