import step1 from 'assets/images/onboarding/step1.png';
import {
  GraphikMediumTextMd,
  GraphikTextMd,
  MediumTitle,
} from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { Image, Platform, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ColOnboardingScreen2: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View style={TailwindResponsive(`items-center`)}>
      <MediumTitle style={TailwindResponsive(`mb-8`)}>
        📌 Here’s how it works:
      </MediumTitle>

      <View style={TailwindResponsive(`mb-4`)}>
        {Platform.OS === 'web' ? (
          <img src={step1} style={TailwindResponsive(`h-52`)} />
        ) : (
          <Image
            source={step1}
            style={{ height: 200, width: 200 }}
            resizeMode="contain"
          />
        )}
      </View>

      <GraphikMediumTextMd style={TailwindResponsive(`mb-4`)}>
        1 - Record
      </GraphikMediumTextMd>

      <GraphikTextMd>
        Upload information about artworks in your collection, including photos,
        bills of sale, provenance, and appraisal records.
      </GraphikTextMd>
    </View>
  );
};

export default ColOnboardingScreen2;
