import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { FairchainButton } from '../button/FairchainButton';
import { GraphikSemiTextLg, GraphikSemiTextMd, GraphikTextSm } from '../styled';
import { ISubOptionProps, PriceText } from './SubscriptionSelector';

export const GalleryOption: FunctionComponent<ISubOptionProps> = ({
  selectOption,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View
      style={[
        {
          boxShadow: '0 0 10px rgb(0 0 0 / 0.2)',
        },
        TailwindResponsive(
          `border border-Dark1 flex items-center p-10 my-10 relative rounded-sm max-w-lg xl:flex-1 xl:mx-10 xl:my-0`,
        ),
      ]}
    >
      <GraphikSemiTextLg>Gallery Standard</GraphikSemiTextLg>

      <View style={TailwindResponsive(`flex flex-row items-start mt-6`)}>
        <GraphikTextSm>$</GraphikTextSm>
        <PriceText>150</PriceText>
      </View>

      <GraphikTextSm>per month</GraphikTextSm>

      <View
        style={TailwindResponsive(
          `border-t border-Dark3 items-center mt-8 pt-8`,
        )}
      >
        <GraphikSemiTextMd>Key Features</GraphikSemiTextMd>

        <View style={TailwindResponsive(`mt-2`)}>
          <GraphikTextSm>
            Fairchain&apos;s standard functionality with one fixed monthly cost.
          </GraphikTextSm>
        </View>
      </View>

      <View
        style={TailwindResponsive(
          `items-center justify-end w-full xl:flex xl:flex-1`,
        )}
      >
        <FairchainButton
          buttonStyle={TailwindResponsive(`bg-Primary mt-8 rounded-full w-4/5`)}
          label="Subscribe"
          onPress={selectOption}
          textStyle={TailwindResponsive(`text-Light1`)}
        />
      </View>
    </View>
  );
};

export default GalleryOption;
