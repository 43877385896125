import { PDFUploader } from 'components/shared/fileUploader';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import { resetRider, setRider } from 'store/transferWork';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ContractRider: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const dispatch = useDispatch();
  const reset = () => dispatch(resetRider());

  return (
    <View style={TailwindResponsive('items-end pb-4')}>
      <PDFUploader
        handlePDFSelected={(base64RiderAndFileName) => {
          dispatch(setRider(base64RiderAndFileName));
        }}
        reset={reset}
        title="Upload"
        width={60}
      />
    </View>
  );
};
