import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { getArtistBlocks } from 'store/collection';
import { ArtistBlock } from './ArtistBlocks';
import { CollectionHeader } from './CollectionHeader';

export const ArtistBlockGroup: FunctionComponent = () => {
  const artistBlocks = useSelector(getArtistBlocks);

  return (
    <View>
      <CollectionHeader />
      {artistBlocks.map((block) => (
        <ArtistBlock key={`art-block-group-${block[0]?.id}`} block={block} />
      ))}
    </View>
  );
};
