import { makeVar } from '@apollo/client';
import { DraftWork } from 'types';

export class CollectionStore {
  public static Vars = {
    draftWorks: makeVar<DraftWork[]>([]),
    draftWorksRefetch: makeVar<any>(null),
    showDelDraftConfirmModal: makeVar<boolean>(false),
  };

  static Reset() {
    CollectionStore.Vars.draftWorks([]);
    CollectionStore.Vars.draftWorksRefetch(null);
    CollectionStore.Vars.showDelDraftConfirmModal(false);
  }
}
