import { useNavigation } from '@react-navigation/native';
import { RoundedButton } from 'components/shared/button';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import DefaultTextInput from 'components/shared/form/DefaultTextInput';
import { WorkflowPage } from 'components/shared/page';
import { ButtonAlignment } from 'constants/enums';
import { UserContext } from 'contexts';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import { FCStackNavProp } from 'navigation/types';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useState,
} from 'react';
import { View } from 'react-native';
import Styles from 'style';
import {
  FCAnalyticEvents,
  getFBAuth,
  logAnalyticsEvent,
  logError,
} from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ResetPasswordProfile: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const authUser = useContext(UserContext);
  const firebaseAuth = getFBAuth();

  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [error, updateError] = useState('');
  const navigation = useNavigation<FCStackNavProp>();

  const validate = useCallback(() => {
    if (newPassword !== confirmedPassword) {
      updateError('Passwords must match');
      return false;
    }
    return true;
  }, [newPassword, confirmedPassword]);

  const resetPasswordFields = useCallback(() => {
    setNewPassword('');
    setCurrentPassword('');
    setConfirmedPassword('');
    navigation.navigate('Profile');
  }, [navigation]);

  const handlePasswordUpdate = useCallback(async () => {
    if (validate()) {
      try {
        if (authUser) {
          const credentials = EmailAuthProvider.credential(
            authUser.email || '',
            currentPassword,
          );
          await reauthenticateWithCredential(authUser, credentials);
          await updatePassword(firebaseAuth.currentUser!, newPassword);
          logAnalyticsEvent(FCAnalyticEvents.PasswordResetRequest, {
            uid: firebaseAuth.currentUser?.uid,
          });
          resetPasswordFields();
          navigation.push('Profile');
        }
      } catch (caughtError) {
        logError(caughtError);
        updateError((caughtError as Error).message);
      }
    }
  }, [
    validate,
    authUser,
    currentPassword,
    firebaseAuth.currentUser,
    newPassword,
    resetPasswordFields,
    navigation,
  ]);

  return (
    <WorkflowPage>
      <View style={TailwindResponsive('flex mt-36 mx-14 mob:mx-6')}>
        <View style={TailwindResponsive('mob:w-full')}>
          <DefaultTextInput
            value={currentPassword}
            label="Current Password"
            secureTextEntry
            handleChange={setCurrentPassword}
            color={Styles.Colours.Dark1}
          />
          <DefaultTextInput
            value={newPassword}
            label="New Password"
            secureTextEntry
            handleChange={setNewPassword}
            color={Styles.Colours.Dark1}
          />
          <DefaultTextInput
            value={confirmedPassword}
            label="Confirm New Password"
            secureTextEntry
            handleChange={setConfirmedPassword}
            color={Styles.Colours.Dark1}
            errorMessage={error}
          />
        </View>
        <View style={TailwindResponsive('flex-row')}>
          <View style={TailwindResponsive('items-start mt-20 w-72 mob:w-full')}>
            <RoundedButton
              alignment={ButtonAlignment.Left}
              label="Update Password"
              onPress={handlePasswordUpdate}
              backgroundColor={Styles.Colours.Dark1}
              color={Styles.Colours.Light1}
              paddingHorizontal={30}
            />
          </View>
          <View style={TailwindResponsive('mt-20 ml-14')}>
            <FairchainButton
              label="Cancel"
              onPress={() => {
                resetPasswordFields();
                navigation.navigate('Profile');
              }}
              testId="cancel-change-password"
            />
          </View>
        </View>
      </View>
    </WorkflowPage>
  );
};

export default ResetPasswordProfile;
