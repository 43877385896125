import { useReactiveVar } from '@apollo/client';
import DefaultTextInput from 'components/shared/form/DefaultTextInput';
import { GraphikSemiTextMd } from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';
import { RegisterStore } from 'store/registerWork/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { RegisterWorkFields } from '../../Helpers';
import { useGetFormDetails } from '../../forms/artist-registration.form';
import { APInformation } from '../APInformation';

export const Details: FunctionComponent = () => {
  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const arForm = useGetFormDetails(detailsForm);
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <>
      <View style={TailwindResponsive('mt-12')}>
        <GraphikSemiTextMd style={TailwindResponsive(`mb-16`)}>
          Item
        </GraphikSemiTextMd>
        <View style={TailwindResponsive(`flex justify-between mb-12`)}>
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.title}
            render={({ field: { onChange, value, ref } }) => (
              <DefaultTextInput
                inputRef={ref}
                value={value}
                label="* Title"
                handleChange={(input) => {
                  arForm?.clearErrors('title');
                  onChange(input);
                }}
                errorMessage={
                  arForm?.formState?.errors?.title?.message as string
                }
                testId="ArtworkTitle"
              />
            )}
          />
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.yearProduced}
            render={({ field: { onChange, value, ref } }) => (
              <DefaultTextInput
                inputRef={ref}
                value={value}
                inputMode="numeric"
                label="* Year Produced"
                handleChange={(input) => {
                  arForm?.clearErrors('yearProduced');
                  onChange(input);
                }}
                errorMessage={
                  arForm?.formState?.errors?.yearProduced?.message as string
                }
                testId="YearProduced"
              />
            )}
          />
          <Controller
            control={arForm?.control}
            name={RegisterWorkFields.materials}
            render={({ field: { onChange, value, ref } }) => (
              <DefaultTextInput
                inputRef={ref}
                value={value}
                label="* Materials/Fabric"
                handleChange={(input) => {
                  arForm?.clearErrors('materials');
                  onChange(input);
                }}
                errorMessage={
                  arForm?.formState?.errors?.materials?.message as string
                }
                testId="Materials"
              />
            )}
          />

          <View style={TailwindResponsive(`mb-10 mobWeb:w-full`)}>
            <Controller
              control={arForm?.control}
              name={RegisterWorkFields.measurements}
              render={({ field: { onChange, value, ref } }) => (
                <DefaultTextInput
                  inputRef={ref}
                  value={value}
                  label="* Size/Measurements"
                  handleChange={(input) => {
                    arForm.clearErrors('measurements');
                    onChange(input);
                  }}
                  errorMessage={
                    arForm?.formState?.errors?.measurements?.message as string
                  }
                  testId="Size/Measurements"
                />
              )}
            />
          </View>
          <View style={TailwindResponsive(`mb-10 mobWeb:w-full`)}>
            <Controller
              control={arForm?.control}
              name={RegisterWorkFields.inventoryNumber}
              render={({ field: { onChange, value } }) => (
                <DefaultTextInput
                  value={value}
                  label="Inventory Number (Optional)"
                  handleChange={(input) => {
                    onChange(input);
                  }}
                  testId="Inventory Number"
                />
              )}
            />
          </View>
        </View>
      </View>

      <APInformation />
    </>
  );
};

export default Details;
