import { alignFromEnum, ButtonAlignment } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { Platform, Pressable, Text } from 'react-native';
import Styles from 'style';
import { Style } from 'tailwind-rn/dist';

export interface IRoundedButtonProps {
  alignment?: ButtonAlignment;
  backgroundColor?: string;
  buttonStyle?:
    | {
        [key: string]: string | number;
      }
    | Style;
  color?: string;
  disabled?: boolean;
  label: string;
  onPress?: () => void;
  paddingHorizontal?: number;
  testId?: string;
  textStyle?:
    | {
        [key: string]: string | number;
      }
    | Style;
}

export const RoundedButton: FunctionComponent<IRoundedButtonProps> = ({
  alignment,
  backgroundColor,
  buttonStyle,
  color,
  disabled = false,
  label,
  onPress,
  paddingHorizontal,
  testId,
  textStyle,
}) => (
  <Pressable
    style={{
      alignItems: alignment === undefined ? 'center' : alignFromEnum(alignment),
      backgroundColor,
      borderRadius: 30,
      height: Platform.OS === 'web' ? '48px' : 40,
      justifyContent: 'center',
      paddingHorizontal: paddingHorizontal || 0,
      width: '100%',
      ...buttonStyle,
    }}
    disabled={disabled}
    onPress={onPress}
  >
    <Text
      testID={testId}
      style={{
        fontFamily: Styles.Fonts.FontFamily.SansMedium,
        fontSize: Styles.Fonts.FontSize.Small,
        color,
        ...textStyle,
      }}
    >
      {label}
    </Text>
  </Pressable>
);

export default RoundedButton;
