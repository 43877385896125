import { CenteredImage } from 'components/shared/image';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import {
  GraphikSemiTextSm,
  GraphikTextSm,
  IBMTextSm,
} from 'components/shared/styled';
import { AssetType, CollectionTab } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { getCollectionTab } from 'store/collection';
import { IGridItemProps } from 'types';
import { getWorkDimensions } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const GridItem: FunctionComponent<IGridItemProps> = ({
  item,
  numColumns,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const collectionView = useSelector(getCollectionTab);

  const link =
    collectionView === CollectionTab.Past && item.lastCompletedTransactionId
      ? `/transaction-details/${item.lastCompletedTransactionId}`
      : `/details/${item.id}`;

  return (
    <View style={[TailwindResponsive(`mb-5 p-1 w-1/${numColumns}`)]}>
      <ExternalLink link={link}>
        <View
          style={[
            { aspectRatio: 1 },
            TailwindResponsive(`bg-Light5 p-8 w-full my-2.5 mobWeb:p-4`),
          ]}
        >
          <CenteredImage uri={item.images[0]} />
          <View
            style={TailwindResponsive(
              `absolute bottom-3.5 flex flex-row h-4 items-center left-3 bottom-3.5`,
            )}
          >
            <IBMTextSm style={TailwindResponsive(`h-4`)}>
              {item.workIdentifier}
            </IBMTextSm>
          </View>
        </View>
      </ExternalLink>

      <ExternalLink link={`/details/${item.id}`}>
        <View style={TailwindResponsive(`flex mt-1.5 flex-col pb-5`)}>
          <GraphikSemiTextSm>{item.artist.artistName}</GraphikSemiTextSm>
          <GraphikSemiTextSm>{item.title}</GraphikSemiTextSm>
          {item.assetType === AssetType.Artwork && (
            <>
              <GraphikTextSm>{getWorkDimensions(item)}</GraphikTextSm>
              <GraphikTextSm>{item.medium}</GraphikTextSm>
            </>
          )}
          {item.assetType === AssetType.Collectible && (
            <>
              <GraphikTextSm>{item.collectible?.measurements}</GraphikTextSm>
              <GraphikTextSm>{item.collectible?.materials}</GraphikTextSm>
            </>
          )}
          <GraphikTextSm>{item.inventoryNumber}</GraphikTextSm>
          <GraphikTextSm>{item.yearProduced}</GraphikTextSm>
        </View>
      </ExternalLink>
    </View>
  );
};
