import InfoIcon from 'components/graphics/InfoIcon';
import { GraphikSemiTextTiny } from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const InvoiceAffordance: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View style={TailwindResponsive('flex-row')}>
      <View>
        <InfoIcon width={18} />
      </View>
      <GraphikSemiTextTiny style={TailwindResponsive('ml-4 w-3/4')}>
        After signing the sales agreement, you will be sent an invoice and
        payment instructions via Stripe. You can also access the invoice on
        Fairchain.
      </GraphikSemiTextTiny>
    </View>
  );
};
