import currency from 'currency.js';
import * as Device from 'expo-device';
import * as Localization from 'expo-localization';
import { CurrencyType } from 'types';

export const getCurrencySymbol = (currencyType) => {
  switch (currencyType) {
    case CurrencyType.USD:
    case CurrencyType.AUD:
    case CurrencyType.MXN:
    case CurrencyType.CAD:
      return '$';
    case CurrencyType.CHF:
      return 'CHF ';
    case CurrencyType.CNY:
    case CurrencyType.JPY:
      return '¥';
    case CurrencyType.SEK:
      return 'SEK ';
    case CurrencyType.GBP:
      return '£';
    case CurrencyType.EUR:
      return '€';
    default:
      return '$';
  }
};

export interface IUserAgentInfo {
  brand: string;
  deviceName: string;
  ipAddress: string;
  locale: string;
  manufacturer: string;
  modelName: string;
  osName: string;
  osVersion: string;
}

export const getUserAgentInfo = (): IUserAgentInfo => ({
  brand: Device.brand || '',
  deviceName: Device.deviceName || '',
  ipAddress: 'n/a',
  locale: Localization.locale,
  manufacturer: Device.manufacturer || '',
  modelName: Device.modelName || '',
  osName: Device.osName || '',
  osVersion: Device.osVersion || '',
});

export const getTotalDisplayValue = (transaction) => {
  const currencySymbol = getCurrencySymbol(transaction);
  return transaction.items
    .reduce((prevItem, currItem) => {
      const discount = currency(currItem.price).multiply(
        currItem.discount / 100,
      );
      const discountedValue = currency(currItem.price, {
        symbol: currencySymbol,
      }).subtract(discount);
      return prevItem.add(discountedValue);
    }, currency(0, { symbol: currencySymbol }))
    .add(transaction.shipping / 100 || 0)
    .add(transaction.tax / 100 || 0)
    .format();
};
