import { useLazyQuery } from '@apollo/client';
import { GET_LAST_SELLER_TRANSACTION } from 'api/requests.v2';
import { ImageIcon } from 'components/graphics';
import BlueSquareCheckIcon from 'components/graphics/BlueSquareCheckIcon';
import { Checkbox } from 'components/shared/checkbox';
import { FairchainImage } from 'components/shared/image';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import {
  GraphikMediumTextSm,
  GraphikTextSm,
  IBMTextSm,
} from 'components/shared/styled';
import { AssetType, CollectionTab } from 'constants/enums';
import { format, parseISO } from 'date-fns';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { getCollectionTab, sortingByDate } from 'store/collection';
import { getSelectedWorks } from 'store/transferWork';
import { getUser } from 'store/user';
import Styles from 'style';
import { Work, getDisplayName } from 'types';
import { getResponsiveSizes } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { getWorkDimensions } from 'utilities/Work';
import { useCollectionHooks } from '../hooks';

export interface IListTableLineItemProps {
  expanded?: boolean;
  id?: number;
  index?: number;
  work: Work;
}

export const ListTableLineItem: FunctionComponent<IListTableLineItemProps> = ({
  expanded,
  id,
  index,
  work,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const collectionView = useSelector(getCollectionTab);
  const isByDate = useSelector(sortingByDate);
  const selectedWorks = useSelector(getSelectedWorks);
  const userInfo = useSelector(getUser);

  const [link, setLink] = useState<string>('/collection');

  const [getLastSellerTransaction, { loading }] = useLazyQuery(
    GET_LAST_SELLER_TRANSACTION,
    {
      onCompleted: ({ response }) => {
        setLink(`/transaction-details/${response}`);
      },
    },
  );

  useEffect(() => {
    if (collectionView === CollectionTab.Past) {
      if (userInfo?.id === work.artist.id) {
        setLink(`/details/${work.id}`);
      } else {
        getLastSellerTransaction({
          variables: { sellerId: userInfo?.id, workId: work.id },
        });
      }
    } else if (collectionView === CollectionTab.Drafts) {
      setLink(`/register-work/${work.id}`);
    } else {
      setLink(`/details/${work.id}`);
    }
  }, [collectionView, getLastSellerTransaction, userInfo, work]);

  const { toggleWorkSelection } = useCollectionHooks();

  const linkIsActive = useMemo(() => {
    if (loading) return false;
    if (
      userInfo &&
      userInfo.isEstate &&
      userInfo.estateOf !== work.ownerId &&
      userInfo.id !== work.ownerId
    ) {
      return false;
    }
    return true;
  }, [userInfo, work, loading]);

  const { mobWeb } = getResponsiveSizes();
  const boldStyle = {
    fontSize: Styles.Fonts.FontSize.Small,
    fontFamily: Styles.Fonts.FontFamily.SansMedium,
  };
  const columnSmallStyle = {
    flex: 1,
    pointerEvents: linkIsActive ? 'auto' : 'none',
  };
  const columnMediumStyle = {
    flex: 2,
    pointerEvents: linkIsActive ? 'auto' : 'none',
  };
  const columnLargeStyle = {
    flex: 3,
    pointerEvents: linkIsActive ? 'auto' : 'none',
  };

  const tailwindPE = linkIsActive
    ? 'pointer-events-auto'
    : 'pointer-events-none';

  const selectable = useMemo(
    () =>
      (!work.currentTransactionId || work.currentTransactionId === '') &&
      collectionView === CollectionTab.Current &&
      (!work.isRetro || work.lastCompletedTransactionId),
    [
      work.currentTransactionId,
      collectionView,
      work.isRetro,
      work.lastCompletedTransactionId,
    ],
  );

  const rowDisplayStyle = expanded === false && index! > 2 ? 'hidden' : 'flex';
  const artistNameStyle = index! > 0 ? 'hidden' : 'flex';
  const displayBorder = id === 0 ? '' : 'border-t border-Gray1';

  let dateRegisteredText = '';
  if (work.dateRegistered)
    dateRegisteredText = format(parseISO(work.dateRegistered), 'PPP');
  else if ((work as any).lastSaved)
    dateRegisteredText = format(parseISO((work as any).lastSaved), 'PPP');

  return (
    <View
      style={TailwindResponsive(
        `${rowDisplayStyle} ${displayBorder} flex-row min-h-[6rem] items-center`,
      )}
    >
      {!isByDate && (
        <View style={mobWeb ? columnSmallStyle : columnMediumStyle}>
          <ExternalLink link={link}>
            <GraphikMediumTextSm
              style={TailwindResponsive(`${artistNameStyle}`)}
            >
              {getDisplayName(work.artist) || 'No artist name'}
            </GraphikMediumTextSm>
          </ExternalLink>
        </View>
      )}
      {isByDate && (
        <View style={TailwindResponsive(`w-20 mobWeb:w-7`)}>
          {selectable && (
            <Checkbox
              isChecked={selectedWorks.includes(work.id)}
              size={12}
              onPress={() => {
                toggleWorkSelection(work.id);
              }}
            />
          )}
        </View>
      )}
      <View
        style={[
          columnSmallStyle,
          TailwindResponsive(`flex-row mobWeb:max-w-min mobWeb:mr-5`),
        ]}
      >
        <ExternalLink link={link}>
          {work.images && work.images[0]?.startsWith('https://') ? (
            <FairchainImage uri={work.images[0]!} width={40} />
          ) : (
            <ImageIcon width={40} />
          )}
        </ExternalLink>
      </View>

      {isByDate ? (
        <View
          style={[
            columnMediumStyle,
            TailwindResponsive(`mobWeb:max-w-min mobWeb:mx-5 mobWeb:hidden`),
          ]}
        >
          <ExternalLink link={link}>
            <Text style={boldStyle}>
              {getDisplayName(work.artist) || 'No artist name'}
            </Text>
          </ExternalLink>
        </View>
      ) : (
        <View style={TailwindResponsive(`w-20`)}>
          {selectable && (
            <Checkbox
              isChecked={selectedWorks.includes(work.id)}
              size={12}
              onPress={() => {
                toggleWorkSelection(work.id);
              }}
            />
          )}
        </View>
      )}

      {mobWeb ? (
        <View style={TailwindResponsive(`flex-1`)}>
          {isByDate && (
            <View style={[columnMediumStyle]}>
              <ExternalLink link={link}>
                <Text style={boldStyle}>
                  {getDisplayName(work.artist) || 'No artist name'}
                </Text>
              </ExternalLink>
            </View>
          )}
          <View
            style={[
              TailwindResponsive('flex-row justify-between'),
              columnLargeStyle,
            ]}
          >
            <ExternalLink link={link}>
              <Text style={boldStyle}>{work.title}</Text>
            </ExternalLink>
            {work.certified && (
              <View
                style={{
                  ...TailwindResponsive('flex items-end'),
                  paddingRight: '2px',
                }}
              >
                <BlueSquareCheckIcon width={19} />
              </View>
            )}
          </View>
          <View style={TailwindResponsive(`w-24 ${tailwindPE}`)}>
            <ExternalLink link={link}>
              <Text style={boldStyle}>{work.yearProduced}</Text>
            </ExternalLink>
          </View>
          <View style={columnMediumStyle}>
            <ExternalLink link={link}>
              <View style={TailwindResponsive(`flex-col`)}>
                <Text
                  style={{ fontFamily: Styles.Fonts.FontFamily.SansRegular }}
                >
                  {work.medium}
                </Text>
                <GraphikTextSm>{work.inventoryNumber}</GraphikTextSm>
              </View>
            </ExternalLink>
          </View>
        </View>
      ) : (
        <>
          <View style={columnLargeStyle}>
            <ExternalLink link={link}>
              <Text style={boldStyle}>{work.title}</Text>
            </ExternalLink>
          </View>
          <View style={TailwindResponsive(`w-24 ${tailwindPE}`)}>
            <ExternalLink link={link}>
              <Text style={boldStyle}>{work.yearProduced}</Text>
            </ExternalLink>
          </View>
          {work.assetType === AssetType.Artwork && (
            <>
              <View style={columnMediumStyle}>
                <ExternalLink link={link}>
                  <View style={TailwindResponsive(`flex-col`)}>
                    <Text style={boldStyle}>{work.medium}</Text>
                    <GraphikTextSm>{work.inventoryNumber}</GraphikTextSm>
                  </View>
                </ExternalLink>
              </View>
              <View style={columnMediumStyle}>
                <ExternalLink link={link}>
                  <Text style={boldStyle}>{getWorkDimensions(work)}</Text>
                </ExternalLink>
              </View>
            </>
          )}
          {work.assetType === AssetType.Collectible && (
            <>
              <View style={columnMediumStyle}>
                <ExternalLink link={link}>
                  <View style={TailwindResponsive(`flex-col`)}>
                    <Text style={boldStyle}>{work.collectible?.materials}</Text>
                    <GraphikTextSm>{work.inventoryNumber}</GraphikTextSm>
                  </View>
                </ExternalLink>
              </View>
              <View style={columnMediumStyle}>
                <ExternalLink link={link}>
                  <Text style={boldStyle}>
                    {work.collectible?.measurements}
                  </Text>
                </ExternalLink>
              </View>
            </>
          )}

          <View style={columnMediumStyle}>
            <ExternalLink link={link}>
              <IBMTextSm>{work.workIdentifier}</IBMTextSm>
            </ExternalLink>
          </View>

          <View
            style={TailwindResponsive(
              `flex-row items-center w-44 ${tailwindPE}`,
            )}
          >
            <ExternalLink link={link}>
              <Text style={boldStyle}>{dateRegisteredText}</Text>
            </ExternalLink>
          </View>
          <View style={TailwindResponsive('w-6 items-end')}>
            {work.certified && (
              <View>
                <View style={TailwindResponsive('mr-1')}>
                  <BlueSquareCheckIcon width={24} />
                </View>
              </View>
            )}
          </View>
        </>
      )}
    </View>
  );
};
