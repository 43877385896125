import { calculateTotalRoyalty } from 'components/views/transferWork/Helpers';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDiminishedRoyalties,
  setDiminishedRoyaltiesErrors,
  setDiminishedRoyalty,
} from 'store/forms';
import DiminishedRoyaltyGSASection from './DiminishedRoyaltyGSASection';

import DiminishedRoyaltySection from './DiminishedRoyaltySection';

export enum DiminishingRoyaltyDisplay {
  SalesOffer,
}

interface IDiminishingRoyaltyProps {
  isGallery: boolean;
  style?: DiminishingRoyaltyDisplay;
  workId: string;
}

export const DiminishingRoyalty: FunctionComponent<
  IDiminishingRoyaltyProps
> = ({ isGallery, style, workId }) => {
  const dispatch = useDispatch();

  const diminishedRoyalties = useSelector(getDiminishedRoyalties);

  const [isInitialized, setIsInitialized] = useState(false);
  const [doesDiminish, setDoesDiminish] = useState(false);
  const [diminishedArtistValue, setDiminishedArtistValue] = useState('0');
  const [diminishedGalleryValue, setDiminishedGalleryValue] = useState('0');
  const [totalDiminishedValue, setTotalDiminishedValue] = useState('0');
  const [diminishedArtistError, setDiminishedArtistError] = useState('');
  const [diminishedGalleryError, setDiminishedGalleryError] = useState('');
  const [diminishedTotalValueError, setDiminishedTotalValueError] =
    useState('');

  useEffect(() => {
    if (
      !diminishedArtistError &&
      !diminishedGalleryError &&
      !diminishedTotalValueError
    ) {
      dispatch(setDiminishedRoyaltiesErrors(false));
    }
  }, [
    diminishedArtistError,
    diminishedGalleryError,
    diminishedTotalValueError,
    dispatch,
  ]);

  useEffect(() => {
    let hasErrors = false;

    if (Number(diminishedArtistValue) > 50) {
      setDiminishedArtistError('Artist resale commissions are capped at 50%');
      dispatch(setDiminishedRoyaltiesErrors(true));
      hasErrors = true;
    }
    if (Number(diminishedGalleryValue) > 50) {
      setDiminishedGalleryError('Gallery resale commissions are capped at 50%');
      dispatch(setDiminishedRoyaltiesErrors(true));
      hasErrors = true;
    }
    if (Number(diminishedGalleryValue) > Number(diminishedArtistValue)) {
      setDiminishedGalleryError(
        'Gallery resale commission cannot exceed artist resale commission.',
      );
      dispatch(setDiminishedRoyaltiesErrors(true));
      hasErrors = true;
    }
    if (Number(diminishedArtistValue) + Number(diminishedGalleryValue) > 50) {
      setDiminishedTotalValueError(
        'Total resale commissions are capped at 50%',
      );
      dispatch(setDiminishedRoyaltiesErrors(true));
      hasErrors = true;
    }

    if (!hasErrors) {
      setDiminishedArtistError('');
      setDiminishedGalleryError('');
      setDiminishedTotalValueError('');
    }

    const newTotalRoyalty = calculateTotalRoyalty(
      diminishedArtistValue,
      diminishedGalleryValue,
    );
    setTotalDiminishedValue(newTotalRoyalty);
  }, [diminishedArtistValue, diminishedGalleryValue, dispatch]);

  useEffect(() => {
    if (isInitialized === false) {
      const theRoyalty = diminishedRoyalties.find(
        (royalty) => royalty?.workId === workId,
      );
      if (theRoyalty) {
        setIsInitialized(true);
        setDiminishedArtistValue(theRoyalty.diminishedArtistValue);
        setDiminishedGalleryValue(theRoyalty.diminishedGalleryValue);
        setDoesDiminish(theRoyalty.doesDiminish);
      }
    }
  }, [diminishedRoyalties, isInitialized, workId]);

  useEffect(() => {
    dispatch(
      setDiminishedRoyalty({
        diminishedArtistValue,
        diminishedDate: '',
        diminishedGalleryValue,
        doesDiminish: true,
        workId,
      }),
    );
  }, [
    doesDiminish,
    diminishedArtistValue,
    diminishedGalleryValue,
    dispatch,
    workId,
  ]);

  if (style === DiminishingRoyaltyDisplay.SalesOffer)
    return (
      <DiminishedRoyaltyGSASection
        diminishedArtistError={diminishedArtistError}
        diminishedArtistValue={diminishedArtistValue}
        diminishedGalleryError={diminishedGalleryError}
        diminishedGalleryValue={diminishedGalleryValue}
        diminishedTotalValueError={diminishedTotalValueError}
        isGallery={isGallery}
        setDiminishedArtistError={setDiminishedArtistError}
        setDiminishedArtistValue={setDiminishedArtistValue}
        setDiminishedGalleryError={setDiminishedGalleryError}
        setDiminishedGalleryValue={setDiminishedGalleryValue}
        totalDiminishedValue={totalDiminishedValue}
      />
    );

  return (
    <DiminishedRoyaltySection
      diminishedArtistError={diminishedArtistError}
      diminishedArtistValue={diminishedArtistValue}
      diminishedGalleryError={diminishedGalleryError}
      diminishedGalleryValue={diminishedGalleryValue}
      diminishedTotalValueError={diminishedTotalValueError}
      isGallery={isGallery}
      setDiminishedArtistError={setDiminishedArtistError}
      setDiminishedArtistValue={setDiminishedArtistValue}
      setDiminishedGalleryError={setDiminishedGalleryError}
      setDiminishedGalleryValue={setDiminishedGalleryValue}
      totalDiminishedValue={totalDiminishedValue}
    />
  );
};
