import { useReactiveVar } from '@apollo/client';
import { ExitButtonIcon } from 'components/graphics';
import { TextButton } from 'components/shared/button';
import CenteredImage from 'components/shared/image/CenteredImage';
import { FairchainImage } from 'components/shared/image/FairchainImage';
import { useDeleteTempImage } from 'components/views/registerWork/Hooks';
import { FunctionComponent } from 'react';
import { Modal, Platform, Pressable, ScrollView, View } from 'react-native';
import { RegisterStore } from 'store/registerWork/apollo';
import Styles from 'style';
import { IImagePickerResponse } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ImageDisplayModal: FunctionComponent = () => {
  const deleteImageAsync = useDeleteTempImage();
  const { TailwindResponsive } = useTailwindResponsive();

  const key = useReactiveVar(RegisterStore.Vars.Images.modalImage);
  const image = useReactiveVar<IImagePickerResponse | null>(
    RegisterStore.Vars.Images[key],
  );
  const primaryKey = useReactiveVar(RegisterStore.Vars.Images.primary);
  const showModal = useReactiveVar(RegisterStore.Vars.Images.showModal);

  const image1 = useReactiveVar(RegisterStore.Vars.Images.image1);
  const image2 = useReactiveVar(RegisterStore.Vars.Images.image2);
  const image3 = useReactiveVar(RegisterStore.Vars.Images.image3);
  const image4 = useReactiveVar(RegisterStore.Vars.Images.image4);

  const deleteImage = () => {
    if (primaryKey === key) {
      if (image1 && key !== 'image1')
        RegisterStore.Vars.Images.primary('image1');
      else if (image2 && key !== 'image2')
        RegisterStore.Vars.Images.primary('image2');
      else if (image3 && key !== 'image3')
        RegisterStore.Vars.Images.primary('image3');
      else if (image4 && key !== 'image4')
        RegisterStore.Vars.Images.primary('image4');
      else RegisterStore.Vars.Images.primary('');
    }

    deleteImageAsync(image);
    RegisterStore.Vars.Images[key](null);

    RegisterStore.Vars.Images.showModal(false);
  };

  return (
    <Modal
      style={TailwindResponsive('flex-1 items-center py-10')}
      transparent
      visible={showModal}
    >
      <Pressable
        onPress={() => {
          RegisterStore.Vars.Images.showModal(false);
        }}
        style={TailwindResponsive(
          `bg-black/50 flex-1 items-center justify-center mob:px-4`,
        )}
      >
        <Pressable
          onStartShouldSetResponder={() => true}
          onTouchEnd={(e) => {
            e.stopPropagation();
          }}
          style={[
            TailwindResponsive(
              `bg-Light1 flex shrink rounded-lg max-w-2xl m-10 w-full`,
            ),
            { cursor: 'default' },
          ]}
        >
          <View style={TailwindResponsive(`items-end pr-2 pt-2`)}>
            <Pressable
              onPress={() => {
                RegisterStore.Vars.Images.showModal(false);
              }}
              style={TailwindResponsive(`self-end`)}
            >
              <ExitButtonIcon color={Styles.Colours.Dark1} />
            </Pressable>
          </View>

          <ScrollView style={TailwindResponsive(`p-10 pt-6 mob:p-4`)}>
            <View
              style={{
                shadowColor: 'black',
                shadowOffset: { height: 0, width: 0 },
                shadowOpacity: 0.5,
                shadowRadius: 10,
              }}
            >
              {Platform.OS === 'web' ? (
                <FairchainImage
                  uri={image?.remoteURI || image?.localURI || ''}
                />
              ) : (
                <View style={TailwindResponsive(`h-80 w-full`)}>
                  <CenteredImage isLocal uri={image?.localURI} />
                </View>
              )}
            </View>

            <View
              style={TailwindResponsive(
                `flex flex-row flex-1 py-5 mobWeb:flex-col`,
              )}
            >
              <View style={TailwindResponsive(`flex flex-1`)}>
                <TextButton
                  label="Set Primary"
                  onPress={() => {
                    RegisterStore.Vars.Images.primary(key);
                    RegisterStore.Vars.Images.showModal(false);
                  }}
                  testID="SetPrimary_Button"
                />
              </View>

              <View style={TailwindResponsive(`flex flex-1`)}>
                <TextButton
                  label="Delete"
                  onPress={deleteImage}
                  testID="DeleteImage_Button"
                />
              </View>
            </View>
          </ScrollView>
        </Pressable>
      </Pressable>
    </Modal>
  );
};

export default ImageDisplayModal;
