import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { UPDATE_ARTIST_NAME } from 'api/requests.v2';
import { RoundedButton } from 'components/shared/button';
import { DefaultTextInput } from 'components/shared/form';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Text, View } from 'react-native';
import Styles from 'style';
import { trimFormData } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import * as yup from 'yup';

export const UpdateArtistName: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const [updateArtistName] = useMutation(UPDATE_ARTIST_NAME);
  const [isSuccessfullySubmitted, setSuccessfullySubmitted] = useState('');
  const [isSubmittedError, setSubmittedError] = useState('');

  const updateArtistNameSchema = yup.object().shape({
    artistEmail: yup.string().required('This is a required field'),
    artistName: yup.string().required('This is a required field'),
  });

  const {
    control,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      artistEmail: '',
      artistName: '',
    },
    resolver: yupResolver(updateArtistNameSchema),
  });

  const onSubmit = async (data: any) => {
    const trimmedData = trimFormData(data);
    const updateResponse = await updateArtistName({
      variables: {
        artistEmail: trimmedData.artistEmail,
        artistName: trimmedData.artistName,
      },
    });

    if (updateResponse.data.response.success === true) {
      setSuccessfullySubmitted('🤠 Artist Name Updated! 🚀');
    } else {
      setSubmittedError(updateResponse.data.response.errorMessage);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setTimeout(() => {
        setSubmittedError('');
        setSuccessfullySubmitted('');
      }, 3000);
    }
  }, [reset, isSubmitSuccessful]);

  return (
    <View style={TailwindResponsive('pb-12')}>
      <View style={TailwindResponsive(`border-t border-Dark1 p-12`)}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              value={value}
              label="* Artist Email"
              handleChange={onChange}
              errorMessage={errors.artistEmail?.message}
            />
          )}
          name="artistEmail"
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              value={value}
              label="* New Artist Name"
              handleChange={onChange}
              errorMessage={errors.artistName?.message}
            />
          )}
          name="artistName"
          defaultValue=""
        />
        <View style={TailwindResponsive('m-5')}>
          <RoundedButton
            label="Update Artist Name"
            onPress={() => {
              handleSubmit(onSubmit)();
            }}
            backgroundColor={Styles.Colours.Primary}
            color={Styles.Colours.Light1}
          />
          {!!isSuccessfullySubmitted && (
            <Text style={{ color: Styles.Colours.Success }}>
              {isSuccessfullySubmitted}
            </Text>
          )}
          {!!isSubmittedError && (
            <Text style={{ color: Styles.Colours.Error }}>
              {isSubmittedError}
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};
