import React, { FunctionComponent } from 'react';
import Svg, { G, Rect } from 'react-native-svg';
import { ISVGProps } from './Types';

export const CheckboxUnchecked: FunctionComponent<ISVGProps> = ({
  color,
  width,
}) => (
  <Svg
    id="Check_Box"
    data-name="Check Box"
    width={width || '15'}
    height="15"
    viewBox="0 0 15 15"
  >
    <G
      id="Rectangle_1068"
      data-name="Rectangle 1068"
      fill="none"
      stroke={color || '#000'}
      strokeWidth="1"
    >
      <Rect width="15" height="15" stroke="none" />
      <Rect x="0.5" y="0.5" width="14" height="14" fill="none" />
    </G>
  </Svg>
);

export default CheckboxUnchecked;
