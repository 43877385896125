import { CenteredImage } from 'components/shared/image/CenteredImage';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Image, Pressable, View } from 'react-native';
import useTailwindResponsive, {
  getResponsiveSizes,
} from 'utilities/TailwindResponsive';
import { GetEmbeddedURL } from './ImageHelpers';

interface SmallImageProps {
  image: string;
  setActiveImage: () => void;
}

const SmallImage: FunctionComponent<SmallImageProps> = ({
  setActiveImage,
  image,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const [size, setSize] = React.useState<{ width: number; height: number }>();
  const responsiveSizes = getResponsiveSizes();
  const SMALL_IMAGE_SIZE = useMemo(() => {
    if (!responsiveSizes.md && responsiveSizes.sm) {
      return 30;
    }
    if (!responsiveSizes.lg && responsiveSizes.md) {
      return 40;
    }
    if (responsiveSizes.mobWeb) {
      return 60;
    }
    return 80;
  }, [responsiveSizes]);

  useEffect(() => {
    Image.getSize(image, (width, height) => {
      const aspectRatio = width / height;
      const newWidth =
        aspectRatio > 1 ? SMALL_IMAGE_SIZE : SMALL_IMAGE_SIZE * aspectRatio;
      const newHeight =
        aspectRatio > 1 ? SMALL_IMAGE_SIZE / aspectRatio : SMALL_IMAGE_SIZE;
      setSize({
        width: newWidth,
        height: newHeight,
      });
    });
  }, [image, SMALL_IMAGE_SIZE]);

  return (
    <Pressable style={TailwindResponsive(`mr-2`)} onPress={setActiveImage}>
      <View
        style={{
          ...TailwindResponsive(
            `p-6 bg-Light5 w-full md:p-4 sm:p-2 mobWeb:p-2`,
          ),
        }}
      >
        <Image
          source={{ uri: image }}
          style={{ width: size?.width, height: SMALL_IMAGE_SIZE }}
          resizeMode="contain"
        />
      </View>
    </Pressable>
  );
};

interface IImagesWithThumbnailsProps {
  images: string[];
  isLocal?: boolean;
  videoURL?: string;
}
export const ImagesWithThumbnails: FunctionComponent<
  IImagesWithThumbnailsProps
> = ({ images, isLocal, videoURL }) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const [activeImage, setActiveImage] = useState(images[0]);
  const [embedURLSrc, setEmbedURLSrc] = useState('');
  const [thumbnail, setThumbnail] = useState('');

  useEffect(() => {
    setActiveImage(images[0]);
  }, [images]);

  useEffect(() => {
    const setup = async () => {
      if (videoURL) {
        const videoInfo = await GetEmbeddedURL(videoURL);
        setEmbedURLSrc(videoInfo.uri);
        setThumbnail(videoInfo.thumbnail);
      }
    };

    setup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View>
      <View style={TailwindResponsive(`p-8 bg-Light5 w-full h-96 lg:h-165`)}>
        <CenteredImage isLocal={isLocal} uri={activeImage!} />
      </View>

      <View style={TailwindResponsive(`flex flex-row mt-2`)}>
        {(images.length > 1 || !!videoURL) &&
          images.map((image) => (
            <SmallImage
              key={image}
              image={image}
              setActiveImage={() => setActiveImage(image)}
            />
          ))}
        {!!videoURL && videoURL !== '' && (
          <SmallImage
            image={thumbnail}
            setActiveImage={() => {
              setActiveImage(embedURLSrc);
            }}
          />
        )}
      </View>
    </View>
  );
};

export default ImagesWithThumbnails;
