import { BreadcrumbItem, BreadcrumbStep } from 'components/shared/breadcrumb';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { getIsRetro } from 'store/transferWork';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IBreadcrumbsProps {
  currentStep: number;
  maxStep?: number;
  steps: BreadcrumbStep[];
}

export const Breadcrumbs: FunctionComponent<IBreadcrumbsProps> = ({
  currentStep,
  maxStep,
  steps,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const isRetro = useSelector(getIsRetro);

  if (currentStep === steps.length || (maxStep && currentStep > maxStep))
    return (
      <View style={TailwindResponsive(`flex flex-row mb-10`)}>
        <BreadcrumbItem
          currentStep={currentStep}
          myStep={currentStep}
          title="Review"
        />
      </View>
    );

  return (
    <View style={TailwindResponsive('flex-row web:mb-24')}>
      {steps.map((step, ind) => {
        if (maxStep && ind > maxStep) {
          return null;
        }

        return (
          <BreadcrumbItem
            key={`ARBreadcrumb-${step.step}`}
            currentStep={currentStep}
            myStep={ind}
            title={isRetro && step.retroTitle ? step.retroTitle : step.title}
          />
        );
      })}
    </View>
  );
};
