import {
  StackNavigationProp,
  createStackNavigator,
} from '@react-navigation/stack';
import { StackScreenOptions } from 'navigation/RootNavigator';
import { FunctionComponent } from 'react';
import SignUpForm from './components/SignUpForm';
import { SignUpSelection } from './components/SignUpSelection';

export type SignUpParamList = {
  Artist: undefined;
  Collector: undefined;
  SignUp: undefined;
};
export type SignupStackNavProp = StackNavigationProp<SignUpParamList>;

export const SignUp: FunctionComponent = () => {
  const Stack = createStackNavigator();

  return (
    <Stack.Navigator screenOptions={StackScreenOptions}>
      <Stack.Screen
        name="SignUp"
        component={SignUpSelection}
        options={{ title: 'Create Account' }}
      />
      <Stack.Screen
        name="Artist"
        component={SignUpForm}
        options={{ title: 'Artist Sign Up' }}
      />
      <Stack.Screen
        name="Collector"
        component={SignUpForm}
        options={{ title: 'Collector Sign Up' }}
      />
    </Stack.Navigator>
  );
};

export default SignUp;
