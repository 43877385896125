import React, { FunctionComponent, useEffect, useState } from 'react';
import { Animated, Platform, Text, TextInput, View } from 'react-native';
import Styles from 'style';
import { DefaultTextInputProps } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const DefaultTextInput: FunctionComponent<DefaultTextInputProps> = ({
  handleChange,
  value,
  label,
  color,
  editable,
  infoMessage,
  secureTextEntry,
  errorMessage,
  inputRef,
  testId,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const { TailwindResponsive } = useTailwindResponsive();

  const [isAnimate] = useState(
    value === '' ? new Animated.Value(0) : new Animated.Value(1),
  );
  useEffect(() => {
    Animated.timing(isAnimate, {
      toValue: isFocused || value !== '' ? 1 : 0,
      duration: 200,
      useNativeDriver: Platform.OS !== 'web',
    }).start();
  }, [isAnimate, isFocused, value]);

  return (
    <View style={TailwindResponsive('flex-col')}>
      <View
        style={{
          borderBottomColor:
            color || !editable ? Styles.Colours.Gray1 : Styles.Colours.Dark1,
          borderBottomWidth: 1,
          height: 60,
          justifyContent: 'flex-end',
          marginVertical: 18,
          width: '100%',
        }}
      >
        <Animated.Text
          style={{
            position: 'absolute',
            top: 32,
            color: color || Styles.Colours.Dark2,
            fontSize: isAnimate.interpolate({
              inputRange: [0, 1],
              outputRange: [
                Styles.Fonts.FontSize.Medium,
                Styles.Fonts.FontSize.Tiny,
              ],
            }),
            fontFamily: Styles.Fonts.FontFamily.SansRegular,
            opacity: isAnimate.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 0],
            }),
            zIndex: -1,
          }}
        >
          {label}
        </Animated.Text>
        <Animated.Text
          style={{
            position: 'absolute',
            right: 0,
            left: 'auto',
            top: 36,
            color: color || Styles.Colours.Dark2,
            fontSize: Styles.Fonts.FontSize.Tiny,
            fontFamily: Styles.Fonts.FontFamily.SansRegular,
            opacity: isAnimate.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 1],
            }),
            zIndex: -1,
          }}
        >
          {label}
        </Animated.Text>
        {editable === false ? (
          <Text
            testID={testId}
            style={[
              {
                borderWidth: 0,
                height: 34,
                fontSize: Styles.Fonts.FontSize.Medium,
                fontFamily: Styles.Fonts.FontFamily.SansMedium,
                color: color || 'black',
                paddingEnd: 50,
              },
              // @ts-ignore style for web only
              Platform.OS === 'web' && { outlineStyle: 'none' }, // added for web
            ]}
            onFocus={() => (editable ? setIsFocused(true) : null)}
          >
            {value}
          </Text>
        ) : (
          <TextInput
            style={[
              {
                borderWidth: 0,
                height: 34,
                fontSize: Styles.Fonts.FontSize.Medium,
                fontFamily: Styles.Fonts.FontFamily.SansMedium,
                color: color || 'black',
                paddingEnd: 50,
              },
              // @ts-ignore style for web only
              Platform.OS === 'web' && { outlineStyle: 'none' }, // added for web
            ]}
            secureTextEntry={secureTextEntry || false}
            onFocus={() => (editable ? setIsFocused(true) : null)}
            onChangeText={handleChange}
            testID={`${testId}_Input`}
            value={value}
            ref={inputRef}
            {...props}
          />
        )}
      </View>

      {!!infoMessage && !errorMessage && (
        <View style={{ marginTop: 5 }}>
          <Text
            style={{
              color:
                color === Styles.Colours.Light1
                  ? Styles.Colours.Gray3
                  : Styles.Colours.Gray2,
            }}
          >
            {infoMessage}
          </Text>
        </View>
      )}

      {!!errorMessage && (
        <View style={{ marginTop: 5 }}>
          <Text style={{ color: Styles.Colours.Error }}>{errorMessage}</Text>
        </View>
      )}
    </View>
  );
};

export default DefaultTextInput;
