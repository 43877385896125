import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { APPROVE_TRANSFER } from 'api/requests.v2';
import { RoundedButton } from 'components/shared/button';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { GraphikSemiTextSm, GraphikTextSm } from 'components/shared/styled';
import { HashPriority } from 'constants/enums';
import { UserContext } from 'contexts';
import { FCStackNavProp } from 'navigation';
import React, { FunctionComponent, useContext, useState } from 'react';
import { ActivityIndicator, Pressable, View } from 'react-native';
import Styles from 'style';
import { Transaction } from 'types';
import { getTotalDisplayValue } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface Props {
  transaction: Transaction;
  refetch?: () => void;
}

export const ViewTransactionsLineItem: FunctionComponent<Props> = ({
  transaction,
  refetch,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const authUser = useContext(UserContext);
  const [isApproved, setIsApproved] = useState(false);
  const [approveTransfer, { loading }] = useMutation(APPROVE_TRANSFER);
  const navigation = useNavigation<FCStackNavProp>();

  const approveOnPress = async (transactionId) => {
    const variables = {
      adminId: authUser?.uid,
      transactionId,
      priority: HashPriority.Normal,
    };
    await approveTransfer({ variables });
    refetch!();
  };

  const approveImmediatelyOnPress = async (transactionId) => {
    const variables = {
      adminId: authUser?.uid,
      transactionId,
      priority: HashPriority.Immediate,
    };

    await approveTransfer({ variables });
    refetch!();
  };

  return (
    <View style={TailwindResponsive(`bg-Light5 flex-1 m-8 p-8`)}>
      <Pressable
        onPress={() => {
          navigation.push('TransactionDetail', {
            transactionId: transaction.id,
          });
        }}
      >
        <View style={TailwindResponsive(`flex flex-row mb-2`)}>
          <GraphikTextSm style={TailwindResponsive(`mr-8`)}>
            {transaction.id}
          </GraphikTextSm>
          <View style={TailwindResponsive(`flex flex-col`)}>
            {transaction.items.map((item) => (
              <GraphikSemiTextSm
                key={item.work.id}
              >{`${item.work.title} `}</GraphikSemiTextSm>
            ))}
          </View>
          <GraphikSemiTextSm>
            ({getTotalDisplayValue(transaction)})
          </GraphikSemiTextSm>
        </View>
        <View style={TailwindResponsive(`flex flex-row `)}>
          <GraphikSemiTextSm>
            {`${transaction.seller.firstName} ${transaction.seller.lastName}`}
          </GraphikSemiTextSm>
          <GraphikTextSm style={TailwindResponsive(`mx-6`)}>
            {' '}
            -{'>'}{' '}
          </GraphikTextSm>
          <GraphikSemiTextSm>
            {`${transaction.buyer.firstName} ${transaction.buyer.lastName}`}
          </GraphikSemiTextSm>
        </View>
      </Pressable>

      {loading && (
        <View style={TailwindResponsive(`absolute items-end right-10 top-10`)}>
          <ActivityIndicator size="large" />
        </View>
      )}

      {!isApproved && !loading && refetch && (
        <View style={TailwindResponsive(`flex flex-row items-end justify-end`)}>
          <View style={TailwindResponsive(`mr-12 w-48`)}>
            <FairchainButton
              buttonStyle={TailwindResponsive(`bg-Light1 rounded-full`)}
              onPress={() => {
                setIsApproved(true);
                approveImmediatelyOnPress(transaction.id);
              }}
              label="Approve Now"
            />
          </View>

          <View style={TailwindResponsive(`w-48`)}>
            <RoundedButton
              onPress={() => {
                setIsApproved(true);
                approveOnPress(transaction.id);
              }}
              label="Approve"
              backgroundColor={Styles.Colours.Dark1}
              color={Styles.Colours.Light1}
            />
          </View>
        </View>
      )}
    </View>
  );
};

export default ViewTransactionsLineItem;
