import { useReactiveVar } from '@apollo/client';
import { Checkbox } from 'components/shared/checkbox';
import { FCCurrencyInput } from 'components/shared/form';
import { GraphikTextSm, GraphikTextTiny } from 'components/shared/styled';
import currency from 'currency.js';
import { FunctionComponent } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { Platform, View } from 'react-native';
import { useSelector } from 'react-redux';
import { InvoiceChoice, userInvoiceChoiceVar } from 'store/transferWork/apollo';
import { getUser } from 'store/user';
import Styles from 'style';
import { CurrencyType } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ITransactionDetailsForm, TransferWorkForm } from '../Helpers';
import ConnectToStripe from './ConnectToSripe';
import DetailsLineItem from './DetailsLineItem';
import UploadInvoice from './UploadInvoice';

interface IInvoiceDetailsProps {
  allowCreditCard: boolean;
  control: Control<ITransactionDetailsForm>;
  currencySymbol: string;
  currencyType: CurrencyType;
  errors: any;
  feesPassedOn: currency;
  isStripeSetup: boolean;
  setAllowCreditCard: (boolean) => void;
  setPassFees: (boolean) => void;
  setValues: UseFormSetValue<ITransactionDetailsForm>;
}

export const InvoiceDetails: FunctionComponent<IInvoiceDetailsProps> = ({
  allowCreditCard,
  control,
  currencySymbol,
  currencyType,
  errors,
  feesPassedOn,
  isStripeSetup,
  setAllowCreditCard,
  setPassFees,
  setValues,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const userInfo = useSelector(getUser);
  const userInvoiceChoice = useReactiveVar(userInvoiceChoiceVar);

  if (userInvoiceChoice === InvoiceChoice.DONTINCLUDE) {
    return (
      <View
        style={[
          TailwindResponsive(`border py-6 px-8 w-full h-full`),
          { zIndex: -1 },
        ]}
      >
        <GraphikTextSm>
          Once the buyer has paid and signed the sales agreement, you&apos;ll be
          able to initiate title transfer.
        </GraphikTextSm>
      </View>
    );
  }

  if (userInvoiceChoice === InvoiceChoice.UPLOAD) {
    return <UploadInvoice />;
  }

  if (!isStripeSetup && !userInfo?.isSubUser) {
    return <ConnectToStripe />;
  }

  return (
    <View
      style={[
        TailwindResponsive(`border py-6 px-8 w-full h-full`),
        { zIndex: -1 },
      ]}
    >
      <View style={TailwindResponsive(`mb-5`)}>
        <Controller
          control={control}
          name={TransferWorkForm.allowCreditCard}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              isChecked={value}
              label="Allow credit card payments."
              onPress={() => {
                setAllowCreditCard(!value);
                onChange(!value);
                if (value) {
                  setValues('passFeesToBuyer', false);
                }
              }}
            />
          )}
        />
      </View>

      {!!userInfo?.connectedToQb && (
        <View style={TailwindResponsive(`mb-5`)}>
          <Controller
            control={control}
            name={TransferWorkForm.quickbooksInvoice}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                isChecked={value}
                label="Sync your Fairchain invoice to your Quickbooks account."
                onPress={() => {
                  onChange(!value);
                }}
              />
            )}
          />
        </View>
      )}

      <View style={TailwindResponsive(`mb-5`)}>
        <Controller
          control={control}
          name={TransferWorkForm.allowKlarna}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              isChecked={value}
              label="Accept payments via Klarna."
              onPress={() => {
                onChange(!value);
              }}
            />
          )}
        />
        <GraphikTextSm style={TailwindResponsive(`ml-10`)}>
          Buyer pays in four installments, you get paid in full up front.
          Klarna&apos;s fee is 6% of the transaction value.
        </GraphikTextSm>
      </View>

      {!!allowCreditCard && (
        <View>
          <Controller
            control={control}
            name={TransferWorkForm.passFeesToBuyer}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                isChecked={value}
                label="Charge credit card processing fees to buyer."
                onPress={() => {
                  onChange(!value);
                  setPassFees(!value);
                  setValues('feesPassed', value ? 0 : feesPassedOn.value);
                }}
              />
            )}
          />
          <GraphikTextSm style={TailwindResponsive(`ml-10`)}>
            If selected, user will only be able to pay with credit card. This
            practice may be prohibited in certain jurisdictions, and you shall
            ensure that it is not prohibited in yours.
          </GraphikTextSm>
        </View>
      )}

      <View style={TailwindResponsive(`mt-24 -mb-12 mobWeb:-mb-4 mob:mt-12`)}>
        <DetailsLineItem
          title={Platform.OS === 'ios' ? 'Shipping' : 'Shipping and Handling'}
        >
          <View style={TailwindResponsive(`mob:h-16`)}>
            <Controller
              control={control}
              name={TransferWorkForm.shipping}
              render={({ field: { onChange, value } }) => (
                <FCCurrencyInput
                  errorMessage={errors?.shipping?.message as string}
                  handleChange={onChange}
                  precision={2}
                  label=""
                  value={value}
                />
              )}
            />
          </View>
          <GraphikTextTiny color={Styles.Colours.Gray2}>
            Shipping and Handling ({`${currencySymbol} ${currencyType}`})
          </GraphikTextTiny>
        </DetailsLineItem>

        <DetailsLineItem title="Tax">
          <View style={TailwindResponsive(`mob:h-16`)}>
            <Controller
              control={control}
              name={TransferWorkForm.tax}
              render={({ field: { onChange, value } }) => (
                <FCCurrencyInput
                  errorMessage={errors?.tax?.message as string}
                  handleChange={onChange}
                  precision={2}
                  label=""
                  value={value}
                />
              )}
            />
          </View>
          <GraphikTextTiny color={Styles.Colours.Gray2}>
            Tax ({`${currencySymbol} ${currencyType}`})
          </GraphikTextTiny>
        </DetailsLineItem>
      </View>
    </View>
  );
};
