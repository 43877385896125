import { useQuery } from '@apollo/client';
import { GET_KYC_MATCHES } from 'api/requests.v2';
import { ErrorMessage } from 'components/shared/error';
import { SpinLoader } from 'components/shared/loader';
import { GraphikSemiTextLg, GraphikTextMd } from 'components/shared/styled';
import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { KycMatch } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import KycLineItem from './KYCLineItem';

interface IKycProps {
  buyer: string;
}

export const KYC: FunctionComponent<IKycProps> = ({ buyer }) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const [kycResults, setKycResults] = useState<KycMatch[]>([]);

  const { loading: getKYCLoading, error: getKYCError } = useQuery(
    GET_KYC_MATCHES,
    {
      variables: {
        name: buyer,
      },
      onCompleted: ({ response }) => {
        const filteredResponse = Array.from(
          new Set(response.map((type) => JSON.stringify(type))),
        ).map((type) => JSON.parse(type as string));
        setKycResults(filteredResponse);
      },
    },
  );

  if (getKYCLoading) {
    return <SpinLoader />;
  }

  if (getKYCError) {
    return <ErrorMessage />;
  }

  return (
    <View
      style={TailwindResponsive(`mb-8 pr-16 w-1/2 mobWeb:w-full mobWeb:pr-0`)}
    >
      <GraphikSemiTextLg style={TailwindResponsive(`mb-6`)}>
        Know Your Customer Check
      </GraphikSemiTextLg>
      {kycResults.length === 0 ? (
        <View>
          <GraphikTextMd>No results found.</GraphikTextMd>
        </View>
      ) : (
        kycResults.map((result, index) => (
          <KycLineItem index={index} result={result} />
        ))
      )}
    </View>
  );
};

export default KYC;
