import { useMutation } from '@apollo/client';
import { UNCHECK_TWO_FACTOR_ENABLED } from 'api/requests.v2';
import { Checkbox } from 'components/shared/checkbox';
import { GraphikTextSm } from 'components/shared/styled';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, updateTwoFactorEnabled } from 'store/user';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import TwoFactorAffordance from './TwoFactorAffordance';

export const TwoFactorEnable: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const [modalVisible, setModalVisible] = useState(false);
  const userFromStore = useSelector(getUser);
  const dispatch = useDispatch();
  const [uncheckTwoFactorEnabled] = useMutation(UNCHECK_TWO_FACTOR_ENABLED);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleUncheckTwoFactor = useCallback(async () => {
    dispatch(updateTwoFactorEnabled(false));
    await uncheckTwoFactorEnabled({
      variables: { userId: userFromStore?.id },
    });
  }, [dispatch, uncheckTwoFactorEnabled, userFromStore]);

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
      }, 6000);
    }
  }, [showSuccess]);

  return (
    <View style={TailwindResponsive(`mt-6`)}>
      <Checkbox
        labelStyle={Styles.Fonts.FontFamily.SansMedium}
        label="Enable SMS two-factor authentication to secure your account. You’ll receive an SMS notification with a code each time you log in."
        isChecked={userFromStore?.twoFactorEnabled || modalVisible === true}
        onPress={() => {
          if (userFromStore?.twoFactorEnabled) {
            handleUncheckTwoFactor();
          }
          setModalVisible(!userFromStore?.twoFactorEnabled && !modalVisible);
        }}
      />
      <TwoFactorAffordance
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setShowSuccess={setShowSuccess}
      />
      {showSuccess && (
        <GraphikTextSm
          style={TailwindResponsive(`mt-2 ml-10`)}
          color={Styles.Colours.Success}
        >
          SMS two-factor authentication has successfully been enabled for your
          account
        </GraphikTextSm>
      )}
    </View>
  );
};

export default TwoFactorEnable;
