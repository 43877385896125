import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  GET_ARTWORK_DETAILS,
  GET_CONTRACT_URL,
  UPDATE_ARTWORK_PRIVATE_INFO,
} from 'api/requests.v2';
import { UserRights } from 'constants/enums';
import { useCallback } from 'react';
import { Linking } from 'react-native';
import { useSelector } from 'react-redux';
import { DetailsData } from 'store/artworkDetails/apollo';
import { getUser } from 'store/user';
import { CertificationStatus, Work } from 'types';

export const useArtistCanIssueCertificate = (): boolean => {
  const user = useSelector(getUser);
  const work = useReactiveVar(DetailsData.work);

  return (
    !!work &&
    !!user &&
    work.certificationStatus === CertificationStatus.adminApproved &&
    user.id === work.artist.id
  );
};

export const useArtworkDetails = (
  userId: string | undefined,
  artworkId: string,
) => {
  const {
    loading: artworkDetailsLoading,
    data: artworkDetailsData,
    error: artworkDetailsError,
    refetch: refetchArtworkDetails,
  } = useQuery(GET_ARTWORK_DETAILS, {
    variables: {
      userId,
      artworkId,
    },
    fetchPolicy: 'cache-and-network',
  });
  return {
    artworkDetailsData,
    artworkDetailsError,
    artworkDetailsLoading,
    refetchArtworkDetails,
  };
};

export const useCanAccessTransaction = (): boolean => {
  const isOwner = useGetIsOwner();
  const user = useSelector(getUser);
  const work = useReactiveVar(DetailsData.work);

  if (
    work?.certificationStatus === CertificationStatus.adminApproved &&
    user?.id !== work.artist.id
  )
    return false;

  if (
    user &&
    user.isSubUser &&
    user.rights &&
    user.rights.includes(UserRights.Transactions)
  ) {
    return true;
  }

  return isOwner;
};

export const useGetArtistName = (): string => {
  const work = useReactiveVar(DetailsData.work);
  return (
    work?.artist?.artistName ||
    `${work?.artist?.firstName} ${work?.artist?.lastName}` ||
    'No artist name'
  );
};

export const useGetContract = (transactionId: string) => {
  const [getContract, { error: getContractError }] = useLazyQuery(
    GET_CONTRACT_URL,
    {
      variables: {
        transactionId,
      },
      onCompleted: (data) => {
        Linking.openURL(data.response);
      },
    },
  );
  return {
    getContract,
    getContractError,
  };
};

export const useGetIsOwner = (): boolean => {
  const user = useSelector(getUser);
  const work = useReactiveVar(DetailsData.work);

  return (
    !!user &&
    (user.id === work?.owner.id ||
      user?.isAdmin ||
      (user.isSubUser && user.mainUser === work?.owner.id) ||
      (user.isArtist &&
        work?.certificationStatus === CertificationStatus.adminApproved &&
        work?.artist?.id === user.id))
  );
};

export const useConsignView = (): boolean => {
  const user = useSelector(getUser);
  const work = useReactiveVar(DetailsData.work);

  if (!work || !user) return false;
  return (
    work.consignment &&
    work.consignment.filter((c) => c.consignor === user.id).length > 0
  );
};

export const useCorrectConsignment = (
  work: Work | undefined,
  userId: string | undefined,
) => {
  if (!work || !userId) {
    return null;
  }
  return work.consignment
    ?.filter((consignment) => consignment.consignor === userId)
    .pop();
};

export const useUpdateArtworkPrivateInfo = (
  workId: string | undefined,
  userId: string | undefined,
  tempSeller: string,
  tempPurchaseAmount: string,
  tempPurchaseDate: string,
  tempLocation: string,
  tempPrivateNotes: string,
  work: Work | null,
) => {
  const [updateArtworkPrivateInfo, { error: updateArtworkPrivateInfoError }] =
    useMutation(UPDATE_ARTWORK_PRIVATE_INFO);

  const { refetch: refetchArtworkDetails } = useQuery(GET_ARTWORK_DETAILS, {
    variables: {
      userId,
      artworkId: workId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      DetailsData.work(data.response.work);
    },
  });

  const updateArtworkPrivateInfoHandler = useCallback(async () => {
    if (work) {
      const workVariables = {
        updateArtworkPrivateInfoInput: {
          workId,
          userId,
          seller: tempSeller,
          purchaseAmount: tempPurchaseAmount,
          purchaseDate: tempPurchaseDate,
          location: tempLocation,
          privateNotes: tempPrivateNotes,
        },
      };
      await updateArtworkPrivateInfo({
        variables: {
          ...workVariables,
        },
      }).catch((err) => err);
      refetchArtworkDetails();
    }
  }, [
    work,
    workId,
    userId,
    tempSeller,
    tempPurchaseAmount,
    tempPurchaseDate,
    tempLocation,
    tempPrivateNotes,
    updateArtworkPrivateInfo,
    refetchArtworkDetails,
  ]);
  return { updateArtworkPrivateInfoHandler, updateArtworkPrivateInfoError };
};
