import { ENV } from 'constants/constants';
import React, { FunctionComponent, ReactNode } from 'react';
import { Platform, SafeAreaView, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { EasterEgg } from '../button/EasterEgg';
import { Footer } from '../footer';
import { SpinLoader } from '../loader';
import { useAppStarter } from './AppStarter';

interface IBasicPageProps {
  children?: ReactNode;
}

export const BasicPage: FunctionComponent<IBasicPageProps> = ({ ...props }) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const appReady = useAppStarter();

  return (
    <SafeAreaView style={TailwindResponsive(`flex-1 w-full bg-Light1`)}>
      <View
        style={TailwindResponsive(
          `bg-Light1 flex-1 p-16 relative mob:p-4 mobWeb:p-4`,
        )}
      >
        {Platform.OS === 'web' && ENV !== 'PRODUCTION' && <EasterEgg />}
        {appReady ? props.children : <SpinLoader />}
      </View>
      {Platform.OS === 'web' && <Footer />}
    </SafeAreaView>
  );
};
