/* eslint-disable react/no-unstable-nested-components */
import { ChaliceIcon } from 'components/graphics';
import { alignTextFromEnum, TextAlignment } from 'constants/enums';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Platform, View } from 'react-native';
import DropDownPicker, {
  DropDownDirectionType,
  ItemType,
  ValueType,
} from 'react-native-dropdown-picker';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { GraphikTextTiny } from '../styled';

interface IOptionPickerProps {
  absolute?: boolean;
  alignment?: TextAlignment;
  bold?: boolean;
  defaultValue: ValueType;
  dropDownDirection?: DropDownDirectionType;
  label?: string;
  options: ItemType<string | number>[];
  onChange: (input: string) => void;
  placeholder?: string;
  showIcon?: boolean;
  value: ValueType;
}

export const OptionPicker: FunctionComponent<IOptionPickerProps> = ({
  absolute,
  alignment = TextAlignment.Center,
  bold = false,
  defaultValue,
  dropDownDirection,
  label,
  options,
  onChange,
  placeholder,
  showIcon = false,
  value,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<ValueType | null>(
    value || defaultValue || null,
  );
  const [items, setItems] = useState(options);
  const zIndex = useMemo(() => (!open ? 'z-40' : 'z-50'), [open]);
  const sharedStyles = useMemo(
    () => `bg-Light1 rounded-none z-40 ${zIndex}`,
    [zIndex],
  );
  const iconPosition = useMemo(
    () =>
      absolute ? `${sharedStyles} absolute -top-3 -right-3` : sharedStyles,
    [absolute, sharedStyles],
  );

  useEffect(() => {
    if (selected) {
      onChange(selected?.toString() || defaultValue.toString());
    }
  }, [defaultValue, onChange, selected]);

  return (
    <View
      style={{
        ...TailwindResponsive(`${sharedStyles} flex relative`),
        ...(Platform.OS !== 'android' && { zIndex: !open ? 40 : 50 }),
      }}
    >
      <DropDownPicker
        autoScroll
        ArrowDownIconComponent={() => (
          <View
            pointerEvents="none"
            style={{
              ...TailwindResponsive(`${iconPosition}`),
            }}
          >
            <ChaliceIcon width={25} />
          </View>
        )}
        ArrowUpIconComponent={() => (
          <View
            pointerEvents="none"
            style={{
              ...TailwindResponsive(`${iconPosition}`),
            }}
          >
            <ChaliceIcon width={25} inverted />
          </View>
        )}
        containerStyle={{
          ...TailwindResponsive(
            `${sharedStyles} flex-col h-16 items-center w-full `,
          ),
        }}
        dropDownContainerStyle={{
          ...TailwindResponsive(
            `${sharedStyles} border-0.5 h-48 m-0 p-0 rounded-none w-full`,
          ),
          elevation: 1000,
        }}
        dropDownDirection={dropDownDirection || 'DEFAULT'}
        items={items}
        itemKey="value"
        itemSeparator
        itemSeparatorStyle={{
          ...TailwindResponsive(`bg-Dark1 w-full ${zIndex}`),
        }}
        listItemContainerStyle={{
          ...TailwindResponsive(
            `${sharedStyles} flex-row h-16 items-center pl-6 pr-6`,
          ),
        }}
        listMessageContainerStyle={{
          ...TailwindResponsive(`${sharedStyles}`),
        }}
        listMode="FLATLIST"
        listParentContainerStyle={{
          ...TailwindResponsive(`${sharedStyles}`),
        }}
        mode="BADGE"
        open={open}
        placeholder={placeholder || value.toString() || 'Select an option'}
        setItems={setItems}
        setOpen={() => setOpen(!open)}
        setValue={(input) => {
          setSelected(input);
        }}
        showArrowIcon={showIcon}
        arrowIconStyle={TailwindResponsive(
          `flex-row flex-wrap h-16 items-center justify-center ml-2 w-6 ${zIndex}`,
        )}
        showTickIcon={false}
        style={{
          ...TailwindResponsive(
            `${sharedStyles} border-0.5 border-black flex-row h-16 items-center ${
              alignTextFromEnum(alignment) === 'center'
                ? 'pl-3 pr-8'
                : 'pl-6 pr-6'
            } relative w-full`,
          ),
        }}
        textStyle={{
          textAlign: alignTextFromEnum(alignment),
          fontSize: Styles.Fonts.FontSize.Small,
          fontFamily: bold
            ? Styles.Fonts.FontFamily.SansSemiBold
            : Styles.Fonts.FontFamily.SansRegular,
        }}
        value={selected}
      />
      {!!label && (
        <GraphikTextTiny
          color={Styles.Colours.Gray2}
          style={TailwindResponsive(`mt-1`)}
        >
          {label}
        </GraphikTextTiny>
      )}
    </View>
  );
};
