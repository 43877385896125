import step3 from 'assets/images/onboarding/step3.png';
import { GraphikMediumTextMd, GraphikTextMd } from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { Image, Platform, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ColOnboardingScreen4: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View style={TailwindResponsive(`items-center`)}>
      <View style={TailwindResponsive(`mb-4`)}>
        {Platform.OS === 'web' ? (
          <img src={step3} style={TailwindResponsive(`h-52`)} />
        ) : (
          <Image
            source={step3}
            style={{ height: 200, width: 200 }}
            resizeMode="contain"
          />
        )}
      </View>

      <GraphikMediumTextMd style={TailwindResponsive(`mb-4`)}>
        3 - Maintain
      </GraphikMediumTextMd>

      <GraphikTextMd>
        Manage all aspects of your collection in one elegant and easy to use
        interface. Easily track where your work is stored, get the latest artist
        insights to protect your investments, and even connect with curators in
        our network for institutional loans.
      </GraphikTextMd>
    </View>
  );
};

export default ColOnboardingScreen4;
