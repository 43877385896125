import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { TextButton } from 'components/shared/button';
import { WorkflowPage } from 'components/shared/page';
import { AssetType } from 'constants/enums';
import { FCStackNavProp } from 'navigation';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useSelector } from 'react-redux';
import { RegisterStore } from 'store/registerWork/apollo';
import { getUser } from 'store/user';
import { useUnload } from 'utilities/Hooks';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useResetAll, useSetParams } from './Hooks';
import RWErrorBanner from './components/ErrorBanner';
import RWHeader from './components/Header';
import RWMain from './components/RWMain';
import RCMain from './components/collectibles/RCMain';
import { useSetupRegistrationForm } from './forms/artist-registration.form';
import { useSetupRoyaltyForm } from './forms/royalty.form';
import { useGetSavedRegistration } from './hooks.auto-save';

export const RegisterWork: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const user = useSelector(getUser);
  const navigation = useNavigation<FCStackNavProp>();

  const [assetType, setAssetType] = useState(AssetType.Artwork);
  const [pageTitle, setPageTitle] = useState('Register Artwork');

  const currentStep = useReactiveVar(RegisterStore.Vars.currentStep);

  const [RegistrationFlow, setRegistrationFlow] =
    useState<FunctionComponent | null>(null);
  const [RegistrationButton, setRegistrationButton] =
    useState<FunctionComponent | null>(null);

  const registerArtworkButton = () => (
    <TextButton
      label="Register an Artwork"
      onPress={() => {
        setAssetType(AssetType.Artwork);
      }}
    />
  );

  const registerCollectibleButton = () => (
    <TextButton
      label="Register a Collectible/Product"
      onPress={() => {
        setAssetType(AssetType.Collectible);
      }}
    />
  );

  useSetupRegistrationForm(assetType);
  useSetupRoyaltyForm();

  const resetAll = useResetAll();
  const setParams = useSetParams();
  const getSave = useGetSavedRegistration();

  // Only mass reset on first entry
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      resetAll();
      setParams();
      getSave(); // This must be last
    });

    return unsubscribe;
  }, [resetAll, setParams, getSave, navigation]);

  useUnload((e: any) => {
    const navState = navigation.getState();
    if (navState.routes[navState.index]?.name === 'RegisterWork') {
      e.preventDefault();
      // eslint-disable-next-line no-param-reassign
      e.returnValue =
        'Are you sure you want to leave? You will potentially lose your current registration data.';
    }
  });

  RegisterStore.Vars.keyboardScrollRef(useRef(null));
  const keyboardScrollRef = useReactiveVar(
    RegisterStore.Vars.keyboardScrollRef,
  );

  useEffect(() => {
    if (assetType === AssetType.Artwork) {
      setRegistrationFlow(() => RWMain);
      setRegistrationButton(() => registerCollectibleButton);
      setPageTitle('Register Artwork');
    } else if (assetType === AssetType.Collectible) {
      setRegistrationFlow(() => RCMain);
      setRegistrationButton(() => registerArtworkButton);
      setPageTitle('Register Item');
    }
  }, [assetType]);

  return (
    <WorkflowPage title={pageTitle}>
      <RWHeader />
      <RWErrorBanner />
      {user && user.isBrand && currentStep === 0 && (
        <View style={TailwindResponsive(`mob:px-4`)}>
          {RegistrationButton && <RegistrationButton />}
        </View>
      )}
      <KeyboardAwareScrollView
        bounces={false}
        extraHeight={240}
        keyboardOpeningTime={0}
        enableResetScrollToCoords={false}
        contentContainerStyle={{
          flexGrow: 1,
        }}
        showsVerticalScrollIndicator={false}
        style={TailwindResponsive(`mob:-mx-4 flex-1`)}
        ref={keyboardScrollRef}
      >
        <View style={TailwindResponsive(`mob:px-4 mob:h-full flex-1`)}>
          {RegistrationFlow && <RegistrationFlow />}
        </View>
      </KeyboardAwareScrollView>
    </WorkflowPage>
  );
};

export default RegisterWork;
