import { useNavigation } from '@react-navigation/native';
import { Footer } from 'components/shared/footer';
import { MainHeader } from 'components/shared/header';
import { FCStackNavProp } from 'navigation';
import React, { FunctionComponent, ReactNode } from 'react';
import { Platform, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ExitPopUpButton } from '../button';
import { SpinLoader } from '../loader';
import { GraphikTextHuge } from '../styled';
import { useAppStarter } from './AppStarter';

interface IWorkflowPageProps {
  showBorder?: boolean;
  title?: string;
  exitButton?: boolean;
  hideIosBorder?: boolean;
  children?: ReactNode;
}

export const WorkflowPage: FunctionComponent<IWorkflowPageProps> = ({
  children,
  exitButton,
  hideIosBorder,
  showBorder,
  title,
}) => {
  const appReady = useAppStarter();
  const borderStyles = showBorder ? 'border border-b border-Dark1' : '';
  const navigation = useNavigation<FCStackNavProp>();
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <SafeAreaView style={TailwindResponsive(`bg-Light1 flex-1`)}>
      <View
        style={TailwindResponsive(`bg-Light1 flex-1 p-16 mobWeb:p-8 mob:p-4`)}
      >
        <MainHeader title={title} hideIosBorder={hideIosBorder}>
          {Platform.OS === 'ios' ? (
            <View
              style={[TailwindResponsive('flex-row'), { marginLeft: 'auto' }]}
            >
              {exitButton ? (
                <ExitPopUpButton
                  onPress={() => {
                    navigation.goBack();
                  }}
                />
              ) : (
                <Text
                  onPress={() => {
                    navigation.goBack();
                  }}
                >
                  Cancel
                </Text>
              )}
            </View>
          ) : (
            <Text
              style={[TailwindResponsive('flex-row'), { marginLeft: 'auto' }]}
              onPress={() => {
                navigation.goBack();
              }}
            >
              Cancel
            </Text>
          )}
        </MainHeader>

        {!!title && Platform.OS === 'web' && (
          <View
            style={{
              ...TailwindResponsive(`pb-1.5 ${borderStyles}`),
            }}
          >
            <GraphikTextHuge>{title}</GraphikTextHuge>
          </View>
        )}

        {appReady ? children : <SpinLoader />}
      </View>
      {Platform.OS === 'web' && <Footer />}
    </SafeAreaView>
  );
};

export default WorkflowPage;
