import {
  GraphikSemiTextMd,
  GraphikTextMd,
  GraphikTextSm,
} from 'components/shared/styled';
import { differenceInYears, format } from 'date-fns';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';
import { CertificationStatus, WorkWithObject, getDisplayName } from 'types';
import {
  formatEditionText,
  getCombinedRoyalty,
  getWorkDimensions,
  isCollectible,
} from 'utilities';
import { formatRoyaltyCopy } from 'utilities/Royalties';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IArtworkViewProps {
  index: number;
  style?: { [key: string]: string };
  transactionDate: string;
  work: WorkWithObject;
}

export const ArtworkView: FunctionComponent<IArtworkViewProps> = ({
  index,
  style,
  transactionDate,
  work,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const [formattedDiminishedDate, setFormattedDiminishedDate] = useState(0);

  const transaction = useGetTransactionDetailsHook();

  const dimensions = getWorkDimensions(work);
  const formattedStyle = style || {};
  const assetIsCollectible = isCollectible(work);
  const assetLabel = assetIsCollectible ? 'Item' : 'Artwork';

  const itemStyle = `border-b border-Dark6 flex flex-row py-1 items-center justify-between mobWeb:flex-row mob:flex-row mob:py-2`;

  const doesDiminish = work.royalties.find(
    (royalty) => royalty.diminishedValue,
  );

  const diminishedStartDate = doesDiminish
    ? format(new Date(work.dateOfFirstTransaction), 'MM/dd/yyyy')
    : format(new Date(transactionDate), 'MM/dd/yyyy');

  useEffect(() => {
    if (doesDiminish) {
      const diminishedDate = new Date(doesDiminish.diminishedDate!);
      const difference = work.dateOfFirstTransaction
        ? differenceInYears(
            diminishedDate,
            new Date(work.dateOfFirstTransaction),
          )
        : differenceInYears(diminishedDate, new Date(transactionDate));

      setFormattedDiminishedDate(difference);
    }
  }, [doesDiminish, work, transactionDate]);

  return (
    <View style={formattedStyle}>
      <View
        style={TailwindResponsive(
          `pb-2 border-b border-Dark6 mob:border-Dark4`,
        )}
      >
        <GraphikTextSm>
          {transaction?.isMultiWork
            ? `${assetLabel} #${index + 1}`
            : assetLabel}
        </GraphikTextSm>
      </View>

      <View>
        <View style={TailwindResponsive(`${itemStyle}`)}>
          <GraphikSemiTextMd style={TailwindResponsive(`pr-6`)}>
            {getDisplayName(work.artist)}
          </GraphikSemiTextMd>
          <GraphikTextSm>{`${
            assetIsCollectible ? 'Brand' : 'Artist'
          }`}</GraphikTextSm>
        </View>
        <View style={TailwindResponsive(itemStyle)}>
          <GraphikSemiTextMd style={TailwindResponsive(`pr-6`)}>
            {work.title}
          </GraphikSemiTextMd>
          <GraphikTextSm>Title</GraphikTextSm>
        </View>
        <View style={TailwindResponsive(itemStyle)}>
          <GraphikSemiTextMd style={TailwindResponsive(`pr-6`)}>
            {work.yearProduced}
          </GraphikSemiTextMd>
          <GraphikTextSm>Year</GraphikTextSm>
        </View>

        {!!formatEditionText(work) && (
          <View style={TailwindResponsive(itemStyle)}>
            <GraphikSemiTextMd style={TailwindResponsive(`pr-6`)}>
              {formatEditionText(work)}
            </GraphikSemiTextMd>
            <GraphikTextSm>Edition</GraphikTextSm>
          </View>
        )}

        {!!dimensions && !assetIsCollectible && (
          <View style={TailwindResponsive(itemStyle)}>
            <GraphikSemiTextMd style={TailwindResponsive(`pr-6`)}>
              {dimensions}
            </GraphikSemiTextMd>
            <GraphikTextSm>Dimensions</GraphikTextSm>
          </View>
        )}

        {!assetIsCollectible && (
          <View style={TailwindResponsive(itemStyle)}>
            <GraphikSemiTextMd style={TailwindResponsive(`pr-6`)}>
              {work.medium}
            </GraphikSemiTextMd>
            <GraphikTextSm>Medium</GraphikTextSm>
          </View>
        )}
        {assetIsCollectible && (
          <View style={TailwindResponsive(itemStyle)}>
            <GraphikSemiTextMd style={TailwindResponsive(`pr-6`)}>
              {work.collectible?.materials}
            </GraphikSemiTextMd>
            <GraphikTextSm>Materials/Fabric</GraphikTextSm>
          </View>
        )}
        {assetIsCollectible && (
          <View style={TailwindResponsive(itemStyle)}>
            <GraphikSemiTextMd style={TailwindResponsive(`pr-6`)}>
              {work.collectible?.measurements}
            </GraphikSemiTextMd>
            <GraphikTextSm>Size/Measurements</GraphikTextSm>
          </View>
        )}
        <View style={TailwindResponsive(itemStyle)}>
          <GraphikSemiTextMd style={TailwindResponsive(`pr-6`)}>
            {work.workIdentifier}
          </GraphikSemiTextMd>
          <GraphikTextSm>{`Fairchain ${
            assetIsCollectible ? 'Item' : 'Artwork'
          } ID`}</GraphikTextSm>
        </View>

        {(work.certified ||
          work.certificationStatus === CertificationStatus.artistApproved) && (
          <View style={TailwindResponsive(itemStyle)}>
            {doesDiminish ? (
              <>
                <Text
                  style={{
                    ...TailwindResponsive(`text-left`),
                    maxWidth: '40%',
                  }}
                >
                  <GraphikSemiTextMd>
                    {getCombinedRoyalty(work.royalties, false)}%{' '}
                  </GraphikSemiTextMd>
                  <GraphikTextMd>for the first </GraphikTextMd>
                  <GraphikSemiTextMd>
                    {formattedDiminishedDate}{' '}
                    {formatRoyaltyCopy(formattedDiminishedDate)}{' '}
                  </GraphikSemiTextMd>
                  <GraphikTextMd>following </GraphikTextMd>
                  <GraphikSemiTextMd>{diminishedStartDate} </GraphikSemiTextMd>
                  <GraphikTextMd>and </GraphikTextMd>
                  <GraphikSemiTextMd>
                    {getCombinedRoyalty(work.royalties, true)}%{' '}
                  </GraphikSemiTextMd>
                  <GraphikTextMd>thereafter</GraphikTextMd>
                </Text>
                <GraphikTextSm>Resale Commission</GraphikTextSm>
              </>
            ) : (
              <>
                <GraphikSemiTextMd>
                  {`${getCombinedRoyalty(work.royalties, false)}%`}
                </GraphikSemiTextMd>
                <GraphikTextSm>Resale Commission</GraphikTextSm>
              </>
            )}
          </View>
        )}
      </View>
    </View>
  );
};

export default ArtworkView;
