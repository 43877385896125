import { useLazyQuery } from '@apollo/client';
import { GET_PUBLIC_URL } from 'api/requests.v2';
import LockIcon from 'components/graphics/LockIcon';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { GraphikSemiTextMd } from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { Linking, View } from 'react-native';
import Styles from 'style';
import { Work } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IADSupportDocsProps {
  work: Work;
}

export const ADSupportingDocuments: FunctionComponent<IADSupportDocsProps> = ({
  work,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const [getPublicUrl] = useLazyQuery(GET_PUBLIC_URL);

  const handleDocPress = async (docUrl: string) => {
    const response = await getPublicUrl({
      variables: { url: docUrl },
    });
    if (response && response.data && response.data.response)
      Linking.openURL(response.data.response);
  };

  if (!work.supportingDocUris || work.supportingDocUris.length === 0)
    return null;

  return (
    <>
      <GraphikSemiTextMd
        style={TailwindResponsive('mb-4 mt-8 border-b border-Gray2')}
      >
        Supporting Documents
      </GraphikSemiTextMd>

      {work.supportingDocUris.map((doc) => {
        const theSplit = doc.uri.split('/');
        const docName = theSplit[theSplit.length - 1] || '';
        return (
          <View style={TailwindResponsive(`flex flex-row items-center`)}>
            <FairchainButton
              buttonStyle={TailwindResponsive(`h-8 mr-2 self-start`)}
              label={docName}
              onPress={() => {
                handleDocPress(doc.uri);
              }}
              textStyle={TailwindResponsive(`underline`)}
            />

            {doc.transferable ? (
              <LockIcon color={Styles.Colours.Light1} width={18} />
            ) : (
              <LockIcon color={Styles.Colours.Dark1} width={18} />
            )}
          </View>
        );
      })}
    </>
  );
};
