import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'types';

export interface IUserState {
  currentUser: User | null;
  estateUser: User | null;
  hasBeen2FAD: boolean;
  mainUser: User | null;
  profile: IProfileInfo;
}

export interface IIntegrationErrors {
  subUser: string;
  stripe: string;
}

interface IProfileInfo {
  stripeLoading: boolean;
  subUsers: User[];
  subUsersLoading: boolean;
}

const initialProfileState: IProfileInfo = {
  stripeLoading: true,
  subUsers: [],
  subUsersLoading: true,
};

const initialState: IUserState = {
  currentUser: null,
  estateUser: null,
  hasBeen2FAD: false,
  mainUser: null,
  profile: initialProfileState,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    activateSubscription: (state, action: PayloadAction<boolean>) => {
      if (state.currentUser) {
        state.currentUser.firstLogin = false;
        state.currentUser.subscriptionActive = action.payload;
      }
    },
    agreeToTerms: (state, action: PayloadAction<User>) => {
      if (state.currentUser) {
        state.currentUser.agreedTimeStamp = action.payload.agreedTimeStamp;
        if (!state.currentUser.isArtist || state.currentUser.verified) {
          state.currentUser.firstLogin = false;
        }
      }
    },
    removeSubUser: (state, action: PayloadAction<string>) => {
      const newSubUsers = state.profile.subUsers.filter(
        (user) => user.id !== action.payload,
      );
      state.profile.subUsers = newSubUsers;
    },
    setEstateUser: (state, action: PayloadAction<User>) => {
      state.estateUser = action.payload;
    },
    setMainUser: (state, action: PayloadAction<User>) => {
      state.mainUser = action.payload;
    },
    setPublicCertificates: (state, action: PayloadAction<boolean>) => {
      if (state.currentUser) {
        state.currentUser.enablePublicCerts = action.payload;
      }
    },
    setPublicProvenance: (state, action: PayloadAction<boolean>) => {
      if (state.currentUser) {
        state.currentUser.enablePublicProvenance = action.payload;
      }
    },
    setSeenOnboarding: (state, action: PayloadAction<boolean>) => {
      if (state.currentUser) {
        state.currentUser.hasSeenCollectorOnboarding = action.payload;
        state.currentUser.hasSeenOnboarding = action.payload;
      }
    },
    setSignatureTemp: (state, action: PayloadAction<string>) => {
      if (state.currentUser) state.currentUser.signatureTemp = action.payload;
    },
    setStripeAccountStatus: (state, action: PayloadAction<boolean>) => {
      if (state.currentUser)
        state.currentUser.stripeAccountIsSetup = action.payload;
    },
    setStripeLoading: (state, action: PayloadAction<boolean>) => {
      if (state.profile) {
        state.profile.stripeLoading = action.payload;
      } else {
        state.profile = initialProfileState;
      }
    },
    setSubScriptionStatus: (state, action: PayloadAction<boolean>) => {
      if (state.currentUser) {
        state.currentUser.subscriptionActive = action.payload;
      }
    },
    setSubUsers: (state, action: PayloadAction<User[]>) => {
      state.profile.subUsers = action.payload;
    },
    setSubUsersLoading: (state, action: PayloadAction<boolean>) => {
      if (state.profile) {
        state.profile.subUsersLoading = action.payload;
      } else {
        state.profile = initialProfileState;
      }
    },
    setValidAuthToken: (state, action: PayloadAction<boolean>) => {
      state.hasBeen2FAD = action.payload;
    },
    setVeriffId: (state, action: PayloadAction<string>) => {
      state.currentUser!.veriffId = action.payload;
    },
    submittedVerification: (state, action: PayloadAction<string>) => {
      if (state.currentUser) {
        state.currentUser.firstLogin = false;
        state.currentUser.veriffId = action.payload;
        state.currentUser.verified = false;
      }
    },
    userConnect: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
    },
    userDisconnect: (state) => {
      state.currentUser = initialState.currentUser;
      state.estateUser = initialState.estateUser;
      state.hasBeen2FAD = initialState.hasBeen2FAD;
      state.mainUser = initialState.mainUser;
      state.profile = initialProfileState;
    },
    userUpdate: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
    },
    userUpdateFirstLogin: (state, action: PayloadAction<boolean>) => {
      state.currentUser!.firstLogin = action.payload;
    },
    updateTwoFactorEnabled: (state, action: PayloadAction<boolean>) => {
      state.hasBeen2FAD = action.payload;
      state.currentUser!.twoFactorEnabled = action.payload;
    },
  },
});

const { actions, reducer } = userSlice;
export const {
  activateSubscription,
  agreeToTerms,
  removeSubUser,
  setEstateUser,
  setMainUser,
  setPublicCertificates,
  setPublicProvenance,
  setSeenOnboarding,
  setSignatureTemp,
  setStripeAccountStatus,
  setStripeLoading,
  setSubScriptionStatus,
  setSubUsers,
  setSubUsersLoading,
  setValidAuthToken,
  setVeriffId,
  submittedVerification,
  userConnect,
  userDisconnect,
  userUpdate,
  userUpdateFirstLogin,
  updateTwoFactorEnabled,
} = actions;
export { reducer as userReducer };

export type UserState = ReturnType<typeof reducer>;
export type UserAction =
  | typeof activateSubscription
  | typeof agreeToTerms
  | typeof removeSubUser
  | typeof setEstateUser
  | typeof setMainUser
  | typeof setPublicCertificates
  | typeof setPublicProvenance
  | typeof setSeenOnboarding
  | typeof setSignatureTemp
  | typeof setStripeAccountStatus
  | typeof setStripeLoading
  | typeof setSubScriptionStatus
  | typeof setSubUsers
  | typeof setSubUsersLoading
  | typeof setValidAuthToken
  | typeof setVeriffId
  | typeof submittedVerification
  | typeof userConnect
  | typeof userDisconnect
  | typeof userUpdate
  | typeof userUpdateFirstLogin
  | typeof updateTwoFactorEnabled;
