import React, { FunctionComponent } from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { getGridWorks } from 'store/collection';
import { EmptyCollection } from './EmptyCollection';
import { ListTable } from './ListTable';
import { ListTableHeader } from './ListTableHeader';

export const ListView: FunctionComponent = () => {
  const works = useSelector(getGridWorks);

  if (works && works.length === 0) return <EmptyCollection />;

  return (
    <>
      {Platform.OS === 'web' && <ListTableHeader />}
      <ListTable />
    </>
  );
};
