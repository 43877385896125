import { CollectionTab } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { getCollectionDisplayType } from 'store/collection';
import Styles from 'style';
import { ITabSelectorProps } from 'types/Props';

export const TabSelector: FunctionComponent<ITabSelectorProps> = ({
  hasConsignedWorks,
  onTabChange,
  transactionView,
}) => {
  const collectionView = useSelector(getCollectionDisplayType);

  const tabButtonStyle = {
    fontSize: Styles.Fonts.FontSize.Medium,
    marginTop: 6,
  };

  const active = {
    fontFamily: Styles.Fonts.FontFamily.SansMedium,
    color: Styles.Colours.Dark1,
  };

  const inactive = {
    fontFamily: Styles.Fonts.FontFamily.SansRegular,
    color: Styles.Colours.Dark3,
  };

  return (
    <View>
      <Text
        onPress={() => onTabChange(CollectionTab.Current)}
        style={[
          tabButtonStyle,
          collectionView === CollectionTab.Current ? active : inactive,
        ]}
      >
        Open
      </Text>
      {hasConsignedWorks && !transactionView && (
        <Text
          onPress={() => onTabChange(CollectionTab.Consigned)}
          style={[
            tabButtonStyle,
            collectionView === CollectionTab.Consigned ? active : inactive,
          ]}
        >
          Consigned
        </Text>
      )}
      <Text
        onPress={() => onTabChange(CollectionTab.Past)}
        style={[
          tabButtonStyle,
          collectionView === CollectionTab.Past ? active : inactive,
        ]}
      >
        Closed
      </Text>
    </View>
  );
};
