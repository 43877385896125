import step4 from 'assets/images/onboarding/step4.png';
import { GraphikMediumTextMd, GraphikTextMd } from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { Image, Platform, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ColOnboardingScreen5: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View style={TailwindResponsive(`items-center`)}>
      <View style={TailwindResponsive(`mb-4`)}>
        {Platform.OS === 'web' ? (
          <img src={step4} style={TailwindResponsive(`h-52`)} />
        ) : (
          <Image
            source={step4}
            style={{ height: 200, width: 200 }}
            resizeMode="contain"
          />
        )}
      </View>

      <GraphikMediumTextMd style={TailwindResponsive(`mb-4`)}>
        4 - Transfer
      </GraphikMediumTextMd>

      <GraphikTextMd>
        Sell, consign or gift works in your collection to others with clear
        title transfer. Plus, take advantage of integrated payment processing
        options including Stripe, Klarna WeChat Pay and more.
      </GraphikTextMd>
    </View>
  );
};

export default ColOnboardingScreen5;
