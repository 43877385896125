import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import React, { FunctionComponent } from 'react';
import { Platform } from 'react-native';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';
import Styles from 'style';
import { StripePromise } from 'utilities/Stripe';
import ACHInfo from './ACHInfo';
import InvoiceButton from './InvoiceButton';
import PaymentFLow from './PaymentFlow';

export const OfferAcceptance: FunctionComponent = () => {
  const transaction = useGetTransactionDetailsHook();

  const customAppearance = {
    variables: {
      colorPrimary: Styles.Colours.Primary,
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: Styles.Fonts.FontFamily.SansRegular,
      fontLineHeight: `${Styles.Fonts.LineHeights.Regular.toString()}px`,
      spacingUnit: '4px',
      borderRadius: '0px',
    },
    rules: {
      '.TabLabel': {
        fontWeight: 'normal',
      },
      '.TabLabel--selected': {
        fontWeight: 'bold',
      },
      '.Input:focus': {
        borderColor: Styles.Colours.Primary,
      },
    },
  };

  const options: StripeElementsOptions = {
    clientSecret: transaction?.stripePaymentIntentId,
    appearance: customAppearance,
  };

  if (!transaction?.hasPayment || !transaction.isBuyerView) return null;

  if (Platform.OS !== 'web')
    return (
      <>
        <InvoiceButton />
        {transaction?.stripePaymentFlowReady && (
          <Elements options={options} stripe={StripePromise}>
            <PaymentFLow />
          </Elements>
        )}
      </>
    );

  return (
    <>
      <InvoiceButton />

      {transaction?.stripePaymentFlowReady && (
        <Elements options={options} stripe={StripePromise}>
          <PaymentFLow />
          <ACHInfo />
        </Elements>
      )}
    </>
  );
};

export default OfferAcceptance;
