import React, { FunctionComponent } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface ButtonFooterProps {
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

export const ButtonFooter: FunctionComponent<ButtonFooterProps> = ({
  children,
  style,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View
      style={[
        TailwindResponsive(
          'flex-row justify-between h-28 bg-white absolute inset-x-0 bottom-0',
        ),
        style,
      ]}
    >
      {children}
    </View>
  );
};
