import { useReactiveVar } from '@apollo/client';
import { GraphikMediumTextSm } from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { TextInput, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { buyerRegistrationFormVar } from 'store/transferWork/apollo';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IAdditionalInfoProps {
  title?: string;
  name: any;
  keyboardScrollRef: React.RefObject<KeyboardAwareScrollView>;
  returnKeyType?: 'next' | 'done';
  placeholder?: string;
}

export const AdditionalInformation: FunctionComponent<IAdditionalInfoProps> = ({
  title,
  name,
  keyboardScrollRef,
  returnKeyType,
  placeholder,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const buyerForm = useReactiveVar(buyerRegistrationFormVar);
  const control = buyerForm?.control;

  return (
    <View style={TailwindResponsive('flex-1 mt-14 mob:mt-6')}>
      <View style={TailwindResponsive('mb-20 mob:mb-4')}>
        <GraphikMediumTextSm>{title}</GraphikMediumTextSm>
      </View>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <TextInput
            placeholder={placeholder}
            placeholderTextColor={Styles.Colours.Gray2}
            multiline
            style={[
              TailwindResponsive(`border-0.5 border-Dark2 h-48 p-4`),
              {
                fontSize: Styles.Fonts.FontSize.Medium,
                fontFamily: Styles.Fonts.FontFamily.SansRegular,
                lineHeight: Styles.Fonts.LineHeights.Medium,
              },
            ]}
            value={value}
            onChangeText={onChange}
            returnKeyType={returnKeyType}
            blurOnSubmit
            onBlur={() => keyboardScrollRef.current?.scrollToEnd()}
          />
        )}
      />
    </View>
  );
};

export default AdditionalInformation;
