import { FairchainLogo } from 'components/graphics';
import { HugeTitle } from 'components/shared/styled';
import React from 'react';
import { Image, View } from 'react-native';
import { User } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface Props {
  seller: User | undefined;
}

export const SellerBranding: React.FunctionComponent<Props> = ({ seller }) => {
  const { TailwindResponsive } = useTailwindResponsive();

  if (!seller) return null;

  if (seller.logo) {
    return (
      <View style={[TailwindResponsive(`max-w-xs flex-1`), { minHeight: 200 }]}>
        <Image
          source={seller.logo as any}
          style={TailwindResponsive(`flex-1 w-full mb-12`)}
          height={undefined}
          width={undefined}
          resizeMode="contain"
        />
      </View>
    );
  }

  if (seller.isGallery) {
    return (
      <HugeTitle style={TailwindResponsive(`mb-24 mob:mb-5 mobWeb:mb-5`)}>
        {seller.galleryName || `${seller.firstName} ${seller.lastName}`}
      </HugeTitle>
    );
  }

  return (
    <View style={TailwindResponsive(`mb-24 mob:mb-5 mobWeb:mb-5`)}>
      <FairchainLogo />
    </View>
  );
};
