import { useReactiveVar } from '@apollo/client';
import {
  IBuyerForm,
  ITransactionDetailsForm,
} from 'components/views/transferWork';
import { DOCUSIGN_SIGN_COMPLETE } from 'constants/docusign';
import { FCStackNavProp } from 'navigation';
import { useCallback } from 'react';
import { UseFormReset } from 'react-hook-form';
import WebView from 'react-native-webview';
import { useSelector } from 'react-redux';
import { buyerRegistrationFormVar } from 'store/transferWork/apollo';
import { getUser } from 'store/user';

export const HANDLE_SIGN_RETURN = 5;

export interface INavStateChangeProps {
  setWebviewOpen: (boolean) => void;
  navigation: FCStackNavProp;
  webviewRef: React.RefObject<WebView>;
  resetBuyerForm?: UseFormReset<IBuyerForm>;
  resetTransactionDetailsForm?: UseFormReset<ITransactionDetailsForm>;
  setCurrentStep?: (step: number) => void;
  currentStep?: number;
  stripeRefetch?: () => void;
}

export const useWebViewNavigationStateChange = ({
  setWebviewOpen,
  navigation,
  webviewRef,
  resetTransactionDetailsForm,
  setCurrentStep,
  currentStep,
  stripeRefetch,
}: INavStateChangeProps) => {
  const buyerForm = useReactiveVar(buyerRegistrationFormVar);
  const user = useSelector(getUser);

  const webViewHandler = useCallback(
    (newNavState) => {
      const { url } = newNavState;
      if (!url) return;

      if (url.includes('fairchain')) {
        setWebviewOpen(false);
        webviewRef?.current?.stopLoading();
        if (currentStep === HANDLE_SIGN_RETURN) {
          const transactionIdx = url.indexOf('?transactionId=');
          const eventIdx = url.indexOf('&event=');
          const transactionId = url.substring(
            transactionIdx + '?transactionId='.length,
            eventIdx,
          );
          if (setCurrentStep) {
            setCurrentStep(0);
          }
          if (buyerForm && resetTransactionDetailsForm) {
            buyerForm.reset();
            resetTransactionDetailsForm();
          }
          navigation.push('TransactionSignReturn', {
            transactionId,
            userId: user!.id,
            event: DOCUSIGN_SIGN_COMPLETE,
          });
        } else if (stripeRefetch) {
          stripeRefetch();
        }
      }
    },
    [
      buyerForm,
      user,
      currentStep,
      navigation,
      resetTransactionDetailsForm,
      setCurrentStep,
      setWebviewOpen,
      stripeRefetch,
      webviewRef,
    ],
  );
  return webViewHandler;
};
