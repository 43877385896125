import { useQuery, useSubscription } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { GET_NAVIGATION_INFO, NAV_INFO_SUBSCRIPTION } from 'api/requests.v2';
import AccountActive from 'assets/images/AccountActive.png';
import ProfileNav from 'components/views/profile/ProfileNav';
import { CollectionTab, UserRole } from 'constants/enums';
import { UserContext } from 'contexts';
import React, { FunctionComponent, useContext, useState } from 'react';
import { Pressable, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { setCollectionDisplay } from 'store/collection';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { RoundedButton } from '../button';
import { GraphikMediumTextMd } from '../styled';
import NavItem from './NavItem';
import HeaderVars from './apollo.header';
import { useGetNumUserTransactions, useHandleRegisterWork } from './hooks';

interface IMainMenuNavigationProps {
  userRole: number;
}

export const MainMenuNavigation: FunctionComponent<
  IMainMenuNavigationProps
> = ({ userRole }) => {
  const authUser = useContext(UserContext);
  const dispatch = useDispatch();
  const isMobileWeb = useMediaQuery({ maxWidth: 768 });
  const navigation = useNavigation();
  const route = useRoute();
  const { TailwindResponsive } = useTailwindResponsive();

  const numTransactions = useGetNumUserTransactions();

  const handleRegisterWork = useHandleRegisterWork();

  const handleButtonPress = (navigateTo: any) => {
    navigation.navigate(navigateTo);
    HeaderVars.showMenuModal(false);
  };

  const [navInfo, setNavInfo] = useState({
    unviewedTransactions: 0,
    transfersToInitiate: 0,
  });

  useQuery(GET_NAVIGATION_INFO, {
    variables: {
      userId: authUser?.uid,
    },
    onCompleted: ({ getNavigationInfo }) => {
      setNavInfo(getNavigationInfo);
    },
    skip: !authUser,
  });

  useSubscription(NAV_INFO_SUBSCRIPTION, {
    variables: {
      userId: authUser?.uid,
    },
    onData: ({ data }) => {
      let transUnViewed =
        navInfo.unviewedTransactions + data.data.navUpdate.unviewedTransactions;
      let transToInitiate =
        navInfo.transfersToInitiate + data.data.navUpdate.transfersToInitiate;

      if (transUnViewed < 0) transUnViewed = 0;
      if (transToInitiate < 0) transToInitiate = 0;

      setNavInfo({
        unviewedTransactions: transUnViewed,
        transfersToInitiate: transToInitiate,
      });
    },
  });

  return (
    <>
      <View
        style={TailwindResponsive(
          'flex flex-row mobWeb:flex-col mobWeb:items-start mobWeb:mx-2',
        )}
      >
        <NavItem
          amount={0}
          label="Collection"
          onPress={() => {
            dispatch(setCollectionDisplay(CollectionTab.Current));
            handleButtonPress('Collection');
          }}
        />
        {numTransactions > 0 && (
          <NavItem
            label="Transactions"
            amount={navInfo.transfersToInitiate}
            onPress={() => {
              dispatch(setCollectionDisplay(CollectionTab.Current));
              handleButtonPress('Transactions');
            }}
          />
        )}
        <NavItem
          amount={navInfo.unviewedTransactions}
          label="Inbox"
          onPress={() => handleButtonPress('Inbox')}
        />
        {userRole === UserRole.Admin && (
          <NavItem
            amount={0}
            label="Admin"
            onPress={() => handleButtonPress('Admin')}
          />
        )}

        <Pressable
          onPress={() => handleButtonPress('Profile')}
          style={TailwindResponsive(
            `${
              route.name === 'Profile' ? 'border-b' : ''
            } items-center justify-center ml-2 mobWeb:border-none mobWeb:flex mobWeb:flex-row mobWeb:p-4`,
          )}
        >
          <img src={AccountActive} style={TailwindResponsive(`h-6`)} />
          {isMobileWeb && (
            <GraphikMediumTextMd style={TailwindResponsive(`ml-4`)}>
              Account
            </GraphikMediumTextMd>
          )}
        </Pressable>

        {route.name === 'Profile' && (
          <View style={TailwindResponsive(`hidden mobWeb:block`)}>
            <ProfileNav />
          </View>
        )}
      </View>

      <View style={TailwindResponsive('mt-auto px-4 pb-24 hidden mobWeb:flex')}>
        <View style={TailwindResponsive(`w-full`)}>
          <RoundedButton
            label="Register Artwork"
            backgroundColor={Styles.Colours.Primary}
            color={Styles.Colours.Light1}
            onPress={handleRegisterWork}
          />
        </View>
      </View>
    </>
  );
};
