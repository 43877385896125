import React, { FunctionComponent } from 'react';
import Svg, { G, Rect } from 'react-native-svg';
import { ISVGProps } from './Types';

export const GridFilled: FunctionComponent<ISVGProps> = ({ width }) => (
  <Svg width={width || '16'} height="16" viewBox="0 0 16 16">
    <G id="Group_171" data-name="Group 171" transform="translate(-252 -440)">
      <Rect
        id="Rectangle_1405"
        data-name="Rectangle 1405"
        width="7"
        height="7"
        transform="translate(252 440)"
      />
      <Rect
        id="Rectangle_1407"
        data-name="Rectangle 1407"
        width="7"
        height="7"
        transform="translate(261 440)"
      />
      <Rect
        id="Rectangle_1406"
        data-name="Rectangle 1406"
        width="7"
        height="7"
        transform="translate(252 449)"
      />
      <Rect
        id="Rectangle_1408"
        data-name="Rectangle 1408"
        width="7"
        height="7"
        transform="translate(261 449)"
      />
    </G>
  </Svg>
);

export default GridFilled;
