import { GraphikMediumTextSm } from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { TextInput, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IAdditionalInformationProps {
  control: Control<any> | undefined;
  keyboardScrollRef?: React.RefObject<KeyboardAwareScrollView>;
  name: any;
  placeholder?: string;
  returnKeyType?: 'next' | 'done';
  title?: string;
}

export const AdditionalInformation: FunctionComponent<
  IAdditionalInformationProps
> = ({
  control,
  keyboardScrollRef,
  name,
  placeholder,
  returnKeyType,
  title,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View style={TailwindResponsive('flex-1 mb-14 mob:mt-12')}>
      <View style={TailwindResponsive('mb-12')}>
        <GraphikMediumTextSm>{title}</GraphikMediumTextSm>
      </View>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <TextInput
            style={{
              borderWidth: 0.5,
              height: 200,
              fontSize: Styles.Fonts.FontSize.Medium,
              fontFamily: Styles.Fonts.FontFamily.SansRegular,
              borderColor: '#00000099',
              paddingHorizontal: 25,
              paddingTop: 25,
              verticalAlign: 'top',
            }}
            placeholder={placeholder}
            placeholderTextColor={Styles.Colours.Gray2}
            multiline
            returnKeyType={returnKeyType}
            blurOnSubmit
            value={value}
            onChangeText={onChange}
            onBlur={() => {
              keyboardScrollRef?.current?.scrollToEnd();
            }}
          />
        )}
      />
    </View>
  );
};

export default AdditionalInformation;
