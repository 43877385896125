import { useNavigation } from '@react-navigation/native';
import { RoundedButton } from 'components/shared/button';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import {
  GraphikItalicSm,
  GraphikMediumTextSm,
  GraphikTextSm,
} from 'components/shared/styled';
import { format, parseISO } from 'date-fns';
import { FCStackNavProp } from 'navigation';
import React, { FunctionComponent, useMemo } from 'react';
import { View } from 'react-native';
import { IntegratedFonts } from 'style/fonts';
import {
  Transaction,
  Status as TransactionStatus,
  getDisplayName,
} from 'types';
import { getTotalDisplayValue } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import CountdownTimer from '../transactionDetails/components/CountdownTimer';

interface Props {
  transaction: Transaction;
}

export const InboxLineItem: FunctionComponent<Props> = ({ transaction }) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const navigation = useNavigation<FCStackNavProp>();
  const displayName = getDisplayName(transaction.seller);

  let background = `bg-Light5`;
  if (!transaction.wasViewed && transaction.status !== TransactionStatus.void)
    background = `bg-transparent`;
  const buttonBackground = transaction.wasViewed
    ? `bg-transparent border border-Dark1`
    : `bg-Primary`;
  const buttonTextColour = transaction.wasViewed ? `text-Dark1` : `text-Light1`;
  const inboxText = transaction.isRetro
    ? `${displayName} has issued you a certificate`
    : `${displayName} has sent you a Sales Agreement for ${getTotalDisplayValue(
        transaction,
      )}`;

  const showTimer = useMemo(
    () =>
      !(
        transaction.status === TransactionStatus.signed ||
        transaction.status === TransactionStatus.pendingApproval ||
        transaction.status === TransactionStatus.completed ||
        transaction.status === TransactionStatus.void
      ) && !!transaction.expirationTime,
    [transaction],
  );

  return (
    <ExternalLink link={`/transaction-details/${transaction.id}`}>
      <View
        style={TailwindResponsive(
          `border-b border-MidGray flex flex-row items-center justify-between p-6 ${background} mob:flex-col mob:items-start mobWeb:flex-col mobWeb:items-start`,
        )}
      >
        <View>
          <GraphikMediumTextSm>
            {format(parseISO(transaction.createDate), ' MMM dd yyyy — p')}
          </GraphikMediumTextSm>

          <View style={TailwindResponsive(`my-2`)}>
            {transaction.wasViewed === false ? (
              <GraphikMediumTextSm>{inboxText}</GraphikMediumTextSm>
            ) : (
              <GraphikTextSm>{inboxText}</GraphikTextSm>
            )}
          </View>

          <View>
            {transaction.items.map((item) => (
              <View
                key={`inbox-${transaction.id}-item-${item.work.id}`}
                style={TailwindResponsive('flex flex-row')}
              >
                <GraphikItalicSm>{item.work.title}</GraphikItalicSm>
                <GraphikTextSm>{` by ${getDisplayName(
                  item.work.artist,
                )}`}</GraphikTextSm>
              </View>
            ))}
          </View>
        </View>

        <View
          style={TailwindResponsive(
            `flex flex-row items-center mob:flex-row-reverse mob:mt-8 mobWeb:flex-row-reverse mobWeb:mt-8`,
          )}
        >
          {showTimer && (
            <View
              style={TailwindResponsive(
                `mr-4 web:w-44 mob:mr-0 mob:ml-2 mobWeb:ml-2 mobWeb:mr-0`,
              )}
            >
              <CountdownTimer
                withIcon
                endDate={transaction?.expirationTime}
                size={IntegratedFonts.small}
              />
            </View>
          )}

          {transaction.status === TransactionStatus.void ? (
            <GraphikMediumTextSm style={TailwindResponsive(`pr-12`)}>
              Void
            </GraphikMediumTextSm>
          ) : (
            <RoundedButton
              buttonStyle={TailwindResponsive(`${buttonBackground} w-40`)}
              label="View Agreement"
              onPress={() => {
                navigation.push('TransactionDetail', {
                  transactionId: transaction.id,
                });
              }}
              textStyle={TailwindResponsive(`${buttonTextColour}`)}
            />
          )}
        </View>
      </View>
    </ExternalLink>
  );
};

export default InboxLineItem;
