/* Copyright (C) 2020 Fairplay Group Ltd. (d.b.a. Fairchain) - All Rights Reserved */
import { useQuery } from '@apollo/client';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useNavigation } from '@react-navigation/native';
import { GET_USER } from 'api/requests.v2';
import { ErrorMessage } from 'components/shared/error';
import { SpinLoader } from 'components/shared/loader';
import { WorkflowPage } from 'components/shared/page';
import { UserContext } from 'contexts';
import React, { FunctionComponent, useContext } from 'react';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { AdminTransactions } from './AdminTransactions';
import { AssetManagement } from './AssetManagement';
import { BuyerTransactionList } from './BuyerTransactionList';
import { Dashboard } from './Dashboard';
import { LoginAsUserForm } from './LoginAsUserForm';
import PendingCerts from './PendingCerts';
import { UserManagement } from './UserManagement';

export const Admin: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const navigation = useNavigation();
  const Tab = createMaterialTopTabNavigator();
  const { TailwindResponsive } = useTailwindResponsive();

  const {
    loading: userLoading,
    data: userData,
    error: userError,
  } = useQuery(GET_USER, {
    variables: {
      userId: authUser?.uid,
    },
  });

  if (userLoading) return <SpinLoader />;
  if (userError) return <ErrorMessage error={userError} />;

  if (!userData.response.isAdmin) {
    navigation.navigate('Collection');
  }

  return (
    <WorkflowPage>
      <Tab.Navigator sceneContainerStyle={TailwindResponsive(`bg-Light1`)}>
        <Tab.Screen name="Dashboard" component={Dashboard} />
        <Tab.Screen
          name="Pending Approval Transactions"
          component={AdminTransactions}
        />
        <Tab.Screen
          name="Buyer Pending Transactions"
          component={BuyerTransactionList}
        />
        <Tab.Screen name="User Management" component={UserManagement} />
        <Tab.Screen name="Login as User" component={LoginAsUserForm} />
        <Tab.Screen name="Pending Certs" component={PendingCerts} />
        <Tab.Screen name="Asset Management" component={AssetManagement} />
      </Tab.Navigator>
    </WorkflowPage>
  );
};

export default Admin;
