import React, { FunctionComponent } from 'react';
import Svg, { G, Path, Rect } from 'react-native-svg';
import { ISVGProps } from './Types';

export const CheckboxChecked: FunctionComponent<ISVGProps> = ({
  color,
  width,
}) => (
  <Svg width={width || '18.778'} height="19.629" viewBox="0 0 18.778 19.629">
    <G id="Check_Box" data-name="Check Box" transform="translate(0 0.629)">
      <G
        id="Rectangle_1068"
        data-name="Rectangle 1068"
        transform="translate(0 4)"
        fill="none"
        stroke={color || '#000'}
        strokeWidth="1"
      >
        <Rect width="15" height="15" stroke="none" />
        <Rect x="0.5" y="0.5" width="14" height="14" fill="none" />
      </G>
      <Path
        id="Path_154"
        data-name="Path 154"
        d="M2633.1,1649.784l2.868,6.009,12.132-15"
        transform="translate(-2630.1 -1640.793)"
        fill="none"
        stroke={color || '#000'}
        strokeWidth="2"
      />
    </G>
  </Svg>
);

export default CheckboxChecked;
