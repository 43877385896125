import { BreadcrumbBubble } from 'components/shared/breadcrumb';
import { CenteredImage } from 'components/shared/image';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Dimensions, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { GetEmbeddedURL } from '../image/ImageHelpers';

interface ISlideshowProps {
  images: string[];
  videoURL?: string;
}

export const Slideshow: FunctionComponent<ISlideshowProps> = ({
  images,
  videoURL,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const screenWidth = Dimensions.get('window').width;

  const [allImages, setAllImages] = useState<string[]>([]);
  const [flatlistIndex, setFlatlistIndex] = useState(0);

  const onViewableItemsChanged = useCallback(
    ({ viewableItems }) => {
      if (viewableItems.length > 0) {
        const currentIndex = viewableItems[0].index;
        setFlatlistIndex(currentIndex);
      }
    },
    [setFlatlistIndex],
  );

  useEffect(() => {
    const setup = async () => {
      const imagesAndVideo = [...images];
      if (videoURL) {
        const videoInfo = await GetEmbeddedURL(videoURL);
        imagesAndVideo.push(videoInfo.uri);
      }
      setAllImages(imagesAndVideo);
    };

    setup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={TailwindResponsive('flex-col justify-center')}>
      <FlatList
        keyExtractor={(item, index) => `${item}-${index}`}
        horizontal
        showsHorizontalScrollIndicator={false}
        data={allImages}
        pagingEnabled
        viewabilityConfig={{
          viewAreaCoveragePercentThreshold: 100,
        }}
        onViewableItemsChanged={onViewableItemsChanged}
        renderItem={({ item, index }) => (
          <View
            key={index}
            style={{
              ...TailwindResponsive(`h-80 mb-6 p-4 bg-Light5`),
              width: screenWidth,
              aspectRatio: 1,
            }}
          >
            <CenteredImage isLocal key={index} uri={item} />
          </View>
        )}
      />
      {allImages?.length > 1 && (
        <View style={TailwindResponsive('flex flex-row justify-center')}>
          {[...Array(allImages?.length).keys()].map((imageIndex) => (
            <BreadcrumbBubble
              checked={imageIndex === flatlistIndex}
              key={imageIndex}
            />
          ))}
        </View>
      )}
    </View>
  );
};
