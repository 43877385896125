import { useRoute } from '@react-navigation/native';
import {
  GraphikSemiTextLg,
  GraphikSemiTextMd,
  GraphikTextSm,
} from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { ProvenanceItem } from 'types';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ProvenanceLineItem } from './ProvenanceLineItem';

interface IProvenanceProps {
  provenance: ProvenanceItem[];
  isRedactedView: boolean;
}

export const Provenance: FunctionComponent<IProvenanceProps> = ({
  provenance,
  isRedactedView,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const route = useRoute();
  const isPublicView = route.name === 'ArtworkPublicView';

  return (
    <View>
      {isPublicView ? (
        <GraphikSemiTextLg style={TailwindResponsive('mb-4')}>
          Provenance
        </GraphikSemiTextLg>
      ) : (
        <View
          style={TailwindResponsive(
            'mb-4 border-b border-Gray2 flex-row justify-between',
          )}
        >
          <GraphikSemiTextMd style={TailwindResponsive('mb-3')}>
            Provenance
          </GraphikSemiTextMd>
        </View>
      )}

      {isRedactedView && (
        <View style={TailwindResponsive('mb-8')}>
          <GraphikTextSm>
            For full provenance information please contact current owner.
          </GraphikTextSm>
        </View>
      )}
      {!isRedactedView && (
        <View style={TailwindResponsive('mb-8')}>
          {provenance && provenance.length > 0 ? (
            <>
              {provenance.map((item, index) => (
                <ProvenanceLineItem
                  key={JSON.stringify(item)}
                  isFinal={index === provenance.length - 1}
                  provenance={item}
                />
              ))}
            </>
          ) : (
            <GraphikTextSm>None</GraphikTextSm>
          )}
        </View>
      )}
    </View>
  );
};
