import { useEffect, useRef } from 'react';
import { Platform } from 'react-native';

export const useUnload = (fn) => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    if (Platform.OS === 'web') {
      const onUnload = (...args) => cb.current?.(...args);
      window.addEventListener('beforeunload', onUnload);
      return () => window.removeEventListener('beforeunload', onUnload);
    }
    return () => {
      // do nothing for native
    };
  }, []);
};

export default {
  useUnload,
};
