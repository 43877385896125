import { useMutation } from '@apollo/client';
import {
  GET_NAVIGATION_INFO,
  GET_TRANSACTION_DETAILS,
  TRANSFER_TITLE,
} from 'api/requests.v2';
import { useCallback, useEffect, useState } from 'react';
import { Transaction } from 'types';
import { FCAnalyticEvents, logAnalyticsEvent } from 'utilities';
import TDGQL from '../../../store/transactionDetails/apollo';

export const useTitleTransfer = () => {
  const [
    transferTitle,
    { error: transferTitleError, loading: transferTitleLoading },
  ] = useMutation(TRANSFER_TITLE, {
    refetchQueries: [GET_TRANSACTION_DETAILS, GET_NAVIGATION_INFO],
    onCompleted: ({ response }) => {
      TDGQL.transferTitleSucceededVar(!!response && !!response.success);
    },
  });
  return { transferTitle, transferTitleError, transferTitleLoading };
};

export const useTransferTitleHandler = (
  transaction: Transaction | undefined | null,
  transferTitle,
) =>
  useCallback(async () => {
    if (transaction && transferTitle) {
      const variables = {
        transactionId: transaction.id,
      };
      await transferTitle({ variables });
      logAnalyticsEvent(FCAnalyticEvents.TransactionUpdate, {
        transactionId: transaction.id,
      });
    }
  }, [transaction, transferTitle]);

export const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime(),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [
    days.toString(),
    hours.toString(),
    minutes.toString(),
    seconds.toString(),
  ];
};

export const useExpirationHeader = (expirationDate) => {
  if (!expirationDate) return '';
  const date = new Date(expirationDate);
  if (new Date(expirationDate) <= new Date()) return '';
  return date.toDateString();
};
