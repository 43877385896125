import { useReactiveVar } from '@apollo/client';
import { CollectionStore } from 'components/views/artworkDetails/apollo';
import { CollectionPage, CollectionTab } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCollectionTab,
  getCurrentPage,
  hasConsignedWorks,
  setCollectionTab,
} from 'store/collection';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const TabSelector: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { TailwindResponsive } = useTailwindResponsive();

  const drafts = useReactiveVar(CollectionStore.Vars.draftWorks);

  const collectionTab = useSelector(getCollectionTab);
  const collectionPage = useSelector(getCurrentPage);

  const active = {
    fontFamily: Styles.Fonts.FontFamily.SansMedium,
    fontSize: Styles.Fonts.FontSize.Medium,
    color: Styles.Colours.Dark1,
  };

  const inactive = {
    fontFamily: Styles.Fonts.FontFamily.SansRegular,
    fontSize: Styles.Fonts.FontSize.Medium,
    color: Styles.Colours.Dark3,
  };

  return (
    <>
      <Text
        onPress={() => dispatch(setCollectionTab(CollectionTab.Current))}
        style={[
          TailwindResponsive(`mt-2 mob:mr-2 mob:mt-1`),
          collectionTab === CollectionTab.Current ? active : inactive,
        ]}
      >
        Current
      </Text>
      {useSelector(hasConsignedWorks) &&
        collectionPage === CollectionPage.Collection && (
          <Text
            onPress={() => dispatch(setCollectionTab(CollectionTab.Consigned))}
            style={[
              TailwindResponsive(`mt-2 mob:mr-2 mob:mt-1`),
              collectionTab === CollectionTab.Consigned ? active : inactive,
            ]}
          >
            Consigned
          </Text>
        )}

      {drafts &&
        drafts.length > 0 &&
        collectionPage === CollectionPage.Collection && (
          <Text
            onPress={() => dispatch(setCollectionTab(CollectionTab.Drafts))}
            style={[
              TailwindResponsive(`mt-2 mob:mr-2 mob:mt-1`),
              collectionTab === CollectionTab.Drafts ? active : inactive,
            ]}
          >
            Drafts ({drafts.length})
          </Text>
        )}

      <Text
        onPress={() => dispatch(setCollectionTab(CollectionTab.Past))}
        style={[
          TailwindResponsive(`mt-2 mob:mt-1`),
          collectionTab === CollectionTab.Past ? active : inactive,
        ]}
      >
        Past
      </Text>
    </>
  );
};
