import step2 from 'assets/images/onboarding/step2.png';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import { GraphikTextMd, MediumTitle } from 'components/shared/styled';
import { SITE_BASE_URL } from 'constants/constants';
import { FunctionComponent } from 'react';
import { Image, Platform, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ArtistOnboardingProfileScreen: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View>
      <MediumTitle style={TailwindResponsive(`mb-8`)}>
        Finish setting up your profile
      </MediumTitle>

      <View style={TailwindResponsive(`items-center mb-8`)}>
        {Platform.OS === 'web' ? (
          <img src={step2} style={TailwindResponsive(`h-52`)} />
        ) : (
          <Image
            source={step2}
            style={{ height: 200, width: 200 }}
            resizeMode="contain"
          />
        )}
      </View>

      <GraphikTextMd>
        Strengthen your account by completing the following in your profile
        page:
      </GraphikTextMd>

      <View style={TailwindResponsive(`ml-6`)}>
        <View style={TailwindResponsive(`flex flex-row my-6`)}>
          <GraphikTextMd style={TailwindResponsive(`w-8`)}>1.</GraphikTextMd>
          <GraphikTextMd>
            <ExternalLink link={`${SITE_BASE_URL}/profile?scroll=signature`}>
              Upload a photo of your signature
            </ExternalLink>{' '}
            to be included on the digital certificates you create on the
            platform.
          </GraphikTextMd>
        </View>

        <View style={TailwindResponsive(`flex flex-row`)}>
          <GraphikTextMd style={TailwindResponsive(`w-6`)}>2.</GraphikTextMd>
          <GraphikTextMd>
            <ExternalLink link={`${SITE_BASE_URL}/profile?scroll=signature`}>
              Connect to Stripe
            </ExternalLink>{' '}
            to access the ability to process and collect payments.
          </GraphikTextMd>
        </View>
      </View>
    </View>
  );
};

export default ArtistOnboardingProfileScreen;
