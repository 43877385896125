import { makeVar } from '@apollo/client';

export const HeaderVars = {
  draftWorkId: makeVar<string | null>(null),
  showMenuModal: makeVar<boolean>(false),
  showNeedSubModal: makeVar<boolean>(false),
  transactionCount: makeVar<number>(-1),
};

export const ResetHeader = () => {
  HeaderVars.draftWorkId(null);
  HeaderVars.showMenuModal(false);
  HeaderVars.showNeedSubModal(false);
  HeaderVars.transactionCount(-1);
};

export default HeaderVars;
