import { useMutation, useReactiveVar } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { DELETE_DRAFT } from 'api/requests.v2';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { GraphikSemiTextLg, GraphikTextMd } from 'components/shared/styled';
import { UserContext } from 'contexts';
import { RegisterWorkRouteProp } from 'navigation';
import { FunctionComponent, useContext } from 'react';
import { Modal, Pressable, View } from 'react-native';
import { RegisterStore } from 'store/registerWork';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useAutoSave } from '../hooks.auto-save';

export const CancelRWModal: FunctionComponent = () => {
  const authUser = useContext(UserContext);
  const navigation = useNavigation();
  const route = useRoute<RegisterWorkRouteProp>();
  const { TailwindResponsive } = useTailwindResponsive();

  const showThySelf = useReactiveVar(RegisterStore.Vars.showCancelModal);

  const handleClose = () => {
    RegisterStore.Vars.showCancelModal(false);
    navigation.navigate('Collection');
  };

  const { handleSave, loading: saveLoading } = useAutoSave(handleClose);

  const [deleteDraft, { loading: deleteLoading }] = useMutation(DELETE_DRAFT, {
    onCompleted: () => {
      handleClose();
    },
  });

  if (!showThySelf) return null;

  return (
    <Modal
      style={TailwindResponsive('flex-1 items-center py-10')}
      transparent
      visible={showThySelf}
    >
      <Pressable
        onPress={() => {
          RegisterStore.Vars.showCancelModal(false);
        }}
        style={TailwindResponsive(
          `bg-black/50 flex-1 items-center justify-center mob:px-4`,
        )}
      >
        <Pressable
          onStartShouldSetResponder={() => true}
          onTouchEnd={(e) => {
            e.stopPropagation();
          }}
          style={[
            TailwindResponsive(
              `bg-Light1 flex shrink rounded-lg max-w-lg m-10 w-full`,
            ),
            { cursor: 'default' },
          ]}
        >
          <View style={TailwindResponsive(`p-8`)}>
            <GraphikSemiTextLg style={TailwindResponsive(`border-b mb-6 pb-2`)}>
              Are you sure you want to exit?
            </GraphikSemiTextLg>

            <GraphikTextMd>
              You&apos;re almost done! Please confirm what you&apos;d like to do
              with the info you&apos;ve entered so far.
            </GraphikTextMd>

            <View
              style={TailwindResponsive(
                `flex flex-row items-center justify-center mb-6 mt-10`,
              )}
            >
              <View>
                <FairchainButton
                  buttonStyle={TailwindResponsive(
                    'border px-8 mx-8 rounded-full',
                  )}
                  label="Discard Draft"
                  loading={deleteLoading}
                  loadingStyle={TailwindResponsive(`w-36`)}
                  onPress={() => {
                    if (route.params.draftId !== 'new') {
                      deleteDraft({
                        variables: {
                          draftId: route.params.draftId,
                          userId: authUser?.uid,
                        },
                      });
                    } else {
                      handleClose();
                    }
                  }}
                />
              </View>

              <View>
                <FairchainButton
                  buttonStyle={TailwindResponsive(
                    'bg-Primary px-8 mx-8 rounded-full',
                  )}
                  label="Save & Exit"
                  loading={saveLoading}
                  loadingStyle={TailwindResponsive(`w-36`)}
                  onPress={() => {
                    handleSave();
                  }}
                  textStyle={TailwindResponsive(`text-Light1`)}
                />
              </View>
            </View>

            <FairchainButton
              label="Continue Editing"
              onPress={() => {
                RegisterStore.Vars.showCancelModal(false);
              }}
              textStyle={TailwindResponsive(`underline`)}
            />
          </View>
        </Pressable>
      </Pressable>
    </Modal>
  );
};
