import { TextButton } from 'components/shared/button';
import { MAX_WORKS_PER_ARTIST } from 'constants/collections';
import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { Work } from 'types/Works';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ListTableLineItem } from './ListTableLineItem';

interface IArtistBlockGroupProps {
  block: Work[];
}

export const ArtistBlock: FunctionComponent<IArtistBlockGroupProps> = ({
  block,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const [open, setOpen] = useState(false);

  return (
    <View style={TailwindResponsive(`flex-col pb-12`)}>
      {block.map((work, index) => (
        <ListTableLineItem
          expanded={open}
          index={index}
          key={`art-block-${work.id}`}
          work={work}
        />
      ))}

      {block.length > MAX_WORKS_PER_ARTIST ? (
        <TextButton
          label={open ? 'Collapse' : 'View All'}
          onPress={() => setOpen(!open)}
          style={TailwindResponsive(`mt-2`)}
        />
      ) : null}
    </View>
  );
};
