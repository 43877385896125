import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export const setPushNotifications = async (firebaseUser, addUserExpoToken) => {
  if (Device.isDevice && Platform.OS !== 'web') {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    if (existingStatus !== 'granted') {
      const { status: requestStatus } =
        await Notifications.requestPermissionsAsync();
      if (requestStatus === 'granted') {
        const token = (await Notifications.getExpoPushTokenAsync()).data;
        await addUserExpoToken({
          variables: { expoPushToken: token, userId: firebaseUser?.uid },
        });
      }
    } else {
      const token = (await Notifications.getExpoPushTokenAsync()).data;
      await addUserExpoToken({
        variables: { expoPushToken: token, userId: firebaseUser?.uid },
      });
    }
  }
};
