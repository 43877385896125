import { GraphikMediumTextSm } from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { Pressable } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface ButtonProps {
  onPress?: () => void;
  label: string;
}

export const ProfileButton: FunctionComponent<ButtonProps> = ({
  onPress,
  label,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <Pressable
      style={TailwindResponsive(
        `items-center justify-center bg-Dark1 rounded-full h-pblg w-pblg mob:h-pbsm mob:w-pbsm`,
      )}
      onPress={onPress}
    >
      <GraphikMediumTextSm style={TailwindResponsive(`text-Light1`)}>
        {label}
      </GraphikMediumTextSm>
    </Pressable>
  );
};

export default ProfileButton;
