import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeviceType } from 'expo-device';
import { IFeatureFlags, INotifications } from 'types';

export interface IGeneralState {
  deviceType: DeviceType;
  featureFlags: IFeatureFlags;
  notifications: INotifications;
  redirectUrl: string | null;
}

const initialState: IGeneralState = {
  deviceType: DeviceType.DESKTOP,
  featureFlags: {
    allowBuyerRejection: false,
    galleryCcEmail: false,
    gallerySubscription: false,
    kyc: false,
    pushNotifications: false,
    quickbooks: false,
    registerBlockchain: false,
    workTransDetails: false,
  },
  notifications: {
    transfersToInitiate: 0,
    unviewedTransactions: 0,
  },
  redirectUrl: null,
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setDeviceType: (state, action: PayloadAction<DeviceType>) => {
      state.deviceType = action.payload;
    },
    setFeatureFlags: (state, action: PayloadAction<IFeatureFlags>) => {
      state.featureFlags = action.payload;
    },
    setNotifications: (state, action: PayloadAction<INotifications>) => {
      state.notifications = action.payload;
    },
    setRedirect: (state, action: PayloadAction<string | null>) => {
      state.redirectUrl = action.payload;
    },
  },
});

const { actions, reducer } = generalSlice;
export const { setDeviceType, setFeatureFlags, setNotifications, setRedirect } =
  actions;
export { reducer as generalReducer };

export type GeneralState = ReturnType<typeof reducer>;
export type GeneralAction =
  | typeof setDeviceType
  | typeof setFeatureFlags
  | typeof setNotifications
  | typeof setRedirect;
