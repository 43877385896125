import { ErrorMessage } from 'components/shared/error';
import { SpinLoader } from 'components/shared/loader';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { getCollectionLoading, getErrorMessage } from 'store/collection';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ListView } from './ListView';

export const CollectionWrapper: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const collectionLoading = useSelector(getCollectionLoading);
  const errorMessage = useSelector(getErrorMessage);

  if (collectionLoading)
    return (
      <View style={TailwindResponsive(`flex-1 items-center justify-center`)}>
        <SpinLoader />
      </View>
    );

  if (errorMessage) return <ErrorMessage />;

  return <ListView />;
};
