import { FairchainButton } from 'components/shared/button/FairchainButton';
import { useHandleRegisterWork } from 'components/shared/header/hooks';
import { ExternalLink } from 'components/shared/link/ExternalLink';
import { GraphikTextLarge, GraphikTextMd } from 'components/shared/styled';
import { CollectionTab, UserRole } from 'constants/enums';
import React, { FunctionComponent, useCallback } from 'react';
import { Platform, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { getCollectionTab } from 'store/collection';
import { getUserRole } from 'store/user';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const EmptyCollection: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const userRole = useSelector(getUserRole);
  const collectionView = useSelector(getCollectionTab);
  const handleRegisterWork = useHandleRegisterWork();

  const getPlaceHolderText = useCallback(() => {
    switch (collectionView) {
      case CollectionTab.Consigned:
        return `Works that you've consigned will appear here`;
      case CollectionTab.Past:
        return `Works that you transfer will appear here`;
      default:
        return `Upload your first work to begin.`;
    }
  }, [collectionView]);

  return (
    <View style={TailwindResponsive('flex mob:self-center ')}>
      {userRole === UserRole.Collector && (
        <View style={TailwindResponsive('flex mt-16 px-24 mobWeb:px-0')}>
          <GraphikTextLarge style={TailwindResponsive('mb-16 text-Dark1')}>
            Welcome to Fairchain! Click below to get started.
          </GraphikTextLarge>
          <FairchainButton
            textStyle={TailwindResponsive('text-Light1 ')}
            buttonStyle={TailwindResponsive(
              `bg-Primary rounded-full w-fit px-24`,
            )}
            label="Register Artwork"
            onPress={handleRegisterWork}
          />
        </View>
      )}

      {userRole !== UserRole.Collector && (
        <>
          <Text
            style={[
              {
                fontSize:
                  Platform.OS === 'ios'
                    ? Styles.Fonts.FontSize.Large
                    : Styles.Fonts.FontSize.Huge,
                fontFamily: Styles.Fonts.FontFamily.SansRegular,
                color: Styles.Colours.Dark1,
                letterSpacing: -0.75,
              },
              TailwindResponsive(`mb-10`),
            ]}
          >
            {getPlaceHolderText()}
          </Text>

          {Platform.OS === 'web' && (
            <>
              <FairchainButton
                buttonStyle={TailwindResponsive(
                  'bg-Primary px-8 rounded-full self-start',
                )}
                label="Register Artwork"
                onPress={handleRegisterWork}
                textStyle={TailwindResponsive(`text-Light1`)}
              />

              <GraphikTextMd style={TailwindResponsive(`mt-8`)}>
                Or, watch our getting started video{' '}
                <ExternalLink link="https://www.youtube.com/watch?v=q99TKgHtMVM">
                  here.
                </ExternalLink>
              </GraphikTextMd>
            </>
          )}
        </>
      )}
    </View>
  );
};
