import { RootState } from 'store/appStore';
import { DiminishedRoyalty } from './slice';

export const getDiminishedRoyalties = (store: RootState): DiminishedRoyalty[] =>
  store.form.diminishedRoyalties;

export const getDefaultsInitialized = (store: RootState): boolean =>
  store.form.defaultsInitialized;

export const getDiminishedRoyaltiesErrors = (store: RootState): boolean =>
  store.form.errors ? store.form.errors.diminishedRoyalties : false;
