import { useNavigation } from '@react-navigation/native';
import { GraphikSemiTextSm, GraphikTextMd } from 'components/shared/styled';
import React, { FunctionComponent } from 'react';
import { Linking, Pressable, View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const SignUpLink: FunctionComponent = () => {
  const navigation = useNavigation();
  const { TailwindResponsive } = useTailwindResponsive();

  const introText = "Don't have a Fairchain account?";
  const signUpText = 'Sign Up';
  const depracationText =
    'The Fairchain platform has closed, effective August 1, 2024. ';

  // Added "hidden" to depracate sign up ability
  return (
    <View style={TailwindResponsive(`mt-14 mb-24`)}>
      {/* Sign up deprecation notice */}
      <View
        style={TailwindResponsive(
          'flex-col mt-24 mobWeb:w-full mobWeb:flex-col',
        )}
      >
        <GraphikTextMd color={Styles.Colours.Light1}>
          {depracationText}
        </GraphikTextMd>
        <GraphikTextMd
          style={TailwindResponsive(' mt-8 ')}
          color={Styles.Colours.Light1}
        >
          Read more{' '}
          <GraphikTextMd
            style={{ textDecorationLine: 'underline' }}
            color={Styles.Colours.Light1}
            onPress={() => {
              Linking.openURL('https://fairchain.art');
            }}
          >
            here
          </GraphikTextMd>
          .
        </GraphikTextMd>
      </View>
      <View style={TailwindResponsive(`hidden`)}>
        <GraphikTextMd color={Styles.Colours.Light1}>{introText}</GraphikTextMd>
        <Pressable
          onPress={() => {
            navigation.navigate('SignUp');
          }}
          style={TailwindResponsive(`self-start`)}
        >
          <GraphikSemiTextSm
            style={TailwindResponsive(`mt-8 text-Light1 underline mob:mt-2`)}
          >
            {signUpText}
          </GraphikSemiTextSm>
        </Pressable>
      </View>
    </View>
  );
};

export default SignUpLink;
