import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { PaymentIntent, SetupIntent, StripeError } from '@stripe/stripe-js';
import { SITE_BASE_URL } from 'constants/constants';
import { addDays, format } from 'date-fns';
import { isFunction } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { RoundedButton } from '../button';
import { SpinLoader } from '../loader';
import { GraphikTextMd, GraphikTextTiny } from '../styled';
import { SubscriptionOption } from '../subscription/SubscriptionSelector';

export enum PaymentFlowStatus {
  failed = 'FAILED',
  processing = 'PROCESSING',
  succeeded = 'SUCCEEDED',
}

export interface IPaymentFlowResponse {
  message: string;
  paymentStatus: PaymentFlowStatus;
  success: boolean;
}

interface IPaymentFlowProps {
  callback?: (input: IPaymentFlowResponse) => void;
  choice: SubscriptionOption;
  paymentIntentId: string;
  redirectUrl?: string;
}

export const StripePaymentFLow: FunctionComponent<IPaymentFlowProps> = ({
  callback,
  choice,
  paymentIntentId,
  redirectUrl,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [paidSuccess, setPaidSuccess] = useState(true);

  useEffect(() => {
    if (!stripe || !paymentIntentId) {
      return;
    }

    const handlePaymentIntent = (
      paymentIntent: PaymentIntent | SetupIntent | undefined,
    ) => {
      const response: IPaymentFlowResponse = {
        message: '',
        paymentStatus: PaymentFlowStatus.failed,
        success: false,
      };

      switch (paymentIntent!.status) {
        case 'succeeded':
          setPaidSuccess(true);
          setMessage('Payment succeeded!');
          response.message = 'Payment succeeded!';
          response.paymentStatus = PaymentFlowStatus.succeeded;
          response.success = true;
          break;
        case 'processing':
          setPaidSuccess(true);
          setMessage('Your payment is processing');
          response.message = 'Your payment is processing...';
          response.paymentStatus = PaymentFlowStatus.processing;
          response.success = true;
          break;
        case 'requires_payment_method':
          setPaidSuccess(false);
          response.message = 'Please enter a valid payment method.';
          break;
        default:
          setPaidSuccess(false);
          response.message = 'Something went wrong, please try again.';
          break;
      }

      if (callback && isFunction(callback)) {
        callback(response);
      }
    };

    if (choice === SubscriptionOption.Standard) {
      stripe.retrieveSetupIntent(paymentIntentId).then(({ setupIntent }) => {
        handlePaymentIntent(setupIntent);
      });
    } else {
      stripe
        .retrievePaymentIntent(paymentIntentId)
        .then(({ paymentIntent }) => {
          handlePaymentIntent(paymentIntent);
        });
    }
  }, [callback, choice, paymentIntentId, stripe]);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    let error: StripeError;
    if (choice === SubscriptionOption.Standard) {
      const { error: retError } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${SITE_BASE_URL}/${redirectUrl}`,
        },
      });
      error = retError;
    } else {
      const { error: retError } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${SITE_BASE_URL}/${redirectUrl}`,
        },
      });
      error = retError;
    }
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (
      error &&
      (error.type === 'card_error' || error.type === 'validation_error')
    ) {
      setMessage(error.message || 'There was an error confirming the payment');
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  if (!paymentIntentId) return null;

  return (
    <View>
      {!paidSuccess && (
        <View>
          <PaymentElement id="payment-element" />
          <View style={TailwindResponsive(`mt-2`)}>
            {isLoading ? (
              <SpinLoader width={62} />
            ) : (
              <RoundedButton
                backgroundColor={Styles.Colours.Primary}
                buttonStyle={TailwindResponsive(`my-2`)}
                color={Styles.Colours.Light1}
                label={
                  choice === SubscriptionOption.Standard
                    ? 'Start free trial'
                    : 'Pay now'
                }
                onPress={handleSubmit}
              />
            )}
          </View>
          <GraphikTextTiny style={TailwindResponsive(`mt-2`)}>
            Connecting a US bank account allows for one tap payments.
          </GraphikTextTiny>

          {choice === SubscriptionOption.Standard && (
            <GraphikTextTiny>
              You won&apos;t be charged until{' '}
              {format(addDays(new Date(), 90), 'PPP')}.
            </GraphikTextTiny>
          )}
        </View>
      )}
      {!isLoading && message && (
        <GraphikTextMd
          color={paidSuccess ? Styles.Colours.Success : Styles.Colours.Error}
        >
          {message}
        </GraphikTextMd>
      )}
    </View>
  );
};

export default StripePaymentFLow;
