/* eslint-disable no-param-reassign */
import { useMutation } from '@apollo/client';
import { REMOVE_SUB_USER, UPDATE_SUB_USER_RIGHTS } from 'api/requests.v2';
import { FairchainButton } from 'components/shared/button/FairchainButton';
import { Checkbox } from 'components/shared/checkbox';
import { GraphikMediumTextMd, GraphikTextSm } from 'components/shared/styled';
import { UserContext } from 'contexts';
import { debounce } from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Pressable, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { removeSubUser } from 'store/user';
import Styles from 'style';
import { User } from 'types/Users';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface ISubUserManagementLineItemProps {
  subUser: User;
}

enum SubUserRights {
  MainUserClientList = 'MainUserClientList',
  MainUserTransactions = 'MainUserTransactions',
  MainUserCollection = 'MainUserCollection',
}

export const SubUserManagementLineItem: FunctionComponent<
  ISubUserManagementLineItemProps
> = ({ subUser }) => {
  const authUser = useContext(UserContext);
  const dispatch = useDispatch();
  const { TailwindResponsive } = useTailwindResponsive();

  const [sendRemoveSubUser, { loading }] = useMutation(REMOVE_SUB_USER);
  const [updateSubUserRights] = useMutation(UPDATE_SUB_USER_RIGHTS);

  const wrapperStyle = `border-b-2 border-Dark6 flex-row justify-between mb-3`;

  const [newRightsArray, setNewRightsArray] = useState(subUser.rights || []);
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);

  const editSubUserRights = useCallback(
    async (right: string) => {
      if (newRightsArray.includes(right)) {
        setNewRightsArray(newRightsArray.filter((x) => x !== right));
      } else {
        const newNewArray = [...newRightsArray, right];
        setNewRightsArray(newNewArray);
      }
    },
    [newRightsArray],
  );

  const revokeAccess = useCallback(async () => {
    await sendRemoveSubUser({
      variables: {
        subUserId: subUser.id,
        userId: authUser?.uid,
      },
    });
    dispatch(removeSubUser(subUser.id));
    setShowRemoveConfirm(false);
  }, [authUser, dispatch, sendRemoveSubUser, subUser]);

  useEffect(() => {
    if (subUser && subUser.id) {
      updateSubUserRights({
        variables: {
          userId: subUser.id,
          rights: newRightsArray,
        },
      });
    }
  }, [newRightsArray, subUser, updateSubUserRights]);

  if (showRemoveConfirm)
    return (
      <View
        style={TailwindResponsive(wrapperStyle)}
        key={`${subUser.id}_confirm`}
      >
        <View style={TailwindResponsive('flex-1 flex-row items-center py-10')}>
          <View style={TailwindResponsive(`flex-1`)}>
            <GraphikMediumTextMd>
              Are you sure you want to remove sub user {subUser.firstName}{' '}
              {subUser.lastName}?
            </GraphikMediumTextMd>
          </View>

          <FairchainButton
            buttonStyle={TailwindResponsive(`bg-Primary rounded-full w-40`)}
            label="Remove"
            loading={loading}
            onPress={() => revokeAccess()}
            textStyle={TailwindResponsive(`text-Light1`)}
          />

          <FairchainButton
            buttonStyle={TailwindResponsive(`ml-4 px-8`)}
            label="Cancel"
            onPress={() => {
              setShowRemoveConfirm(false);
            }}
          />
        </View>
      </View>
    );

  return (
    <View style={TailwindResponsive(wrapperStyle)} key={subUser.id}>
      <View style={TailwindResponsive('mt-8')}>
        <GraphikMediumTextMd>
          {subUser.firstName} {subUser.lastName}
        </GraphikMediumTextMd>
      </View>
      <View
        style={TailwindResponsive(
          `mt-8 flex items-end mb-8 mobWeb:flex-col-reverse`,
        )}
      >
        <View style={TailwindResponsive('flex-row flex items-end mobWeb:pt-4')}>
          <View style={TailwindResponsive('ml-4')}>
            <Checkbox
              labelSpacing={5}
              labelStyle={Styles.Fonts.FontFamily.SansRegular}
              label="Contact List"
              isChecked={newRightsArray.includes('MainUserClientList')}
              onPress={debounce(async () => {
                await editSubUserRights(SubUserRights.MainUserClientList);
              }, 250)}
            />
          </View>
          <View style={TailwindResponsive('ml-4')}>
            <Checkbox
              labelSpacing={5}
              labelStyle={Styles.Fonts.FontFamily.SansRegular}
              label="Transactions"
              isChecked={newRightsArray.includes('MainUserTransactions')}
              onPress={debounce(async () => {
                await editSubUserRights(SubUserRights.MainUserTransactions);
              }, 250)}
            />
          </View>
          <View style={TailwindResponsive('ml-4')}>
            <Checkbox
              labelSpacing={5}
              labelStyle={Styles.Fonts.FontFamily.SansRegular}
              label="Collection"
              isChecked={newRightsArray.includes('MainUserCollection')}
              onPress={debounce(async () => {
                await editSubUserRights(SubUserRights.MainUserCollection);
              }, 250)}
            />
          </View>
        </View>
        <View style={TailwindResponsive('flex items-end mt-6 mobWeb:-mt-2')}>
          <Pressable
            onPress={() => {
              setShowRemoveConfirm(true);
            }}
            style={TailwindResponsive(`flex justify-center`)}
          >
            <GraphikTextSm
              style={{
                color: Styles.Colours.Primary,
                textDecorationLine: 'underline',
              }}
            >
              Remove User
            </GraphikTextSm>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

export default SubUserManagementLineItem;
