import { CollectionTab } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { getCollectionTab } from 'store/collection';
import { CollectionHeader } from './CollectionHeader';
import { ConsignmentHeader } from './ConsignmentHeader';

export const ListTableHeader: FunctionComponent = () => {
  const currentTab = useSelector(getCollectionTab);
  if (currentTab === CollectionTab.Consigned) return <ConsignmentHeader />;
  return <CollectionHeader />;
};
