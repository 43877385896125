import { SITE_VERSION } from 'constants/constants';
import { format } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import Styles from 'style';
import { Style } from 'tailwind-rn/dist';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ExternalLink } from '../link/ExternalLink';
import { GraphikTextSm } from '../styled';

interface IFooterProps {
  style?: { [key: string]: string } | Style;
  backgroundColor?: string;
  textColor?: string;
}

export const Footer: FunctionComponent<IFooterProps> = ({
  backgroundColor,
  style,
  textColor,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const baseStyle = style || {};
  const baseBackgroundColor = backgroundColor || 'Light1';
  const baseTextColor = textColor || Styles.Colours.Dark1;

  return (
    <View
      style={[
        baseStyle,
        TailwindResponsive(
          `hidden pb-20 px-16 bg-${baseBackgroundColor} mobWeb:px-8 pt-12 mobWeb:pt-4`,
        ),
      ]}
      // Hidden footer here for depracation purposes
    >
      <View
        style={TailwindResponsive(
          `flex flex-row justify-between mobWeb:flex-col`,
        )}
      >
        <View style={TailwindResponsive(`flex flex-row mobWeb:flex-col`)}>
          <View
            style={TailwindResponsive(
              'border-Dark1 mobWeb:border-t mobWeb:pt-5',
            )}
          >
            <ExternalLink link="/contact">
              <View style={TailwindResponsive('pr-11 mobWeb:h-10 mobWeb:pr-0')}>
                <GraphikTextSm color={baseTextColor}>Contact</GraphikTextSm>
              </View>
            </ExternalLink>
          </View>
          <View>
            <ExternalLink link="/terms">
              <View style={TailwindResponsive('pr-11 mobWeb:h-10 mobWeb:pr-0')}>
                <GraphikTextSm color={baseTextColor}>Terms</GraphikTextSm>
              </View>
            </ExternalLink>
          </View>
          <View>
            <ExternalLink link="/privacy">
              <View style={TailwindResponsive('pr-11 mobWeb:h-10 mobWeb:pr-0')}>
                <GraphikTextSm color={baseTextColor}>
                  Privacy Policy
                </GraphikTextSm>
              </View>
            </ExternalLink>
          </View>
        </View>
        <View style={[TailwindResponsive('pr-11')]}>
          <GraphikTextSm color={baseTextColor}>
            &copy; FAIRCHAIN {format(new Date(), 'yyyy')} {SITE_VERSION}
          </GraphikTextSm>
        </View>
      </View>
    </View>
  );
};

export default Footer;
