/* eslint-disable no-console */
import { ENV, SITE_VERSION } from 'constants/constants';
import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';

export const init = () => {
  Sentry.init({
    dsn: 'https://a02b1d7aa10942adb14cfce45b501ebc@o1048607.ingest.sentry.io/6117173',
    enableInExpoDevelopment: true,
    environment: ENV.toLocaleLowerCase(),
    release: `fairchain-app@${SITE_VERSION}`,
    tracesSampleRate: 0.2,
  });
};

export const logError = (error, responseString = '') => {
  const isDev = ENV === 'DEVELOPMENT';

  if (responseString || isDev)
    console.log(
      `${responseString} | ${
        error.message ? error.message : JSON.stringify(error)
      }`,
    );

  if (!isDev) {
    if (Platform.OS === 'web') Sentry.Browser.captureException(error);
    else Sentry.Native.captureException(error);
  }
};

export const logMessage = (message) => {
  if (Platform.OS === 'web') Sentry.Browser.captureMessage(message);
  else Sentry.Native.captureMessage(message);
};

export default {
  init,
  logError,
  logMessage,
};
