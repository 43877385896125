import { AssetType } from 'constants/enums';
import { Artist, Contributor, User } from 'types';

export interface Work {
  id: string;
  additionalInfo: string;
  apNumber: string;
  apTotal: string;
  artist: Artist;
  artistId: string;
  artistRoyalty: string;
  assetType: AssetType;
  availableForLoan: boolean;
  blockchainData: string;
  certificationStatus: CertificationStatus;
  certified: boolean;
  certificateURL: string;
  collectible?: CollectibleInfo;
  consignment: ConsignmentInfo[];
  consignorUsers: string[];
  currentTransactionId: string;
  dateBought: string;
  dateOfFirstTransaction: string;
  dateRegistered: string;
  depth: string;
  digitalAsset: DigitalAssetObject[];
  digitalAssetURLs: DigitalAssetObject[];
  digitalRepoRights: boolean;
  dimensions: string;
  dimensionUnits: string;
  editionNumber: string;
  editionTotal: string;
  editionType: string;
  embeddedCode: string;
  ethereumTransaction: string;
  fundRoyalty: string;
  galleryRoyalty: string;
  hasPublicCertificate: boolean;
  height: string;
  images: string[];
  inventoryNumber: string;
  isEditionWork: boolean;
  isRetro: boolean;
  lastCompletedTransactionId: string;
  medium: string;
  notes: string;
  numAP: string;
  owner: User;
  ownerId: string;
  previewImages: string[];
  privateNote: string;
  provenance: ProvenanceItem[];
  publicViewOptions: PublicViewOptions;
  registeredById: string;
  royalties: ArtworkRoyalty[];
  salesInfo: UncertifiedSalesInfo;
  supportingDocUris: SupportingDocInfo[];
  title: string;
  transactionId: string;
  transactions: string[];
  totalNumberOfAPs: string;
  userConsignment: ConsignmentInfo;
  videoURL: string;
  width: string;
  workIdentifier: string;
  yearProduced: string;
}

export interface PublicViewOptions {
  col1?: string;
  col2?: string;
  imageBgColor?: string;
  paddingTop?: string;
}

export interface ArtworkRoyalty {
  diminishedValue?: string;
  diminishedDate?: string;
  user: string;
  type: string;
  value: string;
}

export enum CertificationStatus {
  requested = 'REQUESTED',
  adminApproved = 'ADMIN_APPROVED',
  artistApproved = 'ARTIST_APPROVED',
  void = 'VOID',
}

export interface ConsignmentInfo {
  consignor: string;

  consignedDate: string;

  consignee: string;
}

export interface DigitalAssetObject {
  title: string;
  url: string;
}

export interface DraftWork {
  id: string;
  assetType?: string;
  artist: User;
  image: string;
  lastSaved: string;
  medium: string;
  title: string;
  yearProduced: string;
}

export interface SupportingDocInfo {
  transferable: boolean;
  uri: string;
}

export interface UncertifiedSalesInfo {
  purchaseAmount?: string;
  purchaseDate?: string;
  location?: string;
  seller?: string;
}

export type WorkPublicView = Pick<
  Work,
  | 'id'
  | 'additionalInfo'
  | 'artist'
  | 'artistId'
  | 'assetType'
  | 'collectible'
  | 'dateRegistered'
  | 'depth'
  | 'dimensions'
  | 'dimensionUnits'
  | 'editionNumber'
  | 'editionTotal'
  | 'editionType'
  | 'embeddedCode'
  | 'hasPublicCertificate'
  | 'height'
  | 'images'
  | 'isEditionWork'
  | 'medium'
  | 'numAP'
  | 'owner'
  | 'ownerId'
  | 'provenance'
  | 'publicViewOptions'
  | 'title'
  | 'totalNumberOfAPs'
  | 'width'
  | 'workIdentifier'
  | 'yearProduced'
>;

export interface WorkWithReference extends Work {
  artistId: string;
  ownerId: string;
}

export interface WorkWithObject extends Work {
  artist: Artist;
  owner: User;
}

export interface WorkConsignmentForm {
  additionalInfo: string;
  consigneeName: string;
  consigneeEmail: string;
  consigneePhone: string;
}

export const enum EditionAPType {
  Edition = 0,
  AP = 1,
}

export interface ProvenanceItem {
  action?: string;
  certificateId?: string;
  contractId?: string;
  date?: string;
  individualBlockchainHash?: string;
  individualEtheriumTransaction?: string;
}

export interface CollectibleInfo {
  materials?: string;
  measurements?: string;
  rfid?: string;
  contributors?: Contributor[];
}
