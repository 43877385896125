import React, { FunctionComponent } from 'react';
import Svg, { Path, Polygon } from 'react-native-svg';

export const Logo: FunctionComponent = () => (
  <Svg id="Logo" viewBox="0 0 222.394 133.62">
    <Path d="M161.393,10.769c21.279,0,38.974,9.857,48.66,27.072h12.341C212.182,14.452,188.972,0,161.393,0c-17.937,0-34.62,6.109-46.975,17.2a65.3,65.3,0,0,0-21.1,41.885h12.7C109.517,30.582,132.145,10.769,161.393,10.769Z" />
    <Polygon points="76.675 2.614 0 2.614 0 59.086 12.636 59.086 12.636 13.382 76.675 13.382 76.675 2.614" />
    <Polygon points="74.995 74.533 0 74.533 0 131.006 12.636 131.006 12.636 85.302 74.995 85.302 74.995 74.533" />
    <Path d="M210.053,95.779c-9.686,17.215-27.381,27.072-48.66,27.072-29.248,0-51.876-19.814-55.374-48.318h-12.7a65.309,65.309,0,0,0,21.1,41.886c12.356,11.092,29.038,17.2,46.975,17.2,27.579,0,50.789-14.453,61-37.841Z" />
  </Svg>
);

export default Logo;
