import { useReactiveVar } from '@apollo/client';
import { ErrorMessage } from 'components/shared/error';
import React, { FunctionComponent } from 'react';
import { RegisterStore } from 'store/registerWork/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const RWErrorBanner: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const registrationError = useReactiveVar(
    RegisterStore.Vars.registrationError,
  );

  if (!registrationError) return null;

  return (
    <ErrorMessage
      errorMessage={`There was an error in registering your work. ${registrationError}.`}
      errorStyle={TailwindResponsive(
        `-mt-14 mb-14 bg-red-200 items-center p-4`,
      )}
      textStyle={TailwindResponsive(`m-0 p-0`)}
    />
  );
};

export default RWErrorBanner;
