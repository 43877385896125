import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import Styles from 'style';
import { ISVGProps } from './Types';

export const AddIcon: FunctionComponent<ISVGProps> = ({ color, width }) => {
  const iconWidth = width || 48;

  return (
    <Svg
      // height="150px"
      viewBox="0 0 24 24"
      width={iconWidth}
      fill={color || Styles.Colours.Dark1}
    >
      <Path d="M0 0h24v24H0V0z" fill="none" />
      <Path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    </Svg>
  );
};

export default AddIcon;
