import { useQuery } from '@apollo/client';
import { GET_FEE_TIERS } from 'api/requests.v2';
import bankersRounding from 'bankers-rounding';
import {
  GraphikSemiTextSm,
  GraphikTextSm,
  GraphikTextTiny,
} from 'components/shared/styled';
import currency from 'currency.js';
import { compareAsc, parseISO } from 'date-fns';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  Control,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getIsRetro, setStripeFeesAmount } from 'store/transferWork';
import { getMainUser, getUser } from 'store/user';
import { WorkWithObject } from 'types';
import { CurrencyType } from 'types/Transactions';
import { getCurrencySymbol, isCollectible } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { ContractRider } from './ContractRider';
import {
  ITransactionDetailsForm,
  calculatePaymentWithStripeFeePassedOn,
  dateInPast,
} from './Helpers';
import TransferWorkDetails from './TransferWorkDetails';
import DetailsLineItem from './components/DetailsLineItem';
import { InvoiceCosts } from './components/InvoiceCosts';
import { InvoiceDetails } from './components/InvoiceDetails';
import { InvoiceSelection } from './components/InvoiceSelection';
import { TransactionDetailsProceeds } from './components/TransactionDetailsProceeds';
import { TransactionSettings } from './components/TransactionSettings';
import { useSetupDiminishedRoyalties } from './hooks';

const DISCOUNTED_FAIRCHAIN_CUT = 10;
const MIN_FAIRCHAIN_CUT = 0.011;

interface ITransactionDetailsProps {
  clearErrors: UseFormClearErrors<ITransactionDetailsForm>;
  control: Control<ITransactionDetailsForm>;
  errors: any;
  getValues: UseFormGetValues<ITransactionDetailsForm>;
  isStripeSetup: boolean;
  setValues: UseFormSetValue<ITransactionDetailsForm>;
  trigger: () => void;
  watch: UseFormWatch<ITransactionDetailsForm>;
  works: WorkWithObject[];
}

export const TransactionDetails: FunctionComponent<
  ITransactionDetailsProps
> = ({
  clearErrors,
  control,
  errors,
  getValues,
  isStripeSetup,
  setValues,
  trigger,
  watch,
  works,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const mainUser = useSelector(getMainUser);
  const userInfo = useSelector(getUser);
  const transIsRetro = useSelector(getIsRetro);

  const [feeTiers, setFeeTiers] = useState<any[]>([]);

  useQuery(GET_FEE_TIERS, {
    onCompleted: ({ response }) => {
      setFeeTiers(response.tiers);
    },
  });

  const [useStripeInvoice, setUseStripeInvoice] = useState(
    getValues('generateInvoice'),
  );
  const [passFees, setPassFees] = useState(getValues('passFeesToBuyer'));
  const [allowCreditCard, setAllowCreditCard] = useState(
    getValues('allowCreditCard'),
  );

  const currencyType = getValues('currency');
  const currencySymbol = getCurrencySymbol(currencyType);

  const isMultipleWorks = works.length > 1;
  const assetsAreCollectibles =
    works.length > 0 ? isCollectible(works[0] as WorkWithObject) : false;
  const dispatch = useDispatch();

  const watchAll = watch();

  useEffect(() => {
    setUseStripeInvoice(watchAll.generateInvoice);
  }, [watchAll.generateInvoice]);

  const totalArtworkValue = useMemo(
    () =>
      watchAll.items.reduce((prevItem: number, currItem) => {
        const { artworkValue } = currItem;
        // eslint-disable-next-line no-param-reassign
        prevItem += artworkValue;
        return prevItem;
      }, 0),
    [watchAll],
  );

  const taxValue = useMemo(
    () => currency(watchAll.tax, { symbol: currencySymbol }),
    [watchAll, currencySymbol],
  );

  const shipHandleValue = useMemo(
    () => currency(watchAll.shipping, { symbol: currencySymbol }),
    [watchAll, currencySymbol],
  );

  const totalDiscount = useMemo(
    () =>
      watchAll.items.reduce((prevItem: currency, currItem) => {
        const discount = currency(currItem.artworkValue).multiply(
          currItem.discount / 100,
        );
        return prevItem.add(discount);
      }, currency(0, { symbol: currencySymbol })),
    [watchAll, currencySymbol],
  );

  const fairchainFee = useMemo(() => {
    const userForDiscounts = userInfo?.isSubUser ? mainUser : userInfo;
    const isTimeDiscounted: boolean =
      !!userForDiscounts?.discountEndDate &&
      userForDiscounts.discountEndDate !== '' &&
      compareAsc(new Date(), parseISO(userForDiscounts.discountEndDate)) <= 0;

    const getFairchainCut = (unitPrice: number): number => {
      let cut = MIN_FAIRCHAIN_CUT;
      let cutSet = false;

      feeTiers.forEach((tier) => {
        if ((tier.value < 0 || unitPrice < tier.value) && !cutSet) {
          cut = tier.percentage;
          cutSet = true;
        }
      });

      return cut;
    };

    return watchAll.items.reduce((prevItem: currency, currItem, index) => {
      const work = works[index]!;
      const isFirstSale =
        !work.lastCompletedTransactionId ||
        work.lastCompletedTransactionId === '';
      const isDiscounted = isTimeDiscounted && isFirstSale;

      const adjustedPrice = currItem.discount
        ? bankersRounding(
            currItem.artworkValue -
              currItem.artworkValue * (currItem.discount / 100),
          )
        : currItem.artworkValue;

      let itemFairchainFee = isDiscounted
        ? DISCOUNTED_FAIRCHAIN_CUT
        : Math.max(
            DISCOUNTED_FAIRCHAIN_CUT,
            bankersRounding(
              adjustedPrice * getFairchainCut(adjustedPrice * 100),
            ),
          );

      // Gallerist subscribers should not pay FC Fee
      if (
        isFirstSale &&
        userForDiscounts?.subscriptionActive &&
        userForDiscounts.isGallery
      ) {
        itemFairchainFee = 0;
      }
      return prevItem.add(currency(itemFairchainFee));
    }, currency(0, { symbol: currencySymbol }));
  }, [currencySymbol, feeTiers, mainUser, userInfo, watchAll, works]);

  const fairchainFundContribution = useMemo(() => {
    if (works) {
      const fairchainFundContributionPercentage = 0.015;

      const fairchainFundContributionAmount = watchAll.items.reduce(
        (prevItem: currency, currItem, index) => {
          const work = works[index]!;
          const fundRoyalty = work.fundRoyalty
            ? Number(work.fundRoyalty)
            : fairchainFundContributionPercentage;

          const isFirstSale =
            !work.lastCompletedTransactionId ||
            work.lastCompletedTransactionId === '';

          const discountedPrice = bankersRounding(
            currItem.artworkValue -
              currItem.artworkValue * (currItem.discount / 100),
          );

          return isFirstSale
            ? currency(-1, { symbol: currencySymbol })
            : prevItem.add(
                currency(bankersRounding(discountedPrice * fundRoyalty), {
                  precision: 0,
                }),
              );
        },
        currency(0, { symbol: currencySymbol, precision: 0 }),
      );

      return fairchainFundContributionAmount >
        currency(-1, { symbol: currencySymbol })
        ? fairchainFundContributionAmount
        : null;
    }
    return null;
  }, [works, watchAll, currencySymbol]);

  const totalArtistResaleCommissions = useMemo(() => {
    if (works) {
      const resaleCommissionAmount = watchAll.items.reduce(
        (prevItem: currency, currItem, index) => {
          const work: WorkWithObject = works[index]!;

          const isFirstSale =
            !work.lastCompletedTransactionId ||
            work.lastCompletedTransactionId === '';

          const discountedPrice = bankersRounding(
            currItem.artworkValue -
              currItem.artworkValue * (currItem.discount / 100),
          );

          if (isFirstSale) {
            return currency(-1, { symbol: currencySymbol });
          }

          const totalRoyaltyPayout = work.royalties.reduce(
            (prevRoyalty, currRoyalty) => {
              if (
                currRoyalty.diminishedDate &&
                dateInPast(new Date(currRoyalty.diminishedDate), new Date())
              ) {
                const royaltyValue = bankersRounding(
                  discountedPrice * (Number(currRoyalty.diminishedValue) / 100),
                );
                return prevRoyalty + royaltyValue;
              }

              const royaltyValue = bankersRounding(
                discountedPrice * (Number(currRoyalty.value) / 100),
              );
              return prevRoyalty + royaltyValue;
            },
            0,
          );

          return prevItem.add(totalRoyaltyPayout);
        },
        currency(0, { symbol: currencySymbol, precision: 2 }),
      );

      return resaleCommissionAmount > currency(-1, { symbol: currencySymbol })
        ? resaleCommissionAmount
        : null;
    }
    return null;
  }, [works, watchAll, currencySymbol]);

  const totalValueAmount = useMemo(
    () =>
      watchAll.items
        .reduce((prevItem: currency, currItem) => {
          const discount = currency(currItem.artworkValue).multiply(
            currItem.discount / 100,
          );
          const discountedValue = currency(currItem.artworkValue).subtract(
            discount,
          );
          return prevItem.add(discountedValue);
        }, currency(0, { symbol: currencySymbol }))
        .add(watchAll.shipping)
        .add(watchAll.tax),
    [watchAll, currencySymbol],
  );

  // We are eating the costs of ACH Debit Transactions
  const achFee = currency(0, { symbol: currencySymbol });
  const achStripeTransferFee = currency(0, { symbol: currencySymbol });

  const creditCardFee = useMemo(
    () =>
      totalValueAmount
        ? currency(totalValueAmount.value * 0.029 + 0.3, {
            symbol: currencySymbol,
          })
        : currency(0, { symbol: currencySymbol }),
    [totalValueAmount, currencySymbol],
  );

  const wireTransferFee = useMemo(
    () =>
      totalValueAmount
        ? currency(8, {
            symbol: currencySymbol,
          })
        : currency(0, { symbol: currencySymbol }),
    [totalValueAmount, currencySymbol],
  );

  const ccStripeTransferFee = useMemo(
    () =>
      totalValueAmount
        ? currency(totalValueAmount.value)
            .subtract(fairchainFee)
            .subtract(fairchainFundContribution?.value || 0)
            .subtract(totalArtistResaleCommissions?.value || 0)
            .subtract(creditCardFee)
            .multiply(0.0025)
            .add(0.25)
        : currency(0, { symbol: currencySymbol }),
    [
      currencySymbol,
      totalValueAmount,
      creditCardFee,
      fairchainFee,
      fairchainFundContribution,
      totalArtistResaleCommissions,
    ],
  );

  const wireStripeTransferFee = useMemo(
    () =>
      totalValueAmount
        ? currency(totalValueAmount.value)
            .subtract(fairchainFee)
            .subtract(fairchainFundContribution?.value || 0)
            .subtract(totalArtistResaleCommissions?.value || 0)
            .subtract(wireTransferFee)
            .multiply(0.0025)
            .add(0.25)
        : currency(0, { symbol: currencySymbol }),
    [
      currencySymbol,
      totalValueAmount,
      wireTransferFee,
      fairchainFee,
      fairchainFundContribution,
      totalArtistResaleCommissions,
    ],
  );

  const feesPassedOn = useMemo(
    () =>
      calculatePaymentWithStripeFeePassedOn(
        totalValueAmount.value,
        currencySymbol,
      ).subtract(totalValueAmount.value),
    [currencySymbol, totalValueAmount],
  );

  useEffect(() => {
    dispatch(setStripeFeesAmount(feesPassedOn.intValue));
  }, [feesPassedOn, dispatch]);

  useSetupDiminishedRoyalties(works);

  return (
    <View style={TailwindResponsive(`flex mb-8 mob:mb-0`)}>
      {isMultipleWorks && (
        <View
          style={[
            TailwindResponsive(
              `pr-16 w-1/2 mb-16 small:w-full mobWeb:w-full mobWeb:pr-0`,
            ),
            { zIndex: 100 },
          ]}
        >
          <TransactionSettings
            control={control}
            getValues={getValues}
            setValues={setValues}
          />
        </View>
      )}

      {works.map((work, index) => {
        const isFinal = index === works.length - 1;
        return (
          <TransferWorkDetails
            key={`transfer-item-${work.id}`}
            work={work}
            assetIsCollectible={assetsAreCollectibles}
            index={index}
            control={control}
            errors={errors}
            getValues={getValues}
            clearErrors={clearErrors}
            isMultipleWorks={isMultipleWorks}
            isFinal={isFinal}
            setValues={setValues}
            trigger={trigger}
          />
        );
      })}

      {!transIsRetro && (
        <View
          style={{
            ...TailwindResponsive(
              `pr-16 w-1/2 small:w-full mobWeb:w-full mobWeb:pr-0`,
            ),
            zIndex: -1,
          }}
        >
          <View
            style={TailwindResponsive(
              `border-t border-Dark1 mt-0 mob:mt-0 pt-12 mob:pt-10 mob:mb-10`,
            )}
          >
            <DetailsLineItem
              title="Contract Rider"
              text="Optionally attach a rider to be covered by the contract signature."
            >
              <ContractRider />
            </DetailsLineItem>
          </View>
        </View>
      )}

      {/* // to allow option picker to overlay */}
      <View style={{ zIndex: -1 }}>
        {currencyType === CurrencyType.USD && !transIsRetro && (
          <View
            style={[
              TailwindResponsive(
                `pr-16 w-1/2 small:w-full mobWeb:w-full mobWeb:pr-0`,
              ),
              { zIndex: -1 },
            ]}
          >
            <View style={TailwindResponsive(`border-t mb-5 pt-12 mob:pt-6`)}>
              <GraphikTextTiny style={TailwindResponsive(`mb-4`)}>
                Fairchain will send the buyer a title transfer agreement. Would
                you also like to invoice and charge the buyer via Fairchain?
              </GraphikTextTiny>

              <View style={TailwindResponsive(`flex flex-row mobWeb:flex-col`)}>
                <View
                  style={TailwindResponsive(
                    `w-1/3 z-10 mobWeb:w-full mobWeb:border-r`,
                  )}
                >
                  <InvoiceSelection setValues={setValues} />
                </View>

                <View style={TailwindResponsive(`w-2/3 mobWeb:w-full`)}>
                  <InvoiceDetails
                    allowCreditCard={allowCreditCard}
                    control={control}
                    currencySymbol={currencySymbol}
                    currencyType={currencyType}
                    errors={errors}
                    feesPassedOn={feesPassedOn}
                    isStripeSetup={isStripeSetup}
                    setAllowCreditCard={setAllowCreditCard}
                    setPassFees={setPassFees}
                    setValues={setValues}
                  />
                </View>
              </View>
            </View>
          </View>
        )}

        {(!useStripeInvoice || !isStripeSetup) && !transIsRetro && (
          <View
            style={TailwindResponsive(
              `pr-16 w-1/2 small:w-full mobWeb:w-full mobWeb:pr-0`,
            )}
          >
            <View
              style={TailwindResponsive(
                `border-t border-b border-Dark1 mb-12 mt-10 mob:mt-0 pt-12 mob:pt-10 mob:mb-10`,
              )}
            >
              <DetailsLineItem vertical title="Summary:">
                <View
                  style={
                    useStripeInvoice &&
                    TailwindResponsive(`mb-0 pb-0 mob:mb-0 mob:py-4`)
                  }
                >
                  <GraphikTextSm>
                    {`${
                      assetsAreCollectibles ? 'List Price' : 'Artwork Value'
                    }: `}
                    {`${
                      currency(totalArtworkValue, {
                        symbol: currencySymbol,
                      })
                        .format()
                        .split('.')[0]
                    }`}
                  </GraphikTextSm>
                  <GraphikTextSm>
                    Title Management: (
                    {`${fairchainFee.format().split('.')[0]}`})
                  </GraphikTextSm>
                </View>
              </DetailsLineItem>
            </View>
          </View>
        )}

        {isStripeSetup &&
          useStripeInvoice &&
          currencyType === CurrencyType.USD &&
          !transIsRetro && (
            <View
              style={TailwindResponsive(
                `pr-16 w-1/2 small:w-full mobWeb:w-full mobWeb:pr-0`,
              )}
            >
              <View
                style={TailwindResponsive(
                  `border-t border-b border-Dark1 mb-12 mt-0 mob:mt-0 pt-12 mob:pt-10 mob:mb-10`,
                )}
              >
                <DetailsLineItem vertical title="Summary:">
                  <View
                    style={
                      useStripeInvoice &&
                      TailwindResponsive(`mb-0 pb-0 mob:mb-0 mob:py-4`)
                    }
                  >
                    <GraphikTextSm>
                      {`${
                        assetsAreCollectibles ? 'List Price' : 'Artwork Value'
                      }: `}
                      {`${
                        currency(totalArtworkValue, {
                          symbol: currencySymbol,
                        })
                          .format()
                          .split('.')[0]
                      }`}
                    </GraphikTextSm>
                    <GraphikTextSm>
                      Discount: ({`${totalDiscount.format()}`})
                    </GraphikTextSm>
                    {useStripeInvoice && (
                      <>
                        <GraphikTextSm>
                          Shipping: {`${shipHandleValue.format()}`}
                        </GraphikTextSm>
                        <GraphikTextSm>
                          Tax: {`${taxValue.format()}`}
                        </GraphikTextSm>
                        <GraphikSemiTextSm>
                          Total Charge to Buyer:{' '}
                          {passFees
                            ? `${calculatePaymentWithStripeFeePassedOn(
                                totalValueAmount.value,
                                currencySymbol,
                              ).format()}`
                            : `${totalValueAmount.format()}`}
                        </GraphikSemiTextSm>
                      </>
                    )}
                  </View>
                </DetailsLineItem>
                <View
                  style={TailwindResponsive(
                    `border-t border-Dark1 mb-0 mt-0 pt-12 mobWeb:mb-28 mob:mt-0 mob:pt-10 mob:mb-10`,
                  )}
                >
                  <InvoiceCosts
                    achFee={achFee}
                    achStripeTransferFee={achStripeTransferFee}
                    allowCreditCard={allowCreditCard}
                    ccStripeTransferFee={ccStripeTransferFee}
                    creditCardFee={creditCardFee}
                    passFees={passFees}
                    wireStripeTransferFee={wireStripeTransferFee}
                    wireTransferFee={wireTransferFee}
                  />
                </View>
              </View>

              <View style={TailwindResponsive(`mb-0 pb-0 mob:pb-2`)}>
                <TransactionDetailsProceeds
                  achFee={achFee}
                  allowCreditCard={watchAll.allowCreditCard}
                  creditCardFee={creditCardFee}
                  currencySymbol={currencySymbol}
                  fairchainFee={fairchainFee}
                  fairchainFundContribution={fairchainFundContribution}
                  royaltyFees={totalArtistResaleCommissions}
                  ccTransferFee={ccStripeTransferFee}
                  achTransferFee={achStripeTransferFee}
                  wireStripeTransferFee={wireStripeTransferFee}
                  passFees={passFees}
                  totalArtworkValue={totalArtworkValue}
                  totalArtistResaleCommissions={totalArtistResaleCommissions}
                  totalValueAmount={totalValueAmount}
                  wireTransferFee={wireTransferFee}
                />
              </View>
            </View>
          )}
      </View>
    </View>
  );
};

export default TransactionDetails;
