import { GraphikSemiTextMd, GraphikTextMd } from 'components/shared/styled';
import { AssetType, RoyaltyType, UserRole } from 'constants/enums';
import { differenceInYears, format } from 'date-fns';
import React, { FunctionComponent, useMemo } from 'react';
import { Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { getUserRole } from 'store/user';
import { Work } from 'types';
import { getCombinedRoyalty, getRoyaltyByType } from 'utilities';
import { formatRoyaltyCopy } from 'utilities/Royalties';
import useTailwindResponsive from 'utilities/TailwindResponsive';

interface IRoyaltiesProps {
  work: Work;
}

type ContributorAndRoyalty = {
  id: string;
  label: string;
  value: string;
};

export const Royalties: FunctionComponent<IRoyaltiesProps> = ({ work }) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const userRole = useSelector(getUserRole);
  const itemStyle = `border-b border-Dark6 flex flex-row py-1 items-center justify-between`;

  const useCombinedRoyalties = useMemo(
    () =>
      work.lastCompletedTransactionId && work.lastCompletedTransactionId !== '',
    [work],
  );

  const doesDiminish = work.royalties.find(
    (royalty) => royalty.diminishedValue,
  );

  const contributorRoyalties: ContributorAndRoyalty[] =
    work.collectible?.contributors?.map((contributor) => {
      const royaltyValue = work.royalties.find(
        (royalty) => royalty.user === contributor.id,
      );

      return {
        id: contributor.id,
        label: `${contributor.firstName} ${contributor.lastName}`,
        value: royaltyValue?.value ?? '',
      };
    }) ?? [];

  if (work.assetType === AssetType.Collectible) {
    return (
      <View>
        {useCombinedRoyalties ? (
          <View style={TailwindResponsive('mb-2')}>
            <GraphikSemiTextMd>
              Total Creator Commission –{' '}
              {getCombinedRoyalty(work.royalties, false)}%
            </GraphikSemiTextMd>
          </View>
        ) : (
          <>
            <View style={TailwindResponsive(itemStyle)}>
              <GraphikTextMd>Brand Resale Commission</GraphikTextMd>
              <GraphikTextMd>
                {getRoyaltyByType(work.royalties, RoyaltyType.brand, false)}%
              </GraphikTextMd>
            </View>
            {contributorRoyalties.length > 0 &&
              contributorRoyalties.map((contributorAndRoyalty) => (
                <View style={TailwindResponsive(itemStyle)}>
                  <GraphikTextMd key={contributorAndRoyalty.id}>
                    {contributorAndRoyalty.label} Resale Commission
                  </GraphikTextMd>
                  <GraphikTextMd>{contributorAndRoyalty.value}%</GraphikTextMd>
                </View>
              ))}
            <View style={TailwindResponsive(itemStyle)}>
              <GraphikSemiTextMd>Total Resale Commission</GraphikSemiTextMd>
              <GraphikSemiTextMd>
                {getCombinedRoyalty(work.royalties, false)}%
              </GraphikSemiTextMd>
            </View>
          </>
        )}
      </View>
    );
  }

  if (doesDiminish) {
    const formattedDiminishDate = differenceInYears(
      new Date(doesDiminish.diminishedDate || ''),
      new Date(work.dateRegistered),
    );

    const formattedDateOfFirstTransaction = work.dateOfFirstTransaction
      ? format(new Date(work.dateOfFirstTransaction), 'MM/dd/yyyy')
      : 'date of first transaction';

    return (
      <View>
        <GraphikSemiTextMd
          style={TailwindResponsive('mb-4 border-b border-Gray2')}
        >
          Resale Commissions
        </GraphikSemiTextMd>
        {useCombinedRoyalties ? (
          <View style={TailwindResponsive(itemStyle)}>
            <GraphikSemiTextMd>Total Resale Commission</GraphikSemiTextMd>
            <View style={TailwindResponsive(`flex flex-row`)}>
              <GraphikSemiTextMd>
                {getCombinedRoyalty(work.royalties, false)}%{' '}
              </GraphikSemiTextMd>
              <GraphikTextMd>for the first </GraphikTextMd>
              <GraphikSemiTextMd>
                {formattedDiminishDate}{' '}
                {formatRoyaltyCopy(formattedDiminishDate)}{' '}
              </GraphikSemiTextMd>
              <GraphikTextMd>following </GraphikTextMd>
              <GraphikSemiTextMd>
                {formattedDateOfFirstTransaction}{' '}
              </GraphikSemiTextMd>
              <GraphikTextMd>and </GraphikTextMd>
              <GraphikSemiTextMd>
                {getCombinedRoyalty(work.royalties, true)}%{' '}
              </GraphikSemiTextMd>
              <GraphikTextMd>thereafter</GraphikTextMd>
            </View>
          </View>
        ) : (
          <>
            <View style={TailwindResponsive(itemStyle)}>
              <GraphikTextMd>Artist Resale Commission</GraphikTextMd>
              <Text style={TailwindResponsive(`text-right w-1/2 mobWeb:mt-2`)}>
                <GraphikSemiTextMd>
                  {getRoyaltyByType(work.royalties, RoyaltyType.artist, false)}%{' '}
                </GraphikSemiTextMd>
                <GraphikTextMd>for the first </GraphikTextMd>
                <GraphikSemiTextMd>
                  {formattedDiminishDate}{' '}
                  {formatRoyaltyCopy(formattedDiminishDate)}{' '}
                </GraphikSemiTextMd>
                <GraphikTextMd>following </GraphikTextMd>
                <GraphikSemiTextMd>
                  {formattedDateOfFirstTransaction}{' '}
                </GraphikSemiTextMd>
                <GraphikTextMd>and </GraphikTextMd>
                <GraphikSemiTextMd>
                  {getRoyaltyByType(work.royalties, RoyaltyType.artist, true)}%{' '}
                </GraphikSemiTextMd>
                <GraphikTextMd>thereafter</GraphikTextMd>
              </Text>
            </View>
            {userRole === UserRole.Gallery && (
              <View style={TailwindResponsive(itemStyle)}>
                <GraphikTextMd>Gallery Resale Commission</GraphikTextMd>
                <Text
                  style={TailwindResponsive(`text-right w-1/2 mobWeb:mt-2`)}
                >
                  <GraphikSemiTextMd>
                    {getRoyaltyByType(
                      work.royalties,
                      RoyaltyType.gallery,
                      false,
                    )}
                    %{' '}
                  </GraphikSemiTextMd>
                  <GraphikTextMd>for the first </GraphikTextMd>
                  <GraphikSemiTextMd>
                    {formattedDiminishDate}{' '}
                    {formatRoyaltyCopy(formattedDiminishDate)}{' '}
                  </GraphikSemiTextMd>
                  <GraphikTextMd>following </GraphikTextMd>
                  <GraphikSemiTextMd>
                    {formattedDateOfFirstTransaction}{' '}
                  </GraphikSemiTextMd>
                  <GraphikTextMd>and </GraphikTextMd>
                  <GraphikSemiTextMd>
                    {getRoyaltyByType(
                      work.royalties,
                      RoyaltyType.gallery,
                      true,
                    )}
                    %{' '}
                  </GraphikSemiTextMd>
                  <GraphikTextMd>thereafter</GraphikTextMd>
                </Text>
              </View>
            )}
            <View style={TailwindResponsive(itemStyle)}>
              <GraphikSemiTextMd>Total Resale Commission</GraphikSemiTextMd>
              <Text style={TailwindResponsive(`text-right w-1/2 mobWeb:mt-2`)}>
                <GraphikSemiTextMd>
                  {getCombinedRoyalty(work.royalties, false)}%{' '}
                </GraphikSemiTextMd>
                <GraphikTextMd>for the first </GraphikTextMd>
                <GraphikSemiTextMd>
                  {formattedDiminishDate}{' '}
                  {formatRoyaltyCopy(formattedDiminishDate)}{' '}
                </GraphikSemiTextMd>
                <GraphikTextMd>following </GraphikTextMd>
                <GraphikSemiTextMd>
                  {formattedDateOfFirstTransaction}{' '}
                </GraphikSemiTextMd>
                <GraphikTextMd>and </GraphikTextMd>
                <GraphikSemiTextMd>
                  {getCombinedRoyalty(work.royalties, true)}%{' '}
                </GraphikSemiTextMd>
                <GraphikTextMd>thereafter</GraphikTextMd>
              </Text>
            </View>
          </>
        )}
      </View>
    );
  }

  return (
    <View>
      <GraphikSemiTextMd
        style={TailwindResponsive('mb-4 border-b border-Gray2')}
      >
        Resale Commissions
      </GraphikSemiTextMd>
      {useCombinedRoyalties ? (
        <View style={TailwindResponsive(itemStyle)}>
          <GraphikSemiTextMd>Total Resale Commission</GraphikSemiTextMd>
          <GraphikSemiTextMd>
            {getCombinedRoyalty(work.royalties, false)}%
          </GraphikSemiTextMd>
        </View>
      ) : (
        <>
          <View style={TailwindResponsive(itemStyle)}>
            <GraphikTextMd>Artist Resale Commission</GraphikTextMd>
            <GraphikTextMd>
              {getRoyaltyByType(work.royalties, RoyaltyType.artist, false)}%
            </GraphikTextMd>
          </View>
          {userRole === UserRole.Gallery && (
            <View style={TailwindResponsive(itemStyle)}>
              <GraphikTextMd>Gallery Resale Commission</GraphikTextMd>
              <GraphikTextMd>
                {getRoyaltyByType(work.royalties, RoyaltyType.gallery, false)}%
              </GraphikTextMd>
            </View>
          )}
          <View style={TailwindResponsive(itemStyle)}>
            <GraphikSemiTextMd>Total Resale Commission</GraphikSemiTextMd>
            <GraphikSemiTextMd>
              {getCombinedRoyalty(work.royalties, false)}%
            </GraphikSemiTextMd>
          </View>
        </>
      )}
    </View>
  );
};
