import { alignFromEnum, ButtonAlignment } from 'constants/enums';
import React, { FunctionComponent } from 'react';
import { Pressable, Text } from 'react-native';
import Styles from 'style';
import { FairchainStyle } from 'types';

interface ButtonProps {
  alignment?: ButtonAlignment;
  color?: string;
  disabled?: boolean;
  label: string;
  onPress?: () => void;
  paddingHorizontal?: number;
  style?: FairchainStyle;
  testID?: string;
  textStyle?: { [key: string]: string };
  underline?: boolean;
}

export const TextButton: FunctionComponent<ButtonProps> = ({
  alignment,
  color,
  disabled,
  label,
  onPress,
  paddingHorizontal,
  style,
  testID,
  textStyle,
  underline,
}) => (
  <Pressable
    style={{
      ...style,
      alignItems: alignment === undefined ? 'center' : alignFromEnum(alignment),
      backgroundColor: 'transparent',
      justifyContent: 'flex-start',
      paddingHorizontal:
        paddingHorizontal || paddingHorizontal === 0 ? paddingHorizontal : 22,
      paddingVertical: 16,
    }}
    onPress={onPress}
    disabled={disabled}
    testID={testID}
  >
    <Text
      style={{
        fontFamily: Styles.Fonts.FontFamily.SansMedium,
        fontSize: Styles.Fonts.FontSize.Medium,
        color: color || Styles.Colours.Dark1,
        textDecorationLine: underline ? 'underline' : 'none',
        ...(textStyle ?? style),
      }}
    >
      {label}
    </Text>
  </Pressable>
);

export default TextButton;
