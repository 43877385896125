import { useReactiveVar } from '@apollo/client';
import { ToggleButton } from 'components/shared/button';
import { Checkbox } from 'components/shared/checkbox';
import { UnitInput } from 'components/shared/form/UnitInput';
import React, { FunctionComponent, useState } from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';
import { RegisterStore } from 'store/registerWork/apollo';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { RegisterWorkFields } from '../Helpers';
import { useGetFormDetails } from '../forms/artist-registration.form';

export const APInformation: FunctionComponent = () => {
  const detailsForm = useReactiveVar(RegisterStore.Vars.detailsForm);
  const arForm = useGetFormDetails(detailsForm);
  const { TailwindResponsive } = useTailwindResponsive();

  const [isEdition, setIsEdition] = useState<boolean>(
    typeof arForm?.getValues(RegisterWorkFields.editionType) === 'undefined'
      ? true
      : arForm?.getValues(RegisterWorkFields.editionType),
  );

  const [showEditionAp, setShowEditionAp] = useState<boolean>(
    arForm?.getValues(RegisterWorkFields.isEditionWork) || false,
  );

  return (
    <>
      <View style={TailwindResponsive('web:mb-12 mob:mb-6 mt-6')}>
        <Controller
          control={arForm?.control}
          name={RegisterWorkFields.isEditionWork}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              label="This work is an Edition or AP"
              isChecked={value}
              onPress={() => {
                onChange(!value);
                setShowEditionAp(!value);
              }}
              testId="EditionAPCheckbox"
            />
          )}
        />
      </View>
      {showEditionAp && (
        <View
          style={TailwindResponsive(
            'web:flex-row flex-1 mob:flex-col mb-12 mobWeb:flex-col mb-12',
          )}
          testID="EditionAPWrapper"
        >
          <View
            style={TailwindResponsive(
              'flex-1 mob:mb-12 mobWeb:items-center mb-12',
            )}
          >
            <Controller
              control={arForm?.control}
              name={RegisterWorkFields.editionType}
              render={({ field: { onChange, value } }) => (
                <ToggleButton
                  value={value}
                  setValue={() => {
                    onChange(!value);
                    setIsEdition(!value);
                  }}
                  testId="EditionAPToggle"
                  toggleOptionOne="Edition"
                  toggleOptionTwo="AP"
                />
              )}
            />
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 2,
            }}
          >
            {isEdition && (
              <Controller
                control={arForm?.control}
                name={RegisterWorkFields.editionNumber}
                render={({ field: { onChange, value } }) => (
                  <UnitInput
                    value={value}
                    label="* Edition #"
                    handleChange={(input) => {
                      arForm?.clearErrors('editionNumber');
                      onChange(input);
                    }}
                    errorMessage={
                      arForm?.formState?.errors?.editionNumber
                        ?.message as string
                    }
                    testId="EditionNumInput"
                  />
                )}
              />
            )}
            <Controller
              control={arForm?.control}
              name={RegisterWorkFields.editionTotal}
              render={({ field: { onChange, value } }) => (
                <UnitInput
                  value={value}
                  label={isEdition ? '* Edition Total' : 'Edition Total'}
                  handleChange={(input) => {
                    arForm?.clearErrors('editionTotal');
                    onChange(input);
                  }}
                  errorMessage={
                    arForm?.formState?.errors?.editionTotal?.message as string
                  }
                  testId="EditionTotalInput"
                />
              )}
            />
            {!isEdition && (
              <Controller
                control={arForm?.control}
                name={RegisterWorkFields.numAP}
                render={({ field: { onChange, value } }) => (
                  <UnitInput
                    value={value}
                    label="* AP #"
                    handleChange={(input) => {
                      arForm?.clearErrors('numAP');
                      onChange(input);
                    }}
                    errorMessage={
                      arForm?.formState?.errors?.numAP?.message as string
                    }
                    testId="APNumInput"
                  />
                )}
              />
            )}
            <Controller
              control={arForm?.control}
              name={RegisterWorkFields.totalNumberOfAPs}
              render={({ field: { onChange, value } }) => (
                <UnitInput
                  value={value}
                  label={!isEdition ? '* AP Total' : 'AP Total'}
                  handleChange={(input) => {
                    arForm?.clearErrors('totalNumberOfAPs');
                    onChange(input);
                  }}
                  errorMessage={
                    arForm?.formState?.errors?.totalNumberOfAPs
                      ?.message as string
                  }
                  testId="APTotalInput"
                />
              )}
            />
          </View>
        </View>
      )}
    </>
  );
};

export default APInformation;
