import { CollectionTab, TransactionItemPosition } from 'constants/enums';
import currency from 'currency.js';
import { ReactNode, RefCallback } from 'react';
import { InputModeOptions } from 'react-native';
import {
  Transaction,
  TransactionWithObjects,
  User,
  Work,
  WorkWithObject,
} from 'types';

export interface ICollectionTableProps {
  collectionStatus?: CollectionTab;
  open: boolean;
  selectedWorks: string[];
  toggleWorkSelection: (workId: string) => void;
  works: WorkWithObject[];
}

export interface ICollectionTransactionHeaderProps {
  children?: ReactNode;
  collectionStatus?: CollectionTab;
  disableGridView?: boolean;
  isByGrid?: boolean;
  onTabChange?: (tab: CollectionTab) => void;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  showCollection: boolean;
  toggleSortModalVisible?: () => void;
  viewByGrid: () => void;
  viewByLine: () => void;
}

export interface IWorkDetailsHeaderProps {
  backScreenTitle?: string;
  backScreen?: string;
  children?: ReactNode;
  hideExitButton?: boolean;
  title?: string;
}

export interface IADCertificateProps {
  setIsCertificateView?: (boolean) => void;
  work: Work;
}

export interface ITransactionTableLineItemProps {
  index: number;
  transaction: Transaction;
  work: Work;
  buyer: User;
  isLastTransaction?: boolean;
  transactionItemPosition?: TransactionItemPosition;
}

export interface ITransactionTotalValueProps {
  transaction: TransactionWithObjects;
}

export interface ITabSelectorProps {
  collectionStatus?: CollectionTab;
  disabled?: boolean;
  hasConsignedWorks: boolean;
  onTabChange: (tab: CollectionTab) => void;
  transactionView?: boolean;
}

export interface ICollectionLineItemProp {
  id?: number;
  index?: number;
  isByDate?: boolean;
  open?: boolean;
  selectedWorks: string[];
  toggleWorkSelection: (workId: string) => void;
  work: WorkWithObject;
}

export interface IGridItemProps {
  item: Work;
  numColumns: number;
}

export interface IGridViewProps {
  works?: WorkWithObject[];
  toggleWorkSelection: (workId: string) => void;
  selectedWorks: string[];
  open?: boolean;
}

export interface DefaultTextInputProps {
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters' | undefined;
  autoCorrect?: boolean | undefined;
  color?: string;
  editable?: boolean;
  errorMessage?: string;
  handleChange?: (text: string) => void;
  infoMessage?: string;
  inputRef?: RefCallback<any>;
  inputMode?: InputModeOptions;
  label: string | undefined;
  onKeyPress?: (text: string) => Promise<void>;
  secureTextEntry?: boolean | undefined;
  testId?: string;
  value: string | undefined;
}

export enum DetailsLineItemTitleStyle {
  regular,
  bold,
}
export interface IDetailsLineItemProps {
  children?: ReactNode;
  text?: string;
  title?: string;
  titleStyle?: DetailsLineItemTitleStyle;
  vertical?: boolean;
}

export interface ITransactionDetailsProceeds {
  achFee: currency;
  achTransferFee: currency;
  allowCreditCard: boolean;
  ccTransferFee: currency;
  creditCardFee: currency;
  currencySymbol: string;
  fairchainFee: currency;
  fairchainFundContribution: currency | null;
  passFees: boolean;
  royaltyFees: currency | null;
  totalArtworkValue: number;
  totalArtistResaleCommissions: currency | null;
  totalValueAmount: currency;
  wireStripeTransferFee: currency;
  wireTransferFee: currency;
}
