import { useLazyQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { GET_APP_STARTER, GET_USER_FEATURE_FLAGS } from 'api/requests.v2';
import { UserContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFeatureFlags } from 'store/general';
import {
  getUser,
  setEstateUser,
  setMainUser,
  userConnect,
  userDisconnect,
} from 'store/user';
import { FCUser } from 'store/user/apollo';
import LogError from 'utilities/LogError';
import Constants from '../../../constants';

export const useAppStarter = () => {
  const [appReady, setAppReady] = useState(false);
  const dispatch = useDispatch();
  const firebaseUser = useContext(UserContext);
  const navigation = useNavigation();
  const userFromStore = useSelector(getUser);

  const [getAppStart, { client, data, loading }] = useLazyQuery(
    GET_APP_STARTER,
    { fetchPolicy: 'cache-and-network' },
  );

  const [getUserFeatureFlags, { loading: getFFLoading }] = useLazyQuery(
    GET_USER_FEATURE_FLAGS,
    {
      onCompleted: ({ response }) => {
        dispatch(setFeatureFlags(response));
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (!firebaseUser) {
      // Nested if needed to not fire off the getAppStart in interim of disconnecting user
      if (userFromStore) {
        FCUser.Logout();
        dispatch(userDisconnect());
        client.clearStore(); // clears getAppStart cache
      }
    } else if (!userFromStore && !loading) {
      // Using then/catch notation to not have async in useEffect or complicated
      // if/else statements to cover when data is returned and not fire call a second time
      getAppStart({
        variables: { userId: firebaseUser!.uid },
      }).then(({ data: thenData }) => {
        if (thenData && thenData.response) {
          dispatch(userConnect(thenData.response.user));
          dispatch(setFeatureFlags(thenData.response.featureFlags));
          dispatch(setMainUser(thenData.response.mainUser));
          dispatch(setEstateUser(thenData.response.artistOfEstateUser));

          // Apollo switcheroo
          FCUser.LoggedInUserVar(thenData.response.user);
          FCUser.MainUserVar(thenData.response.mainUser);
        } else {
          LogError.logError(
            `Error in getAppStart for user: ${firebaseUser?.uid} and call to ${Constants.GraphQL.GRAPHQL_URL}`,
          );
        }
      });
    }
  }, [
    client,
    data,
    dispatch,
    firebaseUser,
    getAppStart,
    loading,
    userFromStore,
  ]);

  useEffect(() => {
    if (firebaseUser && !getFFLoading) {
      getUserFeatureFlags({ variables: { userId: firebaseUser.uid } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const willFocusSubscription = navigation.addListener('focus', () => {
      if (firebaseUser && !getFFLoading) {
        getUserFeatureFlags({ variables: { userId: firebaseUser.uid } });
      }
    });
    return willFocusSubscription;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAppReady(!!userFromStore);
  }, [userFromStore]);

  return appReady;
};
