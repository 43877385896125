import {
  GraphikMediumTextMd,
  GraphikTextMd,
  MediumTitle,
} from 'components/shared/styled';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const ArtistTransactScreen: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  return (
    <View>
      <MediumTitle style={TailwindResponsive(`mb-8`)}>
        Here&apos;s how it works:
      </MediumTitle>

      <GraphikMediumTextMd style={TailwindResponsive(`mb-6`)}>
        2 — Transact & Track:{' '}
        <GraphikTextMd>
          Generate sales agreements, securely process payments, and transfer
          title to collectors. You&apos;ll remain connected to your work as it
          moves through the market.
        </GraphikTextMd>
      </GraphikMediumTextMd>
    </View>
  );
};

export default ArtistTransactScreen;
