import { makeVar } from '@apollo/client';
import { ResetHeader } from 'components/shared/header/apollo.header';
import { User as FBUser } from 'firebase/auth';
import { User } from 'types';

export class FCUser {
  static AuthToken = makeVar<string | null>(null);

  static FirebaseUserVar = makeVar<FBUser | null>(null);

  static LoggedInUserVar = makeVar<User | null>(null);

  static MainUserVar = makeVar<User | null>(null);

  static Logout = () => {
    this.AuthToken(null);
    this.LoggedInUserVar(null);
    this.MainUserVar(null);

    ResetHeader();
  };
}
