import { ExitPopUpButton, RoundedButton } from 'components/shared/button';
import { ErrorMessage } from 'components/shared/error';
import { ButtonFooter } from 'components/shared/footer/ButtonFooter';
import { DefaultTextInput } from 'components/shared/form';
import MainHeader from 'components/shared/header/MainHeader.ios';
import { MediumTitle } from 'components/shared/styled';
import { UserRole } from 'constants/enums';
import { UserContext } from 'contexts';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SafeAreaView, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Divider } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, getUserRole } from 'store/user';
import Styles from 'style';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { useUpdateUser } from '../hooks';

interface IEditProfileInformationProps {
  setIsEditProfileInfoModalOpen: (isEditProfileInfoModalOpen: boolean) => void;
}

export const EditProfileInformation: FunctionComponent<
  IEditProfileInformationProps
> = ({ setIsEditProfileInfoModalOpen }) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const authUser = useContext(UserContext);
  const dispatch = useDispatch();

  const userFromStore = useSelector(getUser);
  const userRole = useSelector(getUserRole);

  const [isEditing, setIsEditing] = useState(false);
  const [tempFirstName, setTempFirstName] = useState('');
  const [tempLastName, setTempLastName] = useState('');
  const [tempEmail, setTempEmail] = useState('');
  const [tempPhoneNumber, setTempPhoneNumber] = useState('');
  const [tempAddressLineOne, setTempAddressLineOne] = useState('');
  const [tempAddressLineTwo, setTempAddressLineTwo] = useState('');
  const [tempCity, setTempCity] = useState('');
  const [tempState, setTempState] = useState('');
  const [tempZipCode, setTempZipCode] = useState('');

  const setDefaults = useCallback(() => {
    setTempFirstName(userFromStore?.firstName || '');
    setTempLastName(userFromStore?.lastName || '');
    setTempEmail(userFromStore?.email || '');
    setTempPhoneNumber(userFromStore?.phoneNumber || '');
    setTempAddressLineOne(userFromStore?.address?.billingAddressLineOne || '');
    setTempAddressLineTwo(userFromStore?.address?.billingAddressLineTwo || '');
    setTempCity(userFromStore?.address?.billingAddressCity || '');
    setTempState(userFromStore?.address?.billingAddressState || '');
    setTempZipCode(userFromStore?.address?.billingAddressZipcode || '');
  }, [userFromStore]);

  useEffect(() => {
    if (userFromStore) {
      setDefaults();
    }
  }, [setDefaults, userFromStore]);

  useEffect(() => {
    if (
      tempFirstName !== userFromStore?.firstName ||
      tempLastName !== userFromStore?.lastName ||
      tempEmail !== userFromStore?.email ||
      tempPhoneNumber !== userFromStore?.phoneNumber ||
      tempAddressLineOne !== userFromStore?.address?.billingAddressLineOne ||
      tempAddressLineTwo !== userFromStore?.address?.billingAddressLineTwo ||
      tempCity !== userFromStore?.address?.billingAddressCity ||
      tempState !== userFromStore?.address?.billingAddressState ||
      tempZipCode !== userFromStore?.address?.billingAddressZipcode
    ) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [
    isEditing,
    setTempFirstName,
    tempAddressLineOne,
    tempAddressLineTwo,
    tempCity,
    tempEmail,
    tempFirstName,
    tempLastName,
    tempPhoneNumber,
    tempState,
    tempZipCode,
    userFromStore?.address?.billingAddressCity,
    userFromStore?.address?.billingAddressLineOne,
    userFromStore?.address?.billingAddressLineTwo,
    userFromStore?.address?.billingAddressState,
    userFromStore?.address?.billingAddressZipcode,
    userFromStore?.email,
    userFromStore?.firstName,
    userFromStore?.lastName,
    userFromStore?.phoneNumber,
  ]);

  const { updateUserHandler, updateUserInfoError } = useUpdateUser(
    authUser?.uid,
    dispatch,
    tempAddressLineOne,
    tempAddressLineTwo,
    tempCity,
    tempFirstName,
    tempLastName,
    tempPhoneNumber,
    tempState,
    tempZipCode,
    userFromStore,
  );

  const pageTitle = useMemo(
    () =>
      userFromStore && userFromStore.firstName && userFromStore.lastName
        ? `${userFromStore.firstName} ${userFromStore.lastName}`
        : '',
    [userFromStore],
  );

  if (updateUserInfoError) return <ErrorMessage error={updateUserInfoError} />;

  return (
    <SafeAreaView style={TailwindResponsive('flex-1 m-4')}>
      <MainHeader title={pageTitle}>
        <View style={[TailwindResponsive('flex-row'), { marginLeft: 'auto' }]}>
          <ExitPopUpButton
            onPress={() => {
              setIsEditProfileInfoModalOpen(false);
            }}
          />
        </View>
      </MainHeader>
      <KeyboardAwareScrollView
        extraHeight={0}
        bounces={false}
        keyboardOpeningTime={0}
        showsVerticalScrollIndicator={false}
        style={TailwindResponsive('flex-1')}
        contentContainerStyle={TailwindResponsive('pb-24')}
      >
        <View style={TailwindResponsive('py-8')}>
          <MediumTitle>Edit Account Information</MediumTitle>
        </View>
        <Divider />
        <View
          style={{
            display: 'flex',
            height: 200,
            justifyContent: 'space-evenly',
          }}
        >
          <DefaultTextInput
            value={tempFirstName}
            label="First Name"
            handleChange={(input) => {
              setTempFirstName(input);
            }}
            editable={userRole !== UserRole.Artist}
          />
          <DefaultTextInput
            value={tempLastName}
            label="Last Name"
            handleChange={(input) => {
              setTempLastName(input);
            }}
            editable={userRole !== UserRole.Artist}
            infoMessage={
              userRole === UserRole.Artist
                ? 'Please contact support@fairchain.art if you need to edit your name'
                : ''
            }
          />
        </View>
        <View
          style={{
            display: 'flex',
            height: 700,
            justifyContent: 'space-evenly',
          }}
        >
          <DefaultTextInput
            inputMode="email"
            value={tempEmail}
            label="Email"
            handleChange={(input) => {
              setTempEmail(input);
            }}
          />
          <DefaultTextInput
            inputMode="tel"
            value={tempPhoneNumber}
            label="Phone"
            handleChange={(input) => {
              setTempPhoneNumber(input);
            }}
          />
          <DefaultTextInput
            value={tempAddressLineOne}
            label="Address Line 1"
            handleChange={(input) => {
              setTempAddressLineOne(input);
            }}
          />
          <DefaultTextInput
            value={tempAddressLineTwo}
            label="Address Line 2"
            handleChange={(input) => {
              setTempAddressLineTwo(input);
            }}
          />
          <DefaultTextInput
            value={tempCity}
            label="City"
            handleChange={(input) => {
              setTempCity(input);
            }}
          />
          <DefaultTextInput
            value={tempState}
            label="State"
            handleChange={(input) => {
              setTempState(input);
            }}
          />
          <DefaultTextInput
            value={tempZipCode}
            label="Zip"
            handleChange={(input) => {
              setTempZipCode(input);
            }}
          />
        </View>
      </KeyboardAwareScrollView>
      <ButtonFooter>
        <View style={TailwindResponsive('flex-col w-full self-center')}>
          <RoundedButton
            label="Apply"
            onPress={() => {
              updateUserHandler();
              setIsEditProfileInfoModalOpen(false);
            }}
            backgroundColor={
              isEditing ? Styles.Colours.Dark1 : Styles.Colours.Dark4
            }
            color="white"
          />
        </View>
      </ButtonFooter>
    </SafeAreaView>
  );
};
export default EditProfileInformation;
