import { useMutation } from '@apollo/client';
import { VERIFY_USER } from 'api/requests.v2';
import { RoundedButton } from 'components/shared/button';
import { DefaultTextInput } from 'components/shared/form';
import { OptionPicker } from 'components/shared/optionPicker';
import { GraphikTextSm } from 'components/shared/styled';
import { IdVerificationMethod, TextAlignment } from 'constants/enums';
import { UserContext } from 'contexts';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { createElement, Platform, Text, View } from 'react-native';
import Styles from 'style';
import { trimFormData } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const VerifyUser: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();

  const [verifyUser] = useMutation(VERIFY_USER);
  const [isSuccessfullySubmitted, setSuccessfullySubmitted] = useState('');
  const [isSubmittedError, setSubmittedError] = useState('');

  const authUser = useContext(UserContext);

  const verificationOptions = [
    { label: 'Previously Known', value: 'Previously Known' },
    { label: 'ID viewed', value: 'ID Viewed' },
    { label: 'ID verified via Jumio', value: 'ID verified via Jumio' },
  ];

  const IDTypes = [
    { label: 'State ID', value: 'State ID' },
    { label: 'Drivers License', value: 'Drivers License' },
    { label: 'Passport', value: 'Passport' },
  ];

  const {
    control,
    formState: { errors, isSubmitSuccessful },
    watch,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      email: '',
      howWasIdViewed: '',
      idType: '',
      prevKnownEmployee: '',
      verificationMethod: '',
      verifiedBy: '',
      verificationAdmin: authUser?.uid,
      verificationDate: new Date(),
    },
  });
  const watchVerificationMethod = watch('verificationMethod');

  const onSubmit = async (data: any) => {
    const trimmedData = trimFormData(data);
    const verifyUserResponse = await verifyUser({
      variables: {
        userVerificationInput: {
          email: trimmedData.email,
          howWasIdViewed:
            watchVerificationMethod === IdVerificationMethod.IdViewed
              ? trimmedData.howWasIdViewed
              : '',
          idType:
            watchVerificationMethod === IdVerificationMethod.IdViewed
              ? trimmedData.idType
              : '',
          prevKnownEmployee:
            watchVerificationMethod === IdVerificationMethod.PreviouslyKnown
              ? trimmedData.prevKnownEmployee
              : '',
          verificationMethod: trimmedData.verificationMethod,
          verifiedBy: trimmedData.verifiedBy,
          verificationAdmin: trimmedData.verificationAdmin,
          verificationDate: trimmedData.verificationDate,
        },
      },
    });

    if (verifyUserResponse.data.verifyUser.success === true) {
      setSuccessfullySubmitted('Identification details have been uploaded');
    } else {
      setSubmittedError(verifyUserResponse.data.verifyUser.errorMessage);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setTimeout(() => {
        setSubmittedError('');
        setSuccessfullySubmitted('');
      }, 3000);
    }
  }, [reset, isSubmitSuccessful]);

  return (
    <View style={TailwindResponsive('pb-12')}>
      <View style={TailwindResponsive(`border-t border-Dark1 p-12`)}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              value={value}
              label="* Email"
              handleChange={onChange}
              errorMessage={errors.email?.message}
            />
          )}
          name="email"
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <OptionPicker
              bold
              label="Verification Method"
              defaultValue=""
              options={verificationOptions}
              onChange={onChange}
              showIcon
              alignment={TextAlignment.Left}
              value={value}
            />
          )}
          name="verificationMethod"
          defaultValue=""
        />
        {watchVerificationMethod === IdVerificationMethod.PreviouslyKnown && (
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <DefaultTextInput
                value={value}
                label="* Previously Known Employee"
                handleChange={onChange}
                errorMessage={errors.prevKnownEmployee?.message}
              />
            )}
            name="prevKnownEmployee"
            defaultValue=""
          />
        )}
        {watchVerificationMethod === IdVerificationMethod.IdViewed && (
          <>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <OptionPicker
                  bold
                  label="ID Type"
                  defaultValue=""
                  options={IDTypes}
                  onChange={onChange}
                  showIcon
                  alignment={TextAlignment.Left}
                  value={value}
                />
              )}
              name="idType"
              defaultValue=""
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <DefaultTextInput
                  value={value}
                  label="* How was ID viewed"
                  handleChange={onChange}
                  errorMessage={errors.howWasIdViewed?.message}
                />
              )}
              name="howWasIdViewed"
              defaultValue=""
            />
          </>
        )}
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <DefaultTextInput
              value={value}
              label="* Verified By"
              handleChange={onChange}
              errorMessage={errors.verifiedBy?.message}
            />
          )}
          name="verifiedBy"
          defaultValue=""
        />
        {Platform.OS === 'web' && (
          <View style={TailwindResponsive(`my-5`)}>
            <GraphikTextSm>Date of Verification:</GraphikTextSm>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) =>
                createElement('input', {
                  type: 'date',
                  value,
                  onInput: onChange,
                  style: {
                    padding: '10px',
                    fontFamily: `${Styles.Fonts.FontFamily.SansRegular}`,
                  },
                })
              }
              name="verificationDate"
              defaultValue={new Date()}
            />
          </View>
        )}
        <View style={TailwindResponsive('m-5')}>
          <RoundedButton
            label="Record ID Verification"
            onPress={() => {
              handleSubmit(onSubmit)();
            }}
            backgroundColor={Styles.Colours.Primary}
            color={Styles.Colours.Light1}
          />
          {!!isSuccessfullySubmitted && (
            <Text style={{ color: Styles.Colours.Success }}>
              {isSuccessfullySubmitted}
            </Text>
          )}
          {!!isSubmittedError && (
            <Text style={{ color: Styles.Colours.Error }}>
              {isSubmittedError}
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};
