import { GraphikSemiTextMd, GraphikTextSm } from 'components/shared/styled';
import currency from 'currency.js';
import React, { FunctionComponent, useMemo } from 'react';
import { View } from 'react-native';
import { useGetTransactionDetailsHook } from 'store/transactionDetails/apollo';
import { CurrencyType } from 'types';
import { getCurrencySymbol } from 'utilities';
import useTailwindResponsive from 'utilities/TailwindResponsive';

type TransactionValueProps = {
  assetIsCollectible?: boolean;
};

export const TransactionValue: FunctionComponent<TransactionValueProps> = ({
  assetIsCollectible,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const transaction = useGetTransactionDetailsHook();

  const tItems = transaction?.items || [];

  const currencySymbol = getCurrencySymbol(transaction?.currency);
  const tax = currency(transaction?.tax || 0, {
    fromCents: true,
    symbol: currencySymbol,
  });
  const shippingValue = currency(transaction?.shipping || 0, {
    fromCents: true,
    symbol: currencySymbol,
  });
  const feesValue = currency(transaction?.feesPassed || 0, {
    fromCents: true,
    symbol: currencySymbol,
  });

  const totalArtworkValue = tItems.reduce(
    (prevItem: currency, currItem) => {
      const { price, discount } = currItem;
      return prevItem
        .add(price)
        .subtract(currency(price || 0).multiply((discount || 0) / 100));
    },
    currency(0, {
      symbol: currencySymbol,
      precision: 0,
    }),
  );

  const totalValue = currency(totalArtworkValue, {
    symbol: currencySymbol,
  })
    .add(tax)
    .add(shippingValue)
    .add(feesValue);

  const areAdditionalFees = useMemo(
    () => totalValue.value !== totalArtworkValue.value,
    [totalArtworkValue, totalValue],
  );

  const itemStyle =
    'border-b border-Dark6 py-1 flex flex-row items-center justify-between mobWeb:flex-row mob:flex-row mob:py-2';

  if (!transaction) return null;

  return (
    <View
      style={TailwindResponsive(
        `my-12 w-full mob:border-b mob:border-black mob:border-opacity-20 mob:my-10`,
      )}
    >
      <View
        style={TailwindResponsive(
          `pb-2 border-b border-Dark6 mob:border-Dark4`,
        )}
      >
        <GraphikTextSm>
          Transaction Details ({currencySymbol}
          {transaction.currency})
        </GraphikTextSm>
      </View>

      {transaction?.items.map(({ discount, price, work }) => {
        const artworkPrice = currency(price || 0, {
          symbol: currencySymbol,
          precision: 0,
        });

        const discountAmount = currency(artworkPrice || 0, {
          symbol: currencySymbol,
        })
          .multiply((discount || 0) / 100)
          .format();

        return (
          <View key={`${work.id}_Value_Item`}>
            <View style={TailwindResponsive(itemStyle)}>
              <GraphikSemiTextMd>{artworkPrice.format()}</GraphikSemiTextMd>
              {assetIsCollectible ? (
                <GraphikTextSm>{`${work.title}, List Price`}</GraphikTextSm>
              ) : (
                <GraphikTextSm>{`${work.title} Value`}</GraphikTextSm>
              )}
            </View>

            {discount > 0 && (
              <View style={TailwindResponsive(itemStyle)}>
                <GraphikSemiTextMd>{`(${discountAmount})`}</GraphikSemiTextMd>
                <GraphikTextSm>{`${work.title} Discount`}</GraphikTextSm>
              </View>
            )}
          </View>
        );
      })}

      {(transaction.stripeInvoiceId || transaction.stripePaymentIntentId) &&
        transaction.currency === CurrencyType.USD && (
          <>
            {shippingValue.value > 0 && (
              <View style={TailwindResponsive(itemStyle)}>
                <GraphikSemiTextMd>{shippingValue.format()}</GraphikSemiTextMd>
                <GraphikTextSm>Shipping and Handling</GraphikTextSm>
              </View>
            )}
            {tax.value > 0 && (
              <View style={TailwindResponsive(itemStyle)}>
                <GraphikSemiTextMd>{tax.format()}</GraphikSemiTextMd>
                <GraphikTextSm>Tax</GraphikTextSm>
              </View>
            )}
            {feesValue.value > 0 && (
              <View style={TailwindResponsive(itemStyle)}>
                <GraphikSemiTextMd>{feesValue.format()}</GraphikSemiTextMd>
                <GraphikTextSm>Credit Card Fees</GraphikTextSm>
              </View>
            )}
          </>
        )}

      {(areAdditionalFees ||
        transaction.items.length > 1 ||
        totalArtworkValue.intValue !== transaction.items[0]?.price) && (
        <View
          style={TailwindResponsive(
            `py-1 border-t border-Dark4 flex flex-row items-center justify-between mob:flex-row mob:py-2`,
          )}
        >
          <GraphikSemiTextMd>{totalValue.format()}</GraphikSemiTextMd>
          <GraphikTextSm>Total</GraphikTextSm>
        </View>
      )}
    </View>
  );
};

export default TransactionValue;
