import { useRoute } from '@react-navigation/native';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import { TextButton } from '../button';
import NotificationBadge from './NotificationBadge';

interface NavItemProps {
  amount: number;
  label: string;
  onPress: () => void;
}

export const NavItem: FunctionComponent<NavItemProps> = ({
  amount,
  label,
  onPress,
}) => {
  const { TailwindResponsive } = useTailwindResponsive();

  const isMobileWeb = useMediaQuery({ maxWidth: 768 });
  const textButtonStyle = isMobileWeb
    ? 'border-b border-black px-4 text-Dark1 w-full flex-row items-center'
    : 'mx-2 h-pbsm text-Dark1 flex-row';
  const route = useRoute();

  return (
    <View style={TailwindResponsive(textButtonStyle)}>
      <TextButton
        label={label}
        paddingHorizontal={5}
        textStyle={{ marginRight: 'auto' }}
        onPress={onPress}
        underline={route.name === label}
      />
      <NotificationBadge onPress={onPress} amount={amount} />
    </View>
  );
};

export default NavItem;
