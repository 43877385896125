import FairchainIcon from 'assets/images/faichain-emoji-bg.png';
import React, { FunctionComponent, useRef, useState } from 'react';
import { Animated, Image, Pressable, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';

export const EasterEgg: FunctionComponent = () => {
  const { TailwindResponsive } = useTailwindResponsive();
  const [showEE, setShowEE] = useState(false);

  const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0

  let clickCount = 0;
  let isStarted = false;

  const handleEasterEgg = () => {
    if (clickCount >= 3) {
      setShowEE(true);
      Animated.timing(fadeAnim, {
        duration: 250,
        toValue: 1,
        useNativeDriver: true,
      }).start();

      setTimeout(() => {
        Animated.timing(fadeAnim, {
          duration: 250,
          toValue: 0,
          useNativeDriver: true,
        }).start();
        setTimeout(() => {
          setShowEE(false);
        }, 250);
      }, 2000);
    }

    isStarted = false;
    clickCount = 0;
  };

  const handlePress = () => {
    clickCount += 1;

    if (!isStarted) {
      isStarted = true;
      setTimeout(handleEasterEgg, 500);
    }
  };

  return (
    <>
      <Pressable
        onPress={handlePress}
        style={[
          TailwindResponsive(`absolute right-0 top-0`),
          { cursor: 'default' },
        ]}
      >
        <View style={TailwindResponsive(`w-12 h-12`)} />
      </Pressable>
      {showEE && (
        <Animated.View
          style={{
            ...TailwindResponsive(
              `absolute bg-white items-center left-0 top-0 right-0 bottom-0 z-50`,
            ),
            opacity: fadeAnim,
          }}
        >
          <Image
            resizeMode="contain"
            source={FairchainIcon}
            style={TailwindResponsive(`h-1/2 w-1/2`)}
          />
        </Animated.View>
      )}
    </>
  );
};
