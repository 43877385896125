import React, { FunctionComponent } from 'react';
import { Platform, View } from 'react-native';
import useTailwindResponsive from 'utilities/TailwindResponsive';
import CheckOnlyIcon from './CheckOnlyIcon';

interface IBlueSquareCheckIconProps {
  backgroundColor?: string;
  checkColor?: string;
  width?: number;
}
export const BlueSquareCheckIcon: FunctionComponent<
  IBlueSquareCheckIconProps
> = ({ backgroundColor, checkColor, width }) => {
  const { TailwindResponsive } = useTailwindResponsive();
  const widthValue = width || (Platform.OS !== 'web' ? 24 : 48);
  return (
    <View
      style={{
        ...TailwindResponsive(
          `rounded-lg bg-${backgroundColor || 'Primary'} items-center`,
        ),
        width: widthValue,
        height: widthValue,
      }}
    >
      <CheckOnlyIcon color={checkColor || 'white'} width={widthValue} />
    </View>
  );
};
export default BlueSquareCheckIcon;
