import { ApolloError, makeVar } from '@apollo/client';

export enum ProfileScreens {
  personal = 'PERSONAL',
  subscription = 'SUBSCRIPTION',
  userManagement = 'USER_MANAGEMENT',
}

const profileScreenVar = makeVar<ProfileScreens>(ProfileScreens.personal);

export const ProfileVars = {
  screen: profileScreenVar,
  subScreen: {
    loading: makeVar<boolean>(true),
    refetch: makeVar<any>(null),
    subscription: makeVar<any>(null),
  },
  subUsers: {
    error: makeVar<ApolloError | undefined>(undefined),
  },
};

export const resetProfileVars = (screen: ProfileScreens | null) => {
  ProfileVars.screen(screen || ProfileScreens.personal);
  ProfileVars.subScreen.loading(true);
  ProfileVars.subScreen.refetch(null);
  ProfileVars.subScreen.subscription(null);
};
