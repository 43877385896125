import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { GET_TRANSACTION_COUNT } from 'api/requests.v2';
import { UserContext } from 'contexts';
import { FCStackNavProp } from 'navigation/types';
import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { getMainUser, getUser } from 'store/user';
import HeaderVars from './apollo.header';

export const useSubscriptionActive = (): boolean => {
  const user = useSelector(getUser);
  const mainUser = useSelector(getMainUser);

  return (
    (!!user &&
      (user.isArtist ||
        user.isBrand ||
        user.isGallery ||
        user.isAdmin ||
        user.isEstate ||
        user.subscriptionActive)) ||
    (!!mainUser &&
      (mainUser.isArtist ||
        mainUser.isBrand ||
        mainUser.isGallery ||
        mainUser.isAdmin ||
        mainUser.isEstate ||
        mainUser.subscriptionActive))
  );
};

export const useGetNumUserTransactions = () => {
  const authUser = useContext(UserContext);
  const numTransactions = useReactiveVar(HeaderVars.transactionCount);

  const [getTransCount] = useLazyQuery(GET_TRANSACTION_COUNT, {
    onCompleted: (data) => {
      HeaderVars.transactionCount(data.response);
    },
  });

  useFocusEffect(
    useCallback(() => {
      if (numTransactions < 0) {
        getTransCount({
          variables: {
            userId: authUser?.uid,
          },
        });
      }
    }, [authUser, getTransCount, numTransactions]),
  );

  return numTransactions;
};

export const useHandleRegisterWork = (): (() => void) => {
  const navigation = useNavigation<FCStackNavProp>();

  return () => {
    navigation.navigate('RegisterWork', { draftId: 'new' });
    HeaderVars.showMenuModal(false);
  };
};
